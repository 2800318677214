import React from 'react';
import { TableHead,TableRow, TableCell } from '@material-ui/core';

const IntlParcelHeaders = (props) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell style={{maxWidth:'60px'}}>
          <b>Parcel no.</b>
        </TableCell>
        <TableCell align="left"  style={{maxWidth:'138px'}}>
          <b>Weight&nbsp;(kg)</b>
        </TableCell>
        <TableCell align="left"  style={{maxWidth:'138px'}}>
          <b>Length (cm)</b>
        </TableCell> 
        <TableCell align="left"  style={{maxWidth:'138px'}}>
          <b>Width&nbsp;(cm)</b>
        </TableCell>
        <TableCell align="left"  style={{maxWidth:'138px'}}>
          <b>Height&nbsp;(cm)</b>
        </TableCell>
        <TableCell align="left"  style={{maxWidth:'138px'}}>
          <b>Parcel reference</b>
        </TableCell>
        <TableCell align="left" style={{maxWidth:'90px'}}>
          <b>Volumetric wt.&nbsp;(kg)</b>
        </TableCell>
      </TableRow>
     </TableHead>
  );
};

export default IntlParcelHeaders;
