import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import OrdersData from './ordersData';
import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';
import { axiosConfig } from '../../../api/axios';
import { getCustomerAccountID, customTableSort } from '../../../utils/helperFunctions';
import * as actions from '../../../store/actions/index';

class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: 'id', field: 'id', hidden: true, width: 0 },
        {
          title: 'Customer ref.',
          field: 'customerReference',
          width: 120,
          // customSort: (a, b) => customTableSort(a?.customerReference, b?.customerReference),
          render: (row) => <div onClick={() => this.orderRoWClicked(row)}>{row.customerReference}</div>,
          sorting: true,
        },
        {
          title: 'Business/Customer name',
          field: 'customerName',
          sorting: true,
          width: 220,
          render: (row) => <div onClick={() => this.orderRoWClicked(row)}>{row.customerName}</div>,
        },
        {
          title: 'Collection postal code',
          field: 'postalCode',
          sorting: true,
          width: 200,
        },
        {
          title: 'Source',
          field: 'source',
          sorting: false,
          width: 100,
        },
        {
          title: 'Sub-source',
          field: 'subSource',
          sorting: false,
          width: 100,
        },
        {
          title: 'Contact',
          field: 'contact',
          sorting: true,
          width: 100,
        },
        {
          title: 'Collection date',
          field: 'dispatchDate',
          sorting: true,
          width: 90,
        },
      ],
      orders_totalCount: 0,
      pageSize: 10,
      pageNumber: 0,
      isLoading: false,
      ordersData: [],
    };
    this.onClickSetSortOrder = this.onClickSetSortOrder.bind(this);
  }

  orderRoWClicked = (rowData) => {
    this.props.history.push({
      pathname: `/shipment/collectionRequest/${rowData.id}/true/true`, state: {
        isScanFlag: this.props.isScanFlag
      }
    });
  };

  componentDidMount() {
    this.props.setSortOrder(``);
    this.getOrdersData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.tabIndex === 2 && ((this.props.isSearched !== prevProps.isSearched || this.props.refreshList)
      || (this.props.addressBookSearchParams.split('=')[0] !== prevProps.addressBookSearchParams.split('=')[0]
        && this.props.addressBookSearchParams.split('=')[1] !== '' && prevProps.addressBookSearchParams !== ''))) {
      this.setState({ pageSize: this.state.pageSize, pageNumber: 0 }, () => {
        this.getOrdersData();
      });
    }
  }

  getOrdersData() {
    this.props.setRefreshList(false);
    this.setState({ isLoading: true });
    setTimeout(() => {
    const offset = this.state.pageSize * this.state.pageNumber;
    const limit = this.state.pageSize;
    axiosConfig
      .get(this.getUrl(offset, limit))
      .then((res) => {
        this.formatOrdersData(res.data);
      })
      .catch((err) => {
        console.log(err);
        this.formatOrdersData([]);
      })
      .finally(() => this.setState({ isLoading: false }));       
    }, 0);
  }

  getUrl = (offset, limit) => {
    let url = `${ServiceEndPoints.getOrders
      }?organization=${getCustomerAccountID()}&isValid=true&iscollectionrequest=true`;
    url += '&offset=' + offset;
    url += '&limit=' + limit;
    url += this.props.sortOrderState;
    url += this.props.addressBookSearchParams;
    return url;
  };

  formatOrdersData = (result) => {
    let final_data = [];
    ((result || {}).orders || []).forEach((element, index) => {
      const pickupAddress = element?.shipmentModel?.pickupAddress ?? {};
      final_data.push({
        id: element.id,
        customerReference: (element || {}).referenceNumber,
        customerName: pickupAddress.companyName || pickupAddress.name,
        source: element.sourceReference,
        subSource: element.subSource,
        contact: pickupAddress.name,
        postalCode: pickupAddress.postalCode,
        dispatchDate: (((element || {}).shipmentModel || {}).pickup || {}).date,
        printerQueueName: element?.printerQueueName
      });
    });
    if (
      this.props.addressBookSearchParams !== '' &&
      this.props.addressBookSearchParams?.split('=')?.[1] &&
      this.props.addressBookSearchParams !== '&customerReferenceNumber=' &&
      this.props.addressBookSearchParams !== '&alternateReference=' &&
      final_data.length === 1
    ) {
      this.props.history.push({
        pathname: `/shipment/collectionRequest/${final_data[0].id}/true/true`,
        state: {
          isScanFlag: this.props.isScanFlag,
        }
      });
    }

    this.setState({
      ordersData: final_data,
      orders_totalCount: result.totalRecords ? result.totalRecords : 0,
    });
  };

  onPageNumChange(pageNum) {
    this.setState({ pageNumber: pageNum }, () => {
      this.getOrdersData();
    });
  }

  onPageSizeChange(pageSize) {
    this.setState({ pageSize: pageSize }, () => {
      this.getOrdersData();
    });
  }

  onClickSetSortOrder(orderIn, orderBy) {
    if(orderIn === 'asc')
    {
      this.props.setSortOrder(`&sortBy=${orderBy}&sortIn=ascending`);
    }
    else if(orderIn === 'desc'){
      this.props.setSortOrder(`&sortBy=${orderBy}&sortIn=descending`);
    }
    else{
      this.props.setSortOrder(``);
    }
    this.setState({ pageNumber: 0 });
    this.getOrdersData();
  }

  render() {
    return (
      <OrdersData
        rows={this.state.ordersData}
        columns={this.state.columns}
        deleteSelectedOrder={this.props.deleteSelectedOrder}
        createAllCollections={this.props.createAllCollections}
        createSelectedCollections={this.props.createSelectedCollections}
        empty_DataSource_Message="Collections not found"
        // tableRef={this.props.tableRef}
        Dialogtitle="Delete collections"
        tabIndex={this.props.tabIndexes}
        fullWidth
        totalCount={this.state.orders_totalCount}
        pageSize={this.state.pageSize}
        count={this.state.orders_totalCount}
        isLoading={this.state.isLoading}
        inBuiltEvents={{
          onChangeRowsPerPage: (e) => this.onPageSizeChange(e),
          onChangePage: (a) => this.onPageNumChange(a),
        }}
        page={this.state.pageNumber}
        tableLayout="fixed"
        deleteAllOrder={this.props.deleteAllOrder}
        isCollectionRequest={true}
        isValid={true}
        onClickSetSortOrder={this.onClickSetSortOrder}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    addressBookSearchParams: state.searchParams,
    sortOrderState: state.common.sortOrderState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSortOrder: (data) => {
      dispatch(actions.setSortOrderState(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Orders));
