/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Add } from '@material-ui/icons';
import FormRenderer from '../../../../../framework/Inputs/formRenderer/formRenderer';
import { connect } from 'react-redux';
import { ServiceEndPoints } from '../../../../../utils/constants/ApiConstant';
import { GET } from '../../../../../api/axios';
import { setDropdownValues } from '../../../../../utils/helperFunctions';
const CollectionsServices = (props) => {
    const {
        formField: {
            service,
        },
        formikValues,
        setFieldValue,
        collectionDefaultValues,
    } = props;

    const [services, setServices] = useState([]);

    useEffect(() => {
        if (formikValues.deliveryType) {
            let addressType = 'servicePoint'
            if (formikValues.deliveryType === 'deliverToDoorStep')
                addressType = 'doorstep'
            GET(
                `${ServiceEndPoints.products
                }?destinationPostalCode=CV37 6UH&destinationCountryCode=GB&destinationRecipientType=business&weight=${formikValues.weight || 15
                }&originPostalCode=TR20 8ZS&originCountryCode=GB&parcels=1&destinationAddressType=${addressType}`,
            )
                .then((res) => {
                    if (res.data.length > 0) {
                        let min = Math.min.apply(
                            null,
                            res.data.map((item) => item.sortOrder),
                        );
                        let minSortOrderData = res.data.find((services) => services.sortOrder === min);
                        setServices(minSortOrderData?.deliveryCapabilities?.services || []);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setServices([]);
                });
        }
    }, [formikValues.weight, formikValues.deliveryType]);

    useEffect(() => {
        if (props.collectionDefaultValues?.orderedProduct && services.length > 0) {
            const serviceObj = setDropdownValues(services, 'orderedProduct', collectionDefaultValues?.orderedProduct);
            setFieldValue('service', serviceObj);
        } else {
            setFieldValue('service', services[0]);
        }
    }, [services, props.collectionDefaultValues]);

    return (
        <Grid container direction="row" spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
                <FormRenderer {...service.props} data={services} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} md={6}></Grid>
        </Grid>
    );
};

CollectionsServices.propTypes = {
    formField: PropTypes.object,
    formikValues: PropTypes.object,
    setFieldValue: PropTypes.func,
    collectionDefaultValues: PropTypes.object
};


export default connect(null, null)(CollectionsServices);
