export const DeliveryTypeOptions = [
  {
    value: 'business',
    label: 'Business',
  },
  {
    value: 'residential',
    label: 'Residential',
  },
];

export const SearchByOptions = [
  {
    value: 'customernumber',
    name: 'Customer number',
  },
  {
    value: 'recipientname',
    name: 'Recipient name',
  },
  {
    value: 'businessname',
    name: 'Business name',
  },
  {
    value: 'town',
    name: 'Town',
  },
  {
    value: 'postcode',
    name: 'Postal code',
  },
];

export const PostalCodeTablecolumns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'text', headerName: 'Text', width: 130 },
  { field: 'type', headerName: 'Type', width: 80 },
  { field: 'highlight', headerName: 'Highlight', width: 130 },
  { field: 'description', headerName: 'Description', width: 130 },
];
