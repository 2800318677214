import React, { useEffect } from 'react';
import InternationalSummary from './InternationalSummary';
import { useDispatch, useSelector } from 'react-redux';
import DomesticSummary from './DomesticSummary';
import * as actions from '../../../../store/actions/index';

const AddShipmentSummary = () => {
  const shipmentDetails = useSelector((state) => state.addShipmentData.shipmentDetails);
  const isDomestic = (shipmentDetails.destinationCountry || {}).code === 'GBR';
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(actions.setLoadedFrom({ loadedFrom: 'fromPreviewSummary', loadedResponse: shipmentDetails }));
    };
  }, []);

  return (
    <div>
      {isDomestic
? (
        <DomesticSummary shipmentDetails={shipmentDetails} />
      )
: (
        <InternationalSummary shipmentDetails={shipmentDetails} />
      )}
    </div>
  );
};

export default AddShipmentSummary;
