import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export const ShowNotification = (props) => {
  const classes = useStyles();
  const { displayMsg, alertSeverity, openNotification } = props;
  const [open, setOpen] = React.useState(openNotification);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Snackbar
        id="MuiAlert-snackbar-message"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={handleClose} severity={alertSeverity}>
          {displayMsg}
        </Alert>
      </Snackbar>
    </div>
  );
};

export const NotificationType = {
  error: 'error',
  warning: 'warning',
  info: 'info',
  success: 'success',
};
