import React, {useState, useEffect} from 'react'
import { Grid, makeStyles, Typography} from '@material-ui/core';
import CustomCaption from '../../../../framework/summaryFormate/CustomCaption';

const useStyles = makeStyles({
    flexContainer: {
         flexGrow: 1, 
    flexShrink: 1,
  flexBasis: 0,
    },
    parcelHandover:{
        paddingBottom:'20px',
    },
});

const ProductInternational = ({shipmentDetails}) => {
    const classes = useStyles();
    return (
        <>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3} >
      
  
        <Grid item className={classes.flexContainer} >
        <CustomCaption title="Product for delivery" value={(shipmentDetails.product || {}).productName}/>
        </Grid>
        <Grid item className={classes.flexContainer} >
        <CustomCaption title="International accounts" value={(shipmentDetails.accountNumber || {}).accountId}/>
        </Grid>
        <Grid item className={classes.flexContainer} >
        <CustomCaption title="Delivery ServicePoint" value={shipmentDetails.deliveryServicePoint}/>
        </Grid>

      
    </Grid>
    </>
    )
}

export default ProductInternational
