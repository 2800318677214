import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import ReactHtmlParser from 'react-html-parser';
import './customAccordion.scss';

const CheckBoxCustomAccordion = (props) => {
  const { heading, children, defaultExpanded } = props;


  return (
    <Grid className="accordion-Container">
     
      <Accordion className="custom-accordion" defaultExpanded={defaultExpanded}>
        <AccordionSummary
          className="expandedPanel"
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header" 
        >
          {/* <span className="checkBoxHeading" dangerouslySetInnerHTML={{ __html: heading }} /> */}
          <span className="checkBoxHeading">
            {ReactHtmlParser(heading)}
          </span>
        </AccordionSummary>
        <AccordionDetails className="content">{children}</AccordionDetails>
      </Accordion>
    </Grid>
  );
};

// CheckBoxCustomAccordion.propTypes = {
//   heading: PropTypes.string.isRequired,
//   content: PropTypes.any,
//   defaultExpanded: PropTypes.bool,
//   children: PropTypes.object,
// };
// CheckBoxCustomAccordion.defaultProps = {
//   defaultExpanded: true,
// };

export default CheckBoxCustomAccordion;

