import 'date-fns';
import React, { useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import { useField } from 'formik';
import { Grid } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import './inputDate.scss';

const useStylesTextField = makeStyles(() => ({
  root: {
    border: '1px solid #00000053',
    overflow: 'hidden',
    color: 'black',
    borderRadius: 4,
    backgroundColor: '#fff ',
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&$focused': {
      backgroundColor: '#fff',
      border: '1px solid #00000053',
    },
    disabledDatePicker: {
      backgroundColor: 'white',
    },
  },
}));

const TextFieldComponent = (props) => {
  return <TextField {...props} disabled={true} className="textfield-disabled" />;
};
const InputDate = (props) => {
  const classes = useStylesTextField();
  const [field, meta, helper] = useField(props);
  const { label, fullWidth, name, workingDays, isWorkingDays, minDate, maxDate, } = props;
  const { touched, error } = meta;
  const { setValue } = helper;
  const isError = touched && error && true;
  const { value } = field;
  let minimumDate = minDate ? new Date(new Date().getTime() + 86400000) : new Date();
  const [selectedDate, setSelectedDate] = useState(minimumDate);
  const [isDispatchFuture, setIsDispatchFuture] = useState(false)

  useEffect(() => {
    const dispatchProperty = props.isAmendShipment ? "DispatchDateForAmend" : "DispatchDate";
    const sessionDispatchDate = sessionStorage.getItem(dispatchProperty);

    if (value || sessionDispatchDate) {
      if (sessionDispatchDate && name === 'DispatchDate') {
        setSelectedDate(sessionDispatchDate);
        if (new Date(sessionDispatchDate).toDateString() !== new Date().toDateString()) {
          setIsDispatchFuture(true)
        }
      } else {
        const date = new Date(value);
        setSelectedDate(date);
        setIsDispatchFuture(false)
        if (name === 'DispatchDate') {
          if (date.toDateString() !== new Date().toDateString()) {
            setIsDispatchFuture(true)
          }
        }
      }
    }
  }, [value, props.isAmendShipment]);

  const handleDateChange = (date) => {
    setIsDispatchFuture(false)
    if (date) {
      setSelectedDate(date);
      try {
        const ISODateString = date.toISOString();
        if (props.name === 'DispatchDate') {
          props.isAmendShipment ? sessionStorage.setItem('DispatchDateForAmend', ISODateString) :
            sessionStorage.setItem('DispatchDate', ISODateString);
          ;
        }
        setValue(ISODateString);
      } catch (dateError) {
        setValue(date);
      }
    } else {
      setValue(date);
    }
  };

  const disableDates = (date) => {
    if (isWorkingDays) {
      if (workingDays.some((workingDay) => workingDay.getTime() === date.getTime())) {
        return false;
      }
      return true;
    }
    return false;
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around" className={`date-container ${(isDispatchFuture) ? " futuredate-container" : " "}`}>
        <KeyboardDatePicker
          required
          className="datePicker"
          inputVariant="filled"
          id="date-picker-dialog"
          {...field}
          format="dd/MM/yyyy"
          label={<label className="labelTextfield">{label}</label>}
          fullWidth={fullWidth}
          name={name}
          // minDate={minDate}
          // maxDate={maxDate}
          value={new Date(selectedDate).toDateString()}
          shouldDisableDate={disableDates}
          onChange={handleDateChange}
          InputProps={{ classes, disableUnderline: true }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          error={isError}
          TextFieldComponent={TextFieldComponent}
          invalidDateMessage={isError && error}
          helperText={isError && error}
          disabled={props.isDisabled}
        // cancelLabel="Cancel"
        // okLabel="Ok"
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

InputDate.propTypes = {
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  name: PropTypes.string,
};

export default InputDate;
