/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, SvgIcon, makeStyles, Tooltip } from '@material-ui/core';
import FormRenderer from '../../framework/Inputs/formRenderer/formRenderer';
import { ReactComponent as InfoIcon } from '../../assets/images/InfoIcon.svg';
import { GET } from '../../api/axios';
import { useSelector } from 'react-redux';
import { ServiceEndPoints } from '../../utils/constants/ApiConstant';
import { getCustomerAccountID } from '../../utils/helperFunctions';
import { UserRoles } from '../../utils/constants/constants';
const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: '#fff',
    color: '#000000E6',
    fontSize: '14px',
    boxShadow: '0px 3px 6px #00000029',
    padding: '8px 15px',
    borderRadius: '0',
  },
  customArrow: {
    color: '#fff',
  },
}));

const AddToAddressAndFav = (props) => {
  const classes = useStyles();
  const {
    formikValues,
    formField,
    customerNumber,
    setFieldValue,
    addToAddressBookControl,
    addToFavControlName,
    updateAddressBookControl,
    customerNumberControl,
    addressSearchObj,
    shipmentDetails,
    isAmendShipment
  } = props;

  const [isInAddressbook, setIsInAddressbook] = useState(false);
  const [isInFavourites, setIsInFavourites] = useState(false);
  const [disabledFields, setDisabledFields] = useState({
    addToAddressBook: false,
    updateAddToAddressBook: false,
    addToFavCutomerList: false,
  });

  const userAccounts = useSelector((state) => state.auth.userDetails);
  const loadedFrom = useSelector((state) => state.loadedFrom.loadedFrom);
  const userDetails = useSelector(state => state.auth.userDetails);

  useEffect(() => {
    if (typeof customerNumber === 'string' && (customerNumber || '').trim().length < 1) {
      setFieldValue(addToAddressBookControl, false);
      setFieldValue(addToFavControlName, false);
      setFieldValue(updateAddressBookControl, false);
      setIsInAddressbook(false);
      setIsInFavourites(false);
    } else if (customerNumber === true) {
      setIsInAddressbook(true);
      setIsInFavourites(true);
    } else {
      checkCustomerIfAlreadyExists();
    }
  }, [customerNumber]);

  const checkCustomerIfAlreadyExists = () => {
    GET(
      `${ServiceEndPoints.getAddressBook
      }?organization=${getCustomerAccountID()}&offSet=0&limit=1&isExactMatch=true&customernumber=${formikValues[customerNumberControl]
      }`,
    )
      .then((res) => {
        if (res.data?.addressBooks?.length > 0) {
          setIsInAddressbook(true);
          addressSearchObj(res.data?.addressBooks[0]);
          const record = res.data.addressBooks[0];
          if (record?.favouriteUsers?.some((userId) => userId === userAccounts?.userId)) {
            setIsInFavourites(true);
          } else {
            setIsInFavourites(false);
          }
        } else {
          setIsInAddressbook(false);
        }
      })
      .catch((err) => {
        addressSearchObj({});
        console.log(err);
        setIsInAddressbook(false);
        setIsInFavourites(false);
      });
  };

  useEffect(() => {
    if (isInAddressbook && !isInFavourites && loadedFrom !== 'fromPreviewSummary') {
      setDisabledFields({ ...disabledFields, updateAddToAddressBook: false, addToFavCutomerList: false });
      setFieldValue(updateAddressBookControl, false);
      setFieldValue(addToFavControlName, false);
      setFieldValue(addToAddressBookControl, false);
    } else if (isInAddressbook && isInFavourites && loadedFrom !== 'fromPreviewSummary') {
      setDisabledFields({ ...disabledFields, updateAddToAddressBook: false, addToFavCutomerList: true });
      setFieldValue(updateAddressBookControl, false);
      setFieldValue(addToFavControlName, true);
      setFieldValue(addToAddressBookControl, false);
    } else if (!isInAddressbook && !isInFavourites && loadedFrom !== 'fromPreviewSummary') {
      setDisabledFields({ ...disabledFields, addToAddressBook: false, addToFavCutomerList: false });
      setFieldValue(addToAddressBookControl, false);
      setFieldValue(addToFavControlName, false);
      setFieldValue(updateAddressBookControl, false);
    }
  }, [isInAddressbook, isInFavourites, setFieldValue]);

  useEffect(() => {
    if (formikValues[addToFavControlName] && !isInAddressbook) {
      setFieldValue(addToAddressBookControl, true);
    } else {
      setFieldValue(addToAddressBookControl, false);
    }
  }, [formikValues[addToFavControlName], isInAddressbook, setFieldValue]);

  useEffect(() => {
    if (
      shipmentDetails &&
      shipmentDetails.updateAddToAddressBook &&
      formikValues[addToFavControlName] &&
      isInAddressbook
    ) {
      setFieldValue(updateAddressBookControl, true);
    } else {
      setFieldValue(updateAddressBookControl, false);
    }
  }, [shipmentDetails, formikValues[addToFavControlName], isInAddressbook, setFieldValue]);

  return (
    <Grid container spacing={3} direction="row" alignItems="flex-start" className="addToFav">
      {isInAddressbook ? (
        <Grid item xs={4} md={4} lg={5}>
          <FormRenderer
            isDisabled={(formikValues[customerNumberControl] || '').trim().length < 1 || isAmendShipment || userDetails.role === UserRoles.BasicShipmentEntry}
            fullWidth
            {...formField[updateAddressBookControl].props}
          />
        </Grid>
      ) : (
        <Grid item xs={4} md={4} lg={5}>
          <FormRenderer
            style={{ opacity: '1 !important' }}
            isDisabled={
              formikValues[addToFavControlName] || (formikValues[customerNumberControl] || '').trim().length < 1 || isAmendShipment || userDetails.role === UserRoles.BasicShipmentEntry
            }
            fullWidth
            {...formField[addToAddressBookControl].props}
          />
        </Grid>
      )}

      <Grid item xs={4} md={4} lg={5}>
        <FormRenderer
          style={{ opacity: '1 !important' }}
          isDisabled={
            (formikValues[customerNumberControl] || '').trim().length < 1 || disabledFields.addToFavCutomerList || isAmendShipment || userDetails.role === UserRoles.BasicShipmentEntry
          }
          fullWidth
          {...formField[addToFavControlName].props}
        />
      </Grid>
      <Grid item xs={2} md={2} lg={2} style={{ marginTop: '7px' }}>
        <Tooltip
          classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }}
          title="You need to enter customer number to add to address book or favourite list."
          arrow
          placement="left"
          disableHoverListener={isAmendShipment || userDetails.role === UserRoles.BasicShipmentEntry}
        >
          <SvgIcon fontSize="large" component={InfoIcon} style={{ fontSize: '1.5rem' }} />
        </Tooltip>
      </Grid>
    </Grid>
  );
};

AddToAddressAndFav.propTypes = {};

export default AddToAddressAndFav;
