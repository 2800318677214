import React, { forwardRef, useState, useEffect, useRef } from 'react';
import MaterialTable from 'material-table';
import { Grid, SvgIcon, IconButton, MenuItem, Select, ListItemIcon, ListItemText, Checkbox } from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import Badge from '@material-ui/core/Badge';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import InputButton from '../../../framework/Inputs/inputButton/inputButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { ReactComponent as FilterIcon } from '../../../assets/images/Filter.svg';
import { useHistory } from 'react-router';
import './DashnboardMonitorList.scss';
import { customTableSort, basicSort, customTableShipmentNoSort, formatDateToUKFormat } from '../../../utils/helperFunctions';
import { shipmentDetails } from '../../../utils/Models';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};
const DashboardMonitorList = (props) => {
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [showFilter, setShowFilter] = React.useState(false);
  const [selectedVal, setSelectedVal] = useState([]);
  const [monitorListData, setmonitorList] = useState(props.monitorList);
  const [tableData, setTableData] = useState(props.monitorList);
  let history = useHistory();
  const tableRef = useRef();
  const filterButtonStyle = {
    padding: '4px !important',
  };
  const CustomFilter = props => {
    const handleChange = async (e) => {
      const val = await e.target.value;
      setSelectedVal(val);
      // props.onFilterChanged(props.columnDef.tableData.columnOrder, val);
      if (val.length > 0) {
        const myArrayFiltered = monitorListData.filter(el => {
          return val.some(f => {
            return f === el.shipmentDetails.status;
          });
        });
        setTableData(myArrayFiltered)
      } else {
        setTableData(monitorListData)
      }
    }

    return (
      <th>
        <Select value={selectedVal} onChange={handleChange}
          multiple={true}
          MenuProps={MenuProps}
          style={{ width: "100px" }}
          renderValue={(data) => <div>{(data.length > 1) ? data[0] + '...' : data[0]}</div>}
        // disableUnderline
        >
          {props.columnDef.filterValues.map((option) => (
            <MenuItem key={option} value={option}>
              <ListItemIcon>
                <Checkbox
                  checked={selectedVal.indexOf(option) > -1}
                />
              </ListItemIcon>
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </th>
    );
  };
  const columns = [
    {
      title: 'Customer ref.', field: 'shipmentDetails.customerReference',
      customSort: (a, b) => customTableSort(a.shipmentDetails.customerReference, b.shipmentDetails.customerReference),
      filtering: false
    },
    {
      title: 'Shipment number', field: 'shipmentDetails.shipmentNumber',
      customSort: (a, b) => customTableShipmentNoSort(a.shipmentDetails.shipmentNumber, b.shipmentDetails.shipmentNumber),
      filtering: false
    },
    {
      title: 'Collection date', field: 'shipmentDetails.collectionDate',
      render: (row) => <span>{`${formatDateToUKFormat(row.shipmentDetails.collectionDate)}`}</span>,
      customSort: (a, b) => basicSort(new Date(a.shipmentDetails.collectionDate).getTime() || 0, new Date(b.shipmentDetails.collectionDate).getTime() || 0),
      filtering: false
    },
    {
      title: 'Delivery date', field: 'shipmentDetails.deliveryDate',
      render: (row) => <span>{`${formatDateToUKFormat(row.shipmentDetails.deliveryDate)}`}</span>,
      customSort: (a, b) => basicSort(new Date(a.shipmentDetails.deliveryDate).getTime() || 0, new Date(b.shipmentDetails.deliveryDate).getTime() || 0),
      filtering: false,
    },
    {
      title: (
        <div style={{ display: 'flex', paddingTop: '5px' }}>
          <div>
            <span>Status</span>
          </div>
          <div>
            <IconButton className="FilterIcon" onClick={() => setShowFilter(true)} style={filterButtonStyle}>
              <SvgIcon component={FilterIcon} viewBox="0 0 30 30" />
            </IconButton>
          </div>
        </div>
      ),
      field: 'shipmentDetails.status',
      sorting: false,
      render: (rowData) => {
        let statusStyleBar = { height: '15px', width: '5px', margin: '2px', background: '' };
        switch (rowData.shipmentDetails?.status?.trim()?.toLowerCase()) {
          case 'order received':
            statusStyleBar.background = '#ED7700';
            break;
          case 'in transit':
            statusStyleBar.background = '#F7BC00';
            break;
          case 'exception':
            statusStyleBar.background = '#9A77E8';
            break;
          case 'delivery attempted':
            statusStyleBar.background = '#2699FB';
            break;
          case 'out for delivery':
            statusStyleBar.background = '#025598';
            break;
          case 'delivered':
            statusStyleBar.background = '#76BD22';
            break;
          default:
            statusStyleBar.background = '#F7BC00';
        }
        return (
          <div style={{ display: 'flex', paddingTop: '5px' }}>
            <div style={statusStyleBar}></div>
            <div style={{ paddingLeft: '5px' }}>{rowData.shipmentDetails.status}</div>
          </div>
        );
      },
      filtering: showFilter,
      lookup: {
        'Order received': 'Order received',
        'In transit': 'In transit',
        Exception: 'Exception',
        'Delivery attempted': 'Delivery attempted',
        'Out for delivery': 'Out for delivery',
        Delivered: 'Delivered'
      },
      filterValues:
        ['In transit', 'Out for delivery', 'Exception', 'Delivered', 'Delivery attempted'
          , 'Order received'
        ],
      filterComponent: (props) => <CustomFilter {...props} />,
    },
  ];
  const tableIcons = {
    SortArrow: forwardRef((props, ref) => <KeyboardArrowUpIcon {...props} ref={ref} />),
  };
  const tableOptions = {
    selection: true,
    search: false,
    paging: false,
    showTitle: false,
    filtering: showFilter,
    sorting: true,
    maxBodyHeight: '387px',
    toolbar: false,
    overflowY: 'auto',
    overflowX: 'auto',
    headerStyle: { position: 'sticky', top: 0, fontWeight: 'bold' },
    rowStyle: (rowData, index) => {
      let backGroundColor = '';
      index % 2 === 0 ? (backGroundColor = '#F2F2F2') : (backGroundColor = '');
      if (rowData.isStatusChanged) {
        backGroundColor = '#FFEFAE';
      }
      return { backgroundColor: backGroundColor };
    },
  };

  useEffect(() => {
    return () => {
      props.clearRecentlyViewed();
    }
  }, [])
  useEffect(() => {
    setTableData(props.monitorList)
    setmonitorList(props.monitorList)
  }, [props.monitorList])

  const addSelectedRows = (selectedRowsFromTable) => {
    if (selectedVal.length > 0) {
      const myArrayFiltered = props.monitorList.filter(el => {
        return selectedVal.some(f => {
          return f === el.shipmentDetails.status;
        });
      });
      setTableData(myArrayFiltered)
    } else {
      setTableData(props.monitorList)
    }
    setSelectedRows(selectedRowsFromTable);
  };

  const trackShipmet = () => {
    history.push(`/dashboard/Tracking?id=${selectedRows[0].shipmentDetails.shipmentNumber}`);
  };

  const clearShipmet = () => {
    props.clearFromMonitorList(selectedRows);
    setSelectedVal([])
    setSelectedRows([]);
  };

  const clearRecent = () => {
    props.clearRecentlyViewed();
    if (tableRef.current) {
      tableRef.current.onAllSelected(false)
    }
    props.handleDrawer();
  };

  return (
    <React.Fragment>
      <Grid>
        <Grid>
          <button id="Drawer-monitor-list-btn" onClick={clearRecent}>
            <VisibilityOutlinedIcon className="eye-icon" style={{ fontSize: '32px', verticalAlign: 'middle' }} />
            <span style={{ verticalAlign: 'middle' }}>Monitor list ({props.monitorListCount.total})</span>
            <Badge badgeContent={`${props.monitorListCount.active}`} color="error"></Badge>
            <FilterListOutlinedIcon
              className="collapse-icon"
              style={{ fontSize: '20px', verticalAlign: 'middle', float: 'right' }}
            />
          </button>
        </Grid>
        <Grid className="Dashboard-monitor-list">
          <MaterialTable
            icons={tableIcons}
            columns={columns}
            data={tableData}
            options={tableOptions}
            onSelectionChange={(rows) => { addSelectedRows(rows) }}
            tableRef={tableRef}
          />
        </Grid>
        <Grid justify="flex-end" sm={12} lg={12}>
          {/* <Grid lg={6} sm={6} style={{ paddingLeft: '40px', paddingTop: '10px', position: 'absolute' }}>
            {<p style={{ color: '#707070', opacity: '1' }}>Note: Use long press to set the priority of the rows</p>}
          </Grid> */}
          <Grid className="Monitor-Btn" lg={6} sm={6}>
            <InputButton
              className="outlined-btn Delete-AddressBook"
              isDisabled={selectedRows.length === 0}
              label="Clear"
              variant="outlined"
              onClick={clearShipmet}
            />
            <InputButton
              className="outlined-btn Delete-AddressBook"
              isDisabled={selectedRows.length !== 1}
              label="Track"
              variant="outlined"
              onClick={trackShipmet}
            />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default DashboardMonitorList;
