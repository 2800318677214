import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Tab, Tabs, Divider, Icon } from '@material-ui/core';
import TabPanel from '../../../shared/tabPanel';
import InputButton from '../../../../framework/Inputs/inputButton/inputButton';
import { ArrowForward } from '@material-ui/icons';
import './shipmentDetails.scss';
import CustomDialog from '../../../../framework/dialog/customDialog';
import DimensionDetails from '../dimensionDetails/dimensionDetails';

const ShipmentDetails = (props) => {
  const { shipmentDetails } = props;
  const [tabIndex, setTabIndex] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deliveryType, setdeliveryType] = useState('');
  const [currentdeliveryTypeDropDownValue, setcurrentdeliveryTypeDropDownValue] = useState('');

  const handleTabChange = (event, value) => {
    setTabIndex(value);
  };

  const handleDialog = (value) => {
    setDialogOpen(value);
  };

  useEffect(async () => {
    let _isRescheduled = shipmentDetails.isRescheduled;
    let _deliveryChoice = shipmentDetails.deliveryChoice;
    if (!_isRescheduled) {
      if (_deliveryChoice === 'YYY') {
        setdeliveryType('Doorstep - Leave with neighbour');
        setcurrentdeliveryTypeDropDownValue({ name: 'Leave with neighbor', value: 'Leave with neighbor' });
      } else if (_deliveryChoice === 'NYN') {
        setdeliveryType('Deliver to doorstep only');
        setcurrentdeliveryTypeDropDownValue({ name: 'Select', value: '' });
      } else if (_deliveryChoice === 'YYY/SAFE') {
        setdeliveryType('Leave at safe location');
        setcurrentdeliveryTypeDropDownValue({ name: 'Safe place location', value: 'Safe place location' });
      } else if (_deliveryChoice === 'NYN/AGE') {
        setdeliveryType('Deliver to doorstep with AGE verification');
        setcurrentdeliveryTypeDropDownValue({ name: 'Deliver to doorstep with AGE verification', value: 'deliverToDoorStepWithAge' });
      }else if (_deliveryChoice === 'NYN/PIN') {
        setdeliveryType('Deliver to doorstep with PIN');
        setcurrentdeliveryTypeDropDownValue({ name: 'Deliver to doorstep with PIN', value: 'deliverToDoorStepWithPin' });
      } else if (_deliveryChoice === 'NNN/SP') {
        setdeliveryType('Deliver to Service Point');
        setcurrentdeliveryTypeDropDownValue({
          name: 'Deliver to local ServicePoint',
          value: 'Deliver to local service point',
        });
      }
    } else if (_deliveryChoice === 'NYN/SAFE' || _deliveryChoice === 'YYY/SAFE') {
        setdeliveryType(`Safe place location – ${shipmentDetails.secureLocation}`);
        setcurrentdeliveryTypeDropDownValue({ name: 'Safe place location', value: 'Safe place location' });
      } else if (_deliveryChoice === 'YYY') {
        setdeliveryType('Leave with neighbour');
        setcurrentdeliveryTypeDropDownValue({ name: 'Leave with neighbor', value: 'Leave with neighbor' });
      } else if (_deliveryChoice === 'NNN/SP') {
        setdeliveryType('Deliver to Service Point');
        setcurrentdeliveryTypeDropDownValue({
          name: 'Deliver to local ServicePoint',
          value: 'Deliver to local service point',
        });
      } else if (_deliveryChoice === 'NNN/Depot') {
        setdeliveryType('Deliver to local depot');
        setcurrentdeliveryTypeDropDownValue({
          name: 'Deliver to local DHL eCommerce UK depot',
          value: 'Deliver to local DHL eCommerce UK depot',
        });
      } else if (_deliveryChoice === 'NYN') {
        setdeliveryType('Deliver to doorstep only');
        setcurrentdeliveryTypeDropDownValue({ name: 'Select', value: '' });
      }
  }, [shipmentDetails]);

  useEffect(() => {
    props.onDeliveryTypeChange(currentdeliveryTypeDropDownValue);
  }, [currentdeliveryTypeDropDownValue]);

  const getAddressLayout = (addressType) => {
    return (
      <>
        {((shipmentDetails || {})[addressType] || {}).companyName
          ? `${((shipmentDetails || {})[addressType] || {}).companyName} `
          : `${((shipmentDetails || {})[addressType] || {}).name}`}
        <br />
        {`${((shipmentDetails || {})[addressType] || {}).address1
          ? `${((shipmentDetails || {})[addressType] || {}).address1},`
          : ''
          } `}
        {((shipmentDetails || {})[addressType] || {}).address2
          ? `${((shipmentDetails || {})[addressType] || {}).address2}, `
          : ''}
        <br />
        {((shipmentDetails || {})[addressType] || {}).address3
          ? `${((shipmentDetails || {})[addressType] || {}).address3}, `
          : ''}
        {((shipmentDetails || {})[addressType] || {}).city
          ? `${((shipmentDetails || {})[addressType] || {}).city}, `
          : ''}
        <br />
        {((shipmentDetails || {})[addressType] || {}).state
          ? `${((shipmentDetails || {})[addressType] || {}).state}, `
          : ''}
        {((shipmentDetails || {})[addressType] || {}).country
          ? `${((shipmentDetails || {})[addressType] || {}).country}, `
          : ''}
        <br />
        {((shipmentDetails || {})[addressType] || {}).postalCode}
      </>
    );
  };
  
  return (
    <Grid>
      <Tabs value={tabIndex} onChange={handleTabChange}>
        <Tab label="Shipment details" />
        <Tab label="Addresses" />
      </Tabs>
      <Grid style={{ marginTop: '20px' }}>
        <TabPanel value={tabIndex} index={0} style={{ width: '100%' }}>
          <Grid className="T-Shipment-details" container direction="column" spacing={2}>
            <Grid className="list-items" item>
              <Grid item className="sub-header">
                Account number
              </Grid>
              <Grid item className="sub-content">
                {(shipmentDetails || {}).accountNumber}
              </Grid>
            </Grid>
            <Grid className="list-items" item>
              <Grid item className="sub-header">
                Shipment number
              </Grid>
              <Grid item className="sub-content">
                {(shipmentDetails || {}).shipmentNumber}
              </Grid>
            </Grid>
            {(shipmentDetails || {}).customerReference
? (
              <Grid className="list-items" item>
                <Grid item className="sub-header">
                  Customer reference
                </Grid>
                <Grid item className="sub-content">
                  {' '}
                  {(shipmentDetails || {}).customerReference}
                </Grid>
              </Grid>
            )
: null}

            <Grid className="items-section list-items" item>
              <Grid container direction="row" justify="space-between">
                <Grid item xs={4}>
                  <Grid container direction="column" spacing={1}>
                    <Grid item className="sub-header">
                      No of items
                    </Grid>
                    <Grid item className="sub-content">
                      {(shipmentDetails || {}).numberofItems}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container direction="column" spacing={1}>
                    <Grid item className="sub-header">
                      Weight
                    </Grid>
                    <Grid item className="sub-content">
                      {(shipmentDetails || {}).totalWeight} Kgs
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <InputButton
                    label="Shipment dimensions"
                    endIcon={<ArrowForward />}
                    onClick={() => handleDialog(true)}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Divider />
            <Grid className="list-items" item>
              <Grid item className="sub-header">
                Parcel handover method
              </Grid>
              <Grid item className="sub-content">
                {' '}
                {(shipmentDetails || {}).parcelHandoverMethod &&
                  (shipmentDetails || {}).parcelHandoverMethod.toLowerCase() === 'pickup'
                  ? 'Driver collection'
                  : 'Drop off at ServicePoint'}
              </Grid>
            </Grid>

            <Divider />
            <Grid className="list-items" item>
              <Grid item className="sub-header">
                Delivery type
              </Grid>
              <Grid item className="sub-content">
                {' '}
                {/* {(shipmentDetails || {}).deliveryType} */}
                {deliveryType}
              </Grid>
            </Grid>
            <Grid className="list-items" item>
              <Grid item className="sub-header">
                Service type
              </Grid>
              <Grid item className="sub-content">
                {' '}
                {(shipmentDetails || {}).serviceType}
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tabIndex} index={1} style={{ width: '100%' }}>
          <Grid className="T-Shipment-details" container direction="column" spacing={4}>
            <Grid className="list-items" item>
              <Grid container direction="column" spacing={1}>
                <Grid item className="sub-header">
                  Delivery address
                </Grid>
                <Grid item className="sub-content">
                  {getAddressLayout('consigneeAddress')}
                </Grid>
              </Grid>
            </Grid>

            {shipmentDetails.deliveryType === 'ServicePoint'
? (
              <>
                <Divider />
                <Grid className="list-items" item>
                  <Grid container direction="column" spacing={1}>
                    <Grid item className="sub-header">
                      Recipient address
                    </Grid>
                    <Grid item className="sub-content">
                      {getAddressLayout('recipientAddress')}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )
: null}
            <Divider />
            <Grid className="list-items" item>
              <Grid container direction="column" spacing={1}>
                <Grid item className="sub-header">
                  {shipmentDetails.parcelHandoverMethod === 'PICKUP'
                    ? "Collection address"
                    : "Sender address"
                  }
                </Grid>
                <Grid item className="sub-content">
                  {shipmentDetails.parcelHandoverMethod === 'PICKUP'
                    ? getAddressLayout('pickupAddress')
                    : getAddressLayout('senderAdddress')}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
        <CustomDialog
          className="shipment-dimention-dialog"
          open={dialogOpen}
          title="Shipment dimensions details"
          onClose={() => handleDialog(false)}
        >
          <DimensionDetails
            shipmentDimensions={(shipmentDetails || {}).shipmentDimension}
            footerValue="21.08"
            footerLabel="voulumetric weight"
            shipmentDetails={shipmentDetails}
          />
        </CustomDialog>
      </Grid>
    </Grid>
  );
};

ShipmentDetails.propTypes = {};

export default ShipmentDetails;
