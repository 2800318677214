import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle, Grid, makeStyles, IconButton } from '@material-ui/core';
import { yellow } from '@material-ui/core/colors';
import ClearIcon from '@material-ui/icons/Clear';
import './customDialog.scss';

const useStyles = makeStyles({
  dialog: {
    backgroundColor: '#FFCC00F2',
    minHeight: 200,
  },
  Paper: {
    maxWidth: 1000,
  },
});

const CustomDialog = (props) => {
  const classes = useStyles();
  const { onClose, selectedValue, open, title, maxWidth } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog
      onClose={handleClose}
      disableBackdropClick
      fullWidth={true}
      maxWidth={maxWidth}
      aria-labelledby="simple-dialog-title"
      BackdropProps={{ style: { backgroundColor: 'rgba(255,203,0,1)', opacity: 0.95 } }}
      open={open}
      className={`DialogContainer ${props.className}`}
    >
      <DialogTitle id="simple-dialog-title">
        <Grid container spacing={3} direction="row" justify="space-between" alignItems="flex-start">
          <Grid item className="dialogTitle">
            {title}
          </Grid>
          <Grid item>
            <IconButton onClick={handleClose}>
              <ClearIcon className="clearButton" />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className="dialogContent">{props.children}</DialogContent>
    </Dialog>
  );
};

CustomDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  children: PropTypes.element,
  title: PropTypes.string,
};
CustomDialog.defaultProps = {
  maxWidth: 'md',
  title: 'Service Point',
};

export default CustomDialog;
