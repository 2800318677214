import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import DynamicMaterialTable from '../../framework/DynamicMaterialTable/DynamicMaterialTable';
import InputButton from '../../framework/Inputs/inputButton/inputButton';
import { useSelector } from 'react-redux';
import CustomDialog from '../../framework/dialog/customDialog';
import CreateNewUser from '../../components/userManagement/createNewUser/createNewUser';
import CreateNewRetailUser from '../../components/userManagement/createNewRetailUser/createNewRetailUser';

export default function UserList(props) {
  const {
    rows,
    columns,
    isSearchable,
    isSelectable,
    sorting,
    paging,
    pageSize,
    pageSizeOptions,
    isUsers,
    currentAccount,
  } = props;

  const [open, setOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedAccountRows, setSelectedAccountRows] = useState([]);
  const addDefaultAccountToAllUsers = useSelector((state) => state.addNewAccountToUsers.addNewAccountToUsers);

  const addSelectedRows = (selRows, index) => {
    if (isUsers) {
      setSelectedRows(selRows);
    } else if (currentAccount !== '0' && index) {
      setSelectedAccountRows(selRows);
      props.checkedUserToAccount(index.id, false);
    } else {
      props.checkedUserToAccount('', true);
    }
  };
  useEffect(() => {
    setSelectedAccountRows([]);
  }, [currentAccount]);

  useEffect(()=> {
    setTimeout(() => {
      props.setFieldValue('setupSearchFor','');
      if(props.setupSearchForFlag)
       {
         props.getuserListData();
         props.setSetupSearchForFlag();
       } 
    }, 0);  
  },[props.formikValues.setupSearchBy.value])

  const addAccountToAllUsers = () => {
    props.addAccountToAllUsers(currentAccount);
  };

  const addUserToAccount = () => {
    props.addToAccount(selectedAccountRows, currentAccount);
  };

  const openCreateUserModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };
  return (
    <Grid container justify="flex-end" alignItems="flex-start">
      <Grid item lg={12} md={12} xs={12} className="Table-column-color">
        <DynamicMaterialTable
          rows={rows}
          columns={columns}
          isSelectable={isSelectable}
          isSearchable={isSearchable}
          sorting={sorting}
          paging={paging}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
          onSelectionChange={addSelectedRows}
          {...props}
        />
      </Grid>
      {isUsers
        ? [
          <Grid container justify="flex-end" alignItems="flex-end" style={{ paddingTop: '10px' }}>
            <Grid>
              <InputButton
                isDisabled={selectedRows.length === 0 || selectedRows.length > 1}
                className="outlined-btn Clone-user"
                label="Clone user"
                onClick={openCreateUserModal}
              // onClick={() => setShowConfirmationDialog(true)}
              />
            </Grid>
          </Grid>,
        ]
        : [
          <Grid container spacing={2} justify="flex-end" alignItems="flex-end" style={{ paddingTop: '10px' }}>
            {!addDefaultAccountToAllUsers && (
              <Grid item className="AddressBook-Delete">
                <InputButton
                  className="outlined-btn Add-Account-to-all-users"
                  isDisabled={currentAccount === '0'}
                  label="Add account to all users"
                  variant="outlined"
                  onClick={() => addAccountToAllUsers()}
                />
              </Grid>
            )}
            <Grid item className="AddressBook-Delete">
              <InputButton
                className="outlined-btn Add-to-Account"
                isDisabled={selectedAccountRows.length === 0}
                label="Add to account"
                variant="outlined"
                onClick={() => addUserToAccount()}
              />
            </Grid>
          </Grid>,
        ]}
      {props.userDetailsRole?.toLowerCase() === 'superuser' ? <CustomDialog
        maxWidth="sm"
        open={open}
        onClose={handleCloseModal}
        className="commodityDetails-popup"
        title="Create new user"
      >
        <CreateNewUser {...props} row={selectedRows} handleButtonClick={handleCloseModal} />
      </CustomDialog>
        :
        <CustomDialog
          maxWidth="sm"
          open={open}
          onClose={handleCloseModal}
          className="cloneUserPopups"
          title="Create new user"
        >
          <CreateNewRetailUser {...props} row={selectedRows} handleButtonClick={handleCloseModal} />
        </CustomDialog>}
    </Grid>
  );
}

UserList.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  isSearchable: PropTypes.bool,
  isSelectable: PropTypes.bool,
  sorting: PropTypes.bool,
  paging: PropTypes.bool,
  pageSize: PropTypes.number,
  isUsers: PropTypes.bool,
  addToAccount: PropTypes.func,
  addAccountToAllUsers: PropTypes.func,
  checkedUserToAccount: PropTypes.func,
  hideTotalCount: PropTypes.bool,
};
