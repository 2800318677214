import React from 'react';
import { Grid } from '@material-ui/core';
import CustomCaption from './CustomCaption';

const AdditionalInfoSummary = (props) => {
  const { additionalInfo, destinationCountry } = props;

  return (
    <>
      <Grid container direction="row" justify="flex-end" alignItems="flex-start" lg={12}>
        <Grid container xs={12} sm={12} lg={6}>
          <Grid item lg={6}>
            <CustomCaption
              title="Customer reference"
              value={(additionalInfo || {}).customerReference !== null ? (additionalInfo || {}).customerReference : ''}
            />
          </Grid>
          <Grid item lg={6}>
            <CustomCaption
              title="Alternative reference"
              value={
                (additionalInfo || {}).alternativeReference !== null ? (additionalInfo || {}).alternativeReference : ''
              }
            />
          </Grid>
        </Grid>
        <Grid container xs={12} sm={12} lg={6}>
          <Grid item lg={4}>
            <CustomCaption
              title="Extended liability"
              value={
                destinationCountry?.toLowerCase() === 'united kingdom'
                  ? ((additionalInfo || {}).extendedCover || {}).extendedLiabilityUnits ?? '-'
                  : ((additionalInfo || {}).extendedCover || {}).extendedLiabilityRequired ? "Yes" : "No"
              }
            />
          </Grid>
          <Grid item lg={4}>
            {/* <ReadOnlyCheckbox label="Email" checked={state} /> */}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AdditionalInfoSummary;
