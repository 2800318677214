import { Grid, IconButton } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import DynamicMaterialTable from '../../../../framework/DynamicMaterialTable/DynamicMaterialTable';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MaterialTable from "material-table";
import './createNewSchema.scss'

const SchemaMaterialTable = (props) => {
  const { formikValues, dragFromValues, dragToValues, isSelectable, leftSideColumns, rightSideColumns, setDragToValues, setDragFromValues, setCurrentValueStartLength, setCurrentValue, isDefaultSchema ,isOutputFile} = props;

  const [selectedRows, setSelectedRows] = useState([]);
  const [importRows, setImportRows] = useState([]);
  const [isImportIconDisabled, setImportIconDisabled] = useState(true);
  const [isExportIconDisabled, setExportIconDisabled] = useState(true);


  const addSelectedRows = (selectedValues) => {

    //  selectedRows.tableData.checked= true;
    setSelectedRows(selectedValues);

  };
  const addSelectedFiles = (files) => {

    setImportRows(files)
  }
  const handleImport = () => {
    selectedRows.map((row) => {
      row.startsAt = '';
      row.length = '';
      row.columnFromFile = '';
      row.tableData.checked = false
    })
    props.removeFromfields(selectedRows)
    setSelectedRows([]);
    if(!isOutputFile){
    setCurrentValueStartLength([0, 0]);
    setCurrentValue("");
    }
  }
  const handleExport = () => {
    importRows.map((row) => {
      row.startsAt = '';
      row.length = '';
      row.columnFromFile = '';
      row.tableData.checked = false
    })
    props.removeFromImportFiles(importRows)
    setImportRows([]);
    if(!isOutputFile){
    setCurrentValueStartLength([0, 0]);
    setCurrentValue("");
    }
  }


  useEffect(() => {
    if (selectedRows.length > 0) {
      setImportIconDisabled(false)
    } else {
      setImportIconDisabled(true)
    }

    if (importRows.length > 0) {
      setExportIconDisabled(false)
    } else {
      setExportIconDisabled(true)
    }


  }, [selectedRows, importRows, formikValues])


  // useEffect(() => {
  //   dragFromValues.forEach((fromValue)=>{
  //    const data= (fromValue.name.toLowerCase())[0].toUpperCase() + (fromValue.name.toLowerCase()).substr(1)
  //    const objIndex =dragFromValues.findIndex((obj => obj.id == fromValue.id));
  //    dragFromValues[objIndex].name=data
  //   })
  // },[dragFromValues])
  // console.log(dragToValues)
  return (
    <Grid container className="SchemaMaterialTable">
      <Grid item xs={12} sm={12} md={5} lg={5} className="Table-column-color">

        <DynamicMaterialTable rows={dragFromValues} columns={leftSideColumns} onSelectionChange={addSelectedRows} paging={false} setRows={setDragToValues} />
      </Grid>
      <Grid item xs={12} sm={12} md={2} lg={2}>
        <div className="arrow-btn-grid">
          <IconButton variant="outlined" size="large" color="secondary" className="ArrowButton" onClick={handleImport} disabled={isImportIconDisabled || isDefaultSchema}>
            <ArrowForwardIcon fontSize="inherit" />
          </IconButton>
          <IconButton variant="outlined" size="large" color="secondary" className="ArrowButton" onClick={handleExport} disabled={isExportIconDisabled || isDefaultSchema}>
            <ArrowBackIcon fontSize="inherit" />
          </IconButton>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={5} className="Table-column-color schema-rightTable">
        <DynamicMaterialTable rows={dragToValues} columns={rightSideColumns} onSelectionChange={addSelectedFiles} paging={false} setRows={setDragFromValues} />
      </Grid>
    </Grid>
  );
};

export default SchemaMaterialTable;
