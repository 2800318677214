import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../../../store/actions/index';
import { getYupSchemaFromMetaData } from '../../../../../utils/yupSchema/yupSchemaGenerator';
import CustomAccordion from '../../../../../framework/accordion/customAccordion';
import ParcelDetails from '../../../../../container/shipment/addShipment/parcelDetails/parcelDetails';
import ReturnOptions from '../../../../../container/shipment/addShipment/returnOptions/returnOptions';
import CommodityDetails from '../../../../../container/shipment/addShipment/customsDeclaration/commodityDetails/commodityDetails';
import internationDefaultsForm from '../../../../../utils/formConfig/configuration/orderFileDefaultsForm/internationalDefaultsForm';
import {
  formatParcelDetails,
  getParsedFloatValues,
  getCommodityDetails,
  setValuesToParcelDetails,
  setCommodityDetails,
  removeUndefinedProperties,
  setDropdownValues,
  getCustomerAccountID,
  getDomesticAccounts,
  getInternationalAccounts,
  getDriverCollectionAccounts,
} from '../../../../../utils/helperFunctions';
import InputButton from '../../../../../framework/Inputs/inputButton/inputButton';
import { PATCH } from '../../../../../api/axios';
import { ServiceEndPoints } from '../../../../../utils/constants/ApiConstant';
import CustomModal from '../../../../../framework/modal/modal';
import ConfirmationDialog from '../../../../addressBook/confirmation/confirmation';

const { formField } = internationDefaultsForm;

const InternationalDefaults = (props) => {
  const { internationalDefaultValues, countries, getOrderDefaults, selectedAccount } = props;
  const [showReturnLabel, setReturnLabel] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [orderFileDetailsValidationSchema, setorderFileDetailsValidationSchema] = useState(
    getYupSchemaFromMetaData(formField, [], []),
  );
  const formikRef = useRef(null);
  const initialValues = {};
  const userAccounts = useSelector((state) => state?.accounts?.userAccounts ?? []);
  const inboxReturnLabelValue = useSelector(
    (state) => state?.common?.commonConfiguration?.defaultPrintInboxReturnLabel,
  );
  const dispatch = useDispatch();
  const reqFields = ['destinationCountry', 'weight', 'length', 'width', 'height', 'inboxReturnLabel', 'returnReference', 'noOfItems', 'commodityDetails', 'subTotal', 'totalValue']
  for (let property in formField) {
    if (Object.prototype.hasOwnProperty.call(formField, property)) {
      const field = formField[property];
      if (field.props.type === 'checkbox' || field.props.type === 'radio') {
        initialValues[field.props.name] = field.value;
      } else if (field.props.type === 'array') {
        initialValues[field.props.name] = field.values;
      } else {
        initialValues[field.props.name] = field.value || '';
      }
    }
  }

  useEffect(() => {
    if (getDriverCollectionAccounts(getInternationalAccounts(userAccounts)).length > 0) {
      setReturnLabel(true);
    } else {
      setReturnLabel(false);
    }
  }, [userAccounts]);

  useEffect(() => {
    if (internationalDefaultValues) {
      const parcelDimensions = (internationalDefaultValues?.parcelDetails?.parcelDimensions || [])[0];
      internationalDefaultValues['destinationCountry'] = 'DER';
      const defaultValues = {
        ...setValuesToParcelDetails(internationalDefaultValues , null , true),
        commodityDetails: setCommodityDetails(
          internationalDefaultValues?.customDeclaration?.customItemDetails,
          countries,
        ),
        inboxReturnLabel: internationalDefaultValues?.includeReturnLabel?.toString() ?? null,
        returnReference: internationalDefaultValues.returnreference,
        length: parcelDimensions?.length || '',
        width: parcelDimensions?.width || '',
        height: parcelDimensions?.height || '',
      };
      removeUndefinedProperties(defaultValues);
      formikRef.current.setValues({ ...formikRef.current.values, ...defaultValues });
    }
  }, [internationalDefaultValues]);

  // const isFormInValid = (errors) => {
  //   let clonedReqFields = [...reqFields];
  //   // clonedReqFields.forEach(field => {
  //   //   formikRef.current.setFieldTouched(field)
  //   // });
  //   for (let err in errors) {
  //     if (clonedReqFields.some((fieldName) => fieldName === err)) {
  //       return true;
  //     }
  //   }
  //   return false;
  // };

  const handleSubmit = async (formikValues, errors) => {
    if (Object.keys(await formikRef.current.validateForm(formikValues)).length === 0) {
      const reqObj = {
        parcelDetails: formatParcelDetails(formikValues, false, true),
        customDeclaration: {
          // shippingCharges: 0,
          customItemDetails: getCommodityDetails(formikValues.commodityDetails),
          subTotal: Number(formikValues.subTotal),
          shippingCost: getParsedFloatValues(formikValues.customDeclarationShippingCharges),
          totalValue: Number(formikValues.totalValue),
        },
        returnreference: formikValues.returnReference,
        includeReturnLabel: formikValues.inboxReturnLabel === undefined ? inboxReturnLabelValue : formikValues.inboxReturnLabel === 'true',
      };
      removeUndefinedProperties(reqObj);
      PATCH(`${ServiceEndPoints.getOrderDefaults}/international?organization=${getCustomerAccountID()}`, reqObj)
        .then((res) => {
          dispatch(actions.setAppSnackBarData({ msg: "International order file defaults saved successfully" }))
          getOrderDefaults();
        })
        .catch((err) => {
          console.log(err);
        });
    }
    else {
      dispatch(actions.setBottomAlert({
        msgs: [
          {
            type: 'error',
            msg: 'The form has errors, please correct and try again',
          },
        ]
      }));
    }

  };

  const handleDialogClose = () => {
    setShowConfirmationDialog(false);
  };

  const resetForm = () => {
    formikRef?.current?.resetForm();
    handleDialogClose();
  };

  const updateValidationSchema = () => {
    setorderFileDetailsValidationSchema(getYupSchemaFromMetaData(formField, [], []));
  };

  return (
    <Grid container spacing={2} direction="column">
      <Formik
        initialValues={initialValues}
        validationSchema={orderFileDetailsValidationSchema}
        enableReinitialize={true}
        innerRef={formikRef}
        validateOnMount={true}
      >
        {({ values, setFieldValue, setFieldTouched, errors }) => {
          return (
            <Grid>
              <CustomAccordion heading="Parcel details">
                <ParcelDetails
                  formField={formField}
                  formikValues={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  isDomestic={false}
                  setFieldTouched={setFieldTouched}
                  isOrderDefaults={true}
                  updateValidationSchema={updateValidationSchema}
                />
              </CustomAccordion>
              <CustomAccordion heading="Customs declaration" className="returnOptions-accordion">
                <Grid container direction="column" spacing={3} style={{ flexFlow: 'wrap' }}>
                  <Grid item xs={12}>
                    <CommodityDetails
                      formField={formField}
                      formikValues={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      isOrderDefaults={true}
                    />
                  </Grid>
                </Grid>
              </CustomAccordion>

              {showReturnLabel ? (
                <CustomAccordion heading="Return option" className="returnOptions-accordion">
                  <ReturnOptions isOrderFileDefault={true} formField={formField} formikValues={values} setFieldValue={setFieldValue} />
                </CustomAccordion>
              ) : null}
              <Grid container className="international-btn-container" direction="row" justify="flex-end" spacing={3}>
                <Grid item>
                  <InputButton
                    variant="outlined"
                    className="outlined-btn"
                    onClick={() => setShowConfirmationDialog(true)}
                    label="Reset"
                  />
                </Grid>
                <Grid item>
                  <InputButton
                    variant="contained"
                    className="contained-btn"
                    onClick={() => handleSubmit(values, errors)}
                    label="Save changes"
                    buttonType="submit"
                  />
                </Grid>
              </Grid>
            </Grid>
          );
        }}
      </Formik>

      <Grid item></Grid>
      <CustomModal open={showConfirmationDialog} title="Reset Form" onClose={handleDialogClose} maxWidth="sm">
        <ConfirmationDialog
          confirmationMessage="Are you sure you want to reset?"
          onConfirm={resetForm}
          OnCancel={handleDialogClose}
        />
      </CustomModal>
    </Grid>
  );
};

InternationalDefaults.propTypes = {};

export default InternationalDefaults;
