// import { UK_POSTAL_CODE_REGEX } from '../../constants/constants';
import { testFunctionForPostCodeRegex } from '../../helperFunctions';

const carriageForwardForm = {
  formId: 'carriageForwardForm',
  formField: {
    accountNumber: {
      props: {
        name: 'accountNumber',
        label: 'Account number',
        type: 'radio',
        isRequired: true,
      },
      value: '',
      validation: {
        validationType: 'object',
        validations: [
          {
            type: 'required',
            params: ['Account name is required'],
          },
        ],
      },
    },
    collectionDate: {
      props: {
        name: 'collectionDate',
        label: 'Collection date',
        type: 'date',
        isRequired: true,
      },
      value: '',
    },
    fromAddressType: {
      props: {
        name: 'fromAddressType',
        label: 'Address type:',
        type: 'radio',
      },
      value: 'residential',
    },
    fromWeight: {
      props: {
        name: 'fromWeight',
        label: 'Weight (Kg)',
        type: 'number',
        isRequired: true,
        placeholder: 'Select',
        inputProps: {
          min: 1,
          max: 999,
        },
      },
      validation: {
        validationType: 'number',
        validations: [
          {
            type: 'required',
            params: ['Weight field is required'],
          },
          {
            type: 'min',
            params: [1, 'Weight is outside permitted range'],
          },
          {
            type: 'max',
            params: [999, 'Weight is outside permitted range'],
          },
        ],
      },
      value: 0,
    },

    fromParcel: {
      props: {
        name: 'fromParcel',
        label: 'No. of parcels',
        type: 'number',
        placeholder: 'Select',
        isRequired: true,
        inputProps: {
          min: 1,
          max: 20,
        },
        restrictDecimal: true
      },
      validation: {
        validationType: 'number',
        validations: [
          {
            type: 'required',
            params: ['Number of Items is required.'],
          },
          {
            type: 'min',
            params: [1, 'Must be a value between 1 to 20'],
          },
          {
            type: 'max',
            params: [20, 'Must be a value between 1 to 20'],
          },
        ],
      },
      value: 1,
    },

    fromSearchFor: {
      props: {
        name: 'fromSearchFor',
        type: 'searchControl',
      },
      selectProps: {
        props: {
          name: 'fromSearchBy',
          label: 'Search by',
        },
      },
      textProps: {
        props: {
          name: 'fromSearchFor',
          label: 'Search For',
        },
      },
    },
    fromCustomerNo: {
      props: {
        name: 'fromCustomerNo',
        label: 'Customer no.',
        type: 'text',
      },
    },
    fromPostCode: {
      props: {
        name: 'fromPostCode',
        label: 'Postal code',
        type: 'text',
        isRequired: true,
        inputProps: {
          maxLength: 15,
        },
      },
      validation: {
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Postal code is required'],
          },
          {
            type: 'min',
            params: [3, 'Minimum of 3 characters are required'],
          },
          {
            type: 'max',
            params: [15, 'Maximum 15 characters are allowed'],
          },
          {
            type: 'when',
            params: [
              'deliveryType',
              (deliveryType, schema) => {
                return testFunctionForPostCodeRegex(schema, 'Invalid postal code', '', true);
              },
            ],
          },
        ],
      },
    },
    fromContactName: {
      props: {
        name: 'fromContactName',
        label: 'Contact name',
        isRequired: true,
        type: 'text',
        inputProps: {
          maxLength: 35,
        },
      },
      validation: {
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Contact name is required'],
          },
          {
            type: 'max',
            params: [35, 'Maximum of 35 characters allowed'],
          },
        ],
      },
    },
    fromBusinessName: {
      props: {
        name: 'fromBusinessName',
        label: 'Business name',
        type: 'text',
        isRequired: true,
        inputProps: {
          maxLength: 35,
        },
      },
      validation: {
        validationType: 'string',
        validations: [
          {
            type: 'when',
            params: [
              'fromAddressType',
              (fromAddressType, schema) => {
                return fromAddressType === 'business'
                  ? schema.required('Business name is required')
                  : schema.notRequired();
              },
            ],
          },
          {
            type: 'max',
            params: [35, 'Maximum 35 characters are allowed'],
          },
        ],
      },
    },
    fromAddressLine1: {
      props: {
        name: 'fromAddressLine1',
        label: 'Address line 1',
        type: 'text',
        isRequired: true,
        inputProps: {
          maxLength: 35,
        },
      },
      validation: {
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Address is required'],
          },
          {
            type: 'max',
            params: [35, 'Maximum 35 characters are allowed'],
          },
        ],
      },
    },
    fromAddressLine2: {
      props: {
        name: 'fromAddressLine2',
        label: 'Address line 2',
        type: 'text',
        inputProps: {
          maxLength: 35,
        },
      },
      validation: {
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [35, 'Maximum 35 characters are allowed'],
          },
        ],
      },
    },
    fromAddressLine3: {
      props: {
        name: 'fromAddressLine3',
        label: 'Address line 3',
        type: 'text',
        inputProps: {
          maxLength: 35,
        },
      },
      validation: {
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [35, 'Maximum 35 characters are allowed'],
          },
        ],
      },
    },
    fromTown: {
      props: {
        name: 'fromTown',
        label: 'Town',
        type: 'text',
        isRequired: true,
        inputProps: {
          maxLength: 35,
        },
      },
      validation: {
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Town is required'],
          },
          {
            type: 'max',
            params: [35, 'Maximum 35 characters are allowed'],
          },
        ],
      },
    },
    fromCounty: {
      props: {
        name: 'fromCounty',
        label: 'County',
        type: 'text',
        inputProps: {
          maxLength: 35,
        },
      },
      validation: {
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [35, 'Maximum 35 characters are allowed'],
          },
        ],
      },
    },
    fromTelephoneNumber: {
      props: {
        name: 'fromTelephoneNumber',
        label: 'Telephone number',
        isRequired: true,
        type: 'text',
        inputProps: {
          maxLength: 35,
        },
      },
      validation: {
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Telephone number is required'],
          },
          {
            type: 'min',
            params: [10, 'Invalid Phone Number'],
          },
          {
            type: 'max',
            params: [35, 'Maximum 35 characters are allowed'],
          },
          {
            type: 'matches',
            params: [/^[0-9,(,),+,\-,.,[,\] ]+$/, 'Invalid phone number'],
          },
        ],
      },
    },
    fromEmailAddress: {
      props: {
        name: 'fromEmailAddress',
        label: 'Email address',
        type: 'text',
        isRequired: true,
        inputProps: {
          maxLength: 70,
        },
      },
      validation: {
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Email address is required'],
          },
          {
            type: 'max',
            params: [70, 'Maximum 70 characters are allowed'],
          },
          {
            type: 'email',
            params: ['Invalid email address'],
          },
        ],
      },
    },
    fromCustomerReference: {
      props: {
        name: 'fromCustomerReference',
        label: 'Customer reference',
        type: 'text',
        inputProps: {
          maxLength: 20,
        },
      },
      validation: {
        name: 'fromCustomerReference',
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [20, 'Maximum 20 characters are allowed'],
          },
        ],
      },
    },
    collectionInstructions: {
      props: {
        name: 'collectionInstructions',
        label: 'Collection instructions',
        type: 'text',
        inputProps: {
          maxLength: 60,
        },
      },
      validation: {
        name: 'collectionInstructions',
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [60, 'Maximum 60 characters are allowed'],
          },
        ],
      },
    },
    descriptionOfGoods: {
      props: {
        name: 'descriptionOfGoods',
        label: 'Description of goods to be collected',
        type: 'text',
        inputProps: {
          maxLength: 50,
        },
      },
    },
    fromAddToaddressBook: {
      props: {
        name: 'fromAddToaddressBook',
        label: 'Add to address book',
        type: 'checkbox',
      },
      value: false,
    },
    updateFromAddToaddressBook: {
      props: {
        name: 'updateFromAddToaddressBook',
        label: 'Update address in address book',
        type: 'checkbox',
      },
      value: false,
    },
    fromAddToFavourites: {
      props: {
        name: 'fromAddToFavourites',
        label: 'Add to favourites',
        type: 'checkbox',
      },
      value: false,
    },
    serviceRequired: {
      props: {
        name: 'serviceRequired',
        label: 'Service required',
        type: 'select',
      },
    },
    toAddressType: {
      props: {
        name: 'toAddressType',
        label: 'Address type:',
        type: 'radio',
      },
      value: 'residential',
    },
    toSearchFor: {
      props: {
        name: 'toSearchFor',
        type: 'searchControl',
      },
      selectProps: {
        props: {
          name: 'toSearchBy',
          label: 'Search by',
        },
      },
      textProps: {
        props: {
          name: 'toSearchFor',
          label: 'Search For',
        },
      },
    },
    toCustomerNo: {
      props: {
        name: 'toCustomerNo',
        label: 'Customer no.',
        type: 'text',
      },
      validation: {
        name: 'toCustomerNo',
        validationType: 'string',
        validations: [
          {
            type: 'when',
            params: [
              ['fromCustomerNo'],
              (fromCustomerNo, schema) => {
                if (fromCustomerNo) {
                  return schema.test('', 'Customer no. can not be same', (value) => {
                    return value?.trim() !== fromCustomerNo?.trim();
                  });
                } else {
                  return schema;
                }
              },
            ],
          },
        ],
      },
    },
    toPostCode: {
      props: {
        name: 'toPostCode',
        label: 'Postal code',
        type: 'text',
        isRequired: true,
        inputProps: {
          maxLength: 15,
        },
      },
      validation: {
        validationType: 'string',
        validations: [
          // {
          //   type: 'matches',
          //   params: [UK_POSTAL_CODE_REGEX, 'Invalid postal code'],
          // },
          {
            type: 'when',
            params: [
              'returnType',
              (returnType, schema) => {
                return returnType === 'sendToThirdParty'
                  ? schema.required('Postal code is required')
                  : schema.notRequired();
              },
            ],
          },
          {
            type: 'min',
            params: [3, 'Minimum of 3 characters are required'],
          },
          {
            type: 'max',
            params: [15, 'Maximum 15 characters are allowed'],
          },
          {
            type: 'when',
            params: [
              'returnType',
              (returnType, schema) => {
                if (returnType === 'sendToThirdParty') {
                  return testFunctionForPostCodeRegex(schema, 'Invalid postal code', '', true);
                }
              },
            ],
          },
        ],
      },
    },
    toContactName: {
      props: {
        name: 'toContactName',
        label: 'Contact name',
        // placeholder: 'Contact 1',
        type: 'text',
        isRequired: true,
        inputProps: {
          maxLength: 35,
        },
      },
      validation: {
        name: 'toContactName',
        validationType: 'string',
        validations: [
          {
            type: 'when',
            params: [
              'returnType',
              (returnType, schema) => {
                return returnType === 'sendToThirdParty'
                  ? schema.required('Contact name is required')
                  : schema.notRequired();
              },
            ],
          },
          {
            type: 'max',
            params: [35, 'Maximum 35 characters are allowed'],
          },
        ],
      },
    },
    toBusinessName: {
      props: {
        name: 'toBusinessName',
        label: 'Business name',
        // placeholder: 'Business 1',
        type: 'text',
        isRequired: true,
        inputProps: {
          maxLength: 35,
        },
      },
      validation: {
        name: 'toBusinessName',
        validationType: 'string',
        validations: [
          {
            type: 'when',
            params: [
              ['toAddressType', 'returnType'],
              (toAddressType, returnType, schema) => {
                return toAddressType === 'business' && returnType === 'sendToThirdParty'
                  ? schema.required('Business name is required').max(35, 'Maximum of 35 characters allowed')
                  : schema.notRequired();
              },
            ],
          },
          {
            type: 'max',
            params: [35, 'Maximum 35 characters are allowed'],
          },
          {
            type: 'max',
            params: [35, 'Maximum 35 characters are allowed'],
          },
        ],
      },
    },
    toAddressLine1: {
      props: {
        name: 'toAddressLine1',
        label: 'Address line 1',
        type: 'text',
        isRequired: true,
        inputProps: {
          maxLength: 35,
        },
      },
      validation: {
        name: 'toAddressLine1',
        validationType: 'string',
        validations: [
          {
            type: 'when',
            params: [
              'returnType',
              (returnType, schema) => {
                return returnType === 'sendToThirdParty'
                  ? schema.required('Address is required')
                  : schema.notRequired();
              },
            ],
          },
          {
            type: 'max',
            params: [35, 'Maximum 35 characters are allowed'],
          },
          // {
          //   type: 'max',
          //   params: [100, 'Maximum of 100 characters allowed'],
          // },
        ],
      },
    },
    toAddressLine2: {
      props: {
        name: 'toAddressLine2',
        label: 'Address line 2',
        type: 'text',
        inputProps: {
          maxLength: 35,
        },
      },
      validation: {
        name: 'toAddressLine2',
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [35, 'Maximum of 35 characters allowed'],
          },
        ],
      },
    },
    toAddressLine3: {
      props: {
        name: 'toAddressLine3',
        label: 'Address line 3',
        type: 'text',
        inputProps: {
          maxLength: 35,
        },
      },
      validation: {
        name: 'toAddressLine3',
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [35, 'Maximum of 35 characters allowed'],
          },
        ],
      },
    },
    toTown: {
      props: {
        name: 'toTown',
        label: 'Town',
        type: 'text',
        isRequired: true,
        inputProps: {
          maxLength: 35,
        },
      },
      validation: {
        name: 'toTown',
        validationType: 'string',
        validations: [
          {
            type: 'when',
            params: [
              'returnType',
              (returnType, schema) => {
                return returnType === 'sendToThirdParty' ? schema.required('Town is required') : schema.notRequired();
              },
            ],
          },
          {
            type: 'max',
            params: [35, 'Maximum of 35 characters allowed'],
          },
        ],
      },
    },
    toCounty: {
      props: {
        name: 'toCounty',
        label: 'County',
        type: 'text',
        inputProps: {
          maxLength: 35,
        },
      },
      validation: {
        name: 'toCounty',
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [35, 'Maximum of 35 characters allowed'],
          },
        ],
      },
    },
    toTelephoneNumber: {
      props: {
        name: 'toTelephoneNumber',
        label: 'Telephone number',
        type: 'text',
        isRequired: true,
        inputProps: {
          maxLength: 35,
        },
      },
      validation: {
        name: 'toTelephoneNumber',
        validationType: 'string',
        validations: [
          // {
          //   type: 'required',
          //   params: ['Phone Number is required'],
          // },
          {
            type: 'min',
            params: [10, 'Invalid phone number'],
          },
          {
            type: 'max',
            params: [35, 'Invalid phone number'],
          },
          {
            type: 'matches',
            params: [/^[0-9,(,),+,\-,.,[,\] ]+$/, 'Invalid phone number'],
          },
          {
            type: 'when',
            params: [
              'returnType',
              (returnType, schema) => {
                return returnType === 'sendToThirdParty'
                  ? schema.required('Phone Number is required')
                  : schema.notRequired();
              },
            ],
          },
        ],
      },
    },
    toEmailAddress: {
      props: {
        name: 'toEmailAddress',
        label: 'Email address',
        type: 'text',
        inputProps: {
          maxLength: 70,
        },
      },
      validation: {
        name: 'emailAddress',
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [70, 'Maximum 70 characters are allowed'],
          },
          {
            type: 'email',
            params: ['Invalid email address'],
          },
          {
            type: 'when',
            params: [
              'returnType',
              (returnType, schema) => {
                return returnType === 'sendToThirdParty'
                  ? schema
                  : schema.notRequired();
              },
            ],
          },
        ],
      },
    },
    // toCustomerReference: {
    //   props: {
    //     name: 'toCustomerReference',
    //     label: 'Customer reference',
    //     type: 'text',
    //     inputProps: {
    //       maxLength: 20,
    //     },
    //   },
    //   validation: {
    //     name: 'toCustomerReference',
    //     validationType: 'string',
    //     validations: [
    //       {
    //         type: 'max',
    //         params: [20, 'Maximum of 20 characters allowed'],
    //       },
    //     ],
    //   },
    // },
    deliveryType: {
      props: {
        name: 'deliveryType',
        label: 'Delivery type:',
        type: 'radio',
      },
      value: 'deliverToDoorStep',
      validation: {
        name: 'deliveryType',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Delivery Type is required'],
          },
        ],
      },
    },
    doorStepInstructions: {
      props: {
        name: 'doorStepInstructions',
        label: 'Doorstep Instructions',
        type: 'select',
        displayField: 'name',
        hasDefaultValue: true,
        isRequired: true,
      },
      validation: {
        name: 'doorStepInstructions',
        validationType: 'object',
        validations: [
          {
            type: 'when',
            params: [
              'returnType',
              (returnType, schema) => {
                return returnType === 'sendToThirdParty'
                  ? schema.required('Doorstep Instructions is required')
                  : schema.notRequired();
              },
            ],
          },
        ],
      },
    },
    deliveryServicePoint: {
      props: {
        name: 'deliveryServicePoint',
        label: 'Find ServicePoint',
        type: 'text',
        placeholder: 'Search For ServicePoint',
      },
      validation: {
        name: 'postalCode',
        validationType: 'string',
        validations: [
          {
            type: 'when',
            params: [
              'deliveryType',
              (deliveryType, schema) => {
                return deliveryType === 'deliverToServicePoint'
                  ? schema.required('Postal code is required')
                  : schema.notRequired();
              },
            ],
          },
          {
            type: 'when',
            params: [
              ['deliveryType', 'returnType'],
              (deliveryType, returnType, schema) => {
                if (deliveryType === 'deliverToServicePoint' && returnType === 'sendToThirdParty') {
                  return testFunctionForPostCodeRegex(schema, 'Invalid postal code', '', true);
                }
              },
            ],
          },
        ],
      },
    },
    specialInstructions: {
      props: {
        name: 'specialInstructions',
        label: 'Special instructions',
        type: 'text',
      },
      inputProps: {
        maxLength: 30,
      },
      validation: {
        name: 'specialInstructions',
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [30, 'Maximum 30 characters are allowed'],
          },
        ],
      },
    },
    additionalInstruction: {
      props: {
        name: 'additionalInstruction',
        type: 'text',
        inputProps: {
          maxLength: 30,
        },
      },
      validation: {
        name: 'additionalInstruction',
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [30, 'Maximum 30 characters are allowed'],
          },
        ],
      },
    },
    leaveSafeLocation: {
      props: {
        name: 'leaveSafeLocation',
        label: 'Leave Safe location',
        type: 'text',
        inputProps: {
          maxLength: 60,
        },
      },
      validation: {
        name: 'leaveSafeLocation',
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [60, 'Maximum 60 characters are allowed'],
          },
        ],
      },
    },
    toAddToaddressBook: {
      props: {
        name: 'toAddToaddressBook',
        label: 'Add to address book',
        type: 'checkbox',
      },
      value: false,
    },
    updateToAddToaddressBook: {
      props: {
        name: 'updateToAddToaddressBook',
        label: 'Update address in address book',
        type: 'checkbox',
      },
      value: false,
    },
    toAddToFavourites: {
      props: {
        name: 'toAddToFavourites',
        label: 'Add to my favourites',
        type: 'checkbox',
      },
      value: false,
    },
    specialInstructions: {
      props: {
        name: 'specialInstructions',
        label: 'Special instructions',
        type: 'text',
        inputProps: {
          maxLength: 30,
        },
      },
      // value: 'ENTER',
      validation: {
        name: 'specialInstructions',
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [30, 'Maximum of 30 characters allowed'],
          },
        ],
      },
    },
    toServiceRequired: {
      props: {
        name: 'toServiceRequired',
        label: 'Service',
        type: 'select',
        displayField: 'name',
        isRequired: true,
        hasDefaultValue: true,
      },
      value: 'nextday',
      validation: {
        name: 'toServiceRequired',
        validationType: 'object',
        validations: [
          {
            type: 'required',
            params: ['Service is required'],
          },
        ],
      },
    },
    accounts: {
      props: {
        name: 'accountNumber',
        label: 'Account number',
        type: 'autoComplete',
        displayField: 'displayName',
        hasDefaultValue: true,
      },
      validation: {
        name: 'accountNumber',
        validationType: 'object',
        validations: [
          {
            type: 'required',
            params: ['Account number is required'],
          },
        ],
      },
    },
    returnType: {
      props: {
        name: 'returnType',
        type: 'radio',
      },
      value: 'returnToSender',
    },
    sendLabelEmailAddress: {
      props: {
        name: 'sendLabelEmailAddress',
        label: 'Email address',
        type: 'text',
        isRequired: true,
        inputProps: {
          maxLength: 70,
        },
      },
      validation: {
        name: 'sendLabelEmailAddress',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Email address is required'],
          },
          {
            type: 'max',
            params: [70, 'Maximum 70 characters are allowed'],
          },
          {
            type: 'email',
            params: ['Invalid email address'],
          },
          {
            type: 'matches',
            params: [
              /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              'Invalid email Address',
            ],
          },
        ],
      },
    },
    handOverMethod: {
      props: {
        name: 'handOverMethod',
        label: 'Parcel handover method:',
        type: 'radio',
      },
      validation: {
        name: 'handOverMethod',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Name is required'],
          },
        ],
      },
    },
    servicePoint: {
      props: {
        name: 'servicePoint',
        label: 'Find ServicePoint',
        type: 'text',
        placeholder: 'Enter and Search',
      },
      validation: {
        name: 'servicePoint',
        validationType: 'string',

        validations: [
          {
            type: 'required',
            params: ['ServicePoint  is required'],
          },
          {
            type: 'when',
            params: [
              'handOverMethod',
              (handOverMethod, schema) => {
                if (handOverMethod === 'servicePoint') {
                  return testFunctionForPostCodeRegex(schema, 'Enter a correct postcode', '', true);
                }
                return schema;
              },
            ],
          },
        ],
      },
    },
    what3Words: {
      props: {
        name: 'what3Words',
        label: 'What3Words',
        type: 'text',
      },
    },
    w3WCoordinates: {
      props: {
        name: 'w3WCoordinates',
        type: 'object',
      },
    }, 

  },
};

export default carriageForwardForm;
