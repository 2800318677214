import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Grid, IconButton, SvgIcon, Snackbar } from '@material-ui/core';
import OrderFile from './orderFile';
import CustomModal from '../../../../framework/modal/modal';
import { ReactComponent as EditIcon } from '../../../../assets/images/EditIcon.svg';
import { getYupSchemaFromMetaData } from '../../../../utils/yupSchema/yupSchemaGenerator';
import orderFileMappingForm from '../../../../utils/formConfig/configuration/orderFileMappingForm/orderFileMappingForm';
import { useDispatch, useSelector } from 'react-redux';
import { ServiceEndPoints } from '../../../../utils/constants/ApiConstant';
import AddMapping from './addMapping';
import ConfirmationDialog from '../../../addressBook/confirmation/confirmation';
import InputButton from '../../../../framework/Inputs/inputButton/inputButton';
import { getCustomerAccountID, customTableSort } from '../../../../utils/helperFunctions';
import { axiosConfig } from '../../../../api/axios';
import * as actions from '../../../../store/actions/index';

const OrderFileMapping = (props) => {
  const tableColumns = [
    { title: 'Map from', field: 'mapFrom', width: '30%', customSort: (a, b) => customTableSort(a.mapFrom, b.mapFrom) },
    { title: 'Mapped to', field: 'mappedTo', sorting: false, width: '57%' },
  ];
  let userAccounts = useSelector((state) => state?.accounts?.nonReturnuserAccounts ?? []);

  const { formId, formField } = orderFileMappingForm;
  const orderFileMappingSchema = getYupSchemaFromMetaData(formField, [], []);
  const initialValues = {};

  const [columns, setColumns] = useState(tableColumns);

  const [accounts, setAccounts] = useState([]);
  const [services, setServices] = useState([]);
  const [mappingAccounts, setMappingAccounts] = useState([]);
  const [mappingServices, setMappingServices] = useState([]);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [mappedOrderDefault, setMappedOrderDefault] = React.useState({});
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [isUpdating_Flag, setisUpdating_Flag] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    getInitialValues();
  }, []);

  const handleDialogClose = (setFieldTouched, setFieldValue) => {
    setFieldValue('mapFrom','');
    setFieldTouched('mapFrom',false);
    if (isUpdating_Flag) {
      setisUpdating_Flag(false);
      setShowConfirmationDialog(true);
    } else {
      setIsDialogOpen(false);
    }
  };

  const setDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleConfirmClose = () => {
    setIsDialogOpen(true)
    setShowConfirmationDialog(false);
    setisUpdating_Flag(true);
  };
  const onDialogConfirm = () => {
    setIsDialogOpen(false);
    setShowConfirmationDialog(false);
  };

  const getInitialValues = () => {
    axiosConfig
      .get(`${ServiceEndPoints.getMappedServices}`)
      .then((response) => {
        const output = response.data.map(({ code, longDescription }) => ({ name: longDescription, value: code }));
        setServices(output);
      })
      .catch((err) => {
        setServices([]);
      });

    axiosConfig
      .get(`${ServiceEndPoints.getOrderDefaults}?organization=${getCustomerAccountID()}`)
      .then((response) => {
        setMappedOrderDefault(response.data);
      })
      .catch((err) => {
        setMappedOrderDefault([]);
      });
    const output = userAccounts
    setAccounts(output);
  };
  useEffect(() => {
    if (services && services.length > 0 && mappedOrderDefault && mappedOrderDefault.orderFileMapping) {
      if (
        mappedOrderDefault.orderFileMapping.servicesMapping &&
        mappedOrderDefault.orderFileMapping.servicesMapping.length > 0
      ) {
        let serviceMap = [];
        mappedOrderDefault.orderFileMapping.servicesMapping.map((ser) => {
          var mapToName = services.find((serval) => serval.value === ser.mappedTo);
          if (mapToName !== undefined) {
            const item = {
              mapFrom: ser.mapFrom,
              mappedTo: mapToName.name,
            };
            serviceMap.push(item);
          }
        });
        setMappingServices(serviceMap);
      }
      if (
        mappedOrderDefault.orderFileMapping.accountsMapping &&
        mappedOrderDefault.orderFileMapping.accountsMapping.length > 0
      ) {
        setMappingAccounts(mappedOrderDefault.orderFileMapping.accountsMapping);
      }
    }
  }, [services, mappedOrderDefault.orderFileMapping]);

  const saveMappingDetails = (updatedAccounts, updatedServices) => {
    setIsDialogOpen(false);
    setMappingAccounts(updatedAccounts);
    setMappingServices(updatedServices);
  };

  const saveServiceAccountMapping = () => {
    let accountsMapping = [];
    let servicesMapping = [];
    mappingAccounts.map((updateMap) => {
      const item = {
        mapFrom: updateMap.mapFrom,
        mappedTo: updateMap.mappedTo,
      };
      accountsMapping.push(item);
    });
    mappingServices.map((updateMap) => {
      const item = {
        mapFrom: updateMap.mapFrom,
        mappedTo: services.find((serval) => serval.name === updateMap.mappedTo).value,
      };
      servicesMapping.push(item);
    });

    const objectModel = {
      servicesMapping,
      accountsMapping,
    };

    axiosConfig
      .patch(`${ServiceEndPoints.postOrderMapping}?organization=${getCustomerAccountID()}`, objectModel)
      .then((response) => {
        dispatch(actions.setAppSnackBarData({ msg: 'Order file mapping saved successfully' }));
      })
      .catch((error) => {
        dispatch(
          actions.setBottomAlert({
            msgs: [
              {
                type: 'error',
                msg: 'Mappings not saved in database, please try again.',
              },
            ],
          }),
        );
        // dispatch(actions.setAppSnackBarData({type:"error", msg:'Mappings not saved in database, please try again.'}))
      });
  };
  const _isUpdatingFlag = (flag) => {
    setisUpdating_Flag(flag);
  };
  return (
    <React.Fragment>
      <Formik initialValues={initialValues} validationSchema={orderFileMappingSchema} enableReinitialize={true}>
        {({ values, setFieldValue, setFieldError, setFieldTouched, setErrors, errors, resetForm }) => (
          <Form id={formId}>
            <Grid container xs={12} sm={12} md={12} lg={12}>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <span className="service-title">Services</span>
              </Grid>
              <Grid className="" item xs={6} sm={6} md={6} lg={6} justify="flex-end" style={{ display: 'flex' }}>
                <IconButton className="addedit-btn" onClick={() => setDialogOpen()}>
                  <SvgIcon component={EditIcon} viewBox="0 0 48 48" style={{ fontSize: '2.5rem' }} />
                  <span className="addTitle" style={{ fontSize: '14px' }}>Add/edit</span>
                </IconButton>

              </Grid>
            </Grid>

            <Grid container>
              <CustomModal className="addEdit-popup" open={isDialogOpen} title="Add/edit mapping" onClose={()=>handleDialogClose(setFieldTouched, setFieldValue)}>
                <AddMapping
                  saveNewMappings={saveMappingDetails}
                  _isUpdatingFlag={_isUpdatingFlag}
                  formField={formField}
                  formikValues={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  mappedServices={mappingServices}
                  mappedAccounts={mappingAccounts}
                  defaultAccounts={accounts}
                  defaultServices={services}
                  setErrors={setErrors}
                />
              </CustomModal>
            </Grid>
            <Grid container xs={12} sm={12} md={12} lg={12}>
              <OrderFile
                rows={mappingServices}
                columns={columns}
                isSelectable={false}
                paging={false}
                empty_DataSource_Message="No mappings available"
                title="Remove templates"
              />
            </Grid>
            <Grid container xs={12} sm={12} md={12} lg={12} style={{ paddingTop: '10px' }}>
              <span className="service-title" style={{ paddingBottom: '20px', paddingTop: '20px' }}>Accounts</span>
              <OrderFile
                rows={mappingAccounts}
                columns={columns}
                isSelectable={false}
                paging={false}
                empty_DataSource_Message="No mappings available"
                title="Remove templates"
              />
            </Grid>
            <Grid
              container
              justify="flex-end"
              spacing={2}
              // className="addParcel-popup-buttons"
              className="btn-container"
            >
              <Grid item>
                <InputButton
                  variant="contained"
                  className="contained-btn save"
                  onClick={() => saveServiceAccountMapping()}
                  buttonType="submit"
                  label="Save changes"
                // isDisabled={isAddButtonDisabled}
                />
              </Grid>
            </Grid>
            <Grid container item className="customModal-book">
              <CustomModal
                open={showConfirmationDialog}
                title={'Mapping Changes'}
                onClose={handleConfirmClose}
                maxWidth="sm"

              >
                <ConfirmationDialog
                  confirmationMessage={'Any unsaved changes will be lost?'}
                  onConfirm={onDialogConfirm}
                  OnCancel={handleConfirmClose}
                />
              </CustomModal>
            </Grid>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default OrderFileMapping;
