import React, { useEffect, useState } from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import CustomHeader from '../../../../framework/summaryFormate/CustomHeader';
import AddressFormate from '../../../../framework/addressFormate/AddressFormate';
import InputButton from '../../../../framework/Inputs/inputButton/inputButton';
import ParcelAddShipment from './ParcelAddShipment';
import ProductInternational from './ProductInternational';
import MoreInfoSummary from './MoreInfoSummary';
import CustomsDeclarationSummary from './CustomsDeclarationSummary';
import ReadOnlyCheckbox from '../../../../framework/readOnlyCheckbox/ReadOnlyCheckbox';
import CustomCaption from '../../../../framework/summaryFormate/CustomCaption';

const useStyles = makeStyles({
  boxContainer: {
    '& :last-child': {
      borderBottom: 'unset',
    },
  },
});

const InternationalSummary = ({ shipmentDetails }) => {
  const classes = useStyles();
  let history = useHistory();
  const [state, setstate] = useState(false);

  useEffect(() => {
    setstate((shipmentDetails || {}).addToAddressBook || (shipmentDetails || {}).addToFavCutomerList);
  }, [shipmentDetails]);
  return (
    <React.Fragment>
      <Box boxShadow={3} bgcolor="background.paper" m={1} p={1} className={classes.boxContainer}>
        <CustomHeader heading="Details">
          <Grid container spacing={3} direction="row" lg={6}>
            <AddressFormate
              addressType={shipmentDetails.addressType}
              contactName={shipmentDetails.recipientName}
              businessName={shipmentDetails.businessName}
              addressLine1={shipmentDetails.addressLine1}
              addressLine2={shipmentDetails.addressLine2}
              addressLine3={shipmentDetails.addressLine3}
              town={shipmentDetails.town}
              country={shipmentDetails.county}
              postCode={shipmentDetails.postalCode}
              emailAddress={shipmentDetails.emailAddress}
              telephoneNumber={shipmentDetails.telephone}
            />
          </Grid>
        </CustomHeader>
        <CustomHeader heading="Parcel details">
          <ParcelAddShipment shipmentDetails={shipmentDetails} />
        </CustomHeader>
        <CustomHeader heading="Product">
          <ProductInternational shipmentDetails={shipmentDetails} />
        </CustomHeader>
        <CustomHeader heading="Additional information about your shipment">
          <MoreInfoSummary shipmentDetails={shipmentDetails} />
        </CustomHeader>
        <CustomHeader heading="Customs declaration">
          {shipmentDetails.isFullCustomDetails
            ? (
              <CustomsDeclarationSummary shipmentDetails={shipmentDetails} />
            )
            : (
              <>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3}>
                  <Grid item xs={3}>
                    <CustomCaption title="Description of goods" value={shipmentDetails.descriptionOfGoods} />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomCaption title="Value" value={shipmentDetails.customDeclarationValue} />
                  </Grid>

                  <Grid item xs={3}>
                    <CustomCaption title="Currency" value={(shipmentDetails.customDeclarationCurrency || {}).code} />
                  </Grid>

                  <Grid item xs={3}></Grid>
                </Grid>
              </>
            )}
        </CustomHeader>

        {/* <Typography style={{ paddingLeft: '5px' }}>
          <ReadOnlyCheckbox label="Details are added to Address Book" checked={state} />
        </Typography> */}
      </Box>
      <Grid container className="preview-btn-container" spacing={3} direction="row" justify="flex-end" alignItems="flex-end">
        <Grid item>
          <InputButton variant="contained" className="contained-btn" label="Back" onClick={() => history.push({ pathname: history.location.state.prevPath, state: { ...history.location.state, fromPreview: true } })} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default InternationalSummary;
