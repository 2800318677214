
import * as actionTypes from '../actions/actionsTypes'

import { updateObject } from '../../utils/common';

const initialState = {
    userDetails: null,
};


const saveUserDetails = (state, action) => {
    const sortAlphaNum = (a, b) => a?.accountId?.toLowerCase().localeCompare(b?.accountId?.toLowerCase(), 'en', { numeric: true });
    action.data.accounts.sort(sortAlphaNum);
    return updateObject(state,
        {
            userDetails: action.data
        }
    );
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SAVEUSERDETAILS: return saveUserDetails(state, action);
        default: return state
    }
}

export default reducer;