import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import FormRenderer from '../../../../framework/Inputs/formRenderer/formRenderer';
import { GET } from '../../../../api/axios';
import { ServiceEndPoints } from '../../../../utils/constants/ApiConstant';
import { useHistory } from 'react-router-dom';
import { arrayValuesToQueryStringParam } from '../../../../utils/common';
import { useDispatch, connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import { getDefaultDashboardValues, getDefaultDashboardFindValues } from '../../../../utils/helperFunctions';
import { useSelector } from 'react-redux';

const FindShipment = (props) => {
    const {
        formField: { dashboardSearchBy },
        setFieldValue,
        formikValues,
        searchByOptions,
        errors,
        setFieldTouched,
        resetForm,
        setValues,
        userAccounts
    } = props;
    let history = useHistory();
    const dispatch = useDispatch();
    let dashboardFindShipment = useSelector((state) => state.dashboardTracking.dashboardFindShipment);
    useEffect(() => {
        // need to change hard coded index values
        if (
            formikValues.dashboardSearchBy &&
            (formikValues.dashboardSearchBy.value === searchByOptions[8].value ||
                formikValues.dashboardSearchBy.value === searchByOptions[9].value)
        ) {
            setFieldValue('dashboardSearchFor', new Date().toISOString().slice(0, 10));
        } else {
            setFieldValue('dashboardSearchFor', '');
        }
        if (props.formikValues.dashboardSearchBy) {
            dispatch(
                actions.setDashboardFindShipment(
                    props.formikValues.dashboardSearchBy
                ))
        }
        else if (dashboardFindShipment) {
            dispatch(
                actions.setDashboardFindShipment(
                    dashboardFindShipment
                ))
        } else {
            dispatch(
                actions.setDashboardFindShipment(
                    searchByOptions[0]
                ))
        }

        // dispatch(
        //     actions.setDashboardTracking({
        //         loadedFrom: '',
        //         loadedResponse: {},
        //         userAccounts: _temp_userAccounts,
        //         dashboardQueryParams: `${getDefaultDashboardFindValues(
        //             {},
        //             _temp_userAccounts,
        //             false,
        //         )}`,
        //     }),
        // );
    }, [formikValues.dashboardSearchBy]);

    useEffect(() => {
        if (dashboardFindShipment) {
            setTimeout(() => {
                setFieldValue('dashboardSearchBy', dashboardFindShipment)
            }, 1000);
        } else {
            setFieldValue('dashboardSearchBy', searchByOptions[0])
        }
    }, [dashboardFindShipment]);

    const searchShipment = (event = null) => {
        event.preventDefault();
        let searchedValue = props.formikValues.dashboardSearchFor.trim();
        let searchedParams = props.formikValues.dashboardSearchBy.value;
        let accountsValue = '';
        accountsValue = arrayValuesToQueryStringParam(formikValues.accounts);

        const isCollectionDatesReq = searchedParams === 'dispatchDate' ? true : false;
        if (isCollectionDatesReq) {
            searchedParams = '';
            searchedParams += `fromCollectionDate=${searchedValue}`;
            searchedParams += `&toCollectionDate=${searchedValue}`;
        }
        let url = '';
        let _temp_userAccounts = [];
        userAccounts.map((element) => {
            _temp_userAccounts.push(element.accountId);
        });
        if (isCollectionDatesReq) {
            url = `${ServiceEndPoints.getShipmentDashboard}?${getDefaultDashboardFindValues(
                formikValues,
                _temp_userAccounts,
                true,
                false,
            )}${searchedParams}`;
        } else {
            url = `${ServiceEndPoints.getShipmentDashboard}?${getDefaultDashboardFindValues(
                formikValues,
                _temp_userAccounts,
                false,
                false,
            )}${searchedParams}=${searchedValue}`;
        }
        url = `${url}&offset=0&limit=10`;
        GET(url)
            .then((res) => {
                if ((((res || {}).data || {}).shipmentModels || []).length === 1) {
                    history.push(`/dashboard/Tracking?id=${res.data.shipmentModels[0].shipmentId}`);
                    window.location.reload();
                } else {
                    dispatch(
                        actions.setDashboardTracking({
                            loadedFrom: 'findshipment',
                            loadedResponse: null,
                            userAccounts: _temp_userAccounts,
                            dashboardQueryParams: `${getDefaultDashboardFindValues(
                                {},
                                _temp_userAccounts,
                                false,
                            )}${searchedParams}=${searchedValue}`,
                        }),
                    );
                    history.push('/dashboard/dashboardDetailsBreakdown');
                }
            })
            .catch((err) => {
                alert('Shipment not found');
            });
    };
    return (
        <Grid className="TrackingFindShipment" spacing={3} direction="row" alignItems="flex-start">
            <Grid className="searchControl" item xs={12}>
                <FormRenderer
                    data={searchByOptions}
                    selectProps={dashboardSearchBy.selectProps.props}
                    textProps={dashboardSearchBy.textProps.props}
                    onSearchClick={(event) => searchShipment(event, '')}
                    isIconDisabled={
                        errors.dashboardSearchFor ||
                        formikValues?.dashboardSearchFor === undefined ||
                        formikValues?.dashboardSearchFor?.trim() === ''
                    }
                    {...dashboardSearchBy.props}
                />
            </Grid>
        </Grid>
    )
}

// export default FindShipment;
const mapStateToProps = (state) => {
    return {
        // dashboardDetails: ((state || {}).dashboardTracking || {}).dashboardTracking,
        userAccounts: (((state || {}).auth || {}).userDetails || {}).accounts,
    };
};
export default connect(mapStateToProps, null)(FindShipment);