import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, SvgIcon, Divider } from '@material-ui/core';
import FormRenderer from '../../../framework/Inputs/formRenderer/formRenderer';
import { useSelector, useDispatch } from 'react-redux';
import InputButton from '../../../framework/Inputs/inputButton/inputButton';
import { ReactComponent as InfoIcon } from '../../../assets/images/InfoIcon.svg';
import { axiosConfig, GET, PATCH, POST } from '../../../api/axios';
import CustomModal from '../../../framework/dialog/customDialog';
import ConfirmationDialog from '../../addressBook/confirmation/confirmation';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { userDetailsObj, setDropdownValues, updateUserObj, getCustomerAccountID } from '../../../utils/helperFunctions';
import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';
import * as actions from '../../../store/actions/index';

const CreateNewRetailUser = (props) => {
    const {
        formField: { userName, userId, emailId, role, accounts, isActive },
        getuserListData,
    } = props;
    const isFormValid = (errors) => {
        const { deliveryServicePoint, ...restControls } = errors;
        return Object.keys(restControls).length === 0;
    };
    const { setFieldValue, formikValues, errors, setErrors, setFieldTouched } = props;
    const [roleAccess, setRollAccess] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [accountsData, setAccountsData] = React.useState([]);
    const [roleOptions, setRoleOptions] = React.useState([]);
    const [offset, setOffset] = React.useState(0);
    // const [showConfirmationDialog, setShowConfirmationDialog] = React.useState(false);
    let roleArray = [];

    const showRoleDetails = () => { };
    const dispatch = useDispatch();
    const getRoles = () => {
        GET(ServiceEndPoints.getRolesAccess).then((res) => {
            roleArray.push(res.data.find(r => r.roleName?.toLowerCase() === formikValues?.role?.value?.toLowerCase())?.accessSet || []);
            setRollAccess(roleArray);
        });
    };

    React.useEffect(() => {
        getRoles();
    }, [formikValues.role]);



    const getAccounts = (data, textQueryParam = "", offsetValue = 0) => {
        data.length === 0 && setOffset(0);
        let url = `${ServiceEndPoints.getAccounts}?customerAccountId=${getCustomerAccountID()}&offset=${offsetValue}&limit=50`;
        if (textQueryParam) {
            url += `&text=${textQueryParam}`;
        }
        GET(url).then((res) => {
            let tempArray = data.concat(res.data)
            setAccountsData(tempArray);
        });

    };

    useEffect(() => {
        getAccounts([]);
        if (!props.updateUser)
            setFieldValue('userName', '');
        if (props.userDetailsRole?.toLowerCase() === 'retail superuser') {
            setRoleOptions(roleOptionsRetailer);
        }
        if (props.userDetailsRole?.toLowerCase() === 'national superuser') {
            setRoleOptions(roleOptionsReseller);
        }

    }, []);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (value) => {
        setAnchorEl(null);
    };

    // const handleDialogClose = () => {
    //   setShowConfirmationDialog(false);
    // };

    // const onDialogConfirm = () => {
    //   setShowConfirmationDialog(false);
    // };

    // const onDialogCancel = () => {
    //   setShowConfirmationDialog(false);
    //   setFieldValue('role',roleOptions[0]);
    // };

    function validateEmail(input){
        var validRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(input.match(validRegex))
        {
          return false;
        }
        else
        {
          return true;
        }
      }

    const postData = () => {
        let errors_object = {};

        if (formikValues.userName?.trim() === '') {
            errors_object.userName = 'User name is required';
        }
        if (formikValues.emailId === '' || formikValues.emailId === ' ') {
            errors_object.emailId = 'Email is required';
        }
        if(validateEmail(formikValues.emailId))
        {
            errors_object.emailId = 'Invalid email'
        }
        if (formikValues.role.value === '') {
            errors_object.role = 'Role is required';
        }
        if (formikValues.accounts.length === 0) {
            errors_object.accounts = 'Accounts selection is required';
        }
        setErrors(errors_object);

        if (isFormValid(errors_object)) {
            dispatch(actions.setLoaderLayOver(true));
            const reqObj = userDetailsObj(formikValues);
            POST(`${ServiceEndPoints.postUser}`, reqObj)
                .then((res) => {
                    dispatch(actions.setAppSnackBarData({ msg: 'User Created Successfully' }));
                    setTimeout(() => {
                        dispatch(actions.setLoaderLayOver(false));
                    }, 2000);
                    props.handleButtonClick();
                })
                .catch((err) => {
                    console.log(err);
                    dispatch(
                        actions.setBottomAlert({
                            msgs: [
                                {
                                    type: 'error',
                                    msg: err?.response?.data?.[0]?.detail ?? 'Error occurred, please try again',
                                },
                            ],
                        }),
                    );
                    // dispatch(actions.setAppSnackBarData({type:"error", msg:'Error!'}))
                    setTimeout(() => {
                        dispatch(actions.setLoaderLayOver(false));
                    }, 2000);
                });
        } else {
            dispatch(
                actions.setBottomAlert({
                  msgs: [{ type: 'error', msg: 'The form has errors, please correct and try again' }],
                }),
              );
            setFieldTouched('userName', true);
            setFieldTouched('emailId', true);
            setFieldTouched('role', true);
            setFieldTouched('accounts', true);
        }
    };

    const updateData = () => {
        let errors_object = {};

        if (formikValues.userName?.trim() === '') {
            errors_object.userName = 'User name is required';
        }
        if (formikValues.emailId === '' || formikValues.emailId === ' ') {
            errors_object.emailId = 'Email is required';
        }
        if(validateEmail(formikValues.emailId))
        {
            errors_object.emailId = 'Invalid email'
        }
        if (formikValues.role.value === '') {
            errors_object.role = 'Role is required';
        }
        if (formikValues.accounts.length === 0) {
            errors_object.accounts = 'Accounts selection is required';
        }
        setErrors(errors_object);
        if (isFormValid(errors_object)) {
            dispatch(actions.setLoaderLayOver(true));
            const reqObj = updateUserObj(formikValues);
            PATCH(ServiceEndPoints.postUser, reqObj)
                .then((res) => {
                    dispatch(actions.setAppSnackBarData({ msg: 'User updated successfully' }));
                    setTimeout(() => {
                        dispatch(actions.setLoaderLayOver(false));
                        props.handleButtonClick();
                        props.getuserListData();
                    }, 9000);
                })
                .catch((err) => {
                    console.log(err);
                    dispatch(
                        actions.setBottomAlert({
                            msgs: [
                                {
                                    type: 'error',
                                    msg: err?.response?.data?.[0]?.detail ?? 'Error occurred, please try again',
                                },
                            ],
                        }),
                    );
                    setTimeout(() => {
                        dispatch(actions.setLoaderLayOver(false));
                    }, 2000);
                });
        } else {
            dispatch(
                actions.setBottomAlert({
                  msgs: [{ type: 'error', msg: 'The form has errors, please correct and try again' }],
                }),
              );
            setFieldTouched('userName', true);
            setFieldTouched('emailId', true);
            setFieldTouched('role', true);
            setFieldTouched('accounts', true);
            // return;
        }
    };

    React.useEffect(() => {
        if (props.updateUser) {
            setFieldValue('userName', props.updateRowData.userName);
            setFieldValue('userId', props.updateRowData.id);
            setFieldValue('emailId', props.updateRowData.emailId);
            setTimeout(() => {
                setFieldValue('role', setDropdownValues(roleOptions, 'value', props.updateRowData.role));  
            }, 0);
            setFieldValue('accounts', props.updateRowData.accounts);
            setFieldValue('isActive', props.updateRowData.status);
        }
        else if (props.row) {
            setFieldValue('userName', '');
            setFieldValue('emailId', '');
            setFieldValue('role', setDropdownValues(roleOptions, 'value', props.row[0].role));
            setFieldValue('accounts', props.row[0].accounts);
        }
        else {
            setFieldValue('userName', '');
            setFieldValue('userId', '        ');
            setFieldValue('emailId', '');
            setFieldValue('role', setDropdownValues(roleOptions, 'value', roleOptions[0]?.value));
            setFieldValue('accounts', '');
            setFieldValue('isActive', props.updateRowData.status);
        }
    }, [props.updateUser, props.row, roleOptions]);

    // React.useEffect(()=>{
    //   if (formikValues.role.value === 'SuperUser')
    //   {
    //     setShowConfirmationDialog(true);
    //   }
    // },[formikValues]);

    return (
        <Grid container direction='row' spacing={6}>
            <Grid container item lg={4} direction='row' spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormRenderer {...userName.props} fullWidth />
                </Grid>
                {props.updateUser === true ? (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormRenderer {...emailId.props} isReadOnly={true} fullWidth />
                    </Grid>
                ) : (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormRenderer {...emailId.props} fullWidth />
                    </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormRenderer {...role.props} data={roleOptions} fullWidth />
                </Grid>

                <Grid className="form-group text-low-visible" item xs={12} sm={12} md={12} lg={12}>
                    <IconButton onClick={handleClick} style={{ width: '20px', height: '20px' }}>
                        <SvgIcon component={InfoIcon} />
                    </IconButton>
                    <span className="role-text">With this role user get access to</span>
                    <Menu
                        style={{ marginLeft: '25px', marginTop: '10px' }}
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <span style={{ fontWeight: 'bold', fontSize: '16px', marginLeft: '15px' }}>
                            With this role user get access to
                        </span>
                        <Divider style={{ marginTop: '5px' }} />
                        {roleAccess[0] &&
                            roleAccess[0].map((item, index) => (
                                <Grid>
                                    <MenuItem onClick={handleClose} key={index} value={item}>
                                        {item}
                                    </MenuItem>
                                    <Divider style={{ marginTop: '5px' }} />
                                </Grid>
                            ))}
                    </Menu>
                </Grid>
            </Grid>

            <Grid container item lg={8} direction='row'>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                    <div className='AccountsDropDownRoot'>
                        <FormRenderer
                            name='accounts'
                            type='multipleValues'
                            label='Accounts'
                            displayField={'accountId'}
                            isRequired={true}
                            hasDefaultValue={false}
                            valuePropertyName='accountId'
                            //fieldValue={accounts}
                            formikValues={formikValues.accounts}
                            data={accountsData}
                            isFromCreateNewUser={true}
                            //isDisabled={formikValues.role.value === 'SuperUser'}
                            offset={offset}
                            setOffset={setOffset}
                            multiple={true}
                            getAccounts={getAccounts}
                            createUser={props.createUser}
                            setState={props.setState}
                            fullWidth
                        />
                    </div>
                </Grid>
            </Grid>

            {props.updateUser === true ? (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormRenderer {...isActive.props} checked={true} />
                </Grid>
            ) : null}

            {/* <Grid container className="customModal-book">
        <CustomModal open={showConfirmationDialog} title={'Confirmation'} maxWidth="sm" onClose={handleDialogClose}>
          <ConfirmationDialog
            confirmationMessage= {(props.userDetailsRole==='Retail Super User') ?"You are changing the user role for this user to “Retail Super User” you will not be able to revert this user back to a standard “Super User” role are you happy to continue?":"You are changing the user role for this user to “National Super User” you will not be able to revert this user back to a standard “Super User” role are you happy to continue?"}
            onConfirm={onDialogConfirm}
            OnCancel={onDialogCancel}
          />
        </CustomModal>
      </Grid> */}

            <Grid container
                className={`newRetail-submit-btn ${props.updateUser ? 'update-btn-container form-group' : 'create-btn-container form-group'}`}
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
            >
                <Grid container direction="row" spacing={3} lg={12}>
                    <Grid item xs={9} sm={9} md={9} lg={10}></Grid>
                    <Grid item xs={3} sm={3} md={3} lg={2} >
                        <InputButton
                            label={props.updateUser ? 'Update' : 'Create'}
                            isDisabled={!((formikValues || {}).accounts || []).length > 0}
                            variant="contained"
                            buttonType="submit"
                            onClick={() => {
                                props.updateUser !== true ? postData() : updateData();
                            }}
                            className={`${props.updateUser ? 'contained-btn update-btn' : 'contained-btn create-btn'}`}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

    );
};
CreateNewRetailUser.propTypes = {
    formField: PropTypes.object,
    formikValues: PropTypes.object,
    setFieldValue: PropTypes.func,
};

const roleOptionsRetailer = [
    {
        value: '',
        label: 'Select',
    },
    {
        value: 'Supervisor',
        label: 'Supervisor',
    },
    {
        value: 'Shipment Entry',
        label: 'Shipment Entry',
    },
    {
        value: 'Reporting & Shipment Entry',
        label: 'Reporting & Shipment Entry',
    },
    {
        value: 'Reporting',
        label: 'Reporting',
    },
    {
        value: 'Retail SuperUser',
        label: 'Retail SuperUser',
    },
    {
        value: 'Basic Shipment Entry',
        label: 'Basic Shipment Entry',
    },
]
const roleOptionsReseller = [
    {
        value: '',
        label: 'Select',
    },
    {
        value: 'National SuperUser',
        label: 'National SuperUser',
    },
    {
        value: 'Basic Shipment Entry',
        label: 'Basic Shipment Entry',
    },
    {
        value: 'Basic Shipment Entry & Reporting',
        label: 'Basic Shipment Entry & Reporting',
    }
];

export default CreateNewRetailUser;

