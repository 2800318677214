import React, { useEffect } from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import { FormControl, MenuItem, FormHelperText, FormLabel, makeStyles, TextField } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import './inputSelect.scss';

const useStylesTextField = makeStyles(() => ({
  root: {
    border: '1px solid #00000053',
    overflow: 'hidden',
    color: 'black',
    borderRadius: 4,
    backgroundColor: '#fff ',

    '&:hover': {
      backgroundColor: '#fff',
    },
    '&$focused': {
      backgroundColor: '#fff',
      border: '1px solid #00000053',
    },
  },
}));

const InputSelect = (props) => {
  const { label, isRequired, data, displayField, multiple, hasDefaultValue, displayId, isDisabled, ...rest } = props;
  const [field, meta] = useField(props);
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;
  const classes = useStylesTextField();

  if (data.length > 0 && !data.some((defaultValue) => defaultValue[displayField] === 'Select') && !hasDefaultValue) {
    if (label !== 'Country') {
      data.unshift({ value: '', [displayField]: 'Select' });
    }
  }
  else if(hasDefaultValue && data.some((defaultValue) => defaultValue[displayField] === 'Select')) {
    {
       data.shift();
    }
  }

  const renderHelperText = () => {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  };

  useEffect(()=>{
    if(props.onJspmVersionSelectionChange && selectedValue?.value !== '')
    {
      props.onJspmVersionSelectionChange(selectedValue?.value);
    }
  },[selectedValue]);

  return (
    <div className="select-container">
      {props.variant === 'filled' ? (
        <FormControl fullWidth className={classes.margin} error={isError}>
          <FormLabel className="labelSelect">
            {label} <span className="span-required">*</span>{' '}
          </FormLabel>
          <TextField
            select
            {...field}
            disabled={isDisabled}

            value={selectedValue ? selectedValue : ''}
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
              }
            }}
          >
            {data.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item[displayField]}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      ) : (
        <FormControl {...rest} error={isError}>
          <TextField
            InputProps={{ classes, disableUnderline: true }}
            label={
              isRequired ? (
                <label className="labelSelect">
                  {label} <span className="span-required">*</span>
                </label>
              ) : (
                <label className="labelSelect">{label}</label>
              )
            }
            select
            disabled={isDisabled}

            {...field}
            defaultValue="Bob"
            value={selectedValue ? selectedValue : ''}
            variant="filled"
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
              },
              renderValue: () => <div>{selectedValue.value === "daterange" ? rest?.renderVal || selectedValue[displayField] : selectedValue[displayField]}</div>

            }}
          >
            {data.map((item, index) => (
              < MenuItem key={index} className={(item.status && item.status.toLowerCase() === "onstop") ? 'OnStopItem' : 'test'} value={item} onClick={() => { item?.clickEvent?.() }} >
                {displayId && item[displayId] ? (
                  <span>
                    {' '}
                    {item[displayId]}-{item[displayField]}
                  </span>
                ) : (
                  <span>{item[displayField]}</span>
                )}
              </MenuItem>
            )
            )}
          </TextField>
          {renderHelperText()}
        </FormControl>
      )
      }
    </div >
  );
};

InputSelect.propTypes = {
  data: PropTypes.array.isRequired,
  label: PropTypes.string,
  hasDefaultValue: PropTypes.bool,
};

export default InputSelect;
