import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ShipmentDetailView from '../shipmentDetailView/shipmentDetailView';
import { Grid, IconButton, SvgIcon, MenuItem, Select, ListItemIcon, ListItemText, Checkbox } from '@material-ui/core';
import InputButton from '../../../framework/Inputs/inputButton/inputButton';
import { Form, Formik } from 'formik';
import { axiosConfig, GET, POST } from '../../../api/axios';
import { useHistory } from 'react-router';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { ReactComponent as FilterIcon } from '../../../assets/images/Filter.svg';
import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';
import {
  customTableSort,
  getBackGroundColor,
  remove_duplicates_dashboardSummary,
  basicSort,
  customTableShipmentNoSort,
  formatDateToUKFormat,
  formatDate,
} from '../../../utils/helperFunctions';
// import TableExport from '../../../components/TableExport/TableExport';
import TableExportPopup from '../../../components/TableExport/TableExportPopup';
import './summary.scss';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { arrayValuesToQueryStringParam } from '../../../utils/common';
import { getDayOfTheWeek, convertTZwithFormat, formatDateToSecondFormat, convertTZ } from '../../../utils/helperFunctions';

const filterButtonStyle = {
  padding: '4px !important',
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};
const Summary = ({ dashboardDetails, userDetails }) => {
  const trackingType = useSelector((state) => state.dashboardTracking.dashboardTracking);
  const dashboardResponse = (dashboardDetails || {}).loadedResponse;
  const history = useHistory();
  const dispatch = useDispatch();
  // const [totalRecodsLength, setTotalRecodsLength] = useState();
  const getLookupData = (dataObj, isNested = false, nestedPropertyName = '') => {
    let lookupObj = {};
    (dataObj || []).forEach((ele) => {
      const propertyValue = isNested ? ele[nestedPropertyName] : ele;
      lookupObj[propertyValue] = propertyValue;
    });
    return lookupObj;
  };
  const getLookupFilterData = (dataObj, isNested = false, nestedPropertyName = '') => {
    let lookupArray = [];
    (dataObj || []).forEach((ele) => {
      const propertyValue = isNested ? ele[nestedPropertyName] : ele;
      lookupArray.push(propertyValue);
    });
    return lookupArray;
  };

  const headers = [
    { label: 'Accounts', key: 'pickupAccount' },
    { label: 'Customer reference', key: 'customerReferenceNumber' },
    { label: 'Shipment number', key: 'Shipmentnumber' },
    { label: 'Status', key: 'status' },
    { label: 'Delivery due date', key: 'deliveryDueDate' },
    { label: 'ETA', key: 'ETA' },
    { label: 'Number of parcels', key: 'numberOfParcels' },
    { label: 'Weight', key: 'weight' },
    { label: 'Product', key: 'product' },
    { label: 'Service', key: 'service' },
    { label: 'Country', key: 'destinationCountry' },
    { label: 'Dispatch date', key: 'collectionDate' },
    { label: 'Business/Recipient name', key: 'recipientName' },
    { label: 'Contact number', key: 'contactNumber' },
    { label: 'Postal Code', key: 'destinationPostalCode' },
  ];

  const CustomFilter = (props) => {
    const [selectedVal, setSelectedVal] = useState([]);
    function handleChange(e) {
      const val = e.target.value;
      setSelectedVal(val);
      props.onFilterChanged(props.columnDef.tableData?.id, val);
    }

    return (
      <th>
        <Select
          value={selectedVal}
          onChange={handleChange}
          multiple={true}
          MenuProps={MenuProps}
          style={{ minWidth: '100px' }}
          renderValue={(data) => <div>{data.length > 1 ? data[0] + ',...' : data[0]}</div>}
        >
          {props.columnDef.filterValues.map((option) => (
            <MenuItem key={option} value={option}>
              <ListItemIcon>
                <Checkbox checked={selectedVal.indexOf(option) > -1} />
              </ListItemIcon>
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </th>
    );
  };
  const columns = [
    { title: 'id', field: 'id', hidden: true },
    {
      title: (
        <div style={{ display: 'flex' }}>
          <div>
            <span>Accounts</span>
          </div>
          {(
            (dashboardDetails || {}).loadedFrom === 'findshipment'
              ? (dashboardDetails || {}).userAccounts.length > 1
              : (dashboardResponse?.accounts ?? []).length > 1
          ) ? (
            <div>

              <IconButton className="FilterIcon" onClick={() => setShowFilter(true)} style={filterButtonStyle}>
                <SvgIcon component={FilterIcon} viewBox="0 0 30 30" />
              </IconButton>
            </div>
          ) : null}
        </div>
      ),
      field: 'pickupAccount',
      fieldName: 'accounts',
      // lookup: getLookupData(dashboardResponse?.accounts ?? [], false, ''),
      lookup: getLookupData(
        (dashboardDetails || {}).loadedFrom === 'findshipment'
          ? (dashboardDetails || {}).userAccounts
          : dashboardResponse?.accounts ?? [],
        false,
        '',
      ),
      // filtering: (dashboardResponse?.accounts ?? []).length > 1,
      filtering:
        (dashboardDetails || {}).loadedFrom === 'findshipment'
          ? (dashboardDetails || {}).userAccounts.length > 1
          : (dashboardResponse?.accounts ?? []).length > 1,
      // customSort: (a, b) => customTableSort(a.pickupAccount, b.pickupAccount),
      sorting: true,
      filterValues: getLookupFilterData(
        (dashboardDetails || {}).loadedFrom === 'findshipment'
          ? (dashboardDetails || {}).userAccounts
          : dashboardResponse?.accounts ?? [],
        false,
        '',
      ),
      filterComponent: (props) => <CustomFilter {...props} />,
    },
    {
      title: 'Customer ref.',
      field: 'customerReferenceNumber',
      filtering: false,
      //whiteSpace: "nowrap",
      // customSort: (a, b) => customTableSort(a.customerReferenceNumber, b.customerReferenceNumber),
      sorting: true,
    },
    {
      title: 'Shipment no.',
      field: 'shipments[0].shipmentDetails.shipmentId',
      filtering: false,
      customSort: (a, b) =>
        customTableShipmentNoSort(a.shipments[0].shipmentDetails.shipmentId, b.shipments[0].shipmentDetails.shipmentId),
    },
    {
      title: (
        <div style={{ display: 'flex', paddingTop: '5px' }}>
          <div>
            <span>Status</span>
          </div>
          {dashboardDetails.loadedFrom !== 'onChartClick' ? (
            <div>
              <IconButton className="FilterIcon" onClick={() => setShowFilter(true)} style={filterButtonStyle}>
                <SvgIcon component={FilterIcon} viewBox="0 0 30 30" />
              </IconButton>
            </div>
          ) : null}
        </div>
      ),
      field: 'status',
      fieldName: 'status',
      cellStyle: {
        whiteSpace: 'nowrap',
      },
      render: (row) => (
        <Grid container direction="row" style={{ position: 'relative', whiteSpace: 'nowrap', width: '113px' }}>
          <Grid item style={{ width: '5px', height: '17px', background: getBackGroundColor(row.status) }}></Grid>
          <Grid item style={{ position: 'absolute', paddingLeft: '10px', whiteSpace: 'nowrap' }}>
            {row.status}
          </Grid>
        </Grid>
      ),
      filtering: dashboardDetails.loadedFrom !== 'onChartClick',
      lookup: {
        'In transit': 'In transit',
        'Out for delivery': 'Out for delivery',
        Exception: 'Exception',
        Delivered: 'Delivered',
        'Delivery attempted': 'Delivery attempted',
        'Order received': 'Order received'
      },
      filterValues: [
        'In transit',
        'Out for delivery',
        'Exception',
        'Delivered',
        'Delivery attempted',
        'Order received'
      ],
      sorting: false,
      filterComponent: (props) => <CustomFilter {...props} />,
    },
    {

      title: (trackingType?.loadedFrom === 'onChartClick' && trackingType?.status === 'Delivered') ? 'Delivery date' : 'Delivery due date',
      field: 'deliveryDueDate',
      filtering: false,
      // sorting: true,

      render: (row) => row?.status === 'Out for delivery' ? <span>{convertTZwithFormat(row?.eventDateTime, 'DD/MM/YYYY')}</span> : trackingType?.loadedFrom === 'onChartClick' && trackingType?.status === 'Delivered' ? <span> {convertTZwithFormat(row?.eventDateTime, 'DD/MM/YYYY')}</span> : <span>{`${((trackingType?.loadedFrom === 'detailView' || trackingType?.loadedFrom === 'findshipment') && row?.status === 'Delivered') ? '' : formatDateToUKFormat(row?.deliveryDueDate)}`}</span>,
      customSort: (a, b) =>
        basicSort(new Date(a?.deliveryDueDate).getTime() || 0, new Date(b?.deliveryDueDate).getTime() || 0),
    },
    {
      title: (trackingType?.loadedFrom === 'onChartClick' && trackingType?.status === 'Delivered') ? 'Delivery time' : 'ETA',
      cellStyle: {
        whiteSpace: 'nowrap',
      },
      render: (row) => (trackingType?.loadedFrom === 'detailView' || trackingType?.loadedFrom === 'findshipment') && row?.status === 'Delivered' ? '' : row?.status === 'Out for delivery' ? <span>{(row?.eventEstimatedDeliveryStartTime && row?.eventEstimatedDeliveryEndTime) ? `${row?.eventEstimatedDeliveryStartTime} - ${row?.eventEstimatedDeliveryEndTime}` : `${row?.estimatedDeliveryStartTime} - ${row?.estimatedDeliveryEndTime}`}</span> : (trackingType?.loadedFrom === 'onChartClick' && trackingType?.status === 'Delivered') ? convertTZwithFormat(row?.eventDateTime, 'H:mm') : (row?.estimatedDeliveryStartTime && row?.estimatedDeliveryEndTime && <span>{`${row?.estimatedDeliveryStartTime} - ${row?.estimatedDeliveryEndTime}`}</span>),
      filtering: false,
      // customSort: (a, b) => customTableSort(a?.estimatedDeliveryStartTime, b?.estimatedDeliveryStartTime),
      sorting: true,
    },
    {
      title: 'No. of parcels',
      field: 'numberOfParcels',
      filtering: false,
      // customSort: (a, b) => customTableSort(a.numberOfParcels, b.numberOfParcels),
      sorting: true,
    },
    // {
    //   title: 'Weight',
    //   field: 'weight',
    //   filtering: false,
    //   customSort: (a, b) => customTableSort(a.weight, b.weight),
    // },
    {
      title: (
        <div style={{ display: 'flex', paddingTop: '5px' }}>
          <div>
            <span>Product</span>
          </div>
          {!(
            dashboardDetails.loadedFrom === 'onChartClick' ||
            (history.location.pathname.indexOf('dashboardDetailsBreakdown') !== -1 &&
              ((dashboardDetails.loadedResponse || {}).product || {}).value)
          ) ? (
            <div>
              <IconButton className="FilterIcon" onClick={() => setShowFilter(true)} style={filterButtonStyle}>
                <SvgIcon component={FilterIcon} viewBox="0 0 30 30" />
              </IconButton>
            </div>
          ) : null}
        </div>
      ),
      field: 'product.productNameForUI',
      fieldName: 'product',
      filtering: !(
        dashboardDetails.loadedFrom === 'onChartClick' ||
        (history.location.pathname.indexOf('dashboardDetailsBreakdown') !== -1 &&
          ((dashboardDetails.loadedResponse || {}).product || {}).value)
      ),
      lookup: getLookupData(dashboardDetails.products, true, 'productName'),
      filterValues: getLookupFilterData(dashboardDetails.products, true, 'productName'),
      filterComponent: (props) => <CustomFilter {...props} />,
      // customSort: (a, b) => customTableSort((a.product || {}).productName, (b.product || {}).productName),
      sorting: true,
    },
    {
      title: (
        <div style={{ display: 'flex', paddingTop: '5px' }}>
          <div>
            <span>Service</span>
          </div>
          {!(
            dashboardDetails.loadedFrom === 'onChartClick' ||
            (history.location.pathname.indexOf('dashboardDetailsBreakdown') !== -1 &&
              ((dashboardDetails.loadedResponse || {}).domesticService || {}).value)
          ) ? (
            <div>
              <IconButton className="FilterIcon" onClick={() => setShowFilter(true)} style={filterButtonStyle}>
                <SvgIcon component={FilterIcon} viewBox="0 0 30 30" />
              </IconButton>
            </div>
          ) : null}
        </div>
      ),
      field: 'service.description',
      fieldName: 'service',
      lookup: getLookupData(dashboardDetails.services, true, 'serviceName'),
      filterValues: getLookupFilterData(dashboardDetails.services, true, 'serviceName'),
      filterComponent: (props) => <CustomFilter {...props} />,
      filtering: !(
        dashboardDetails.loadedFrom === 'onChartClick' ||
        (history.location.pathname.indexOf('dashboardDetailsBreakdown') !== -1 &&
          ((dashboardDetails.loadedResponse || {}).domesticService || {}).value)
      ),
      // customSort: (a, b) => customTableSort(a.service.description, b.service.description),
      sorting: true,
    },
    {
      title: (
        <div style={{ display: 'flex', paddingTop: '5px' }}>
          <div>
            <span>Country</span>
          </div>
          {dashboardDetails.loadedFrom === 'onChartClick' ||
            ((history.location.pathname.indexOf('dashboardDetailsSummary') !== -1 ||
              history.location.pathname.indexOf('dashboardDetailsBreakdown') !== -1) &&
              ((dashboardDetails.loadedResponse || {}).internationalCountry || {}).name !== 'All countries') ? null : (
            <div>
              <IconButton className="FilterIcon" onClick={() => setShowFilter(true)} style={filterButtonStyle}>
                <SvgIcon component={FilterIcon} viewBox="0 0 30 30" />
              </IconButton>
            </div>
          )}
        </div>
      ),
      field: 'destinationCountry',
      fieldName: 'destinationCountry',
      filtering: !(
        dashboardDetails.loadedFrom === 'onChartClick' ||
        ((history.location.pathname.indexOf('dashboardDetailsSummary') !== -1 ||
          history.location.pathname.indexOf('dashboardDetailsBreakdown') !== -1) &&
          ((dashboardDetails.loadedResponse || {}).internationalCountry || {}).name !== 'All countries')
      ),
      lookup: getLookupData(dashboardDetails.countries, true, 'name'),
      filterValues: getLookupFilterData(dashboardDetails.countries, true, 'name'),
      filterComponent: (props) => <CustomFilter {...props} />,
      sorting: false,
    },
    {
      title: 'Dispatch date',
      field: 'collectionDate',
      filtering: false,
      // sorting: false,
      render: (row) => <span>{`${formatDateToUKFormat(row.collectionDate)}`}</span>,
      customSort: (a, b) =>
        basicSort(new Date(a.collectionDate).getTime() || 0, new Date(b.collectionDate).getTime() || 0),
    },
    {
      title: 'Business/Recipient name',
      field: 'recipientName',
      render: (row) => (
        <Grid container direction="row">
          <Grid item style={{ wordWrap: 'break-word' }}>
            {row?.recipientName ? row.recipientName : row.businessName ? row.businessName : row.receiverName}
          </Grid>
        </Grid>
      ),
      filtering: false,
      // customSort: (a, b) =>
      //   customTableSort(
      //     a.businessName ? a.businessName : a.receiverName,
      //     b.businessName ? b.businessName : b.receiverName,
      //   ),
      sorting: true,
    },
    {
      title: 'Contact no.',
      field: 'contactNumber',
      filtering: false,
      // customSort: (a, b) => customTableSort(a.contactNumber, b.contactNumber),
      sorting: true,
    },
    {
      title: 'Postal code',
      field: 'destinationPostalCode',
      filtering: false,
      // customSort: (a, b) => customTableSort(a.destinationPostalCode, b.destinationPostalCode),
      sorting: true,
    },
  ];

  const [selectedRows, setSelectedRows] = useState([]);
  const [tableColumns, setTableColumns] = useState(columns);
  const [tableHeaderColumns, setTableHeaderColumns] = useState(headers);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [SelectedAccountsFromDashboard, setSelectedAccountsFromDashboard] = useState([]);
  const [shipmentCancelItems, setshipmentCancelItems] = useState([]);
  const [tableRawData, setTableRawData] = useState([]);
  const [ExporttableRawData, setExportTableRawData] = useState([]);
  const [TotalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [tableData, setTableData] = useState([]);
  const [tableDataLength, setTableDataLength] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [queryString, setqueryString] = useState('');
  const [sortByOrder, setSortByOrder] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [showExportDialog, setshowExportDialog] = useState(false);

  // const [pageSize, setPageSize] = useState(10);

  const [pageDetails, setPageDetails] = useState({
    offset: 0,
    limit: 0,
    totalRecordsLength: 0,
  });
  const [showFilter, setShowFilter] = React.useState(false);

  const addSelectedRows = (selectedRowsFromTable) => {
    setSelectedRows(selectedRowsFromTable);
  };

  const addToMonitorList = () => {
    const reqBody = selectedRows.map((row) => {
      return {
        customerReference: row.customerReferenceNumber,
        shipmentNumber: row.shipments[0].shipmentDetails.shipmentId,
        collectionDate: row.collectionDate,
        deliveryDate: row.deliveryDueDate,
        status: row.status,
      };
    });
    POST(`${ServiceEndPoints.getAllMonitioredShipments}${userDetails.userId}`, reqBody)
      .then((res) => {
        setSelectedRows([]);
        dispatch(actions.setAppSnackBarData({ msg: 'Details added to monitor list' }));
        getTableData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const trackShipmet = () => {
    // history.push(`/dashboard/Tracking?id=${selectedRows[0].shipments[0].shipmentDetails.shipmentId}`);
    window.open(`#/dashboard/Tracking?id=${selectedRows[0].shipments[0].shipmentDetails.shipmentId}`,'_blank');
  };

  const getUrl = (offset, limit) => {
    let url = `${ServiceEndPoints.getShipmentManagement}${(dashboardDetails || {}).dashboardQueryParams}`;
    url += queryString?.indexOf('accounts=') > -1 ? '' : `&${dashboardDetails.accountsParams}`;
    if (queryString) {
      url += `${queryString}`;
    }
    if (sortByOrder) {
      url += sortByOrder;
    }
    url += `&offset=${offset}`;
    return (url += `&limit=${limit}`);
  };

  useEffect(() => {
    getTableData();
  }, [pageSize, pageNumber, queryString]);

  const getTableData = () => {
    setIsLoading(true);
    const offset = pageNumber * pageSize;
    axiosConfig
      .get(getUrl(offset, pageSize))
      .then((res) => {
        let tempTableData = [];
        tempTableData = res.data?.shipmentModels;
        if (history.location && history.location.state !== undefined && history.location.state !== null) {
          tempTableData = remove_duplicates_dashboardSummary(tempTableData, history.location.state.itemsToBeSelected);
        }
        setTableData(tempTableData);
        setTableRawData(tempTableData);
        setPageDetails({
          offset,
          pageSize,
          totalRecordsLength: res.data?.totalRecords,
        });
        // setTotalRecodsLength(res.data?.totalRecords);
        resolveTableData(res.data?.totalRecords);
      })
      .catch((err) => {
        console.log(err);
        setTableData([]);
        setTableRawData([]);
        setPageDetails({
          offset,
          pageSize,
          totalRecordsLength: 0,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const onPageNumChange = (pageNum = 0) => {
    setPageNumber(pageNum);
  };

  const onFilter_Change = (filterData) => {
    let _filterQueryString = '';
    filterData.map((element) => {
      if (element.value.length > 0) {
        element.value.map((data) => {
          if (!dashboardResponse?.isDomestic && element.column.fieldName === "destinationCountry") {
            dashboardDetails.countries.map((country) => {
              if (country.name === data) {
                _filterQueryString += `&${element.column.fieldName}=${country.code2Digit}`;
              }
            })
          } else {
            _filterQueryString += `&${element.column.fieldName}=${data}`;
          }
        });
      }
    });
    // console.log(_filterQueryString);
    setqueryString(_filterQueryString);
    setPageNumber(0);
    setPageSize(10);
    // getTableData();
  };

  const onPageSizeChange = (pageSize = 10) => {
    setPageSize(pageSize);
  };

  // const filterData = (filters) => {
  //   // const filtersArray = [...query.filters];
  //   const filteredAccounts = filters.find((filter) => filter.column.field === 'pickupAccount');
  //   (filteredAccounts || {}).value === undefined || (filteredAccounts || {}).value.length == 0
  //     ? setSelectedAccounts(SelectedAccountsFromDashboard)
  //     : setSelectedAccounts((filteredAccounts || {}).value);

  //   const filteredData = tableRawData.filter((row) => {
  //     return !filters
  //       .filter((individualFilter) => individualFilter.value.length > 0)
  //       .map((mappedObjet) => {
  //         if (
  //           (mappedObjet.value || []).filter((filterValue) => {
  //             const rowValue = mappedObjet.column.field.split('.').reduce((a, b) => (a || {})[b], row);
  //             return (filterValue || '').toLowerCase() === (rowValue || '').toLowerCase();
  //           }).length > 0
  //         ) {
  //           return true;
  //         }
  //         return false;
  //       })
  //       .some((checkValue) => checkValue === false);
  //   });
  //   setTableData(filteredData);
  //   // resolveTableData(resolve, filteredData, query, totalRecordsLength);
  // };

  useEffect(() => {
    if (history.location && history.location.state !== undefined && history.location.state !== null) {
      setshipmentCancelItems(history.location.state.itemsToBeSelected);
    }
    return () => {
      if (history.location.pathname === '/dashboard') {
        dispatch(
          actions.setDashboardTracking({
            loadedFrom: 'dashboardDetails',
            loadedResponse: dashboardResponse,
            dashboardQueryParams: (dashboardDetails || {}).dashboardQueryParams,
            tabIndex: (dashboardDetails || {}).tabIndex,
            accountsParams: arrayValuesToQueryStringParam(dashboardDetails?.loadedResponse?.accounts),
          }),
        );
      }
    };
  }, []);

  const resolveTableData = (totalRecordsLength) => {
    setTotalCount(totalRecordsLength ?? 0);
  };

  useEffect(() => {
    let updatedColumns = [...columns];
    let updatedHeaderColumns = [...headers];
    updatedColumns.forEach((column) => {
      if (column.lookup && Object.keys(column.lookup).length === 0 && column.lookup.constructor === Object) {
        delete column.lookup;
      }
    });
    if (dashboardResponse?.isDomestic) {
      updatedColumns = updatedColumns.filter((val) => val.fieldName !== 'destinationCountry')
      updatedHeaderColumns = updatedHeaderColumns.filter((val) => val.key !== 'destinationCountry')
      // updatedColumns.splice(10, 1);
      // updatedHeaderColumns.splice(9, 1);
    } else {
      // updatedColumns.splice(9, 1);
      updatedColumns = updatedColumns.filter((val) => val.fieldName !== 'service')
      updatedHeaderColumns = updatedHeaderColumns.filter((val) => val.key !== 'service')
      // updatedHeaderColumns.splice(10, 1);
    }
    // updatedHeaderColumns = updatedHeaderColumns.map((val) => {
    //   if (trackingType?.loadedFrom === 'onChartClick' && trackingType?.status === 'Delivered') {
    //     if (val.key === 'ETA') val.label = "Delivery time";
    //     else if (val.key === 'deliveryDueDate') val.label = "Delivery date"
    //   };
    //   return val
    // });
    setTableColumns(updatedColumns);
    setTableHeaderColumns(updatedHeaderColumns);
    setSelectedAccounts(
      (dashboardDetails || {}).loadedFrom === 'findshipment'
        ? (dashboardDetails || {}).userAccounts
        : ((dashboardDetails || {}).loadedResponse || {}).accounts,
    );
    setSelectedAccountsFromDashboard(
      (dashboardDetails || {}).loadedFrom === 'findshipment'
        ? (dashboardDetails || {}).userAccounts
        : ((dashboardDetails || {}).loadedResponse || {}).accounts,
    );
  }, [dashboardDetails]);

  const onDataUpdate = async () => {
    dispatch(actions.setLoaderLayOver(true));
    await GET(getUrl(0, pageDetails.totalRecordsLength))
      .then((res) => {
        let ExportData = [];
        res.data.shipmentModels.forEach((data) => {
          ExportData.push({
            pickupAccount: data?.pickupAccount,
            customerReferenceNumber: data?.customerReferenceNumber,
            Shipmentnumber: data.shipments[0]?.shipmentDetails?.shipmentId,
            status: data?.status,
            deliveryDueDate: (trackingType?.loadedFrom === 'onChartClick' && trackingType?.status === 'Delivered') ?
              convertTZwithFormat(data?.eventDateTime, 'DD/MM/YYYY') :
              data?.status === 'Delivered' ? '' : formatDate(data.deliveryDueDate, '/','dd/mm/yyyy', true),
            ETA: (trackingType?.loadedFrom === 'onChartClick' && trackingType?.status === 'Delivered') ?
              convertTZwithFormat(data?.eventDateTime, 'H:mm') :
              data?.status === 'Delivered' ? '' :
                data?.status === 'Out for delivery' && (data?.eventEstimatedDeliveryStartTime && data?.eventEstimatedDeliveryEndTime) ?
                  `${data?.eventEstimatedDeliveryStartTime} - ${data?.eventEstimatedDeliveryEndTime}` :
                  `${data?.estimatedDeliveryStartTime} - ${data?.estimatedDeliveryEndTime}`,
            numberOfParcels: data?.numberOfParcels,
            weight: data?.shipments[0]?.shipmentDetails?.totalWeight,
            product: data.product?.productNameForUI,
            service: data.service?.description,
            destinationCountry: data?.destinationCountry,
            collectionDate: formatDate(data?.collectionDate, '/', 'dd/mm/yyyy'),
            recipientName: data?.recipientName ? data.recipientName : data?.businessName != '' ? data?.businessName : data?.receiverName,
            contactNumber: data?.contactNumber,
            destinationPostalCode: data?.destinationPostalCode,
          });
        });
        setExportTableRawData(ExportData);
        dispatch(actions.setAppSnackBarData({ msg: `Data exported successfully` }));
        setshowExportDialog(false);
      })
      .catch((err) => {
        console.log(err);
        setExportTableRawData([]);
        dispatch(
          actions.setBottomAlert({
            msgs: [
              {
                type: 'error',
                msg: 'Error in exporting data, please try again later',
              },
            ],
          }),
        );
        setshowExportDialog(false);
      })
      .finally(() => {
        dispatch(actions.setLoaderLayOver(false));
      });
  };
  const printDocument = () => {
    try {
      axiosConfig.get(getUrl(0, pageDetails.totalRecordsLength)).then(async (res) => {
        let data = res.data?.shipmentModels ?? [];
        const unit = 'pt';
        const size = 'A4'; // Use A1, A2, A3 or A4
        const orientation = 'landscape'; // portrait or landscape
        const doc = new jsPDF(orientation, unit, size);
        const title = 'My Awesome Report';

        let updatedColumns = [...columns];
        let updatedHeaderColumns = [...headers];
        updatedColumns.forEach((column) => {
          if (column.lookup && Object.keys(column.lookup).length === 0 && column.lookup.constructor === Object) {
            delete column.lookup;
          }
        });
        if (dashboardResponse?.isDomestic) {
          // updatedColumns.splice(10, 1);
          updatedHeaderColumns = updatedHeaderColumns.filter((val) => val.key !== 'destinationCountry');
          data = data.map((elt) => [
            elt.pickupAccount,
            elt.customerReferenceNumber,
            elt.shipmentId,
            elt.status,
            (trackingType?.loadedFrom === 'onChartClick' && trackingType?.status === 'Delivered') ?
              convertTZwithFormat(elt?.eventDateTime, 'DD/MM/YYYY') :
              elt?.status === 'Delivered' ? '' : formatDate(elt.deliveryDueDate, '/', 'dd/mm/yyyy', true),
            (trackingType?.loadedFrom === 'onChartClick' && trackingType?.status === 'Delivered') ?
              convertTZwithFormat(elt?.eventDateTime, 'H:mm') :
              elt?.status === 'Delivered' ? '' :
                elt?.status === 'Out for delivery' && (elt?.eventEstimatedDeliveryStartTime && elt?.eventEstimatedDeliveryEndTime) ?
                  `${elt?.eventEstimatedDeliveryStartTime} - ${elt?.eventEstimatedDeliveryEndTime}` :
                  `${elt?.estimatedDeliveryStartTime} - ${elt?.estimatedDeliveryEndTime}`,
            elt.numberOfParcels,
            elt?.shipments[0]?.shipmentDetails?.totalWeight,
            elt.product?.productNameForUI,
            elt.service?.description,
            formatDate(elt.collectionDate, '/', 'dd/mm/yyyy'),
            elt?.recipientName ? elt.recipientName : elt.businessName != '' ? elt?.businessName : elt?.receiverName,
            elt.contactNumber,
            elt.destinationPostalCode,
          ]);
        } else {
          // updatedColumns.splice(9, 1);
          updatedHeaderColumns = updatedHeaderColumns.filter((val) => val.key !== 'service');
          data = data.map((elt) => [
            elt.pickupAccount,
            elt.customerReferenceNumber,
            elt.shipmentId,
            elt.status,
            (trackingType?.loadedFrom === 'onChartClick' && trackingType?.status === 'Delivered') ?
              convertTZwithFormat(elt?.eventDateTime, 'DD/MM/YYYY') :
              elt?.status === 'Delivered' ? '' : formatDate(elt.deliveryDueDate, '/', 'dd/mm/yyyy', true),
            (trackingType?.loadedFrom === 'onChartClick' && trackingType?.status === 'Delivered') ?
              convertTZwithFormat(elt?.eventDateTime, 'H:mm') :
              elt?.status === 'Delivered' ? '' :
                elt?.status === 'Out for delivery' && (elt?.eventEstimatedDeliveryStartTime && elt?.eventEstimatedDeliveryEndTime) ?
                  `${elt?.eventEstimatedDeliveryStartTime} - ${elt?.eventEstimatedDeliveryEndTime}` :
                  `${elt?.estimatedDeliveryStartTime} - ${elt?.estimatedDeliveryEndTime}`,
            elt.numberOfParcels,
            elt?.shipments[0]?.shipmentDetails?.totalWeight,
            elt.product?.productNameForUI,
            elt.destinationCountry,
            formatDate(elt.collectionDate, '/', 'dd/mm/yyyy'),
            elt.businessName != '' ? elt?.businessName : elt?.receiverName,
            elt.contactNumber,
            elt.destinationPostalCode,
          ]);
        }
        const _headers = [updatedHeaderColumns.map((headers) => headers.label)];
        if (!dashboardResponse?.isDomestic) {
        }

        let content = {
          head: _headers,
          body: data,
          columnStyles: {
            1: {
              columnWidth: 80,
            },
            6: {
              columnWidth: 35,
            },
            11: {
              columnWidth: 100,
            },
            12: {
              columnWidth: 60,
            }
            // 7: {
            //   columnWidth: 50
            // },
            // 8: {
            //   columnWidth: 50
            // }
          },
          styles: {
            cellWidth: 'wrap',
            fontSize: 6,
            cellPadding: 2,
            overflowColumns: 'linebreak',
          },
        };

        // doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save('DashboardSummary.pdf');
        dispatch(actions.setAppSnackBarData({ msg: `Data exported successfully` }));
        setshowExportDialog(false);
      });
    } catch (err) {
      console.log(err);
      dispatch(
        actions.setBottomAlert({
          msgs: [
            {
              type: 'error',
              msg: 'Error in exporting data, please try again later',
            },
          ],
        }),
      );
      setshowExportDialog(false);
    }
  };

  const handleDialogClose = () => {
    setshowExportDialog(false);
  };
  return (
    <Grid container justify="flex-end" alignItems="flex-end" className="shipment-Management-table">
      <Grid>
        <TableExportPopup
          totalRecords={tableDataLength}
          dashboard={true}
          headers={tableHeaderColumns}
          data={ExporttableRawData}
          filename={'DashboardSummary.csv'}
          onDataUpdate={onDataUpdate}
          onClick={printDocument}
          tableData={tableData}
          setSortByOrder={setSortByOrder}
          // disableExportButton={tableDataLength === 0 ? true : false}
          showExportDialog={showExportDialog}
          ExportTitle={"Export"}
          handleDialogClose={handleDialogClose}
        />
      </Grid>
      <Grid container spacing={2} direction="column" className="dashboard-details-container">
        <Grid item className="dashboard-details-grid">
          <Grid container justify="space-between" className="dashboard-details-subcontainer">
            <Grid item xs={6}>
              <p>Selected accounts</p>
              <p>{(selectedAccounts || []).join(', ')}</p>
              <p></p>
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={3} className="btn-container" style={{ maxWidth: '100px', float: 'right' }}>
                <InputButton
                  className={`outlined-btn`}
                  label="Export"
                  variant="outlined"
                  onClick={() => setshowExportDialog(true)}
                  isDisabled={tableDataLength === 0 ? true : false}
                />
              </Grid>
              {/* <TableExport
                      totalRecords={tableDataLength}
                      dashboard={true}
                      headers={tableHeaderColumns}
                      data={ExporttableRawData}
                      filename={'DashboardSummary.csv'}
                      onDataUpdate={onDataUpdate}
                      setFieldValue={setFieldValue}
                      formikValues={values}
                      onClick={printDocument}
                      tableData={tableData}
                      setSortByOrder={setSortByOrder}
                    /> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid className="w-full" item id="dashboardView">
          <Grid container spacing={2} justify="flex-end" direction="column">
            <Grid className="w-full" item>
              <ShipmentDetailView
                id="customers"
                rows={tableData}
                addSelectedRows={addSelectedRows}
                columns={tableColumns}
                filtering={showFilter}
                pageSize={pageSize}
                tableLayout="fixed"
                totalCount={pageDetails.totalRecordsLength}
                count={pageDetails.totalRecordsLength}
                isLoading={isLoading}
                inBuiltEvents={{
                  onChangeRowsPerPage: (e) => onPageSizeChange(e),
                  onChangePage: (a) => onPageNumChange(a),
                  onFilterChange: (e) => onFilter_Change(e),
                }}
                page={pageNumber}
              />
            </Grid>
            <Grid className="btnMainContainer" item>
              <Grid className="btnContainer" container justify="flex-end" spacing={3}>
                <Grid item>
                  <InputButton
                    className="outlined-btn Delete-AddressBook"
                    isDisabled={selectedRows.length !== 1}
                    label="Track"
                    variant="outlined"
                    onClick={trackShipmet}
                  />
                </Grid>
                <Grid item>
                  <InputButton
                    className="outlined-btn Delete-AddressBook"
                    isDisabled={selectedRows.length < 1}
                    label="Add to monitor list"
                    variant="outlined"
                    onClick={addToMonitorList}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

Summary.propTypes = {};

const mapStateToProps = (state) => {
  return {
    dashboardDetails: ((state || {}).dashboardTracking || {}).dashboardTracking,
    userDetails: state.auth.userDetails,
  };
};

export default connect(mapStateToProps, null)(Summary);
