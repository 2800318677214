import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import FormRenderer from '../../../../../framework/Inputs/formRenderer/formRenderer';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import * as actions from '../../../../../store/actions/index';
import { SearchIcon } from '@material-ui/data-grid';
import { IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles(() => ({
  SearchMySchema: {
    marginTop: '4px',
  },
}));

const SearchSchemas = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let [isClearBtn, setIsClearBtn] = useState(false);

  const { 
    formField: { schemaSearchFor },
    setFieldValue,
    onAddressChange,
    formikValues,
    setFieldTouched,
  } = props;

  const getClear = (event = null) => {
    if (event) {
      event.preventDefault();
    }
    setIsClearBtn(false);
    setFieldValue('schemaSearchFor', '');
    setFieldTouched('schemaSearchFor', false)
    dispatch(actions.setAddressBookSearchParams('&schemaName='));
    onAddressChange(true);
  };

  const getAddresses = (event = null) => {
    if (event) {
      event.preventDefault();
    }
    setIsClearBtn(true);
    onAddressChange(true);
  };

  useEffect(() => {
    setIsClearBtn(false);
  }, [props.formikValues.schemaSearchFor]);

  useEffect(() => {
    dispatch(actions.setAddressBookSearchParams(`&schemaName=${encodeURIComponent(formikValues.schemaSearchFor)}`));
  }, [dispatch, formikValues]);

  useEffect(() => {
    setFieldValue('schemaSearchFor', '');
  }, [setFieldValue]);
  return (
    <div className={`${classes.SearchMySchema} SchemaNameSearch`}>
      <Grid container direction="row" justify="flex-end" alignItems="flex-start" spacing={3}>
        <Grid item xs={12} sm={10} lg={9}>
          <FormRenderer
            style={{ boxShadow: '0px 3px 6px #00000019', height: '43px' }}
            {...schemaSearchFor.props}
            endArguments={{
              endAdornment: isClearBtn
                ? (
                  <IconButton aria-label="search" onClick={(event) => getClear(event)}>
                    <ClearIcon />
                  </IconButton>
                )
                : (
                  <IconButton className="SearchSchemaIcon" disabled={(formikValues.schemaSearchFor === '')} aria-label="search" type="submit" onClick={(event) => getAddresses(event, '')}>
                    <SearchIcon />
                  </IconButton>
                ),
            }}
            fullWidth
          />

          {/* 
          <FormRenderer
            {...schemaSearchFor.props}
            endArguments={{
              endAdornment: (
                <IconButton onClick={(event) => getAddresses(event, '')}>
                  <SearchIcon />
                </IconButton>
              ),
            }}
            fullWidth
          /> */}
        </Grid>
      </Grid>
    </div>
  );
};

SearchSchemas.propTypes = {
  formField: PropTypes.object,
  schemaSearchFor: PropTypes.string,
  setFieldValue: PropTypes.func,
  onAddressChange: PropTypes.func,
  formikValues: PropTypes.object,
};

export default SearchSchemas;
