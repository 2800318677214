import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import FormRenderer from '../../../../framework/Inputs/formRenderer/formRenderer';
import { SearchIcon } from '@material-ui/data-grid';
import ClearIcon from '@material-ui/icons/Clear';
import { Grid, Menu, MenuItem, IconButton, Box, Divider, SvgIcon } from '@material-ui/core';
import { Children } from 'react';
import { useSelector } from 'react-redux';

import './dialogForTemplate.scss';
const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles({
  root: {
    height: '380px',
    width: '400px',
    marginLeft: '780px',
    marginTop: '100px',
  },
});

// function SimpleDialog(props) {
//   const classes = useStyles();
//   const { onClose, selectedValue, open } = props;

//   const handleClose = () => {
//     onClose(selectedValue);
//   };

//   const handleListItemClick = (value) => {
//     onClose(value);
//   };

//   return (
//     <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
//       <DialogTitle id="simple-dialog-title">Set backup account</DialogTitle>
//       <List>
//         {emails.map((email) => (
//           <ListItem button onClick={() => handleListItemClick(email)} key={email}>
//             <ListItemAvatar>
//               <Avatar className={classes.avatar}>
//                 <PersonIcon />
//               </Avatar>
//             </ListItemAvatar>
//             <ListItemText primary={email} />
//           </ListItem>
//         ))}

//         <ListItem autoFocus button onClick={() => handleListItemClick('addAccount')}>
//           <ListItemAvatar>
//             <Avatar>
//               <AddIcon />
//             </Avatar>
//           </ListItemAvatar>
//           <ListItemText primary="Add account" />
//         </ListItem>
//       </List>
//     </Dialog>
//   );
// }

// SimpleDialog.propTypes = {
//   onClose: PropTypes.func.isRequired,
//   open: PropTypes.bool.isRequired,
//   selectedValue: PropTypes.string.isRequired,
// };

export default function SimpleDialogDemo(props) {
  const classes = useStyles();
  let  alertInfo = useSelector((state) => state.alertInfo.showAlert);
  // const { onClose, selectedValue, open, data ,
  //     formField: { templateSearch },isClearBtn,onClickClear,onClickSearch} = props;
  const {
    // formField: { templateSearch },
    setFieldValue,
    setValues,
    templateData,
    formikValues,
    errors,
    resetForm,
    isDomestic,
    backUpResponse,
    currencies,
    countries,
    onClose,
    selectedValue,
    open,
    data,
    isClearBtn,
    onClickClear,
    onClickSearch,
  } = props;

  const handleListItemClick = (value) => {
    onClose(value);
  };
  return (
    <Grid className="template-dialog-container">
      <Dialog
        className={`Template-DialogContainer ${props.className} ${alertInfo ? "alertDialogContainer" : ""}`}
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        BackdropProps={{ style: { opacity: 0 } }}
      >
        {props.children}
      </Dialog>
    </Grid>
  );
}
