import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { Link } from 'react-router-dom';
import CardContent from '@material-ui/core/CardContent';
import dhlLoginImg from './../../assets/images/dhlLoginImg.svg';
import './login.scss';
import { Button, TextField, Grid, FormControlLabel, Checkbox, Container } from '@material-ui/core';
import Copyright from '../copyright/copyright';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    display: 'flex',
    boxShadow: '0px 3px 6px #00000029',
    border: '1px solid #E0E0E0',
    opacity: 1,
  },
  Card: {
    boxShadow: 0,
  },
  paper: {
    paddingTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#D40511',
    color: 'white',
    textTransform: 'none',
  },
  Register: {
    margin: theme.spacing(3, 0, 2),
    borderColor: '#D40511',
    color: '#D40511',
    textTransform: 'none',
  },
}));

const Login = (props) => {
  const classes = useStyles();
  const [isLoginInProgress, setLoginInProgress] = useState(false);
  const userDetails = useSelector((state) => state.auth.userDetails);

  const getUserInfo = () => {
    setLoginInProgress(true);
    userDetails?.userId && props.history.push('/shipment/addShipment');
  };

  useEffect(() => {
    if (isLoginInProgress && userDetails?.userId) {
      props.history.push('/shipment/addShipment');
    }
  }, [userDetails]);

  return (
    <div>
      <CardContent>
        <Card className={classes.root}>
          <Grid container>
            <Grid item xs style={{ height: '320px' }}>
              <img src={dhlLoginImg} alt="Welcome to Universal Shipping Platform" className="ImageLogin"></img>
            </Grid>

            <Grid item xs className="Grid2">
              <Container component="main" maxWidth="xs">
                <div className={classes.paper}>
                  {/* <Typography component="h5" variant="h6">
                    Please Login using your credentials
                  </Typography> */}
                  <form className={classes.form} noValidate>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="email"
                      label="User Name"
                      name="email"
                      autoComplete="email"
                    />
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                    />
                    <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me " />
                    <Button
                      disabled={isLoginInProgress}
                      type="submit"
                      fullWidth
                      variant="contained"
                      className={classes.submit}
                      component={Link}
                      onClick={getUserInfo}
                    >
                      Login
                    </Button>
                    <Grid container className="ForgotPassword">
                      <Grid item xs>
                        <Link href="#" variant="body2" className="ForgotPasswordText">
                          Forgot password
                        </Link>
                      </Grid>
                    </Grid>
                  </form>
                  <br></br>
                  <br></br>
                  <br></br>
                  {/* <Grid item xs>
                    <Link href="#" variant="body2">
                      New User? Please Register
                    </Link>
                  </Grid>
                  <Button type="submit" fullWidth variant="outlined" className={classes.Register}>
                    <strong>Register</strong>
                  </Button> */}
                </div>
              </Container>
            </Grid>
          </Grid>
        </Card>
      </CardContent>

      <Copyright className="copy" />
    </div>
  );
};

export default Login;
