/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Grid, SvgIcon } from '@material-ui/core';
import CustomAccordion from '../../../../framework/accordion/customAccordion';
import FormRenderer from '../../../../framework/Inputs/formRenderer/formRenderer';
import BarChartComponent from '../../../../framework/charts/barChart/BarChartComponent';
import { abortOnGoingCallsFor, axiosConfig } from '../../../../api/axios';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { setDropdownValues } from '../../../../utils/helperFunctions';
import InputButton from '../../../../framework/Inputs/inputButton/inputButton';
import { ReactComponent as ArrowDetails } from '../../../../assets/images/arrow_details.svg';

import { Link as RouterLink } from 'react-router-dom';
import { ServiceEndPoints } from '../../../../utils/constants/ApiConstant';

import * as actions from '../../../../store/actions/index';
import { getDefaultDashboardValues, getBackGroundColor } from '../../../../utils/helperFunctions';
import { useDebouncedCallback } from 'use-debounce/lib';
import { arrayValuesToQueryStringParam } from '../../../../utils/common';

const BreakDownDashboard = (props) => {
  const {
    formField: { product, domesticService },
    setFieldValue,
    formikValues,
    countries,
    setProductData,
    setServiceData,
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const [serviceName, setServiceName] = useState([]);
  const [serviceCount, setServiceCount] = useState([]);
  const [totalCountForDomestic, setTotalCountForDomestic] = useState(8);
  const [serviceColorsArray, setServiceColors] = useState([]);
  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);

  useEffect(() => {
    if (formikValues.fromCollectionDate && formikValues.toCollectionDate && formikValues.accounts.length > 0) {
      getBreakdownValues();
    }
  }, [
    formikValues.accounts,
    formikValues.fromCollectionDate,
    formikValues.toCollectionDate,
    formikValues.isDomestic,
    formikValues.internationalCountry,
    countries,
  ]);

  const getBreakdownValues = useDebouncedCallback(() => {
    setServiceName([]);
    setServiceCount([]);
    setServiceColors([]);
    let url = `${ServiceEndPoints.getBreakDownDetails}breakdown?${getDefaultDashboardValues(
      formikValues,
    )}&${arrayValuesToQueryStringParam(formikValues.accounts)}`;
    abortOnGoingCallsFor(url);
    axiosConfig(url)
      .then((res) => {
        const rawProducts = [];
        rawProducts.unshift({ productName: 'Select', value: '' });
        res.data.shipmentByProducts.forEach((singleProduct) => {
          singleProduct.value = singleProduct?.productName?.replace(/ /g, '');
          rawProducts.push(singleProduct);
        });
        setProducts(rawProducts);
        setProductData(rawProducts);
        // setTimeout(() => {
        // }, 2000);
      })
      .catch((err) => {
        console.log(err);
        setProducts([]);
        setServices([]);
        setServiceName([]);
        setServiceCount([]);
        setServiceColors([]);
      })
      .finally(()=> {
      dispatch(
        actions.setDashboardTracking({
          loadedFrom: 'detailView',
          loadedResponse: formikValues,
          dashboardQueryParams: getDefaultDashboardValues(formikValues),
          accountsParams: arrayValuesToQueryStringParam(formikValues.accounts),
        }),
      )});
  }, 400);

  useEffect(() => {
    const serviceNames = [];
    const serviceGraphValues = [];
    const serviceColorArray = [];
    const rawServices = [];
    if (formikValues.product && formikValues.isDomestic) {
      if (rawServices.length > 0) {
        rawServices.unshift({ serviceName: 'Select', value: '' });
      }
      setTotalCountForDomestic((formikValues.product || {}).totalCount || 8);
      ((formikValues.product || {}).shipmentByServices || []).forEach((service) => {
        service.value = service.serviceName.replace(/ /g, '');
        rawServices.push(service);
        serviceNames.push(service.serviceName);
        serviceGraphValues.push(service.totalCount);
        serviceColorArray.push(getBackGroundColor(service.serviceName));
      });
    } else if (!(formikValues.product || {}).value && !formikValues.isDomestic) {
      const slicedProducts = [...products].slice(1);
      let maxCount = 0;
      slicedProducts.forEach((product) => {
        serviceNames.push(product.productName);
        serviceGraphValues.push(product.totalCount);
        serviceColorArray.push(getBackGroundColor(product.productName));
        maxCount = product.totalCount > maxCount ? product.totalCount : maxCount;
      });
      setTotalCountForDomestic(maxCount);
    } else if ((formikValues.product || {}).value && !formikValues.isDomestic) {
      setTotalCountForDomestic((formikValues.product || {}).totalCount);
      ((formikValues.product || {}).shipmentByStatuses || []).forEach((status) => {
        serviceNames.push(status.status);
        serviceGraphValues.push(status.totalCount);
        serviceColorArray.push(getBackGroundColor(status.status));
      });
    }
    setServices(rawServices);
    setServiceName(serviceNames);
    setServiceCount(serviceGraphValues);
    setServiceColors(serviceColorArray);
  }, [formikValues.isDomestic, formikValues.product, products, formikValues.internationalCountry]);

  useEffect(() => {
    if (formikValues.isDomestic) {
      const parcelObj = setDropdownValues(products, 'value', 'Parcels');
      setFieldValue('product', parcelObj ? parcelObj : products[0]);
    } else {
      const parcelObj = setDropdownValues(products, 'value', '');
      setFieldValue('product', parcelObj ? parcelObj : products[0]);
    }
    const responseFormikValues = props.dashboardDetails.loadedResponse;
    if (
      props.dashboardDetails.loadedFrom === 'dashboardDetails' &&
      products.length > 0 &&
      responseFormikValues?.product
    ) {
      setFieldValue('product', setDropdownValues(products, 'value', (responseFormikValues.product || {}).value));
    }
  }, [products]);

  useEffect(() => {
    const responseFormikValues = props.dashboardDetails.loadedResponse;
    const serviceObj = setDropdownValues(services, 'serviceName', 'Select');
    setFieldValue('domesticService', serviceObj);
    if (
      props.dashboardDetails.loadedFrom === 'dashboardDetails' &&
      services.length > 0 &&
      responseFormikValues?.domesticService
    ) {
      setFieldValue(
        'domesticService',
        setDropdownValues(services, 'value', responseFormikValues.domesticService.value),
      );
    }
  }, [services]);

  useEffect(() => {
    const labels = [];
    const serviceGraphValues = [];
    const serviceColorArray = [];
    if ((formikValues.domesticService || {}).value && formikValues.isDomestic) {
      setTotalCountForDomestic((formikValues.domesticService || {}).totalCount);
      ((formikValues.domesticService || {}).shipmentByStatuses || []).forEach((status) => {
        labels.push(status.status);
        serviceGraphValues.push(status.totalCount);
        serviceColorArray.push(getBackGroundColor(status.status));
      });
    } else if (formikValues.isDomestic && !(formikValues.domesticService || {}).value) {
      ((formikValues.product || {}).shipmentByServices || []).forEach((service) => {
        labels.push(service.serviceName);
        serviceGraphValues.push(service.totalCount);
        serviceColorArray.push(getBackGroundColor(service.serviceName));
      });
      setTotalCountForDomestic((formikValues.product || {}).totalCount || 8);
    }
    setServiceName(labels);
    setServiceCount(serviceGraphValues);
    setServiceColors(serviceColorArray);
  }, [formikValues.domesticService]);

  const dispatchingAction = () => {
    let breakDownQueryParams = formikValues.product
      ? formikValues.product?.productName !== undefined && formikValues.product?.productName?.toLowerCase() !== 'select'
        ? `&product=${formikValues.product?.productName}`
        : ''
      : '';
    if (formikValues.isDomestic) {
      breakDownQueryParams += formikValues.product?.productName
        ? formikValues.domesticService?.serviceName !== undefined &&
          formikValues.domesticService?.serviceName?.toLowerCase() !== 'select'
          ? `&service=${formikValues.domesticService?.serviceName}`
          : ''
        : '';
    }
    dispatch(
      actions.setDashboardTracking({
        loadedFrom: 'detailView',
        loadedResponse: formikValues,
        dashboardQueryParams: getDefaultDashboardValues(formikValues) + breakDownQueryParams,
        accountsParams: arrayValuesToQueryStringParam(formikValues.accounts),
        products: [...products].slice(1),
        countries,
        services: [...services].slice(1),
      }),
    );
    history.push('/dashboard/dashboardDetailsBreakdown');
  };

  useEffect(() => {
    setServiceData([...services].slice(1));
    setProductData([...products].slice(1));
  }, [products, services]);

  return (
    <Grid
      className={`${formikValues.isDomestic ? 'domestic-chart' : 'international-chart'} breakdown-section`}
      container
      spacing={3}
      direction="row"
      alignItems="flex-start"
      style={{ margin: '10px' }}
    >
      <CustomAccordion heading="Breakdown">
        <Grid className="breakdown-container" container alignItems="flex-start" spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {formikValues.isDomestic ? (
              <Grid container spacing={3} direction="row" alignItems="flex-start">
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormRenderer {...product.props} data={products} fullWidth isRequired={true} />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormRenderer {...domesticService.props} data={services} fullWidth />
                </Grid>
                {(formikValues || {}).accounts.length > 0 ? (
                  <BarChartComponent
                    labels={serviceName}
                    values={serviceCount}
                    totalCount={totalCountForDomestic}
                    colors={serviceColorsArray}
                    formikValues={formikValues}
                  />
                ) : null}
              </Grid>
            ) : (
              <Grid container spacing={3} direction="row" alignItems="flex-start">
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <FormRenderer {...product.props} data={products} fullWidth />
                </Grid>
                {(formikValues || {}).accounts.length > 0 ? (
                  <BarChartComponent
                    labels={serviceName}
                    values={serviceCount}
                    totalCount={totalCountForDomestic}
                    colors={serviceColorsArray}
                    formikValues={formikValues}
                  />
                ) : null}
              </Grid>
            )}
          </Grid>
          <Grid className="BreakDBtn" item xs={12} sm={12} md={12} lg={12}>
            <Grid container alignItems="flex-start" direction="row" justify="flex-end">
              <Grid item>
                <InputButton
                  style={{ textTransform: 'none', fontSize: '16px' }}
                  label="Detail view"
                  endIcon={<SvgIcon fontSize="large" component={ArrowDetails} />}
                  component={RouterLink}
                  onClick={dispatchingAction}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CustomAccordion>
    </Grid>
  );
};

export default BreakDownDashboard;
