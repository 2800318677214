import * as actionTypes from '../actions/actionsTypes';
import { updateObject } from '../../utils/common';

const initialState = {
  key: '',
};

const setKeyPressEvent = (state, action) => {
  return updateObject(state, {
    key: action.data,
  });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.KEYPRESSEVENT:
      return setKeyPressEvent(state, action);
    default:
      return state;
  }
};

export default reducer;
