/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import FormRenderer from '../../../../framework/Inputs/formRenderer/formRenderer';
import { fullSearchDashboardByOptions } from '../../../../utils/constants/constants';
import './findShipment.scss';
import { GET } from '../../../../api/axios';
import { ServiceEndPoints } from '../../../../utils/constants/ApiConstant';
import { useHistory } from 'react-router-dom';
import { arrayValuesToQueryStringParam } from '../../../../utils/common';
import { useDispatch } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import { getDefaultDashboardValues, getDefaultDashboardFindValues } from '../../../../utils/helperFunctions';
import { useSelector } from 'react-redux';
const FindShipment = (props) => {
  const {
    formField: { dashboardSearchBy, collectionDate },
    setFieldValue,
    formikValues,
    errors,
    userAccounts,
    tabIndex,
    products,
    services,
    countries,
  } = props;
  let history = useHistory();
  const dispatch = useDispatch();
  let dashboardFindShipment = useSelector((state) => state.dashboardTracking.dashboardFindShipment);

  const searchShipment = (event = null) => {
    event.preventDefault();
    let searchedValue = props.formikValues.dashboardSearchFor.trim();
    let searchedParams = props.formikValues.dashboardSearchBy.value;
    let accountsValue = '';
    accountsValue = arrayValuesToQueryStringParam(formikValues.accounts);

    const isCollectionDatesReq = searchedParams === 'dispatchDate';
    if (isCollectionDatesReq) {
      searchedParams = '';
      searchedParams += `fromCollectionDate=${searchedValue}`;
      searchedParams += `&toCollectionDate=${searchedValue}`;
    }
    let url = '';
    let _temp_userAccounts = [];
    userAccounts.map((element) => {
      _temp_userAccounts.push(element.accountId);
    });
    if (isCollectionDatesReq) {
      url = `${ServiceEndPoints.getShipmentDashboard}?${getDefaultDashboardFindValues(
        formikValues,
        _temp_userAccounts,
        true,
        false,
      )}${searchedParams}`;
    } else {
      url = `${ServiceEndPoints.getShipmentDashboard}?${getDefaultDashboardFindValues(
        formikValues,
        _temp_userAccounts,
        false,
        false,
      )}${searchedParams}=${searchedValue}`;
    }
    url = `${url}&offset=0&limit=10`;
    GET(url)
      .then((res) => {
        if ((((res || {}).data || {}).shipmentModels || []).length === 1) {
          history.push(`/dashboard/Tracking?id=${res.data.shipmentModels[0].shipmentId}`);
        } else {
          // dispatch(actions.setDashboardTracking({ loadedFrom: 'findshipment', loadedResponse: formikValues }));
          dispatch(
            actions.setDashboardTracking({
              loadedFrom: 'findshipment',
              loadedResponse: formikValues,
              userAccounts: _temp_userAccounts,
              dashboardQueryParams: isCollectionDatesReq
                ? `${getDefaultDashboardFindValues(
                  formikValues,
                  _temp_userAccounts,
                  true,
                  // false,
                )}${searchedParams}`
                : `${getDefaultDashboardFindValues(
                  formikValues,
                  _temp_userAccounts,
                  false,
                  // false,
                )}${searchedParams}=${searchedValue}`,
              accountsParams: arrayValuesToQueryStringParam(formikValues.accounts),
              tabIndex,
              products,
              countries,
              services,
            }),
          );
          history.push('/dashboard/dashboardDetailsBreakdown');
        }
      })
      .catch((err) => {
        alert('Shipment not found');
      });
  };

  useEffect(() => {
    // need to change hard coded index values
    if (
      formikValues.dashboardSearchBy &&
      (formikValues.dashboardSearchBy.value === fullSearchDashboardByOptions[8].value ||
        formikValues.dashboardSearchBy.value === fullSearchDashboardByOptions[9].value)
    ) {
      setFieldValue('dashboardSearchFor', new Date().toISOString()
.slice(0, 10));
    } else {
      setFieldValue('dashboardSearchFor', '');
    }
    if (props.formikValues.dashboardSearchBy) {
      dispatch(
        actions.setDashboardFindShipment(
          props.formikValues.dashboardSearchBy
        ))
    }
    else if (dashboardFindShipment) {
      dispatch(
        actions.setDashboardFindShipment(
          dashboardFindShipment
        ))
    } else {
      dispatch(
        actions.setDashboardFindShipment(
          fullSearchDashboardByOptions[0]
        ))
    }
  }, [formikValues.dashboardSearchBy]);

  useEffect(() => {
    if (dashboardFindShipment) {
      setTimeout(() => {
        setFieldValue('dashboardSearchBy', dashboardFindShipment)
      }, 1000);
    } else {
      setFieldValue('dashboardSearchBy', fullSearchDashboardByOptions[0])
    }
  }, [dashboardFindShipment]);

  return (
    <Grid container className="searchContainer" spacing={3} direction="row" alignItems="flex-start">
      <Grid className="searchText" item xs={12}>
        Find shipment
      </Grid>
      <Grid className="searchControl" item xs={12}>
        <FormRenderer
          data={fullSearchDashboardByOptions}
          selectProps={dashboardSearchBy.selectProps.props}
          textProps={dashboardSearchBy.textProps.props}
          onSearchClick={(event) => searchShipment(event, '')}
          isIconDisabled={
            errors.dashboardSearchFor ||
            // formikValues.accounts.length === 0 ||
            formikValues.dashboardSearchFor.trim() === ''
          }
          {...dashboardSearchBy.props}
        />
      </Grid>
      {/* {(props.formikValues.dashboardSearchBy && props.formikValues.dashboardSearchBy.value === "dispatchdeliverydate") &&
            <FormRenderer {...collectionDate.props} />
        } */}
    </Grid>
  );
};

FindShipment.propTypes = {
  formField: PropTypes.object,
  formikValues: PropTypes.object,
  setFieldValue: PropTypes.func,
  errors: PropTypes.object,
};

export default FindShipment;
