import * as actionTypes from './actionsTypes';

export const setDashboardTracking = (newAccount) => {
  
  return {
    type: actionTypes.SETDASHBOARDTRACKING,
    data: newAccount,
  };
};
export const setDashboardFindShipment = (dashboardFindShipment) => {
  return {
    type: actionTypes.SETDASHBOARDFINDSHIPMANET,
    data: dashboardFindShipment,
  };
};

