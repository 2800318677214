import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { abortOnGoingCallsFor, axiosConfig, GET } from '../../../api/axios';
import { useSelector, useDispatch } from 'react-redux';
import FormRenderer from '../../../framework/Inputs/formRenderer/formRenderer';
import * as actions from '../../../store/actions/index';
import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';
import {
  getDomesticAccounts,
  getWorkingDays,
  getFMDAccounts,
  getDriverCollectionAccounts,
} from '../../../utils/helperFunctions';
import { doorStepInstructionOptions } from '../../../utils/constants/constants';

const Basicdetails = (props) => {
  const dispatch = useDispatch();
  const {
    formField: { returnType, accounts, collectionDate },
    setFieldValue,
    formikValues,
  } = props;

  let userAccounts = useSelector((state) => state.accounts.nonReturnuserAccounts);
  const loadedFrom = useSelector((state) => state.loadedFrom.loadedFrom);
  let [displayAccounts, setDisplayAccounts] = useState([]);
  let [domesticAccounts, setDomesticAccounts] = useState(userAccounts);
  const [isDisPatchDateVisible, setIsDispatchDateVisible] = useState(true);

  useEffect(() => {
    userAccounts = getDomesticAccounts(userAccounts);
    setDisplayAccounts(userAccounts);
  }, [userAccounts]);

  useEffect(() => {
    if (formikValues.handOverMethod === 'servicePoint') {
      setIsDispatchDateVisible(false);
    } else if (formikValues.handOverMethod === 'driverCollection') {
      setIsDispatchDateVisible(true);
    }
  }, [formikValues.handOverMethod])
  useEffect(() => {
    const { accountNumber } = props.formikValues;
    if (accountNumber) {
      dispatch(actions.setSelectedAccount(accountNumber));
    }
  }, [props.formikValues.accountNumber]);

  const [workingDays, setWorkingDays] = useState([]);

  const setActualWorkingDays = async () => {
    let holidayRegion = '';
    if (formikValues?.accountNumber?.sender?.postalCode) {
      abortOnGoingCallsFor(ServiceEndPoints.getPostcodeDistrict);
      await axiosConfig
        .get(`${ServiceEndPoints.getPostcodeDistrict}?postcode=${formikValues.accountNumber.sender.postalCode}`)
        .then((res) => {
          holidayRegion = res?.data?.ukHolidayRegion;
        })
        .catch((err) => console.log(err))
    };
    if (formikValues.accountNumber?.configuration) {
      abortOnGoingCallsFor(ServiceEndPoints.getPublicHolidays);
      axiosConfig.get(`${ServiceEndPoints.getPublicHolidays}?countryCode=GB`)
        .then((res) => {
          const publicHolidays = holidayRegion
            ? res.data?.publicHolidays.filter((holiday) => holiday.region === holidayRegion)
            : res.data?.publicHolidays;
          getWorkingDays(publicHolidays, setWorkingDays);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  useEffect(() => {
    setActualWorkingDays();
  }, [formikValues.accountNumber]);

  useEffect(() => {
    if (
      workingDays.length > 0 &&
      (!formikValues.collectionDate ||
        (formikValues.collectionDate &&
          new Date(formikValues.collectionDate).getTime() < new Date(workingDays[0]).getTime()))
    ) {
      setFieldValue('collectionDate', workingDays[0]);
    }
  }, [formikValues.collectionDate, setFieldValue, workingDays]);

  useEffect(() => {
    if (loadedFrom === '') {
      setFieldValue('collectionDate', workingDays.length > 0 ? workingDays[0] : '');
    }
  }, [workingDays]);

  useEffect(() => {
    const { handOverMethod } = props.formikValues;
    if (handOverMethod === 'driverCollection' && props.formikValues.accountNumber === '') {
      const domesticCollectionAccounts = getDriverCollectionAccounts(displayAccounts);
      domesticCollectionAccounts.length > 0 ? setFieldValue('accountNumber', domesticCollectionAccounts[0]) : setFieldValue('accountNumber', '');
    }
  }, [displayAccounts, props.formikValues.handOverMethod]);

  return (
    <Grid className="wd-100 Top-10px">
      <Grid container spacing={3} direction="row">
        <Grid item xs={6} sm={9} md={9} lg={9}>
          <FormRenderer {...returnType.props} data={ReturnTypes} />
        </Grid>
        <Grid className="ac-sel-numb AutoComplete_inputRoot_parent_class" item xs={12} sm={3} md={3} lg={3}>
          <FormRenderer variant="filled" {...accounts.props} fieldValue={formikValues.accountNumber} valuePropertyName="accountId" data={displayAccounts} fullWidth />
        </Grid>
      </Grid>
    </Grid>
  );
};

Basicdetails.propTypes = {
  setFieldValue: PropTypes.func,
  formField: PropTypes.object,
  returnType: PropTypes.object,
  accounts: PropTypes.object,
  collectionDate: PropTypes.object,
  accountNumber: PropTypes.object,
  formikValues: PropTypes.object,
};

export default Basicdetails;

const ReturnTypes = [
  {
    value: 'returnToSender',
    label: 'Return to sender',
  },
  {
    value: 'sendToThirdParty',
    label: 'Send to 3rd party',
  },
];
