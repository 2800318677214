import React from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  FormControl,
  FormHelperText,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Grid,
  withStyles,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 24,
    height: 24,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },

    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 24,
      height: 24,
      backgroundImage: 'radial-gradient(#000000,20%,transparent 25%)',
      content: '""',
    },
  },
});

const InputRadio = (props) => {
  const { label, data, labelPlacement, isDisabled, isRequired, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const { setValue } = helper;
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;

  const renderHelperText = () => {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  function StyledRadio(props) {
    const classes = useStyles();
    return (
      <Radio
        className={classes.root}
        disableRipple
        color="default"
        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
        icon={<span className={classes.icon} />}
        {...props}
      />
    );
  }

  return (
    <FormControl {...rest} error={isError} style={{ width: '112%' }}>
      <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2} disabled={isDisabled}>
        {label !== undefined
? (
          <Grid item>
            {isRequired
? (
              <FormLabel disabled={isDisabled}>
                {label} <span className="span-required">*</span>
              </FormLabel>
            )
: (
              <FormLabel disabled={isDisabled}>{label}</FormLabel>
            )}
          </Grid>
        )
: null}
        <Grid item>
          <RadioGroup value={field.value} name={props.name} onChange={handleChange} row>
            {data.map((item, index) => (
              <FormControlLabel
                disabled={isDisabled}
                key={index}
                {...item}
                control={<StyledRadio />}
                labelPlacement={labelPlacement}
                checked={item.value === meta.value}
                style={{ marginRight: '20px' }}
              />
            ))}
          </RadioGroup>
        </Grid>
      </Grid>
      {renderHelperText()}
    </FormControl>
  );
};

InputRadio.defaultProps = {
  data: [],
  labelPlacement: 'end',
  isDisabled: false,
};

InputRadio.propTypes = {
  data: PropTypes.array.isRequired,
  labelPlacement: PropTypes.string,
  label: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default InputRadio;
