import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    primary: {
      color: '#76BD22 !important',
      height:'230px !important',
      width:'230px !important'
    },
    sizeAndColor:{
        fontSize: '2.75rem',
        color: '#76BD22 !important',
    }
  }));

function CircularProgressWithLabel(props) {
    const classes = useStyles();

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props}  className={classes.primary} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" className={classes.sizeAndColor} color="textSecondary">{props.text}</Typography>  
     </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired
};

export default CircularProgressWithLabel;