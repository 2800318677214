import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import InvoiceTypeDeclaration from '../customsDeclaration/InvoiceTypeDeclaration/InvoiceTypeDeclaration';
import AddDetailsForCustomDeclaration from '../customsDeclaration/addDetailsForCustomDeclaration/addDetailsForCustomDeclaration';
import PropTypes from 'prop-types';
import BasicDeclaration from './basicDeclaration';
import ShippingDetails from './shippingDetails';
import './customsDeclaration.scss';
import CommodityDetails from './commodityDetails/commodityDetails';

const CustomsDeclaration = (props) => {
  const { isAddNewAddress, setFieldValue, formikValues, internationalProductList, setInternationalProductList, reasonsForExport} = props;

  const [customLevel, setCustomLevel] = useState(false);

  useEffect(() => {
    const accountFdaNumber =
      (formikValues.accountNumber?.customsRegistration?.fdaNumber === null || formikValues.accountNumber?.customsRegistration?.fdaNumber === undefined)
        ? ''
        : formikValues.accountNumber?.customsRegistration?.fdaNumber;
    setFieldValue('FDARegistrationNumber', accountFdaNumber);
  }, [formikValues.accountNumber, setFieldValue]);

  useEffect(() => {
    if (formikValues?.destinationCountry?.name === 'Select') {
      setCustomLevel(true);
    } else {
      formikValues.isFullCustomDetails ? setCustomLevel(true) : setCustomLevel(false);
    }
  }, [formikValues.isFullCustomDetails]);

  return (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      spacing={2}
      fullwidth
      className="customDeclaration-container"
    >
      {customLevel ? (
        <>
          {!isAddNewAddress ? (
            <>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <InvoiceTypeDeclaration {...props} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <ShippingDetails {...props} />
              </Grid>
            </>
          ) : null}

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CommodityDetails {...props} />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <AddDetailsForCustomDeclaration {...props} />
          </Grid>
        </>
      ) : (
        <BasicDeclaration {...props} />
      )}
    </Grid>
  );
};

CustomsDeclaration.propTypes = {
  formField: PropTypes.object,
  formikValues: PropTypes.object,
  currencies: PropTypes.array,
};

export default CustomsDeclaration;
