import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import './customAccordion.scss';

const DashBoardAccordian = ({ heading, defaultExpanded, secondaryheading, children }) => {
  return (
    <Grid className="accordion-Container">
      <Accordion className="custom-accordion" defaultExpanded={defaultExpanded}>
        <AccordionSummary
          className="expandedPanel"
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div style={{ display: 'inline-flex', width: '100%' }}>
            <div>{heading}</div>
            {secondaryheading !== '' ? <div style={{ marginLeft: 'auto' }}>Current:{secondaryheading}</div> : null}
          </div>
        </AccordionSummary>
        <AccordionDetails className="content">{children}</AccordionDetails>
      </Accordion>
    </Grid>
  );
};

DashBoardAccordian.propTypes = {
  heading: PropTypes.string.isRequired,
  content: PropTypes.any,
  defaultExpanded: PropTypes.bool,
  children: PropTypes.object,
  secondaryheading: PropTypes.string,
};
DashBoardAccordian.defaultProps = {
  defaultExpanded: true,
  secondaryheading: '',
};

export default DashBoardAccordian;
