export const printerConfiguration = {
  formId: 'printerConfiguration',
  formField: {
    labelRadioTypes: {
      props: {
        name: 'labelRadioTypes',
        type: 'radio',
      },
      value: 'PrintLabel',
    },
    labelPrinter: {
      props: {
        name: 'labelPrinter',
        label: 'Print labels with printer',
        type: 'select',
        displayField: 'name',
        hasDefaultValue: false,
        isRequired: true,
      },
      validation: {
        name: 'labelPrinter',
        validationType: 'object',
        validations: [
          {
            type: 'required',
            params: ['Label printer is required'],
          },
          {
            type: 'test',
            params: [
              '',
              'Label printer is required',
              (value) => {
                return value?.value ? true : false;
              },
            ],
          },
        ],
      },
    },
    labelType: {
      props: {
        name: 'labelType',
        label: 'Label type',
        type: 'select',
        displayField: 'name',
        isRequired: true,
        hasDefaultValue: false,
      },
      validation: {
        name: 'labelType',
        validationType: 'object',
        validations: [
          {
            type: 'required',
            params: ['Label type is required'],
          },
          {
            type: 'test',
            params: [
              '',
              'Label type is required',
              (value) => {
                return value?.value ? true : false;
              },
            ],
          },
        ],
      },
    },
    pauseCount: {
      props: {
        name: 'pauseCount',
        label: 'Pause after how many labels',
        type: 'text',
      },
      validation: {
        name: 'pauseCount',
        validationType: 'number',
        validations: [
          {
            type: 'typeError',
            params: ['Only numbers allowed'],
          },
          {
            type: 'min',
            params: [1, 'Pause count should be greater than 0'],
          },
        ],
      },
    },
    otherReportsPrinter: {
      props: {
        name: 'otherReportsPrinter',
        label: 'Print all other reports with',
        type: 'select',
        displayField: 'name',
        hasDefaultValue: false,
      },
    },
    labelSize: {
      props: {
        name: 'labelSize',
        label: 'Label size',
        type: 'select',
        displayField: 'name',
        isRequired: true,
        hasDefaultValue: false,
      },
      validation: {
        name: 'labelSize',
        validationType: 'object',
        validations: [
          {
            type: 'test',
            params: [
              '',
              'Label size is required',
              (value) => {
                return value?.value === 1 || value?.value === 0 ? true : false;
              },
            ],
          },

        ],
      },
    },
    jspmVersion: {
      props: {
        name: 'jspmVersion',
        label: 'JSPM Version',
        type: 'select',
        displayField: 'name',
        hasDefaultValue: false,
        isRequired: true,
      },
      validation: {
        name: 'jspmVersion',
        validationType: 'object',
         validations: [
          {
            type: 'test',
            params: [
              '',
              'JSPM version is required',
              (value) => {
                return value?.value === '3' || value?.value === '4' ? true : false;
              },
            ],
          },
         ],
      },
    },
  },
};
