import React, { useEffect, useRef, useState } from 'react';

import { Form, Formik } from 'formik';
import { Grid } from '@material-ui/core';
import { GET, POST } from '../../../api/axios';
import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';
import { useHistory } from 'react-router-dom';
import CustomAccordion from '../../../framework/accordion/customAccordion';
import DeliveryOptions from '../../shipment/addShipment/deliveryOptions/deliveryOptions';
import AdditionalInfo from '../../shipment/addShipment/additionalInfo/additionalInfo';
import CollectionDetails from '../CollectionDetails/collectionDetails';

import addNewAddressFrom from '../../../utils/formConfig/addNewAddressForm/addNewAddressForm';
import ParcelDetails from '../../shipment/addShipment/parcelDetails/parcelDetails';
import DestinationCountry from '../../../components/addressBook/AddNew/DestinationCountry';
import ProductAndServiceDetails from '../../shipment/addShipment/productAndServiceDetails/productAndServiceDetails';
import InternationalDeliveryOption from '../../../components/addShipment/DeliveryOptions/InternationalDeliveryOption';
import './addNewAddress.scss';
import DeliveryAddress from '../../shipment/addShipment/deliveryAddress/deliveryAddress';
import CustomsDeclaration from '../../shipment/addShipment/customsDeclaration/customsDeclaration';

import { getYupSchemaFromMetaData } from '../../../utils/yupSchema/yupSchemaGenerator';
import InputButton from '../../../framework/Inputs/inputButton/inputButton';
import {
  formatAddToFavReqObj,
  setValuesToForm,
  setValuesToCollectionDetailsFromAddressBook,
  dynamicSort,
  getCustomerAccountID,
  setDropdownValues,
} from '../../../utils/helperFunctions';
import { connect, useDispatch } from 'react-redux';
import * as actions from '../../../store/actions/index';
import CustomModal from '../../../framework/modal/modal';
import ConfirmationDialog from '../../../components/addressBook/confirmation/confirmation';
import { abortOnGoingCallsFor } from '../../../api/axios';
import { axiosConfig } from '../../../api/axios';

const AddNewAddress = (props) => {
  const { formId, formField } = addNewAddressFrom;
  const { onLoad, setLoaderLayOver } = props;
  const [countries, setCountries] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [isUpdateAddressBook, setisUpdateAddressBook] = useState('');
  const [addressBookResponse, setAddressBookResponse] = useState({});
  const [showDeliveryOptions, setShowDeliveryOptions] = React.useState(true);
  const [showAllAddressFields, setShowAllAddressFields] = React.useState(false);
  const [addNewAddressValidationSchema, setNewAddressValidationSchema] = useState(
    getYupSchemaFromMetaData(formField, [], []),
  );
  const formikRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const [confirmationMsg, setConfirmationMsg] = useState('');
  const [showCancelConfirmationDialog, setShowCancelConfirmationDialog] = useState(false);

  useEffect(() => {
    setisUpdateAddressBook(history.location.search);
    getCountriesList();
    getCurrenciesList();
    if (history.location.search) {
      populateAddressBook(history.location.search);
    }
  }, []);

  const populateAddressBook = (searchParams) => {
    searchParams = searchParams.replace('?', '');
    setLoaderLayOver(true);
    GET(`${ServiceEndPoints.getAddressBook}?organization=${getCustomerAccountID()}&${searchParams}&offset=0&limit=1`)
      .then((res) => {
        setAddressBookResponse(res.data?.addressBooks?.[0]);
        if(res.data?.addressBooks?.[0]?.deliveryAddress?.postCode){
          let postalCode = res.data?.addressBooks?.[0]?.deliveryAddress?.postCode;
          abortOnGoingCallsFor(ServiceEndPoints.getPostcodeDistrict);
          axiosConfig
          .get(`${ServiceEndPoints.getPostcodeDistrict}?postcode=${postalCode}`)
          .then((res) => {
            dispatch(actions.setPostalCodeDistrict(res?.data));
          })
          .catch((err) => {
            console.log(err);
            return null;
          });
  
        }
       

      })
      .catch((err) => {
        console.log(err);
        setLoaderLayOver(false);
      });
  };

  //Gets the list of All countries
  const getCountriesList = () => {
    GET(ServiceEndPoints.getCountries)
      .then((response) => {
        setCountries(response.data.countries.sort(dynamicSort('name')));
      })
      .catch((error) => {
        setCountries([]);
      });
  };
  const getCurrenciesList = () => {
    GET(ServiceEndPoints.getCurrencies)
      .then((response) => {
        setCurrencies(response.data.currencies);
      })
      .catch((error) => {
        setCurrencies([]);
      });
  };

  const isFormValid = (errors, formikValues) => {
    const { searchFor, deliveryServicePoint, ...restControls } = errors;
    if (restControls.weight && formikValues.weight === 0) {
      delete restControls?.weight;
    }
    if ((restControls.length && formikValues.length === '')||formikValues.destinationCountry.code2Digit !== 'GB') {
      delete restControls?.length;
    }
    if (formikValues.destinationCountry.code !== 'GBR' && formikValues.intlParcelDetails.filter(x => x.isValid === false).length>0) {
      return false;
    }
    delete restControls?.pieceWeight;
    if (errors.addressLine1 !== undefined ||
      errors.addressLine2 !== undefined ||
      errors.addressLine3 !== undefined ||
      errors.postalCode !== undefined ||
      errors.town !== undefined ||
      errors.county !== undefined ||
      (formikValues.destinationCountry?.code === 'USA' && errors.county !== undefined)) {
      setShowAllAddressFields(true);
      return false;
    } else {
      setShowAllAddressFields(false);
    }
    return Object.keys(restControls).length === 0;
  };

  const handleSubmit = (formikValues, errors, resetForm) => {
    if (isFormValid(errors, formikValues)) {
      setLoaderLayOver(true);
      const reqObj = formatAddToFavReqObj(formikValues);
      POST(ServiceEndPoints.postAddressBook, reqObj)
        .then((res) => {
          const successMsg = isUpdateAddressBook
            ? 'Address book entry updated successfully'
            : 'Address book entry saved successfully';
          dispatch(actions.setAppSnackBarData({ msg: successMsg }));
          resetForm();
          setTimeout(() => {
            if(isUpdateAddressBook) {history.push('/addressBook')};
          }, 0);
        })
        .catch((err) => {
          console.log(err);
          dispatch(
            actions.setBottomAlert({
              msgs: [
                {
                  type: 'error',
                  msg: `${err?.response?.data[0]?.detail ?? 'Error occurred while saving, please try again'}`,
                },
              ],
            }),
          );
        })
        .finally(() => {
          setLoaderLayOver(false);
        });
    } else {
      dispatch(
        actions.setBottomAlert({
          msgs: [{ type: 'error', msg: 'The form has errors, please correct and try again' }],
        }),
      );
      // dispatch(actions.setAppSnackBarData({ type: 'error', msg: 'The form has errors, please correct and try again' }));
    }
  };

  const initialValues = {};

  for (let property in formField) {
    if (Object.prototype.hasOwnProperty.call(formField, property)) {
      const field = formField[property];
      if (field.props.type === 'checkbox' || field.props.type === 'radio') {
        initialValues[field.props.name] = field.value;
      } else if (field.props.type === 'array') {
        initialValues[field.props.name] = field.values;
      } else {
        initialValues[field.props.name] = field.value || '';
      }
    }
  }

  useEffect(() => {
    if (countries.length > 0 && currencies.length > 0 && Object.keys(addressBookResponse).length > 0) {
      let formValues = { ...setValuesToForm(addressBookResponse, formikRef.current.values, countries, currencies, true), ...setValuesToCollectionDetailsFromAddressBook(addressBookResponse) }
      delete formValues.reasonForExport;
      formikRef.current.setValues(formValues);
      // setTimeout(() => {
      onLoad({
        loadedFrom: 'dropdownResponse',
        loadedResponse: { ...addressBookResponse },
      });
      setTimeout(() => {
        setLoaderLayOver(false)
        onLoad({ loadedFrom: '' });
      }, 2000);
      formikRef.current?.setFieldValue('weight', addressBookResponse?.parcelDetails?.weight);
      // }, 0);
    }
  }, [countries, currencies, addressBookResponse]);

  const updateValidationSchema = () => {
    setNewAddressValidationSchema(getYupSchemaFromMetaData(formField, [], []));
  };

  const handleClear = (resetForm, setFieldValue) => {
    resetForm();
    setFieldValue('destinationCountry', setDropdownValues(countries, 'code2Digit', 'GB'));
    setFieldValue('customerNumber', addressBookResponse?.deliveryAddress?.customerNumber ?? '');
  };

  const handleCancel = () => {
    setConfirmationMsg('Are you sure you want to cancel, all unsaved changes will be lost?');
    setShowCancelConfirmationDialog(true);
  };

  const handleDialogClose = () => {
    setShowCancelConfirmationDialog(false);
  };

  const onDialogCancel = () => {
    setShowCancelConfirmationDialog(false);
  };

  const onCancelDialogConfirm = () => {
    // dispatch(
    //   actions.setActiveTab("myTemplates"),
    // );
    history.goBack();
    setShowCancelConfirmationDialog(false);
  };

  return (
    <Grid className="shipment">
      <Grid>
        <React.Fragment>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={addNewAddressValidationSchema}
            innerRef={formikRef}
          >
            {({ values, setValues, setFieldValue, errors, setFieldTouched, isValid, resetForm }) => {
              const isDomestic = ((values || {}).destinationCountry || {}).code === 'GBR';
              return (
                <Form id={formId} noValidate>
                  <DestinationCountry
                    formField={formField}
                    countries={countries}
                    formikValues={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    isUpdateAddressBook={isUpdateAddressBook}
                  />
                  <CustomAccordion heading="Parcel details">
                    <ParcelDetails
                      formField={formField}
                      formikValues={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      isDomestic={isDomestic}
                      isAddressBook={true}
                      setFieldTouched={setFieldTouched}
                      updateValidationSchema={updateValidationSchema}
                      setShowDeliveryOptions={setShowDeliveryOptions}
                    />
                  </CustomAccordion>
                  <CustomAccordion heading="Delivery address">
                    <DeliveryAddress
                      formField={formField}
                      formikValues={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      errors={errors}
                      setValues={setValues}
                      isEditAddress={isUpdateAddressBook ? true : false}
                      updateValidationSchema={updateValidationSchema}
                      showAllAddressFields={showAllAddressFields}
                      setShowAddressFields={() => { setShowAllAddressFields(false) }}
                    />
                  </CustomAccordion>
                  {isDomestic ? (
                    <CustomAccordion heading="Delivery options">
                      <DeliveryOptions
                        formField={formField}
                        formikValues={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        setValues={setValues}
                        isDomestic={isDomestic}
                        isAddressBook={true}
                        populatedAddressBookResponse={addressBookResponse}
                      />
                    </CustomAccordion>
                  ) : showDeliveryOptions && values.addressType === 'residential' ? (
                    <CustomAccordion heading={'Delivery options'}>
                      <InternationalDeliveryOption
                        formField={formField}
                        formikValues={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        isDomestic={isDomestic}
                        isAddressBook={true}
                      />
                    </CustomAccordion>
                  ) : null}
                  {isDomestic && <CustomAccordion heading="Product and service">
                    <ProductAndServiceDetails
                      formField={formField}
                      formikValues={values}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      isDomestic={isDomestic}
                      isAddressBook={true}
                    />
                  </CustomAccordion>
                  }
                  <CustomAccordion heading="Additional information about your shipment">
                    <AdditionalInfo
                      formField={formField}
                      formikValues={values}
                      setFieldValue={setFieldValue}
                      isDomestic={isDomestic}
                    />
                  </CustomAccordion>
                  <CustomAccordion heading="Collection details">
                    <CollectionDetails
                      formField={formField}
                      formikValues={values}
                      setFieldValue={setFieldValue}
                      isDomestic={isDomestic}
                    />
                  </CustomAccordion>
                  {((values || {}).destinationCountry || {}).code !== 'GBR' ? (
                    <CustomAccordion heading="Customs declaration">
                      <CustomsDeclaration
                        isAddNewAddress={true}
                        formField={formField}
                        formikValues={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        setValues={setValues}
                        currencies={currencies}
                      />
                    </CustomAccordion>
                  ) : null}

                  <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="flex-end"
                    alignItems="flex-end"
                    style={{ paddingTop: '10px' }}
                  >
                    {/* <Grid item>
                      <InputButton
                        variant="text"
                        className="text-btn"
                        label="Clear"
                        onClick={() => {
                          handleClear(resetForm, setFieldValue);
                        }}
                      />
                    </Grid> */}
                    <Grid item>
                      <InputButton label="Cancel" variant="text" onClick={handleCancel} className="text-btn" />
                    </Grid>
                    {/* <Grid item>
                      <InputButton
                        variant="outlined"
                        className="outlined-btn"
                        component={NavLink}
                        to={'/shipment/addShipment/preview'}
                        label="Preview "
                        onClick={() => {
                          handlePreview(values, errors);
                        }}
                      />
                    </Grid> */}
                    <Grid item>
                      <InputButton
                        buttonType="submit"
                        variant="contained"
                        className="contained-btn"
                        onClick={() => handleSubmit(values, errors, resetForm)}
                        label={isUpdateAddressBook ? 'Update address book entry' : 'Save address book entry'}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item className="customModal-book">
                    <CustomModal open={showCancelConfirmationDialog} title="" onClose={handleDialogClose} maxWidth="sm">
                      <ConfirmationDialog
                        confirmationMessage={confirmationMsg}
                        onConfirm={onCancelDialogConfirm}
                        OnCancel={onDialogCancel}
                      />
                    </CustomModal>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </React.Fragment>
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoad: (loadedFrom) => {
      dispatch(actions.setLoadedFrom(loadedFrom));
    },
    setLoaderLayOver: (value) => {
      dispatch(actions.setLoaderLayOver(value))
    }
  };
};

export default connect(null, mapDispatchToProps)(AddNewAddress);
