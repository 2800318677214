import { Breadcrumbs, Button, Grid, SvgIcon, Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import Dashboard from './../../assets/images/Dashboard.svg';
import React, { useState } from 'react';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { Route, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import './breadcrumb.scss';
import { useSelector } from 'react-redux';
import CustomModal from '../modal/modal';
import ConfirmationDialog from '../../components/addressBook/confirmation/confirmation';
import { UserRoles } from '../../utils/constants/constants';
import { axiosConfig } from '../../api/axios';
import { ServiceEndPoints } from '../../utils/constants/ApiConstant';
import { ReactComponent as DownloadIcon } from '../../assets/images/Download.svg';

const useStylesTextField = makeStyles(() => ({
  root: {
    paddingBottom: '5px',
    border: '1px #000000',
    opacity: 0.5,
  },
  roleAccess: {
    pointerEvents: 'none',
  },
}));

// const breadcrumbNameMap = {
//   '/shipment': 'Shipment',
//   '/shipment/addShipment': 'Add shipment',
//   '/shipment/addShipment/preview': 'Preview',
//   '/shipment/shipmentmanagement': 'Shipment management',
//   '/shipment/collectionRequest': 'Collection request',
//   '/shipment/shipmentManagement': 'Shipment management',
//   '/shipment/shipmentmanagement/summary': 'Shipment',
//   '/shipment/shipmentmanagement/bookedcollection': 'Shipment ',
//   '/shipment/templateManagement': 'Template management',
//   '/shipment/bookDriverPickUp': 'Book driver pick-up',
//   '/addressBook': 'Address Book',
//   '/addressBook/Addnew': 'Add new address',
//   '/dashboard': 'Dashboard',
//   '/addressBook/editAddress': 'Edit address',
//   '/dashboard/Tracking': 'Tracking',
//   '/dashboard/dashboardDetailsSummary': 'Dashboard details',
//   '/dashboard/dashboardDetailsBreakdown': 'Dashboard details',
//   '/dashboard/summary': 'Shipment No:',
//   '/configuration': 'Configuration',
//   '/configuration/genralConfiguration': 'General configuration',
//   '/auditEnquiry': 'Audit enquiry',
//   '/configuration/fileConfiguration': 'Order file configurations',
// };

const Breadcrumb = () => {
  const history = useHistory();
  const location = useLocation();
  const userRole = useSelector((state) => state?.auth?.userDetails?.role);
  let shipmentId = "";
  const selectedShipmentId = useSelector((state) => state?.shipmentId?.shipmentId?.shipmentId);
  if (location.pathname.toLowerCase().includes("addshipment")) {
    const splitedPath = location.pathname.split("/");
    if (splitedPath?.[splitedPath.length - 2]?.toLowerCase() === "addshipment") {
      shipmentId = splitedPath?.[splitedPath.length - 1] ?? ""
    }
  }
  let breadcrumbNameMap = {
    '/shipment': 'Shipment',
    '/shipment/addshipment': 'Add shipment',
    [`/shipment/addshipment/${shipmentId}`]: 'Amend shipment',
    '/shipment/addshipment/preview': 'Preview',
    '/shipment/shipmentmanagement': 'Shipments awaiting driver pick-up',
    '/shipment/collectionrequest': 'Collection request',
    [`/shipment/shipmentmanagement/summary/${selectedShipmentId}`]: `Shipment no. - ${selectedShipmentId}`,
    [`/shipment/shipmentmanagement/bookedcollection/${selectedShipmentId}`]: `Shipment no. - ${selectedShipmentId}`,
    '/shipment/templatemanagement': 'Template management',
    '/shipment/bookdriverpickup': 'Request driver pick-up',
    '/shipment/processorders': 'Process orders',
    '/addressbook': 'Address book',
    '/addressbook/addnew': 'Add new address',
    '/dashboard': 'Dashboard & tracking',
    '/addressbook/editaddress': 'Edit address',
    '/dashboard/tracking': 'Tracking',
    '/dashboard/dashboarddetailssummary': 'Dashboard details',
    '/dashboard/dashboarddetailsbreakdown': 'Dashboard details',
    [`/dashboard/summary/${selectedShipmentId}`]: `Shipment No. - ${selectedShipmentId}`,
    '/configuration': 'Configuration',
    '/configuration/barcodeconfiguration': 'Barcode configuration',
    '/configuration/genralconfiguration': 'General configuration',
    '/configuration/marketplaceconfig': 'Marketplace configuration',
    '/auditenquiry': 'Audit enquiry',
    '/configuration/fileconfiguration': 'Order configurations',
    '/configuration/printerconfiguration': 'Printer configuration',
    [`/configuration/fileconfiguration/schemapreview/${selectedShipmentId}`]: `Preview -${selectedShipmentId}`,
    '/usermanagement': 'User management',
    '/accounts': 'Accounts',
    '/shipment/templatemanagement/edittemplate': `Templates -${selectedShipmentId}`,
    '/faq': `FAQ`,
    '/applicationupdates': `Application updates`,
  };

  const classes = useStylesTextField();
  const [showCancelConfirmationDialog, setShowCancelConfirmationDialog] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState('');

  let barCodePath = '/configuration/barcodeconfiguration';
  let genralPath = '/configuration/genralconfiguration';
  let printerPath = '/configuration/printerconfiguration';
  let orderFilePath = '/configuration/fileconfiguration';
  let marketplaceConfigPath = '/configuration/marketplaceconfig';

  const goBack = () => {
    const currentPath = location.pathname.toLowerCase()
    if (
      barCodePath === currentPath ||
      genralPath === currentPath ||
      printerPath === currentPath ||
      orderFilePath === currentPath ||
      marketplaceConfigPath === currentPath
    ) {
      setConfirmationMsg('Any unsaved changes will be lost?');
      setShowCancelConfirmationDialog(true);
    }
  };
  const handleDialogClose = () => {
    setShowCancelConfirmationDialog(false);
  };

  const onDialogCancel = () => {
    setShowCancelConfirmationDialog(false);
  };

  const onCancelDialogConfirm = () => {
    history.push('/configuration');

    setShowCancelConfirmationDialog(false);
  };

  const downloadExe = () => {
    axiosConfig.get(ServiceEndPoints.pollingApp).then((res) => {
      window.open(res.data?.downloadurl, '_self');
    });
  };

  return (
    <Route>
      {({ location }) => {
        const pathnames = location.pathname?.toLowerCase().split('/').filter((x) => x);
        return (
          <Grid container className="breadcrumbContainer" direction="row" justify="space-between">
            <Grid item>
              <Breadcrumbs
                separator={<ChevronRight className={classes.root} fontSize="default" />}
                aria-label="Breadcrumb"
              >
                <RouterLink
                  // className={
                  //   (userRole === '' || userRole === UserRoles.Admin || userRole === UserRoles.ShipmentEntry) &&
                  //   classes.roleAccess
                  // }
                  color="inherit"
                  to="/dashboard"
                >
                  {/* <Dashboard fontSize="small" /> */}
                  <img className="Breadcrumbs-Dashboard-btn" src={Dashboard} alt="Header Logo" />
                </RouterLink>
                {(pathnames || [])
                  .map((value, index) => {
                    const last = index === pathnames.length - 1;
                    const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                    const currentPath = location.pathname.toLowerCase()
                    // eslint-disable-next-line multiline-ternary
                    if (!breadcrumbNameMap[to]) {
                      return;
                    }
                    return last ? (
                      <Typography color="textPrimary" key={to} className="Addshipmentrouter">
                        {breadcrumbNameMap[to]}
                      </Typography>
                    ) : (
                      <RouterLink
                        className="shipmentrouterLink"
                        color="inherit"
                        to={
                          barCodePath === currentPath ||
                            genralPath === currentPath ||
                            printerPath === currentPath ||
                            orderFilePath === currentPath ||
                            marketplaceConfigPath === currentPath
                            ? currentPath
                            : to
                        }
                        key={to}
                        style={{ textDecoration: 'none' }}
                      >
                        <Typography color="textPrimary" key={to} className="shipmentrouter" onClick={() => goBack()}>
                          {breadcrumbNameMap[to]}
                        </Typography>
                      </RouterLink>
                    );
                  })
                  .filter((val) => val)}
              </Breadcrumbs>
            </Grid>
            <Grid item>
              {location?.pathname.toLowerCase()?.indexOf('configuration/fileconfiguration') > -1 && (
                <Grid style={{ cursor: 'pointer' }} onClick={downloadExe} container direction="row" spacing={2}>
                  <Grid item>
                    <SvgIcon fontSize="default" component={DownloadIcon} />
                  </Grid>
                  <Grid item>Download order file processor</Grid>
                </Grid>
              )}
            </Grid>
            <CustomModal open={showCancelConfirmationDialog} title="" onClose={handleDialogClose} maxWidth="sm">
              <ConfirmationDialog
                confirmationMessage={confirmationMsg}
                onConfirm={onCancelDialogConfirm}
                OnCancel={onDialogCancel}
              />
            </CustomModal>
          </Grid>
        );
      }}
    </Route>
  );
};

Breadcrumb.propTypes = {};

export default Breadcrumb;
