import * as actionTypes from './actionsTypes';

export const setUserNotification = (notificationData) => {
  return {
    type: actionTypes.SET_NOTIFICATION,
    data: notificationData,
  };
};

export const setJSPMStatus = (jspmStatus) => {
  return {
    type: actionTypes.SET_JSPMSTATUS,
    data: jspmStatus,
  };
};

export const setPrinterConfiguration = (printerConfiguration) => {
  return {
    type: actionTypes.SET_PRINTERCONFIGURATION,
    data: printerConfiguration,
  };
};

export const setAvailablePrinter = (printerList) => {
  return {
    type: actionTypes.SET_AVAILABLEPRINTERS,
    data: printerList,
  };
};

export const setGenralConfiguration = (genConfig) => {
  return {
    type: actionTypes.SET_COMMONCONFIGURATION,
    data: genConfig,
  };
};

export const setAppSnackBarData = (payload) => {
  return {
    type: actionTypes.SET_APPSNACKBAR,
    data: payload,
  };
};

export const resetAppSnackBarData = (payload) => {
  return {
    type: actionTypes.RESET_APPSNACKBAR,
    data: payload,
  };
};

export const setBottomAlert = (payload) => {
  return {
    type: actionTypes.SET_BOTTOM_ALERT,
    data: payload,
  };
};

export const resetBottomAlert = (payload) => {
  return {
    type: actionTypes.RESET_BOTTOM_ALERT,
    data: payload,
  };
};

export const setActiveTab = (tab) => {
  return {
    type: actionTypes.SETACTIVETAB,
    data: tab,
  };
};

export const setReturnReferenceCount = (count) => {
  return {
    type: actionTypes.SET_RETURN_REFERENCE_COUNT,
    data: count,
  };
};

export const setIsReturnReferenceUpdated = (isUpdated) => {
  return {
    type: actionTypes.SET_IS_RETURN_REFERENCE_UPDATED,
    data: isUpdated
  }
};

export const resetReturnReferenceParams = () => {
  return {
    type: actionTypes.RESET_RETURN_REFERENCE_VALUES,
    data: null
  }
}

export const setAllAvailableProducts = (products) => {
  return {
    type: actionTypes.SET_ALL_PRODUCTS,
    data: products
  }
}

export const setDhlIossNumber = (dhlIossNumber) => {
  return {
    type:actionTypes.SET_DHL_IOSS_NUMBER,
    data:dhlIossNumber,
  }
}

export const setSortOrderState = (order) => {
  return {
    type:actionTypes.SET_SORT_ORDER_STATE,
    data:order,
  }
}

export const setAutoReloadProcessOrders = (autoReloadFlag) => {
  return {
    type: actionTypes.SET_AUTORELOAD_PROCESSORDERS,
    data: autoReloadFlag,
  };
};