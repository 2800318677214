import React, { useEffect, useRef, useState } from 'react';
import { Grid, Snackbar } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { connect, useDispatch } from 'react-redux';
import ConfirmationDialog from '../../../components/addressBook/confirmation/confirmation';
import InputButton from '../../../framework/Inputs/inputButton/inputButton';
import CustomModal from '../../../framework/modal/modal';
import { getYupSchemaFromMetaData } from '../../../utils/yupSchema/yupSchemaGenerator';
// import barcodeConfigurationForm from '../../../utils/formConfig/configuration/barcodeConfigurationForm/barcodeConfigurationForm';
import BarcodeConfigurationDetails from './BarcodeConfigurationDetails';
import { useHistory } from 'react-router-dom';
import { formatBarcodeObj, setBarcodevaluesToForm, getCustomerAccountID } from '../../../utils/helperFunctions';
import { axiosConfig, GET, POST } from '../../../api/axios';
import { filedToSearchByOptions } from '../../../utils/constants/constants';
import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';
import * as actions from '../../../store/actions/index';
import './BarCodeConfiguration.scss';

const BarCodeConfiguration = (props) => {
  const { formId, formField } = barcodeConfigurationForm;
  const formikRef = useRef(null);
  const newMainBarCodeSchema = getYupSchemaFromMetaData(formField, [], []);
  const [confirmationMsg, setConfirmationMsg] = useState('');
  const { onLoad } = props;

  const [showCancelConfirmationDialog, setShowCancelConfirmationDialog] = useState(false);

  const initialValues = {
    startPosition: '',
    finalPosition: '',
    filedToSearchBy: filedToSearchByOptions[0],
    barCodeLength: '',
  };
  const history = useHistory();
  const dispatch = useDispatch();
  let reqFields = ['filedToSearchBy', 'startPosition', 'finalPosition', 'barCodeLength'];

  const handleSubmit = (values, errors, resetForm) => {
    if (!isFormInValid(errors, values) && values.filedToSearchBy.name !== 'Select') {
      const reqObj = formatBarcodeObj(values);
      axiosConfig
        .post(`${ServiceEndPoints.getBarcode}?organization=${getCustomerAccountID()}`, reqObj)
        .then((res) => {
          dispatch(actions.setAppSnackBarData({ msg: 'Barcode configuration saved successfully' }));
        })
        .catch((err) => {
          console.log(err, 'err');
          dispatch(
            actions.setBottomAlert({
              msgs: [
                {
                  type: 'error',
                  msg: 'Error in saving schema: Error status',
                },
              ],
            }),
          );
          // dispatch(actions.setAppSnackBarData({msg:"Error in saving schema: Error status"}))
        });
    } else {
      dispatch(
        actions.setBottomAlert({
          msgs: [
            {
              type: 'error',
              msg: 'The form has errors, please correct and try again',
            },
          ],
        }),
      );
      // dispatch(actions.setAppSnackBarData({ type: 'error', msg: 'The form has errors, please correct and try again' }));
    }
  };

  const isFormInValid = (errors, values) => {
    for (let err in errors) {
      if (reqFields.some((fieldName) => fieldName === err)) {
        return true;
      }
    }
    return false;
  };

  const cancelSchema = () => {
    setConfirmationMsg('Are you sure you want to cancel, all unsaved changes will be lost?');
    setShowCancelConfirmationDialog(true);
  };
  const handleDialogClose = () => {
    setShowCancelConfirmationDialog(false);
  };

  const onDialogCancel = () => {
    setShowCancelConfirmationDialog(false);
  };

  const onCancelDialogConfirm = () => {
    history.goBack();
    setShowCancelConfirmationDialog(false);
  };

  useEffect(() => {
    GET(`${ServiceEndPoints.getBarcode}?organization=${getCustomerAccountID()}`)
      .then((res) => {
        if (Object.keys(res.data).length > 0) {
          res.data['destinationCountry'] = 'GBR';
          onLoad({
            loadedFrom: 'dropdownResponse',
            loadedResponse: { ...res.data },
          });
          setTimeout(() => {
            formikRef.current.setValues(setBarcodevaluesToForm(res.data, formikRef.current.values));
          }, 0);
          setTimeout(() => {
            onLoad({ loadedFrom: '' });
          }, 2000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={newMainBarCodeSchema}
        innerRef={formikRef}
      >
        {({ values, setFieldValue, errors, setFieldTouched, resetForm, setValues }) => (
          <Form id={formId}>
            <BarcodeConfigurationDetails
              errors={errors}
              formField={formField}
              formikValues={values}
              setFieldValue={setFieldValue}
              setValues={setValues}
            />
            <Grid
              container
              spacing={3}
              direction="row"
              justify="flex-end"
              alignItems="flex-end"
              className="btn-container"
            >
              <Grid item>
                <InputButton variant="text" className="text-btn" onClick={() => cancelSchema()} label="Cancel " />
                <Grid container item className="customModal-book">
                  <CustomModal open={showCancelConfirmationDialog} title="" onClose={handleDialogClose} maxWidth="sm">
                    <ConfirmationDialog
                      confirmationMessage={confirmationMsg}
                      onConfirm={onCancelDialogConfirm}
                      OnCancel={onDialogCancel}
                    />
                  </CustomModal>
                </Grid>
              </Grid>
              <Grid item>
                <InputButton
                  variant="contained"
                  className="contained-btn"
                  onClick={() => {
                    handleSubmit(values, errors, resetForm);
                  }}
                  buttonType="submit"
                  label="Save"
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoad: (loadedFrom) => {
      dispatch(actions.setLoadedFrom(loadedFrom));
    },
  };
};

export default connect(null, mapDispatchToProps)(BarCodeConfiguration);

const barcodeConfigurationForm = {
  formId: 'barcodeConfigurationForm',
  formField: {
    filedToSearchBy: {
      props: {
        name: 'filedToSearchBy',
        label: 'Field to search by',
        type: 'select',
        displayField: 'name',
        isRequired: true,
        hasDefaultValue: true,
      },
      validation: {
        name: 'filedToSearchBy',
        validationType: 'object',
        validations: [
          {
            type: 'required',
            params: ['Field to search by is required'],
          },
        ],
      },
    },
    startPosition: {
      props: {
        name: 'startPosition',
        label: 'Start position',
        type: 'text',
        isRequired: true,
        placeholder: 'Enter',
      },
      validation: {
        name: 'startPosition',
        validationType: 'number',
        validations: [
          {
            type: 'required',
            params: ['Start position is required'],
          },
          {
            type: 'typeError',
            params: ['Should be a valid number'],
          },
          {
            type: 'min',
            params: [1, 'Should be a valid number'],
          },
        ],
      },
    },
    finalPosition: {
      props: {
        name: 'finalPosition',
        label: 'Final position',
        type: 'text',
        isRequired: true,
        placeholder: 'Enter',
      },
      validation: {
        name: 'finalPosition',
        validationType: 'number',
        validations: [
          {
            type: 'required',
            params: ['Final position is required'],
          },
          {
            type: 'typeError',
            params: ['Should be a valid number'],
          },
          {
            type: 'min',
            params: [1, 'Should be a valid number'],
          },
        ],
      },
    },
    barCodeLength: {
      props: {
        name: 'barCodeLength',
        label: 'Barcode length (Ignore if not this length)',
        type: 'text',
        placeholder: 'Enter',
      },
      validation: {
        name: 'barCodeLength',
        validationType: 'number',
        validations: [
          {
            type: 'typeError',
            params: ['Should be a valid number'],
          },
        ],
      },
    },
  },
};
