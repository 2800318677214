import React from 'react';
import { Grid } from '@material-ui/core';
import FormRenderer from '../../../framework/Inputs/formRenderer/formRenderer';
import PropTypes from 'prop-types';

const DestinationCountry = (props) => {
  const {
    formField: { destinationCountry },
    setFieldValue,
    countries,
    isUpdateAddressBook,
  } = props;
  if (
    countries.length > 0 &&
    (props.formikValues.destinationCountry === undefined || props.formikValues.destinationCountry === '')
  ) {
    if(!isUpdateAddressBook){
      setFieldValue(
        'destinationCountry',
        countries.find((country) => country.code === 'GBR'),
      );
    }
  }

  return (
    <Grid container item xs={12} sm={6} lg={4} spacing={3}>
      <Grid item xs={12}>
        <FormRenderer {...destinationCountry.props} data={countries} fullWidth />
      </Grid>
    </Grid>
  );
};

DestinationCountry.propTypes = {
  formField: PropTypes.object,
  formikValues: PropTypes.object,
  setFieldValue: PropTypes.func,
  errors: PropTypes.object,
  countries: PropTypes.array,
};

export default DestinationCountry;
