import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import DeliverToSender from './deliverToSender';
import DeliverToThirdParty from './deliverToThirdParty';
import './deliverTo.scss';

const DeliverTo = (props) => {
  const { formikValues } = props;
  return (
    <Grid container style={{ alignContent: 'flex-start' }}>
      {(formikValues || {}).returnType === 'returnToSender'
        ? (
          <DeliverToSender {...props} />
        )
        : (
          <DeliverToThirdParty {...props} />
        )}
    </Grid>
  );
};

DeliverTo.propTypes = {
  formikValues: PropTypes.object,
  returnType: PropTypes.string,
};

export default DeliverTo;
