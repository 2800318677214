import React, { useRef, useState, useEffect } from 'react';

import { ContentState, convertToRaw, EditorState, convertFromHTML } from "draft-js";
import ReactHtmlParser from "react-html-parser";

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from "draftjs-to-html";
import './richTextEditor.scss';
import { FormControl, FormLabel, Input } from '@material-ui/core';
import { useField } from 'formik';
import { at } from 'lodash';

const RichTextEditor = (props) => {
  const {
    label,
    isRequired,
    categoryType,
    data,

  } = props;

  const [field, meta, helper] = useField(props);

  const { value: selectedValue } = field;

  const { setValue, setTouched } = helper;

  const [editorState, setEditorState] = useState(
    EditorState.createEmpty()
  );

  const [isStateChanged, setStateChanged] = useState(false);

  const onEditorStateChange = (editorState) => {
    setStateChanged(true);
    setEditorState(editorState)
    const editorSourceHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setValue(editorSourceHTML);
  
  };
 
  const renderHelperText = () => {
    const [touched, error] = at(meta, 'touched', 'error');

    if (touched && error) {
      return error;
    }

  };

  useEffect(() => { 
    if (!isStateChanged && selectedValue && categoryType != 'textBox') {
      const contentBlocks = convertFromHTML(selectedValue);
      const contentStates = ContentState.createFromBlockArray(contentBlocks);
      setEditorState(EditorState.createWithContent(contentStates)
      )
    }
  }, [selectedValue])


  return (
    <div className="App">

      <FormControl component="fieldset">
        <p className="labelText">{label} {isRequired ? <span>*</span> : <></>}</p>
        <Editor
          name={props.name}
          editorState={editorState}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          onEditorStateChange={onEditorStateChange}
          onContentStateChange={(v) => {
            // console.log(v)
            if (v.blocks[0] != undefined) {
              setTouched(v.blocks[0].text === "")
            }
          }}
          toolbar={{
            options: ["inline", "link"],
            inline: {
              options: ["bold", "italic", "underline", "strikethrough"]
            }
          }}
          {...props}
        />
      </FormControl>

      <span className="errorText">
        {renderHelperText()}
      </span>
    </div>
  )
}
export default RichTextEditor;