import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import CustomCaption from './CustomCaption';
import { getParsedFloatValues } from '../../utils/helperFunctions';
import { useSelector } from 'react-redux';
// const useStyles = makeStyles({
//   flexContainer: {
//     flexGrow: 1,
//     flexShrink: 1,
//     flexBasis: 0,
//   },
// });
const ParcelSummary = ({ shipmentSummary, shipmentmanagement }) => {
  // const classes = useStyles();
  const [parcelDetails, setParcelDetails] = useState({});
  const [volumetricWeight, setVolumetricWeight] = useState(0.0);
  const [volumetricWeightDivisor, setVolumetricWeightDivisor] = useState(4000);

  // const [parcelDimensionsChecked, setParcelDimensionsChecked] = useState(false);
  const userDetails = useSelector((state) => state.auth.userDetails);
  useEffect(() => {
    setParcelDetails(shipmentSummary?.parcelDetail);
  }, [shipmentSummary]);

  useEffect(() => {
    let volumetricWeight = 0;
    parcelDetails?.pieces?.forEach((row) => {
      volumetricWeight += getCaluclatedVolumetricWeight(row);
    });
    setVolumetricWeight(parseFloat(volumetricWeight).toFixed(2));
  }, [parcelDetails, volumetricWeightDivisor]);

  useEffect(() => {
    const shipmentAcc = userDetails?.accounts?.find((user) => user.accountId === shipmentSummary.accountNumber);
    setVolumetricWeightDivisor(shipmentAcc?.configuration?.volumetricWeightDivisor || 4000);
  }, [userDetails, shipmentSummary.accountNumber]);

  const getCaluclatedVolumetricWeight = (row) => {
    return getParsedFloatValues((row.length * row.height * row.width) / volumetricWeightDivisor);
  };
  return (
    <>
      <div style={{ width: '100%' }}>
        <Box display="flex" flexDirection="row" flexWrap="nowrap">
          {/* {parcelDetails?.servicePoint === undefined && shipmentmanagement ? null : (
            <Box component="div" flexGrow={1}>
              <CustomCaption title="ServicePoint" value={parcelDetails?.servicePoint ?? ''} />
            </Box>
          )} */}
          {shipmentSummary.productDetail?.product === 'Pallet' ? (<Box component="div" flexGrow={1}>
            <CustomCaption
              title="No. of pallets"
              value={shipmentSummary.productDetail?.product === 'Pallet' ? parcelDetails?.totalPallets : ' '}
            />
          </Box>) : (parcelDetails?.numberOfItems === undefined && shipmentmanagement ? null : (
            <Box component="div" flexGrow={1}>
              <CustomCaption
                title="No. of items"
                value={parcelDetails?.numberOfItems ? parcelDetails?.numberOfItems : ''}
              />
            </Box>
          ))}
          {parcelDetails?.totalWeight === undefined && shipmentmanagement ? null : (
            <Box component="div" flexGrow={1}>
              <CustomCaption title="Weight (kg)" value={parcelDetails?.totalWeight ?? ''} />
            </Box>
          )}
          {/* <Box component="div" flexGrow={1}>
            <ReadOnlyCheckbox label="Parcel dimensions added" checked={parcelDimensionsChecked} />
          </Box> */}
          <Box component="div" flexGrow={1}>
            <CustomCaption title="Total volumetric wt(kg)" value={volumetricWeight ?? ''} />
          </Box>
        </Box>
      </div>
    </>
  );
};

export default ParcelSummary;
