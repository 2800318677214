import React from 'react';
import { Grid } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import DynamicMaterialTable from '../../../../framework/DynamicMaterialTable/DynamicMaterialTable';

export default function OrderFile(props) {
  const {
    rows,
    columns,
    isSelectable,
    emptyDataSourceMessage
  } = props;
  return (
    <Grid container justify="flex-end" alignItems="flex-end">
      <Grid item lg={12} md={12} xs={12} className="Table-column-color">
        <DynamicMaterialTable
          rows={rows}
          columns={columns}
          isSelectable={isSelectable}
          empty_DataSource_Message={emptyDataSourceMessage}
          {...props}
        />
      </Grid>
    </Grid>
  )
}

OrderFile.propTypes = {
  rows: PropTypes.any,
  columns: PropTypes.any,
  isSelectable: PropTypes.bool,
  emptyDataSourceMessage: PropTypes.string
};
