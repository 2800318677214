import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import FormRenderer from '../../../../framework/Inputs/formRenderer/formRenderer';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../../store/actions/index';
const ReturnOptions = (props) => {
  const dispatch = useDispatch();
  const {
    formField: { inboxReturnLabel, returnReference },
    setFieldValue,
    isOrderFileDefault,
    formikValues,
    isAmendShipment
  } = props;
  const inboxReturnLabelValue = useSelector(
    (state) => state?.common?.commonConfiguration?.defaultPrintInboxReturnLabel,
  );
  const shipmentDetails = useSelector((state) => state.addShipmentData.shipmentDetails);

  const returnReferenceCounter = useSelector((state) => state.common.returnReferenceCount);

  const isReturnReferenceUpdated = useSelector((state) => state.common.isReturnReferenceUpdated);

  const formLoadedFrom = useSelector((state) => state.loadedFrom)

  useEffect(() => {
    if (shipmentDetails?.inboxReturnLabel === undefined || shipmentDetails?.inboxReturnLabel === null) {
      if (
        (!formLoadedFrom?.loadedFrom?.loadedFrom && !isOrderFileDefault) || (formikValues?.inboxReturnLabel === undefined || formikValues?.inboxReturnLabel === null) 
      ) {
        if (inboxReturnLabelValue === true) {
          setTimeout(() => {
            setFieldValue('inboxReturnLabel', inboxReturnLabelOptions[0].value);
          }, 0);
        }
        else {
          setTimeout(() => {
            setFieldValue('inboxReturnLabel', inboxReturnLabelOptions[1].value);
          }, 0);
        }
      }
    } else {
      if (shipmentDetails?.inboxReturnLabel === 'true') {
        setFieldValue('inboxReturnLabel', inboxReturnLabelOptions[0].value);
      }
      else {
        setFieldValue('inboxReturnLabel', inboxReturnLabelOptions[1].value);
      }
    }
    return () => {
      if (!isAmendShipment) {
        setFieldValue('inboxReturnLabel', inboxReturnLabelOptions[1].value);
        setFieldValue('returnReference', '');
      }
    };
  }, [inboxReturnLabelValue, shipmentDetails?.inboxReturnLabel]);

  //Set Return reference on load if customer reference is set.
  useEffect(() => {
    const { customerReference, returnReference } = formikValues;
    if (formikValues.inboxReturnLabel === 'true' && customerReference !== '' && formLoadedFrom.loadedFrom.loadedFrom !== "fromPreviewSummary") {
      setFieldValue('returnReference', (returnReference && returnReference !== '') ? returnReference : customerReference);
      dispatch(actions.setReturnReferenceCount(returnReferenceCounter + 1));
      dispatch(actions.setIsReturnReferenceUpdated(true));
    }
  }, []);

  //Set Return reference once tabbed out from customer reference field.
  useEffect(() => {
    if (
      formikValues.customerReference !== '' &&
      (returnReferenceCounter === 0 || !isReturnReferenceUpdated) &&
      formikValues.inboxReturnLabel === 'true' && !isAmendShipment && !props?.formikValues?.returnReference
    ) {
      setFieldValue('returnReference', formikValues.customerReference);
      dispatch(actions.setReturnReferenceCount(returnReferenceCounter + 1));
      dispatch(actions.setIsReturnReferenceUpdated(true));
    } else if (formikValues.inboxReturnLabel === 'false') {
      setFieldValue('returnReference', '');
    }
  }, [returnReferenceCounter, formikValues.inboxReturnLabel]);

  return (
    <Grid container className="returnoption-main-grid">
      <Grid container spacing={3} direction="row" alignItems="flex-start">
        <Grid className="radio-label" item xs={12} sm={8} md={6} lg={6} fullWidth>
          <FormRenderer {...inboxReturnLabel.props} data={inboxReturnLabelOptions} isDisabled={isAmendShipment} fullWidth />
        </Grid>
        {formikValues.inboxReturnLabel === 'true' && (
          <Grid item xs={12} sm={4} md={6} lg={6} fullWidth style={{ paddingLeft: '15px' }}>
            <FormRenderer {...returnReference.props} isDisabled={isAmendShipment} fullWidth />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

ReturnOptions.propTypes = {
  formField: PropTypes.object,
};

export default ReturnOptions;

const inboxReturnLabelOptions = [
  {
    value: 'true',
    label: 'Yes',
  },
  {
    value: 'false',
    label: 'No',
  },
];
