import React from 'react'
import { Grid } from '@material-ui/core';
import CustomCaption from './CustomCaption';

const SetupSummary = ({ data }) => {

    return (
        <>
            <Grid container alignItems="flex-start" xs={12} md={12} sm={12} lg={12}>
                <Grid container xs={6} md={6} sm={6} lg={6}>
                    <Grid item xs={6} md={6} sm={6} lg={6} >
                        <CustomCaption title="Shipment number" value={(data || {}).shipmentNumber !== null || (data || {}).shipmentNumber !== undefined ? (data || {}).shipmentNumber : ''} />
                    </Grid>
                    <Grid item xs={6} md={6} sm={6} lg={6} >
                        <CustomCaption title="Account number" value={(data || {}).accountNumber !== null ? (data || {}).accountNumber : ''} />
                    </Grid>
                </Grid>
                <Grid container xs={6} md={6} sm={6} lg={6}>
                    <Grid item xs={6} md={6} sm={6} lg={4} >
                        <CustomCaption title="Parcel handover method" value={( (data || {}).parcelHandoverMethod !== null && (data || {}).parcelHandoverMethod !== undefined ) ? ((data || {}).parcelHandoverMethod.toLowerCase() === 'pickup' ? 'Driver collection' : 'Drop off at ServicePoint') : ''} />
                    </Grid>
                    <Grid item xs={6} md={6} sm={6} lg={4} >
                        <CustomCaption title="Destination country" value={(data || {}).destinationCountry !== null ? (data || {}).destinationCountry : ' '} />
                    </Grid>
                    <Grid item xs={6} md={6} sm={6} lg={4} >
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default SetupSummary
