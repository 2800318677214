import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

import './inputButton.scss';

const InputButton = (props) => {
  const className = `button ${props.className}`;
  const { label, variant, isDisabled, buttonSize, color, onClick, buttonType, ...rest } = props;
  return (
    <Button
      className={className}
      variant={variant}
      size={buttonSize}
      color={color}
      disabled={isDisabled}
      onClick={onClick}
      type={buttonType}
      {...rest}
    >
      {label}
    </Button>
  );
};

InputButton.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  buttonSize: PropTypes.oneOf(['large', 'medium', 'small']),
  icon: PropTypes.any,
  style: PropTypes.object,
  label: PropTypes.string,
  buttonType: PropTypes.string,
  className: PropTypes.string,
};

export default InputButton;
