export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const isValidObject = (obj) => {
  return !(obj === undefined || obj === '' || obj === null);
};

export const isValidPostCode = (countryRegex, postalCode) => {
  if (countryRegex === null) {
    return true;
  }
  const regExp = new RegExp(countryRegex);
  return regExp.test(postalCode);
};

export const getServiceRequired = (services) => {
  const serviceLevelsTime = ['09:00', '10:30', '12:00', 'am'];
  const serviceLevels = ['parcel', 'bagit s', 'bagit m', 'bagit l', 'bagit xl'];
  const mappedServices = [];

  var displayServiceName;
  services.map((service) => {
    const serviceName = service.name.toLowerCase().trim();
    const serviceLevel = service.serviceLevel.toLowerCase().trim();
    if (
      serviceName.includes(serviceLevelsTime[0]) ||
      serviceName.includes(serviceLevelsTime[1]) ||
      serviceName.includes(serviceLevelsTime[2])
    ) {
      displayServiceName = serviceName;
      if (serviceLevel.includes(serviceLevelsTime[3]) || serviceLevel.endsWith(serviceLevelsTime[3])) {
        displayServiceName = `${service.name} ` + `am`;
      } else {
        displayServiceName = `${service.name} ` + `pm`;
      }
    } else if (
      serviceName.includes(serviceLevels[0]) ||
      serviceName.includes(serviceLevels[1]) ||
      serviceName.includes(serviceLevels[2]) ||
      serviceName.includes(serviceLevels[3]) ||
      serviceName.includes(serviceLevels[4])
    ) {
      displayServiceName = `${service.name} ${service.serviceLevel}`;
    } else {
      displayServiceName = service.name;
    }
    const item = {
      name: displayServiceName,
      value: service.orderedProduct,
    };
    mappedServices.push(item);
  });
  return mappedServices;
};

export const renderEmptyText = (value) => {
  return value ?? '';
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => reject(error);
  });

export const arrayValuesToQueryStringParam = (accounts) => {
  let returnValue = '';
  (accounts || []).forEach((account) => {
    returnValue = `${returnValue}accounts=${account}&`;
  });

  return returnValue;
};

export const exportToTextFile = (fileContents,fileName)=> {
  const a = document.createElement("a");
  a.href = URL.createObjectURL(new Blob([fileContents], {
    type: "text/plain"
  }));
  a.setAttribute("download",fileName);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

//Check if the value is Number
export const isValidNumber = (value)=>{
  return  value !== "" && !isNaN(value); 
}