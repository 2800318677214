import React from 'react'
import { Grid } from '@material-ui/core';
import CustomCaption from '../../../framework/summaryFormate/CustomCaption';
import { dateFormatForSummaryPage } from '../../../utils/helperFunctions'

const CollectionSetUpSummary = ({ data }) => {
    return (
        <>
            <Grid container alignItems="flex-start" xs={12} md={12} sm={12} lg={12}>
                <Grid container xs={6} md={6} sm={6} lg={6}>
                    <Grid item xs={6} md={6} sm={6} lg={6} >
                        <CustomCaption title="Shipment number" value={(data || {}).shipmentNumber} />
                    </Grid>
                    <Grid item xs={6} md={6} sm={6} lg={6} >
                        <CustomCaption title="Collection date" value={dateFormatForSummaryPage((data || {}).collectionDate)} />
                    </Grid>
                </Grid>
                <Grid container xs={6} md={6} sm={6} lg={6}>
                    <Grid item xs={6} md={6} sm={6} lg={6} >
                        <CustomCaption title="Account number" value={(data || {}).accountNumber} />
                    </Grid>
                    <Grid item xs={6} md={6} sm={6} lg={6} >
                        
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}


export default CollectionSetUpSummary
