import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { connect } from 'react-redux';

import DomesticDefaults from '../orderFileDefaults/domesticDefaults/domesticDefaults';
// import CollectionsDefaults from '../orderFileDefaults/collectionsDefaults/collectionsDefaults';
import MarketplaceIntlDefaults from '../marketplacesDefaults/marketplaceIntlDefaults/marketplaceIntlDefaults'
import { GET } from '../../../../api/axios';
import { ServiceEndPoints } from '../../../../utils/constants/ApiConstant';
import { dynamicSort, getCustomerAccountID } from '../../../../utils/helperFunctions';
import '../orderFileDefaults/orderFileDefaults.scss';

const MarketplacesDefaults = (props) => {
  const [formType, setFormType] = useState('domestic');
  const [defaultValues, setDefaultValues] = useState({});
  const [countries, setCountries] = React.useState([]);

  const toggleButtons = (event, value) => {
    if (value) {
      setFormType(value);
    }
  };

  useEffect(() => {
    getMarketplaceDefaults();
  }, []);

  const getMarketplaceDefaults = () => {
    GET(`${ServiceEndPoints.getMarketplaceDefaults}?organization=${getCustomerAccountID()}`)
      .then((res) => {
        setDefaultValues(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    GET(ServiceEndPoints.getCountries)
      .then((res) => {
        setCountries(res.data.countries.sort(dynamicSort('name')));
      })
      .catch((err) => {
        setCountries([]);
      });
  };

  return (
    <Grid container spacing={3} direction="column" style={{ margin: '0' }} >
      <Grid item style={{ paddingLeft: '0' }}>
        <ToggleButtonGroup size="small" value={formType} exclusive onChange={toggleButtons}>
          <ToggleButton value="domestic" style={{ textTransform: "capitalize" }}>Domestic</ToggleButton>
          <ToggleButton value="international" style={{ textTransform: "capitalize" }}>International</ToggleButton>
          {/* <ToggleButton value="collections" style={{ textTransform: "capitalize" }}>Collections</ToggleButton> */}
        </ToggleButtonGroup>
      </Grid>
      <Grid item style={{ paddingLeft: '8px' }}>
        {formType === 'domestic'
          ? (
            <DomesticDefaults
              userDetails={props.userDetails}
              domesticDefaultValues={defaultValues.domesticDefaults}
              getOrderDefaults={getMarketplaceDefaults}
              tabIndex={formType}
              isFromMarketplace = {true}
            />
          )
          : null}
        {formType === 'international'
          ? (
            <MarketplaceIntlDefaults
              userDetails={props.userDetails}
              internationalDefaultValues={defaultValues.internationalDefaults}
              countries={countries}
              getOrderDefaults={getMarketplaceDefaults}
            />
          )
          : null}
        {/* {formType === 'collections'
          ? (
            <CollectionsDefaults
              userDetails={props.userDetails}
              collectionDefaultValues={defaultValues.collectionDefaults}
              getOrderDefaults={getMarketplaceDefaults}
              tabIndex={formType}
              isFromMarketplace = {true}
            />
          )
          : null} */}
      </Grid>
    </Grid>
  );
};

MarketplacesDefaults.propTypes = {};

const mapStateToProps = (state) => {
  return {
    userDetails: state.auth.userDetails,
  };
};

export default connect(mapStateToProps, null)(MarketplacesDefaults);
