import { Route, Redirect } from 'react-router-dom';
import { UserRoles } from '../../utils/constants/constants';

const PrivateRoute = ({ component: Component, isAuth, isAccess, role, ...rest }) => (
    <Route {...rest} render={(props) => (
        (isAuth === true && isAccess === true)
            ? <Component {...props} />
                : (isAuth === true && role === UserRoles.Reporting)
? <Redirect to="/dashboard" />
                    : (isAuth === true) && <Redirect to="/shipment/addShipment" />
        //  <Redirect to='/login' />
    )} />
)

export default PrivateRoute;