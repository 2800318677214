import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, makeStyles, Paper, SvgIcon } from '@material-ui/core';
import DynamicMaterialTable from '../../../../../framework/DynamicMaterialTable/DynamicMaterialTable';
import { ReactComponent as EditIcon } from '../../../../../assets/images/EditIcon.svg';
import CustomDialog from '../../../../../framework/dialog/customDialog';
import AddPrinterQueue from '../addPrinterQueue/addPrinterQueue';
import { FieldArray } from 'formik';
import ConfirmationDialog from '../../../../addressBook/confirmation/confirmation';

const useStyles = makeStyles((theme) => ({
  customHeader: {
    font: '14px Delivery_W_Bd',
    padding: '0px 16px',
  },
  customTable: {
    padding: '5px 30px 20px 30px !important',
  },
}));

const PrinterQueueList = (props) => {
  const classes = useStyles();
  const { availablePrinters, setFieldValue, formikValues, formField } = props;
  const getColumns = () => {
    return [
      { title: 'id', field: 'id', hidden: true, width: 0 },
      {
        title: 'Printer queue name',
        field: 'printerQueueName',
        sorting: false,
      },
      {
        title: 'Printer to print on',
        field: 'printerToPrint.name',
        sorting: false,
      },
    ];
  };
  const [open, setOpen] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [showConfirmationPopup, setConfirmationPopup] = useState(false);

  const [columns] = useState(getColumns());
  const [oldprinterQueueList, setOldprinterQueueList] = React.useState([]);

  const handleModal = () => {
    open ? setOpen(false) : setOpen(true);
  };

  const handleDialogClose = () => {
    if (!isFormDirty) {
      setOpen(false);
      setFieldValue('printerQueueList', oldprinterQueueList);
      setFieldValue('printerQueueName', '');
      setFieldValue('printerToPrint', availablePrinters[0]);
    } else {
      setConfirmationPopup(true);
    }
  };

  const handleCloseConfirmationPopup = () => {
    setConfirmationPopup(false);
  };

  const addToPrinterList = (arrayHelpers, printersList) => {
    arrayHelpers.form.setValues(printersList);
  };

  const handleConfirmation = () => {
    setOpen(false);
    setFieldValue('printerQueueList', oldprinterQueueList);
    setFieldValue('printerQueueName', '');
    setFieldValue('printerToPrint', availablePrinters[0]);
    setConfirmationPopup(false);
  };

  useEffect(() => {
    if (!formikValues?.defaultPrinter?.value) {
      setFieldValue('defaultPrinter', availablePrinters[0]);
    }
  }, [availablePrinters, formikValues?.defaultPrinter, setFieldValue]);

  return (
    <Grid container direction="column" style={{ width: '100%' }}>
      <Grid item>
        <Grid container direction="row" justify="flex-end">
          <Grid item>
            <IconButton className="add-btn-printerq" onClick={handleModal}>
              <SvgIcon component={EditIcon} viewBox="0 0 48 48" style={{ fontSize: '2.5rem' }} />
               Add/remove
            </IconButton>

          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Paper elevation={3}>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <Grid container direction="row" justify="flex-start">
                <Grid item>
                  <span className={classes.customHeader}>Printer queue list</span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.customTable + ' Table-column-color'}>
              <FieldArray
                name={formField.printerQueueList.props.name}
                render={(arrayHelpers) => {
                  const printerList = formikValues.printerQueueList;
                  return (
                    <>
                      <DynamicMaterialTable
                        rows={printerList}
                        columns={columns}
                        paging={false}
                        isSelectable={false}
                        empty_DataSource_Message="No printer queue mappings available"
                        tableLayout="fixed"
                      />
                      <CustomDialog className="add-remove-printerq-popup" title="Add/remove printer queue" open={open} onClose={handleDialogClose}>
                        <AddPrinterQueue
                          {...props}
                          arrayHelpers={arrayHelpers}
                          addToPrinterList={(printerList) => addToPrinterList(arrayHelpers, printerList)}
                          setOldprinterQueueList={setOldprinterQueueList}
                          savePrinterQueues={handleModal}
                          setIsFormDirty={setIsFormDirty}
                        />
                      </CustomDialog>
                      <CustomDialog
                        className="popup-remove-message"
                        title="Add/remove printer queue"
                        open={showConfirmationPopup}
                        onClose={handleCloseConfirmationPopup}
                        maxWidth="xs"
                      >
                        <ConfirmationDialog
                          confirmationMessage={'Any unsaved changes will be lost'}
                          onConfirm={handleConfirmation}
                          OnCancel={handleCloseConfirmationPopup}
                        />
                      </CustomDialog>
                    </>
                  );
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

PrinterQueueList.propTypes = {};

export default PrinterQueueList;
