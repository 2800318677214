import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import InputSelect from '../../../../framework/Inputs/inputSelect/inputSelect'
import { useSelector } from 'react-redux';
import { getYupSchemaFromMetaData } from '../../../../utils/yupSchema/yupSchemaGenerator';
import FormRenderer from '../../../../framework/Inputs/formRenderer/formRenderer';
import {
    formatParcelDetails,
    formatDeliveryOptions,
    setValuesToParcelDetails,
    setValuesToDeliveryOptions,
    setValuesToDeliveryOptions_domaesticDefaults,
    removeUndefinedProperties,
    getCustomerAccountID,
    getDomesticAccounts,
    getDriverCollectionAccounts,
    setDropdownValues,
} from '../../../../utils/helperFunctions';
import domesticDefaultsForm from '../../../../utils/formConfig/configuration/orderFileDefaultsForm/domesticDefaultsForm';
const CollectionDefaultAccounts = (props) => {
    const { formField: { defaultCollectionAccounts },
        setFieldValue, formikValues } = props
    const userAccounts = useSelector(state => state?.accounts?.nonReturnuserAccounts ?? []);
    let domesticAccounts = getDomesticAccounts(userAccounts);

    let accountFromApi;
    useEffect(() => {
        accountFromApi = domesticAccounts.find(temp => temp?.accountId === props.collectionDefaultValues?.account);

        setTimeout(() => {
            if (!props.collectionDefaultValues?.account && domesticAccounts?.length > 0) {
                setFieldValue('defaultCollectionAccounts', domesticAccounts[0])
            }
            else {
                setFieldValue('defaultCollectionAccounts', accountFromApi)
            }
        }, 0);
    }, [props.tabIndex, props.collectionDefaultValues])
    return (
        <Grid container spacing={2} direction="row">
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormRenderer   {...defaultCollectionAccounts.props} data={domesticAccounts} fullWidth />
            </Grid>
        </Grid>
    )

}


export default CollectionDefaultAccounts
