/* eslint-disable no-useless-constructor */
import React from 'react';
import PropTypes from 'prop-types';
import DateRangePicker from 'react-daterange-picker';
import 'react-daterange-picker/dist/css/react-calendar.css';
import { FormControl, TextField } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

const InputDateRange = (props) => {
  const { showTextField, minimumDate, maximumDate, label } = props;
  return (
    <div>
      {showTextField
        ? (
          <div>
            <FormControl fullWidth>
              <TextField
                style={{ width: '300px', border: '1px solid #ACACAC', borderRadius: '4px' }}
                value={
                  props.value.start !== undefined
                    ? `${props.value.start.format('DD/MM/YYYY')}-${props.value.end.format('DD/MM/YYYY')}`
                    : `${props.value.value.start.format('DD/MM/YYYY')}-${props.value.value.end.format('DD/MM/YYYY')}`
                }
                variant="filled"
                label={label}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <EventIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </div>
        )
        : null}

      <DateRangePicker
        value={props.datepickerValue}
        onSelect={props.onSelect}
        singleDateRange={true}
        minimumDate={minimumDate}
        maximumDate={maximumDate}
      />
    </div>
  );
};

InputDateRange.propTypes = {
  showTextField: PropTypes.bool,
};

InputDateRange.defaultProps = {
  showTextField: true,
};

export default InputDateRange;
