export const ChangeDeliveryInstrForm = {
  formId: 'changeDeliveryInstrForm',
  formField: {
    deliveryDueDate: {
      props: { name: 'deliveryDueDate', label: '', type: 'radio' },
    },
    deliveryType: {
      props: { name: 'deliveryType', label: 'Delivery type', type: 'select', displayField: 'name' },
    },
    safePlaceLocation: {
      props: { name: 'safePlaceLocation', label: 'Select', type: 'select', displayField: 'name', hasDefaultValue: true},
    },
    safePlaceLocationAdditionalText: {
      props: { name: 'safePlaceLocationAdditionalText', type: 'text', label: 'comments', isRequired: true},
      validation: {
        name: 'safePlaceLocationAdditionalText',
        validationType: 'string',
        validations: [
          {
            type: 'when',
            params: [
              ['safePlaceLocation'],
              (safePlaceLocation, schema) => {
                return (safePlaceLocation && (safePlaceLocation.value === '7' || safePlaceLocation.value === '8'))
                  ? schema.required('Instructions are required')
                  : schema.notRequired();
              },
            ],
          },
          {
            type: 'max',
            params: [30, 'Maximum of 30 characters allowed'],
          },
        ],
      },
    },
    
  },
};
