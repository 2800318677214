import * as actionTypes from '../actions/actionsTypes';
import { updateObject } from '../../utils/common';

const initialState = {
  selectedEvent:""
};

const setSelectedEvent = (state, action) => {
  return updateObject(state, {
    selectedEvent: action.data,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SETSELECTEDEVENT:
      return setSelectedEvent(state, action);
    default:
      return state;
  }
};

export default reducer;
