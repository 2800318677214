import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import CustomCaption from '../../../../framework/summaryFormate/CustomCaption';
import { formatDate } from '../../../../utils/helperFunctions';
import CommodityDetailsSummary from '../../../../container/shipment/addShipment/addShipmentSummary/CommodityDetailsSummary';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles({
  flexContainer: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
  },
  rowDiff: {
    marginTop: '5px',
    marginBottom: '10px',
  },
});

const CustomDetailsSummary = ({ shipmentData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let userAccounts = useSelector((state) => state.auth.userDetails);
  const [commodityDetails, setCommodityDetails] = useState({});
  const [iossFieldName, setiossFieldName] = useState('');
  const [iossFieldvalue, setiossFieldvalue] = useState('');

  const parseCommodityDetails = () => {
    return shipmentData?.articles?.article.map((commodity) => {
      return {
        ...commodity,
        fullDescriptionOfGoods: commodity.descriptionofGoods,
        weight: commodity?.unitWeight,
        countryOfOrigin: {
          code: commodity?.countryOfManufacture,
        },
      };
    });
  };

  useEffect(() => {
    const newCommodityDetails = {
      commodityDetails: parseCommodityDetails(),
      subTotal: shipmentData?.totalValue,
      customDeclarationShippingCharges: shipmentData?.shippingCharges,
      totalValue: shipmentData?.shippingCharges + shipmentData?.totalValue,
    };
    setCommodityDetails(newCommodityDetails);
    let result = userAccounts.accounts.filter(accounts => accounts.accountId == shipmentData.accountNumber);
    setiossFieldName("IOSS number")
    if (!shipmentData?.iossShipment) {
      setiossFieldvalue('-')
    }
    else if (result[0]?.configuration?.iossDHLAccountAllowed) {
      setiossFieldvalue("Using DHL IOSS number")
    }
    else if (result[0]?.customsRegistration?.iossNumber) {
      setiossFieldvalue(shipmentData.sender.shippersIOSS)
    }
    else {
      setiossFieldvalue(shipmentData.sender.shippersIOSS)
    }
  }, [shipmentData]);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography m={2} className={classes.rowDiff}>
          Invoice type: {shipmentData?.invoiceCategory ?? '-'}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="row" justify="space-between">
          <Grid item className={classes.flexContainer}>
            <CustomCaption title="Invoice date" value={formatDate(shipmentData?.invoiceDate, '/', 'dd/mm/yy')} />
          </Grid>
          <Grid item className={classes.flexContainer}>
            <CustomCaption title="Invoice no." value={shipmentData?.invoiceNumber ?? '-'} />
          </Grid>
          <Grid item className={classes.flexContainer}>
            <CustomCaption title="Shipping charges" value={shipmentData?.shippingCharges ?? '-'} />
          </Grid>
          <Grid item className={classes.flexContainer}>
            <CustomCaption title="Currency" value={shipmentData?.currencyCode ?? '-'} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="row" justify="space-between">
          <Grid item className={classes.flexContainer}>
            <CustomCaption title="Shipper’s EORI no." value={shipmentData?.sender?.shipperEORI ?? '-'} />
          </Grid>
          <Grid item className={classes.flexContainer}>
            <CustomCaption title="Receiver’s VAT/PID/EORI No." value={shipmentData?.recipient?.recipientEORI ?? '-'} />
          </Grid>
          <Grid item className={classes.flexContainer}></Grid>
          <Grid item className={classes.flexContainer}></Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CommodityDetailsSummary shipmentDetails={commodityDetails} />
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="row" justify="space-between">
          <Grid item className={classes.flexContainer}>
            <CustomCaption title="Reason for export" value={shipmentData?.reasonForExport ?? '-'} />
          </Grid>
          <Grid item className={classes.flexContainer}>
            <CustomCaption title="FDA registration no." value={shipmentData?.sender?.fdaRegistrationNumber ?? '-'} />
          </Grid>
          <Grid item className={classes.flexContainer}>
            <CustomCaption title="Terms of delivery" value={shipmentData?.termsOfDelivery ?? '-'} />
          </Grid>
          <Grid item className={classes.flexContainer}>
            <CustomCaption title="Apply IOSS number" value={(shipmentData?.iossShipment) ? 'Yes' : 'No'} />
          </Grid>
          <Grid item className={classes.flexContainer}>
            <CustomCaption title={iossFieldName} value={iossFieldvalue} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

CustomDetailsSummary.propTypes = {
  shipmentData: PropTypes.object.isRequired,
};

export default CustomDetailsSummary;
