import React, { useEffect, useState } from 'react';
import { Grid, IconButton, SvgIcon} from '@material-ui/core';
import OrderFile from '../orderFileMapping/orderFile';
import CustomModal from '../../../../framework/modal/modal';
import { ReactComponent as EditIcon } from '../../../../assets/images/EditIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ServiceEndPoints } from '../../../../utils/constants/ApiConstant';
import AddMapping from '../orderFileMapping/addMapping';
import ConfirmationDialog from '../../../addressBook/confirmation/confirmation';
import InputButton from '../../../../framework/Inputs/inputButton/inputButton';
import { getCustomerAccountID, customTableSort } from '../../../../utils/helperFunctions';
import { axiosConfig } from '../../../../api/axios';
import * as actions from '../../../../store/actions/index';
import FormRenderer from '../../../../framework/Inputs/formRenderer/formRenderer';

const MarketplaceMapping = (props) => {
  const {
    formField : {marketPlacesAvailable, newMarketplaceName, newMarketplaceCode},
    values,
    setFieldValue, 
    setFieldTouched, 
    errors, 
    setErrors,
    setMarketPlacesList,
    marketPlacesList
  } = props;

  const tableColumns = [
    { title: 'Map from', field: 'mapFrom', width: '30%', customSort: (a, b) => customTableSort(a.mapFrom, b.mapFrom) },
    { title: 'Mapped to', field: 'mappedTo', sorting: false, width: '57%' },
  ];

  let mappingsUrl;

  const [services, setServices] = useState([]);
  const [mappingServices, setMappingServices] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [mappedMarketplaceDefault, setMappedMarketplaceDefault] = React.useState({});
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [isUpdating_Flag, setisUpdating_Flag] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    getMarketplacesList();
    getInitialValues();
  }, []);

  const handleDialogClose = (setFieldTouched, setFieldValue) => {
    setFieldValue('mapFrom', '');
    setFieldTouched('mapFrom', false);
    if (isUpdating_Flag) {
      setisUpdating_Flag(false);
      setShowConfirmationDialog(true);
    } else {
      setIsDialogOpen(false);
    }
  };

  const setDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleConfirmClose = () => {
    setIsDialogOpen(true)
    setShowConfirmationDialog(false);
    setisUpdating_Flag(true);
  };
  const onDialogConfirm = () => {
    setIsDialogOpen(false);
    setShowConfirmationDialog(false);
  };

  const getMarketplacesList = () =>{
    axiosConfig
    .get(`${ServiceEndPoints.getMarketplacesList}`)
    .then((response) => {
        setMarketPlacesList(response.data);
    })
    .catch((err) => {
        setMarketPlacesList([]);
    })
    .finally((e) =>{
        if(props.adminLevelMapping){
          setMarketPlacesList((marketPlacesList) => {
           const pushAddMarketplace = [...marketPlacesList];
           pushAddMarketplace.push({marketplaceName : '+ Add Marketplace', marketplaceCode: 'addMarketplace'});
           return pushAddMarketplace;
         });
       }
    });
  };
  
  const addMarketplacesToList = (values, setFieldValue) => {
    const newMarketplaceData = {
      marketplaceCode: values?.newMarketplaceCode,
      marketplaceName: values?.newMarketplaceName

    }
    axiosConfig
    .post(`${ServiceEndPoints.getMarketplacesList}`,newMarketplaceData)
    .then((response) => {
      dispatch(actions.setAppSnackBarData({ msg: 'Marketplace added successfully' }));
      setFieldValue('newMarketplaceName','');
      setFieldValue('newMarketplaceCode','');
      getMarketplacesList();
    })
    .catch((error) => {
      dispatch(
        actions.setBottomAlert({
          msgs: [
            {
              type: 'error',
              msg: 'Error in adding marketplace, please try again.',
            },
          ],
        }),
      );
    });
  }

  const getInitialValues = () => {
    axiosConfig
    .get(`${ServiceEndPoints.getMappedServices}`)
    .then((response) => {
      const output = response.data.map(({ code, longDescription }) => ({ name: longDescription, value: code }));
      setServices(output);
    })
    .catch((err) => {
      setServices([]);
    });

    props.adminLevelMapping ? mappingsUrl = ServiceEndPoints.getAdminMarketplaceMappings : mappingsUrl = ServiceEndPoints.getMarketplaceDefaults;
      axiosConfig
      .get(`${mappingsUrl}?organization=${getCustomerAccountID()}`)
      .then((response) => {
        props.adminLevelMapping ? setMappedMarketplaceDefault(response.data) : setMappedMarketplaceDefault(response.data.marketPlaceMappings);
      })
      .catch((err) => {
        setMappedMarketplaceDefault([]);
      });
  };

  useEffect(() => {
    if (props.adminLevelMapping && values?.marketPlacesAvailable?.marketplaceCode !== 'addMarketplace') {
      setFieldValue('newMarketplaceName', '');
      setFieldValue('newMarketplaceCode', '');
    }
    if (services && services.length > 0 && mappedMarketplaceDefault && mappedMarketplaceDefault.length > 0) {
      let serviceMap = [];
      let marketplacesServicesData = mappedMarketplaceDefault.filter((ele) => ele?.marketplaceName === values?.marketPlacesAvailable?.marketplaceName);
      marketplacesServicesData.map((ele) => {
        ele.servicesMapping.map((ser) => {
          var mapToName = services.find((serval) => serval.value === ser.mappedTo);
          if (mapToName !== undefined) {
            const item = {
              mapFrom: ser.mapFrom,
              mappedTo: mapToName.name,
            };
            serviceMap.push(item);
          }
        });
      });
      setMappingServices(serviceMap);
    }
  }, [services, mappedMarketplaceDefault, values?.marketPlacesAvailable?.marketplaceName]);

  const saveMappingDetails = (updatedAccounts, updatedServices, formikValues) => {
    let newServicesMapping = [];
    setIsDialogOpen(false);
    setMappingServices(updatedServices);
    updatedServices.map((updateMap) => {
      const item = {
        mapFrom: updateMap.mapFrom,
        mappedTo: services.find((serval) => serval.name === updateMap.mappedTo).value,
      };
      newServicesMapping.push(item);
    });
    const updatedMarketplaceServices = {
        marketplaceName : formikValues?.marketPlacesAvailable?.marketplaceName,
        servicesMapping : newServicesMapping,
    }
    setMappedMarketplaceDefault((mappedMarketplaceDefault) => {
      let newMappedMarketplaceDefault = [];
      if(mappedMarketplaceDefault){
        newMappedMarketplaceDefault = [...mappedMarketplaceDefault?.filter((ele) => ele.marketplaceName !== formikValues?.marketPlacesAvailable.marketplaceName)];
      }
      newMappedMarketplaceDefault?.push(updatedMarketplaceServices);
      return newMappedMarketplaceDefault;
  });
  };

  const saveServiceMapping = () => {
    if(props.adminLevelMapping){
      axiosConfig
      .post(`${ServiceEndPoints.getAdminMarketplaceMappings}?organization=${getCustomerAccountID()}`, mappedMarketplaceDefault)
      .then((response) => {
        dispatch(actions.setAppSnackBarData({ msg: 'Marketplace order mappings saved successfully' }));
      })
      .catch((error) => {
        dispatch(
          actions.setBottomAlert({
            msgs: [
              {
                type: 'error',
                msg: 'Mappings not saved in database, please try again.',
              },
            ],
          }),
        );
      })
    }
    else{
      axiosConfig
      .patch(`${ServiceEndPoints.getMarketplaceDefaults}/marketplacemappings?organization=${getCustomerAccountID()}`, mappedMarketplaceDefault)
      .then((response) => {
        dispatch(actions.setAppSnackBarData({ msg: 'Marketplace order mappings saved successfully' }));
      })
      .catch((error) => {
        dispatch(
          actions.setBottomAlert({
            msgs: [
              {
                type: 'error',
                msg: 'Mappings not saved in database, please try again.',
              },
            ],
          }),
        );
      })
    };
  };

  const _isUpdatingFlag = (flag) => {
    setisUpdating_Flag(flag);
  };

  return (
    <React.Fragment>
      <Grid container xs={12} sm={12} md={12} lg={12}>
        <Grid item xs={2} sm={2} md={2} lg={2} style={{ marginBottom: '15px', marginRight: '10px' }}>
          <FormRenderer {...marketPlacesAvailable.props} data={marketPlacesList} fullWidth />
        </Grid>
        {props.adminLevelMapping && values?.marketPlacesAvailable?.marketplaceCode === 'addMarketplace' ? <><Grid item xs={2} sm={2} md={2} lg={2} style={{ marginBottom: '15px', marginRight: '10px' }}>
          <FormRenderer {...newMarketplaceName.props} fullWidth />
        </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2} style={{ marginBottom: '15px', marginRight: '10px' }}>
            <FormRenderer {...newMarketplaceCode.props} fullWidth />
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1}>
            <InputButton
              variant="outlined"
              className="outlined-btn add-marketplace-btn"
              onClick={() => addMarketplacesToList(values, setFieldValue)}
              isDisabled={values?.newMarketplaceCode === '' || values?.newMarketplaceName === ''}
              label="Add"
              buttonType="submit"
              fullWidth
            />
          </Grid></> : ''}
      </Grid>
      <Grid container xs={12} sm={12} md={12} lg={12}>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <span className="service-title">Services</span>
        </Grid>
        <Grid className="" item xs={6} sm={6} md={6} lg={6} justify="flex-end" style={{ display: 'flex' }}>
          <IconButton className="addedit-btn" onClick={() => setDialogOpen()} disabled={values?.marketPlacesAvailable?.marketplaceCode === 'addMarketplace'}>
            <SvgIcon component={EditIcon} viewBox="0 0 48 48" style={{ fontSize: '2.5rem' }} />
            <span className="addTitle" style={{ fontSize: '14px' }}>Add/edit</span>
          </IconButton>

        </Grid>
      </Grid>

      <Grid container>
        <CustomModal className="addEdit-popup" open={isDialogOpen} title="Add/edit mapping" onClose={() => handleDialogClose(setFieldTouched, setFieldValue)} >
          <AddMapping
            saveNewMappings={saveMappingDetails}
            _isUpdatingFlag={_isUpdatingFlag}
            formField={props.formField}
            formikValues={values}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            mappedServices={mappingServices}
            defaultServices={services}
            setErrors={setErrors}
            isMarketplaceMapping={true}
            marketPlacesList={marketPlacesList}
          />
        </CustomModal>
      </Grid>
      <Grid container xs={12} sm={12} md={12} lg={12}>
        <OrderFile
          rows={mappingServices}
          columns={tableColumns}
          isSelectable={false}
          paging={false}
          empty_DataSource_Message="No mappings available"
          title="Remove templates"
        />
      </Grid>
      <Grid
        container
        justify="flex-end"
        spacing={2}
        className="btn-container"
      >
        <Grid item>
          <InputButton
            variant="contained"
            className="contained-btn save"
            onClick={() => saveServiceMapping()}
            buttonType="submit"
            label="Save changes"
          />
        </Grid>
      </Grid>
      <Grid container item className="customModal-book">
        <CustomModal
          open={showConfirmationDialog}
          title={'Mapping Changes'}
          onClose={handleConfirmClose}
          maxWidth="sm"

        >
          <ConfirmationDialog
            confirmationMessage={'Any unsaved changes will be lost?'}
            onConfirm={onDialogConfirm}
            OnCancel={handleConfirmClose}
          />
        </CustomModal>
      </Grid>
    </React.Fragment>
  );
};

export default MarketplaceMapping;