import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import rootReducer from './store/reducers/index';
import './assets/fonts/Delivery_W_Rg.woff';
import './styles/mixin.scss';
import './styles/styles.scss';
import { createStore } from 'redux';
import AzureAD from "react-aad-msal";
import { signInAuthProvider} from "../src/utils/authProvider/authProvider";
const store = createStore(rootReducer);

ReactDOM.render(
  //for B2C LOGIN AUTHORIZATION

 <AzureAD provider={signInAuthProvider} forceLogin={true}>,
<React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
</React.StrictMode>
 </AzureAD>,
document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
