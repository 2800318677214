const internationDefaultsForm = {
  formId: 'internationDefaultsForm',
  formField: {
    destinationCountry: {
      props: {
        name: 'destinationCountry',
        label: 'Destination country',
        type: 'select',
        displayField: 'name',
        isRequired: true,
      },
      value: {
        code: '',
      },
    },
    totalVolumetricWeight: {
      props: {
        name: 'totalVolumetricWeight',
      },
      value: 0.0,
    },
    weight: {
      props: {
        name: 'weight',
        label: 'Weight (kg)',
        type: 'number',
        placeholder: 'Select',
        // isRequired: true,

        inputProps: {
          min: 0,
          max: 1000,
          step: 'any',
        },
      },
      validation: {
        name: 'weight',
        validationType: 'number',
        validations: [
          {
            type: 'min',
            params: [0.1, 'Weight is outside permitted range'],
          },
          {
            type: 'max',
            params: [1000, 'Weight is outside permitted range'],
          },
        ],
      },
    },
    length: {
      props: {
        name: 'length',
        label: 'Length (cm)',
        type: 'number',
        // isRequired: true,

        inputProps: {
          min: 1,
          max: 200,
        },
      },
      validation: {
        name: 'length',
        validationType: 'number',
        validations: [
          {
            type: 'typeError',
            params: ['Length must be number type'],
          },
          {
            type: 'min',
            params: [1, 'Declared length must be greater than 0'],
          },
          {
            type: 'max',
            params: [200, 'Declared length must be less than or equal to 200'],
          },
          {
            type: 'when',
            params: [
              ['width', 'height'],
              (width, height, schema) => {
                if (width || height) {
                  return schema.test('', 'Length must be longest side', (value) => {
                    return value >= width && value >= height;
                  });
                }
              },
            ],
          },
        ],
      },
    },
    width: {
      props: {
        name: 'width',
        label: 'Width (cm)',
        type: 'number',
        // isRequired: true,

        inputProps: {
          min: 1,
          max: 120,
        },
      },
      validation: {
        name: 'width',
        validationType: 'number',
        validations: [
          {
            type: 'typeError',
            params: ['Width must be number type'],
          },
          {
            type: 'min',
            params: [1, 'Declared width must be greater than 0'],
          },
          {
            type: 'max',
            params: [120, 'Declared width must be less than or equal to 120'],
          },
        ],
      },
    },
    height: {
      props: {
        name: 'height',
        label: 'Height (cm)',
        type: 'number',
        // isRequired: true,

        inputProps: {
          min: 1,
          max: 120,
        },
      },
      validation: {
        name: 'height',
        validationType: 'number',
        validations: [
          {
            type: 'typeError',
            params: ['Height must be number type'],
          },
          {
            type: 'min',
            params: [1, 'Declared height must be greater than 0'],
          },
          {
            type: 'max',
            params: [120, 'Declared height must be less than or equal to 120'],
          },
        ],
      },
    },

    inboxReturnLabel: {
      props: {
        name: 'inboxReturnLabel',
        label: 'Include inbox return label:',
        type: 'radio',
      },
    },

    returnReference: {
      props: {
        name: 'returnReference',
        label: 'Return reference',
        type: 'text',
      },
      value: '',
    },

    noOfItems: {
      props: {
        name: 'noOfItems',
        label: 'No. of items',
        type: 'number',
        inputProps: {
          min: 0,
          max: 20,
        },
        restrictDecimal: true
      },
      validation: {
        name: 'noOfItems',
        validationType: 'number',
        validations: [
          // {
          //   type: 'required',
          //   params: ['Number of Items is required.'],
          // },
          {
            params: [0, 'The value of items can not be less than 1'],
            type: 'min',
          },
          {
            type: 'max',
            params: [20, 'The value of items can not be greater than 20'],
          },
        ],
      },
    },

    commodityDetails: {
      props: {
        name: 'commodityDetails',
        type: 'array',
        fields: {
          commodityCode: 'text',
          fullDescriptionOfGoods: 'text',
          weight: 'number',
          quantity: 'number',
          unitValue: 'number',
          countryOfOrigin: 'text',
        },
      },
      values: [],
      validation: {
        name: 'commodityDetails',
        validationType: 'array',
        validations: [
          {
            type: 'max',
            params: [20, 'max 20 '],
          },
        ],
      },
    },
    subTotal: {
      props: {
        name: 'subTotal',
      },
      value: 0,
    },
    totalValue: {
      props: {
        name: 'totalValue',
      },
      value: 0,
    },
    totalWeightExceed: {
      props: {
        name: 'totalWeightExceed',
        type: 'number',
      },
      value: 0,
      validation: {
        name: 'totalWeightExceed',
        validationType: 'number',
        validations: [
          {
            type: 'when',
            params: [
              ['weight','noOfItems','destinationCountry'],
              (weight, noOfItems, destinationCountry, schema) => {
                if (destinationCountry?.code !== 'GBR') {
                  return schema
                  .test('', 'Total Weight of Pieces exceeds the Declared Weight', (value) => {
                    return ((value || 0) <= (weight || 0) * (noOfItems || 1));
                    });
                } else {
                  return schema;
                }
              },
            ],
          },
        ],
      },
    },

    invoiceTypeLabel: {
      props: {
        name: 'invoiceTypeLabel',
        label: 'Invoice type',
        type: 'radio',
        isRequired: 'true',
      },
      value: 'commercial',
    },
    
    invoiceNumber: {
      props: {
        name: 'InvoiceNumber',
        label: 'Invoice number',
        type: 'text',
        isRequired: false,
        inputProps: {
          maxLength: 35,
        },
      },
      validation: {
        name: 'invoiceNumber',
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [35, 'Maximum 35 characters allowed'],
          },
        ],
      },
    },

    customDeclarationShippingCharges: {
      props: {
        name: 'customDeclarationShippingCharges',
        label: 'Shipping charges',
        type: 'text',
        isRequired: false,
        inputProps: {
          maxLength: 10,
        },
      },
      validation: {
        name: 'customDeclarationShippingCharges',
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [10, 'Maximum 10 digits are allowed for shipping charges'],
          },
          {
            type: 'matches',
            params: [/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/, 'Shipping charges must be a positive number'],
          },
        ],
      },
    },

    customDeclarationCurrency: {
      props: {
        name: 'customDeclarationCurrency',
        label: 'Currency',
        type: 'select',
        displayField: 'name',
        isRequired: false,
        hasDefaultValue: false,
      },
    },

    reasonForExport: {
      props: {
        name: 'reasonForExport',
        label: 'Reason for export',
        type: 'select',
        displayField: 'description',
        isRequired: false,
        // value: '0',
        hasDefaultValue: false,
      },
    },

    marketplaceIossNo: {
      props: {
        name: 'marketplaceIossNo',
        label: "Marketplace IOSS number",
        type: 'text',
        isRequired: false,
        inputProps: {
          maxLength: 12,
        },
      },
      validation: {
        name: 'marketplaceIossNo',
        validationType: 'string',
        validations: [
          {
            type: 'matches',
            params: [/IM\d{10}/, "IOSS no. must be in the correct format (e.g. IM0123456789)"],
          },
        ],
      },
    },

    termsOfDelivery: {
      props: {
        name: 'termsOfDelivery',
        label: 'Terms of delivery',
        type: 'select',
        displayField: 'label',
        isRequired: false,
        hasDefaultValue: false,
        // isDisabled: true,
      },
    },
  },
};

export default internationDefaultsForm;
