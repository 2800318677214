import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import CustomCaption from '../../../../framework/summaryFormate/CustomCaption';

const ProductAndServiceSummary = ({ shipmentDetails }) => {
  return (
    <Grid container item xs={12} sm={12} lg={12} spacing={3}>
      <Grid item xs={12} lg={3}>
        <CustomCaption title="Product" value={shipmentDetails.product?.productNameForUI || ''} />
      </Grid>
      <Grid item xs={12} lg={3}>
        <CustomCaption title="Service" value={(shipmentDetails.service || {}).name} />
      </Grid>
    </Grid>
  );
};

export default ProductAndServiceSummary;
