import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

const CustomSnackBar = ({
  snackBarMsg,
  setSnackBarMsg,
  autoHideDuration,
  verticalPosition,
  horizontalPostion,
  severity,
}) => {
  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  return (
    <Snackbar
      id="MuiAlert-snackbar-message"
      anchorOrigin={{
        vertical: verticalPosition,
        horizontal: horizontalPostion,
      }}
      open={snackBarMsg !== ''}
      onClose={() => setSnackBarMsg('')}
      autoHideDuration={autoHideDuration}
    >
      <Alert onClose={() => setSnackBarMsg('')} severity={severity}>
        {snackBarMsg}
      </Alert>
    </Snackbar>
  );
};

CustomSnackBar.propTypes = {
  horizontalPostion: PropTypes.oneOf(['left', 'center', 'right']),
  verticalPosition: PropTypes.oneOf(['top', 'bottom']),
  autoHideDuration: PropTypes.number,
  snackBarMsg: PropTypes.string.isRequired,
  setSnackBarMsg: PropTypes.func.isRequired,
  severity: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
};

CustomSnackBar.defaultProps = {
  horizontalPostion: 'right',
  verticalPosition: 'top',
  autoHideDuration: 3000,
  severity: 'success',
};

export default CustomSnackBar;
