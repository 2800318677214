import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, IconButton, Menu, SvgIcon, MenuItem, Select, ListItemIcon, ListItemText, Checkbox, Tooltip } from '@material-ui/core';
import { GET } from '../../../api/axios';
import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';
import DynamicMaterialTable from '../../../framework/DynamicMaterialTable/DynamicMaterialTable';
import FormRenderer from '../../../framework/Inputs/formRenderer/formRenderer';
import InputDateRange from '../../../framework/date-range/dateRange';
import originalMoment from 'moment';
import { extendMoment } from 'moment-range';
import TableExport from '../../../components/TableExport/TableExport';
import { Event } from '@material-ui/icons';
import { basicSort, formatDate, convertTZ } from '../../../utils/helperFunctions';
import { ReactComponent as FilterIcon } from '../../../assets/images/Filter.svg';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { ReactComponent as InfoIcon } from '../../../assets/images/InfoIconDark.svg';
import AuditDetails from '../auditDetails/auditDetails';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
const filterButtonStyle = {
  padding: '4px !important',
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};
const ByUserId = (props) => {
  const {
    formikValues,
    formField: { userId, dateRange },
    tabIndex,
    setValues,
    setFieldError,
    setFieldTouched,
    setFieldValue,
  } = props;
  const tableRef = React.createRef();
  const lastYearDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1));

  const CustomFilter = props => {
    const [selectedVal, setSelectedVal] = useState([]);
    function handleChange(e) {
      const val = e.target.value;
      setSelectedVal(val);
      props.onFilterChanged(props.columnDef.tableData.columnOrder, val);
    }

    return (
      <th>
        <Select value={selectedVal} onChange={handleChange}
          multiple={true}
          MenuProps={MenuProps}
          style={{ width: "100px" }}
          renderValue={(data) => <div>{(data.length > 1) ? data[0] + '...' : data[0]}</div>}
        // disableUnderline
        >
          {props.columnDef.filterValues.map((option) => (
            <MenuItem key={option} value={option}>
              <ListItemIcon>
                <Checkbox
                  checked={selectedVal.indexOf(option) > -1}
                />
              </ListItemIcon>
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </th>
    );
  };
  const headers = [
    { label: 'User ID', key: 'user' },
    { label: 'Event name', key: 'artifactDisplayName' },
    { label: 'Audit date', key: 'logDate' },
    { label: 'Audit time', key: 'logTime' },
    { label: 'Description', key: 'operation' },
  ];

  const tableColumns = [
    { title: 'id', field: 'id', hidden: true },
    {
      title: (
        <div style={{ display: 'flex', paddingTop: '7px' }}>
          <div style={{ paddingTop: '10px' }}>
            <span>Event name</span>
          </div>

          <div>
            <IconButton onClick={() => setShowFilter(true)} style={filterButtonStyle}>
              <SvgIcon component={FilterIcon} viewBox="0 0 30 30" />
            </IconButton>
          </div>
        </div>
      ),
      field: 'artifactDisplayName',
      filtering: true,
      sorting: false,
      lookup: {
        Shipment: 'Shipment',
        'Pickup': 'Pickup',
        Template: 'Template',
        AddressBook: 'AddressBook',
        // 'User management': 'User management',
        Configuration: 'Configuration',
        Users: 'Users',
        OrderFile: 'OrderFile'
      },
      filterValues:
        ['Shipment', 'Pickup', 'Template', 'AddressBook',
          , 'Configuration', 'Users', 'OrderFile'
        ],
      filterComponent: (props) => <CustomFilter {...props} />,
    },
    {
      title: 'Audit date',
      field: 'logDate',
      filtering: false,
      sorting: true,
      customSort: (a, b) =>
        basicSort(new Date(`${a?.logDate}`.split('/').reverse().join('-')).getTime(), new Date(`${b?.logDate}`.split('/').reverse().join('-')).getTime()),
    },
    {
      title: 'Audit time',
      field: 'logTime',
      filtering: false,
      customSort: (a, b) =>
        basicSort(new Date(`03/23/2021 ${a.logTime}`).getTime(), new Date(`03/23/2021 ${b.logTime}`).getTime()),
      sorting: true,
    },
    {
      title: 'Description',
      field: 'operation',
      filtering: false,
      sorting: false,
    },
    {
      field: 'operation',
      filtering: false,
      title: 'Details',
      sorting: false,
      render: (row) => {
        return <div>
          {row?.activity === 'amend' && row?.artifactDisplayName === 'Shipment' ?
            <Tooltip
              title="Click to view details"
              arrow
              placement="right"
              onClick={(e) => handleInfoClick(row)}
            >
              <SvgIcon component={InfoIcon} viewBox="0 0 48 48" style={{ fontSize: '2.5rem', borderStyle: 'none' }} />
            </Tooltip> : ''}
        </div>
      },
    }
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const [columns] = useState(tableColumns);
  // const [userIdValues, setUserIdValues] = useState([]);
  const [ExportData, setExportData] = useState([]);
  const [TotalCount, setTotalCount] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [filterChanged, setFilterChanged] = useState(10);
  const [tableRawData, setTableRawData] = useState([]);
  const [pageDetails, setPageDetails] = useState({
    offset: 0,
    limit: 0,
    totalRecordsLength: 0,
  });
  const [userIdOptions, setUserIdOptions] = useState([{ name: 'Select', value: '' }]);
  const moment = extendMoment(originalMoment);
  const todayDate = moment();
  const loader = useSelector((state) => state.loaderLayOver.showLoader);
  const dispatch = useDispatch();
  const [ExportPdfData, setExportPdfData] = useState([]);

  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const [amendInfoTableData, setAmendInfoTableData] = useState([]);
  const [artifactId, setArtifactId] = useState('');

  const getUrl = (query) => {
    let url = ServiceEndPoints.auditLogs;
    url += `?offset=${query.page * query.pageSize}`;
    url += `&limit=${query.pageSize}`;
    setPageSize(query.pageSize);
    return (url += getFilterOptions());
  };
  // useEffect(() => {
  //   let url = ServiceEndPoints.auditLogs;
  //   url += `&offset=${0}`;
  //   GET(url)
  //   .then((res)=>{
  //   })
  // }, []);
  const getFilterOptions = () => {
    let filterString = `&userId=${(formikValues.userId || {}).value ? (formikValues.userId || {}).value : ''}`;
    filterString += `&startDate=${formikValues.startDate}`;
    return (filterString += `&endDate=${formikValues.endDate}`);
  };


  const handleInfoClick = (row) => {
    setTimeout(() => {
      let _temp = _formatInfoTableData(row)
      setAmendInfoTableData(_temp);
      setShowInfoDialog(true);
    }, 0);

  };

  const _formatInfoTableData = (row) => {
    setArtifactId(row.artifactId);
    let finalInfoData = [];
    row?.amendments?.forEach((element) => {
      finalInfoData.push({
        fieldName: element?.fieldName,
        oldValue: element?.oldValue ? element.oldValue : "-",
        newValue: element?.newValue ? element.newValue : "-",
      })
    });
    return finalInfoData;
  }

  const _formatTableData = (_result) => {
    let _finalData = [];
    (_result || []).forEach((_element) => {
      let _convertedDateTime = convertTZ(_element.responseTimeStamp);
      _finalData.push({
        user: _element.user,
        artifactDisplayName: _element.artifactDisplayName,
        artifactId: _element.artifactId,
        amendments: _element.amendments,
        logDate: _convertedDateTime.logDate,
        logTime: _convertedDateTime.logTime,
        operation: _element.operation,
        activity: _element.activity,
      })
    });
    return _finalData;
  }

  const getTableData = (query) => {
    const offset = query.page * query.pageSize;
    const limit = query.pageSize;

    return new Promise((resolve, reject) => {
      if (!formikValues.userId.value) {
        resolveTableData(resolve, [], query, 0);
      }
      if ((formikValues.userId.value !== '') && (pageDetails.offset !== query.page * query.pageSize || pageDetails.limit !== query.pageSize || filterChanged)) {
        GET(getUrl(query))
          .then((res) => {
            // setExportData(res.data.auditLogs);
            let FinalData = (res.data.auditLogs.length > 0) ? _formatTableData(res.data.auditLogs) : [];
            setTableRawData(FinalData);
            setTotalCount(res.data === null ? 0 : res.data.totalRecords);
            setPageDetails({
              offset,
              limit,
              totalRecordsLength: (res.data || {}).totalRecords,
            });
            setFilterChanged(false);
            filterData(FinalData, query, resolve, res?.data?.totalRecords);
            resolveTableData(resolve, FinalData, query, res.data?.totalRecords || 0);
          })
          .catch((err) => {
            console.log(err);
            setTotalCount(0);
            resolveTableData(resolve, [], query, 0);
            setFilterChanged(false);
          });
      } else {
        filterData(tableRawData, query, resolve, pageDetails.totalRecordsLength);
      }
    });
  };

  const filterData = (tableData, query, resolve, totalRecordsLength) => {
    const filteredData = tableData.filter((row) => {
      return !query.filters
        .filter((individualFilter) => individualFilter.value.length > 0)
        .map((mappedObjet) => {
          if (
            (mappedObjet.value || []).filter((filterValue) => {
              const rowValue = mappedObjet.column.field.split('.').reduce((a, b) => a[b], row);
              return (filterValue || '').toLowerCase() === (rowValue || '').toLowerCase();
            }).length > 0
          ) {
            return true;
          }
          return false;
        })
        .some((checkValue) => checkValue === false);
    });
    resolveTableData(resolve, filteredData, query, totalRecordsLength);
  };

  const resolveTableData = (resolve, tableData, query, totalCount) => {
    resolve({
      data: tableData,
      page: query.page,
      totalCount,
      filters: [],
    });
  };

  const onDataUpdate = () => {
    let url = ServiceEndPoints.auditLogs;
    url += `?offset=${0}`;
    url += `&limit=${TotalCount}`;
    url += getFilterOptions();
    GET(url)
      .then((res) => {
        let FinalData = (res.data.auditLogs.length > 0) ? _formatTableData(res.data.auditLogs) : [];
        setExportData(FinalData);
        setExportPdfData(FinalData)
        dispatch(actions.setAppSnackBarData({ msg: `Data exported successfully` }));
      })
      .catch((err) => {
        console.log(err);
        setExportData([]);
        dispatch(
          actions.setBottomAlert({
            msgs: [
              {
                type: 'error',
                msg: 'Error in exporting data, please try again later',
              },
            ],
          }),
        );
      });
  };

  const onSelect = (val) => {
    if (val && val.start && val.end) {
      const dateObj = {
        startDate: formatDate(val.start._i, '/').split('/').reverse().join('/'),
        endDate: formatDate(val.end._i, '/').split('/').reverse().join('/'),
      };
      dateObj.dateRange = `${dateObj.startDate} - ${dateObj.endDate}`;
      setValues({ ...formikValues, ...dateObj });
      setAnchorEl(null);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  useEffect(() => {
    if (formikValues.userId && formikValues.userId.value !== "") {
      let url = ServiceEndPoints.auditLogs;
      url += `?offset=${0}`;
      url += `&limit=${TotalCount}`;
      url += getFilterOptions();
      GET(url).then((res) => {
        let FinalData = (res.data.auditLogs.length > 0) ? _formatTableData(res.data.auditLogs) : [];
        setExportPdfData(FinalData)
        setExportData(FinalData)
      })
    }
    else {
      setExportPdfData([])
      setExportData([]);
    }
  }, [TotalCount, (formikValues.userId || {}).value ? (formikValues.userId || {}).value : ''])

  useEffect(() => {
    dispatch(actions.setLoaderLayOver(true));
    GET(`${ServiceEndPoints.getUsers}`)
      .then((res) => {
        let userDataDefault = [{ name: 'Select', value: '' }]
        let userResponseData = ((res.data || {}).finalResponse || []).map(({ emailId, userId }) => {
          return {
            name: emailId,
            value: userId,
          }
        })
        let userData = [...userDataDefault, ...userResponseData]
        setUserIdOptions(userData)
        setTimeout(() => {
          dispatch(actions.setLoaderLayOver(false));
        }, 2000);
      })
      .catch((err) => {
        dispatch(actions.setLoaderLayOver(false));
        console.log(err);
        setUserIdOptions([{ name: 'Select', value: '' }]);
      });
    setFieldValue('userId', userIdOptions[0]);
    setFieldValue('dateRange', 'Select')
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (tabIndex === 1) {
      tableRef.current && tableRef.current.onQueryChange();
    }
    setFilterChanged(true);
  }, [formikValues.userId, formikValues.startDate, formikValues.endDate, tabIndex]);

  const dateRangeBlurred = (event) => {
    const dateRangeValue = event.target.value;
    const dates = dateRangeValue.split('-');

    if (dates.length !== 2) {
      setFieldTouched('dateRange', true);
      const dateObj = {
        startDate: '',
        endDate: '',
      };
      setValues({ ...formikValues, ...dateObj });
      setFieldError('dateRange', 'Invalid dates');
    } else {
      const fromDate = convertDDtoDateObj(dates[0]);
      const toDate = convertDDtoDateObj(dates[1]);
      if (fromDate instanceof Date && !isNaN(fromDate) && toDate instanceof Date && !isNaN(toDate)) {
        setFieldTouched('dateRange', true);
        if (fromDate.getTime() < lastYearDate.getTime() || toDate.getTime() > new Date().getTime()) {
          setFieldError('dateRange', 'Invalid dates');
          const dateObj = {
            startDate: '',
            endDate: '',
          };
          setValues({ ...formikValues, ...dateObj });
        } else {
          const dateObj = {
            startDate: formatDate(fromDate, '/').split('/').reverse().join('/'),
            endDate: formatDate(toDate, '/').split('/').reverse().join('/'),
          };
          setValues({ ...formikValues, ...dateObj });
        }
      } else {
        setFieldError('dateRange', 'Invalid dates');
        const dateObj = {
          startDate: '',
          endDate: '',
        };
        setValues({ ...formikValues, ...dateObj });
      }
    }
  };

  const convertDDtoDateObj = (dateinDDFormat, splitter = '/') => {
    const splittedDate = dateinDDFormat.split(splitter);
    return new Date(splittedDate[2], splittedDate[1] - 1, splittedDate[0]);
  };

  const printDocument = () => {
    try {
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "landscape"; // portrait or landscape
      const doc = new jsPDF(orientation, unit, size);
      const title = "My Awesome Report";
      const headersName = [headers.map(header => header.label)];
      const data = ExportPdfData.map(elt => [elt.user, elt.artifactDisplayName, elt.logDate, elt.logTime, elt.operation]);

      let content = {
        head: headersName,
        body: data,
        columnStyles: {
          0: {
            columnWidth: 200
          },
          1: {
            columnWidth: 150
          },
          2: {
            columnWidth: 100
          },
          3: {
            columnWidth: 100
          },
          4: {
            columnWidth: 230
          }
        },
        styles: {
          cellWidth: 'wrap',
          fontSize: 12,
          overflowColumns: 'linebreak'
        }
      };
      doc.autoTable(content);
      doc.save("AuditByUserId.pdf")
      dispatch(actions.setAppSnackBarData({ msg: `Data exported successfully` }));
    }
    catch (err) {
      console.log(err);
      dispatch(
        actions.setBottomAlert({
          msgs: [
            {
              type: 'error',
              msg: 'Error in exporting data, please try again later',
            },
          ],
        }),
      );
    }
  }


  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Grid container className="top-btn-container" direction="row" justify="space-between" alignItems="flex-end">
          <Grid item xs={6} >
            <Grid container direction="row" spacing={3} justify="flex-start">
              <Grid item xs={6} className="user-id-container">
                <FormRenderer
                  {...userId.props}
                  fieldValue={formikValues.userId}
                  valuePropertyName="value"
                  fulllWidth
                  data={userIdOptions}
                />
              </Grid>
              <Grid item xs={6}>
                <FormRenderer
                  {...dateRange.props}
                  // label="Select"
                  onBlur={(event) => dateRangeBlurred(event)}
                  endArguments={{
                    endAdornment: (
                      <IconButton onClick={handleClick}>
                        <Event />
                      </IconButton>
                    ),
                  }}
                />
                <Menu
                  id="simple-menu"
                  style={{ maxHeight: '750px' }}
                  anchorEl={anchorEl}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  // transformOrigin={{ vertical: "middle", horizontal: "center" }}
                  // anchorPosition={{
                  //   vertical: 'bottom',
                  //   horizontal: 'center'
                  // }}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <Box width="326px">
                    <Grid style={{ width: '100%', padding: '20px' }} item className="By-userId-Date-range">
                      <InputDateRange
                        onSelect={onSelect}
                        open={true}
                        value={moment.range(todayDate.clone(), todayDate.clone())}
                        showTextField={false}
                        maximumDate={new Date()}
                        minimumDate={lastYearDate}
                      />
                    </Grid>
                  </Box>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} >
            <TableExport
              headers={headers}
              tabIndex={props.tabIndex}
              formikValues={formikValues}
              class_Name="ByShipmentNumber_Export"
              data={ExportData}
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              filename={'ByUserId.csv'}
              onDataUpdate={onDataUpdate}
              onClick={printDocument}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item className="Table-column-color">
        <DynamicMaterialTable
          rows={getTableData}
          columns={columns}
          isSelectable={false}
          tableRef={tableRef}
          filtering={showFilter}
          pageSize={pageSize}
          pageSizeOptions={[10, 25, 50]}
          sorting={true}
          empty_DataSource_Message='Audit details not found'
        />
      </Grid>
      <Grid container xs={12} sm={12} md={12} lg={12}>
        <AuditDetails
          showInfoDialog={showInfoDialog}
          amendInfoTableData={amendInfoTableData}
          artifactId={artifactId}
          setShowInfoDialog={setShowInfoDialog}
        />
      </Grid>
    </Grid>
  );
};

ByUserId.propTypes = {};

export default ByUserId;

// const userIdOptions = [
//   {
//     name: 'Select',
//     value: '',
//   },
//   {
//     name: 'uUDkW7nfEmWSkryUSgNgl18vgxj1',
//     value: 'uUDkW7nfEmWSkryUSgNgl18vgxj1',
//   },
//   {
//     name: 'Option 2',
//     value: 'option2',
//   },
// ];
