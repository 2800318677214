import { createStore, combineReducers } from 'redux';

import authReducer from './auth';
import accountReducer from './userAccounts';
import customerReducer from './favouriteCustomers';
import addressBookSearchParamsReducer from './addressBookSearchParams';
import loadParcelDimensionsReducer from './loadParcelDimensions';
import loadedFromReducer from './loadedFrom';
import shipmentManagementSearchParamsReducer from './shipmentManagementSearchParams';
import addShipmentDataReducer from './addShipmentData';
import dashboardTracking from './dashboardTracking';
import backUpResponseReducer from './backupResponse';
import selectedEventReducer from './selectedEvent';
import shipmentIdReducer from './shipmentId';
import commonReducer from './common';
import setBookedShipmentIdReducer from './bookedShipmentId';
import setDefaultAddNewAccountReducer from './defaultAddNewAccount';
import setApplicationUpdatesReducer from './applicationUpdates';
import setLoaderLayOver from './loaderLayOver';
import setAlertInfo from './alertInfo';
import setKeyPressEvent from './keyPressEvent';
const rootReducer = combineReducers({
  auth: authReducer,
  accounts: accountReducer,
  customers: customerReducer,
  searchParams: addressBookSearchParamsReducer,
  loadParcelDimensions: loadParcelDimensionsReducer,
  loadedFrom: loadedFromReducer,
  shipmentSearchParams: shipmentManagementSearchParamsReducer,
  addShipmentData: addShipmentDataReducer,
  backUpResponse: backUpResponseReducer,
  dashboardTracking,
  selectedEvent: selectedEventReducer,
  shipmentId: shipmentIdReducer,
  bookedshipmentId: setBookedShipmentIdReducer,
  common: commonReducer,
  addNewAccountToUsers: setDefaultAddNewAccountReducer,
  applicationUpdates: setApplicationUpdatesReducer,
  loaderLayOver: setLoaderLayOver,
  keyPressEvent: setKeyPressEvent,
  alertInfo: setAlertInfo,
});

export default rootReducer;
