import * as actionTypes from '../actions/actionsTypes';
 
import { updateObject } from '../../utils/common';

const initialState = {
  dashboardTracking: '',
  dashboardFindShipment: '',
};

const setDashboardTracking = (state, action) => {
  return updateObject(state, {
    dashboardTracking: action.data,
  });
};
const setDashboardFindShipment = (state, action) => {
  return updateObject(state, {
    dashboardFindShipment: action.data,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SETDASHBOARDTRACKING:
      return setDashboardTracking(state, action);
    case actionTypes.SETDASHBOARDFINDSHIPMANET:
      return setDashboardFindShipment(state, action);
    default:
      return state;
  }
};

export default reducer;
