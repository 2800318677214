import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Checkbox, FormControlLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import InputButton from '../../../framework/Inputs/inputButton/inputButton';
import { ADDRESSBOOK_MODAL_CONTENT } from '../../../utils/constants/constants';
import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';
import { toBase64 } from '../../../utils/common';
import { POST } from '../../../api/axios';
import './AddressBookModalContent.scss';
import SampleCSVFile from '../../sampleCSVFile/SampleCSVFile';
import ConfirmationDialog from '../confirmation/confirmation';
import CustomModal from '../../../framework/dialog/customDialog';
import { getCustomerAccountID, setDropdownValues } from '../../../utils/helperFunctions';
import FormRenderer from '../../../framework/Inputs/formRenderer/formRenderer';
import { useDispatch } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { exportToTextFile } from '../../../utils/common';
const CustomCheckBox = withStyles({
  root: {
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const AddressBookModalContent = (props) => {
  const {
    // formField: { addressImportType },
    // addressImportTypeOptions,
    setFieldValue,
    formikValues,
  } = props;

  const dispatch = useDispatch();

  const [state, setState] = useState({
    ignoreFirstRow: false,
    overwriteDuplicates: false,
    isClearExistingAddress: false,
  });
  const [uploadFile, setUploadFile] = useState('No file selected');
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleImport = () => {
    let uploadedFile = document.getElementById('addressBook').files[0];
    const lastIndex = uploadedFile.name.lastIndexOf(".");
    if (uploadedFile && uploadedFile.name.slice(lastIndex + 1)?.toLowerCase() === 'csv'
      // ((props.formikValues.addressImportType?.value !== "consignor" && uploadedFile.name.slice(lastIndex + 1)?.toLowerCase() === 'csv') ||
      //   (props.formikValues.addressImportType?.value === "consignor" && uploadedFile.name.slice(lastIndex + 1)?.toLowerCase() === "txt")
      // )
      ) {
      dispatch(actions.setLoaderLayOver(true));
      toBase64(uploadedFile)
        .then((result) => {
          let payload = result.split(',');
          postAddressData({ addressbookData: payload[1] });
        })
        .catch((error) => {
          dispatch(actions.setLoaderLayOver(false));
          dispatch(
            actions.setBottomAlert({
              msgs: [
                {
                  type: 'error',
                  msg: 'Failed to import file',
                },
              ],
            }),
          );
          // dispatch(actions.setAppSnackBarData({type:"error",msg:"Failed to import file"}))
          clearFile();
        });
    } else {
      dispatch(
        actions.setBottomAlert({
          msgs: [
            {
              type: 'error',
              msg: 'File format is invalid',
            },
          ],
        }),
      );
      // dispatch(actions.setAppSnackBarData({ type: 'error', msg: 'File format is invalid' }));
      clearFile();
    }
  };

  const uponUpload = (event) => {
    setUploadFile(event.target.files[0].name);
  };

  const postAddressData = (payload) => {
    let queryParams = `?ignoreFirstRow=${state.ignoreFirstRow}&overwriteDuplicates=${state.overwriteDuplicates
      }&isClearExistingAddress=${state.isClearExistingAddress}&organization=${getCustomerAccountID()}&importFileType=ship`;
      // &importFileType=${props.formikValues.addressImportType?.value}`;
    const postUrl = ServiceEndPoints.postAddressBookCsv + queryParams;

    POST(postUrl, payload)
      .then((response) => {
        if (response.data.errors === null) {
          dispatch(actions.setAppSnackBarData({ msg: `${response.data.success}` }));
          props.onClose();
          props.refreshData();
        }
        else {
          exportToTextFile(response.data.errors.join('\n'), 'AddressBookImportErrors.txt');
          dispatch(
            actions.setBottomAlert({
              msgs: [
                {
                  type: 'error',
                  msg: `${response.data.success}`,
                },
              ],
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(
          actions.setBottomAlert({
            msgs: [
              {
                type: 'error',
                msg: `Failed to update the data :${err?.response?.data?.[0]?.detail}`,
              },
            ],
          }),
        );
      })
      .finally(() => {
        dispatch(actions.setLoaderLayOver(false));
        clearFile();
        props.refreshData();
      });
  };

  const clearFile = () => {
    document.getElementById('addressBook').value = [];
    setUploadFile('No file selected');
  };

  const handleDialogClose = () => {
    setShowConfirmationDialog(false);
  };
  const onDialogConfirm = () => {
    setShowConfirmationDialog(false);
    handleImport();
  };
  const onDialogCancel = () => {
    setShowConfirmationDialog(false);
  };

  useEffect(() => {
    // setFieldValue('addressImportType', setDropdownValues(addressImportTypeOptions, 'value', 'domestic'));
    setState({ ...state, ignoreFirstRow: true });
  }, []);

  // useEffect(() => {
  //   if (formikValues.addressImportType?.value !== 'consignor') {
  //     setState({ ...state, ignoreFirstRow: true });
  //   } else {
  //     setState({ ...state, ignoreFirstRow: false });
  //   }
  // }, [formikValues.addressImportType]);

  var confirmationMessage = `The address book contains ${props.totalRecords} entries. Are you sure you want to clear them?`;
  return (
    <Grid container>
      {/* <Grid item xs={5}>
        <FormRenderer {...addressImportType.props} fullWidth data={addressImportTypeOptions} />
      </Grid> */}
      {/* {props.formikValues.addressImportType && ( */}
        {/* <Grid item>{ADDRESSBOOK_MODAL_CONTENT(`${props.formikValues.addressImportType.value}`)}</Grid> */}
      {/* )} */}
      <Grid item>{ADDRESSBOOK_MODAL_CONTENT('ship')}</Grid>
      <Grid item container>
        <Grid item lg={9} md={9} sm={12} xs={12} xl={9}>
          <p className="form">
            <label className="add-photo-btn">
              Choose file
              <span>
                <input type="file" id="addressBook" name="myfile" onChange={uponUpload} />
              </span>
            </label>
            <input
              type="text"
              id="path"
              value={uploadFile}
              readOnly
              style={{
                marginLeft: '10px',
                height: '36px',
                border: '1px solid #ACACAC',
                width: '200px',
                textAlign: 'center',
                borderRadius: '4px',
              }}
            />
          </p>
        </Grid>
        {/* {props.formikValues.addressImportType?.value === 'ship' && ( */}
          <Grid item lg={3} md={3} sm={12} xs={12} xl={3} style={{ margin: 'auto', textAlign: 'right' }}>
            <SampleCSVFile />
          </Grid>
        {/* )} */}
      </Grid>
      <Grid container item>
        <Grid item lg={12} sm={12} xs={12} xl={12} md={12} className="isClearExistingAddress">
          <FormControlLabel
            control={
              <CustomCheckBox
                checked={state.isClearExistingAddress}
                onChange={handleChange}
                name="isClearExistingAddress"
              />
            }
            label="Clear existing addresses."
          />
        </Grid>
        <Grid item lg={12} sm={12} xs={12} xl={12} md={12} className="ignoreFirstRow">
          <FormControlLabel
            control={<CustomCheckBox checked={state.ignoreFirstRow} onChange={handleChange} name="ignoreFirstRow" />}
            label="Ignore first row."
          />
        </Grid>
        <Grid item lg={12} sm={12} xs={12} xl={12} md={12} className="overwriteDuplicates">
          <FormControlLabel
            control={
              <CustomCheckBox checked={state.overwriteDuplicates} onChange={handleChange} name="overwriteDuplicates" />
            }
            label="Overwrite duplicates."
          />
        </Grid>
      </Grid>
      <Grid item container spacing={3} align="center" justify="flex-end">
        <Grid item>
          <InputButton className="outlined-btn" variant="outlined" label="Cancel " onClick={props.onClose} />
        </Grid>
        <Grid item>
          <InputButton
            variant="contained"
            className="contained-btn"
            onClick={() => (state.isClearExistingAddress ? setShowConfirmationDialog(true) : handleImport())}
            buttonType="submit"
            label="Import"
          />
        </Grid>
      </Grid>
      <Grid container item className="customModal-book">
        <CustomModal open={showConfirmationDialog} title="" onClose={handleDialogClose} maxWidth="sm">
          <ConfirmationDialog
            confirmationMessage={confirmationMessage}
            onConfirm={onDialogConfirm}
            OnCancel={onDialogCancel}
          />
        </CustomModal>
      </Grid>
    </Grid>
  );
};

AddressBookModalContent.propTypes = {
  tableRef: PropTypes.object,
  totalRecords: PropTypes.string,
  onClose: PropTypes.func,

  formField: PropTypes.object,
  formikValues: PropTypes.object,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
};

export default AddressBookModalContent;
