import React, { useState } from 'react';
import {
  Grid,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableFooter,
  TableBody,
  Paper,
  TableContainer,
} from '@material-ui/core';
const CommodityDetailsSummary = ({ shipmentDetails }) => {
  const [isAddNewAddress, setIsAddNewAddress] = useState(false);
  return (
    <Grid style={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
      <Grid container direction="row" justify="space-between">
        <Grid item style={{ paddingTop: '15px', paddingBottom: '10px' }}>
          <strong>Item details</strong>
        </Grid>
      </Grid>
      <TableContainer component={Paper} className="tableContainer">
        <Table aria-label="caption table">
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <strong>Commodity code</strong>
              </TableCell>
              <TableCell align="left">
                <strong>Full description of goods</strong>
              </TableCell>
              <TableCell align="left">
                <strong>Weight</strong>
              </TableCell>
              <TableCell align="left">
                <strong>Quantity</strong>
              </TableCell>
              <TableCell align="left">
                <strong>Unit value</strong>
              </TableCell>
              <TableCell align="left" style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                <strong>Country of origin</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shipmentDetails?.commodityDetails?.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {row.commodityCode}
                </TableCell>
                <TableCell align="left">{row.fullDescriptionOfGoods}</TableCell>
                <TableCell align="left">{row.weight} kg</TableCell>
                <TableCell align="left">{row.quantity}</TableCell>
                <TableCell align="left">{row.unitValue}</TableCell>
                <TableCell align="left" style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                  {(row.countryOfOrigin || {}).code}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            {!isAddNewAddress ? (
              <>
                {' '}
                <TableRow variant="head" className="footerRow">
                  <TableCell align="left">Sub total</TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"></TableCell>

                  <TableCell align="right" style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                    {shipmentDetails.subTotal}
                  </TableCell>
                </TableRow>
                <TableRow variant="head" className="footerRow">
                  <TableCell align="left">Shipping cost</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>

                  <TableCell align="right" style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                    {isNaN(shipmentDetails.customDeclarationShippingCharges) ||
                    parseInt(shipmentDetails.customDeclarationShippingCharges) < 0
                      ? 0
                      : shipmentDetails.customDeclarationShippingCharges}
                  </TableCell>
                </TableRow>
              </>
            ) : null}
            <TableRow variant="head" className="footerRow">
              <TableCell align="left">
                <strong>Total value</strong>
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>

              <TableCell align="right" style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                <strong>{shipmentDetails.totalValue}</strong>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default CommodityDetailsSummary;
