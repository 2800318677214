import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import './CustomDataTable.scss';
const useStyles = makeStyles({
  // table: {
  //   // minWidth: 700,
  //   cursor: 'pointer'
  // },
  // tableRow: {
  //   '&$hover:hover': {
  //     backgroundColor: '#FFEEA9',
  //   },
  // },
  // rowText: {
  //   verticalAlign: 'text-top',
  // },
  // heading: {
  //   fontWeight: 700,
  // },
  // hover: {},
});
export default function CustomDataTable(props) {
  const { rows, rowClicked } = props;
  const classes = useStyles();
  return (
        <div className="Servicepoint-Tbl">
          <List>
            {rows.map((row, index) => (
              <ListItem className="row" button onClick={() => rowClicked(row)} > 
                <div className="list-items">
                  <div className="item-one items">
                    <h2>Address:</h2>
                    <p>{row.title}</p>
                    <p>{row.address.address1}</p>
                    <p>{row.address.address2}</p>
                    <p>{row.address.address3}</p>
                    <p>{row.address.postalTown}</p>
                    <p>{row.address.zipcode}</p>
                  </div>
                  <div className="item-two items">
                    <h2>Distance:</h2>
                    <p>Distance {parseFloat((row.distance || 0) * (0.00062137)).toFixed(2)} miles</p>
                  </div>
                  <div className="item-three items">
                    <h2>Opening Hours:</h2>
                    {row.openingHours.map((openingTime) => (
                        <p>
                          {openingTime.dayDescription}-{openingTime.timeDescription}{' '}
                        </p>
                      ))}
                  </div>
                </div>
              </ListItem>
            ))}
          </List>
      </div>
  );
}
