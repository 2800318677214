import React, { useState } from 'react'
import CheckBoxCustomAccordion from '../accordion/CheckBoxCustomAccordion'
import Typography from '@material-ui/core/Typography'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import ReactHtmlParser from 'react-html-parser';
import './faq.scss'
const FaqContainer = (props) => {
  const {question, answer, index, setIsChecked, isChecked, setIsUnChecked, setShowCheckBoxes, showCheckboxes, defaultExpanded, userRole} = props;
 
 
  const handleSingleCheck = (event) => {

  if (event.target.checked) {
    setIsChecked(event.target.name);

  } else {
    setIsUnChecked(event.target.name);

  }

}


const handleClickEvent = () => {
  setShowCheckBoxes(true)
}
    return (
      <FormControlLabel className="faqContainer"
      aria-label="Acknowledge"
      onClick={() => {
 handleClickEvent() 
}}
      onFocus={(event) => event.stopPropagation()}
      control={showCheckboxes && userRole
? <Checkbox name={index}  onChange={(e) => {
 handleSingleCheck(e) 
}} />
: <></>}
      label={ <CheckBoxCustomAccordion heading={question} defaultExpanded={defaultExpanded} >
      <Typography >
      <span>
            {ReactHtmlParser(answer)}
      </span>
      {/* <span dangerouslySetInnerHTML={{ __html: answer }} /> */}
     
      </Typography>
         
</CheckBoxCustomAccordion>}
    />
       
 
    )
}

export default FaqContainer
