import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import FormRenderer from '../../../framework/Inputs/formRenderer/formRenderer';
import { useSelector } from 'react-redux';
import iossDeclaration from './iossDeclaration';

const IossDeclartion = (props) => {
  const {
    formField: { iossNumber, marketplaceIossNo },
    setFieldValue,
    selectedAccount,
    formikValues,
  } = props;
  const StyledToggleButtonGroup = withStyles((theme) => ({
    root: {
      width: '100%',
      height: '52px',
      margin: '4px 0px',
    },
    grouped: {
      width: '50%',
    },
  }))(ToggleButtonGroup);
  const StyledToggleButton = withStyles((theme) => ({
    selected: {
      backgroundColor: '#B5B5B5 !important',
      color: '#000000E6 !important',
    },
    disabled: {
      backgroundColor: '#FFFFFF !important',
      color: '#000000 !important',
      borderstyle: 'solid',
    },
  }))(ToggleButton);

  const [selectIossType, setSelectIossType] = useState(formikValues.iossType);
  const [showDhlIoss, setShowDhlIoss] = useState(false);
  const [disableShipperButton, setDisableShipperButton] = useState(false);
  const loadedFrom = useSelector((state) => state.loadedFrom.loadedFrom);
  const loadedResponse = loadedFrom?.loadedResponse;

  const handleChange = (event, value) => {
    if (value !== null) {
      setSelectIossType(value);
      setFieldValue('iossType', value);
    }
  };
  useEffect(() => {
    if (
      loadedFrom?.loadedResponse?.iossNumberAssignedFrom !== undefined &&
      loadedFrom?.loadedResponse?.iossNumberAssignedFrom !== null &&
      loadedFrom?.loadedResponse?.iossNumberAssignedFrom === 'FILE'
    ) {
      setSelectIossType('marketNo');
      setFieldValue('iossType', 'marketNo');
      setFieldValue(
        'marketplaceIossNo',
        loadedResponse?.shipmentModel?.shipments[0]?.senderCustomsRegistrations?.find(val => val.type === "IOSS")?.id ?? ''
      );
      if (
        selectedAccount.configuration.iossDHLAccountAllowed === false &&
        !selectedAccount.customsRegistration.iossNumber
      ) {
        setDisableShipperButton(true);
        setShowDhlIoss(false);
      } else if (selectedAccount.configuration.iossDHLAccountAllowed) {
        setShowDhlIoss(true);
        setFieldValue('iossNumber', 'Using DHL IOSS number');
      } else {
        setShowDhlIoss(false);
        setDisableShipperButton(false);
        setFieldValue('iossNumber', selectedAccount.customsRegistration.iossNumber);
      }
    }
    else if (loadedFrom?.loadedFrom === "fromPreviewSummary") {
      if (
        selectedAccount.configuration.iossDHLAccountAllowed === false &&
        !selectedAccount.customsRegistration.iossNumber
      ) {
        setDisableShipperButton(true);
        setShowDhlIoss(false);
      } else if (selectedAccount.configuration.iossDHLAccountAllowed) {
        setShowDhlIoss(true);
        setFieldValue('iossNumber', 'Using DHL IOSS number');
      } else {
        setShowDhlIoss(false);
        setDisableShipperButton(false);
        setFieldValue('iossNumber', selectedAccount.customsRegistration.iossNumber);
      }
    } else {
      if (
        selectedAccount.configuration?.iossDHLAccountAllowed === false &&
        !selectedAccount.customsRegistration.iossNumber
      ) {
        setDisableShipperButton(true);
        setShowDhlIoss(false);
        setSelectIossType('marketNo');
        setFieldValue('iossType', 'marketNo');
      } else if (selectedAccount.configuration?.iossDHLAccountAllowed) {
        setShowDhlIoss(true);
        setSelectIossType('dhlIossNo');
        setFieldValue('iossType', 'dhlIossNo');
        setFieldValue('iossNumber', 'Using DHL IOSS number');
      } else {
        setShowDhlIoss(false);
        setDisableShipperButton(false);
        setSelectIossType('shippersNo');
        setFieldValue('iossType', 'shippersNo');
        setFieldValue('iossNumber', selectedAccount.customsRegistration?.iossNumber);
      }
    }
  }, [selectedAccount]);
  useEffect(() => {
    if (loadedFrom?.loadedFrom === "fromPreviewSummary") {
      setSelectIossType(loadedFrom?.loadedResponse?.iossType);
      setFieldValue('iossType', loadedFrom?.loadedResponse?.iossType);
      setFieldValue('iossNumber', loadedFrom?.loadedResponse?.iossNumber)
    }
  }, [loadedFrom])

  return (
    <React.Fragment>
      <Grid container className="dashboardContainer" direction="column" spacing={2}>
        <Grid item lg={6} md={6} sm={6} style={{ paddingRight: '34px' }}>
          <StyledToggleButtonGroup value={selectIossType} exclusive onChange={handleChange}>
            {showDhlIoss ? (
              <StyledToggleButton value="dhlIossNo" aria-label="dhlIossNo">
                DHL IOSS number
              </StyledToggleButton>
            ) : (
              <StyledToggleButton value="shippersNo" aria-label="shippersNo" disabled={disableShipperButton}>
                Shipper's IOSS number
              </StyledToggleButton>
            )}
            <StyledToggleButton value="marketNo" aria-label="marketNo">
              Marketplace IOSS number
            </StyledToggleButton>
          </StyledToggleButtonGroup>
        </Grid>
        {(selectIossType === 'shippersNo' || selectIossType === 'dhlIossNo') && (
          <Grid item lg={6} md={6} sm={6} style={{ paddingRight: '34px' }}>
            <FormRenderer
              label={selectedAccount.configuration.iossDHLAccountAllowed ? 'DHL IOSS number' : "Shipper's IOSS number"}
              {...iossNumber.props}
              fullWidth
            />
          </Grid>
        )}
        {selectIossType === 'marketNo' && (
          <Grid item lg={6} md={6} sm={6} style={{ paddingRight: '34px' }}>
            <FormRenderer {...marketplaceIossNo.props} fullWidth />
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default IossDeclartion;
