import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import OrdersData from './ordersData';
import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';
import { axiosConfig } from '../../../api/axios';
import { getCustomerAccountID, customTableSort } from '../../../utils/helperFunctions';
// import { Backdrop } from '@material-ui/core/';
// import CircularProgressWithLabel from '../../../framework/spinner/circularProgressBar';
import * as actions from '../../../store/actions/index';

class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalRecords: 0,
      columns: [
        { title: 'id', field: 'id', hidden: true, width: 0 },
        {
          title: 'Customer ref.',
          field: 'customerReference',
          width: 130,
          // customSort: (a, b) => customTableSort(a?.customerReference, b?.customerReference),
          sorting: true,
          render: (row) => <div onClick={() => this.orderRoWClicked(row)}>{row.customerReference}</div>,
        },
        {
          title: 'Business/Customer name',
          field: 'customerName',
          sorting: true,
          width: 220,
          render: (row) => <div onClick={() => this.orderRoWClicked(row)}>{row.customerName}</div>,
        },
        {
          title: 'Postal code',
          field: 'postalCode',
          sorting: true,
          width: 120,
        },
        {
          title: 'Source',
          field: 'source',
          sorting: false,
          width: 130,
        },
        {
          title: 'Sub-source',
          field: 'subSource',
          sorting: false,
          width: 130,
        },
        {
          title: 'Alternate ref.',
          field: 'alternateReference',
          sorting: true,
          width: 150,
        },
        {
          title: 'Contact',
          field: 'contact',
          sorting: true,
          width: 100,
        },
        {
          title: 'Dispatch date',
          field: 'dispatchDate',
          sorting: true,
          width: 90,
        },
      ],
      orders_pageDetails: {
        offset: 0,
        limit: 0,
        totalRecordsLength: 0,
      },
      orders_final_data: [],
      orders_totalCount: 0,
      pageSize: 10,
      pageNumber: 0,
      isLoading: false,
      ordersData: [],
      pageNumSizeSortChange: false,
      emptyTableMsgOnScanPrint: false,
      // counter: 1,
      // completionPercentage: 0,
      // // inProgress: false,
      // OrdersLength: 1
    };
    this.onClickSetSortOrder = this.onClickSetSortOrder.bind(this);
  }

  orderRoWClicked = (rowData) => {
    this.props.history.push({
      pathname: `/shipment/addShipment/${rowData.id}/true/true`,
      state: {
        isScanFlag: this.props.isScanFlag,
      },
    });
  };

  componentDidMount() {
    this.props.setSortOrder(``);
    this.getOrdersData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.tabIndex === 0 && ((this.props.isSearched !== prevProps.isSearched || this.props.refreshList)
      || (this.props.addressBookSearchParams.split('=')[0] !== prevProps.addressBookSearchParams.split('=')[0]
        && this.props.addressBookSearchParams.split('=')[1] !== '' && prevProps.addressBookSearchParams !== ''))) {
      this.setState({ pageSize: this.state.pageSize, pageNumber: 0 }, () => {
        this.getOrdersData();
      });
    }
  }
  getOrdersDataGetAPICall(offset, limit) {
    this.setState({emptyTableMsgOnScanPrint: false});
    axiosConfig
      .get(this.getUrl(offset, limit))
      .then((res) => {
        this.setState({
          orders_pageDetails: {
            offset: offset,
            limit: limit,
            totalRecordsLength: (res.data || {}).totalRecords,
          },
        });
        this.setState({ totalRecords: res.data?.totalRecords ? res.data?.totalRecords : 0 });
        this.formatOrdersData(res.data);
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          orders_pageDetails: {
            offset: 0,
            limit: 10,
            totalRecordsLength: 0,
          },
        });
        this.setState({ totalRecords: 0 });
        this.formatOrdersData([]);
      })
      .finally(() => this.setState({ isLoading: false }));
  }
  getOrdersDataPostAPICall(offset, limit) {
    // this.setState({
    //   inProgress: true,
    // })
    axiosConfig
      .post(this.postUrl(offset, limit))
      .then((res) => {
        this.setState({emptyTableMsgOnScanPrint: true,
          orders_pageDetails: {
            offset: offset,
            limit: limit,
            totalRecordsLength: (res.data || {}).totalRecords,
          },
        });

        this.props.setSnackBarMsg({ msg: `1 shipment(s) created successfully` });
        this.setState({ totalRecords: res.data?.totalRecords ? res.data?.totalRecords : 0 });
        this.formatOrdersData(res.data);
      })
      .catch(async (err) => {
        this.setState({emptyTableMsgOnScanPrint: false });
        if (err.response?.status === 422) {
          await this.getOrdersDataGetAPICall(offset, limit)
        } else {
          this.setState({
            orders_pageDetails: {
              offset: 0,
              limit: 10,
              totalRecordsLength: 0,
            },
            orders_final_data: [],
            orders_totalCount: 0,
            totalRecords: 0,
          });
          // this.formatOrdersData([]);
        }
        // this.setState({
        //   inProgress: false,
        // })
      })
      .finally(() => this.setState({ isLoading: false }));
  }
  getOrdersData() {
    this.props.setRefreshList(false);
    this.setState({ isLoading: true});
    setTimeout(() => {
    const offset = this.state.pageSize * this.state.pageNumber;
    const limit = this.state.pageSize;
    if (!this.props.isScanPrintFlag) {
      this.getOrdersDataGetAPICall(offset, limit)
    } else {
      if(this.props.addressBookSearchParams && this.props.addressBookSearchParams?.split('=')?.[1] !==''){
        this.getOrdersDataPostAPICall(offset, limit)
      }
      else if(this.props.autoReloadProcessOrders || this.props.isRefersFlag || this.state.pageNumSizeSortChange){
        this.getOrdersDataGetAPICall(offset, limit)
        this.props.UploadrefreshOrder(false);
        this.setState({pageNumSizeSortChange: false});
      }
      else{
        this.setState({ isLoading: false });
      }
    }
  }, 0);
  }

  getUrl = (offset, limit) => {
    let url = `${ServiceEndPoints.getOrders
      }?organization=${getCustomerAccountID()}&isValid=true&iscollectionrequest=false`;
    url += '&offset=' + offset;
    url += '&limit=' + limit;
    url += this.props.sortOrderState;
    url += this.props.addressBookSearchParams;
    return url;
  };
  postUrl = (offset, limit) => {
    let url = `${ServiceEndPoints.searchandprint
      }?organization=${getCustomerAccountID()}&isValid=true&iscollectionrequest=false`;
    url += this.props.addressBookSearchParams;
    url += `&labelFormat=${this.props?.printerConfiguration?.labelType == 2 ? "PDF" : "ZPL"}`
    return url;
  };

  formatOrdersData = (result) => {
    let final_data = [];
    result?.orders?.forEach((element, index) => {
      const consigneeAddress = element?.shipmentModel?.shipments?.[0]?.consigneeAddress;
      const recipientAddress = element?.shipmentModel?.shipments?.[0]?.recipientAddress;
      const shipmentDetails = element?.shipmentModel?.shipments?.[0]?.shipmentDetails;
      final_data.push({
        id: element.id,
        customerReference: (element || {}).referenceNumber,
        customerName:
          consigneeAddress?.addressType === 'doorstep'
            ? consigneeAddress?.companyName || consigneeAddress?.name
            : recipientAddress?.companyName || recipientAddress?.name,
        source: element.sourceReference,
        subSource: element.subSource,
        alternateReference: shipmentDetails?.customerRef2,
        contact: consigneeAddress?.addressType === 'doorstep' ? consigneeAddress?.name : recipientAddress?.name,
        postalCode:
          consigneeAddress?.addressType === 'doorstep' ? consigneeAddress?.postalCode : recipientAddress?.postalCode,
        dispatchDate: element?.shipmentModel?.pickup?.date,
        printerQueueName: element?.printerQueueName
      });
    });
    if (
      this.props.addressBookSearchParams !== '' &&
      this.props.addressBookSearchParams !== '&customerReferenceNumber=' &&
      this.props.addressBookSearchParams !== '&alternateReference=' &&
      this.props.isScanPrintFlag && !(final_data.length > 0)
    ) {
      this.props.printLabels(result, false);
      this.props.onProcessOrdersSelectedItem(true);
      this.props._setAddressBookSearchParams(`&customerReferenceNumber=`);
    } else {
      if (
        this.props.addressBookSearchParams !== '' &&
        this.props.addressBookSearchParams?.split('=')?.[1] &&
        this.props.addressBookSearchParams !== '&customerReferenceNumber=' &&
        this.props.addressBookSearchParams !== '&alternateReference=' &&
        final_data.length === 1 && !this.props.isScanPrintFlag
      ) {
        this.props.history.push({
          pathname: `/shipment/addShipment/${final_data[0].id}/true/true`,
          state: {
            isScanFlag: this.props.isScanFlag,
          }
        });
      }
    }
    this.setState({
      orders_final_data: final_data,
      orders_totalCount: result.totalRecords ? result.totalRecords : 0,
    });
  };

  onPageNumChange(pageNum) {
    this.setState({ pageNumber: pageNum ,pageNumSizeSortChange: true}, () => {
      this.getOrdersData();
    });
  }

  onPageSizeChange(pageSize) {
    this.setState({ pageSize: pageSize }, () => {
      this.getOrdersData();
    });
  }
  
  onClickSetSortOrder(orderIn, orderBy) {
    if(orderIn === 'asc')
    {
      this.props.setSortOrder(`&sortBy=${orderBy}&sortIn=ascending`);
    }
    else if(orderIn === 'desc'){
      this.props.setSortOrder(`&sortBy=${orderBy}&sortIn=descending`);
    }
    else{
      this.props.setSortOrder(``);
    }
    this.setState({ pageNumber: 0, pageNumSizeSortChange: true});
    this.getOrdersData();
  }

  render() {
    return (
      // <div>
        <OrdersData
          rows={this.state.orders_final_data}
          columns={this.state.columns}
          sorting={true}
          deleteSelectedOrder={this.props.deleteSelectedOrder}
          createAllShipments={this.props.createAllShipments}
          createShipment={this.props.createSelectedShipments}
          createAllCollections={this.props.createAllCollections}
          createSelectedCollections={this.props.createSelectedCollections}
          onProcessOrdersSelectedItem={this.props.onProcessOrdersSelectedItem}
          empty_DataSource_Message={this.state.emptyTableMsgOnScanPrint ? "  " : "Order not found, please try in review orders"}
          tabIndex={this.props.tabIndex}
          Dialogtitle="Delete orders"
          fullWidth
          totalCount={this.state.orders_totalCount}
          pageSize={this.state.pageSize}
          count={this.state.orders_totalCount}
          isLoading={this.state.isLoading}
          inBuiltEvents={{
            onChangeRowsPerPage: (e) => this.onPageSizeChange(e),
            onChangePage: (a) => this.onPageNumChange(a),
          }}
          page={this.state.pageNumber}
          tableLayout="fixed"
          deleteAllOrder={this.props.deleteAllOrder}
          isCollectionRequest={false}
          isValid={true}
          onClickSetSortOrder={this.onClickSetSortOrder}
        />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    addressBookSearchParams: state.searchParams,
    kepressed: state.keyPressEvent.key,
    printerConfiguration: state.common.printerConfiguration,
    sortOrderState: state.common.sortOrderState,
    autoReloadProcessOrders: state.common.autoReloadProcessOrders,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setSnackBarMsg: (data) => {
      dispatch(actions.setAppSnackBarData(data));
    },
    setSortOrder: (data) => {
      dispatch(actions.setSortOrderState(data));
    },
    _setAddressBookSearchParams: (data) => {
      dispatch(actions.setAddressBookSearchParams(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Orders));
