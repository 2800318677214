const AccountForm = {
  formId: 'AccountFormId',
  formField: {
    accountNumber: {
      props: {
        name: 'accountNumber',
        label: 'Account number',
        type: 'text',
        placeholder: 'Enter',
      },
    },
    companyName: {
      props: {
        name: 'companyName',
        label: 'Company name',
        type: 'text',
        placeholder: 'Enter',
      },
    },
    postalCode: {
      props: {
        name: 'postalCode',
        label: 'Postal code',
        type: 'text',
        placeholder: 'Enter',
      },
    },
    invoiceAddress: {
      props: {
        name: 'invoiceAddress',
        label: 'Invoice address',
        type: 'text',
        placeholder: 'Enter',
      },
    },
    tradingAddress: {
      props: {
        name: 'tradingAddress',
        label: 'Trading address',
        type: 'text',
        placeholder: 'Enter',
      },
    },
    // termsOfDelivery: {
    //   props: {
    //     name: 'termsOfDelivery',
    //     label: 'Terms of delivery',
    //     type: 'text',
    //     placeholder: 'Enter',
    //   },
    // },
    returnAddress: {
      props: {
        name: 'returnAddress',
        label: 'Return address',
        type: 'text',
        placeholder: 'Enter',
      },
    },
    linkedReturnAccountNumber: {
      props: {
        name: 'linkedReturnAccountNumber',
        label: 'Linked return account number',
        type: 'text',
        placeholder: 'Enter',
      },
    },
    fdaRegistrationNumber: {
      props: {
        name: 'fdaRegistrationNumber',
        label: 'FDA registration',
        type: 'text',
        placeholder: 'Enter',
      },
    },
    eoriNumber: {
      props: {
        name: 'eoriNumber',
        label: 'EORI number',
        type: 'text',
        placeholder: 'Enter',
      },
    },
    vatORtvaNumber: {
      props: {
        name: 'vatORtvaNumber',
        label: 'VAT / TVA number',
        type: 'text',
        placeholder: 'Enter',
      },
    },
    vatRegistersCheckbox: {
      props: {
        name: 'vatRegistersCheckbox',
        label: 'VAT Registered outside of the UK',
        type: 'checkbox',
      },
    },
    vatRadioBox: {
      props: {
        name: 'vatRadioBox',
        label: 'VAT / TVA:',
        type: 'radio',
      },
      value: 'no',
    },
    selectAccount: {
      props: {
        name: 'selectAccount',
        label: 'Select account',
        type: 'autoComplete',
        // displayId:'accountId',
        displayField: 'displayName',
        hasDefaultValue: true,
      },
    },
    returnLabelAccount: {
      props: {
        name: 'returnLabelAccount',
        label: 'Select account',
        type: 'select',
        displayField: 'accountName',
        hasDefaultValue: false,
      },
    },
    configureLinkedReturnAccountNumber: {
      props: {
        name: 'configureLinkedReturnAccountNumber',
        label: 'Linked return account number',
        type: 'text',
        placeholder: 'Enter',
        isDisabled: true,
      },
      value: '',
    },
    printReturnInstructions: {
      props: {
        name: 'printReturnInstructions',
        label: 'Print return instructions',
        type: 'checkbox',
      },
      value: false,
    },
    defaultInstructions: {
      props: {
        name: 'defaultInstructions',
        label: 'Default return instructions',
        type: 'text',
        placeholder: 'Enter',
        multiline: true,
        rowsMax: 11,
        rows: 11,
        isDisabled: true,
      },
      value:
      'Return Instructions within UK \n \nReturning your items via DHL eCommerce UK is simple.\n1. Package your return item ensuring that the parcel is sealed and secure \n2. Apply the return label to your parcel\n3. Take the parcel to your preferred ServicePoint who will process it for you\n \nGo to https://www.dhl.com/uk-service-point-finder to locate our ServicePoints which are situated in PayPoint stores ',
    },
    returnInstructions: {
      props: {
        name: 'returnInstructions',
        label: 'Return instructions',
        type: 'text',
        placeholder: 'Enter specific instructions that you wish to be included',
        multiline: true,
        rowsMax: 11,
        rows: 11,
        inputProps: {
          maxLength: 560,
        },
      },
    },
    logoFile: {
      props: {
        name: 'logoFile',
        label: 'Logo file for upload',
        type: 'text',
        placeholder: 'Select',
        // isDisabled: true,
      },
      value: '',
      validation: {
        name: 'logoFile',
        validationType: 'string',
        validations: [
          {
            type: 'when',
            params: [
              'imageField',
              (imageField, schema) => {
                return schema.test(
                  'checkImageSize',
                  `Maximum size of image file ${imageField?.fileName} cannot exceed 500 KB`,
                  (logoFileValue) => {
                    if ((imageField?.size ?? 0) > 500000) {
                      return false;
                    }
                    return true;
                  },
                );
              },
            ],
          },
          {
            type: 'when',
            params: [
              'imageField',
              (imageField, schema) => {
                return schema.test(
                  'checkImageHeight',
                  `Height of image file ${imageField?.fileName} cannot exceed 200 px`,
                  (logoFileValue) => {
                    if ((imageField?.height ?? 0) > 200) {
                      return false;
                    }
                    return true;
                  },
                );
              },
            ],
          },
          {
            type: 'when',
            params: [
              'imageField',
              (imageField, schema) => {
                return schema.test(
                  'checkImageWidth',
                  `Length of image file ${imageField?.fileName} cannot exceed 700 px`,
                  (logoFileValue) => {
                    if ((imageField?.width ?? 0) > 700) {
                      return false;
                    }
                    return true;
                  },
                );
              },
            ],
          },
        ],
      },
    },
    imageField: {
      props: {
        name: 'imageField',
        label: 'Logo file for upload',
        type: 'text',
        placeholder: 'Enter',
        isDisabled: true,
      },
      value: '',
    },
    iossNumber: {
      props: {
        name: 'iossNumber',
        label: 'IOSS number',
        type: 'text',
        placeholder: 'Enter',
      },
    },
  },
};

export default AccountForm;
