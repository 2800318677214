import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import './App.scss';
import { createMuiTheme } from '@material-ui/core';
import { HashRouter as Router } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { Paper } from '@material-ui/core';

import DeliveryFont from './assets/fonts/Delivery_W_Rg.woff';
import Layout from './framework/layout/layout';

import { getJSPMStatus, getPrintConfiguration, updateJSPMReference } from './utils/PrintHelper';
import { getAllGenralConfiguration } from './utils/helperFunctions';
import * as actions from '../src/store/actions/index';

const DeliveryTheme = {
  fontFamily: 'Delivery Regular',
  fontStyle: 'normal',
  src: `local('Delivery'),
  local('Delivery-Regular')
  url(${DeliveryFont}) format('woff')
  `,
};

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Delivery Regular',
    fontSize: 14,
    letterSpacing: 0,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [DeliveryTheme],
      },
    },
    MuiFormLabel: {
      asterisk: {
        color: 'red',
        '&$error': {
          color: 'red',
        },
      },
    },
  },
});

const App = () => {
  if (Object.keys(caches).length > 0) {
    caches.keys().then(async function (names) {
      await Promise.all(names.map(name => caches.delete(name)))
    })
    window.location.reload();
  }
  else {
    if (!(sessionStorage.getItem("reloadCompleted")) || sessionStorage.getItem("reloadCompleted")==="false") {
      sessionStorage.setItem("reloadCompleted", true);
      window.location.reload();
    }
  }

  const dispatch = useDispatch();
  let userAccounts = useSelector((state) => state.auth.userDetails);

  const printerConfiguration = useSelector((state) => state.common.printerConfiguration)
  const [_printerConfiguration, setPrinterConfiguration] = useState(null);
  //Get Staus of neoDynamics exe on host machine
  // useEffect(() => {
  //   getJSPMStatus(dispatch, actions);
  // }, []);

  useEffect(() => {
    if (userAccounts && userAccounts.customerAccountID) {
      //Get Printer Configuration of the user.
      getPrintConfiguration(dispatch, actions, userAccounts);

      //Get Genral configuration details of the org.
      getAllGenralConfiguration(userAccounts.customerAccountID, dispatch, actions);
    }
  }, [userAccounts]);

  useEffect(() => {
    if (_printerConfiguration !== null && _printerConfiguration?.userId) {
      updateJSPMReference(_printerConfiguration?.jspmVersion?.toString());
      getJSPMStatus(dispatch, actions);
    }
  }, [_printerConfiguration])
  useEffect(() => {
    if (printerConfiguration?.userId) {
      if (_printerConfiguration === null) {
        setPrinterConfiguration(printerConfiguration);
      }
    }
  }, [printerConfiguration])

  return (
    <Paper>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Layout />
        </Router>
      </ThemeProvider>
    </Paper>
  );
};

export default App;
