/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import FormRenderer from '../../../framework/Inputs/formRenderer/formRenderer';

import ServicePoint from '../Products&Services/ServicePoint';
import './InternationalDeliveryOption.scss';
const InternationalDeliveryOption = (props) => {
  const {
    formField: {
      doorStepInstructions,
      exchange,
      specialInstructions,
      additionalInstruction,
      deliveryType,
      leaveSafeLocation,
    },
    formikValues,
    isAddressBook,
  } = props;

  return (
    <div className="wd-100">
      <Grid container spacing={3} direction="row" alignItems="flex-start">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormRenderer {...deliveryType.props} fullWidth data={deliveryTypeOptions} />
        </Grid>
        {formikValues.deliveryType === 'deliverToServicePoint' && !isAddressBook
? (
          <Grid className="internatial-delivery-search" item xs={12} sm={12} md={6} lg={6}>
            <ServicePoint {...props} />
          </Grid>
        )
: null}
      </Grid>
    </div>
  );
};

InternationalDeliveryOption.propTypes = {
  formField: PropTypes.object,
  formikValues: PropTypes.object,
  setFieldValue: PropTypes.func,
};

const deliveryTypeOptions = [
  {
    value: 'deliverToDoorStep',
    label: 'Deliver to doorstep',
  },
  {
    value: 'deliverToServicePoint',
    label: 'Deliver to ServicePoint',
  },
];

export default InternationalDeliveryOption;
