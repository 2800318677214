import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import SliderForAlert from './sliderForAlert';
import './transitionAlerts.scss';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  alertContainer: {
    backgroundColor: '#fff',
  },
}));

function TransitionAlerts(props) {
  const { open, setOpen, alertInfo } = props;
  const classes = useStyles();

  return (
    <div className="transitionAlert">
      <div className={classes.root}>
        <Collapse in={open}>
          <Alert
            className={classes.alertContainer}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                  props.alertClosed(false)
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <SliderForAlert alertInfo={alertInfo} />
          </Alert>
        </Collapse>
      </div>
    </div>
  );
}
export default TransitionAlerts;
