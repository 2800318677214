import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  customHeader: {
    borderBottom: '1px solid RGBA(112,112,112,0.18)',
    //   padding:"10px",
  },
  customTitle: {
    color: '#000000E6',
    paddingBottom: '10px',
    paddingTop: '5px',
  },
  customText: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
}));
const CustomHeader = ({ heading, children }) => {
  const classes = useStyles();

  return (
    <Grid direction="row" justify="flex-end" alignItems="flex-start" spacing={3} className={`${classes.customHeader} box-accrodion`}>
      <Grid item xs={12} className={classes.customTitle}>
        <b>{heading}</b>
      </Grid>
      <Typography component="div" className={classes.customText}>
        {children}
      </Typography>
    </Grid>
  );
};

export default CustomHeader;
