import * as actionTypes from '../actions/actionsTypes';

import { updateObject } from '../../utils/common';

const initialState = {
  applicationUpdates: [],
};

const setApplicationUpdates = (state, action) => {
 
  return updateObject(state, {
    applicationUpdates: action.data,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SETAPPLICATIONNUPDATES:
      return setApplicationUpdates(state, action);
    default:
      return state;
  }
};

export default reducer;
