import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { setDropdownValues } from '../../../utils/helperFunctions';
import { makeStyles, MenuItem, FormHelperText, FormControl} from '@material-ui/core';
import { useField } from 'formik';

// const useStylesTextField = makeStyles(() => ({
//   root: {
//     border: '1px solid #ACACAC',
//     overflow: 'hidden',
//     color: 'black',
//     opacity: 1,
//     borderRadius: 4,
//     backgroundColor: '#FFFFFF  ',
//     '&:hover': {
//       backgroundColor: '#ffffff',
//     },
//     '&$focused': {
//       backgroundColor: '#fff',
//     },
//   },
// }));

const InputAutoComplete = (props) => {
  const { data, label, displayField, name, fieldValue, valuePropertyName, style, isDisabled, displayId, flagForAfterSubmitCheck, setFlagForAfterSubmitCheck, ...rest } = props;

  const [autoCompleteValue, setAutoCompleteValue] = React.useState('');
  const [inputValue, setInputValue] = React.useState('');
  const [firstRecord,setFirstRecord] = React.useState(true);

  const [field, meta, helper] = useField(props);
  const { touched, error } = meta;
  const { setValue } = helper;
  const isError = touched && error && true;
  const { value } = field;

  const renderHelperText = () => {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  };

  //   const classes = useStylesTextField();

  const onAutoCompleteSelection = (event, value) => {
    setValue(value ? value : '');
  };

  useEffect(() => {
    const selectedValue = setDropdownValues(data, valuePropertyName, fieldValue?.[valuePropertyName]);
    inputValue && setAutoCompleteValue(selectedValue ? selectedValue : '');
   if(value && (firstRecord || flagForAfterSubmitCheck))
    { 
      setAutoCompleteValue(selectedValue ? selectedValue : '');
      setInputValue(value[displayField]);
      setFirstRecord(false)
      setFlagForAfterSubmitCheck?.(false);
    }
  }, [data, fieldValue, value, valuePropertyName, firstRecord, flagForAfterSubmitCheck]);
  
  return (
    <div>
      <FormControl fullWidth style={{'backgroundColor':`${autoCompleteValue?.status?.toLowerCase() === "onstop" ? '#f5f5f5' : ''}` }}>
      <Autocomplete
        {...field}
        disabled = { isDisabled }
        value={autoCompleteValue}
        onChange={(event, value) => onAutoCompleteSelection(event, value)}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="controllable-states-demo"
        options={data}
        // (data || []).map((option) => option[displayField])
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          return option[displayField];
        }}


        style={{ ...style }}
        renderInput={(params) => (
          <TextField {...params}  InputProps={{ ...params.InputProps }} label={label} variant="outlined" onBlur={()=>{!inputValue && setFirstRecord(true)}}/>
        )}
        renderOption={(option) => (
          // <span className='OnStopItem'>
          //   {option[displayField]}
          // </span>
          < MenuItem key={option[displayField]} className={(option.status && option.status.toLowerCase() === "onstop") ? 'OnStopItem' : 'test'} value={option} onClick={() => { option?.clickEvent?.() }} >
                {displayId && option[displayId] ? (
                  <span>
                    {' '}
                    {option[displayId]}-{option[displayField]}
                  </span>
                ) : (
                  <span>{option[displayField]}</span>
                )}
              </MenuItem>
            
        )}
      />
       </FormControl>
      {renderHelperText()}
    </div>
  );
};

InputAutoComplete.propTypes = {
  data: PropTypes.array.isRequired, // options for autocomplete dropdown
  label: PropTypes.string, // label for text field
  displayField: PropTypes.string, // nested object property name that need to be shown to user
  name: PropTypes.string.isRequired, // formcontrol name in formik
  fieldValue: PropTypes.object, // formikValue of field control for prefilling
  valuePropertyName: PropTypes.string, // nested object unique property name()
  style: PropTypes.object,
};

InputAutoComplete.defaultProps = {
  data: [], // options for autocomplete dropdown
  label: '', // label for text field
};

export default InputAutoComplete;
