import React from 'react';
import { useHistory } from 'react-router-dom';
const PromptJSPMClientInstallation = (props) => {
  let history = useHistory();
  const routeToPrinter = () => {
    history.push('/configuration/printerConfiguration')
  }
  return (
    <div>
      { !props.printerConfiguration
      ? (
        <>
      <h2>Download &amp; Install JSPM Client App (Version 4)</h2>
      <hr />
      It seems that <strong>JSPM is not installed or not running</strong> on this machine.
      <br />
      <br />
      <strong>JSPM Client App</strong> is a small utility (
      <strong>
        <strong>without any dependencies</strong>
      </strong>
      ) that handles all the <strong>Print Jobs</strong> and runs on{' '}
      <strong>Windows, Linux, Mac &amp; Raspberry Pi</strong> devices.
      <br />
      <br />
      <div className="text-center">
        <a
          href="https://www.neodynamic.com/downloads/jspm/?v=4"
          target="_blank"
          rel="noreferrer"
          className="btn btn-lg btn-primary center"
        >
          <i className="fa fa-download" /> Download JSPM Client App
        </a>
      </div>
      <br />
      Once installed, please set your label printer settings on Ship via Configuration - Printer configuration.
      <br />
      <br />
      <div className="alert alert-warning">
        <i className="fa fa-exclamation-circle" /><b>Important :We support only the version 4 of JSPM client, please download and install the same. The browser needs to be restarted after package installation! (all open instances)
Firefox based browser must be closed before installing JSPM utility..</b>
      </div>
      <br />
      If you have installed JSPM, but are seeing this message please ensure you have downloaded the correct version and have set your label printer settings on Ship via Configuration - Printer configuration.
      <br />
      </>)
      : <>
       <h2>Before you begin booking shipments, please set up your printer via Configurations, 
<b>
         <a
           target="_blank"
           rel="noreferrer"
           className="btn btn-lg btn-primary center"
           onClick={routeToPrinter}
           style={{cursor:'pointer', color:'blue', textDecoration:'underline'}}
         >
           <i className="fa fa-download" style={{cursor:'pointer', color:'blue'}}/> &nbsp;  Printer configurations
         </a>
         </b> &nbsp; 
        to setup.</h2>
       <br />
      
       </>
}
    </div>
  );
};

export default PromptJSPMClientInstallation;
