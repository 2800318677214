const orderFileMappingForm = {
    formId: 'orderFileMapping',
    formField: {
      mapCategory: {
        props: { 
          name: 'mapCategory',
          label: 'Category',
          type: 'select',
          displayField: 'name',
          isRequired: true,
          value: '0',
          hasDefaultValue: true,
        },
        validation: {
          name: 'mapCategory',
          validationType: 'object',
          validations: [
            {
              type: 'required',
              params: ['Please select a category to create a mapping'],
            },
          ],
        },
      },
        mapTo: {
            props: {
              name: 'mapTo',
              label: 'Map to',
              type: 'select',
              displayField: 'name',
              isRequired: true,
              value: '0',
            },
            validation: {
              name: 'mapTo',
              validationType: 'object',
              isRequired: true,
              validations: [
                {
                  type: 'required',
                  params: ['Map to  field is required'],
                },
              ],
            },
          },
          mapToForAccount: {
            props: {
              name: 'mapToForAccount',
              label: 'Map to',
              type: 'select',
              displayField: 'displayName',
              isRequired: true,
              value: '0',
            },
            validation: {
              name: 'mapToForAccount',
              validationType: 'object',
              isRequired: true,
              validations: [
                {
                  type: 'required',
                  params: ['Map to  field is required'],
                },
              ],
            },
          },
          mapFrom: {
            props: {
              name: 'mapFrom',
              label: 'Map from',
              type: 'text',
              isRequired: true,
              placeholder: 'Map from',
            },
            validation: {
              name: 'mapFrom',
              validationType: 'string',
              validations: [
                {
                  type: 'required',
                  params: ['Map from field is required'],
                },
              ],
            },
          },
          mappingDetails: {
            props: {
              name: 'mappingDetails',
              type: 'array',
              fields: {
                mapFrom: 'text',
                mapTo: 'text',
              },
            },
            values: [],
          },
          marketPlacesAvailable: {
            props: { 
              name: 'marketPlacesAvailable',
              label: 'Marketplaces',
              type: 'select',
              displayField: 'marketplaceName',
              isRequired: true,
              hasDefaultValue: true,
            },
          },
          newMarketplaceName: {
            props: {
              name: 'newMarketplaceName',
              label: 'Marketplace name',
              type: 'text',
              isRequired: true,
            },
          },
          newMarketplaceCode: {
            props: {
              name: 'newMarketplaceCode',
              label: 'Marketplace code',
              type: 'text',
              isRequired: true,
            },
          },
    },
  };
  
  export default orderFileMappingForm;
  