import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './deliveryPhotographs.scss';
import { useDispatch } from 'react-redux';
import * as actions from '../../../../store/actions/index';
//import ArrowLeft from "../../../../../src/assets/images/arrow-left.svg";
import {ReactComponent as ArrowLeft} from "../../../../assets/images/slider-arrow-left.svg";
import {ReactComponent as ArrowRight} from "../../../../assets/images/slider-arrow-right.svg";


const DisplayPhotographs = (props) => {
    const { shipmentDetails } = props
    const dispatch = useDispatch();
    const [selectedImage, setSelectedImage] = useState(shipmentDetails[0].imageBase64)
    const [selectedEvent, setSelectedEvent] = useState(shipmentDetails[0].eventId)
    const [selectedClass, setSelectedClass] = useState(false)
    const [Class_name, setClass_name] = useState('')
 // shipmentDetails = shipmentDetails.slice(0,2)
 //shipmentDetails = shipmentDetails.concat(shipmentDetails)
    let settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: shipmentDetails.length >= 3 ? 3 : shipmentDetails.length,
    // slidesToScroll: 2,
    arrows: true,
    // centerPadding:'100px'
    adaptiveHeight: true,
    prevArrow: <ArrowLeft />,
     nextArrow: <ArrowRight />
  };
    const handleSubmit = (selectImg, selectEvent) => {
        setSelectedImage(selectImg)
        setSelectedEvent(selectEvent)
        // setSelectedClass(false)
        setSelectedClass(true)
        dispatch(actions.setSelectedEvent(selectEvent))
       
    }
    const handleSelect = () => {
        if (!selectedClass) {
          setSelectedClass(true)
          dispatch(actions.setSelectedEvent(selectedEvent))

        }
    }
    return (
         <>
        <div style={{}} className="displayPhoto">
        <img className={selectedClass ? 'selected-image-active' : null} style={{}} 
            src={selectedImage} alt="delivery photography"  onClick={() => {
 handleSelect() 
} }   />
        </div>
     
        <div style={{height:"100px", width:"100%"}}>
        <Slider className="delivery-slider" {...settings}>
             {shipmentDetails.map((tile) => {
            return     <>
          <div className={Class_name} >
            <img style={{}} 
                  onClick={() => {
 handleSubmit(tile.imageBase64, tile.eventId) 
} }  
                  src={tile.imageBase64} alt={tile.imageAltText}/>
          </div>
          </>
        })}
        </Slider> 
        </div>
        </>
    );
};

DisplayPhotographs.propTypes = {shipmentDetails:PropTypes.array};

export default DisplayPhotographs;