import React from 'react'
import ReadOnlyCheckbox from '../../../../framework/readOnlyCheckbox/ReadOnlyCheckbox'
import CustomCaption from '../../../../framework/summaryFormate/CustomCaption'
import { Grid, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
    flexContainer: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 0,
    },
});


const AddShipmentAdditionalInfo = ({ shipmentDetails }) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3}>
                <Grid item className={classes.flexContainer} >
                    <CustomCaption title="Customer reference" value={shipmentDetails.customerReference} />
                </Grid>
                <Grid item className={classes.flexContainer}>
                    <CustomCaption title="Alternative reference" value={shipmentDetails.alternateReference} />
                </Grid>
                <Grid item className={classes.flexContainer} >
                    <CustomCaption title="Extended liability" value={shipmentDetails.extendedCoverDomestic} />
                </Grid>
                <Grid item className={classes.flexContainer} >
                    {/* <Typography> Delivery notification
            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3}>
            <Grid item className={classes.flexContainer} >
        <ReadOnlyCheckbox label="Email" checked={shipmentDetails.emailRecipient === 'checked' || shipmentDetails.emailRecipient === true}/>
        </Grid>
        <Grid item className={classes.flexContainer} >
        <ReadOnlyCheckbox label="Mobile" checked={shipmentDetails.mobileRecipient === 'checked' || shipmentDetails.mobileRecipient === true}/>
        </Grid>
            </Grid>
        </Typography> */}

                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default AddShipmentAdditionalInfo
