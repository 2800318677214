import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DynamicMaterialTable from '../../framework/DynamicMaterialTable/DynamicMaterialTable';
import InputButton from '../../framework/Inputs/inputButton/inputButton';
import ConfirmationDialog from '../../components/addressBook/confirmation/confirmation';
import CustomModal from '../../framework/dialog/customDialog';
import { CSVLink } from 'react-csv';
import { GET } from '../../api/axios';
import { ServiceEndPoints } from '../../utils/constants/ApiConstant';
import { getCustomerAccountID } from '../../utils/helperFunctions'
import { useDispatch } from 'react-redux';
import * as actions from '../../store/actions/index';
const useStyles = makeStyles((theme) => ({
  exportButton: {
    // padding: '12px 43px !important',
    // borderRadius: '5px',
    // border: '1px solid #d40511 ',
    // marginBottom: '8px',
  },
  exportLink: {
    // textDecoration: 'none',
    // color: '#d40511 !important',
    // opacity: '1 !important',
    // fontWeight: 'bold',
  },
}));

function MyAddressBook(props) {
  const {
    rows,
    columns,
    isSearchable,
    isSelectable,
    sorting,
    paging,
    pageSize,
    pageSizeOptions,
    isFavourites,
    title,
    confirmationMessage,
    userDetails,
    onRowClicked,
    isSearched,
    page
  } = props;

  const csvLink = useRef();
  const classes = useStyles();

  const [selectedRows, setSelectedRows] = useState([]);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState(confirmationMessage);

  const [ExporttableRawData, setExportTableRawData] = useState([]);
  const dispatch = useDispatch();

  const addSelectedRows = (selectedRows) => {
    setSelectedRows(selectedRows);
  };

  const handleDialogClose = () => {
    setShowConfirmationDialog(false);
  };

  const Export_Data = () => {
    csvLink.current.link.click();
    dispatch(actions.setAppSnackBarData({ msg: "Addressbook data exported susccessfully" }));
  };

  const onDialogConfirm = () => {
    if (isFavourites) {
      props.removeAddressFromFavourite(selectedRows);
    } else {
      props.deleteSelectedAddress(selectedRows);
    }
    setShowConfirmationDialog(false);
    setSelectedRows([]);
  };

  const onDialogCancel = () => {
    setShowConfirmationDialog(false);
  };

  const deleteAddressBookEntry = () => {
    let isFavPresent = false;
    selectedRows.forEach((address) => {
      if (((address || {}).favouriteUsers || []).some((id) => id === (userDetails || {}).userId)) {
        isFavPresent = true;
      }
    });
    isFavPresent
      ? setConfirmationMsg(
        'Are you sure you want to delete the selected address book entries? Note: The entries will also be removed from favourites',
      )
      : setConfirmationMsg('Are you sure you want to delete the selected address book entries?');
    setShowConfirmationDialog(true);
  };

  const onDataUpdate = async () => {
    let url = `${ServiceEndPoints.getAddressBook}?organization=${getCustomerAccountID()}`
    if (props.addressBookSearchParams) {
      url += props.addressBookSearchParams
    }
    await GET(url)
      .then((res) => {
        let ExportData = [];
        ((res.data || {}).addressBooks || []).forEach((element, index) => {
          const customer = element?.deliveryAddress;
          const customerCustomDeclaration = element?.customDeclaration;
          const customerDeliveryOptions = element?.deliveryOptions;
          const customerParcelDimensions = element?.parcelDetails?.parcelDimensions
          ExportData.push({
            id: element.id,
            customerNumber: customer?.customerNumber,
            IsBusiness: (customer?.recipientType === 1) ? 'YES' : 'NO',
            receiverName: customer?.recipientName,
            businessName: customer?.businessName,
            line1: customer?.line1,
            line2: customer?.line2,
            line3: customer?.line3,
            town: customer?.town,
            county: customer?.county,
            postCode: customer?.postCode,
            country: element?.destinationCountry,
            telephone: customer?.telephone,
            email: customer?.email,
            items: element?.parcelDetails?.numberOfItems,
            weight: element?.parcelDetails?.weight,
            customerReference: element?.customerReference,
            alternativeReference: element?.alternativeRefernce,
            extendedLiability: element?.extendedLiability?.extendedLiability,
            leaveSafeLocation: customerDeliveryOptions?.leaveSafeLocation,
            specialInstructions1: customerDeliveryOptions?.specialInstructions,
            specialInstructions2: customerDeliveryOptions?.additionalInstruction,
            length: customerParcelDimensions?.[0]?.length,
            width: customerParcelDimensions?.[0]?.width,
            height: customerParcelDimensions?.[0]?.height,
            value: customerCustomDeclaration?.totalValue,
            currency: customerCustomDeclaration?.currency,
            what3Words:customer?.what3Words?.words,
          });
        });
        setExportTableRawData(ExportData);
      })
      .catch((err) => {
        console.log(err);
        setExportTableRawData([]);
      });
  };

  const headers = [
    { label: 'Customer number', key: 'customerNumber' },
    { label: 'Is Business', key: 'IsBusiness' },
    { label: 'Recipient name', key: 'receiverName' },
    { label: 'Business name', key: 'businessName' },
    { label: 'Address1', key: 'line1' },
    { label: 'Address2', key: 'line2' },
    { label: 'Address3', key: 'line3' },
    { label: 'Town', key: 'town' },
    { label: 'County', key: 'county' },
    { label: 'Postal code', key: 'postCode' },
    { label: 'Country', key: 'country' },
    { label: 'Telephone number', key: 'telephone' },
    { label: 'Email address', key: 'email' },
    { label: 'Items', key: 'items' },
    { label: 'Weight (Kg)', key: 'weight' },
    { label: 'Customer Reference', key: 'customerReference' },
    { label: 'Alternative  Reference', key: 'alternativeReference' },
    { label: 'Extended Liability', key: 'extendedLiability' },
    { label: ' Leave safe location', key: ' leaveSafeLocation' },
    { label: 'Special instructions 1', key: 'specialInstructions1' },
    { label: 'Special instructions 2', key: 'specialInstructions2' },
    { label: 'Length (cm)', key: 'length' },
    { label: 'Width (cm)', key: 'width' },
    { label: 'Height (cm)', key: 'height' },
    { label: ' Value', key: 'value' },
    { label: 'Currency', key: 'currency' },
    { label: 'What3Words', key: 'what3Words' },
  ];

  useEffect(() => {
    onDataUpdate();
  }, []);

  useEffect(() => {
    if (isSearched || !(ExporttableRawData.length>0)){
      onDataUpdate();
    }
  }, [props.addressBookSearchParams, isSearched, pageSize, page]);

  return (
    <Grid container justify="flex-end" alignItems="flex-start">
      <Grid item lg={12} md={12} xs={12} className="Table-column-color">
        <DynamicMaterialTable
          rows={rows}
          columns={columns}
          isSelectable={isSelectable}
          isSearchable={isSearchable}
          sorting={sorting}
          paging={paging}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
          onSelectionChange={addSelectedRows}
          onRowClicked={onRowClicked}
          {...props}
        />
      </Grid>

      <Grid container item className="customModal-book">
        <CustomModal open={showConfirmationDialog} title={title} onClose={handleDialogClose} maxWidth="sm">
          <ConfirmationDialog
            confirmationMessage={confirmationMsg}
            onConfirm={onDialogConfirm}
            OnCancel={onDialogCancel}
          />
        </CustomModal>
      </Grid>
      {isFavourites
        ? [
          <Grid
            item
            container
            lg={4}
            md={4}
            xs={4}
            justify="flex-end"
            alignItems="flex-end"
            style={{ paddingTop: '10px', display: 'grid' }}
          >
            <Grid item lg={6} md={6} xs={6}>
              <InputButton
                isDisabled={selectedRows.length === 0}
                className="Delete-btn Favourites-Remove"
                label="Remove"
                onClick={() => setShowConfirmationDialog(true)}
              />
            </Grid>
          </Grid>,
        ]
        : [
          <Grid container spacing={2} justify="flex-end" alignItems="flex-end" style={{ paddingTop: '10px' }}>
            <Grid item className="AddressBook-Delete">
              <InputButton
                className="outlined-btn Delete-AddressBook"
                isDisabled={selectedRows.length === 0}
                label="Delete"
                variant="outlined"
                onClick={() => deleteAddressBookEntry()}
              />
            </Grid>
            {/* <Grid item className={classes.exportButton + ' Export-Btn'}>
              {/* <InputButton
                  className="outlined-btn Delete-AddressBook"
                  // isDisabled={selectedRows.length === 0}
                  label="Export"
                  variant="outlined"
                  onClick={onDataUpdate}
                /> */}

            {/* <CSVLink
                className={classes.exportLink}
                disabled={ExporttableRawData.length < 1}
                data={ExporttableRawData}
                headers={headers}
                filename={'ShipAddressExport.csv'}
                ref={csvLink}
                onClick={() => { dispatch(actions.setAppSnackBarData({ msg: "Addressbook data exported susccessfully" })) }}
              >
                Export
                </CSVLink>
            // </Grid> */}
            <Grid item xs={3} className="btn-container" style={{ maxWidth: '100px' }}>
              <InputButton
                variant="outlined"
                className={`outlined-btn`}
                label="Export"
                onClick={Export_Data}
                isDisabled={ExporttableRawData.length < 1}
              />
              <CSVLink data={ExporttableRawData} headers={headers} filename={'ShipAddressExport.csv'} ref={csvLink} />
            </Grid>
            <Grid item>
              <InputButton
                isDisabled={selectedRows?.length < 1}
                className="Add-fav-btn outlined-btn"
                variant="outlined"
                label="Add to favourites"
                onClick={() => props.addFavouriteAddress(selectedRows)}
              />
            </Grid>
          </Grid>,
        ]}
    </Grid>
  );
}

MyAddressBook.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  isSearchable: PropTypes.bool,
  isSelectable: PropTypes.bool,
  sorting: PropTypes.bool,
  paging: PropTypes.bool,
  pageSize: PropTypes.object,
  pageSizeOptions: PropTypes.object,
  isFavourites: PropTypes.bool,
  title: PropTypes.string,
  confirmationMessage: PropTypes.string,
  removeAddressFromFavourite: PropTypes.func,
  deleteSelectedAddress: PropTypes.func,
  addFavouriteAddress: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    addressBookSearchParams: state.searchParams,
  };
};

export default connect(mapStateToProps, null)(withRouter(MyAddressBook));