import React, { useState, useEffect } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import CustomCaption from '../../../../framework/summaryFormate/CustomCaption';
import ReadOnlyCheckbox from '../../../../framework/readOnlyCheckbox/ReadOnlyCheckbox';

const useStyles = makeStyles({
  flexContainer: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
  },
  parcelHandover: {
    paddingBottom: '20px',
  },
});

const MoreInfoSummary = ({ shipmentDetails }) => {
  const classes = useStyles();
  const [state, setstate] = useState(false);

  useEffect(() => {
    setstate((shipmentDetails || {}).inboxReturnLabel);
  }, [shipmentDetails]);

  return (
    <>
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3}>
        <Grid item className={classes.flexContainer}>
          <CustomCaption title="Customer reference" value={shipmentDetails?.customerReference} />
        </Grid>
        <Grid item className={classes.flexContainer}>
          <CustomCaption title="Alternative reference" value={shipmentDetails?.alternateReference} />
        </Grid>
        {shipmentDetails?.accountNumber?.returnConfiguration?.inboxReturnAllowed &&
          shipmentDetails?.product?.deliveryCapabilities?.inBoxReturn ? (
          <>
            <Grid item className={classes.flexContainer}>
              {state === "false" ?
                (
                  <ReadOnlyCheckbox label="Include return Label" checked={false} />
                ) :
                (
                  <ReadOnlyCheckbox label="Include return Label" checked={true} />
                )
              }
            </Grid>
            <Grid item className={classes.flexContainer}>
              <CustomCaption title="Return reference" value={shipmentDetails?.returnReference} />
            </Grid>
          </>
        ) : null}

        <Grid item className={classes.flexContainer}>
          <ReadOnlyCheckbox label="Extended liability" checked={shipmentDetails.extendedCoverInternational} />
        </Grid>
      </Grid>
    </>
  );
};

export default MoreInfoSummary;
