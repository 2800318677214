import { Grid, IconButton, makeStyles, SvgIcon, Tooltip, Typography } from '@material-ui/core';
import { Clear, Share } from '@material-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import FormRenderer from '../../../framework/Inputs/formRenderer/formRenderer';
import InputButton from '../../../framework/Inputs/inputButton/inputButton';
import { ReactComponent as InfoIcon } from '../../../assets/images/InfoIcon.svg';
import { ReactComponent as SomeIcon } from '../../../assets/images/upload.svg';

import { GET, POST } from '../../../api/axios';
import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';
import CustomDialog from '../../../framework/dialog/customDialog';
import ReturnInstructionsPreview from '../returnInstructionsPreview/returnInstructionsPreview';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import * as actions from '../../../store/actions/index';

const useStyles = makeStyles((theme) => ({
  imagePreview: {
    height: '206px',
    borderRadius: '4px',
    border: '1px solid #ACACAC',
  },
  iconButton: {
    position: 'absolute',
    top: '-7px',
    right: '-4px',
  },
  customTooltip: {
    backgroundColor: '#fff',
    color: '#000000E6',
    fontSize: '14px',
    boxShadow: '0px 3px 6px #00000029',
    padding: '8px 15px',
    borderRadius: '0'
  },
  customArrow: {
    color: '#fff',
  },
}));

const ConfigureReturnLabel = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    formField: {
      configureLinkedReturnAccountNumber,
      printReturnInstructions,
      defaultInstructions,
      returnInstructions,
      logoFile,
      selectAccount,
    },
    formikValues,
    setFieldValue,
    accounts,
    setValues,
    setFieldTouched,
    isValid,
  } = props;

  const hiddenFileInput = useRef(null);
  const [filePath, setFilePath] = useState('');
  const [showClearIcon, setShowClearIcon] = useState(false);
  const [showPreviewDialog, setShowPreviewDialog] = useState(false);

  const onSelectFile = (event) => {
    if (event?.target?.files?.[0]) {
      setFieldValue('logoFile', event.target.files[0].name);
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (loadedEvent) => {
        setFilePath(loadedEvent.target.result?.split?.(',')?.[1]);
        setShowClearIcon(true);
        const image = new Image();
        image.src = loadedEvent.target.result;
        image.onload = () => {
          const imgObj = {
            size: event?.target?.files?.[0]?.size,
            height: image.height,
            width: image.width,
            fileName: event.target.files[0].name,
          };
          setFieldValue('imageField', imgObj);
          setFieldTouched('logoFile', true);
        };
      };
    }
  };

  const openFileDialog = () => {
    hiddenFileInput.current.click();
  };

  const clearFilePath = () => {
    setShowClearIcon(false);
    setFilePath('');
    setFieldValue('logoFile', '');
    setFieldValue('imageField', {});
  };

  useEffect(() => {
    setFilePath('');
    const formValues = {
      printReturnInstructions: false,
      returnInstructions: '',
      logoFile: '',
      imageField: {},
      configureLinkedReturnAccountNumber: '',
    };
    setValues({ ...formikValues, ...formValues });

    if (formikValues.selectAccount.accountId && formikValues.selectAccount.returnConfiguration && formikValues.selectAccount.returnConfiguration.customerReturnAccount) {
      getReturnConfigDetails(formikValues.selectAccount?.returnConfiguration?.customerReturnAccount);
    }
    if (!formikValues.selectAccount && accounts?.length > 0) {
      setFieldValue('selectAccount', accounts[0]);
    }
  }, [formikValues.selectAccount]);

  const getReturnConfigDetails = (currentReturnAccount) => {
    if (currentReturnAccount !== '') {
      GET(`${ServiceEndPoints.getReturnInstructionLabel}/${currentReturnAccount}`)
        .then((res) => {
          setFilePath(res.data?.customerLogoBase64);
          if (res.data?.customerLogoBase64 !== '') {
            setShowClearIcon(true);
          }
          else {
            setShowClearIcon(false);
            setFieldValue('logoFile', '');
            setFieldValue('imageField', {});
          }
          const formValues = {
            printReturnInstructions: res.data.printLabel,
            returnInstructions: atob(res.data.customerReturnInstructions),
            configureLinkedReturnAccountNumber: formikValues.selectAccount?.returnConfiguration?.customerReturnAccount
          };
          setValues({ ...formikValues, ...formValues });
        })
        .catch((err) => {
          setShowClearIcon(false);
          setFilePath('');
          const formValues = {
            printReturnInstructions: false,
            returnInstructions: atob(''),
            configureLinkedReturnAccountNumber: formikValues.selectAccount?.returnConfiguration?.customerReturnAccount,
            logoFile: '',
            imageField: {},
          };
          setValues({ ...formikValues, ...formValues });
          console.log(err);
        });
    }
  }

  const handleSubmit = () => {
    const reqObj = {
      customerAccount: formikValues.configureLinkedReturnAccountNumber,
      printLabel: formikValues.printReturnInstructions,
      customerReturnInstructions: btoa(formikValues.returnInstructions),
      customerLogo: filePath,
      customerLogoType: 'png',
      customerLogoBase64: filePath,
    };
    POST(`${ServiceEndPoints.getReturnInstructionLabel}`, reqObj)
      .then((res) => {
        dispatch(actions.setAppSnackBarData({ msg: `Account data updated successfully` }));
        setFieldValue('logoFile', '')
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          actions.setBottomAlert({
            msgs: [
              {
                type: 'error',
                msg: 'Error in updating account data, please try again later',
              },
            ],
          }),
        );
      });
  };

  const handlePreview = () => {
    setShowPreviewDialog(true);
  };
  const handleCancel = () => {
    history.push('/shipment/addShipment');
  };

  const closePreviewDialog = () => {
    setShowPreviewDialog(false);
  };

  const fileClicked = (event) => {
    event.target.value = '';
  };
  const handleChangeOfNewRows = (e) => {
    var text = document.getElementById('text').value;
    let lines = 1;
    let maxLines = 11;
    let maxTextForLine = 50;
    let textValue = text.replace(/(\r\n|\n|\r)/gm, "").length
    if (textValue > 0) {
      if (textValue % maxTextForLine === 0) {
        lines++;
        document.getElementById('text').value = text + '\n'
      }
    }
    var el = document.getElementById('text');
    let lineCount = 0;
    var liness = text.split("\n");
    for (var i = 0; i < liness.length; i++) {
      if (liness[i].length > 0)
        lineCount++;
    }

    if (lineCount === maxLines) {
      if (textValue === 550) {
        e.preventDefault();

      }
    }

  }

  useEffect(() => {
    const { returnInstructions } = formikValues;
    let contentInRows = returnInstructions.split("\n");
    let updatedContent = returnInstructions.split("\n");
    contentInRows.forEach((row, index) => {
      if (row.length > 50) {
        updatedContent[index] = row.slice(0, 50)
        updatedContent.splice(index + 1, 0, row.slice(50, row.length));
      }
    });
    setFieldValue("returnInstructions", updatedContent.slice(0, 11).join("\n"))
  }, [formikValues.returnInstructions])


  return (
    <div className="wd-100">
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Grid container direction="row" alignItems="flex-start" spacing={2}>
            <Grid item xs={12} sm={5} md={5} lg={6}>
              <FormRenderer
                {...selectAccount.props}
                data={accounts}
                fieldValue={formikValues.selectAccount}
                valuePropertyName="accountId"
                fullWidth />
            </Grid>
            <Grid item xs={12} sm={5} md={5} lg={6} style={{ paddingLeft: '25px' }}>
              <FormRenderer {...configureLinkedReturnAccountNumber.props} fullWidth />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" alignItems="flex-start" style={{ paddingLeft: '5px' }} spacing={2}>

            <Grid item xs={12} sm={5} md={5} lg={6} style={{ paddingLeft: '30px;' }}>
              <FormRenderer {...printReturnInstructions.props} fullWidth />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" alignItems="flex-start" spacing={2}>
            <Grid item xs={12} sm={5} md={5} lg={6}>
              <FormRenderer
                {...defaultInstructions.props}
                endArguments={{
                  endAdornment: (
                    <IconButton className={classes.iconButton}>
                      <Tooltip classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} title="These instructions are included with every return label" arrow placement="left">
                        <SvgIcon fontSize="small" component={InfoIcon} />
                      </Tooltip>
                    </IconButton>
                  ),
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={6} style={{ paddingLeft: '25px' }}>
              <FormRenderer
                id='text'
                {...returnInstructions.props}
                isDisabled={!formikValues.printReturnInstructions}
                isMultilined={true} noOfRows={11}
                endArguments={{
                  endAdornment: (
                    <IconButton className={classes.iconButton}>
                      <Tooltip classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }}
                        title="Enter the specific customer instructions that you wish to be included with your returns label."
                        arrow
                        placement="left"
                      >
                        <SvgIcon fontSize="small" component={InfoIcon} />
                      </Tooltip>
                    </IconButton>
                  ),
                }}
                fullWidth
              />
            </Grid>

          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" alignItems="flex-start" spacing={2}>
            <Grid item xs={12} sm={3} md={3} lg={6}>
              <Grid container direction="column" spacing={1} className="logo-upload-container">
                <Grid item xs={10} className="logo-upload">
                  <FormRenderer
                    {...logoFile.props}
                    isDisabled={!formikValues.printReturnInstructions}
                    fullWidth
                    endArguments={{
                      endAdornment: (
                        <IconButton className={classes.iconButton}>
                          <Tooltip classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }}
                            title={
                              <React.Fragment>
                                <Typography className="comp-requirement" style={{ fontWeight: 'bold', marginBottom: '5px' }}> Company logo requirements: </Typography>
                                <div>File type: Only PNG allowed</div>
                                <div>Maximum size: 700 x 200 pixels</div>
                                <div>Max resolution: 203 dpi</div>
                                <div>Max file size for upload: 500 KB</div>
                              </React.Fragment>
                            }
                            arrow
                            placement="left"
                          >
                            <SvgIcon fontSize="small" component={InfoIcon} />
                          </Tooltip>
                        </IconButton>
                      ),
                    }}
                  />
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    accept="image/png"
                    onChange={(event) => onSelectFile(event)}
                    ref={hiddenFileInput}
                    name="imageField"
                    onClick={fileClicked}
                  />
                </Grid>
                <Grid className="upload-btn-container" item xs={2}>
                  {showClearIcon ? (
                    <InputButton
                      label=""
                      onClick={clearFilePath}
                      variant="outlined"
                      className="outlined-btn"
                      startIcon={<Clear />}
                      isDisabled={!formikValues.printReturnInstructions}
                    />
                  ) : (
                    <InputButton
                      label=""
                      onClick={openFileDialog}
                      variant="outlined"
                      className="outlined-btn"
                      style={{ minWidth: '30px !important', width: '40px !important' }}
                      startIcon={<SvgIcon fontSize="small" component={SomeIcon} style={{ marginLeft: '15px' }} />}
                      isDisabled={!formikValues.printReturnInstructions}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid className="uploaded-img-section" item xs={12} sm={3} md={3} lg={6} style={{ paddingLeft: '25px' }}>
              <div className={classes.imagePreview + ' preview-img'}>
                {filePath ? (
                  <img src={'data:image/png;base64,' + filePath} alt="" />
                ) : (
                  <>
                    <label style={{ fontSize: '12px', color: '#707070', marginLeft: '10px' }}>Logo preview</label>
                    <Grid container justify="center" alignItems="center" style={{ height: '75%' }}>
                      <Grid className="logo-preview-txt" item alignItems="center" style={{ fontSize: '16px', color: '#000000E6' }}>The logo preview will appear here</Grid>
                    </Grid>
                  </>
                )}
              </div>
            </Grid>

          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="flex-end" spacing={3} className="btn-container">
        <Grid item>
          <InputButton label="Cancel" onClick={handleCancel} variant="text" className="text-btn" />
        </Grid>
        <Grid item>
          <InputButton
            variant="outlined"
            className="outlined-btn"
            label="Preview "
            onClick={() => {
              handlePreview();
            }}
            isDisabled={!formikValues.printReturnInstructions}
          />
        </Grid>
        <Grid item>
          <InputButton
            variant="contained"
            className="contained-btn"
            onClick={() => {
              handleSubmit();
            }}
            buttonType="submit"
            label="Save"
            isDisabled={!isValid}
          />
        </Grid>
      </Grid>
      <CustomDialog
        open={showPreviewDialog}
        onClose={closePreviewDialog}
        maxWidth="sm"
        title="Return instructions label preview"
      >
        <ReturnInstructionsPreview filePath={filePath} formikValues={formikValues} closePopup={closePreviewDialog} />
      </CustomDialog>
    </div>
  );
};

export default ConfigureReturnLabel;
