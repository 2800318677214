/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import FormRenderer from '../../../framework/Inputs/formRenderer/formRenderer';
import PropTypes from 'prop-types';
import * as actions from '../../../store/actions/index';
import { Divider } from '@material-ui/core';
import { SearchIcon } from '@material-ui/data-grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import './addShipment.scss';
import { KeyboardArrowDown, StarBorderOutlined } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import ClearIcon from '@material-ui/icons/Clear';
import DialogBox from '../../../components/addShipment/templates/dialogForTemplate/dialogForTemplate';
import { useDebouncedCallback } from 'use-debounce';
import './favourite.scss';

import {
  setValuesToDeliveryAddress,
  setValuesToForm,
  sortBasedOnNestedOrNot
} from '../../../utils/helperFunctions';
import { UserRoles } from '../../../utils/constants/constants';

const Favourite = (props) => {
  const {
    formField: { favouriteSearchBy },
    setFieldValue,
    setValues,
    favouriteValues,
    formikValues,
    countries,
    // errors,
    setCustomerNumber,
    currencies,
    getFavCustomerList,
    favouriteLimit,
    setAddressSearchObj,
    isAmendShipment,
    setIsServicePointAddress,
    searchedAddressBookData
  } = props;

  // const [anchorEl, setAnchorEl] = React.useState(null);
  const [filteredFavourites, setFilteredFavourites] = React.useState([]);
  const userDetails = useSelector(state => state.auth.userDetails)
  let [isClearBtn, setIsClearBtn] = useState(false);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const backUpResponse = useSelector((state) => state.backUpResponse);

  const debounced = useDebouncedCallback((value) => {
    getFavCustomerList(value);
  }, 500);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFieldValue('favouriteSearchBy', '');
  };

  const handleMenuClick = (favoriteCustomerDetails, setValues) => {
    dispatch(actions.setLoaderLayOver(true));
    if (
      // backUpResponse?.loadedFrom?.loadedFrom === 'template' &&
      // backUpResponse?.loadedFrom?.loadedResponse &&
      formikValues?.selectedTemplate &&
      (favoriteCustomerDetails?.destinationCountry === 'GBR' || favoriteCustomerDetails?.destinationCountry === 'GB')
    ) {
      setValues({
        ...formikValues,
        ...setValuesToDeliveryAddress(favoriteCustomerDetails),
      });
      // setTimeout(() => {
      dispatch(
        actions.setLoadedFrom({
          loadedFrom: 'dropdownResponse',
          loadedResponse: { ...backUpResponse.loadedFrom.loadedResponse },
        }),
      );
      setFieldValue('weight', backUpResponse?.loadedFrom?.loadedResponse?.parcelDetails?.weight ?? '');
      // }, 0);
    } else {
      if(formikValues?.handOverMethod === 'servicePoint'){
        setIsServicePointAddress(true);
      }
      setValues(setValuesToForm(favoriteCustomerDetails, formikValues, countries, currencies));
      // setTimeout(() => {
      dispatch(
        actions.setLoadedFrom({ loadedFrom: 'dropdownResponse', loadedResponse: { ...favoriteCustomerDetails } }),
      );
      setFieldValue('weight', favoriteCustomerDetails?.parcelDetails?.weight ?? '');
      // }, 0);
    }
    dispatch(actions.setBackUpResponse({ loadedFrom: 'favourites', loadedResponse: { ...favoriteCustomerDetails } }));
    searchedAddressBookData(favoriteCustomerDetails);
    setTimeout(() => {
      dispatch(actions.setLoaderLayOver(false));
      dispatch(actions.setLoadedFrom({ loadedFrom: '' }));
    }, 5000);
    setCustomerNumber(true);
    handleClose();
    setAddressSearchObj(favoriteCustomerDetails);
  };

  const getClear = (event = null, queryParams = '') => {
    if (event) {
      event.preventDefault();
    }
    setFieldValue('favouriteSearchBy', '');
    getFavCustomerList();
    setIsClearBtn(false);
  };

  const searchFavourities = (event = null) => {
    if (event) {
      event.preventDefault();
    }
    if (formikValues.favouriteSearchBy.trim() !== '') {
      setIsClearBtn(true);
      debounced(formikValues.favouriteSearchBy);
    } else {
      setIsClearBtn(false);
      debounced(formikValues.favouriteSearchBy);
    }
  };

  useEffect(() => {
    sortBasedOnNestedOrNot(favouriteValues.addressBooks || [], 'deliveryAddress.customerNumber', true);
    setFilteredFavourites(favouriteValues.addressBooks || []);
  }, [favouriteValues.addressBooks]);

  useEffect(() => {
    return () => {
      dispatch(actions.setBackUpResponse({ loadedFrom: '', loadedResponse: {} }));
    };
  }, []);

  useEffect(() => {
    searchFavourities();
  }, [formikValues.favouriteSearchBy]);

  return (
    <Grid container spacing={3} direction="row" justify="space-between" alignItems="flex-start">
      <Grid item></Grid>
      <Grid item className="star Favourites-mani-grid" style={{ opacity: !isAmendShipment && userDetails.role !== UserRoles.BasicShipmentEntry ? 1 : 0.5 }}>
        <StarBorderOutlined className="star_mrg" />
        <p className="favourites">Favourites</p>
        <IconButton className="mrg_t" onClick={handleClick} disabled={isAmendShipment || userDetails.role === UserRoles.BasicShipmentEntry}>
          <KeyboardArrowDown />
        </IconButton>
        <DialogBox
          className="favourite-dialog"
          style={{ marginLeft: '100px' }}
          onClose={handleClose}
          open={open}
          data={filteredFavourites}
          isClearBtn={isClearBtn}
          onClickClear={(event) => getClear(event, 'clearField')}
        >
          <Grid style={{ width: '100%', padding: '20px' }} item>
            <FormRenderer
              style={{ boxShadow: '0px 3px 6px #00000019' }}
              {...favouriteSearchBy.props}
              endArguments={{
                endAdornment: isClearBtn ? (
                  <IconButton aria-label="search" onClick={(event) => getClear(event, '')}>
                    <ClearIcon />
                  </IconButton>
                ) : (
                  <IconButton aria-label="search" type="submit" onClick={(event) => searchFavourities(event)}>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
              fullWidth
            />
          </Grid>
          <List style={{ paddingTop: '4px' }}>
            <Grid container direction="row" style={{ marginLeft: '20px' }}>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <span className="ddl-sub-heading">Customer no.</span>
                </Grid>
                <Grid item xs={7}>
                  <span className="ddl-sub-heading">Business/contact name</span>
                </Grid>

                {/* <span style={{flex:'1 1 auto' }}></span> */}
              </Grid>
              <Divider style={{ marginTop: '10px' }} />
              {filteredFavourites.length > 0 ? (
                (filteredFavourites || []).map((customerDetails) => (
                  <Grid container direction="column">
                    <ListItem
                      button
                      style={{ pointer: 'cursor', paddingTop: '4px', paddingBottom: '4px' }}
                      onClick={() => {
                        handleMenuClick(customerDetails, setValues);
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid className="ddl-cust-no" item xs={5}>
                          <span style={{ marginLeft: '-14px' }}>
                            {((customerDetails || {}).deliveryAddress || {}).customerNumber}
                          </span>
                        </Grid>
                        <Grid className="ddl-cust-name" item xs={7}>
                          {((customerDetails || {}).deliveryAddress || {}).businessOrRecipientName}
                        </Grid>
                        {/* <span style={{ flex: '1 1 auto' }}></span> */}
                      </Grid>
                    </ListItem>
                    <Divider style={{ marginLeft: '-35px' }} />
                  </Grid>
                ))
              ) : (
                <Grid container direction="column">
                  <ListItem button style={{ pointer: 'cursor', paddingTop: '4px', paddingBottom: '4px' }}>
                    <span style={{ Width: '100%', textAlign: 'center', paddingLeft: '88px' }}>No favourite found</span>
                  </ListItem>
                  <Divider style={{ marginLeft: '-35px' }} />
                </Grid>
              )}
            </Grid>
          </List>
        </DialogBox>
      </Grid>
    </Grid>
  );
};

Favourite.propTypes = {
  formField: PropTypes.object,
  formikValues: PropTypes.object,
  setFieldValue: PropTypes.func,
  errors: PropTypes.object,
  favouriteValues: PropTypes.array,
  countries: PropTypes.array,
  getFavCustomerList: PropTypes.func,
  favouriteLimit: PropTypes.number,
};

export default Favourite;
