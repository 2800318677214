import { Divider, Grid, List, ListItem, ListItemText } from '@material-ui/core';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import InputButton from '../Inputs/inputButton/inputButton';

// import { GET } from '../../api/axios';

const FaqSideNav = (props) => {
  const { showCheckboxes, selectedMenu, catagoryValues, userRole, addFAQ, isUpdate } = props;

  const selectOptions = (data) => {
    props.setSelectedMenu(data);
    props.setIsUpdate(!isUpdate)
  };

  return (
    <div className="drawerClose">
      <Typography variant="div" display="block" className="saidNavHeading">
        {showCheckboxes && userRole
? (
          <Typography variant="p">List of categories</Typography>
        )
: (
          <Typography variant="p">Choose the key area where you need help</Typography>
        )}
      </Typography>

      <Divider />

      <List>
        {catagoryValues.map((option, key) => {
          return (
            <ListItem button onClick={() => selectOptions(option.name)} key={key}>
              <ListItemText primary={option.name} className={selectedMenu === option.name ? 'activeLink' : ''} />
            </ListItem>
          );
        })}
      </List>

      {userRole
? (
        <>
          {' '}
          <Divider />{' '}
          <Grid container justify="center" style={{ padding: '10px' }}>
            <Grid item>
              <InputButton className="outlined-btn add-category-btn " label="Add category" onClick={(e) => addFAQ(e, 'textBox')} />
            </Grid>
          </Grid>{' '}
        </>
      )
: (
        <></>
      )}
    </div>
  );
};

export default FaqSideNav;
