import { GET } from '../../../api/axios';
import { ServiceEndPoints } from '../../constants/ApiConstant';
import { testFunctionForPostCodeRegex, getCustomerAccountID } from '../../helperFunctions';

let doesCustNumExist = false;
const addNewAddressForm = {
  formId: 'addNewAddressForm',
  formField: {
    destinationCountry: {
      props: {
        name: 'destinationCountry',
        label: 'Destination country',
        type: 'select',
        displayField: 'name',
        isRequired: true,
        hasDefaultValue: true,
      },
      validation: {
        name: 'destinationCountry',
        validationType: 'object',
        validations: [
          {
            type: 'required',
            params: ['Destination country is required'],
          },
        ],
      },
    },

    addParcelDimensions: {
      props: {
        name: 'addParcelDimensions',
        label: 'Add parcel dimensions',
        type: 'checkbox',
      },
      value: false,
    },
    noOfItems: {
      props: {
        name: 'noOfItems',
        label: 'No. of items',
        type: 'number',
        inputProps: {
          min: 1,
          max: 20,
        },
        restrictDecimal: true
      },
      value: 1,
      validation: {
        name: 'noOfItems',
        validationType: 'number',
        validations: [
          {
            params: [1, 'Must be a value between 1 to 20'],
            type: 'min',
          },
          {
            type: 'max',
            params: [20, 'Must be a value between 1 to 20'],
          },
        ],
      },
    },
    weight: {
      props: {
        name: 'weight',
        label: 'Weight (Kg)',
        type: 'number',
        placeholder: 'Select',
        inputProps: {
          min: 0,
          max: 1000,
          step: 'any',
        },
      },
      validation: {
        name: 'weight',
        validationType: 'number',
        validations: [
          {
            type: 'when',
            params: [
              ['totalpieceWeight', 'destinationCountry', 'addParcelDimensions'],
              (totalpieceWeight, destinationCountry, addParcelDimensions, schema) => {
                if (destinationCountry && destinationCountry.code === 'GBR' && addParcelDimensions) {
                  return schema.test('', 'Weight must be greater than or equal to piece weight', (value) => {
                    return value >= totalpieceWeight;
                  });
                } else {
                  return schema;
                }
              },
            ],
          },
        ],
      },
    },
    parcelDetails: {
      props: {
        name: 'parcelDetails',
        type: 'array',
        fields: {
          length: 'number',
          width: 'number',
          height: 'number',
          pieceWeight: 'number',
          volumetricWeight: 'number',
          id: 'number',
        },
      },
      values: [],
    },
    totalVolumetricWeight: {
      props: {
        name: 'totalVolumetricWeight',
      },
      value: 0.0,
    },
    length: {
      props: {
        name: 'length',
        label: 'Length (cm)',
        type: 'number',
      },
      validation: {
        name: 'length',
        validationType: 'number',
        validations: [
          {
            type: 'typeError',
            params: ['Length must be number type'],
          },
          {
            type: 'when',
            params: [
              ['width', 'height'],
              (width, height, schema) => {
                if (width || height) {
                  let highest = width || 0;
                  if ((height || 0) > highest) highest = height;
                  return schema.test('', 'Length must be longest side', (value) => {
                    return value >= highest;
                  });
                } else return schema;
              },
            ],
          },
        ],
      },
    },
    width: {
      props: {
        name: 'width',
        label: 'Width (cm)',
        type: 'number',
      },
      validation: {
        name: 'width',
        validationType: 'number',
        validations: [
          {
            type: 'typeError',
            params: ['Width must be number type'],
          },
        ],
      },
    },
    height: {
      props: {
        name: 'height',
        label: 'Height (cm)',
        type: 'number',
      },
      validation: {
        name: 'height',
        validationType: 'number',
        validations: [
          {
            type: 'typeError',
            params: ['Height must be number type'],
          },
        ],
      },
    },
    addressType: {
      props: {
        name: 'addressType',
        label: 'Address type:',
        type: 'radio',
      },
      value: 'residential',
      validation: {
        name: 'addressType',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Choose any one of address'],
          },
          {
            type: 'when',
            params: [
              ['createPalletShipment', 'destinationCountry'],
              (createPalletShipment, destinationCountry, schema) => {
                if (createPalletShipment === 'yes' && destinationCountry.code === 'GBR') {
                  return schema.test('', 'Residential address not allowed for pallets.', (value) => {
                    return value !== 'residential';
                  });
                }
              },
            ],
          },
        ],
      },
    },
    // TODO: check the below and remove it
    searchFor: {
      props: {
        type: 'searchControl',
        name: 'searchFor',
      },
      selectProps: {
        props: {
          name: 'searchBy',
          label: 'Search By',
        },
        value: '',
      },
      textProps: {
        props: {
          name: 'searchFor',
          placeholder: "Enter Customer's Number",
        },
      },
      validation: {
        name: 'searchFor',
        validationType: 'string',
        validations: [
          {
            type: 'when',
            params: [
              ['destinationCountry', 'searchBy'],
              (destinationCountry, searchBy, schema) => {
                return (destinationCountry || {}).code && (searchBy || {}).value === 'postCode'
                  ? schema
                    .required('Postal code is required')
                    .matches((destinationCountry || {}).postcodeRegex, 'Invalid postal code')
                  : schema.required('Address is required');
              },
            ],
          },
        ],
      },
    },
    backupCustNum: {
      props: {
        name: 'backupCustNum',
        label: 'backupCustNum',
        type: 'text',
      },
    },

    customerNumber: {
      props: {
        name: 'customerNumber',
        label: 'Customer number',
        type: 'text',
        isRequired: true,
        placeholder: 'Enter',
        inputProps: {
          maxLength: 30,
        },
      },
      validation: {
        name: 'customerNumber',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Customer number is required'],
          },
          // {
          //   type: 'strict',
          //   params: [''],
          // },
          // {
          //   type: 'trim',
          //   params: [''],
          // },
          {
            type: 'when',
            params: [
              'backupCustNum',
              (backupCustNum, schema) => {
                return schema.test(
                  'checkDuplCustomerNumber',
                  'Customer number already in use, please choose another.',
                  (customerNumberValue) => {
                    if (window.location.href.indexOf('editAddress') > 1) {
                      return true;
                    } else if (
                      customerNumberValue?.trim?.() &&
                      backupCustNum?.trim?.() !== customerNumberValue?.trim?.()
                    ) {
                      return new Promise((resolve, reject) => {
                        GET(
                          `${ServiceEndPoints.getAddressBook
                          }?organization=${getCustomerAccountID()}&offSet=0&limit=1&isExactMatch=true&customernumber=${customerNumberValue}`,
                        )
                          .then((res) => {
                            if ((res.data?.addressBooks || []).length > 0) {
                              doesCustNumExist = false;
                              resolve(false);
                            } else {
                              doesCustNumExist = true;
                              resolve(true);
                            }
                          })
                          .catch(() => {
                            doesCustNumExist = true;
                            resolve(true);
                          });
                      });
                    }
                    return doesCustNumExist;
                  },
                );
              },
            ],
          },
          {
            type: 'test',
            params: [
              'checkIfBlankSpaces',
              'Customer number should not contain blank spaces',
              (customerNumberValue) => {
                if (customerNumberValue?.length > 0 && !customerNumberValue?.trim?.()) {
                  return false;
                }
                return true;
              },
            ],
          },
          // {
          //   type: 'matches',
          //   params: [/^(\s+$)/g, 'Whitespaces not allowed'],
          // },
        ],
      },
    },
    postalCode: {
      props: {
        name: 'postalCode',
        label: 'Postal code',
        type: 'text',
        isRequired: true,
        inputProps: {
          maxLength: 15,
          minLength: 3,
        },
      },
      validation: {
        name: 'searchFor',
        validationType: 'string',
        validations: [
          {
            type: 'when',
            params: [
              'destinationCountry',
              (destinationCountry, schema) => {
                if (!destinationCountry?.postcodeOptional) {
                  return schema.required('Postal code is required')
                }
              }
            ],
          },
          {
            type: 'when',
            params: [
              'destinationCountry',
              (destinationCountry, schema) => {
                if (destinationCountry?.postcodeRegex) {
                  return testFunctionForPostCodeRegex(
                    schema,
                    `Postal code should match ${destinationCountry?.postcodeMask} format`,
                    destinationCountry?.postcodeRegex,
                    false,
                  );
                }
              },
            ],
          },
          {
            type: 'max',
            params: [15, 'Maximum of 15 characters are allowed'],
          },
          {
            type: 'min',
            params: [3, 'Minimum of 3 characters are required'],
          },
        ],
      },
    },
    recipientName: {
      props: {
        name: 'recipientName',
        label: 'Recipient full name',
        isRequired: true,
        type: 'text',
        inputProps: {
          maxLength: 35,
        },
      },
      validation: {
        name: 'recipientName',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Recipient name is required'],
          },
          {
            type: 'when',
            params: [
              ['addressType', 'destinationCountry'],
              (addressType, destinationCountry, schema) => {
                return addressType === 'residential' || (destinationCountry || {}).code !== 'GBR'
                  ? schema.required('Recipient name is required').max(50, 'Recipient name is required')
                  : schema.notRequired();
              },
            ],
          },
          {
            type: 'max',
            params: [35, 'Maximum 35 characters are allowed'],
          },
        ],
      },
    },
    businessName: {
      props: {
        name: 'businessName',
        label: 'Business name',
        placeholder: 'Business 1',
        type: 'text',
        isRequired: true,
        inputProps: {
          maxLength: 35,
        },
      },
      validation: {
        name: 'businessName',
        validationType: 'string',
        validations: [
          {
            type: 'when',
            params: [
              'addressType',
              (addressType, schema) => {
                return addressType === 'business'
                  ? schema.required('Business name is required').max(35, 'Maximum of 35 characters allowed')
                  : schema;
              },
            ],
          },
          {
            type: 'max',
            params: [35, 'Maximum 35 characters are allowed'],
          },
        ],
      },
    },
    addressLine1: {
      props: {
        name: 'addressLine1',
        label: 'Address line 1',
        type: 'text',
        isRequired: true,
        inputProps: {
          maxLength: 35,
        },
      },
      validation: {
        name: 'addressLine1',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Address is required'],
          },
          {
            type: 'max',
            params: [35, 'Maximum of 100 characters allowed'],
          },
        ],
      },
    },
    addressLine2: {
      props: {
        name: 'addressLine2',
        label: 'Address line 2',
        type: 'text',
        inputProps: {
          maxLength: 35,
        },
      },
      validation: {
        name: 'addressLine2',
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [35, 'Maximum of 35 characters allowed'],
          },
        ],
      },
    },
    addressLine3: {
      props: {
        name: 'addressLine3',
        label: 'Address line 3',
        type: 'text',
        inputProps: {
          maxLength: 35,
        },
      },
      validation: {
        name: 'addressLine3',
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [35, 'Maximum of 35 characters allowed'],
          },
        ],
      },
    },
    town: {
      props: {
        name: 'town',
        label: 'Town',
        type: 'text',
        isRequired: true,
        inputProps: {
          maxLength: 35,
        },
      },
      validation: {
        name: 'town',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Town is required'],
          },
          {
            type: 'max',
            params: [35, 'Maximum of 35 characters allowed'],
          },
        ],
      },
    },
    county: {
      props: {
        name: 'county',
        label: 'County',
        type: 'text',
        inputProps: {
          maxLength: 35,
        },
      },
      validation: {
        name: 'county',
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [35, 'Maximum of 35 characters allowed'],
          },
        ],
      },
    },
    telephone: {
      props: {
        name: 'telephone',
        label: 'Telephone number',
        type: 'text',
        inputProps: {
          maxLength: 35,
        },
      },
      validation: {
        name: 'telephone',
        validationType: 'string',
        validations: [
          {
            type: 'when',
            params: [
              'destinationCountry',
              (destinationCountry, schema) => {
                return destinationCountry?.code !== 'GBR' ? schema.required('Phone number is required') : schema;
              },
            ],
          },
          // {
          //   type: 'min',
          //   params: [10, 'Invalid phone number'],
          // },
          // {
          //   type: 'max',
          //   params: [35, 'Invalid phone number'],
          // },
          // {
          //   type: 'matches',
          //   params: [/^[0-9,(,),+,\-,.,[,\] ].{9,35}/, 'Invalid phone number'],
          // },
          {
            type: 'when',
            params: [
              ['destinationCountry'],
              (destinationCountry, schema) => {
                return schema.test("", "Invalid phone number", (value) => {
                  if (value?.trim()?.length > 0) {
                    return destinationCountry?.code === "GBR" ? /^[0-9()+\-.[\], ]{0,35}$/.test(value) : /^[0-9()+\- ]{9,35}$/.test(value)
                  }
                  else return schema
                }
                )
              },
            ],
          }
        ],
      },
    },
    emailAddress: {
      props: {
        name: 'emailAddress',
        label: 'Email address',
        type: 'text',
        isRequired: true,
        inputProps: {
          maxLength: 70,
        },
      },
      validation: {
        name: 'emailAddress',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Email address is required'],
          },
          {
            type: 'email',
            params: ['Invalid email address'],
          },
          {
            type: 'max',
            params: [70, 'Maximum of 70 characters allowed'],
          },
        ],
      },
    },
    deliveryType: {
      props: {
        name: 'deliveryType',
        label: 'Delivery type:',
        type: 'radio',
      },
      value: 'deliverToDoorStep',
      validation: {
        validationType: 'string',
        validations: [
          // {
          //   type: 'required',
          //   params: ['Delivery Type is required'],
          // },
        ],
      },
    },
    doorStepInstructions: {
      props: {
        name: 'doorStepInstructions',
        label: 'Doorstep instructions',
        type: 'select',
        displayField: 'name',
        hasDefaultValue: true,
      },
      validation: {
        validationType: 'object',
        validations: [
          // {
          //   type: 'required',
          //   params: ['Doorstep Instructions is required'],
          // },
        ],
      },
    },
    deliveryServicePoint: {
      props: {
        name: 'deliveryServicePoint',
        label: 'Search for ServicePoint',
        type: 'text',
        placeholder: 'Search For ServicePoint',
      },
      validation: {
        name: 'postalCode',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['ServicePoint  is required'],
          },
          {
            type: 'when',
            params: [
              'destinationCountry',
              (destinationCountry, schema) => {
                if (destinationCountry?.postcodeRegex) {
                  return testFunctionForPostCodeRegex(
                    schema,
                    'Please  enter a correct postal code',
                    destinationCountry?.postcodeRegex,
                  );
                }
              },
            ],
          },
          // {
          //   type: 'when',
          //   params: [
          //     'destinationCountry',
          //     (destinationCountry, schema) => {
          //       return (destinationCountry || {}).code
          //         ? destinationCountry.code !== 'GBR'
          //           ? schema.matches((destinationCountry || {}).postcodeRegex, 'Please  enter a correct postcode')
          //           : schema.matches((destinationCountry || {}).postcodeRegex, 'Please  enter a correct postcode')
          //         : schema;
          //     },
          //   ],
          // },
        ],
      },
    },
    exchange: {
      props: {
        name: 'exchange',
        label: 'Exchange on delivery:',
        type: 'radio',
      },
      value: 'no',
    },
    specialInstructions: {
      props: {
        name: 'specialInstructions',
        label: 'Special instructions',
        type: 'text',
        inputProps: {
          maxLength: 30,
        },
      },
      validation: {
        name: 'emailAddress',
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [60, 'Maximum of 60 characters allowed'],
          },
        ],
      },
    },
    additionalInstruction: {
      props: {
        name: 'additionalInstruction',
        type: 'text',
        inputProps: {
          maxLength: 30,
        },
      },
    },
    leaveSafeLocation: {
      props: {
        name: 'leaveSafeLocation',
        label: 'Leave Safe location',
        type: 'text',
        inputProps: {
          maxLength: 60,
        },
      },
      validation: {
        name: 'emailAddress',
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [60, 'Maximum of 60 characters allowed'],
          },
        ],
      },
    },
    product: {
      props: {
        name: 'product',
        label: 'Product',
        type: 'select',
        displayField: 'productNameForUI',
        // isRequired: true,
      },
    },
    service: {
      props: {
        name: 'service',
        label: 'Service',
        type: 'select',
        displayField: 'name',
        // isRequired: true,
      },
      validation: {
        name: 'service',
        validationType: 'object',
        validations: [
          // {
          //   type: 'when',
          //   params: [
          //     'destinationCountry',
          //     (destinationCountry, schema) => {
          //       return (destinationCountry || {}).code === 'GBR'
          //         ? schema.required('Service name required')
          //         : schema.notRequired();
          //     },
          //   ],
          // },
        ],
      },
    },
    customerReference: {
      props: {
        name: 'customerReference',
        label: 'Customer reference',
        type: 'text',
        inputProps: {
          maxLength: 20,
        },
      },
      validation: {
        name: 'customerReference',
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [20, 'Max 20 characters are allowed'],
          },
        ],
      },
    },
    alternateReference: {
      props: {
        name: 'alternateReference',
        label: 'Alternative reference',
        type: 'text',
        inputProps: {
          maxLength: 20,
        },
      },
      validation: {
        name: 'alternateReference',
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [20, 'Max 20 characters are allowed'],
          },
        ],
      },
    },
    extendedCoverDomestic: {
      props: {
        name: 'extendedCoverDomestic',
        label: 'Extended liability',
        type: 'number',
        placeholder: 'Please select a level of extended liability, if required',
        inputProps: {
          min: 0,
          max: 5,
        },
      },
      validation: {
        name: 'extendedCoverDomestic',
        validationType: 'number',
        validations: [
          {
            type: 'min',
            params: [0, 'Extended liability value should be between 0 - 5.'],
          },
          {
            type: 'max',
            params: [5, 'Extended liability value should be between 0 - 5.'],
          },
        ],
      },
    },
    extendedCoverInternational: {
      props: {
        name: 'extendedCoverInternational',
        label: 'Extended liability',
        type: 'checkbox',
      },
      value: false,
    },
    mobileRecipient: {
      props: {
        name: 'mobileRecipient',
        label: 'Mobile',
        type: 'checkbox',
      },
      value: false,
    },
    emailRecipient: {
      props: {
        name: 'emailRecipient',
        label: 'Email',
        type: 'checkbox',
      },
      value: true,
    },
    invoiceTypeLabel: {
      props: {
        name: 'invoiceTypeLabel',
        label: 'Invoice Type',
        type: 'radio',
        // isRequired: 'true',
      },
      value: 'commercial',
    },
    addToFavCutomerList: {
      props: {
        name: 'addToFavCutomerList',
        label: 'Add to my favourites',
        type: 'checkbox',
      },
      value: false,
    },
    descriptionOfGoods: {
      props: {
        name: 'descriptionOfGoods',
        label: 'Description of goods',
        type: 'text',
        inputProps: {
          maxLength: 50,
        },
        // isRequired: true,
      },
      validation: {
        name: 'descriptionOfGoods',
        validationType: 'string',
        validations: [
          // {
          //   type: 'required',
          //   params: ['Description of goods is required'],
          // },
          {
            type: 'min',
            params: [1, 'Minimum 1 character is required'],
          },
          {
            type: 'matches',
            params: [/(.*[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-]){3}/i, 'Description must be at least 3 non blank characters'],
          },
          {
            type: 'max',
            params: [50, 'Maximum of 50 characters are allowed'],
          },
        ],
      },
    },
    customDeclarationCurrency: {
      props: {
        name: 'customDeclarationCurrency',
        label: 'Currency',
        type: 'select',
        displayField: 'name',
        // isRequired: true,
      },
      validation: {
        name: 'customDeclarationCurrency',
        validationType: 'object',
        validations: [
          // {
          //   type: 'required',
          //   params: ['Currency is required'],
          // },
        ],
      },
    },
    customDeclarationValue: {
      props: {
        name: 'customDeclarationValue',
        label: 'Value',
        type: 'number',
        // isRequired: true,
        inputProps: {
          min: 1,
          max: 10000,
        },
      },
      validation: {
        name: 'customDeclarationValue',
        validationType: 'number',
        validations: [
          // {
          //   type: 'required',
          //   params: ['Value is required'],
          // },
          {
            type: 'min',
            params: [1, 'Value must be greater than 0'],
          },
          {
            type: 'max',
            params: [10000, 'Value should not exceed 10000'],
          },
        ],
      },
    },
    customDeclarationShippingCharges: {
      props: {
        name: 'customDeclarationShippingCharges',
        label: 'Shipping charges(£)',
        type: 'text',
        // isRequired: true,
        inputProps: {
          maxLength: 10,
        },
      },
      validation: {
        name: 'customDeclarationShippingCharges',
        validationType: 'string',
        validations: [
          // {
          //   type: 'required',
          //   params: ['Shipping charges are required'],
          // },
          {
            type: 'max',
            params: [10, 'Maximum 10 digits are allowed for shipping charges'],
          },
          {
            type: 'matches',
            params: [/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/, 'Shipping charges must be a positive number'],
          },
        ],
      },
    },
    commodityDetails: {
      props: {
        name: 'commodityDetails',
        type: 'array',
        fields: {
          commodityCode: 'text',
          description: 'text',
          weight: 'number',
          quantity: 'number',
          unitValue: 'number',
          countryOfOrigin: 'text',
        },
      },
      values: [],
      validation: {
        name: 'commodityDetails',
        validationType: 'array',
        validations: [
          {
            type: 'max',
            params: [20, 'Maximum 20 items are allowed'],
          },
        ],
      },
    },
    subTotal: {
      props: {
        name: 'subTotal',
      },
      value: 0,
    },
    totalValue: {
      props: {
        name: 'totalValue',
      },
      value: 0,
    },
    pieceWeight: {
      props: {
        name: 'pieceWeight',
        label: 'Weight (kg)',
        type: 'number',
        placeholder: 'Select',
        isRequired: true,
        inputProps: {
          min: 0,
          max: 999,
          step: 'any',
        },
      },
      validation: {
        name: 'pieceWeight',
        validationType: 'number',
        validations: [
          {
            type: 'required',
            params: ['Weight is required'],
          },
        ],
      },
    },
    totalpieceWeight: {
      props: {
        name: 'totalpieceWeight',
      },
      value: 0.0,
    },
    isFullCustomDetails: {
      props: {
        name: 'isFullCustomDetails',
        label: '',
        type: 'checkbox',
      },
      value: false,
    },
    //Addition of pallets.
    createPalletShipment: {
      props: {
        name: 'createPalletShipment',
        label: 'Create pallet shipment',
        type: 'radio',
      },
      value: 'no',
    },
    pallets: {
      props: {
        name: 'pallets',
        label: 'Pallets',
        type: 'number',
        // isDisabled: true,
        inputProps: {
          min: 0,
          max: 1,
        },
      },
      value: 1,

      validation: {
        name: 'pallets',
        validationType: 'number',
        validations: [
          {
            type: 'min',
            params: [0, 'Pallets can  not be less than 0'],
          },
          {
            type: 'max',
            params: [1, 'Pallets can  not be greater than 1'],
          },
        ],
      },
    },
    //Express Changes for international shipment.
    intlParcelDetails: {
      props: {
        name: 'intlParcelDetails',
        type: 'array',
        fields: {
          length: 'number',
          width: 'number',
          height: 'number',
          pieceWeight: 'number',
          pieceRefId: 'string',
          volumetricWeight: 'number',
          id: 'number',
        },
      },
      values: [],
    },
    collectionInstructions: {
      props: {
        name: 'collectionInstructions',
        label: 'Collection instructions',
        type: 'text',
        inputProps: {
          maxLength: 60,
        },
      },
    },
    descriptionOfGoodsToBeCollected: {
      props: {
        name: 'descriptionOfGoodsToBeCollected',
        label: 'Description of goods to be collected',
        type: 'text',
        inputProps: {
          maxLength: 60,
        },
      },
    },
    totalWeightExceed: {
      props: {
        name: 'totalWeightExceed',
        type: 'number',
      },
      value: 0,
      validation: {
        name: 'totalWeightExceed',
        validationType: 'number',
        validations: [
          {
            type: 'when',
            params: [
              ['weight','destinationCountry'],
              (weight, destinationCountry, schema) => {
                if (destinationCountry?.code !== 'GBR') {
                  return schema
                  .test('', 'Total Weight of Pieces exceeds the Declared Weight', (value) => {
                    return (value ? value : 0) <= (weight ? weight : 0);
                    });
                } else {
                  return schema;
                }
              },
            ],
          },
        ],
      },
    },
    what3Words: {
      props: {
        name: 'what3Words',
        label: 'What3Words',
        type: 'text',
      },
    },
    w3WCoordinates: {
      props: {
        name: 'w3WCoordinates',
        type: 'object',
      },
    },
    duplicateParcelDimensions: {
      props: {
        name: 'duplicateParcelDimensions',
        label: 'Duplicate parcel dimensions',
        type: 'checkbox',
      },
      value: false,
    },
  },
};

export default addNewAddressForm;
