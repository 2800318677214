export const SAVEUSERDETAILS = 'SAVEUSERDETAILS';
export const SETUSERACCOUNTS = 'SETUSERACCOUNTS';
export const SETNONRETURNUSERACCOUNTS = 'SETNONRETURNUSERACCOUNTS';
export const SETSELECTEDACCOUNT = 'SETSELECTEDACCOUNT';
export const SETFAVOURITECUSTOMERS = 'SETFAVOURITECUSTOMERS';
export const SETADDRESSBOOKSEARCHPARAMS = 'SETADDRESSBOOKSEARCHPARAMS';
export const SETLOADPARCELDIMENSIONS = 'SETLOADPARCELDIMENSIONS';
export const SETLOADEDFROM = 'SETLOADEDFROM';
export const SETSHIPMENTMANAGEMENTSEARCHPARAMS = 'SETSHIPMENTMANAGEMENTSEARCHPARAMS';
export const SETACTIVETAB = 'SETACTIVETAB';
export const SETSHIPMENTID = 'setShipmentId';
export const SETBOOKEDSHIPMENTID = 'setBookedShipmentId';
export const SETADDSHIPMENTDATA = 'SETADDSHIPMENTDATA';
export const SETDASHBOARDTRACKING = 'SETDASHBOARDTRACKING';
export const SETDASHBOARDFINDSHIPMANET = 'SETDASHBOARDFINDSHIPMANET';
// export const SETLOADPARCELDIMENSIONS = 'SETLOADPARCELDIMENSIONS';
export const SETBACKUPRESPONSE = 'SETBACKUPRESPONSE';
export const SETSELECTEDEVENT = 'SETSELECTEDEVENT';
export const SETDISPLAYACCOUNTS = 'SETDISPLAYACCOUNTS';
export const SET_ALL_PRODUCTS = 'SET_ALL_PRODUCTS';

//Action Types for common actions
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const SET_DELIVERYSERVICEPOINT = 'SET_DELIVERYSERVICEPOINT';
export const SET_JSPMSTATUS = 'SET_JSPMSTATUS';
export const SET_PRINTERCONFIGURATION = 'SET_PRINTERCONFIGURATION';
export const SET_AVAILABLEPRINTERS = 'SET_AVAILABLEPRINTERS';
//Action Types for common actions
export const SETDEFAULTADDNEWACCOUNT = 'SETDEFAULTADDNEWACCOUNT';
export const SETLOADERLAYOVER = 'SETLOADERLAYOVER';

export const SETAPPLICATIONNUPDATES = 'SETAPPLICATIONNUPDATES';
export const KEYPRESSEVENT = 'KEYPRESSEVENT';
export const SET_COMMONCONFIGURATION = 'SET_COMMONCONFIGURATION';

export const SET_APPSNACKBAR = 'SET_APPSNACKBAR';
export const RESET_APPSNACKBAR = 'RESET_APPSNACKBAR';
export const SETALERTINFO = 'SETALERTINFO';
export const SET_BOTTOM_ALERT = 'SET_BOTTOM_ALERT';
export const RESET_BOTTOM_ALERT = 'RESET_BOTTOM_ALERT';
export const SET_RETURN_REFERENCE_COUNT = 'SET_RETURN_REFERENCE_COUNT';
export const SET_IS_RETURN_REFERENCE_UPDATED = 'SET_IS_RETURN_REFERENCE_UPDATED';
export const RESET_RETURN_REFERENCE_VALUES = 'RESET_RETURN_REFERENCE_VALUES';
export const SET_IOSSVALUELIMIT = 'SET_IOSSVALUELIMIT';
export const SET_DHL_IOSS_NUMBER = 'SET_DHL_IOSS_NUMBER';
export const SET_ORDERRESPONSE = 'SET_ORDERRESPONSE';
export const SET_SORT_ORDER_STATE = 'SET_SORT_ORDER_STATE';
export const SET_AUTORELOAD_PROCESSORDERS = 'SET_AUTORELOAD_PROCESSORDERS';
export const SET_POSTALCODERESPONSE = 'SET_POSTALCODERESPONSE';