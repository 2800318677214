
import * as actionTypes from '../actions/actionsTypes';
import { updateObject } from '../../utils/common';

const initialState = {
  showAlert: true,
};

const setAlertInfo = (state, action) => {
  return updateObject(state, {
    showAlert: action.data,
  });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SETALERTINFO:
      return setAlertInfo(state, action);
    default:
      return state;
  }
};

export default reducer;