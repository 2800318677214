import {
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Toolbar,
  SvgIcon,
} from '@material-ui/core';
//import { SvgIcon } from '@material-ui/core';
import MenuIcon from './../../assets/images/MenuIcon.svg';
import { ReactComponent as DashboardIcon } from './../../assets/images/Dashboard.svg';
import { ReactComponent as ShipmentIcon } from './../../assets/images/ShipmentIcon.svg';
import { ReactComponent as AddressBookIcon } from './../../assets/images/AddressBookIcon.svg';
import { ReactComponent as ReportIcon } from './../../assets/images/ReportIcon.svg';
import { ReactComponent as ConfigrationIcon } from './../../assets/images/ConfigrationIcon.svg';
import { ReactComponent as AccountsIcon } from './../../assets/images/AccountsIcon.svg';
import { ReactComponent as AuditEnquiryIcon } from '../../../src/assets/images/auditEnquiryIcon.svg';
import { ReactComponent as userManagementIcon } from '../../../src/assets/images/UserManagementIcon.svg';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Link as RouterLink, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getDomesticAccounts, isPickUpAvailableInAccount } from '../../utils/helperFunctions';
import { UserRoles } from '../../utils/constants/constants';
import { useHistory } from 'react-router';

const drawerWidth = 277;

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  drawer: {
    width: drawerWidth,
    left: 'unset',
    height: '100%',
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    left: 'unset',
    // left:'0px',
    boxShadow: '0px 1px 2px #0000001A',
    border: '1px solid #F2F2F2',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    // overflowX: "hidden",
    overflowY: 'hidden !important',
    overflowX: 'hidden !important',
    '&:hover': {},
  },
  drawerClose: {
    // left:'0px',
    left: 'unset',
    border: '1px solid #F2F2F2',
    boxShadow: '0px 1px 2px #0000001A',
    transition: theme.transitions.create('width ', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowY: 'hidden !important',
    overflowX: 'hidden !important',
    '&:hover': {
      // overflowY:'scroll !important'
    },
    width: theme.spacing(8) + 1,

    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8) + 1,
    },
  },
  nested: {
    paddingLeft: theme.spacing(5),
    whiteSpace: 'initial',
    overflowX: 'hidden',
  },
}));

const SideNav = (props) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  const handleDrawer = () => {
    open ? setOpen(false) : setOpen(true);
    setOpenList(false);
    props.DrawerEventHandler(!open);
  };
  const [openList, setOpenList] = React.useState(false);
  const [shipmentRoutes, setShipmentRoutes] = React.useState(false);
  const userRole = useSelector((state) => state?.auth?.userDetails?.role);
  const domesticAccounts = useSelector((state) => state.accounts.userAccounts);
  const selectedAccount = domesticAccounts?.find((account) => isPickUpAvailableInAccount(account)) || {};

  const handleClick = (reset = false, isShipmentRoute = false) => {
    if (!isShipmentRoute && !reset) { }
    else if (isShipmentRoute && (!reset)) { setShipmentRoutes(true) }
    else { setShipmentRoutes(false) }
    if (reset) {
      setOpenList(false);
    }
    else if (open && (!isShipmentRoute)) {
      setOpenList(!openList);
    }
  };

  useEffect(() => {
    // console.log(history)
    if (history.location.pathname === "/shipment/addShipment") {
      setShipmentRoutes(true)
    }
  }, [])


  const goTo = (path) => {
    history.push(path);
  };

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
        drawerOpened: open,
        drawerClosed: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <Toolbar />

      <div className="drawerClose">
        <div className={props.alertOpened ? 'alertPadding' : ''}>
          <IconButton className="parent-menu-icon-btn" onClick={handleDrawer}>
            {/* <MenuIcon /> */}
            <img className="menu-icon" src={MenuIcon} alt="Header Logo" />
          </IconButton>
          <Divider />

          <List>
            <ListItem
              onClick={() => {
                handleClick(true);
              }}
              button
              component={NavLink}
              to={'/dashboard'}
              activeClassName="main-title"
              disabled={userRole === '' ||
                userRole === UserRoles.ShipmentEntry ||
                userRole === UserRoles.BasicShipmentEntry ? true : false}
            >
              <ListItemIcon>
                {/* <Dashboard /> */}
                <IconButton className="menuIcons">
                  <SvgIcon component={DashboardIcon} viewBox="0 0 30 30" />
                </IconButton>
              </ListItemIcon>
              <ListItemText primary="Dashboard & tracking" className="dashboardSideNav" />
            </ListItem>

            <ListItem
              className={shipmentRoutes ? 'main-title' : ''}
              disabled={userRole === '' || userRole === UserRoles.Reporting ? true : false} button>
              <ListItemIcon
                onClick={() => {
                  !open ? handleClick(false, true) : handleClick()
                  !open && goTo('/shipment/addShipment');
                  // goTo('/shipment/addShipment');
                  // handleClick(false, true);
                }}

              >
                <IconButton className="menuIcons shipment-icon">
                  <SvgIcon component={ShipmentIcon} viewBox="0 0 27 28" />
                </IconButton>
              </ListItemIcon>
              <ListItemText
                primary="Shipment"
                exact={false}
                onClick={() => {
                  handleClick();
                  // handleClick(false, true);
                  // goTo('/shipment/addShipment');
                }}
                style={{ fontSize: '16px !important' }}
              />
              {openList ? (
                <ExpandLess className="arrow-icon"
                  onClick={() => {
                    handleClick();
                  }}
                  style={{ fontSize: '1.2rem' }}
                />
              ) : (
                <ExpandMore className="arrow-icon"
                  onClick={() => {
                    handleClick();
                  }}
                  style={{ fontSize: '1.2rem' }}
                />
              )}
            </ListItem>
            <Collapse in={openList} timeout="auto" unmountOnExit>
              <List className="shipmentSubmenu" component="nav" disablePadding>
                <ListItem
                  button
                  component={NavLink}
                  to={'/shipment/addShipment'}
                  className={classes.nested}
                  activeClassName="addsidenavShipment"
                  onClick={() => {
                    handleClick(false, true);
                  }}
                  disabled={userRole === '' || userRole === UserRoles.Reporting ? true : false}
                >
                  <ListItemText primary="Add shipment" />
                </ListItem>

                <ListItem
                  button
                  component={NavLink}
                  to={'/shipment/processOrders'}
                  className={classes.nested}
                  activeClassName="addsidenavShipment"
                  onClick={() => {
                    handleClick(false, true);
                  }}
                  disabled={userRole === '' ||
                    userRole === UserRoles.Reporting ||
                    userRole === UserRoles.BasicShipmentEntry || userRole === UserRoles.BasicShipmentEntryAndReporting ? true : false}
                >
                  <ListItemText primary="Process orders" />
                </ListItem>
                <ListItem
                  // disabled={selectedAccount?.configuration?.firstMileDropOffAllowed}
                  button
                  component={NavLink}
                  to={'/shipment/bookDriverPickUp'}
                  className={classes.nested}
                  activeClassName="addsidenavShipment"
                  onClick={() => {
                    handleClick(false, true);
                  }}
                  disabled={userRole === '' ||
                    userRole === UserRoles.Reporting ? true : Object.entries(selectedAccount).length === 0 ? true : false}
                >
                  <ListItemText primary="Request driver pick-up" />
                </ListItem>
                <ListItem
                  button
                  component={NavLink}
                  to={'/shipment/collectionRequest'}
                  className={classes.nested}
                  activeClassName="addsidenavShipment"
                  onClick={() => {
                    handleClick(false, true);
                  }}
                  disabled={userRole === '' || userRole === UserRoles.Reporting ? true : false}
                >
                  <ListItemText primary="Collection request" />
                </ListItem>
                <ListItem
                  button
                  component={NavLink}
                  to={'/shipment/shipmentManagement'}
                  className={classes.nested}
                  activeClassName="addsidenavShipment"
                  onClick={() => {
                    handleClick(false, true);
                  }}
                  disabled={userRole === '' || userRole === UserRoles.Reporting ? true : false}
                >
                  <ListItemText className={"sideListItem-custom-width"} primary="Shipments awaiting driver pick-up" />
                </ListItem>
                <ListItem
                  button
                  component={NavLink}
                  to={'/shipment/templateManagement'}
                  className={classes.nested}
                  activeClassName="addsidenavShipment"
                  onClick={() => {
                    handleClick(false, true);
                  }}
                  disabled={userRole === '' ||
                    userRole === UserRoles.Reporting ||
                    userRole === UserRoles.BasicShipmentEntry || userRole === UserRoles.BasicShipmentEntryAndReporting ? true : false}
                >
                  <ListItemText primary="Template management" />
                </ListItem>
              </List>
            </Collapse>

            <ListItem
              onClick={() => {
                handleClick(true);
              }}
              button
              component={NavLink}
              to={'/addressBook'}
              activeClassName="main-title"
              disabled={userRole === '' ||
                userRole === UserRoles.Reporting ||
                userRole === UserRoles.BasicShipmentEntry || userRole === UserRoles.BasicShipmentEntryAndReporting ? true : false}
            >
              <ListItemIcon>
                <IconButton className="menuIcons">
                  <SvgIcon component={AddressBookIcon} viewBox="0 0 30 30" />
                </IconButton>
              </ListItemIcon>
              <ListItemText primary="Address book" className="dashboardSideNav" />
            </ListItem>

            {/* <ListItem activeClassName="main-title" button onClick={handleClick}>
            <ListItemIcon>
              <img src={ReportIcon} className="side-bar-icon" alt="Header Logo" />
            </ListItemIcon>
            <ListItemText primary="Reports" className="dashboardSideNav" />
            {openList ? <ExpandLess /> : <ExpandMore />}
          </ListItem> */}

            <ListItem
              onClick={() => {
                handleClick(true);
              }}
              button
              component={NavLink}
              to={'/configuration'}
              activeClassName="main-title"
            >
              <ListItemIcon>
                <IconButton className="menuIcons">
                  <SvgIcon component={ConfigrationIcon} viewBox="0 0 30 30" />
                </IconButton>
              </ListItemIcon>
              <ListItemText primary="Configuration" className="dashboardSideNav" />
              {/* {openList ? <ExpandLess /> : <ExpandMore />} */}
            </ListItem>

            <ListItem
              onClick={() => {
                handleClick(true);
              }}
              button
              component={NavLink}
              to={'/accounts'}
              activeClassName="main-title"
              disabled={
                userRole === '' ||
                  userRole === UserRoles.ShipmentEntry ||
                  userRole === UserRoles.Reporting ||
                  userRole === UserRoles.ShipmentEntryReporting ||
                  userRole === UserRoles.ReportingShipmentEntry
                  ? true
                  : false
              }
            >
              <ListItemIcon>
                <IconButton className="menuIcons">
                  <SvgIcon component={AccountsIcon} viewBox="0 0 30 30" />
                </IconButton>
              </ListItemIcon>
              <ListItemText primary="Accounts" className="dashboardSideNav" />
              {/* {openList ? <ExpandLess /> : <ExpandMore />} */}
            </ListItem>
            <ListItem
              onClick={() => {
                handleClick(true);
              }}
              button
              component={NavLink}
              to={'/auditEnquiry'}
              activeClassName="main-title"
              disabled={
                userRole === '' ||
                  userRole === UserRoles.ShipmentEntry ||
                  userRole === UserRoles.Reporting ||
                  userRole === UserRoles.ShipmentEntryReporting ||
                  userRole === UserRoles.ReportingShipmentEntry ||
                  userRole === UserRoles.BasicShipmentEntry ||
                  userRole === UserRoles.BasicShipmentEntryAndReporting
                  ? true
                  : false
              }
            >
              <ListItemIcon style={{ marginLeft: '0px' }}>
                {/* AuditEnquiryIcon */}
                {<IconButton className="menuIcons audit-icon">
                  <SvgIcon component={AuditEnquiryIcon} viewBox="0 0 30 30" />
                </IconButton>}
              </ListItemIcon>
              <ListItemText primary="Audit enquiry" className="dashboardSideNav" />
              {/* {openList ? <ExpandLess /> : <ExpandMore />} */}
            </ListItem>
            {/* onClick={handleClick} */}
            <ListItem
              onClick={() => {
                handleClick(true);
              }}
              button
              component={NavLink}
              to={'/userManagement'}
              activeClassName="main-title"
              disabled={
                userRole === '' ||
                  userRole === UserRoles.Supervisor ||
                  userRole === UserRoles.ShipmentEntry ||
                  userRole === UserRoles.Reporting ||
                  userRole === UserRoles.ShipmentEntryReporting ||
                  userRole === UserRoles.ReportingShipmentEntry ||
                  userRole === UserRoles.BasicShipmentEntry ||
                  userRole === UserRoles.BasicShipmentEntryAndReporting
                  ? true
                  : false
              }
            >
              <ListItemIcon>
                {/* userManagementIcon */}
                <IconButton className="menuIcons user-icon">
                  <SvgIcon component={userManagementIcon} viewBox="0 0 30 30" />
                </IconButton>
              </ListItemIcon>
              <ListItemText primary="User management" className="dashboardSideNav" />
              {/* {openList ? <ExpandLess /> : <ExpandMore />} */}
            </ListItem>
          </List>
        </div>
      </div>
    </Drawer >
  );
};

SideNav.propTypes = {};

export default SideNav;
