import React from 'react';
import { connect } from 'react-redux';
import { AppBar, Tabs, Tab, Grid, IconButton, SvgIcon, Divider } from '@material-ui/core/';
import { Form, Formik } from 'formik';
import { ReactComponent as AddIcon } from '../../../src/assets/images/AddIcon.svg';
import { getYupSchemaFromMetaData } from '../../utils/yupSchema/yupSchemaGenerator';
import './UserManagement.scss';
import CreateNewUser from '../../components/userManagement/createNewUser/createNewUser';
import CreateNewRetailUser from '../../components/userManagement/createNewRetailUser/createNewRetailUser';
import TabPanel from '../../components/shared/tabPanel';
import UserList from '../../components/userManagement/UserList';
import { ServiceEndPoints } from '../../utils/constants/ApiConstant';
import { withRouter } from 'react-router-dom';
import { axiosConfig, abortOnGoingCallsFor } from '../../api/axios';
import CustomDialog from './../../framework/dialog/customDialog';
import { ReactComponent as statusyes } from '../../assets/images/status-yes.svg';
import { ReactComponent as statusno } from '../../assets/images/status-no.svg';
import { ReactComponent as InfoIcon } from '../../assets/images/InfoIconDark.svg';
import SelectedAccounts from '../../components/userManagement/Accounts/SelectedAccounts';
import userManagementForm from '../../utils/formConfig/userManagement/userManagementForm';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { roleOptions, UserManagementSearch, UserRoles } from '../../utils/constants/constants';
import { basicSort, customTableSort, getCustomerAccountID, formatDate } from '../../utils/helperFunctions';
import * as actions from '../../store/actions/index';
import FormRenderer from '../../framework/Inputs/formRenderer/formRenderer';

const formikRef = React.createRef(null);
const { formId, formField } = userManagementForm;
const userManagementSchema = getYupSchemaFromMetaData(formField, [], []);
const initialValues = {
  setupSearchFor: '',
  setupSearchBy: UserManagementSearch[0],
  role: roleOptions[0],
};

const additionalProps = {
  selectionProps: (rowData) => ({
    disabled: rowData.isAccountSelected === true && rowData.isAccountDisabled === true ? true : false,
    checked: rowData.isAccountSelected === true ? true : false,
  }),
  showSelectAllCheckbox: false,
};

const ITEM_HEIGHT = 48;
class UserManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createUser: false,
      updateUser: false,
      updateSuperUserFlag: false,
      setupSearchForFlag : false,
      rowData: {},
      open: false,
      tabIndex: 0,
      fromIndex: 1,
      totalRecords: 0,
      currentAccount: '0',
      existingAccounts: [{}],
      anchorEl: null,
      roleAccess: [],
      currentAccess: [],
      isTabChanged: false,
      isClearBtn: false,
      searchText: '',
      userListcolumns: [
        {
          title: 'id',
          field: 'id',
          hidden: true,
          width: 0,
          render: (row) => {
            return <div onClick={() => this.customerNumberClicked(row)}>{row.id}</div>;
          },
        },
        {
          title: 'User name',
          field: 'userName',
          render: (row) => {
            return <div onClick={() => this.customerNumberClicked(row)}>{row.userName}</div>;
          },
          customSort: (a, b) => customTableSort(a.userName, b.userName),
          width: 150,
        },
        {
          title: 'Role',
          field: 'role',
          render: (row) => <div onClick={() => this.customerNumberClicked(row)}>{row.role}</div>,
          sorting: true,
          customSort: (a, b) => customTableSort(a.role, b.role),
          width: 120,
        },
        {
          field: 'role',
          width: 20,
          sorting: false,
          render: (row) => (
            <>
              <IconButton onClick={(e) => this.handleClick(e, row.role)} style={{ height: '20px', width: '20px' }}>
                <SvgIcon component={InfoIcon} viewBox="0 0 48 48" style={{ fontSize: '2.5rem', borderStyle: 'none' }} />
              </IconButton>
            </>
          ),
        },
        {
          title: 'Email id',
          field: 'emailId',
          render: (row) => <div onClick={() => this.customerNumberClicked(row)}>{row.emailId}</div>,
          sorting: true,
          customSort: (a, b) => customTableSort(a.emailId, b.emailId),
          width: 200,
        },
        {
          title: 'Created by',
          field: 'createdBy',
          render: (row) => <div onClick={() => this.customerNumberClicked(row)}>{row.createdBy}</div>,
          sorting: true,
          customSort: (a, b) => customTableSort(a.createdBy, b.createdBy),
          width: 130,
        },
        {
          title: 'Last updated',
          field: 'lastUpdated',
          render: (row) => <div onClick={() => this.customerNumberClicked(row)}>{row.lastUpdated ? formatDate(row.lastUpdated, '/', 'dd/mm/yy') : null}</div>,
          sorting: true,
          customSort: (a, b) =>
            basicSort(new Date(a.lastUpdated).getTime() || 0, new Date(b.lastUpdated).getTime() || 0),
          width: 130,
        },
        {
          title: 'Updated by',
          field: 'updatedBy',
          customSort: (a, b) => customTableSort(a.updatedBy, b.updatedBy),
          render: (row) => <div onClick={() => this.customerNumberClicked(row)}>{row.updatedBy}</div>,
          sorting: true,
          width: 100,
        },
        {
          title: 'Status',
          field: 'status',
          headerStyle: {
            textAlign: 'center',
          },
          cellStyle: {
            textAlign: 'center',
            paddingLeft: '4%',
            paddingTop: '22px !important',
          },
          render: (row) => (
            <>
              <div onClick={() => this.customerNumberClicked(row)}></div>
              {row.status}
              {row.status === true ? (
                <IconButton style={{ height: '20px', width: '20px' }}>
                  <SvgIcon
                    component={statusyes}
                    viewBox="0 0 48 48"
                    style={{ fontSize: '2.5rem', borderStyle: 'none' }}
                  />
                </IconButton>
              ) : (
                <IconButton style={{ height: '20px', width: '20px' }}>
                  <SvgIcon
                    component={statusno}
                    viewBox="0 0 48 48"
                    style={{ fontSize: '2.5rem', borderStyle: 'none' }}
                  />
                </IconButton>
              )}
            </>
          ),
          sorting: false,
          width: 90,
          selection: false,
        },
      ],
      usersToAccountcolumns: [
        { title: 'id', field: 'id', hidden: true, width: 0 },
        {
          title: 'User name',
          field: 'userName',
          render: (row) => {
            return <div onClick={() => this.customerNumberClicked(row)}>{row.userName}</div>;
          },
          customSort: (a, b) => customTableSort(a.userName, b.userName),
          width: 150,
        },
        {
          title: 'Role',
          field: 'role',
          render: (row) => {
            return <div onClick={() => this.customerNumberClicked(row)}>{row.role}</div>;
          },
          customSort: (a, b) => customTableSort(a.role, b.role),
          width: 150,
        },
        {
          title: 'Email id',
          field: 'emailId',
          render: (row) => {
            return <div onClick={() => this.customerNumberClicked(row)}>{row.emailId}</div>;
          },
          customSort: (a, b) => customTableSort(a.emailId, b.emailId),
          width: 200,
        },
      ],
      rows: [],
      userList_pageDetails: {
        offset: 0,
        limit: 0,
        totalRecordsLength: 0,
        page: 0,
      },
      userList_final_data: [],
      userList_totalCount: 0,
      usersToAccount_pageDetails: {
        offset: 0,
        limit: 0,
        totalRecordsLength: 0,
        page: 0,
      },
      usersToAccount_final_data: [],
      usersToAccount_totalCount: 0,
      userListCount: 0,
      pageSize: 50,
      pageNumber: 0,
      isLoading: false,
      userListData: [],
      addUsersToTotalRecords: 0,
      addUsersToData: [],
    };
    this.userDetailsPageToken = [''];
    this.userAccountsPageToken = [''];
    this.onAccountChange = this.onAccountChange.bind(this);
    this.loadUserAccount = this.loadUserAccount.bind(this);
    this.updateAccountStatus = this.updateAccountStatus.bind(this);
    this.checkedUserToAccount = this.checkedUserToAccount.bind(this);
    this.loadRolesAndAccess = this.loadRolesAndAccess.bind(this);
  }

  componentDidMount() {
    formikRef.current.setFieldValue('setupSearchBy', UserManagementSearch[0])
    this.loadUserAccount();
    this.loadRolesAndAccess();
    this.getuserListData();
  }

  handleClick = (event, value) => {
    let currentRole = this.state.roleAccess.find((role) => role.name.toLowerCase() === value.toLowerCase());
    this.setState({ currentAccess: currentRole.value });
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = (value) => {
    this.setState({ currentAccess: [] });
    this.setState({ anchorEl: null });
  };

  loadRolesAndAccess() {
    let url = ServiceEndPoints.getRolesAccess;
    axiosConfig
      .get(url)
      .then((response) => {
        let roles = [];
        response.data.map((userRole) => {
          const item = {
            name: userRole.roleName,
            value: userRole.accessSet,
          };
          roles.push(item);
        });
        this.setState({
          roleAccess: roles,
        });
      })
      .catch((err) => { });
  }

  loadUserAccount() {
    if (this.props.userDetails && this.props.userDetails.accounts.length > 0) {
      var accounts = this.props.userDetails.accounts;
      var lstAccounts = [];
      accounts.map((account) => {
        const item = {
          name: `${account.accountId}-${account.accountName}${(account.configuration.firstMileDropOffAllowed) ? '-FMD' : ''}`,
          value: account.accountId,
        };
        lstAccounts.push(item);
      });
      this.setState({
        existingAccounts: lstAccounts,
      });
    }
  }

  onAccountChange(value) {
    // this.setState(
    //   {
    //     isTabChanged: true,
    //   },
    //   () => {
    this.updateAccountStatus(value);
    // },
    // );
  }

  updateAccountStatus(value) {
    let newArray = [...this.state.usersToAccount_final_data];
    newArray.map((stateValue, index) => {
      newArray[index] = { ...stateValue, isAccountSelected: false, isAccountDisabled: false };
    });
    newArray.map((stateValue, index) => {
      let isAccountAvailable = stateValue.accounts.some((val) => {
        return Object.keys(val) === 0 ?
          val?.toLowerCase() === value.value.toLowerCase() : false
      });
      if (isAccountAvailable) {
        newArray[index] = { ...newArray[index], isAccountSelected: !newArray[index].isAccountSelected };
        newArray[index] = { ...newArray[index], isAccountDisabled: !newArray[index].isAccountDisabled };
      }
    });
    this.setState({
      usersToAccount_final_data: newArray,
      currentAccount: value.value === '' ? '0' : value.value,
    });
    // tableRef.current && tableRef.current.onQueryChange();
    this.getuserListData();
  }

  checkedUserToAccount(selectedRow, resetchecked) {
    if (resetchecked) {
      this.state.userList_final_data.forEach((d) => {
        if (d.tableData) d.tableData.checked = false;
      });
    } else {
      const elementsIndex = this.state.usersToAccount_final_data.findIndex(
        (element) => element.id.toLowerCase() == selectedRow.toLowerCase(),
      );
      let newArray = [...this.state.usersToAccount_final_data];
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        isAccountSelected: !newArray[elementsIndex].isAccountSelected,
      };
      this.setState({
        usersToAccount_final_data: newArray,
      });
    }
  }

  addAccountToAllUsers = (accountSelected) => {
    let itemsToBeAdded = [];
    this.state.usersToAccount_final_data.map((account) => {
      const itemAccount = account.accounts;
      let isAccountAvailable = itemAccount.some((val) => val.toLowerCase() === accountSelected.toLowerCase());
      if (!isAccountAvailable) {
        const item = {
          name: account.userName,
          emailId: account.emailId,
          customerAccountID: getCustomerAccountID(),
          role: account.role,
          accounts: account.accounts,
          isActive: account.status,
        };
        itemsToBeAdded.push(item);
      }
    });
    const payload = itemsToBeAdded;

    axiosConfig
      .patch(`${ServiceEndPoints.postUsersToAccount}?accountID=${accountSelected}`, payload)
      .then((response) => {
        this.props.setSnackBar({ msg: 'Account added to all users' });
        // if (tableRef.current) {
        this.setState({ isTabChanged: true, pageSize: 50, pageNumber: 0 }, () => {
          // tableRef.current.onQueryChange();
          this.getuserListData();
        });
        // }
      })
      .catch((error) => {
        alert('Could not add account to user, please try again.');
      });
  };

  addToAccount = (accountIds, accountSelected) => {
    let itemsToBeAdded = [];
    accountIds.map((account) => {
      const item = {
        name: account.userName,
        emailId: account.emailId,
        customerAccountID: getCustomerAccountID(),
        role: account.role,
        accounts: account.accounts,
        isActive: account.status,
      };
      itemsToBeAdded.push(item);
    });
    const payload = itemsToBeAdded;

    axiosConfig
      .patch(`${ServiceEndPoints.postUsersToAccount}?accountID=${accountSelected}`, payload)
      .then((response) => {
        this.props.setSnackBar({ msg: 'Account added to users' });
        // tableRef.current && tableRef.current.onQueryChange();
        this.getuserListData();
      })
      .catch((error) => {
        alert('Could not add account to user, please try again.');
      });
  };

  customerNumberClicked = (rowDatas) => {
    rowDatas?.role?.toLowerCase() === 'superuser' ? this.setState({ updateSuperUserFlag: true }) : this.setState({ updateSuperUserFlag: false });
    if (this.props.userDetails.role?.toLowerCase() === 'superuser') {
      this.setState({ open: true, rowData: rowDatas, updateUser: true, createUser: false });
    }
    else if (this.props.userDetails.role?.toLowerCase() === 'retail superuser') {
      console.log(rowDatas.role, this.props.userDetails.role)
      if (rowDatas.role?.toLowerCase() !== 'superuser' && rowDatas.role?.toLowerCase() !== 'national superuser') {
        this.setState({ open: true, rowData: rowDatas, updateUser: true, createUser: false });
      }
    }
    else if (this.props.userDetails.role?.toLowerCase() === 'national superuser') {
      if (rowDatas.role?.toLowerCase() === 'national superuser' || rowDatas.role?.toLowerCase() === 'basic shipment entry' || rowDatas.role?.toLowerCase() === 'basic shipment entry & reporting') {
        this.setState({ open: true, rowData: rowDatas, updateUser: true, createUser: false });
      }
    }
  };

  openCreateUserModal = async () => {
    this.setState({ open: true, updateUser: false, createUser: true });
  };

  handleCloseModal = async (setErrors, resetForm) => {
    this.setState({ open: false, updateUser: false, createUser: false });
    await resetForm({});
    await setErrors({});
    formikRef.current.setFieldValue('setupSearchBy', UserManagementSearch[0])
    formikRef.current.setFieldValue('setupSearchFor', '')
    this.getClear();
  };

  handleButtonClick = () => {
    this.setState({ open: false });
  };

  handleTabChange = (event, index) => {
    if (index === 0) {
      this.updateAccountStatus({ name: 'Select', value: '' });
      formikRef.current.resetForm();
      this.state.userList_final_data.forEach((d) => {
        if (d.tableData) d.tableData.checked = false;
      });
      this.userDetailsPageToken.length = 0;
      this.userDetailsPageToken = [''];
    } else {
      this.userAccountsPageToken.length = 0;
      this.userAccountsPageToken = [''];
    }
    this.setState({ tabIndex: index, isTabChanged: true, pageSize: 50, pageNumber: 0 }, () => {
      // tableRef.current && tableRef.current.onQueryChange();
      this.getuserListData();
    });
    formikRef.current.setFieldValue('setupSearchBy', UserManagementSearch[0])
    formikRef.current.setFieldValue('setupSearchFor', '')
    this.getClear()
  };

  getuserListData = () => {
    this.setState({ isLoading: true });
    const offset = this.state.pageSize * this.state.pageNumber;
    const limit = this.state.pageSize;
    let totalCnt = limit * (this.state.pageNumber + 1);
    abortOnGoingCallsFor(ServiceEndPoints.getUsers);
    axiosConfig
      .get(this.getUrl(offset, limit))
      .then((res) => {
        const isAddToAccList = this.state.tabIndex === 1;
        if (this.state.pageNumber === 0) {
          this.userDetailsPageToken.length = 0;
          this.userDetailsPageToken = [''];
          this.userAccountsPageToken.length = 0;
          this.userAccountsPageToken = [''];
        } else if (
          this.state[isAddToAccList ? 'usersToAccount_pageDetails' : 'userList_pageDetails'].page >
          this.state.pageNumber
        ) {
          this.userDetailsPageToken = this.userDetailsPageToken.slice(0, this.state.userList_pageDetails.page);
          this.userAccountsPageToken = this.userAccountsPageToken.slice(0, this.state.usersToAccount_pageDetails.page);
        }

        res.data?.skipToken && this.userDetailsPageToken.push(res.data.skipToken);
        res.data?.skipToken && this.userAccountsPageToken.push(res.data.skipToken);

        this.setState({
          [isAddToAccList ? 'usersToAccount_pageDetails' : 'userList_pageDetails']: {
            offset: offset,
            limit: limit,
            totalRecordsLength: res.data?.skipToken ? totalCnt + 1 : totalCnt,
            page: this.state.pageNumber,
          },
          isTabChanged: false,
        });
        this.setState({ totalRecords: (res.data || {}).skipToken ? totalCnt + 1 : totalCnt });
        this.formatUserListData(res.data, totalCnt);

      })
      .catch((err) => {
        console.log(err);
        this.formatUserListData([]);
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  getUrl = (offset, limit) => {
    let url = `${ServiceEndPoints.getUsers}?accountsNotRequired=true`;
    let searchedValue = formikRef.current.values.setupSearchFor?.trim();
    if (searchedValue && formikRef.current.values.setupSearchBy) {
      this.setState({ searchText: searchedValue })
      if (formikRef.current.values.setupSearchBy.name === 'User name') {
        url += `&userName=${encodeURIComponent(searchedValue)}`
      }
      if (formikRef.current.values.setupSearchBy.name === "User's email id") {
        url += `&emailID=${encodeURIComponent(searchedValue)}`
      }
    }
    url += '&limit=' + limit;
    if (this.state.tabIndex === 0) {
      if (this.state.userList_pageDetails && this.state.userList_pageDetails.limit !== limit) {
        this.userDetailsPageToken.length = 0;
        this.userDetailsPageToken = [''];
      }
      if (this.userDetailsPageToken[this.state.pageNumber] !== '')
        url += '&skipToken=' + this.userDetailsPageToken[this.state.pageNumber];
    } else {
      if (this.state.usersToAccount_pageDetails && this.state.usersToAccount_pageDetails.limit !== limit) {
        this.userAccountsPageToken.length = 0;
        this.userAccountsPageToken = [''];
      }
      if (this.userAccountsPageToken[this.state.pageNumber] !== '')
        url += '&skipToken=' + this.userAccountsPageToken[this.state.pageNumber];
    }
    return url;
  };

  formatUserListData = (result, totalCnt = 0) => {
    let final_data = [];
    ((result || {}).finalResponse || []).forEach((element, index) => {
      const accounts = element?.accounts;
      var lstAccounts = [];
      accounts.map((acc) => {
        lstAccounts.push(acc.accountId);
      });

      var lastUpdateDate = '';
      if (element.updatedAt) {
        var ldate = new Date(element.updatedAt);
        lastUpdateDate = ldate.getMonth() + 1 + '/' + ldate.getDate() + '/' + ldate.getFullYear();
      }

      final_data.push({
        id: element.userId,
        userName: element?.name,
        role: element?.role,
        emailId: element?.emailId,
        createdBy: element?.createdBy,
        lastUpdated: lastUpdateDate,
        updatedBy: element?.updatedBy,
        status: element?.isActive,
        accounts: lstAccounts,
        isAccountSelected: lstAccounts.some((val) => val.toLowerCase() === this.state.currentAccount.toLowerCase()),
        isAccountDisabled: lstAccounts.some((val) => val.toLowerCase() === this.state.currentAccount.toLowerCase()),
      });
    });
    this.setState({
      userList_final_data: final_data,
      userList_totalCount: (result || {}).skipToken ? totalCnt + 1 : totalCnt,
      usersToAccount_final_data: final_data,
      usersToAccount_totalCount: (result || {}).skipToken ? totalCnt + 1 : totalCnt,
    });
  };

  formikRefResetForm = () => {
    formikRef.current.setErrors({});
  };

  onPageNumChange(pageNum) {
    this.setState({ pageNumber: pageNum }, () => {
      this.getuserListData();
    });
  }

  onPageSizeChange(pageSize) {
    this.setState({ pageSize: pageSize }, () => {
      this.getuserListData();
    });
  }

  getUserDetails = (event) => {
    event.preventDefault();
    this.setState({ isClearBtn: true, pageNumber: 0 ,setupSearchForFlag: true}, () => {
      this.getuserListData();
    });
  }

  getClear = (event = null, queryParams = '') => {
    if (event) {
      event.preventDefault();
    }
    formikRef.current.setFieldValue('setupSearchFor', '');
    formikRef.current.setFieldTouched('setupSearchFor', false);
    this.setState({ pageNumber: 0, isClearBtn: false, searchText: '' }, () => {
      this.getuserListData();
    });
  };

  resetClearButton = () => {
    this.setState({ isClearBtn: false })
  }
  setClearButton = () => {
    this.setState({ isClearBtn: true })
  }
  setSetupSearchForFlag = () => {
    this.setState({setupSearchForFlag: false})
  }

  render() {
    return (
      <React.Fragment>
        <Formik
          initialValues={initialValues}
          validationSchema={userManagementSchema}
          enableReinitialize={true}
          innerRef={formikRef}
        >
          {({ values, setValues, setFieldValue, setFieldTouched, errors, resetForm, setErrors }) => {
            if (values.setupSearchFor?.trim()) {
              console.log(this.state.searchText, values.setupSearchFor.trim())
              if (this.state.searchText && values.setupSearchFor.trim() !== this.state.searchText) {
                this.state.isClearBtn && this.resetClearButton();
              }
              else if (values.setupSearchFor.trim() === this.state.searchText && this.state.setupSearchForFlag) {
                !this.state.isClearBtn && this.setClearButton();
              }
            }
            return (
              <Form id={formId}>
                <Grid container className="AddressBook-Container">
                  <Grid item xs sm md lg>
                    <AppBar position="static" className="AddressBook-AppBar">
                      <Grid container spacing={3} direction="row">
                        <Grid item xs={5} sm={5} lg={5}>
                          <Tabs
                            value={this.state.tabIndex}
                            onChange={this.handleTabChange}
                            className={`${this.state.tabIndex === 0 ? 'My-Address-Book-Tab-panel' : 'Favourites-Tab-panel'
                              }`}
                          >
                            <Tab label="User list" className="Tab-panel" />
                            <Tab label="Add users to account" className="Tab-panel"
                              disabled={this.props.userDetails.role?.toLowerCase() === UserRoles.NationalSuperUser.toLowerCase() ||
                                this.props.userDetails.role?.toLowerCase() === UserRoles.RetailSuperUser.toLowerCase()} />
                          </Tabs>
                        </Grid>
                        <Grid xs={2} sm={2} lg={2}></Grid>
                        <Grid item xs={5} sm={5} lg={5} justify="flex-end">
                          <FormRenderer
                            data={UserManagementSearch || []}
                            selectProps={formField.setupSearchBy.selectProps.props}
                            textProps={formField.setupSearchBy.textProps.props}
                            onClearSearch={(event) => this.getClear(event, '')}
                            onSearchClick={this.getUserDetails}
                            isIconDisabled={errors.setupSearchBy || values.setupSearchFor?.trim() === ''}
                            isClearBtn={values.setupSearchFor?.trim() === ''? false : this.state.isClearBtn}
                            {...formField.setupSearchBy.props}
                          />
                        </Grid>
                      </Grid>
                    </AppBar>
                  </Grid>
                  {this.state.tabIndex === 0 ? (
                    <Grid item xs={2} sm={2} md={2} lg={2} alignItems="flex-end">
                      <Grid container direction="row" className="createUser-container">
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <IconButton onClick={this.openCreateUserModal}>
                            Create new user
                            <SvgIcon component={AddIcon} viewBox="5 5 30 30" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}
                  <>
                    <TabPanel
                      className="My-Address-Book-Tab"
                      value={this.state.tabIndex}
                      index={0}
                      style={{ width: '100%' }}
                    >
                      <UserList

                        rows={this.state.userList_final_data}
                        columns={this.state.userListcolumns}
                        pageSizeOptions={[50, 75, 100]}
                        getuserListData={this.getuserListData}
                        setupSearchForFlag={this.state.setupSearchForFlag}
                        setSetupSearchForFlag={this.setSetupSearchForFlag}
                        empty_DataSource_Message="User not found"
                        isUsers={true}
                        updateRowData={this.state.rowData}
                        updateUser={this.state.updateUser}
                        userDetailsRole={this.props.userDetails.role}
                        formField={formField}
                        formikValues={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        setValues={setValues}
                        setErrors={setErrors}
                        setFieldTouched={setFieldTouched}
                        hideTotalCount={true}
                        fullWidth
                        pageSize={this.state.pageSize}
                        totalCount={this.state.userList_totalCount}
                        count={this.state.userList_totalCount}
                        isLoading={this.state.isLoading}
                        inBuiltEvents={{
                          onChangeRowsPerPage: (e) => this.onPageSizeChange(e),
                          onChangePage: (a) => this.onPageNumChange(a),
                        }}
                        page={this.state.pageNumber}
                      />
                    </TabPanel>
                    <TabPanel
                      className="Favourites-Tab"
                      value={this.state.tabIndex}
                      index={1}
                      style={{ width: '100%' }}
                    >
                      <SelectedAccounts
                        formField={formField}
                        formikValues={values}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        onAccountChangeChange={this.onAccountChange}
                        existingAccounts={this.state.existingAccounts}
                        errors={errors}
                        currentAccount={this.state.currentAccount}
                        showSelectAllCheckbox={false}
                      />
                      <UserList
                        rows={this.state.usersToAccount_final_data}
                        columns={this.state.usersToAccountcolumns}
                        getuserListData={this.getuserListData}
                        setupSearchForFlag={this.state.setupSearchForFlag}
                        setSetupSearchForFlag={this.setSetupSearchForFlag}
                        pageSizeOptions={[50, 75, 100]}
                        addAccountToAllUsers={this.addAccountToAllUsers}
                        addToAccount={this.addToAccount}
                        checkedUserToAccount={this.checkedUserToAccount}
                        additionalProps={additionalProps}
                        userDetailsRole={this.props.userDetails.role}
                        empty_DataSource_Message="Users not found"
                        isUsers={false}
                        currentAccount={this.state.currentAccount}
                        formField={formField}
                        formikValues={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        setValues={setValues}
                        hideTotalCount={true}
                        fullWidth
                        pageSize={this.state.pageSize}
                        totalCount={this.state.userList_totalCount}
                        count={this.state.userList_totalCount}
                        isLoading={this.state.isLoading}
                        inBuiltEvents={{
                          onChangeRowsPerPage: (e) => this.onPageSizeChange(e),
                          onChangePage: (a) => this.onPageNumChange(a),
                        }}
                        page={this.state.pageNumber}
                      />
                    </TabPanel>
                  </>
                </Grid>
                <Menu
                  className="role-tooltip"
                  style={{ marginLeft: '-325px', marginTop: '59px' }}
                  id="simple-menu"
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 20,
                      width: 300,
                    },
                  }}
                >
                  <MenuItem onClick={this.handleClose}>
                    {'No.'} {'Access'}
                  </MenuItem>
                  <Divider style={{ marginTop: '5px' }} />
                  {this.state.currentAccess.map((item, index) => (
                    <Grid>
                      <MenuItem onClick={this.handleClose} key={index} value={item}>
                        {index + 1}
                        {'\t'}
                        {item}
                      </MenuItem>
                      <Divider style={{ marginTop: '5px' }} />
                    </Grid>
                  ))}
                </Menu>
                {this.props.userDetails.role?.toLowerCase() === 'superuser' ? <CustomDialog
                  maxWidth="sm"
                  open={this.state.open}
                  onClose={() => {
                    this.handleCloseModal(setErrors, resetForm);
                  }}
                  className="commodityDetails-popup userPopups"
                  title={this.state.updateUser ? 'Update user' : 'Create new user'}
                >
                  <CreateNewUser
                    updateRowData={this.state.rowData}
                    updateUser={this.state.updateUser}
                    formField={formField}
                    formikValues={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    setValues={setValues}
                    setErrors={setErrors}
                    setFieldTouched={setFieldTouched}
                    handleButtonClick={this.handleButtonClick}
                    getuserListData={this.getuserListData}
                    createUser={this.state.createUser}
                    userDetailsRole={this.props.userDetails.role}
                    updateSuperUserFlag={this.state.updateSuperUserFlag}
                  />
                </CustomDialog>
                  :
                  <CustomDialog
                    open={this.state.open}
                    onClose={() => {
                      this.handleCloseModal(setErrors, resetForm);
                    }}
                    className="retailUserPopups"
                    title={this.state.updateUser ? 'Update user' : 'Create new user'}
                  >
                    <CreateNewRetailUser
                      updateRowData={this.state.rowData}
                      updateUser={this.state.updateUser}
                      formField={formField}
                      userDetailsRole={this.props.userDetails.role}
                      formikValues={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      setValues={setValues}
                      setErrors={setErrors}
                      setFieldTouched={setFieldTouched}
                      handleButtonClick={this.handleButtonClick}
                      getuserListData={this.getuserListData}
                      createUser={this.state.createUser}
                      setState={this.setState}
                    />

                  </CustomDialog>
                }
              </Form>
            );
          }}
        </Formik>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.auth.userDetails,
    addressBookSearchParams: state.searchParams,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSnackBar: (data) => {
      dispatch(actions.setAppSnackBarData(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserManagement));