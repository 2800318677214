import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Grid } from "@material-ui/core";
import { useSelector } from 'react-redux';
import { axiosConfig } from '../../../api/axios';
import { ServiceEndPoints } from "../../../utils/constants/ApiConstant";
import moment from "moment";
import * as actions from '../../../store/actions/index'

const MarketplaceConfig = (props) => {

  const[hostedPageUrl, setHostedPageUrl]= useState("");
  const[pageExpiredAt, setpageExpiredAt]= useState("");
  const[storeCreationFlag, setStoreCreationFlag]= useState(false);

  const userDetails = useSelector((state) => state.auth.userDetails);

  useEffect(()=>{
    const interval = setInterval(() => {
      var currentDate = moment();
      var expiryDate = moment(pageExpiredAt);
      
      if(expiryDate.diff(currentDate, 'minutes') === 3){
        setStoreCreationFlag(!storeCreationFlag)
      }
    }, 60000);
  
    return () => clearInterval(interval);
  },[pageExpiredAt]);

  useEffect(()=>{
    props.setLoader(true);
    axiosConfig
    .post(`${ServiceEndPoints.getHostedPageUrl}?customerAccountNumber=${userDetails?.accounts[0]?.customerAccountNumber}`)
    .then((res) => {
      setHostedPageUrl(res.data?.url);
      setpageExpiredAt(res.data?.expiredAt);
    })
    .catch((err) => {
      console.log(err);
      if (err.response?.status === 422) {
        axiosConfig
          .post(`${ServiceEndPoints.getCreateStoreUrl}?customerAccountNumber=${userDetails?.accounts[0]?.customerAccountNumber}`)
          .then((res) => {
            setStoreCreationFlag(!storeCreationFlag)
          })
          .catch((err) => {
            console.log(err);
            props.setBottomAlert({msgs : [
              {
                type: 'error',
                msg: 'Authentication error. Please try again later',
              },
            ]})
          })
          .finally(() => { });
      } else {
        props.setBottomAlert({msgs : [
          {
            type: 'error',
            msg: 'Authentication error. Please try again later',
          },
        ]})
      }
    })
    .finally(() => { props.setLoader(false); });
  },[storeCreationFlag]);

  return (
    <Grid container direction="row">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <embed src={hostedPageUrl} width="106%" height="325%" style={{ border: 'none', overflow: 'hidden' }}>
        </embed>
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoader: (isActive) => {
      dispatch(actions.setLoaderLayOver(isActive));
    },
    setBottomAlert: (errorMessage) => {
      dispatch(actions.setBottomAlert(errorMessage));
    }
  };
}

export default connect(null, mapDispatchToProps)(MarketplaceConfig);