import React, { useState, useEffect } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import CustomCaption from '../../../../framework/summaryFormate/CustomCaption';
import ReadOnlyCheckbox from '../../../../framework/readOnlyCheckbox/ReadOnlyCheckbox';
import { formatDate } from '../../../../../src/utils/helperFunctions';

const useStyles = makeStyles({
  flexContainer: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
  },
  parcelHandover: {
    paddingBottom: '20px',
  },
});

const DetailsSummary = ({ shipmentDetails }) => {
  const classes = useStyles();
  const [state, setstate] = useState(false);

  const pieceslength = () => {
    if ((shipmentDetails.parcelDetails || []).length > 0) {
      setstate(true);
    } else {
      setstate(false);
    }
  };

  useEffect(() => {
    pieceslength();
  }, [shipmentDetails]);

  return (
    <>
      <Grid item xs={12} lg={12}>
        <Typography m={2} className={classes.parcelHandover}>
          {(shipmentDetails || {}).handOverMethod !== ''
            ? `Parcel handover method:  ${shipmentDetails?.handOverMethod === 'driverCollection'
              ? 'Driver collection'
              : 'Drop off at Service Point'
            }`
            : null}
        </Typography>
      </Grid>
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3}>
        <Grid item className={classes.flexContainer}>
          <CustomCaption title="Account number" value={shipmentDetails?.accountNumber?.accountId || ''} />
        </Grid>
        <Grid item className={classes.flexContainer}>
          <CustomCaption title="Dispatch date" value={formatDate(shipmentDetails?.DispatchDate, '/', 'dd/mm/yy')} />
        </Grid>
        {shipmentDetails.createPalletShipment==="yes"?<Grid item className={classes.flexContainer}>
          <CustomCaption title="Number of pallets" value={shipmentDetails.pallets} />
        </Grid>:
          <Grid item className={classes.flexContainer}>
          <CustomCaption title="Number of items" value={shipmentDetails.noOfItems} />
        </Grid>}
        <Grid item className={classes.flexContainer}>
          <CustomCaption title="Weight (kg)" value={shipmentDetails.weight || 0} />
        </Grid>
        {/* <Grid item className={classes.flexContainer} >
        <ReadOnlyCheckbox label="Parcel dimensions added" checked={state} />
        </Grid> */}
        <Grid item className={classes.flexContainer}>
          <CustomCaption title="Total volumetric wt(kg)" value={shipmentDetails.totalVolumetricWeight} />
        </Grid>
      </Grid>
    </>
  );
};

export default DetailsSummary;
