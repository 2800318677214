import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserRoles } from '../../utils/constants/constants';
import MarketplaceConfig from './MarketplaceConfiguration/MarketplaceConfig';

import genralConfiguration from '../../assets/images/GeneralConfigurations.svg';
import printerConfiguration from '../../assets/images/PrinterConfigurations.svg';
import fileConfiguration from '../../assets/images/OrderFileConfigurations.svg';
import barCodeConfiguration from '../../assets/images/Icon_awesome_barcode.svg';
import marketplaceConfiguration from '../../assets/images/marketplaceIcon.svg';
import './ConfigurationHome.scss';

const useCardStyles = makeStyles({
  root: {
    height: '114px',
    width: '212px',
    background: '#F8F7F7',
    '&:hover': {
      background: '#FFCC00',
    },
  },
  img: {
    'object-fit': 'contain !important',
  },
});

const useCardContentStyles = makeStyles({
  root: {
    height: '114px',
    display: 'flex !important',
  },
  roleAccess: {
    height: '114px',
    display: 'flex !important',
    cursor: 'not-allowed',
    pointerevents: 'all !important',
    opacity: '1',
    '&:hover': {
      background: '#FFFFFF',
    },
  }
});

const ConfigurationHome = (props) => {
  const userDetails = useSelector((state) => state?.auth?.userDetails);
  const userRole =  userDetails?.role;
  const classes = useCardStyles();
  const carContentclasses = useCardContentStyles();
  const isMarketplaceAccount = userDetails?.accounts?.some((val) => { 
    return val?.configuration?.marketplaceUserOptional === true;
  })
  
  return (
    <div className="Config-home">
      <p className="config-title">Select the tile to go to configuration flow</p>
      <Grid container>
        <Grid className="config-items" item container spacing={3}>
          <Grid item>
            <Card className={classes.root}>
              <CardActionArea
                component={RouterLink}
                to={(userRole === UserRoles.ShipmentEntry || userRole === UserRoles.Reporting || userRole === UserRoles.ShipmentEntryReporting || userRole === UserRoles.ReportingShipmentEntry || userRole === UserRoles.BasicShipmentEntry || userRole === UserRoles.BasicShipmentEntryAndReporting) ? null : '/configuration/fileConfiguration'}
                className={(userRole === UserRoles.ShipmentEntry || userRole === UserRoles.Reporting || userRole === UserRoles.ShipmentEntryReporting || userRole === UserRoles.ReportingShipmentEntry || userRole === UserRoles.BasicShipmentEntry || userRole === UserRoles.BasicShipmentEntryAndReporting) ? carContentclasses.roleAccess : carContentclasses.root}
              >
                <CardContent>
                  <CardMedia
                    component="img"
                    alt="Order configurations"
                    height="27"
                    width="30"
                    src={fileConfiguration}
                    className={classes.img}
                  />
                  <Typography gutterBottom>Order configurations</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item>
            <Card className={classes.root}>
              <CardActionArea
                component={RouterLink}
                to={'/configuration/printerConfiguration'}
                className={carContentclasses.root}
              >
                <CardContent>
                  <CardMedia
                    component="img"
                    alt="Printer configurations"
                    height="27"
                    width="30"
                    src={printerConfiguration}
                    className={classes.img}
                  />
                  <Typography gutterBottom>Printer configurations</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item>
            <Card className={classes.root}>
              <CardActionArea
                component={RouterLink}
                to={(userRole === '' || userRole === UserRoles.ShipmentEntry || userRole === UserRoles.Reporting || userRole === UserRoles.ShipmentEntryReporting || userRole === UserRoles.ReportingShipmentEntry || userRole === UserRoles.BasicShipmentEntry || userRole === UserRoles.BasicShipmentEntryAndReporting) ? null : '/configuration/genralConfiguration'}
                className={(userRole === '' || userRole === UserRoles.ShipmentEntry || userRole === UserRoles.Reporting || userRole === UserRoles.ShipmentEntryReporting || userRole === UserRoles.ReportingShipmentEntry || userRole === UserRoles.BasicShipmentEntry || userRole === UserRoles.BasicShipmentEntryAndReporting) ? carContentclasses.roleAccess : carContentclasses.root}
              >
                <CardContent>
                  <CardMedia
                    component="img"
                    alt="General configurations"
                    height="27"
                    width="30"
                    src={genralConfiguration}
                    className={classes.img}
                  />
                  <Typography>General configurations</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item>
            <Card className={classes.root}>
              <CardActionArea
                component={RouterLink}
                to={(userRole === '' || userRole === UserRoles.ShipmentEntry || userRole === UserRoles.Reporting || userRole === UserRoles.ShipmentEntryReporting || userRole === UserRoles.ReportingShipmentEntry || userRole === UserRoles.BasicShipmentEntry || userRole === UserRoles.BasicShipmentEntryAndReporting || userRole === UserRoles.BasicShipmentEntryAndReporting) ? null : '/configuration/barCodeConfiguration'}
                className={(userRole === '' || userRole === UserRoles.ShipmentEntry || userRole === UserRoles.Reporting || userRole === UserRoles.ShipmentEntryReporting || userRole === UserRoles.ReportingShipmentEntry || userRole === UserRoles.BasicShipmentEntry || userRole === UserRoles.BasicShipmentEntryAndReporting || userRole === UserRoles.BasicShipmentEntryAndReporting) ? carContentclasses.roleAccess : carContentclasses.root}
              >
                <CardContent>
                  <CardMedia
                    component="img"
                    alt="Barcode configurations"
                    height="27"
                    width="30"
                    src={barCodeConfiguration}
                    className={classes.img}
                  />
                  <Typography>Barcode configurations</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          {(isMarketplaceAccount || userRole === UserRoles.Admin) && <Grid item>
            <Card className={classes.root}>
              <CardActionArea
                component={RouterLink}
                to={(userRole === '' || userRole === UserRoles.Supervisor || userRole === UserRoles.ShipmentEntry || userRole === UserRoles.Reporting || userRole === UserRoles.ShipmentEntryReporting || userRole === UserRoles.ReportingShipmentEntry || userRole === UserRoles.BasicShipmentEntry || userRole === UserRoles.BasicShipmentEntryAndReporting || userRole === UserRoles.BasicShipmentEntryAndReporting) ? null : '/configuration/marketplaceConfig'}
                className={(userRole === '' || userRole === UserRoles.Supervisor|| userRole === UserRoles.ShipmentEntry || userRole === UserRoles.Reporting || userRole === UserRoles.ShipmentEntryReporting || userRole === UserRoles.ReportingShipmentEntry || userRole === UserRoles.BasicShipmentEntry || userRole === UserRoles.BasicShipmentEntryAndReporting || userRole === UserRoles.BasicShipmentEntryAndReporting) ? carContentclasses.roleAccess : carContentclasses.root}
              >
                <CardContent>
                  <CardMedia
                    component="img"
                    alt="Marketplace configurations"
                    height="27"
                    width="30"
                    src={marketplaceConfiguration}
                    className={classes.img}
                  />
                  <Typography>Connect to marketplaces</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>}
        </Grid>
      </Grid>
    </div>
  );
};

export default ConfigurationHome;
