const bookDriverPickUpForm = {
  formId: 'bookDriverPickUpForm',
  formField: {
    bookCollectionDate: {
      props: {
        name: 'bookCollectionDate',
        label: 'Pick up date',
        type: 'date',
        isRequired: true,
      },
      value: '',
      validation: {
        name: 'bookCollectionDate',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Date is required'],
          },
        ],
      },
    },
    pickupLocation: {
      props: {
        name: 'pickupLocation',
        label: 'Pick-up location',
        type: 'autoComplete',
        isRequired: true,
        displayField: 'name',
        hasDefaultValue: true,
      },
      // value: ,
      validation: {
        name: 'pickupLocation',
        validationType: 'object',
        validations: [{
          type: 'test',
          params: [
            '',
            'Error in creating driver pick up: associated account(s) are on stop. Please contact your account manager or credit control',
            (pickupLocation) => {
              if (pickupLocation?.status === 'OnStop') {
                return false;
              }
              return true;
            },
          ],
        }],
      }
    },
    earliestTime: {
      props: {
        name: 'earliestTime',
        label: 'Earlie',
        type: 'time',
        isRequired: true,
      },
      value: '09:00:00',
    },
    latestTime: {
      props: {
        name: 'latestTime',
        label: 'Latest time',
        type: 'time',
        isRequired: true,
      },
      value: '17:00:00',
    },
    specialInstructions: {
      props: {
        name: 'specialInstructions',
        label: 'Special instructions',
        type: 'text',
        placeholder: 'Enter',
        inputProps: {
          maxLength: 80,
        },
      },
      value: '',
    },
  },
};

export default bookDriverPickUpForm;
