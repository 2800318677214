import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import FormRenderer from '../../../../framework/Inputs/formRenderer/formRenderer';
import PropTypes from 'prop-types';
import { setDropdownValues } from '../../../../utils/helperFunctions';

const ShippingDetails = (props) => {
  const {
    formField: {
      customDeclarationShippingCharges,
      customDeclarationCurrency,
      customDeclarationShippersEoriNo,
      customDeclarationReceiversEoriNo,
      reasonForExport,
      fdaRegistrationNumber,
    },
    setFieldValue,
    currencies,
    fromPreviewSummary,
    formikValues,
    isAddNewAddress,
    reasonsForExport,
  } = props;

  const [shippingChargesProps, setShippingChargesProps] = useState(customDeclarationShippingCharges.props);
  const [_customDeclarationCurrency, setcustomDeclarationCurrency] = useState(currencies);

  useEffect(() => {
    // if (formikValues.customDeclarationShippersEoriNo ) {
    //   setFieldValue('customDeclarationShippersEoriNo', formikValues.customDeclarationShippersEoriNo);
    // } else {
      setFieldValue(
        // 'customDeclarationShippersEoriNo',
        // formikValues.accountNumber?.customsRegistration?.eoriNumber || '',
        'customDeclarationShippersEoriNo', (fromPreviewSummary || {}).loadedFrom === 'fromPreviewSummary' ?fromPreviewSummary.loadedResponse?.customDeclarationShippersEoriNo : formikValues.accountNumber?.customsRegistration?.eoriNumber || ''
      );
    // }
  }, [formikValues.accountNumber, setFieldValue]);

  useEffect(() => {
    if (props.formikValues.destinationCountry.iossApplies) {
      let _filterCurrency = currencies.filter((obj) => obj.code == 'GBP' || obj.code == 'EUR');
      setcustomDeclarationCurrency(_filterCurrency);
    } else {
      setcustomDeclarationCurrency(currencies);
    }
    const dropDownValue = setDropdownValues(currencies, 'code', 'GBP');
    setFieldValue('customDeclarationCurrency', dropDownValue ? dropDownValue : '');
  }, [props.formikValues.destinationCountry, currencies]);

  useEffect(() => {
    if (fromPreviewSummary && (fromPreviewSummary || {}).loadedFrom === 'fromPreviewSummary') {
      setFieldValue(
        'customDeclarationCurrency',
        setDropdownValues(
          _customDeclarationCurrency,
          'code',
          fromPreviewSummary.loadedResponse?.customDeclarationCurrency?.code,
        ),
      );
    } else if (fromPreviewSummary && (fromPreviewSummary || {}).loadedFrom === 'dropdownResponse') {
      setFieldValue(
        'customDeclarationCurrency',
        setDropdownValues(
          _customDeclarationCurrency,
          'code',
          fromPreviewSummary.loadedResponse?.shipments?.[0]?.shipmentDetails?.currency
            ? fromPreviewSummary.loadedResponse?.shipments?.[0]?.shipmentDetails?.currency
            : 'GBP',
        ),
      );
    }
  }, [currencies, fromPreviewSummary, _customDeclarationCurrency]);

  useEffect(() => {
    const shippingChargesLabel = `${shippingChargesProps.label.split('(')[0]}(${
      props.formikValues.customDeclarationCurrency?.code || ''
    })`;
    setShippingChargesProps({ ...shippingChargesProps, label: shippingChargesLabel });
  }, [props.formikValues.customDeclarationCurrency]);

  useEffect(() => {
    if (formikValues.accountNumber) {
      const accountFdaNumber =
        formikValues.accountNumber?.customsRegistration?.fdaNumber === null ||
          formikValues.accountNumber?.customsRegistration?.fdaNumber === undefined
          ? ''
          : formikValues.accountNumber?.customsRegistration?.fdaNumber;
      setFieldValue('FDARegistrationNumber', accountFdaNumber);
    }
  }, [formikValues.accountNumber, setFieldValue]);

  return (
    <Grid>
      <Grid container spacing={2} direction="row" alignItems="flex-start">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container spacing={3} direction="row" alignItems="flex-start">
            <Grid item xs={12} sm={6} md={6} lg={6} style={{ paddingRight: '34px' }}>
              <FormRenderer
                {...shippingChargesProps}
                onChange={({ target: { value } }) => {
                  if (/^\d+(\.\d{0,2})?$/.test(value)) {
                    setFieldValue('customDeclarationShippingCharges', value);
                  } else if (value === '') {
                    setFieldValue('customDeclarationShippingCharges', '');
                  }
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <FormRenderer {...customDeclarationCurrency.props} data={_customDeclarationCurrency} fullWidth />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container spacing={3} direction="row" alignItems="flex-start">
            <Grid item xs={12} sm={6} md={6} lg={6} style={{ paddingRight: '34px' }}>
              <FormRenderer {...customDeclarationShippersEoriNo.props} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <FormRenderer {...customDeclarationReceiversEoriNo.props} fullWidth />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container spacing={3} direction="row" alignItems="flex-start">
            <Grid item xs={12} sm={6} md={6} lg={6} style={{ paddingRight: '34px' }}>
              {!isAddNewAddress ? (
                <FormRenderer
                  {...reasonForExport.props}
                  data={reasonsForExport}
                  fullWidth
                />
              ) : null}
            </Grid>
            {!isAddNewAddress ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormRenderer {...fdaRegistrationNumber.props} fullWidth />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ShippingDetails.propTypes = {
  setFieldValue: PropTypes.func,
  currencies: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    fromPreviewSummary: state.loadedFrom.loadedFrom,
  };
};

export default connect(mapStateToProps, null)(ShippingDetails);
