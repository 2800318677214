import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getYupSchemaFromMetaData } from '../../../utils/yupSchema/yupSchemaGenerator';
import { Grid, Snackbar } from '@material-ui/core';
import { Form, Formik } from 'formik';
import dashboardForm from '../../../utils/formConfig/dashboardForm/dashboardForm';
import AccountsForDashboard from '../dashboard/accountsForDashboard/accountsForDashboard';
import { GET, PUT, POST } from '../../../../src/api/axios';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import Badge from '@material-ui/core/Badge';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import Drawer from '@material-ui/core/Drawer';
// import {PDFtoIMG} from 'react-pdf-to-image';
// import file from './pdf-sample.pdf';
import Pdf from 'react-to-pdf';
import DashboardMonitorList from '../../../components/dashboard/MonitorList/DashnboardMonitorList';
import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';
import { fullSearchDashboardByOptions } from '../../../utils/constants/constants';
import { dynamicSort } from '../../../utils/helperFunctions';
import * as actions from '../../../store/actions/index';
import { useSelector } from 'react-redux';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const { formId, formField } = dashboardForm;

const Dashboard = (props) => {
  const [open, setOpen] = React.useState(false);
  const [monitorList, setMonitorList] = React.useState([]);
  const [monitorListCount, setMonitorListCount] = React.useState({ total: 0, active: 0 });
  const [countries, setCountries] = React.useState([]);
  const dispatch = useDispatch();
  const newDomestiValidationSchema = getYupSchemaFromMetaData(formField, [], []);

  let alertInfo = useSelector((state) => state.alertInfo.showAlert);
  // console.log(alertInfo + ' Testing');

  const initialValues = {
    // dashboardSearchBy: fullSearchDashboardByOptions[0],
  };
  for (let property in formField) {
    if (Object.prototype.hasOwnProperty.call(formField, property)) {
      const field = formField[property];
      if (field.props.type === 'checkbox' || field.props.type === 'radio') {
        initialValues[field.props.name] = field.value;
      } else if (field.props.type === 'array') {
        initialValues[field.props.name] = field.values;
      } else {
        initialValues[field.props.name] = field.value || '';
      }
    }
  }

  useEffect(() => {
    GET(ServiceEndPoints.getCountries)
      .then((res) => {
        const sortedCountries = res.data.countries.sort(dynamicSort('name'));
        sortedCountries.unshift({ value: '', name: 'All countries', code2Digit: '' });
        setCountries(sortedCountries);
      })
      .catch((err) => {
        setCountries([]);
      });
  }, []);
  useEffect(() => {
    props.userDetails?.userId && getAllMonitioredShipments();
  }, [props.userDetails]);

  const getAllMonitioredShipments = () => {
    const requestUrl = ServiceEndPoints.getAllMonitioredShipments + props.userDetails.userId;
    GET(requestUrl)
      .then((response) => {
        const changedItems = response.data.filter((x) => x.isStatusChanged === true);
        setMonitorListCount({ active: changedItems ? changedItems.length : 0, total: response.data.length });
        setMonitorList(response.data);
      })
      .catch((error) => {
        setMonitorList([]);
        setMonitorListCount({ active: 0, total: 0 });
      });
  };

  const handleDrawer = () => {
    open ? setOpen(false) : setOpen(true);
  };

  const clearRecentlyViewed = () => {
    const requestUrl = ServiceEndPoints.getAllMonitioredShipments + props.userDetails.userId;
    const payload = monitorList.filter((x) => x.isStatusChanged === true);
    if (payload.length > 0) {
      PUT(requestUrl, payload)
        .then((response) => {
          getAllMonitioredShipments();
        })
        .catch((error) => {
          dispatch(
            actions.setBottomAlert({
              msgs: [
                {
                  type: 'error',
                  msg: 'Failed to update',
                },
              ],
            }),
          );
          // dispatch(actions.setAppSnackBarData({ type: 'error', msg: 'Failed to update' }));
        });
    }
  };

  const clearFromMonitorList = (selectedRows) => {
    const requestUrl = ServiceEndPoints.clearMonitoredShipments + props.userDetails.userId;
    const payload = selectedRows.map((row) => {
      return row.shipmentDetails.shipmentNumber;
    });
    POST(requestUrl, payload)
      .then((resposne) => {
        getAllMonitioredShipments();
      })
      .catch((err) => {
        dispatch(
          actions.setBottomAlert({
            msgs: [
              {
                type: 'error',
                msg: 'Failed to clear the shipment',
              },
            ],
          }),
        );
        // dispatch(actions.setAppSnackBarData({ type: 'error', msg: 'Failed to clear the shipment' }));
      });
  };

  const printDocument = () => {
    const input = document.getElementById('dpie');
    var w = document.getElementById('dpie').offsetWidth;
    var h = document.getElementById('dpie').offsetHeight;
    html2canvas(input, {
      dpi: 300,
      scale: 3,
    }).then((canvas) => {
      var pdf = new jsPDF('L', 'px', [w, h]);
      var imgData = canvas.toDataURL('image/jpeg', 1);
      pdf.addImage(imgData, 'JPEG', 15, 5, 1080, 600);
      pdf.save('Dashboard_Graph.pdf');
    });
  };
  const ref = React.createRef();

  const options = {
    orientation: 'portrait',
    unit: 'in',
    format: [4, 2],
  };
  return (
    <Grid className="shipment" id="dpie" ref={ref}>
      <Grid>
        <React.Fragment>
          <Formik
            initialValues={initialValues}
            // onSubmit={handleSubmit}
            validationSchema={newDomestiValidationSchema}
            enableReinitialize={true}
          >
            {({ values, setValues, setFieldValue, setFieldTouched, errors, resetForm }) => {
              const isDomestic = ((values || {}).destinationCountry || {}).code === 'GBR';
              return (
                <Form id={formId}>
                  <br />
                  <div>
                    <button
                      id="monitor-list-btn"
                      className={`Monitor-list-icon ${alertInfo ? 'alertDialogContainer' : ''}`}
                      onClick={handleDrawer}
                      style={{ display: open ? 'none' : '' }}
                    >
                      <VisibilityOutlinedIcon
                        className="eye-icon"
                        style={{ fontSize: '32px', verticalAlign: 'middle' }}
                      />
                      <span style={{ verticalAlign: 'middle' }}>Monitor list ({monitorListCount.total})</span>
                      <Badge badgeContent={`${monitorListCount.active}`} color="error"></Badge>
                      <FilterListOutlinedIcon
                        className="Filter_List_Outlined_Icon"
                        style={{ fontSize: '20px', verticalAlign: 'middle', float: 'right' }}
                      />
                    </button>

                    <Drawer
                      BackdropProps={{ style: { background: '#D3D3D3 0% 0% no-repeat padding-box', opacity: 0.95 } }}
                      // onBackdropClick={handleDrawer}
                      className="monitor-list-drawer"
                      anchor={'right'}
                      open={open}
                    >
                      <DashboardMonitorList
                        handleDrawer={handleDrawer}
                        monitorList={monitorList}
                        monitorListCount={monitorListCount}
                        clearRecentlyViewed={clearRecentlyViewed}
                        clearFromMonitorList={clearFromMonitorList}
                      />
                    </Drawer>
                  </div>
                  <Grid container direction="row" justify="space-evenly" spacing={1} style={{ paddingRight: '50px' }}>
                    <Grid item sm={12} md={12} lg={12}>
                      <AccountsForDashboard
                        className="accountsForDashboard"
                        formField={formField}
                        formikValues={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        isDomestic={isDomestic}
                        setFieldTouched={setFieldTouched}
                        setValues={setValues}
                        countries={countries}
                        printDocument={printDocument}
                        targetRef={ref}
                        options={options}
                      />
                    </Grid>
                    {/* <Grid item sm={6} md={6} lg={6}> */}
                    {/* <FindShipment
                        formField={formField}
                        formikValues={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        setFieldTouched={setFieldTouched}
                      /> */}
                    {/* <BarChartComponent/> */}
                    {/* <BreakDownDashboard
                        formField={formField}
                        formikValues={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        isDomestic={isDomestic}
                        setFieldTouched={setFieldTouched}
                      /> */}
                    {/* </Grid> */}
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </React.Fragment>
      </Grid>
    </Grid>
  );
};

Dashboard.propTypes = {};

const mapStateToProps = (state) => {
  return {
    // selectedAccount: state.accounts.selectedAccount,
    // favouriteCustomers: state.customers.favouriteCustomers,
    userDetails: state.auth.userDetails,
  };
};

export default connect(mapStateToProps, null)(Dashboard);
