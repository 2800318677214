import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import SearchAddress from './SearchAddress';
import { getYupSchemaFromMetaData } from '../../../utils/yupSchema/yupSchemaGenerator';

const SearchAddressBook = (props) => {
  const formField = searchForm;
  const newSearchValidationSchema = getYupSchemaFromMetaData(formField, [], []);

  const initialValues = {
    addressSearchFor: '',
    favoriteSearchFor: '',
    addressSearchBy: searchByOptions[0],
    favoriteSearchBy: searchByFavouriteOptions[0],
    // addressImportType: addressImportTypeOptions[0],
  };
  return (
    <div>

      <Formik initialValues={initialValues} enableReinitialize={true} validationSchema={newSearchValidationSchema}>
        {({ values, setFieldValue, setValues, errors, setFieldTouched }) => (
          <Form className="Addessbook-Searchbar">
            <SearchAddress
              formField={formField}
              searchByOptions={ props.tabIndex === 0 ? searchByOptions : searchByFavouriteOptions }
              formikValues={values}
              setFieldTouched={setFieldTouched}
              setValues={setValues}
              setFieldValue={setFieldValue}
              tabIndex={props.tabIndex}
              onAddressChange={props.onAddressChange}
              errors={errors}
              {...props}
              // addressImportTypeOptions={addressImportTypeOptions}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};


SearchAddressBook.propTypes = {
  tabIndex:PropTypes.string,
  onAddressChange: PropTypes.func,
}

export default SearchAddressBook;

const searchForm = {
  addressSearchFor: {
    props: {
      name: 'addressSearchFor',
      type: 'searchControl',
    },
    selectProps: {
      props: {
        name: 'addressSearchBy',
        label: 'Search For',
        placeholder: "Enter Customer's Number",
      },
    },
    textProps: {
      props: {
        name: 'addressSearchFor',
        label: 'Search For',
        placeholder: "Enter Customer's Number",
      },
   
    },
    validation: {
      name: 'addressSearchFor',
      validationType: 'string',
      // validations: [
      //   {
      //     type: 'required',
      //     params: ['Search for is required'],
      //   },
      // ],
    },
  },
  favoriteSearchFor: {
    props: {
      name: 'favoriteSearchFor',
      type: 'searchControl',
    },
    selectProps: {
      props: {
        name: 'favoriteSearchBy',
        label: 'Search For',
        placeholder: "Enter Customer's Number",
      },
    },
    textProps: {
      props: {
        name: 'favoriteSearchFor',
        label: 'Search For',
        placeholder: "Enter Customer's Number",
      },
    },
    validation: {
      name: 'favoriteSearchFor',
      validationType: 'string',
      // validations: [
      //   {
      //     type: 'required',
      //     params: ['Search for is required'],
      //   },
      // ],
    },
  },
  // addressImportType: {
  //   props: {
  //     name: 'addressImportType',
  //     label: 'Address import type',
  //     type: 'select',
  //     displayField: 'name',
  //     hasDefaultValue: true,
  //     isRequired: true,
  //   },
  //   validation: {
  //     name: 'addressImportType',
  //     validationType: 'object',
  //     validations: [
  //       {
  //         type: 'required',
  //         params: ['Address import type is required'],
  //       },
  //     ],
  //   },
  // },
};

const searchByOptions = [
  {
    value: 'customernumber',
    name: 'Customer number',
  },
  {
    value: 'recipientname',
    name: 'Recipient name',
  },
  {
    value: 'businessname',
    name: 'Business name',
  },
  {
    value: 'town',
    name: 'Town',
  },
  {
    value: 'postcode',
    name: 'Postal code',
  },
];

const searchByFavouriteOptions = [
  {
    value: 'customernumber',
    name: 'Customer number',
  },
  {
    value: 'recipientname',
    name: 'Recipient name',
  },
  {
    value: 'businessname',
    name: 'Business name',
  },
];

// const addressImportTypeOptions = [
//   {
//     value: 'domestic',
//     name: 'iConsign domestic import'
//   },
//   {
//     value: 'international',
//     name: 'iConsign international import'
//   },
//   {
//     value: 'consignor',
//     name: 'Consignor import'
//   },
//   {
//     value: 'ship',
//     name: 'Ship import'
//   }
// ]
