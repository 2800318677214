import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Tabs,
  Tab,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
} from '@material-ui/core';
import TabPanel from '../../../shared/tabPanel';
import { useSelector } from 'react-redux';
import { getParsedFloatValues } from '../../../../utils/helperFunctions';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    border: '1px solid #B5B5B5',
  },
});

const DimensionDetails = (props) => {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);
  const [tableData, setTableData] = useState(props.shipmentDimensions?.declaredValues || []);
  const [volumetricWeightDivisor, setVolumetricWeightDivisor] = useState(4000);

  const handleTabChange = (event, value) => {
    setTabIndex(value);
  };

  const userDetails = useSelector((state) => state.auth.userDetails);

  useEffect(() => {
    const dimensionsData = props.shipmentDimensions?.declaredValues || [];
    setTableData(
      dimensionsData.map((dimension) => {
        return {
          ...dimension,
          volumnWeight: getCaluclatedVolumetricWeight(dimension),
        };
      }),
    );
  }, [props.shipmentDimensions, volumetricWeightDivisor]);

  useEffect(() => {
    const shipmentAcc = userDetails?.accounts?.find((user) => user.accountId === props.shipmentDetails.accountNumber);
    setVolumetricWeightDivisor(shipmentAcc?.configuration?.volumetricWeightDivisor || 4000);
  }, [userDetails, props.shipmentDimensions, props.shipmentDetails.accountNumber]);

  const getCaluclatedVolumetricWeight = (row) => {
    return getParsedFloatValues((row.length * row.height * row.width) / volumetricWeightDivisor);
  };

  const totalVolumetricWeight = (tableData || []).reduce((acc, ele) => acc + (ele || {}).volumnWeight, 0);
  const totalDeclaredWeight = (tableData || []).reduce((acc, ele) => acc + (ele || {}).measuredweight, 0);

  return (
    <Grid>
      <Tabs value={tabIndex} onChange={handleTabChange}>
        <Tab label="Declared values" />
      </Tabs>
      <Grid style={{ marginTop: '8px', overflowY: 'auto' }}>
        <TabPanel value={tabIndex} index={0} style={{ width: '100%' }}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Parcel no.</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Length (cm)</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Width (cm)</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Height (cm)</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Parcel reference</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Volumetric wt.(kg)</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Declared wt.(kg)</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell align="left">{row.length}</TableCell>
                    <TableCell align="left">{row.width} </TableCell>
                    <TableCell align="left">{row.height}</TableCell>
                    <TableCell align="left">{(row.pieceRefId) ? row.pieceRefId : "-"}</TableCell>
                    <TableCell align="left">{row.volumnWeight}</TableCell>
                    <TableCell align="left">{row.measuredweight}</TableCell>
                  </TableRow>
                ))}
                <TableRow className="footerRow">
                  <TableCell align="left">Total</TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left">
                    <b>{totalVolumetricWeight} </b>
                  </TableCell>
                  <TableCell align="left">
                    <b>{totalDeclaredWeight} </b>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
      </Grid>
    </Grid>
  );
};

DimensionDetails.propTypes = {};

export default DimensionDetails;
