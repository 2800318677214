import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import './deliverTo.scss';
import { abortOnGoingCallsFor, axiosConfig } from '../../../api/axios';
import FormRenderer from '../../../framework/Inputs/formRenderer/formRenderer';
import AddressFormate from '../../../framework/addressFormate/AddressFormate';
import { setDropdownValues, arrayToString, getDomesticAccounts } from '../../../utils/helperFunctions';
import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';

const DeliverToSender = (props) => {
  const {
    formField: { toServiceRequired, specialInstructions, additionalInstruction },
    setFieldValue,
    formikValues,
  } = props;

  const [services, setServices] = React.useState([]);
  const selectedAccount = useSelector((state) => state.accounts.selectedAccount);
  const userAccounts = useSelector((state) => state.accounts.nonReturnuserAccounts);
  const displayAccounts = getDomesticAccounts(userAccounts)

  useEffect(() => {
    if (formikValues.fromPostCode && formikValues.fromWeight) {
      const destinationAddressType = formikValues.deliveryType === 'deliverToDoorStep' ? 'doorstep' : 'servicePoint';
      // Removed all accounts due API character limit.
      //  const pickupAccount = (displayAccounts) ? arrayToString(displayAccounts, 'pickupAccount', 'accountId') : '';
      
       // Sending only selected account.
       const pickupAccount = `&pickupAccount=${selectedAccount?.accountId}`;
      abortOnGoingCallsFor(ServiceEndPoints.getProducts);
      axiosConfig
        .get(
          `${ServiceEndPoints.getProducts}DestinationPostalCode=${formikValues.returnType === 'returnToSender'
            ? selectedAccount?.sender?.postalCode
            : formikValues?.toPostCode
          }&DestinationCountryCode=GB&DestinationRecipientType=${formikValues.returnType === 'returnToSender' ? 'business' : formikValues.toAddressType
          }&weight=${formikValues?.fromWeight}&OriginPostalCode=${formikValues.fromPostCode
          }&OriginCountryCode=GB&parcels=${formikValues?.fromParcel
          }&destinationAddressType=${destinationAddressType}${pickupAccount}`,
        )
        .then((res) => {
          if (res.data.length > 0) {
            let min = Math.min.apply(
              null,
              res.data.map((item) => item.sortOrder),
            );
            let minSortOrderData = res.data.find((services) => services.sortOrder === min);
            setServices(minSortOrderData?.deliveryCapabilities?.services || []);
          }
        })
        .catch((err) => {
          console.log(err);
          setServices([]);
        });
    }
  }, [
    selectedAccount,
    formikValues?.fromWeight,
    formikValues?.fromParcel,
    formikValues.returnType,
    formikValues?.toPostCode,
    formikValues.toAddressType,
    formikValues.deliveryType,
    formikValues.fromPostCode,
    formikValues.doorStepInstructions,
  ]);

  const loadedFrom = useSelector((state) => state.loadedFrom.loadedFrom);

  useEffect(() => {
    setFieldValue('toServiceRequired', setDropdownValues(services, 'name', 'Next day'));
  }, [services]);

  useEffect(() => {
    const loadedFromScreen = loadedFrom.loadedFrom;
    const loadedResponse = loadedFrom.loadedResponse;
    if (
      services?.length > 0 &&
      (loadedFromScreen === 'dropdownResponse' || loadedFromScreen === 'fromPreviewSummary') &&
      (loadedResponse || {}).service
    ) {
      const selectedService = setDropdownValues(services, 'name', loadedResponse.service?.description);
      setFieldValue('toServiceRequired', selectedService);
    }
  }, [services, loadedFrom]);

  const getDorStepInstructionsForParams = () => {
    const DSI = formikValues?.doorStepInstructions;
    let instruction = 'doorstepOnly';
    if (DSI?.value === 'deliverToNeighbour') {
      instruction = 'neighbourDelivery';
    } else if (DSI?.value === 'leaveSafe') {
      instruction = 'secureLocationDelivery';
    }
    return instruction;
  };

  return (
    <Grid container item xs={12} sm={12} lg={12} spacing={3} style={{ paddingRight: 'unset' }}>
      <Grid item xs={12} style={{ paddingBottom: 'unset' }}>
        <b>Delivery address</b>
      </Grid>
      <AddressFormate
        addressType={selectedAccount.sender?.companyName ? 'Business' : 'Residential'}
        contactName={selectedAccount.sender?.name}
        businessName={selectedAccount.sender?.companyName}
        addressLine1={selectedAccount.sender?.address1}
        addressLine2={selectedAccount.sender?.address2}
        addressLine3={selectedAccount.sender?.address3}
        town={selectedAccount.sender?.city}
        country={selectedAccount.sender?.country}
        postCode={selectedAccount.sender?.postalCode}
        emailAddress={selectedAccount.sender?.email}
        telephoneNumber={selectedAccount.sender?.phone}
      />
      <Divider className="DividerLine" variant="middle" />
      <Grid container item xs={12} sm={12} lg={12} spacing={3}>
        <Grid item xs={12}>
          <b>Service details</b>
        </Grid>
        <Grid item xs={12}>
          <FormRenderer {...toServiceRequired.props} data={services} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <FormRenderer {...specialInstructions.props} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <FormRenderer {...additionalInstruction.props} label={'Additional instructions'} fullWidth />
        </Grid>
      </Grid>
    </Grid>
  );
};

DeliverToSender.propTypes = {
  formField: PropTypes.object,
  errors: PropTypes.object,
  formikValues: PropTypes.object,
  setFieldValue: PropTypes.func,
};

export default DeliverToSender;
