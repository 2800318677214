import * as actionTypes from '../actions/actionsTypes';

import { updateObject } from '../../utils/common';

const initialState = {
  loadedFrom: '',
};

const setBackUpResponse = (state, action) => {
  return updateObject(state, {
    loadedFrom: action.data,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SETBACKUPRESPONSE:
      return setBackUpResponse(state, action);
    default:
      return state;
  }
};

export default reducer;
