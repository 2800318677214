import { Grid } from '@material-ui/core';
import React, { Component } from 'react';
import Slider from 'react-slick';
import './transitionAlerts.scss';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { GET } from '../../api/axios';
export default class SlideChangeHooks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSlide: 0,
    };
  }

  render() {
    const { alertInfo } = this.props;
  const fiteredData = (alertInfo || [])[this.state.activeSlide]
  const description = (fiteredData)
 
    const settings = {
      dots: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: (current, next) => this.setState({ activeSlide: next }),
      afterChange: (current) => this.setState({ activeSlide: current }),
      nextArrow: <ChevronRightIcon />,
      prevArrow: <ChevronLeftIcon />,
    };
    return (
      <div className="sliderForAlert">
        <Grid container>
          <Grid item xs={2}>
            <Slider {...settings}>
              {(alertInfo || []).map((alert, index) => (
                <div className="sliderText">
                  <p>
                    Showing {index + 1} of {alertInfo.length} alerts
                  </p>
                </div>
              ))}
            </Slider>
          </Grid>
          <Grid item xs={10}>
            <p className="alert-Description">{description}</p>
          </Grid>
        </Grid>
      </div>
    );
  }
}
