import React, { useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import ClockIcon from '@material-ui/icons/AccessTime';

import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import { useField } from 'formik';
import moment from 'moment';
import { FormHelperText } from '@material-ui/core';

import { makeStyles } from '@material-ui/core';

const useStylesTextField = makeStyles(() => ({
  root: {
    border: '1px solid #00000053',
    overflow: 'hidden',
    color: 'black',
    borderRadius: 4,
    backgroundColor: '#fff ',
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&$focused': {
      backgroundColor: '#fff',
      border: '1px solid #00000053',
    },
  },
}));

const InputTime = (props) => {
  const { label, defaultTime, minTime, maxTime, timeInternval, errorMessage, isValidationError } = props;
  const classes = useStylesTextField();

  const [field, meta, helper] = useField(props);

  const [selectedDate, setSelectedDate] = React.useState(defaultTime);
  // const [isOverTime, setOverTime] = React.useState(false);

  // const [selectedDate, setSelectedDate] = useState();
  const { value } = field;
  const { touched, error } = meta;

  const { setValue } = helper;
  const isError = touched && error && true;

  const handleTimePicker = (value) => {
    setSelectedDate(value);
    if (value) {
      const hours = value.getHours() < 10 ? `0${value.getHours()}` : value.getHours();
      // const selectedTime = `${value.getHours()}:${value.getMinutes()}:${value.getSeconds()}`;
      const selectedTime = `${hours}:${(value.getMinutes() < 10 ? '0' : '') + value.getMinutes()}:${value.getSeconds()}`;
      setValue(selectedTime);
      // const time = moment(selectedTime, 'HHmmss');
      // const minTimeing = moment(minTime, 'HHmmss');
      // const maxTimeing = moment(maxTime, 'HHmmss');

      // if (minTimeing.isBefore(time) && maxTimeing.isAfter(time)) {
      //   setOverTime(false);
      // } else {
      //   setOverTime(true);
      // }
    }
  };

  const renderSelectHelperText = () => {
    if (isValidationError) {
      return <FormHelperText>{errorMessage}</FormHelperText>;
    }
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        required
        inputVariant="filled"
        InputProps={{ classes, disableUnderline: true }}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        minutesStep={timeInternval}
        label={label}
        value={selectedDate}
        onChange={handleTimePicker}
        keyboardIcon={<ClockIcon />}
        error={meta.touched && meta.error && true}
        invalidDateMessage={isError && error}
        helperText={renderSelectHelperText()}
      />
    </MuiPickersUtilsProvider>
  );
};

export default InputTime;
