/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SearchIcon } from '@material-ui/data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { axiosConfig } from '../../../api/axios';
import { NoOfRows } from '../../../utils/constants/constants';
import FormRenderer from '../../../framework/Inputs/formRenderer/formRenderer';
import CustomDialog from '../../../framework/dialog/customDialog';
import CustomDataTable from '../../../framework/CustomDataTable/CustomDataTable';
import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';
import * as actions from '../../../store/actions/index';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    marginTop: '2px',
    flex: 0,
    color: '#000000',
  },
}));

const ServicePoint = (props) => {
  const {
    isDomestic,
    formField: { deliveryServicePoint },
    setFieldValue,
    errors,
    formikValues,
    servicePointAddress,
  } = props;

  const dispatch = useDispatch();

  const classes = useStyles();
  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = React.useState([]);

  const servicePointDetails = useSelector((state) => state.addShipmentData.servicePointDelivery);
  const formLoadedFrom = useSelector((state) => state.loadedFrom);

  const rowClicked = (val) => {
    dispatch(actions.setServiceLocationDetails(val));
    setIsAddressSelected(true);
    const data = `${val?.address?.address1 || ''} ${val?.address?.address2 || ''} ${val?.address?.postalTown || ''}`;
    setFieldValue('deliveryServicePoint', data);
    handleModal(false);
  };

  const handleModal = (value) => {
    setOpen(value);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const getServicePoint = () => {
    const { deliveryServicePoint, destinationCountry } = props.formikValues;
    let url = '';
    const encodePostcodeURI = encodeURIComponent(deliveryServicePoint);
    setRows([]);
    if (!errors.deliveryServicePoint) {
      dispatch(actions.setLoaderLayOver(true));
      if (isDomestic) {
        url = `${ServiceEndPoints.getServicePoints}?countryCode=GB&PostCode=${encodePostcodeURI}&ServicePointType=parcelshop`;
      } else {
        url = `${ServiceEndPoints.getServicePoints}?countryCode=${destinationCountry.code2Digit}&PostCode=${encodePostcodeURI}&ServicePointType=parcelshop`;
      }
      axiosConfig
        .get(url)
        .then((res) => {
          handleModal(true);
          setRows(res.data.slice(0, NoOfRows));
        })
        .catch((err) => {
          setRows([]);
          dispatch(
            actions.setBottomAlert({
              msgs: [
                {
                  type: 'error',
                  msg: 'No service point found',
                },
              ],
            }),
          );
          // dispatch(actions.setAppSnackBarData({ type: 'error', msg: 'No service point found' }));
        })
        .finally(() => {
          dispatch(actions.setLoaderLayOver(false));
        });
    }
  };

  useEffect(() => {
    if (formikValues.deliveryServicePoint === '') {
      setServicePointAddressAsNull();
    }
  }, [formikValues.deliveryServicePoint]);

  useEffect(() => {
    const { postalCode } = formikValues;
    if (
      formLoadedFrom.loadedFrom.loadedFrom === 'fromPreviewSummary' &&
      formLoadedFrom.loadedFrom.loadedResponse.deliveryServicePoint !== ''
    ) {
      setFieldValue('deliveryServicePoint', formLoadedFrom?.loadedFrom?.loadedResponse?.deliveryServicePoint || '');
      setIsAddressSelected(true);
    } else if (postalCode) {
      setFieldValue('deliveryServicePoint', postalCode);
      setServicePointAddressAsNull();
    }
  }, [formikValues.postalCode]);

  useEffect(() => {
    const { toPostCode } = formikValues;
    if (toPostCode) {
      setFieldValue('deliveryServicePoint', toPostCode);
      setServicePointAddressAsNull();
    }
  }, [formikValues.toPostCode]);

  const setServicePointAddressAsNull = () => {
    dispatch(actions.setServiceLocationDetails(null));
    setIsAddressSelected(false);
  };

  useEffect(() => {
    if (Object.keys(servicePointAddress ?? {})?.length != 0) {
      dispatch(actions.setServiceLocationDetails(servicePointAddress));
      setIsAddressSelected(true);
      const data = `${servicePointAddress?.address?.address1 || ''} ${servicePointAddress?.address?.address2 || ''} ${servicePointAddress?.address?.postalTown || ''
        }`;
      setFieldValue('deliveryServicePoint', data);
      handleModal(false);
    }
  }, [servicePointAddress]);
  return (
    <Grid container className="service-grid">
      <Grid item xs={12} lg={12} md={12}>
        <FormRenderer
          isAddressSelected={isAddressSelected}
          {...deliveryServicePoint.props}
          isDisabled={(props.isAmendShipment) ? true : false}
          endArguments={{
            endAdornment: (
              <IconButton
                style={{ padding: 'unset' }}
                disabled={(errors || {}).deliveryServicePoint || isAddressSelected}
                onClick={getServicePoint}
                className={classes.iconButton}
              >
                <SearchIcon />
              </IconButton>
            ),
          }}
          fullWidth
        />
        {!errors.deliveryServicePoint && !servicePointDetails?.servicePointID ? (
          <span className="span-required">Select servicePoint</span>
        ) : null}
        <CustomDialog className="Select-service-dialog" open={open} onClose={handleClose} title="Select ServicePoint ">
          <CustomDataTable rows={rows} rowClicked={rowClicked} />
        </CustomDialog>
      </Grid>
    </Grid>
  );
};

ServicePoint.propTypes = {
  productList: PropTypes.object.isRequired,
  isDomestic: PropTypes.bool,
  formField: PropTypes.object,
  formikValues: PropTypes.object,
  setFieldValue: PropTypes.func,
};

export default ServicePoint;
