export const genralConfigurationForm = {
  formId: 'genralConfigurationform',
  formField: {
    customerReference: {
      props: {
        name: 'customerReferenceMandetory',
        label: 'Customer reference mandatory',
        type: 'checkbox',
      },
      value: false,
    },
    alternativeReference: {
      props: {
        name: 'alternativeReferenceMandatory',
        label: 'Alternative reference mandatory',
        type: 'checkbox',
      },
      value: false,
    },
    duplicateCustomerReference: {
      props: {
        name: 'disallowDuplicateCustomerReference',
        label: 'Disallow duplicate customer reference',
        type: 'checkbox',
      },
      value: false,
    },
    duplicateAlternativeReference: {
      props: {
        name: 'disallowDuplicateAlternateReference',
        label: 'Disallow duplicate alternative reference',
        type: 'checkbox',
      },
      value: false,
    },
    customerReferenceText: {
      props: {
        name: 'reference1',
        label: 'Reference 1 (Customer reference)',
        type: 'text',
        placeholder: 'Enter',
        inputProps: {
          maxLength: 20,
        },
      },
    },
    alternativeReferenceText: {
      props: {
        name: 'reference2',
        label: 'Reference 2 (Alternative reference)',
        type: 'text',
        placeholder: 'Enter',
        inputProps: {
          maxLength: 20,
        },
      },
    },
    addNewAccountToUsers: {
      props: {
        name: 'addToAllUsers',
        label: 'By default add new account to all users',
        type: 'checkbox',
      },
      value: false,
    },
    printCustomInvoice: {
      props: {
        name: 'printCustomInvoice',
        label: 'Print customs invoice',
        type: 'checkbox',
      },
      value: false,
    },
    defaultPrintInboxReturnLabel: {
      props: {
        name: 'defaultPrintInboxReturnLabel',
        label: 'By default print inbox return label',
        type: 'checkbox',
      },
      value: false,
    },
  },
};
