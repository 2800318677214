import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import InputButton from '../../../framework/Inputs/inputButton/inputButton';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  confirmationDialog: {
    overflow: 'hidden',
  },
}));

const ConfirmationDialog = (props) => {
  const classes = useStyles();
  const { confirmationMessage, confirmLabel, cancelLabel } = props;
  return (
    <Grid container className={classes.confirmationDialog}>
      <Grid item>
        <Typography variant="subtitle1" gutterBottom>
          {confirmationMessage}
        </Typography>
      </Grid>
      <Grid item container spacing={3} align="center">
        {props.onConfirm
          ? <Grid item>
            <InputButton className="Delete-btn" label={confirmLabel} onClick={() => props.onConfirm()} />
          </Grid>
: null}
        {props.OnCancel
? <Grid item>
          <InputButton className="Delete-btn" label={cancelLabel} onClick={() => props.OnCancel()} />
        </Grid>
: null}
      </Grid>
    </Grid>
  );
};

ConfirmationDialog.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  confirmationMessage: PropTypes.string,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
};

ConfirmationDialog.defaultProps = {
  confirmLabel: "Ok",
  cancelLabel: "Cancel",
}

export default ConfirmationDialog;
