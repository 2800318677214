const API_BASE_URL_DEV = ' https://dhl-non-dev-ukw-api-apim-001.azure-api.net/usp/v1';
const API_BASE_URL_SIT = ' https://dhl-non-sit-ukw-api-apim-001.azure-api.net/usp/v1';
const API_BASE_URL_UAT = ' https://dhl-non-uat-ukw-api-apim-001.azure-api.net/usp/v1';

let LICENSE_API_BASE_URL = 'https://dhl-prd-uks-usp-wapp-002.azurewebsites.net';

const OVERWRITE_LICENSE_API = 'false';

if (OVERWRITE_LICENSE_API.startsWith('#') || OVERWRITE_LICENSE_API === "true") {
  LICENSE_API_BASE_URL = ' https://dhl-non-uat-ukw-api-apim-001.azure-api.net/usp/v1';
}

let API_BASE_URL = 'https://api.dhlecommerce.co.uk/usp/v1';
export const GOOGLE_API_KEY = 'AIzaSyAzT3CCQthZOMEGYGCCkHXB2gw0l8O3SBI';

if (API_BASE_URL.startsWith('#')) {
  API_BASE_URL = API_BASE_URL_UAT;
}

export const ServiceEndPoints = {
  getUser: `${API_BASE_URL}/user`,
  getCountries: `${API_BASE_URL}/countries`,
  getAddressBook: `${API_BASE_URL}/addressbooks`,
  getFavouritesForByText: `${API_BASE_URL}/addressbook/favourite`,
  getCurrencies: `${API_BASE_URL}/currencies`,
  getTemplateBy: `${API_BASE_URL}/templates`,
  getPublicHolidays: `${API_BASE_URL}/publicholidays`,
  getPostalAddress: `${API_BASE_URL}/addresses`,
  postAddressBook: `${API_BASE_URL}/addressbook`,
  postTemplate: `${API_BASE_URL}/template`,
  deleteTemplate: `${API_BASE_URL}/templates/delete`,
  productCountryFeature: `${API_BASE_URL}/ProductCountryFeature`,
  getShipmentManagement: `${API_BASE_URL}/shipments?`,
  postAddressBookCsv: `${API_BASE_URL}/addressbook/upload`,
  getShipment: `${API_BASE_URL}/shipments`,
  cancelShipment: `${API_BASE_URL}/cancel`,
  reprintShipments: `${API_BASE_URL}/reprint`,
  carriageForwardSummary: `${API_BASE_URL}/shipments?CarriageForward=true&shipmentnumber=`,
  getAllMonitioredShipments: `${API_BASE_URL}/monitorshipments/`,
  clearMonitoredShipments: `${API_BASE_URL}/monitorshipments/delete/`,
  getShipmentDashboard: `${API_BASE_URL}/shipments`,
  dashboardSummary: `${API_BASE_URL}/dashboard/shipment?`,
  getBreakDownDetails: `${API_BASE_URL}/dashboard/shipment/`,
  auditLogs: `${API_BASE_URL}/auditLogs`,
  genralConfiguration: `${API_BASE_URL}/configuration/general`,
  getServicePoints: `${API_BASE_URL}/internationalservicepoints`,
  getShipmentTracking: `${API_BASE_URL}/trackingshipments/`,
  getOrderDefaults: `${API_BASE_URL}/orderdefaults`,
  getMappedServices: `${API_BASE_URL}/referencedata/services`,
  postOrderMapping: `${API_BASE_URL}/orderdefaults/ordermapping`,
  orderSetup: `${API_BASE_URL}/configuration/ordersetup`,
  getSchemas: `${API_BASE_URL}/schemas`,
  postSchemas: `${API_BASE_URL}/schemas`,
  getUsers: `${API_BASE_URL}/users`,
  postUsersToAccount: `${API_BASE_URL}/user/accounts`,
  getRolesAccess: `${API_BASE_URL}/user/roles`,
  products: `${API_BASE_URL}/products`,
  postUser: `${API_BASE_URL}/user`,
  getOrders: `${API_BASE_URL}/orders`,
  deleteOrders: `${API_BASE_URL}/orders`,
  createOrders: `${API_BASE_URL}/createorders`,
  searchandprint: `${API_BASE_URL}/searchandprint`,
  saveOrder: `${API_BASE_URL}/order`,
  getBarcode: `${API_BASE_URL}/configuration/barcode`,
  getReturnInstructionLabel: `${API_BASE_URL}/accounts/returnInstructionLabel`,
  returnInstructionLabelPreview: `${API_BASE_URL}/return-instruction-uk`,
  printConfiguration: `${API_BASE_URL}/configuration/printer`,
  applicationupdates: `${API_BASE_URL}/applicationupdates`,
  alerts: `${API_BASE_URL}/alerts`,
  email: `${API_BASE_URL}/email`,
  getProducts: `${API_BASE_URL}/products?`,
  schemaUrl: `${API_BASE_URL}/schema`,
  getLocations: `${API_BASE_URL}/locations`,
  getShipConfiguration: `${API_BASE_URL}/accounts/shipconfiguration`,
  shipmentLabelUrl: `${API_BASE_URL}/shipment`,
  shipmentAmmendUrl: `${API_BASE_URL}/shipment/amendment`,
  commodityCode: `${API_BASE_URL}/commodity`,
  pickUpsUrl: `${API_BASE_URL}/pickup`,
  faqsUrl: `${API_BASE_URL}/faqs`,
  getPickups: `${API_BASE_URL}/pickups`,
  getCustomsDeclaration: `${API_BASE_URL}/customsdeclaration`,
  orderFileUpload: `${API_BASE_URL}/orderfile/upload`,
  printerQueue: `${API_BASE_URL}/orderdefaults/printerqueue`,
  pollingApp: `${API_BASE_URL}/pollingapp`,
  orderswithstatus: `${API_BASE_URL}/orderswithstatus`,
  getPostcodeDistrict: `${API_BASE_URL}/postcodedistrict`,
  getJSPMLicense_v4: `${LICENSE_API_BASE_URL}/license?version=4`,
  getJSPMLicense_v3: `${LICENSE_API_BASE_URL}/license/v3`,
  getCustomerTradingLocation: `${API_BASE_URL}/tradingLocations`,
  getAccounts: `${API_BASE_URL}/accounts/searchAccount`,
  getHostedPageUrl: `${API_BASE_URL}/hostedpage`,
  getCreateStoreUrl: `${API_BASE_URL}/Customer`,
  getMarketplaceDefaults: `${API_BASE_URL}/marketplacedefaultsandmapping`,
  getAdminMarketplaceMappings: `${API_BASE_URL}/marketplacedefaultsandmapping/dhl/marketplacemappings`,
  getMarketplacesList: `${API_BASE_URL}/marketplaces`,
  getWhat3Words: `${API_BASE_URL}/what3words`,
};
