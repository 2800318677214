import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import SearchOrders from './searchOrders';
import { getYupSchemaFromMetaData } from '../../../utils/yupSchema/yupSchemaGenerator';

const SearchProcessOrders = (props) => {
  const formField = searchForm;
  const newSearchValidationSchema = getYupSchemaFromMetaData(formField, [], []);

  const initialValues = {
    processorderSearchFor: '',
    processorderSearchBy: searchByOptions[0],
  };

  return (
    <div>
      <Formik initialValues={initialValues} enableReinitialize={true} validationSchema={newSearchValidationSchema} innerRef={props.formikRef}>
        {({ values, setFieldValue, errors, setFieldError, setErrors, setFieldTouched }) => (
          <Form className="Addessbook-Searchbar">
            <SearchOrders
              formField={formField}
              //   searchByOptions={ props.tabIndex === 0 ? searchByOptions : searchByFavouriteOptions }
              searchByOptions={
                props.tabIndex > 1
                  ? searchByOptions.filter((val) => val.value !== 'alternateReference')
                  : searchByOptions
              }
              formikValues={values}
              setErrors={setErrors}
              setFieldError={setFieldError}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              isFavourites={props.tabIndex}
              tabIndex={props.tabIndex}
              onProcessOrdersChange={props.onProcessOrdersChange}
              isScanFlag={props.isScanFlag}
              onProcessOrdersScanFalgChange={props.onProcessOrdersScanFalgChange}
              errors={errors}
              {...props}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

SearchProcessOrders.propTypes = {
  tabIndex: PropTypes.string,
  onProcessOrdersChange: PropTypes.func,
};

export default SearchProcessOrders;

const searchForm = {
  processorderSearchFor: {
    props: {
      name: 'processorderSearchFor',
      type: 'searchControl',
    },
    selectProps: {
      props: {
        name: 'processorderSearchBy',
        label: 'Search For',
        placeholder: "Enter Customer's Number",
      },
    },
    textProps: {
      props: {
        name: 'processorderSearchFor',
        label: 'Search For',
        placeholder: "Enter Customer's Number",
      },
    },
    validation: {
      name: 'processorderSearchFor',
      validationType: 'string',
      // validations: [
      //   {
      //     type: 'required',
      //     params: ['Search for is required'],
      //   },
      // ],
    },
  },
  favoriteSearchFor: {
    props: {
      name: 'favoriteSearchFor',
      type: 'searchControl',
    },
    selectProps: {
      props: {
        name: 'favoriteSearchBy',
        label: 'Search For',
        placeholder: "Enter Customer's Number",
      },
    },
    textProps: {
      props: {
        name: 'favoriteSearchFor',
        label: 'Search For',
        placeholder: "Enter Customer's Number",
      },
    },
    validation: {
      name: 'favoriteSearchFor',
      validationType: 'string',
      // validations: [
      //   {
      //     type: 'required',
      //     params: ['Search for is required'],
      //   },
      // ],
    },
  },
  isScan: {
    props: {
      name: 'isScan',
      label: 'Scanner',
      type: 'checkbox',
    },
    // value: false,
  },
  isScanPrint: {
    props: {
      name: 'isScanPrint',
      label: 'Scan & print',
      type: 'checkbox',
    },
    // value: false,
  },
};

const searchByOptions = [
  {
    value: 'customerReferenceNumber',
    name: 'Customer ref.',
  },
  {
    value: 'businessName',
    name: 'Customer/Business name',
  },
  {
    value: 'postalCode',
    name: 'Postal code',
  },
  {
    value: 'contact',
    name: 'Contact',
  },
  {
    value: 'alternateReference',
    name: 'Alternative reference',
  },
  {
    value: 'dispatchDate',
    name: 'Dispatch date',
  },
];
