import React from 'react';
import { Grid, Checkbox, FormControlLabel } from '@material-ui/core';
import InputButton from '../../framework/Inputs/inputButton/inputButton';
import CustomModal from '../../framework/dialog/customDialog';
import PropTypes from 'prop-types';

export default function ReprintLabel(props) {
  const {
    reprintTitle,
    reprintLabel,
    reprintCustomsInvoice,
    showPrintDialog
  } = props;
  return (
    <Grid container className="customModal-book">
      <CustomModal className="print-pop" open={showPrintDialog} title={reprintTitle} onClose={props.handleDialogClose}>
        <Grid container direction="column">
          <Grid item>
            <FormControlLabel
              control={<Checkbox checked={reprintLabel} onChange={props.handleChange} name="reprintLabel" />}
              label="Label"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={reprintCustomsInvoice}
                  onChange={props.handleChange}
                  name="reprintCustomsInvoice"
                />
              }
              label="Customs Invoice"
            />
          </Grid>
          <Grid>
            <span>Customs invoice will be printed only if applicable for a shipment</span>
          </Grid>
          <br />
          <Grid item xs={12} justify="flex-end" alignItems="flex-end">
            <InputButton
              variant="contained"
              disabled={!((reprintLabel || reprintCustomsInvoice))}
              className="contained-btn print-btn"
              buttonType="submit"
              label="Print"
              onClick={props.savePrintDialog}
            />
          </Grid>
        </Grid>
      </CustomModal>
    </Grid>
  )
}

ReprintLabel.propTypes = {
  reprintTitle: PropTypes.string,
  reprintLabel: PropTypes.bool,
  reprintCustomsInvoice: PropTypes.bool,
  showPrintDialog: PropTypes.func,
  handleDialogClose: PropTypes.func,
  handleChange: PropTypes.func,
  savePrintDialog: PropTypes.func,
};

