const OrderSetupForm = {
  formId: 'orderSetupForm',
  formField: {
    overWriteSameCustomerRef: {
      props: {
        name: 'overWriteSameCustomerRef',
        label: '',
        type: 'checkbox',
        labelPlacement: 'start',
      },
      value: false,
    },
    overWriteSameAltRef: {
      props: {
        name: 'overWriteSameAltRef',
        label: '',
        type: 'checkbox',
        labelPlacement: 'start',
      },
      value: false,
    },
    autoPostCollection: {
      props: {
        name: 'autoPostCollection',
        label: '',
        type: 'checkbox',
        labelPlacement: 'start',
      },
      value: false,
    },
    includeOrderDefaults: {
      props: {
        name: 'includeOrderDefaults',
        label: '',
        type: 'checkbox',
        labelPlacement: 'start',
      },
      value: false,
    },
    includeOrderMapping: {
      props: {
        name: 'includeOrderMapping',
        label: '',
        type: 'checkbox',
        labelPlacement: 'start',
      },
      value: false,
    },

    autoUpdateDomesticService: {
      props: {
        name: 'autoUpdateDomesticService',
        label: '',
        type: 'checkbox',
        labelPlacement: 'start',
      },
      value: false,
    },
    refreshOrderGridOnScanAndPrint: {
      props: {
        name: 'refreshOrderGridOnScanAndPrint',
        label: '',
        type: 'checkbox',
        labelPlacement: 'start',
      },
      value: false,
    },
    includeMarketplaceDefaults: {
      props: {
        name: 'includeMarketplaceDefaults',
        label: '',
        type: 'checkbox',
        labelPlacement: 'start',
      },
      value: false,
    },
    includeMarketplaceMapping: {
      props: {
        name: 'includeMarketplaceMapping',
        label: '',
        type: 'checkbox',
        labelPlacement: 'start',
      },
      value: false,
    },

    transferAction: {
      props: {
        name: 'transferAction',
        label: '',
        type: 'radio',
      },
      value: 'transferToHoldingArea',
    },

    pollingInterval: {
      props: {
        name: 'pollingInterval',
        label: 'Duration (in seconds)',
        type: 'text',
        inputProps: {
          min: 1,

        },
      },
      value: '10',
      validation: {
        name: 'pollingInterval',
        validationType: 'number',
        validations: [
          {
            type: 'min',
            params: [1, 'Polling interval cannot be less than 1 second'],
          },
          {
            type: 'required',
            params: ['Polling interval is required'],
          },
          {
            type: 'integer',
            params: ['Polling interval must be a natural number'],
          },
        ],
      },
    },

    shipmentConfirmation: {
      props: {
        name: 'shipmentConfirmation',
        label: '',
        type: 'checkbox',
        labelPlacement: 'start',
      },
      value: false,
    },

    outputExportDirectory: {
      props: {
        name: 'outputExportDirectory',
        label: 'Export shipment confirmation file to',
        type: 'text',
        placeholder: 'Enter folder path',
      },
      value: "",
      validation: {
        name: 'outputExportDirectory',
        validationType: 'string',
        validations: [
          {
            type: 'when',
            params: [
              ['shipmentConfirmation'],
              (shipmentConfirmation, schema) => {
                if (shipmentConfirmation) {
                  return schema.required('File output path is required');
                }
              }
            ]
          },
        ],
      },
    },
    manualShipmentConfirmation: {
      props: {
        name: 'manualShipmentConfirmation',
        label: '',
        type: 'checkbox',
        labelPlacement: 'start',
      },
      value: false,
    },
    delimiter: {
      props: {
        name: 'delimiter',
        label: 'Delimiter',
        type: 'select',
        displayField: 'name',
        hasDefaultValue: true,
      },
      validation: {
        validationType: 'object',
        validations: [
          {
            type: 'required',
            params: ['Delimiter is required'],
          }
        ],
      },
    },
    newDelimeter: {
      props: {
        name: 'newDelimeter',
        label: 'Enter the delimeter',
        type: 'text',
        placeholder: 'Enter',
        isRequired: true,
      },
      validation: {
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Delimiter is required'],
          },
        ],
      },
    },
    fileExtension: {
      props: {
        name: 'fileExtension',
        label: 'File Extension',
        type: 'text',
        isRequired: true,
        placeholder: "*.txt",
      },
      validation: {
        name: 'fileExtension',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['File extension is required'],
          },
          {
            type: 'matches',
            params: [/^[a-zA-z0-9*]*(\d*\.\d*)[a-zA-z]{1,5}$/, 'File extension must be in correct format e.g. *.txt or *.csv'],
          },
        ],
      },
    },
  },
};

export default OrderSetupForm;
