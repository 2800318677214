import { useEffect, React } from 'react';
import { Grid, Tab, Tabs, Box } from '@material-ui/core';
import SchemaMaterialTable from '../createNewSchema/SchemaMaterialTable';
import { sortBasedOnNestedOrNot, setDropdownValues } from '../../../../utils/helperFunctions';
import FormRenderer from '../../../../framework/Inputs/formRenderer/formRenderer';

const OutputFile = (props) => {
    const { dragFromValues, dragToValues, setDragToValues, setDragFromValues, formField, formikValues, setFieldValue } = props;

    const leftSideColumns = [
        {
            title: 'Available fields', field: 'name', render: (row) => <div>
                {row.name} {row.isRequiredField ? <span style={{ color: "red" }}>**</span> : ''}
            </div>
        }
    ];

    const rightSideColumns = [
        {
            title: 'Selected fields', field: 'name', render: (row) => <div>
                {row.name} {row.isRequiredField ? <span style={{ color: "red" }}>**</span> : ''}
            </div>
        }
    ];

    const removeFromFields = (selecteddata) => {
        const toValues = [...dragToValues];
        const fromValues = [...dragFromValues];

        const itemsToBeDeleted = selecteddata.map((address) => {
            toValues.push(address);
            setDragToValues(toValues);
            const filteredValue = fromValues.indexOf(address);
            fromValues.splice(filteredValue, 1);

        });
        setDragFromValues(sortBasedOnNestedOrNot(fromValues, "name"));
    };

    const removeFromImportFiles = (selecteddata) => {
        const fromValues = [...dragFromValues];

        const toValues = [...dragToValues];
        const itemsToBeDeleted = selecteddata.map((address) => {
            fromValues.push(address);
            setDragFromValues(sortBasedOnNestedOrNot(fromValues, "name"));
            const filteredValue = toValues.indexOf(address);
            toValues.splice(filteredValue, 1);
        });
        setDragToValues(toValues);
    };

    useEffect(() => {
        setDragFromValues(sortBasedOnNestedOrNot(props.outputFileValues, "name"));
        setDragToValues(props.selectedOutputFileValues);
        dragFromValues.map((row) => {
          row.tableData.checked = false
        });
        dragToValues.map((row) => {
            row.tableData.checked = false
          });
        setFieldValue('manualShipmentConfirmation', false);
    }, [])

    useEffect(() => {
        if (formikValues.manualShipmentConfirmation === false) {
            setFieldValue('fileExtension', '');
            setFieldValue('delimiter', setDropdownValues(delimiterOptions, 'value', delimiterOptions[0].value));
            setFieldValue('newDelimeter', '');
        }
        if (formikValues.delimiter?.value === 'otherDelimiter') {
            setFieldValue('newDelimeter', '');
        }
    }, [formikValues.manualShipmentConfirmation, formikValues.delimiter])

    return (
        <Grid container direction="column" spacing={1}>

            <Grid container xs= {12} spacing={2} direction="row" justify={'space-between'} style={{ paddingBottom: '25px', paddingTop: '20px' }}>
                <Grid container xs={5} alignItems="center" justify={'space-between'}>
                    <Grid item xs={10} sm={10} md={10} lg={10} style={{paddingLeft:'10px'}}>Produce shipment confirmation for manually created shipments</Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2} >
                        <FormRenderer {...formField.manualShipmentConfirmation.props} />
                    </Grid>
                </Grid>
                <Grid container alignItems="start" direction="row" xs={6} justify={'flex-start'} spacing={1} style={{paddingLeft:'61px'}}>
                    {formikValues.manualShipmentConfirmation ? <>
                        <Grid item xs={4}> <FormRenderer {...formField.fileExtension.props} style={{ maxWidth: '130px' }} /></Grid>
                        <Grid item xs={4}> <FormRenderer {...formField.delimiter.props} data={delimiterOptions} style={{ width: '125px' }} /></Grid>
                        <Grid item xs={4}> {formikValues.delimiter?.value === 'otherDelimiter' ? <FormRenderer {...formField.newDelimeter.props} style={{ minWidth: '180px' }} /> : ''}</Grid>
                    </> : ''}
                </Grid>
                <Grid xs={1}></Grid>
            </Grid>

            <Grid item >
                <Tabs value={0}>
                    <Tab label={"Output file to contain"} />
                </Tabs>
                <SchemaMaterialTable
                    dragToValues={dragToValues}
                    dragFromValues={dragFromValues}
                    leftSideColumns={leftSideColumns}
                    rightSideColumns={rightSideColumns}
                    removeFromfields={removeFromFields}
                    removeFromImportFiles={removeFromImportFiles}
                    isOutputFile={true}
                />
            </Grid>
        </Grid>
    );
};

export default OutputFile;

export const delimiterOptions = [
    {
        value: ',',
        name: 'Comma',
    },
    {
        value: '~',
        name: 'Tilde',
    },
    {
        value: '|',
        name: 'Pipe',
    },
    {
        value: '\\t',
        name: 'Tab',
    },
    {
        value: ';',
        name: 'Semicolon',
    },
    {
        value: 'otherDelimiter',
        name: 'Other',
    },
];

