import React, { useEffect, useState } from 'react';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { AppBar } from '@material-ui/core';

import TabPanel from '../../../shared/tabPanel';

import VerticalLinearStepper from '../../../../framework/VerticalLinearStepper/VerticalLinearStepper';
import { useSelector } from 'react-redux';

function TrackingDetails({ shipmentDetails, trackingStatus }) {
  const [value, setValue] = useState(0);
  const [event, setEvent] = useState([]);
  const [shipmentTracking, setShipmentTracking] = useState([]);
  const [deliveryTracking, setDeliveryTracking] = useState([]);

  const selectedEventDetails = useSelector((state) => state.selectedEvent.selectedEvent);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getTrackingDetails = () => {
    // const rowEventDetails = [];
    const rawShipmentDetails = [];
    const rawDeliveryDetails = [];

    (shipmentDetails.shipmentEventModels || []).forEach((eventdetails) => {
      if (
        eventdetails.shipmentEvent.shipHarmonisedEvents != null &&
        eventdetails.shipmentEvent.shipHarmonisedEvents.shipmentTrackingdetails === 'Shipment tracking details'
      ) {
        rawShipmentDetails.push(eventdetails);
        if (selectedEventDetails === eventdetails.shipmentEvent.code) {
          setValue(0);
        }
      } else if (
        eventdetails.shipmentEvent.shipHarmonisedEvents != null &&
        eventdetails.shipmentEvent.shipHarmonisedEvents.shipmentTrackingdetails === 'Delivery History'
      ) {
        rawDeliveryDetails.push(eventdetails);

        if (selectedEventDetails === eventdetails.shipmentEvent.code) {
          setValue(1);
        }
      }
    });
    // setEvent(rowEventDetails)
    setShipmentTracking(rawShipmentDetails);
    setDeliveryTracking(rawDeliveryDetails);
  };
  useEffect(() => {
    getTrackingDetails();
  }, [shipmentDetails, selectedEventDetails]);

  return (
    <div>
      <AppBar position="static" style={{ boxShadow: 'none', backgroundColor: '#fff', color: '#000' }}>
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Shipment tracking details" style={{ textTransform: 'inherit' }} />
          <Tab label="Delivery history" style={{ textTransform: 'inherit' }} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <VerticalLinearStepper isDelveryHistory={false} trackingDetails={shipmentTracking} trackingStatus={trackingStatus} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <VerticalLinearStepper isDelveryHistory={true} trackingDetails={deliveryTracking} trackingStatus={trackingStatus} showPopup={true} />
      </TabPanel>
    </div>
  );
}

export default TrackingDetails;
