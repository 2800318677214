import React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Form, Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import CustomAccordion from '../../../framework/accordion/customAccordion';
import InputButton from '../../../framework/Inputs/inputButton/inputButton';
import { genralConfigurationForm } from '../../../utils/formConfig/genralconfiguration/genralConfiguratiomForm';
import FormRenderer from '../../../framework/Inputs/formRenderer/formRenderer';
import { POST, GET } from '../../../api/axios';
import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';
import GeneralCoonfig from './generalConfigurationDetails/generalConfigurationDetails';
import { getCustomerAccountID } from '../../../utils/helperFunctions';
import CustomModal from '../../../framework/modal/modal';
import ConfirmationDialog from '../../../components/addressBook/confirmation/confirmation';
import * as actions from '../../../store/actions/index';
import './GenralConfiguration.scss';

class GenralConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialFormValues: null,
      showCancelConfirmationDialog:false,
      confirmationMsg:'',
    };
  }

  setShowCancelConfirmationDialog(showmodel) {
    this.setState({ showCancelConfirmationDialog: showmodel });
}
setConfirmationMsg(Msg){
  this.setState({ confirmationMsg: Msg });

}
handleDialogClose = () => {
  this.setShowCancelConfirmationDialog(false);
};
 onDialogCancel = () => {
  this.setShowCancelConfirmationDialog(false);
};

 onCancelDialogConfirm = () => {
  // history.goBack();
  this.props.history.push(`/configuration`);
  this.setShowCancelConfirmationDialog(false);
};

  componentDidMount() {
    this.getInitialValues();
    this.props.setLoader(true);
  }

  getInitialValues = () => {
    GET(`${ServiceEndPoints.genralConfiguration}?organization=${getCustomerAccountID()}`)
      .then((response) => {
        this.setState({
          initialFormValues: response.data,
        });
        this.props.setLoader(false);
      })
      .catch((err) => {
        this.props.setLoader(false);
        this.setEmptyValues();
      });
  };

  setEmptyValues = () => {
    const initialValues = {};
    const { formField } = genralConfigurationForm;
    for (let property in formField) {
      if (Object.prototype.hasOwnProperty.call(formField, property)) {
        const field = formField[property];
        if (field.props.type === 'checkbox' || field.props.type === 'radio') {
          initialValues[field.props.name] = field.value;
        } else if (field.props.type === 'array') {
          initialValues[field.props.name] = field.values;
        } else {
          initialValues[field.props.name] = field.value || '';
        }
      }
    }
    this.setState({ initialFormValues: initialValues });
  };

  handleFormSubmit = (values, errors) => {
    //Todo set organization.
    let payload = {
      id: '',
      type: '',
      organization: getCustomerAccountID(),
      ...values,
    };
    POST(ServiceEndPoints.genralConfiguration, payload)
      .then((response) => {
        this.props.setSnackBar({msg:"General configuration saved successfully"});
        this.props.saveGenralConfiguration(payload);
      })
      .catch((err) => {
        alert('Failed to save..');
      });
  };

  cancelSchema = () => {
    this.setConfirmationMsg('Any unsaved changes will be lost?');
    this.setShowCancelConfirmationDialog(true);
  };

  render() {
    const { formId, formField, value } = genralConfigurationForm;
    const { initialFormValues } = this.state;
    return (
      <React.Fragment>
        {initialFormValues ? (
          <Formik initialValues={initialFormValues}>
            {({ values, errors }) => {
              return (
                <Form id={formId}>
                  <CustomAccordion heading="Customise reference settings">
                    <Grid container direction="row">
                      <Grid container item spacing={3}>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                          <FormRenderer {...formField.customerReference.props} fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                          <FormRenderer {...formField.alternativeReference.props} fullWidth />
                        </Grid>
                      </Grid>
                      <Grid container item spacing={3}>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                          <FormRenderer {...formField.duplicateCustomerReference.props} fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                          <FormRenderer {...formField.duplicateAlternativeReference.props} fullWidth />
                        </Grid>
                      </Grid>
                    </Grid>
                  </CustomAccordion>
                  <CustomAccordion heading="Reference name settings">
                    <Grid container direction="row">
                      <Grid container item spacing={3}>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <FormRenderer {...formField.customerReferenceText.props} fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <FormRenderer {...formField.alternativeReferenceText.props} fullWidth />
                        </Grid>
                      </Grid>
                    </Grid>
                  </CustomAccordion>
                  <CustomAccordion heading="Assign new account to users">
                    <Grid container direction="row">
                      <Grid container spacing={3} item>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <GeneralCoonfig formField={formField} formikValues={values} errors={errors} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </CustomAccordion>
                  <CustomAccordion heading="Customs invoice settings">
                    <Grid container direction="row">
                      <Grid container item spacing={3}>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <FormRenderer {...formField.printCustomInvoice.props} fullWidth />
                        </Grid>
                      </Grid>
                    </Grid>
                  </CustomAccordion>
                  <CustomAccordion heading="Return label settings">
                    <Grid container direction="row">
                      <Grid container item spacing={3}>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <FormRenderer {...formField.defaultPrintInboxReturnLabel.props} fullWidth />
                        </Grid>
                      </Grid>
                    </Grid>
                  </CustomAccordion>
             
                  <Grid container className="btn-container" spacing={3}>
                    <Grid container item spacing={3} direction="row" justify="flex-end" alignItems="flex-end">
                      <Grid item>
                        <InputButton
                          variant="text"
                          className="text-btn"
                          label="Cancel"
                          // component={RouterLink}
                          // to="/configuration" 
                          onClick={() => this.cancelSchema()} 
                        />
                           <Grid container item className="customModal-book">
                  <CustomModal open={this.state.showCancelConfirmationDialog} title="" onClose={this.handleDialogClose} maxWidth="sm">
                    <ConfirmationDialog
                      confirmationMessage={this.state.confirmationMsg}
                      onConfirm={this.onCancelDialogConfirm}
                      OnCancel={this.onDialogCancel}
                    />
                  </CustomModal>
                </Grid>
                      </Grid>
                      <Grid item>
                        <InputButton
                          variant="contained"
                          className="contained-btn"
                          onClick={() => this.handleFormSubmit(values, errors)}
                          buttonType="submit"
                          label="Save"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        ) : null}{' '}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveGenralConfiguration: (genralConfiguration) => {
      dispatch(actions.setGenralConfiguration(genralConfiguration));
    },
    setUserNotification: (alert) => {
      dispatch(actions.setUserNotification(alert));
    },
    setLoader: (isActive) => {
      dispatch(actions.setLoaderLayOver(isActive));
    },
    setSnackBar:(data)=>{
      dispatch(actions.setAppSnackBarData(data));
    }
  };
};
export default connect(null, mapDispatchToProps)(GenralConfiguration);
