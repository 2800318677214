export const  FaqValidatorForm = {
    formId: 'FaqValidatorForm',
    formField: {
        catagory: {
        props: {
          name: 'catagory',
          label: 'Catagory',
          type: 'text',
          // displayField: 'name',
          // hasDefaultValue: true,
          isRequired: true,
        },
        validation: {
          name: 'catagory',
          validationType: 'string',
          validations: [
            {
              type: 'required',
              params: ['Catagory is required'],
            },
          ],
        },
      },
      faqCatagory: {
        props: {
          name: 'faqCatagory',
          label: 'Catagory',
          type: 'select',
          displayField: 'name',
          // hasDefaultValue: true,
          // isRequired: true,
        },
        // validation: {
        //   name: 'faqCatagory',
        //   validationType: 'object',
        //   validations: [
        //     {
        //       type: 'required',
        //       params: ['Catagory is required'],
        //     },
        //   ],
        // },
      },
      addQuestion: {
        props: {
          name: 'addQuestion',
          label: 'Add question',
          type: 'richText',
          isRequired: true,
        },
        validation: {
            name: 'addQuestion',
            validationType: 'string',
            validations: [
              {
                type: 'min',
                params: [10, 'Question is required'],
              },
            ],
          },
      },
      addAnswer: {
        props: {
          name: 'addAnswer',
          label: 'Answer',
          type: 'richText',
          isRequired: true,
        },
        validation: {
            name: 'addAnswer',
            validationType: 'string',
            validations: [
              {
                type: 'min',
                params: [10, 'Answer is required'],
              },
            ],
          },
      },
    }
  };
  

