import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { FieldArray } from 'formik';
import { ReactComponent as DeleteIcon } from '../../../assets/images/DeleteIcon.svg';

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  SvgIcon,
} from '@material-ui/core';
import AddDimension from './AddDimension';
import InputButton from '../../../framework/Inputs/inputButton/inputButton';
import './AddDomesticDimension.scss';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    border: '1px solid #B5B5B5',
  },
});

const AddDomesticDimension = (props) => {
  const {
    saveDimensions,
    isDomestic,
    formikValues,
    setFieldValue,
    resetLengthWidthHeight,
    setOldParcelDetails,
    errors
  } = props;

  const [parcelDetails, setParcelDetails] = useState([]);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);
  const { length, width, height, weight, pieceWeight, totalVolumetricWeight, totalpieceWeight } = formikValues;

  const classes = useStyles();
  let oldParcelDetails = [];

  useEffect(() => {
    const { errors } = props;
    if (isDomestic && (formikValues.parcelDetails || []).length < formikValues.noOfItems) {
      if (
        length !== '' &&
        width !== '' &&
        height !== '' &&
        pieceWeight !== '' &&
        length > 0 &&
        length <= 200 &&
        width > 0 &&
        width <= 120 &&
        height > 0 &&
        height <= 120 &&
        pieceWeight > 0 &&
        errors.length === undefined &&
        errors.width === undefined &&
        errors.height === undefined &&
        errors.pieceWeight === undefined
      ) {
        setIsAddButtonDisabled(false);
      } else {
        setIsAddButtonDisabled(true);
      }
    } else {
      setIsAddButtonDisabled(true);
    }
  }, [length, width, height, weight, pieceWeight, isDomestic, parcelDetails, errors.length, errors.width, errors.height, errors.pieceWeight]);

  const pushToParcelDetails = (arrayHelpers) => {
    let volumetricWeightDivisor = formikValues?.accountNumber?.configuration?.volumetricWeightDivisor || 4000;
    const rowDetails = {
      id: formikValues.parcelDetails.length + 1,
      length,
      width,
      height,
       //pieceWeight: parseFloat(pieceWeight).toFixed(2) changes made by vibhaas on 8-11-2023 to make the decimal pointer upto 3 */
       pieceWeight:Math.floor(pieceWeight*100)/100,
      volumetricWeight: parseFloat(parseFloat((length * width * height) / volumetricWeightDivisor).toFixed(2)),
    };
    // parcelDetails.push(rowDetails);
    setParcelDetails((parcelDetails) => {
      const newParcelDetails = [...parcelDetails];
      newParcelDetails.push(rowDetails);

      return newParcelDetails;
    });
    arrayHelpers.push(rowDetails);
    if (formikValues.noOfItems == arrayHelpers.length) {
      setIsAddButtonDisabled(true);
    }
    resetLengthWidthHeight();
  };

  const resetParcelDetails = () => {
    setFieldValue('parcelDetails', [], false);
    setParcelDetails([]);
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    oldParcelDetails = [...formikValues.parcelDetails];
    setOldParcelDetails(oldParcelDetails);
  }, []);

  return (
    <Grid>
      <FieldArray
        name="parcelDetails"
        render={(arrayHelpers) => {
          const parcelDetails = formikValues.parcelDetails;
          return (
            <Grid container spacing={2} className="shipment">
              <Grid item xs={12} sm={12} md={9} lg={9} style={{ marginTop: '-13px' }}>
                <div className="parcelDimensionsHeading">
                  <strong>Add parcel dimensions</strong>
                </div>
              </Grid>
              <Grid container item>
                <Grid item xs={12} sm={12} md={9} lg={9}>
                  <AddDimension isRequired={false} isDomestic={true} setFieldValue={setFieldValue} />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <InputButton
                    variant="outlined"
                    className="outlined-btn add-btn"
                    onClick={() => pushToParcelDetails(arrayHelpers)}
                    buttonType="submit"
                    label="Add"
                    isDisabled={isAddButtonDisabled}
                  />
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TableContainer component={Paper} style={{ maxHeight: 300}}>
                    <Table stickyHeader className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <b>Parcel no.</b>
                          </TableCell>
                          <TableCell align="left">
                            <b>Length (cm)</b>
                          </TableCell>
                          <TableCell align="left">
                            <b>Width&nbsp;(cm)</b>
                          </TableCell>
                          <TableCell align="left">
                            <b>Height&nbsp;(cm)</b>
                          </TableCell>
                          <TableCell align="left">
                            <b>Volumetric wt.&nbsp;(kg)</b>
                          </TableCell>
                          <TableCell align="left">
                            <b>Weight.&nbsp;(kg)</b>
                          </TableCell>
                          <TableCell align="left"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(parcelDetails || []).map((row, index) => (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              {parseInt(index + 1)}
                            </TableCell>
                            <TableCell align="left">{row.length}</TableCell>
                            <TableCell align="left">{row.width} </TableCell>
                            <TableCell align="left">{row.height}</TableCell>
                            <TableCell align="left">{row.volumetricWeight}</TableCell>
                            <TableCell align="left">{row.pieceWeight}</TableCell>
                            <TableCell align="right">
                              <IconButton onClick={() => arrayHelpers.remove(index)}>
                                <SvgIcon component={DeleteIcon} viewBox="5 5 30 30" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow className="footerRow">
                          <TableCell align="left">Volumetric wt.(kg)</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">{totalVolumetricWeight}</TableCell>
                          <TableCell align="left">{totalpieceWeight}</TableCell>
                          <TableCell align="left"></TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
              <Grid container justify="flex-end" spacing={2} className="addParcel-popup-buttons">
                <Grid item>
                  <InputButton
                    variant="outlined"
                    className="outlined-btn submit"
                    onClick={resetParcelDetails}
                    buttonType="submit"
                    label="Reset"
                  />
                </Grid>
                <Grid item>
                  <InputButton
                    variant="contained"
                    className="contained-btn save"
                    onClick={saveDimensions}
                    buttonType="submit"
                    label="Save"
                  />
                </Grid>
              </Grid>
            </Grid>
          );
        }}
      />
    </Grid>
  );
};

AddDomesticDimension.propTypes = {
  saveDimensions: PropTypes.func.isRequired,
  formikValues: PropTypes.object,
  isDomestic: PropTypes.bool,
  setFieldValue: PropTypes.func,
  resetLengthWidthHeight: PropTypes.func,
  setOldParcelDetails: PropTypes.func,
};

export default AddDomesticDimension;
