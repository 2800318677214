/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import { Typography, Grid } from '@material-ui/core';
import './stepper.scss';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
}));

const useQontoStepIconStyles = makeStyles({
  root: {

    display: 'flex',

    alignItems: 'center',
  },
  circle: {
    backgroundColor: 'white',
    zIndex: 1,
    fontSize: 16,
    width: 8,
    height: 8,
    borderRadius: '50%',
    width: '32px',
    height: '32px',
    border: '1px solid #00000073'

  },
  active: {
    backgroundColor: 'white',
    zIndex: 1,
    fontSize: 16,
    width: 8,
    height: 8,
    borderRadius: '50%',
    width: '32px',
    height: '32px',
    border: '3px solid #000000',

  },

  completed: {
    backgroundColor: '#76BD22',
    border: '1px solid #007C39',
    zIndex: 1,
    fontSize: 16,
    width: 8,
    height: 8,
    borderRadius: '50%',
    width: '32px',
    height: '32px',
    color: 'black',
  },
});


function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;
 
  if (completed) {
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })} >

        <div className={classes.completed}><text style={{ marginLeft: '9px' }}>{props.icon}</text> </div>
      </div>
    );
  } else if (active) {
    return (
      <div
      >
        <div className={classes.active}><text style={{ marginLeft: '7px' }}>{props.icon}</text> </div>
      </div>
    )
  } else {
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })} >
        <div className={classes.circle}><text text style={{ marginLeft: '9px', opacity: 0.3 }}>{props.icon}</text> </div>
      </div>
    )
  }
}
QontoStepIcon.propTypes = {

  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,

  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};


export default function CustomizedSteppers(props) {
  const classes = useStyles();
  const steps = props.steps;
  return (
    <div className="stepper-container">
      <Grid className={classes.root}>
        <Stepper alternativeLabel activeStep={props.activeStep} className="stepper-root">
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
    </div>
  );
}
