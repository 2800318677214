import React, { useState } from 'react';
import { Grid, Checkbox, FormControlLabel } from '@material-ui/core';
import DynamicMaterialTable from '../../framework/DynamicMaterialTable/DynamicMaterialTable';
import InputButton from '../../framework/Inputs/inputButton/inputButton';
import ConfirmationDialog from '../addressBook/confirmation/confirmation';
import CustomModal from '../../framework/dialog/customDialog';

import { Form, Formik } from 'formik';
import { axiosConfig, GET } from '../../api/axios';
import { useDispatch, useSelector } from 'react-redux';
import TableExport from '../../../src/components/TableExport/TableExport';
import TableExportPopup from '../../../src/components/TableExport/TableExportPopup';
import { ServiceEndPoints } from '../../utils/constants/ApiConstant';
import * as actions from '../../store/actions/index';
import jsPDF from 'jspdf';
import { arrayToString, formatDate} from '../../utils/helperFunctions';
import { useHistory } from 'react-router-dom';


export default function MyShipmentManagement(props) {
  const history = useHistory();

  const {
    rows,
    columns,
    isSearchable,
    isSelectable,
    sorting,
    paging,
    pageSize,
    pageSizeOptions,
    Dialogtitle,
    confirmationMessage,
    reprintTitle,
    totalCount
  } = props;

  const initialValues = {
    exportTo: ''
  };

  const headers = [
    { label: 'Customer reference', key: 'customerReferenceNumber' },
    { label: "Alternative reference", key: "alternativeReference" },
    { label: 'Shipment number', key: 'Shipmentnumber' },
    { label: 'Return shipment number', key: 'returnShipmentnumber' },
    { label: 'Collection date', key: 'collectionDate' },
    { label: 'Business/Recipient name', key: 'recipientName' },
    { label: 'Postal code', key: 'destinationPostalCode' },
    { label: 'Number of items', key: 'numberOfParcels' },
    { label: 'Weight', key: 'weight' },
    { label: 'Product', key: 'product' },
    { label: 'Service', key: 'service' }

  ];

  const userAccounts = useSelector((state) => state.accounts.userAccounts);
  const [pageNumber, setPageNumber] = useState(0);
  const [tableHeaderColumns, setTableHeaderColumns] = useState(headers);
  const [ExporttableRawData, setExportTableRawData] = useState([]);
  const dispatch = useDispatch();
  const isBookedCollections = props.tabIndex === 1;

  const [selectedRows, setSelectedRows] = useState([]);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showPrintDialog, setshowPrintDialog] = useState(false);
  const [showExportDialog, setshowExportDialog] = useState(false);
  const [sortByOrder, setSortByOrder] = useState('');
  const [state, setState] = useState({
    reprintLabel: false,
    reprintCustomsInvoice: false,
  });


  const onDataUpdate = async () => {
    dispatch(actions.setLoaderLayOver(true));

    let url = `${ServiceEndPoints.getShipmentManagement}isCollectionRequest=${isBookedCollections}`;
    const offset = pageNumber * pageSize;
    await GET(getUrl(url, offset))
      .then((res) => {
        let ExportData = [];
        res.data.shipmentModels.forEach((data) => {
          ExportData.push({
            customerReferenceNumber: data?.customerReferenceNumber,
            alternativeReference: data?.shipments?.[0]?.shipmentDetails?.customerRef2,
            Shipmentnumber: data?.shipmentId,
            returnShipmentnumber: data?.shipments?.[0]?.return?.returnShipmentId,
            collectionDate: formatDate(data?.collectionDate, '/', 'dd/mm/yyyy'),
            recipientName: data?.businessName != '' ? data?.businessName : data?.receiverName,
            destinationPostalCode: data?.destinationPostalCode,
            numberOfParcels: data?.numberOfParcels,
            weight: data?.shipments?.[0]?.shipmentDetails?.totalWeight ?? "",
            product: data?.product?.productNameForUI ?? "",
            service: data?.service?.description ?? ""
          });
        });
        setExportTableRawData(ExportData);
        dispatch(actions.setAppSnackBarData({ msg: `Data exported successfully` }));
        setshowExportDialog(false);
      })
      .catch((err) => {
        console.log(err);
        setExportTableRawData([]);
        dispatch(
          actions.setBottomAlert({
            msgs: [
              {
                type: 'error',
                msg: 'Error in exporting data, please try again later',
              },
            ],
          }),
        );
        setshowExportDialog(false);
      })
      .finally(() => {
        dispatch(actions.setLoaderLayOver(false));
        setshowExportDialog(false);
      });
  };

  const getUrl = (url, offset) => {

    url += `&${arrayToString(userAccounts, 'accounts', 'accountId')}&status=Order received`;

    url += props.shipmentSearchParams;
    url += `&offset=${offset}`;
    if (sortByOrder) {
      url += sortByOrder;
    }
    return url;
  }


  const printDocument = async () => {
    try {
      dispatch(actions.setLoaderLayOver(true));
      const url = `${ServiceEndPoints.getShipmentManagement}isCollectionRequest=${isBookedCollections}`;
      const offset = pageNumber * pageSize;
      await axiosConfig.get(getUrl(url, offset)).then(async (res) => {

        let data = res.data?.shipmentModels ?? [];
        const unit = 'pt';
        const size = 'A4'; // Use A1, A2, A3 or A4
        const orientation = 'landscape'; // portrait or landscape
        const doc = new jsPDF(orientation, unit, size);
        const title = 'My Updated Awesome Report';
        const updatedHeaderColumns = [...headers];

        data = data.map((elt) => [
          elt.customerReferenceNumber,
          elt.shipments?.[0]?.shipmentDetails?.customerRef2,
          elt.shipmentId,
          elt.shipments?.[0]?.return?.returnShipmentId,
          formatDate(elt.collectionDate, '/', 'dd/mm/yyyy'),
          elt.businessName != '' ? elt?.businessName : elt?.receiverName,
          elt.destinationPostalCode,
          elt.numberOfParcels,
          elt.shipments?.[0]?.shipmentDetails?.totalWeight,
          elt?.product?.productNameForUI ?? "",
          elt?.service?.description ?? ""
        ]);

        const _headers = [updatedHeaderColumns.map((headers) => headers.label)];

        let content = {
          head: _headers,
          body: data,
          columnStyles: {
            0: {
              columnWidth: 100
            },
            1: {
              columnWidth: 80,
            },
            5: {
              columnWidth: 100,
            },
            6: {
              columnWidth: 40,
            },
            7: {
              columnWidth: 30,
            }
          },
          styles: {
            cellWidth: 'wrap',
            fontSize: 6,
            cellPadding: 2,
            overflowColumns: 'linebreak',
          },
        };
        doc.autoTable(content);
        doc.save('BookedShipments.pdf');
        dispatch(actions.setAppSnackBarData({ msg: `Data exported successfully` }));
        setshowExportDialog(false);
      });
    } catch (err) {
      console.log(err);
      dispatch(
        actions.setBottomAlert({
          msgs: [
            {
              type: 'error',
              msg: 'Error in exporting data, please try again later',
            },
          ],
        }),
      );
    } finally {
      dispatch(actions.setLoaderLayOver(false));
    }
  };


  const addSelectedRows = (selectedRows) => {
    setSelectedRows(selectedRows);
  };
  const handleDialogClose = () => {
    setShowConfirmationDialog(false);
    setshowPrintDialog(false);
    setshowExportDialog(false);
    resetReprintState();
  };
  const onDialogConfirm = () => {
    props.cancelSelectedShipment(selectedRows);
    setShowConfirmationDialog(false);
    setSelectedRows([]);
  };
  const onDialogCancel = () => {
    setShowConfirmationDialog(false);
    setshowPrintDialog(false);
    resetReprintState();
  };
  const savePrintDialog = () => {
    setshowPrintDialog(false);
    const values = { reprintLabel: state.reprintLabel, reprintCustomsInvoice: state.reprintCustomsInvoice };
    props.handlePrintSubmit(values, selectedRows);
    resetReprintState();
  };
  const resetReprintState = () => {
    setState({ ...state, reprintLabel: false, reprintCustomsInvoice: false });
  };
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const goToAmmendPage = () => {
    history.push(`/shipment/addShipment/${selectedRows[0].id}`)
  }

  return (
    <Grid container justify="flex-end" alignItems="flex-end" className="shipment-Management-table">
      <Grid item lg={12} md={12} xs={12} className="Table-column-color shipment-Management-child-table">
        <DynamicMaterialTable
          rows={rows}
          columns={columns}
          isSelectable={isSelectable}
          isSearchable={isSearchable}
          sorting={sorting}
          paging={paging}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
          onSelectionChange={addSelectedRows}
          {...props}
        />
      </Grid>

      <Grid container className="customModal-book">
        <CustomModal open={showConfirmationDialog} title={Dialogtitle} onClose={handleDialogClose} maxWidth="sm">
          <ConfirmationDialog
            confirmationMessage={confirmationMessage}
            onConfirm={onDialogConfirm}
            OnCancel={onDialogCancel}
          />
        </CustomModal>
      </Grid>
      <Grid container className="customModal-book">
        <CustomModal className="print-pop" open={showPrintDialog} title={reprintTitle} onClose={handleDialogClose}>
          <Grid container direction="column">
            <Grid item>
              <FormControlLabel
                control={<Checkbox checked={state.reprintLabel} onChange={handleChange} name="reprintLabel" />}
                label="Label"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.reprintCustomsInvoice}
                    onChange={handleChange}
                    name="reprintCustomsInvoice"
                  />
                }
                label="Customs Invoice"
              />
            </Grid>
            <Grid>
              <span>Customs invoice will be printed only if applicable for a shipment</span>
            </Grid>
            <br />
            <Grid item xs={12} justify="flex-end" alignItems="flex-end">
              <InputButton
                variant="contained"
                disabled={!((state.reprintLabel || state.reprintCustomsInvoice))}
                className="contained-btn print-btn"
                buttonType="submit"
                label="Print"
                onClick={savePrintDialog}
              />
            </Grid>
          </Grid>
        </CustomModal>
      </Grid>
      <Grid>
        <TableExportPopup
          myshipment={true}
          headers={headers}
          data={ExporttableRawData}
          filename={'BookedShipments.csv'}
          onDataUpdate={onDataUpdate}
          onClick={printDocument}
          disableExportButton={totalCount === 0 ? true : false}
          setSortByOrder={setSortByOrder}
          showExportDialog={showExportDialog}
          ExportTitle={"Export"}
          handleDialogClose={handleDialogClose}
        />
      </Grid>
      <Grid className="btn-container" item container direction='row' spacing={2} >
        <Grid item container spacing={2} direction="column" className="dashboard-details-container" xs={6} justify='flex-start'>
          {/* <Formik initialValues={initialValues}>
            {({ values, setFieldValue }) => {
              return (
                <Form>
                  <Grid item justify="space-between" xs={12}>
                    <TableExport
                      myshipment={true}
                      headers={tableHeaderColumns}
                      data={ExporttableRawData}
                      filename={'BookedShipments.csv'}
                      onDataUpdate={onDataUpdate}
                      setFieldValue={setFieldValue}
                      formikValues={values}
                      onClick={printDocument}
                      disableExportButton={totalCount === 0 ? true : false}
                      setSortByOrder={setSortByOrder}
                    />
                  </Grid>
                </Form>
              );
            }}
          </Formik> */}
          <Grid item xs={3} className="btn-container" style={{ maxWidth: '100px' }}>
            <InputButton
              className={`outlined-btn`}
              label="Export"
              variant="outlined"
              onClick={() => setshowExportDialog(true)}
              isDisabled={totalCount === 0 ? true : false}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2} justify='flex-end' xs={6}>
          <Grid item >
            <InputButton
              className="outlined-btn Delete-AddressBook"
              isDisabled={!(selectedRows.length === 1 && selectedRows[0].isDomestic && !(selectedRows[0]?.exchangeOnDelivery))}
              label="Amend shipment"
              variant="outlined"
              onClick={() => { goToAmmendPage() }}
            />
          </Grid>

          <Grid item className="AddressBook-Delete">
            <InputButton
              className="outlined-btn Delete-AddressBook"
              isDisabled={selectedRows.length === 0}
              label="Cancel shipment"
              variant="outlined"
              onClick={() => setShowConfirmationDialog(true)}
            />
          </Grid>
          <Grid item>
            <InputButton
              className="Re-print-btn outlined-btn"
              isDisabled={selectedRows.length === 0}
              variant="outlined"
              label="Reprint"
              onClick={() => setshowPrintDialog(true)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid >
  );
}
