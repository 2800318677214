import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import InputButton from '../../../framework/Inputs/inputButton/inputButton';
import DividerWithText from '../../../framework/dividerWithText/dividerWithText';

import './addressBookModal.scss';

const useStyles = makeStyles({
  dialog: {},
  Paper: {
    maxWidth: 1000,
  },
});

const CustomDialog = (props) => {
  const classes = useStyles();
  const { onClose, selectedValue, open, title, maxWidth, onAddNewButtonClick, totalRecords } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };
  return (
    <Dialog
      // onClose={handleClose}
      fullWidth={true}
      maxWidth={maxWidth}
      aria-labelledby="simple-dialog-title"
      open={open}
      BackdropProps={{ style: { backgroundColor: '#FFFFFF 0% 0% no-repeat padding-box', opacity: 0.95 } }}
      className="addressBookModalContainer"
    >
      <DialogTitle id="simple-dialog-title">
        <Grid container spacing={3} direction="row" justify="space-between" alignItems="flex-start">
          <Grid item className="dialogTitle">
            {title}
          </Grid>
          <Grid item>
            <InputButton
              variant="outlined"
              className="outlined-btn addNewButton"
              label="Add address"
              onClick={onAddNewButtonClick}
            />
          </Grid>
          <Grid item>
            <ClearIcon className="clearButton" onClick={handleClose} />
          </Grid>
        </Grid>
      </DialogTitle>
      <DividerWithText>Or</DividerWithText>
      <DialogContent className="dialogContent">{props.children}</DialogContent>
    </Dialog>
  );
};

CustomDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  children: PropTypes.element,
  header: PropTypes.string,
  selectedValue: PropTypes.object,
  title: PropTypes.object,
  maxWidth: PropTypes.string,
  totalRecords: PropTypes.string,
};
CustomDialog.defaultProps = {
  maxWidth: 'md',
  header: 'Service Point',
};

export default CustomDialog;
