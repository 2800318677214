import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import FormRenderer from '../../../framework/Inputs/formRenderer/formRenderer';
const TemplateName = (props) => {
  const {
    formField: { templateName },
    formikValues,
    templateNameExist,
    templateNameEdited,
    getTemplate,
  } = props;

  useEffect(() => {
    if (formikValues.templateName.trim()) {
      getTemplate(formikValues.templateName);
    }
  }, [formikValues.templateName]);

  return (
    <Grid container spacing={3} direction="row" style={{ paddingRight: '60px' }} alignItems="flex-start">
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <FormRenderer {...templateName.props} fullWidth isDisabled={templateNameEdited} />
        {templateNameEdited && templateNameExist
? (
          ''
        )
: (
          <span className="span-required" style={{ display: templateNameExist ? '' : 'none' }}>
            Template name already exists
          </span>
        )}
      </Grid>
    </Grid>
  );
};

TemplateName.propTypes = {
  formField: PropTypes.object,
  formikValues: PropTypes.object,
  templateNameExist: PropTypes.bool,
  getTemplate: PropTypes.func,
};

export default TemplateName;
