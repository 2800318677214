import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Fade } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import './popup.scss';

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 1000,
    //height:'541px',
    marginLeft: '-50px',
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: '0px 1px 2px #B743001F',
    padding: theme.spacing(2, 4, 3),
  },
}));

const Popup = (props) => {
  const { handleClose, open, children, title } = props;
  const classes = useStyles();
  return (
    <div>
      <Fade in={open}>
        <div className={classes.paper}>
          <div className="modalAddress">
            <h2 id="transition-modal-title">{title}</h2>
            <ClearIcon className="clearButton" onClick={handleClose} style={{ marginRight: '-10px' }} />
          </div>
          <div id="transition-modal-description">{children}</div>
        </div>
      </Fade>
    </div>
  );
};

Popup.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  handleSelectionChange: PropTypes.func,
  children: PropTypes.element,
  title: PropTypes.string,
};

export default Popup;
