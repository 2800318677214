import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import FormRenderer from '../../../framework/Inputs/formRenderer/formRenderer';
import { GET } from '../../../api/axios';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import InputButton from '../../../framework/Inputs/inputButton/inputButton';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { shipmentManagementDateFilter } from '../../../utils/constants/constants';
import { formatDate } from '../../../utils/helperFunctions';
import DateRangePicker from '../../../framework/date-range/dateRange';
import CustomDialog from '../../../framework/dialog/customDialog';
import originalMoment from 'moment';
import { extendMoment } from 'moment-range';
// import CustomizedDialogs from '../addressBookModal/addressBookModal'
// import CustomAddressTable from '../../../framework/CustomAddressTable/CustomAddressTable';
import { Divider } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  SearchAddressField: {
    marginTop: '3px',
    // paddingRight: '3px',
  },
}));

const SearchShipment = (props) => {
  const classes = useStyles();
  const {
    formField: { shipmentManagementSearchFor, deliveryStatusForAccounts },
    setFieldValue,
    formikValues,
    searchByOptions,
    errors,
    setFieldTouched,
    tabChanged,
    resetForm,
    setValues,
  } = props;
  const inputRef = React.useRef();
  const moment = extendMoment(originalMoment);
  let [isClearBtn, setIsClearBtn] = useState(false);
  const [renderVal, setRenderVal] = useState('');
  const [open, setOpen] = useState(false);
  const [datepickerValue, setDatepickerValue] = useState(moment.range(new Date(), new Date()));
  const [shipmentManagementSearchForFlag, setShipmentManagementSearchForFlag] = useState(false);
  let [fromCollectionDate, setfromCollectionDate] = useState(formatDate(new Date()));
  let [collectedToDate, setcollectedToDate] = useState(formatDate(new Date()));
  // let [isdatesearch, setIsdatesearch] = useState(false);

  const getClear = (event = null, queryParams = '') => {
    if (event) {
      event.preventDefault();
    }
    // if (tabChanged == 0) {
      setTimeout(() => {
        props.setShipmentSearchParams(
          `&fromCollectionDate=${fromCollectionDate}&toCollectionDate=${collectedToDate}`,
        );
      }, 0);
    // } else {
    // props.setShipmentSearchParams(`&${props.formikValues.shipmentManagementSearchBy.value}=`);
    // }
    setFieldValue('shipmentManagementSearchFor', '');
    setFieldTouched('shipmentManagementSearchFor', false);
    setIsClearBtn(false);
    props.onShipmentChange(false);
  };

  const getShipments = (event = null) => {
    setShipmentManagementSearchForFlag(true);
    if (event) {
      event.preventDefault();
    }
    props.onShipmentChange(true);
    setIsClearBtn(true);
  };

  function getNumberOfDays(start, end) {
    if (start < end) {
      return (end - start) / 1000 / 60 / 60 / 24;
    }
  }

  const onSelect = (value) => {
    const count = getNumberOfDays(value.start, value.end);
    if (count > 30) {
      setDatepickerValue(moment.range(value.start._d, value.end._d));
    } else {
      setDatepickerValue(moment.range(value.start._d, value.end._d));
    }
  };

  useEffect(() => {
    // if (tabChanged == 0) {
    //   props.setShipmentSearchParams(
    //     `&${props.formikValues.shipmentManagementSearchBy.value}=${encodeURIComponent(props.formikValues.shipmentManagementSearchFor)}&fromCollectionDate=${fromCollectionDate}&toCollectionDate=${collectedToDate}`,
    //   );
    // } else {
    // if (!isdatesearch) {
      props.setShipmentSearchParams(
        `&${props.formikValues.shipmentManagementSearchBy.value}=${encodeURIComponent(props.formikValues.shipmentManagementSearchFor)}`,
      );
    // }
    // setIsdatesearch(false)
    // }
  }, [props.formikValues?.shipmentManagementSearchBy, props.formikValues?.shipmentManagementSearchFor]);

  useEffect(() => {
    setIsClearBtn(false);
  }, [props.formikValues.shipmentManagementSearchFor]);

  useEffect(() => {
    // if (tabChanged === 0) {
      props.setShipmentSearchParams(`&${props.formikValues.shipmentManagementSearchBy.value}=&fromCollectionDate=${fromCollectionDate}&toCollectionDate=${collectedToDate}`);
    // } else {
    // props.setShipmentSearchParams(`&${props.formikValues.shipmentManagementSearchBy.value}=`);
    // }
    setValues({
      shipmentManagementSearchFor: '',
      shipmentManagementSearchBy: searchByOptions[0],
    });
    setFieldTouched('shipmentManagementSearchFor', false);
    setFieldValue('deliveryStatusForAccounts', shipmentManagementDateFilter[0]);
  }, [tabChanged]);

  useEffect(() => {
    setFieldValue('shipmentManagementSearchFor', '');
    setFieldTouched('shipmentManagementSearchFor', true);
    inputRef.current.focus();
    if (props.formikValues.shipmentManagementSearchFor || shipmentManagementSearchForFlag) {
      getClear()
      setShipmentManagementSearchForFlag(false);
    }
  }, [props.formikValues.shipmentManagementSearchBy]);

  useEffect(() => {
    // if (tabChanged == 0) {
      // if (props.formikValues.shipmentManagementSearchFor != '') {
      //   props.setShipmentSearchParams(
      //     `&${props.formikValues.shipmentManagementSearchBy.value}=${encodeURIComponent(props.formikValues.shipmentManagementSearchFor)}&fromCollectionDate=${fromCollectionDate}&toCollectionDate=${collectedToDate}`,
      //   );
      // } else {
      //   props.setShipmentSearchParams(
      //     `&${props.formikValues.shipmentManagementSearchBy.value}=&fromCollectionDate=${fromCollectionDate}&toCollectionDate=${collectedToDate}`,
      //   );
      // }
      // getClear();
      // setIsdatesearch(true)
      setFieldValue('shipmentManagementSearchFor', '');
      setFieldTouched('shipmentManagementSearchFor', false);
      setIsClearBtn(false);
      setTimeout(() => {
        props.setShipmentSearchParams(
          `&fromCollectionDate=${fromCollectionDate}&toCollectionDate=${collectedToDate}`,
        );
      }, 0);
      getShipments();
    // }
  }, [fromCollectionDate, collectedToDate]);

  const handleDateClose = (value) => {
    setOpen(false);
    let _fromCollectionDate = formatDate(datepickerValue.start._i);
    let _collectedToDate = formatDate(datepickerValue.end._d);
    setfromCollectionDate(_fromCollectionDate)
    setcollectedToDate(_collectedToDate)
    setRenderVal(`${moment(_fromCollectionDate).format('DD/MM/YYYY')}-${moment(_collectedToDate).format('DD/MM/YYYY')}`);
  };

  const dateRange = (selectedValue) => {
    if (selectedValue === 'daterange') {
      setOpen(true);
    }
  };

  const dateRangeInSelect = () => {
    setOpen(true);
  };

  useEffect(() => {
    shipmentManagementDateFilter[1].clickEvent = dateRangeInSelect;
    if (formikValues.deliveryStatusForAccounts.value === 'daterange') {
      dateRange('daterange');
    }
    else if (props.formikValues?.deliveryStatusForAccounts?.value === 'today') {
      let _fromCollectionDate = formatDate(new Date());
      let _collectedToDate = formatDate(new Date());
      setfromCollectionDate(_fromCollectionDate)
      setcollectedToDate(_collectedToDate)
      setOpen(false);
      shipmentManagementDateFilter[1].name = 'Date range';
    }
    else {
      setOpen(false);
      shipmentManagementDateFilter[1].name = 'Date range';
    }
  }, [formikValues.deliveryStatusForAccounts]);

  return (
    <div className={classes.SearchAddressField}>
      <Grid container direction="row" justify="flex-end" alignItems="flex-start" spacing={3}>
        {/* {tabChanged == 0 ?  */}
        <Grid item xs={4} sm={6} lg={4}>
          <FormRenderer
            {...deliveryStatusForAccounts.props}
            data={shipmentManagementDateFilter}
            label={tabChanged == 0 ? 'Select despatch date' : 'Select collection date'}
            // onClick={(e) => dateRange(e)}
            fullWidth
            renderVal={renderVal}
          />
        </Grid> 
        {/* : null} */}
        <Grid item xs={8} sm={6} lg={8}>
          <FormRenderer
            data={searchByOptions || []}
            onSearchClick={(event) => getShipments(event, '')}
            onClearSearch={(event) => getClear(event, '')}
            isIconDisabled={Boolean(errors.shipmentManagementSearchFor) || formikValues.shipmentManagementSearchFor?.trim() === ''}
            selectProps={shipmentManagementSearchFor.selectProps.props}
            textProps={shipmentManagementSearchFor.textProps.props}
            isClearBtn={isClearBtn}
            inputRef={inputRef}
            {...shipmentManagementSearchFor.props}
          />
        </Grid>
        <CustomDialog open={open} onClose={handleDateClose} title="Date range" className="datePicker-dialog-box">
          <DateRangePicker
            onSelect={onSelect}
            value={datepickerValue}
            datepickerValue={datepickerValue}
            open={true}
            singleDateRange={true}
          // minimumDate={formikValues.isDomestic ? last6months : last12months}
          // maximumDate={lastDay}
          />
          <Grid className="datepicker-btn-grid">
            <InputButton
              variant="contained"
              label="OK"
              className="contained-btn datepicker-btn"
              onClick={handleDateClose}
            />
          </Grid>
        </CustomDialog>
      </Grid>
    </div>
  );
};

export default SearchShipment;
