import React, { forwardRef } from 'react';
import MaterialTable, { MTableBody } from 'material-table';
// import { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';
//import ArrowDownward from '@material-ui/icons/ArrowDownward';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { Hidden } from '@material-ui/core';
import { TablePagination } from '@material-ui/core';
import PropTypes from 'prop-types';
import { KeyboardArrowDown } from '@material-ui/icons';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <KeyboardArrowUpIcon {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const DynamicMaterialTable = (props) => {
  const {
    rows,
    columns,
    isSearchable,
    isSelectable,
    sorting,
    paging,
    pageSize,
    pageSizeOptions,
    empty_DataSource_Message,
    onSelectionChange = () => { },
    fixedColumns,
    filtering,
    onRowClicked,
    additionalProps,
    tableLayout,
    hideTotalCount,
    inBuiltEvents,
    mTableBody,
    customMaxHeight,
  } = props;
  const rowClicked = (event, rowData) => {
    if (onRowClicked) {
      onRowClicked(rowData);
    }
  };
  
  const sortOrderChange = (orderBy,orderDirection) =>{
    if(props.isProcesOrders){
        props.onClickSetSortOrder(orderDirection, columns[orderBy]?.field);
    }
  }

  return (
    <div className="DynamicMaterialTable-main-div">
      <MaterialTable
        title=""
        columns={columns}
        data={rows}
        icons={tableIcons}
        {...inBuiltEvents}
        // onChangePage={(a) => {
        //   console.log(a, 'page');
        // }}
        // onChangeRowsPerPage={(a) => {
        //   console.log('rows per page');
        // }}
        options={{
          search: isSearchable,
          selection: isSelectable,
          sorting,
          paging,
          emptyRowsWhenPaging: false,
          pageSize,
          pageSizeOptions,
          maxBodyHeight: customMaxHeight || '350px',
          overflowY: 'auto',
          overflowX: 'auto',
          headerStyle: {
            position: 'sticky',
            top: 0,
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            height: 56,
            maxHeight: 56,
            paddingTop: 0,
            paddingBottom: 0,
          },
          rowStyle: {
            height: 48,
            maxHeight: 48,
            padding: 0,
          },
          tableLayout: tableLayout ? tableLayout : 'auto',
          cellStyle: {
            // whiteSpace: "nowrap",
            //   textOverflow: "ellipsis",
            //   // display: "block",
            //   overflow: "hidden",
            //   // maxWidth: '100px'
          },
          toolbar: false,
          showFirstLastPageButtons: false,
          paginationType: 'stepped',
          filtering,
          ...additionalProps,
        }}
        {...props}
        {...additionalProps}
        localization={{
          pagination: {
            labelRowsSelect: '',
            labelRowsPerPage: 'Showing results',
          },
          body: {
            emptyDataSourceMessage: empty_DataSource_Message ? empty_DataSource_Message : 'No records to display',
          },
        }}
        onRowClick={rowClicked}
        onOrderChange={sortOrderChange}
        onSelectionChange={onSelectionChange}
        components={{
          mTableBody,
          Pagination: (paginationProps) => (
            <TablePagination

              {...paginationProps}
              {...props}
              labelDisplayedRows={({ from, to, count }) =>
                (hideTotalCount !== true ? `${from}-${to} of ${count} results` : `${from}-${to} results`)
              }
              SelectProps={{
                IconComponent: () => <KeyboardArrowDown className="pagination-select-icon" />,
              }}
            />
          ),
        }}
        {...props}
      />
    </div>
  );
};

DynamicMaterialTable.propTypes = {
  isSelectable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  sorting: PropTypes.bool,
  paging: PropTypes.bool,
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  columns: PropTypes.array,
  filtering: PropTypes.bool,
  empty_DataSource_Message: PropTypes.string,
};

DynamicMaterialTable.defaultProps = {
  isSelectable: true,
  isSearchable: false,
  sorting: true,
  paging: true,
  pageSize: 10,
  pageSizeOptions: [10, 25, 50],
  columns: [],
  filtering: false,
};

export default DynamicMaterialTable;
