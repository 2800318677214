import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import CustomHeader from '../../../../framework/summaryFormate/CustomHeader';
import SetupSummary from '../../../../framework/summaryFormate/SetupSummary';
import { Grid, Box, makeStyles, SvgIcon } from '@material-ui/core';
import DeliveryOptionsSummary from '../../../../framework/summaryFormate/DeliveryOptionsSummary';
import CustomModal from '../../../../framework/dialog/customDialog';
import ReturnOptionsSummary from '../../../../framework/summaryFormate/ReturnOptionsSummary';

import { axiosConfig } from '../../../../api/axios';
import ParcelSummary from '../../../../framework/summaryFormate/ParcelSummary';
import AdditionalInfoSummary from '../../../../framework/summaryFormate/AdditionalInfoSummary';
import ProductAndServiceSummary from '../../../../framework/summaryFormate/ProductAndServiceSummary';
import InputButton from '../../../../framework/Inputs/inputButton/inputButton';
import AddressFormate from '../../../../framework/addressFormate/AddressFormate';
import { ServiceEndPoints } from '../../../../utils/constants/ApiConstant';
import ReprintLabel from '../../../../components/Reprint/ReprintLabel';
import { useDispatch } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import PromptJSPMClientInstallation from '../../../../components/addShipment/Printing/PromptJSPMClientInstallation';
import CustomDialog from '../../../../framework/dialog/customDialog';
import { printZplLabels, printPDF, downloadPDF } from '../../../../utils/PrintHelper';
import ConfirmationDialog from '../../../../components/addressBook/confirmation/confirmation';
import './dashBoardSummary.scss';
import CustomDetailsSummary from '../../../../components/shipmentManagement/shipmentSummary/customDetailsSummary/customDetailsSummary';
import { ReactComponent as EyesIcon } from '../../../../assets/images/eyes.svg';
import { setDropdownValues } from '../../../../utils/helperFunctions';
import CustomCaption from '../../../../framework/summaryFormate/CustomCaption';

const useStyles = makeStyles({
  boxContainer: {
    '& :last-child': {
      borderBottom: 'unset',
    },
  },
});

const DashBoardSummary = (props) => {
  const { id } = useParams();
  const classes = useStyles();
  const [list, setlist] = useState([]);
  let history = useHistory();
  const [showPrintDialog, setshowPrintDialog] = useState(false);
  const [openJSPMPrompt, setJSPMPrompt] = React.useState(false);
  const [isDomestic, setisDomestic] = useState(true);

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [state, setState] = useState({
    reprintLabel: false,
    reprintCustomsInvoice: false,
  });
  const [showCustomDetails, setShowCustomDetails] = useState(false);
  const [customsDetails, setCustomsDetails] = useState({});
  const dispatch = useDispatch();
  const [accountDetails, setAccountDetails] = useState({});
  const [fullCustom, setFullCustom] = useState(false)
  const resetReprintState = () => {
    setState({ ...state, reprintLabel: false, reprintCustomsInvoice: false });
  };

  const handleDialogClose = () => {
    setshowPrintDialog(false);
    setShowConfirmationDialog(false);
    resetReprintState();
  };

  const onDialogConfirm = () => {
    cancelShipment();
    setShowConfirmationDialog(false);
  };

  const handlePrintSubmit = (values, errors) => {
    let printFormat
    if (props.printerConfiguration?.isDowloadLabelAsPDF) {
      printFormat = 'PDF';
    } else {
      printFormat = props.printerConfiguration?.labelType === 1 ? 'ZPL' : 'PDF';
    }
    let pageSize = props.printerConfiguration?.pageSize ? props.printerConfiguration?.pageSize : 0
    axiosConfig
      .post(
        `${ServiceEndPoints.reprintShipments}?isLabel=${values.reprintLabel}&pageSize=${pageSize}&isCustomsInvoice=${values.reprintCustomsInvoice}&format=${printFormat}`,
        [list.shipmentNumber],
      )
      .then((response) => {
        if (!props.JSPMStatus && !props.printerConfiguration?.isDowloadLabelAsPDF) {
          setJSPMPrompt(true);
        } else {
          let labelResponse = response?.data[0]?.labelResponse;
          let customsInvoiceResponse = response?.data[0]?.customsInvoiceResponse;

          labelResponse?.map((lblResponse) => {
            lblResponse?.labels?.map((lblValue, index) => {
              if (props.printerConfiguration?.isDowloadLabelAsPDF === true) {
                let lableName = '';
                if (index === 0) {
                  lableName = '_Label.pdf';
                } else if (index === 1) {
                  lableName = '_ReturnLabel.pdf';
                } else {
                  lableName = '_ReturnInstructions.pdf';
                }
                downloadPDF(lblValue.label, lableName, lblResponse);
              }
              else {
                if (props.printerConfiguration.labelType == 1) {
                  printZplLabels(lblValue.label, props.printerConfiguration?.labelPrinter);
                } else {
                  printPDF(lblValue.label, props.printerConfiguration?.labelPrinter, "Label");
                }
              }
            });
          });

          customsInvoiceResponse?.map((customResponse) => {
            customResponse?.customsInvoice?.map((customValue, index) => {
              if (props.printerConfiguration?.isDowloadLabelAsPDF === true) {
                let lableName = '';
                if (index === 0) {
                  lableName = '_CustomsInvoice.pdf';
                } else if (index === 1) {
                  lableName = '_CustomsInvoice.pdf';
                } else {
                  lableName = '_CustomsInvoice.pdf';
                }
                downloadPDF(customValue, lableName, customResponse);
              }
              else {
                printPDF(customValue, props.printerConfiguration?.otherReportsPrinter, "Invoice");
              }
            });
          });
          dispatch(actions.setAppSnackBarData({ msg: 'Shipment successfully reprinted' }));
        }
      })
      .catch((error) => {
        dispatch(
          actions.setBottomAlert({
            msgs: [
              {
                type: 'error',
                msg: `Error in reprinting the shipment:${list.shipmentNumber}`,
              },
            ],
          }),
        );
        // dispatch(
        //   actions.setAppSnackBarData({ type: 'error', msg: `Error in reprinting the shipment:${list.shipmentNumber}` }),
        // );
      });
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const savePrintDialog = () => {
    setshowPrintDialog(false);
    const values = { reprintLabel: state.reprintLabel, reprintCustomsInvoice: state.reprintCustomsInvoice };
    handlePrintSubmit(values);
    resetReprintState();
  };

  const getDashBoardList = () => {
    //dashboard and shipmanagement shipment by id apis are same
    axiosConfig
      .get(`${ServiceEndPoints.getShipment}/${id}`)
      .then((res) => {
        if (res.data?.destinationCountry !== 'United Kingdom') {
          setisDomestic(false);
        }
        setlist(res.data);
        setSelectedAccountDetails(res.data?.accountNumber);
        dispatch(
          actions.setShipmentId({
            shipmentId: res.data?.shipmentNumber,
          }),
        );
        getProductDetails(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClose = () => {
    setJSPMPrompt(false);
  };

  useEffect(() => {
    getDashBoardList();
  }, []);

  const cancelShipment = () => {
    const itemsToBeSelected = [];
    const item = {
      pickupAccount: list?.accountNumber,
      shipmentId: list?.shipmentNumber,
      postalCode: list?.deliveryAddress?.postalCode,
    };
    itemsToBeSelected.push(item);
    const payload = itemsToBeSelected;
    axiosConfig
      .post(ServiceEndPoints.cancelShipment, payload)
      .then((response) => {
        dispatch(actions.setAppSnackBarData({ msg: 'Shipment successfully cancelled' }));
        // history.goBack()
        history.push({
          pathname: '/dashboard/dashboardDetailsSummary',
          state: {
            itemsToBeSelected: itemsToBeSelected,
          },
        });
      })
      .catch((error) => {
        dispatch(
          actions.setBottomAlert({
            msgs: [
              {
                type: 'error',
                msg: 'Error in cancelling the shipment:' + payload[0].shipmentId,
              },
            ],
          }),
        );
        // dispatch(
        //   actions.setAppSnackBarData({
        //     type: 'error',
        //     msg: 'Error in cancelling the shipment:' + payload[0].shipmentId,
        //   }),
        // );
      });
  };

  const getCustomsDetails = () => {
    axiosConfig
      .get(`${ServiceEndPoints.getCustomsDeclaration}?shipmentId=${id}`)
      .then((res) => {
        setCustomsDetails(res.data);
        setShowCustomDetails(true);
      })
      .catch((err) => {
        dispatch(
          actions.setBottomAlert({
            msgs: [
              {
                type: 'error',
                msg: 'Error in fetching customs details',
              },
            ],
          }),
        );
        // dispatch(actions.setAppSnackBarData({ type: 'error', msg: 'Error in fetching customs details' }));
      });
  };

  const setSelectedAccountDetails = (accountId) => {
    setAccountDetails(setDropdownValues(props.userDetails.accounts, 'accountId', accountId));
  };

  const getProductDetails = (details) => {
    axiosConfig
      .get(`${ServiceEndPoints.productCountryFeature}?countrycode=${details?.deliveryAddress?.countryCode}&orderedProduct=${details?.productDetail?.orderedProduct}`)
      .then((res) => {
        if (res.data[0]?.customsLevel === 'full') {
          setFullCustom(true)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <Box boxShadow={3} bgcolor="background.paper" m={1} p={1} className={`${classes.boxContainer} shipment_box`}>
        <CustomHeader heading="Setup">
          <SetupSummary data={list || {}} />
        </CustomHeader>
        <CustomHeader heading="Parcel details">
          <ParcelSummary shipmentSummary={list} accountDetails={accountDetails} />
        </CustomHeader>
        <CustomHeader heading="Delivery address">
          <Grid container spacing={3} direction="row" lg={6}>
            <AddressFormate
              dashboardSummary={true}
              addressType={((list || {}).deliveryAddress || {}).addressType}
              contactName={((list || {}).deliveryAddress || {}).recipientName}
              businessName={((list || {}).deliveryAddress || {}).businessName}
              addressLine1={((list || {}).deliveryAddress || {}).address1}
              addressLine2={((list || {}).deliveryAddress || {}).address2}
              addressLine3={((list || {}).deliveryAddress || {}).address3}
              town={((list || {}).deliveryAddress || {}).city}
              country={((list || {}).deliveryAddress || {}).country}
              postCode={((list || {}).deliveryAddress || {}).postalCode}
              emailAddress={((list || {}).deliveryAddress || {}).email}
              telephoneNumber={((list || {}).deliveryAddress || {}).contactNumber}
            />
          </Grid>
        </CustomHeader>
        <CustomHeader heading="Delivery options">
          <DeliveryOptionsSummary deliveryOptions={(list || {}).deliveryOptions || {}}
            servicePoint={((list || {}).deliveryAddress || {})?.businessName} />
        </CustomHeader>
        <CustomHeader heading="Product and service">
          <ProductAndServiceSummary data={(list || {}).productDetail} />
        </CustomHeader>
        <CustomHeader heading="Additional information about your shipment">
          <AdditionalInfoSummary
            additionalInfo={(list || {}).additionalInformation || {}}
            destinationCountry={list.destinationCountry}
          />
        </CustomHeader>
        <CustomHeader heading="Return option" style={{ borderBottom: 'none' }}>
          <ReturnOptionsSummary data={(list || {}).returnOption || {}} />
        </CustomHeader>

        {!isDomestic && (
          fullCustom ?
            (
              <Grid container direction="row" justify="flex-end" alignItems="center">
                <Grid item>
                  <InputButton
                    variant="text"
                    className="plain-btn"
                    label="View customs details"
                    startIcon={<SvgIcon fontSize="small" component={EyesIcon} viewBox="1 1 30 30" />}
                    onClick={getCustomsDetails}
                    isDisabled={showCustomDetails}
                    style={{ color: '#000000 !important' }}
                  />
                </Grid>
                <Grid item></Grid>
              </Grid>
            )
            :
            <>
              <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3}>
                <Grid item xs={3}>
                  <CustomCaption title="Description of goods" value={(list || {})?.additionalInformation?.shipmentDesc} />
                </Grid>
                <Grid item xs={3}>
                  <CustomCaption title="Value" value={(list || {})?.additionalInformation?.valueOfGoods} />
                </Grid>

                <Grid item xs={3}>
                  <CustomCaption title="Currency" value={((list || {})?.additionalInformation?.currency)} />
                </Grid>

                <Grid item xs={3}></Grid>
              </Grid>
            </>
        )}
        {showCustomDetails && (
          <>
            <CustomHeader heading="Customs declaration">
              <CustomDetailsSummary shipmentData={customsDetails} />
            </CustomHeader>
          </>
        )}
      </Box>

      <Grid
        className="shipment-btn-continer"
        container
        spacing={3}
        direction="row"
        justify="flex-end"
        alignItems="flex-end"
      >
        <Grid item>
          <InputButton variant="text" className="text-btn" label="Back" onClick={() => history.goBack()} />
        </Grid>
        {list && list.status && list.status.replace(/\s+/g, '').toLowerCase() === 'orderreceived' && (
          <>
            <Grid item>
              <InputButton
                className="outlined-btn"
                variant="outlined"
                label="Cancel shipment"
                onClick={() => {
                  setShowConfirmationDialog(true);
                }}
              />
            </Grid>

            <Grid item>
              <InputButton
                variant="contained"
                className="contained-btn"
                onClick={() => {
                  setshowPrintDialog(true);
                }}
                buttonType="submit"
                label="Reprint"
              />
            </Grid>
          </>
        )}
      </Grid>
      <ReprintLabel
        reprintTitle="Select type of reprint"
        reprintLabel={state.reprintLabel}
        reprintCustomsInvoice={state.reprintCustomsInvoice}
        showPrintDialog={showPrintDialog}
        handleDialogClose={handleDialogClose}
        handleChange={handleChange}
        savePrintDialog={savePrintDialog}
      />
      <CustomDialog open={openJSPMPrompt} onClose={handleClose} title="JSPM">
        <PromptJSPMClientInstallation />
      </CustomDialog>
      <Grid container className="customModal-book">
        <CustomModal open={showConfirmationDialog} title={'Cancel shipment'} onClose={handleDialogClose} maxWidth="sm">
          <ConfirmationDialog
            confirmationMessage={'Are you sure you want to cancel the shipment?'}
            onConfirm={onDialogConfirm}
            OnCancel={handleDialogClose}
          />
        </CustomModal>
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    JSPMStatus: state.common.isJSPMInstalled,
    printerConfiguration: state.common.printerConfiguration,
    availablePrinter: state.common.availablePrinter,
    userDetails: state.auth.userDetails,
  };
};

// export default DashBoardSummary;
export default connect(mapStateToProps, null)(DashBoardSummary);
