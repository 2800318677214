const PrinterQueueForm = {
  formId: 'PrinterQueueForm',
  formField: {
    defaultPrinter: {
      props: {
        name: 'defaultPrinter',
        label: 'Print unknown printer queues to printer (Default)',
        type: 'select',
        displayField: 'name',
      },
      value: '',
    },
    printerQueueName: {
      props: {
        name: 'printerQueueName',
        label: 'Printer queue name',
        type: 'text',
        isRequired: true,
        inputProps: {
          maxLength: 35,
        },
      },
      value: '',
      validation: {
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Printer queue name is required'],
          },
          {
            type: 'when',
            params: [
              'printerQueueList',
              (printerQueueList, schema) => {
                return schema.test('duplicateFinder', 'Duplicate printer queue name', (value) => {
                  return !printerQueueList?.some(
                    (printerQueue) =>
                      printerQueue?.printerQueueName?.toLowerCase()?.trim() === value?.toLowerCase()?.trim(),
                  );
                });
              },
            ],
          },
        ],
      },
    },
    printerToPrint: {
      props: {
        name: 'printerToPrint',
        label: 'Printer to print on',
        type: 'select',
        displayField: 'name',
        isRequired: true,
      },
      value: '',
      validation: {
        validationType: 'object',
        validations: [
          {
            type: 'required',
            params: ['Printer to print on is required'],
          },
          {
            type: 'test',
            params: [
              '',
              'Printer to print on is required',
              (value) => {
                if (!value?.value) {
                  return false;
                }
                return true;
              },
            ],
          },
        ],
      },
    },
    printerQueueList: {
      props: {
        name: 'printerQueueList',
        type: 'array',
        fields: {
          printerQueueName: '',
          printerToPrint: '',
        },
      },
      values: [],
    },
  },
};
export default PrinterQueueForm;
