import React from 'react';
import PropTypes from 'prop-types';
import DynamicMaterialTable from '../../../framework/DynamicMaterialTable/DynamicMaterialTable';
// import TableGrid from '../../../framework/grid/grid';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';

const ShipmentDetailView = (props) => {
  let history = useHistory();

  const onRowClicked = (res) => {
    history.push(`/dashboard/summary/${res.shipments[0].shipmentDetails.shipmentId}`);
  };

  return (
    <Grid className="shipmentDetailView-Table Table-column-color">
      <DynamicMaterialTable
        columns={props.columns}
        rows={props.rows}
        onSelectionChange={props.addSelectedRows}
        onRowClicked={onRowClicked}
        filtering={props.filtering}
        pageSize={props.pageSize}
        tableLayout={props.tableLayout}
        {...props}
        // onChangePage={props.onChangePage}
        // onChangeRowsPerPage={props.onChangeRowsPerPage}
        // totalRecordsCount={props.totalRecordsCount}
      />
    </Grid>
  );
};

ShipmentDetailView.propTypes = {};

export default ShipmentDetailView;
