import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import FormRenderer from '../../../framework/Inputs/formRenderer/formRenderer';
import Grid from '@material-ui/core/Grid';
import InputButton from '../../../framework/Inputs/inputButton/inputButton';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import * as actions from '../../../store/actions/index';

const useStyles = makeStyles((theme) => ({
  SearchAddressField: {
    marginTop: '10px',
    paddingRight: '13px',
  },
}));

const SelectedAccounts = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();
  const {
    formField: { selectAccount },
    setFieldValue,
    formikValues,
    errors,
    setFieldTouched,
    existingAccounts,
    onAccountChangeChange,
  } = props;
useEffect(() => {
    if (formikValues.selectAccount === undefined) {
        setFieldValue('selectAccount', existingAccounts[0]);
    }
    if (formikValues.selectAccount) {
        props.onAccountChangeChange(formikValues.selectAccount)
    }
}, [formikValues.selectAccount])
  return (
    <div className={classes.SearchAddressField}>
      <Grid container direction="row" >
        <Grid item xs={2} style={{paddingBottom:'10px'}} >
            <FormRenderer {...selectAccount.props} data={existingAccounts} fullWidth />
        </Grid>
      </Grid>
    </div>
  );
};

SelectedAccounts.propTypes = {
  formField: PropTypes.object,
  formikValues: PropTypes.object,
  onAccountChange: PropTypes.func,
  totalRecords: PropTypes.string,
  setFieldValue: PropTypes.func,
  isFavourites: PropTypes.object,
  setFieldTouched: PropTypes.func,
}

export default SelectedAccounts;
