import React, { useEffect, useState } from 'react';
import { Grid, TextField, Divider, makeStyles, TableRow, TableCell } from '@material-ui/core';

import { isValidNumber } from '../../../../utils/common';

const useStylesTextField = makeStyles(() => ({
  root: {
    width: '138px',
    border: '1px solid #ACACAC',
    overflow: 'hidden',
    color: 'black',
    opacity: 1,
    borderRadius: 4,
    backgroundColor: '#FFFFFF  ',
    '&:hover': {
      backgroundColor: '#ffffff',
    },
    '&$focused': {
      backgroundColor: '#fff',
    },
  },
}));

const IntlParcelDimension = (props) => {
  const { rowData, manageParcelDimensions, volumetricDivisor, dimsValidation, isAddressBook, loadedFrom, afterSubmitFlag, setAfterSubmitFlag, duplicatedFlag, setDuplicatedFlag} = props;
  const cellNames = ['length', 'weight', 'width', 'height'];
  const [weightError, setWeightError] = useState('');
  const [lengthError, setLengthError] = useState('');
  const [heightError, setHeightError] = useState('');
  const [widthError, setWidthError] = useState('');
  const [prevStateOfParcel,setPrevStateOfParcel] = useState({name: 'weight', value: rowData.weight});
  const classes = useStylesTextField();

  //Handle Volumetric changes
  const calculateVolumetricWeight = () => {
    const { height, width, length } = rowData;
    if (isValidNumber(height) && isValidNumber(length) && isValidNumber(width)) {
      const volWeight = (length * width * height) / volumetricDivisor;
      return volWeight.toFixed(2);
    }
    return 0.0;
  };

  useEffect(()=>{
    if((dimsValidation !== null && (loadedFrom?.loadedFrom === 'dropdownResponse' || loadedFrom?.loadedFrom === 'fromPreviewSummary')) || afterSubmitFlag || (duplicatedFlag && dimsValidation !== null)){
        validateFieldValue({name: 'weight', value: rowData.weight});
        validateFieldValue({name: 'length', value: rowData.length});
        validateFieldValue({name: 'width', value: rowData.width});
        validateFieldValue({name: 'height', value: rowData.height});
        setAfterSubmitFlag?.(false);
        setDuplicatedFlag?.(false);
      }
  },[dimsValidation, afterSubmitFlag, duplicatedFlag]);

  //Handle text field changes
  const onTextFieldChange = (event) => {
    if(event.target.type==='number' && ((event.target.value).toString().length > 3))
    {
      var t = (event.target.value).toString();
      event.target.value = t.indexOf('.') >= 0 ? t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), 2) : event.target.value;
    }
    validateFieldValue({ name: event.target.name, value: event.target.value });
  };

  useEffect(()=> {
    let isValidRow = false;
    const currentChangedField = cellNames.filter((x) => x !== prevStateOfParcel.name);
    currentChangedField.map((cell) => {
        if ((isValidNumber(rowData[cell]) && isValidNumber(prevStateOfParcel.value)) || prevStateOfParcel.name === 'pieceRefId' || isAddressBook) {
            if ( weightError === '' && lengthError === '' && heightError === '' && widthError === '') {
              isValidRow = true;
            }
            else {
              isValidRow = false;
            }      
        } else {
          isValidRow = false;
        }
    });
    manageParcelDimensions({ name: prevStateOfParcel.name, value: prevStateOfParcel.value, isValid: isValidRow }, props.parcelNo);
  },[lengthError, widthError, weightError, heightError, prevStateOfParcel]);
  
  //Validate fields against the dims from productcountry feature API
  const validateFieldValue = (fieldDetails) => {
    let isValidRow = false;
    if (fieldDetails.name === 'weight') {
      if(!isAddressBook && !fieldDetails.value){
        setWeightError('Weight is required.');
      } else if (fieldDetails.value > dimsValidation.maxWeight) {
        setWeightError(`Declared weight must be less than or equal to ${dimsValidation.maxWeight}.`);
      } else if ((!isAddressBook) ? fieldDetails.value < 0.1 : fieldDetails.value && fieldDetails.value < 0.1) {
        setWeightError('Declared weight must be greater than 0');
      } else {
        setWeightError('');
      }
    } else if (fieldDetails.name === 'length') {
      if(!isAddressBook && !fieldDetails.value){
        setLengthError('Length is required.');
       }
      else if (fieldDetails.value > dimsValidation.maxLength) {
        setLengthError(`Declared length must be less than or equal to ${dimsValidation.maxLength}`);
      } else if ((!isAddressBook) ? fieldDetails.value < dimsValidation.minLength : fieldDetails.value && fieldDetails.value < dimsValidation.minLength) {
        setLengthError(`Declared length must be greater than or equal to ${dimsValidation.minLength}`);
      } else {
        setLengthError('');
        if (
          isValidNumber(rowData.width) &&
          isValidNumber(rowData.height) &&
          (parseFloat(rowData.width) > fieldDetails.value || parseFloat(rowData.height) > fieldDetails.value)
        ) {
          setLengthError('Length must be longest side');
        } else {
          setLengthError('');
        }
      }
    } else if (fieldDetails.name === 'width') {
      if(!isAddressBook && !fieldDetails.value){
        setWidthError('Width is required.');
      }
      else if (fieldDetails.value > dimsValidation.maxWidth) {
        setWidthError(`Declared width must be less than or equal to ${dimsValidation.maxWidth}`);
      } else if ((!isAddressBook) ? fieldDetails.value < dimsValidation.minWidth : fieldDetails.value && fieldDetails.value < dimsValidation.minWidth) {
        setWidthError(`Declared width must be greater than or equal to ${dimsValidation.minWidth}`);
      } else {
        setWidthError('');
      }
      if (
        isValidNumber(rowData.length) &&
        lengthError === '' &&
        (isValidNumber(rowData.height) || isValidNumber(fieldDetails.value)) &&
        (parseFloat(fieldDetails.value) > rowData.length || parseFloat(rowData.height) > rowData.length)
      ) {
        setLengthError('Length must be longest side');
      } else if (
        isValidNumber(rowData.length) &&
        lengthError === 'Length must be longest side' &&
        (isValidNumber(rowData.height) || isValidNumber(fieldDetails.value)) &&
        (parseFloat(fieldDetails.value) < rowData.length && parseFloat(rowData.height) < rowData.length)
      ) {
        setLengthError('');
      }
    } else if (fieldDetails.name === 'height') {
      if(!isAddressBook && !fieldDetails.value){
        setHeightError('Height is required.');
      } else if (fieldDetails.value > dimsValidation.maxHeight) {
        setHeightError(`Declared height must be less than or equal to ${dimsValidation.maxHeight}`);
      } else if ((!isAddressBook) ? fieldDetails.value < dimsValidation.minHeight : fieldDetails.value && fieldDetails.value < dimsValidation.minHeight) {
        setHeightError(`Declared height must be greater than or equal to ${dimsValidation.minHeight}`);
      } else {
        setHeightError('');
      }
      if (
        isValidNumber(rowData.length) &&
        lengthError === '' &&
        (isValidNumber(rowData.width) || isValidNumber(fieldDetails.value)) &&
        (parseFloat(fieldDetails.value) > rowData.length || parseFloat(rowData.width) > rowData.length)
      ) {
        setLengthError('Length must be longest side');
      } else if (
        isValidNumber(rowData.length) &&
        lengthError === 'Length must be longest side' &&
        (isValidNumber(rowData.width) || isValidNumber(fieldDetails.value)) &&
        parseFloat(fieldDetails.value) < rowData.length &&
        parseFloat(rowData.width) < rowData.length
      ) {
        setLengthError('');
      }
    }
    setPrevStateOfParcel(fieldDetails);
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCell item lg={1} style={{maxWidth:'60px'}}>
          {props.parcelNo + 1}
        </TableCell>
        <TableCell item lg={2} style={{maxWidth:'138px'}}>
          <TextField
            name="weight"
            type={'number'}
            onChange={onTextFieldChange}
            value={rowData.weight}
            InputProps={{ classes,  inputProps: {min:0, max:1000, step:1} }}
            helperText={ weightError }
            fullWidth
            onKeyPress={(e) => {
              if ((e.key === 'e' || e.key === '+' || e.key === '-')) {
                e.preventDefault();
              }
            }}
          />
        </TableCell>
        <TableCell item lg={2} style={{maxWidth:'138px'}}>
          <TextField
            name="length"
            type={'number'}
            onChange={onTextFieldChange}
            value={rowData.length}
            InputProps={{ classes, inputProps: {min:0, max:1000, step:1} }}
            helperText={ lengthError }
            fullWidth
            onKeyPress={(e) => {
              if ((e.key === 'e' || e.key === '+' || e.key === '-')) {
                e.preventDefault();
              }
            }}
          />
        </TableCell>
        <TableCell item lg={2} style={{maxWidth:'138px'}}>
          <TextField
            name="width"
            type={'number'}
            onChange={onTextFieldChange}
            value={rowData.width}
            InputProps={{ classes, inputProps: {min:0, max:1000, step:1} }}
            helperText={ widthError }
            fullWidth
            onKeyPress={(e) => {
              if ((e.key === 'e' || e.key === '+' || e.key === '-')) {
                e.preventDefault();
              }
            }}
          />
        </TableCell>
        <TableCell item lg={2} style={{maxWidth:'138px'}}>
          <TextField
            name="height"
            type={'number'}
            onChange={onTextFieldChange}
            value={rowData.height}
            InputProps={{ classes, inputProps: {min:0, max:1000, step:1} }}
            helperText={ heightError }
            fullWidth
            onKeyPress={(e) => {
              if ((e.key === 'e' || e.key === '+' || e.key === '-')) {
                e.preventDefault();
              }
            }}
          />
        </TableCell>
        <TableCell item lg={2} style={{maxWidth:'138px'}}>
          <TextField
            name="pieceRefId"
            onChange={onTextFieldChange}
            value={rowData.pieceRefId}
            InputProps={{ classes, inputProps: {maxLength:40} }}
            fullWidth
          />
        </TableCell>
        <TableCell item lg={1} fullWidth style={{maxWidth:'90px'}}>{calculateVolumetricWeight()}</TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default IntlParcelDimension;
