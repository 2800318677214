import { Grid, Typography } from '@material-ui/core';
import React, { useRef, useEffect, useState } from 'react';
import FormRenderer from '../Inputs/formRenderer/formRenderer';
import RichTextEditor from '../Inputs/richTextEditor/richTextEditor';

const FaqForm = (props) => {
  const {
    formField: { catagory, addAnswer, addQuestion, faqCatagory },
    setFieldValue,
    // isFavourites,
    catagoryValues,
    errors,
    categoryType,
    editQuestion,
    editAnswer,
    // uniqueLeftPanelData,
    formikValues,
    setNewCategory,
    // setFieldTouched,
  } = props;
const [catagoryNameExist, setCatagoryNameExist] = useState(false)

  useEffect(() => {
    if (categoryExists(formikValues.catagory)) {
      setCatagoryNameExist(true)
      setNewCategory(true)
    } else {
      setCatagoryNameExist(false)
      setNewCategory(false)

    }
 
  }, [formikValues.catagory])

//Check if object value exists within a Javascript array 
function categoryExists(categoryName) {
  return catagoryValues.some((el) => {
    return el.name === categoryName;
  }); 
}

  return (
    <Grid container direction="column" className="createUser-container" spacing={2}>
      <Grid className="form-group" item xs={12} sm={12} md={12} lg={12}>
        {categoryType === 'textBox'
? <>
          <FormRenderer {...catagory.props} fullWidth />
     <span className="span-required" style={{ display: catagoryNameExist ? '' : 'none' }}>
          Catagory  name already exists
          </span> 
        
        </>
: (
          <FormRenderer {...faqCatagory.props} fullWidth data={catagoryValues} />
        )}
      </Grid>
      <Grid className="form-group" item xs={12} sm={12} md={12} lg={12}>
        <FormRenderer {...addQuestion.props} fullWidth  categoryType={categoryType}/>
      </Grid>
      <Grid className="form-group" item xs={12} sm={12} md={12} lg={12}>
        <FormRenderer {...addAnswer.props} fullWidth   categoryType={categoryType} />
      </Grid>
      <Grid>{
        categoryType === "textBox" ? <Typography>Note: Adding a FAQ for the new category is mandatory</Typography> : ''}
        
      </Grid>
    </Grid>
  );
};

export default FaqForm;
