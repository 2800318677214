import * as actionTypes from '../actions/actionsTypes';

import { updateObject } from '../../utils/common';

const initialState = {
  parcelDetails: {},
};

const setLoadParcelDimensions = (state, action) => {
  return updateObject(state, {
    parcelDetails: action.data,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SETLOADPARCELDIMENSIONS:
      return setLoadParcelDimensions(state, action);
    default:
      return state;
  }
};

export default reducer;
