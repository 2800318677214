import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import DynamicMaterialTable from '../../../framework/DynamicMaterialTable/DynamicMaterialTable';
import CustomModal from '../../../framework/modal/modal';
import ConfirmationDialog from '../../addressBook/confirmation/confirmation';
import InputButton from '../../../framework/Inputs/inputButton/inputButton';
import TableExportPopup from '../../TableExport/TableExportPopup';
import * as actions from '../../../store/actions/index'
import { axiosConfig, GET } from '../../../api/axios';
import { useDispatch, useSelector } from 'react-redux';
import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';
import { arrayToString, formatDate } from '../../../utils/helperFunctions';
import jsPDF from 'jspdf';

const BookedCollections = (props) => {
  const {
    rows,
    columns,
    isSearchable,
    isSelectable,
    sorting,
    paging,
    pageSize,
    pageSizeOptions,
    title,
    confirmationMessage,
    cancelCollection,
    totalCount,
  } = props;
  const [selectedRows, setSelectedRows] = useState([]);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [ExporttableRawData, setExportTableRawData] = useState([]);
  const [showExportDialog, setshowExportDialog] = useState(false);
  const [sortByOrder, setSortByOrder] = useState('');
  const dispatch = useDispatch();
  const userAccounts = useSelector((state) => state.accounts.userAccounts);
  const [pageNumber, setPageNumber] = useState(0);
  const isBookedCollections = props.tabIndex === 1;

   const headers = [
    { label: 'Customer reference', key: 'customerReferenceNumber' },
    // { label: "Alternative reference", key: "alternativeReference" },
    { label: 'Shipment number', key: 'Shipmentnumber' },
    { label: 'Collection date', key: 'collectionDate' },
    { label: 'Business/Recipient name', key: 'recipientName' },
    { label: 'Collection postal code', key: 'destinationPostalCode' },
    { label: 'Number of items', key: 'numberOfParcels' },
    { label: 'Weight', key: 'weight' },
    // { label: 'Product', key: 'product' },
    { label: 'Service', key: 'service' }
  ];

  const addSelectedRows = (selectedRows) => {
    setSelectedRows(selectedRows);
  };

  const handleDialogClose = () => {
    setShowConfirmationDialog(false);
    setshowExportDialog(false);
  };

  const onDialogConfirm = () => {
    cancelCollection(selectedRows);
    handleDialogClose();
    setSelectedRows([]);
  };

  const openConfirmtionPopup = () => {
    setShowConfirmationDialog(true);
  };

  const onDataUpdate = async () => {
    dispatch(actions.setLoaderLayOver(true));

    let url = `${ServiceEndPoints.getShipmentManagement}isCollectionRequest=${isBookedCollections}`;
    const offset = pageNumber * pageSize;
    await GET(getUrl(url, offset))
      .then((res) => {
        let ExportData = [];
        res.data.shipmentModels.forEach((data) => {
          ExportData.push({
            customerReferenceNumber: data.shipments?.[0]?.shipmentDetails?.customerRef1,
            // alternativeReference: data?.shipments?.[0]?.shipmentDetails?.customerRef2,
            Shipmentnumber: data?.shipmentId,
            collectionDate: formatDate(data?.pickup?.date, '/', 'dd/mm/yyyy'),
            recipientName: data.pickupAddress?.companyName || data.pickupAddress.name,
            destinationPostalCode: data?.pickupAddress?.postalCode,
            numberOfParcels: data?.numberOfParcels,
            weight: data?.shipments?.[0]?.shipmentDetails?.totalWeight ?? "",
            // product: data?.product?.productNameForUI ?? "",
            service: data?.service?.description ?? ""
          });
        });
        setExportTableRawData(ExportData);
        dispatch(actions.setAppSnackBarData({ msg: `Data exported successfully` }));
        setshowExportDialog(false);
      })
      .catch((err) => {
        console.log(err);
        setExportTableRawData([]);
        dispatch(
          actions.setBottomAlert({
            msgs: [
              {
                type: 'error',
                msg: 'Error in exporting data, please try again later',
              },
            ],
          }),
        );
        setshowExportDialog(false);
      })
      .finally(() => {
        dispatch(actions.setLoaderLayOver(false));
        setshowExportDialog(false);
      });
  };

  const printDocument = async () => {
    try {
      dispatch(actions.setLoaderLayOver(true));
      const url = `${ServiceEndPoints.getShipmentManagement}isCollectionRequest=${isBookedCollections}`;
      const offset = pageNumber * pageSize;
      await axiosConfig.get(getUrl(url, offset)).then(async (res) => {

        let data = res.data?.shipmentModels ?? [];
        const unit = 'pt';
        const size = 'A4'; // Use A1, A2, A3 or A4
        const orientation = 'landscape'; // portrait or landscape
        const doc = new jsPDF(orientation, unit, size);
        const title = 'My Updated Awesome Report';
        const updatedHeaderColumns = [...headers];

        data = data.map((elt) => [
          elt.shipments?.[0]?.shipmentDetails?.customerRef1,
          // elt.shipments?.[0]?.shipmentDetails?.customerRef2,
          elt.shipmentId,
          formatDate(elt?.pickup?.date, '/', 'dd/mm/yyyy'),
          elt.pickupAddress?.companyName || elt.pickupAddress.name,
          elt.pickupAddress?.postalCode,
          elt.numberOfParcels,
          elt.shipments?.[0]?.shipmentDetails?.totalWeight,
          // elt?.product?.productNameForUI ?? "",
          elt?.service?.description ?? ""
        ]);

        const _headers = [updatedHeaderColumns.map((headers) => headers.label)];

        let content = {
          head: _headers,
          body: data,
          columnStyles: {
            0: {
              columnWidth: 100
            },
            1: {
              columnWidth: 80,
            },
            5: {
              columnWidth: 80,
            },
            6: {
              columnWidth: 60,
            },
            7: {
              columnWidth: 50,
            }
          },
          styles: {
            cellWidth: 'wrap',
            fontSize: 6,
            cellPadding: 2,
            overflowColumns: 'linebreak',
          },
        };
        doc.autoTable(content);
        doc.save('BookedCollections.pdf');
        dispatch(actions.setAppSnackBarData({ msg: `Data exported successfully` }));
        setshowExportDialog(false);
      });
    } catch (err) {
      console.log(err);
      dispatch(
        actions.setBottomAlert({
          msgs: [
            {
              type: 'error',
              msg: 'Error in exporting data, please try again later',
            },
          ],
        }),
      );
    } finally {
      dispatch(actions.setLoaderLayOver(false));
    }
  };

  const getUrl = (url, offset) => {

    url += `&${arrayToString(userAccounts, 'accounts', 'accountId')}&status=Order received`;

    url += props.shipmentSearchParams;
    url += `&offset=${offset}`;
    if (sortByOrder) {
      url += sortByOrder;
    }
    return url;
  }

  return (
    <Grid container justify="flex-end" alignItems="flex-end">
      <Grid item lg={12} md={12} xs={12} className="Table-column-color">
        <DynamicMaterialTable
          rows={rows}
          columns={columns}
          isSelectable={isSelectable}
          isSearchable={isSearchable}
          sorting={sorting}
          paging={paging}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
          onSelectionChange={addSelectedRows}
          {...props}
        />
      </Grid>

      <Grid container item className="customModal-book">
        <CustomModal open={showConfirmationDialog} title={title} onClose={handleDialogClose} maxWidth="sm">
          <ConfirmationDialog
            confirmationMessage={confirmationMessage}
            onConfirm={onDialogConfirm}
            OnCancel={handleDialogClose}
          />
        </CustomModal>
      </Grid>

      <Grid>
        <TableExportPopup
          myshipment={true}
          isBookedCollections={true}
          headers={headers}
          data={ExporttableRawData}
          filename={'BookedCollections.csv'}
          onDataUpdate={onDataUpdate}
          onClick={printDocument}
          disableExportButton={totalCount === 0 ? true : false}
          setSortByOrder={setSortByOrder}
          showExportDialog={showExportDialog}
          ExportTitle={"Export"}
          handleDialogClose={handleDialogClose}
        />
      </Grid>
      <Grid className="btn-container" item container direction='row' spacing={2} >
          <Grid item xs={10} className="btn-container">
            <InputButton
              className={`outlined-btn`}
              label="Export"
              variant="outlined"
              onClick={() => setshowExportDialog(true)}
              isDisabled={totalCount === 0 ? true : false}
            />
          </Grid>
        <Grid item xs={2} justify="flex-end" alignItems="flex-end" style={{ paddingLeft: '36px'}}>
          <InputButton
            className="outlined-btn Delete-AddressBook"
            isDisabled={selectedRows.length === 0}
            label="Cancel collection"
            variant="outlined"
            onClick={openConfirmtionPopup}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

BookedCollections.propTypes = {};

export default BookedCollections;
