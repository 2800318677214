import React, { useState, useEffect } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import CustomCaption from '../../../../framework/summaryFormate/CustomCaption';

const useStyles = makeStyles({
  flexContainer: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
  },
  parcelHandover: {
    paddingBottom: '20px',
  },
});

const ParcelAddShipment = ({ shipmentDetails }) => {
  const classes = useStyles();

  return (
    <>
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3}>
        <Grid item className={classes.flexContainer}>
          <CustomCaption title="Number of items" value={shipmentDetails.noOfItems} />
        </Grid>
        <Grid item className={classes.flexContainer}>
          <CustomCaption title="Weight (kg)" value={shipmentDetails.weight || 0} />
        </Grid>
        {/* <Grid item className={classes.flexContainer}>
          <CustomCaption title="Length (cm)" value={shipmentDetails.length} />
        </Grid>
        <Grid item className={classes.flexContainer}>
          <CustomCaption title="Width (cm)" value={shipmentDetails.width} />
        </Grid> */}
        {/* <Grid item className={classes.flexContainer} >
        <ReadOnlyCheckbox label="Parcel dimensions added" checked={state} />
        </Grid> */}
        {/* <Grid item className={classes.flexContainer}>
          <CustomCaption title="Height (cm)" value={shipmentDetails.height} />
        </Grid> */}
        <Grid item className={classes.flexContainer}>
          <CustomCaption title="Total volumetric wt.(kg):" value={shipmentDetails.totalVolumetricWeight} />
        </Grid>
      </Grid>
    </>
  );
};

export default ParcelAddShipment;
