import * as actionTypes from '../actions/actionsTypes';
import { updateObject } from '../../utils/common';

const initialState = {
  bookedshipmentId: '',
};

const setBookedShipmentId = (state, action) => {
  return updateObject(state, {
    bookedshipmentId: action.data,
  });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SETBOOKEDSHIPMENTID:
      return setBookedShipmentId(state, action);
    default:
      return state;
  }
};

export default reducer;
