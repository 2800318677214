import * as yup from 'yup';

/** Adding just additional methods here */

// yup.addMethod(yup.string, 'URL', function (...args) {
//   return this.url(...args);
// });

// const validator = (message) => {
//   return this.test('is-string-boolean', message, function (value) {
//     // if (isEmpty(value)) {
//     //   return true;
//     // }

//     if (['Y', 'N'].indexOf(value) !== -1) {
//       return true;
//     } else {
//       return false;
//     }
//   });
// };

// yup.addMethod(yup.string, 'stringBoolean', validator);
// yup.addMethod(yup.string, "StringBoolean", validator);

export function createYupSchema(schema, config) {
  if (config.validation) {
    const { validationType, validations = [] } = config.validation;
    if (!yup[validationType]) {
      return schema;
    }
    let validator = yup[validationType]();

    // if (config.children) {
    //   config.children.forEach((ctrl) => {
    //     if (!yup[ctrl.validationType]) {
    //       return;
    //     }
    //     validator = yup[ctrl.validationType]();
    //     ((ctrl || {}).validations || []).forEach((validation) => {
    //       const { params, type } = validation;
    //       if (!validator[type]) {
    //         return;
    //       }
    //       validator = validator[type](...params);
    //       schema[ctrl.name] = validator;
    //     });
    //   });
    // } else {
    validations.forEach((validation) => {
      const { params, type } = validation;
      if (!validator[type]) {
        return;
      }
      validator = validator[type](...params);
    });
    schema[config.props.name] = validator;
  }
  //     schema[name] = validator;

  return schema;
}

export const getYupSchemaFromMetaData = (metadata, additionalValidations, forceRemove, options = [[]]) => {
  const formData = [];
  for (let property in metadata) {
    if (Object.prototype.hasOwnProperty.call(metadata, property)) {
      formData.push(metadata[property]);
    }
  }
  const yepSchema = formData.reduce(createYupSchema, {});
  const mergedSchema = {
    ...yepSchema,
    ...additionalValidations,
  };

  forceRemove.forEach((name) => {
    delete mergedSchema[name];
  });

  const validateSchema = yup.object().shape(mergedSchema, options);

  return validateSchema;
};
