import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AddressFormate from '../../../framework/addressFormate/AddressFormate';
import { Grid, Box, makeStyles, Divider } from '@material-ui/core';
import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';
import { GET, POST } from '../../../api/axios';
import CollectionSetUpSummary from './CollectionSetUpSummary';
import InputButton from '../../../framework/Inputs/inputButton/inputButton';
import CustomHeader from '../../../framework/summaryFormate/CustomHeader';
import CollectionParcelSummary from './CollectionParcelSummary';
import CustomCaption from '../../../framework/summaryFormate/CustomCaption';
import { connect, useDispatch } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { setDropdownValues, formatDateToUKFormat } from '../../../utils/helperFunctions';
import { getYupSchemaFromMetaData } from '../../../utils/yupSchema/yupSchemaGenerator';
import { Form, Formik } from 'formik';
import CustomDialog from '../../../framework/dialog/customDialog';
import FormRenderer from '../../../framework/Inputs/formRenderer/formRenderer';
import { EmailParam } from '../../../utils/constants/constants';

const formikRef = React.createRef();

const useStyles = makeStyles({
  boxContainer: {
    '& :last-child': {
      borderBottom: 'unset',
    },
  },
  customTitle: {
    color: '#000000E6',
    paddingBottom: '10px',
    paddingTop: '10px',
  },
  contentHead: {
    paddingBottom: '5px',
    paddingTop: '5px',
  },
  verticalLine: {
    borderLeft: '1px solid #E3E3E3',
    height: '500px',
    margin: '3px',
  },
});
const BookedCollectionSummary = ({ userDetails }) => {
  const formField = emailLabelForm;
  const newEmailValidationSchema = getYupSchemaFromMetaData(formField, [], []);
  const initialValues = {
    sendLabelEmailAddress: '',
  };

  const dispatch = useDispatch();
  let { id } = useParams();
  const classes = useStyles();
  const [list, setlist] = useState([]);
  const [doorStepInstructionsValue, setDoorStepInstructionsValue] = useState('');
  let history = useHistory();
  const [accountDetails, setAccountDetails] = useState({});
  const [showEmailConfirmation, setShowEmailConfirmation] = useState(false);
  const [labelResponse, setLabelResponse] = useState('');
  const [labelEmail, setLabelEmail] = useState('');

  const getShipmentList = () => {
    GET(`${ServiceEndPoints.getShipment}/${id}`)
      .then((res) => {
        setlist(res.data);
        setSelectedAccountDetails(res.data?.accountNumber);
        dispatch(
          actions.setShipmentId({
            shipmentId: res.data?.shipmentNumber,
          }),
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getShipmentList();
    return () => {
      dispatch(
        actions.setShipmentId({
          shipmentId: '',
        }),
      );
    };
  }, []);

  useEffect(() => {
    if(((list || {}).deliveryOptions || {}).addressType === 'doorstep'){
      if (((list || {}).deliveryOptions || {}).neighbourDelivery) {
        setDoorStepInstructionsValue('Deliver to neighbour if no answer at doorstep');
      } else if (((list || {}).deliveryOptions || {}).secureLocationDelivery) {
        setDoorStepInstructionsValue('Leave safe if no answer at doorstep');
      } else {
        setDoorStepInstructionsValue('Deliver to doorstep only');
      }
    }
    if (((list || {}).collectionDetails || {}).email) {
      formikRef.current.setValues({ sendLabelEmailAddress: ((list || {}).collectionDetails || {}).email });
    } else {
      // formikRef.current.setValues({sendLabelEmailAddress:'Vinoth.Mahendran@Mindtree.com'});
      formikRef.current.setErrors({ sendLabelEmailAddress: 'Email address is required' });
    }
  }, [list]);

  const setSelectedAccountDetails = (accountId) => {
    setAccountDetails(setDropdownValues(userDetails.accounts, 'accountId', accountId));
  };

  const backToBookedCollection = () => {
    dispatch(actions.setActiveTab('bookedCollection'));
    history.push('/shipment/shipmentmanagement');
  };

  const cancelCollection = () => {
    const itemsToBeSelected = [];
    const item = {
      pickupAccount: list?.accountNumber,
      shipmentId: list?.shipmentNumber,
      postalCode: list?.deliveryAddress?.postalCode,
    };
    itemsToBeSelected.push(item);
    const payload = itemsToBeSelected;
    POST(ServiceEndPoints.cancelShipment, payload)
      .then((response) => {
        dispatch(actions.setAppSnackBarData({ type: 'success', msg: 'Collection successfully cancelled' }));
        dispatch(actions.setActiveTab('bookedCollection'));
        // history.push('/shipment/shipmentmanagement');
        history.push({
          pathname: '/shipment/shipmentmanagement',
          state: {
            itemsToBeSelected: itemsToBeSelected,
            shipmentSummary: false,
          },
        });
      })
      .catch((error) => {
        dispatch(
          actions.setBottomAlert({
            msgs: [
              {
                type: 'error',
                msg: 'Error in cancelling the collection:' + payload[0].shipmentId,
              },
            ],
          }),
        );
        // dispatch(actions.setAppSnackBarData({type:"error",msg:'Error in cancelling the collection:' + payload[0].shipmentId}))
      });
  };

  const handleSubmit = (values, errors) => {
    if (!isFormValid(errors)) {
      // isEmail && setShowEmailConfirmation(false)
      return;
    }
    const payload = [id];
    POST(`${ServiceEndPoints.reprintShipments}?isLabel=true&isCustomsInvoice=false&format=PDF`, payload)
      .then((response) => {
        setLabelEmail(values.sendLabelEmailAddress);
        setLabelResponse(response?.data[0]?.labelResponse[0]?.labels[0]?.label);
        setShowEmailConfirmation(false);
      })
      .catch((error) => {
        dispatch(
          actions.setBottomAlert({
            msgs: [
              {
                type: 'error',
                msg: `Error in sending email : ${JSON.parse(error?.response?.data?.errorMessage)?.[0]?.title}\n ${JSON.parse(error?.response?.data?.errorMessage)?.[0]?.detail
                  }`,
              },
            ],
          }),
        );
        // dispatch(
        //   actions.setAppSnackBarData({
        //     type: 'error',
        //     msg: `Error in sending email : ${JSON.parse(error?.response?.data?.errorMessage)?.[0]?.title}\n ${
        //       JSON.parse(error?.response?.data?.errorMessage)?.[0]?.detail
        //     }`,
        //   }),
        // );
        setShowEmailConfirmation(false);
      });
  };

  useEffect(() => {
    if (labelResponse !== '' && labelEmail !== '') {
      let reqEmailObjArray = {
        fromEmailAddress: EmailParam.fromEmailAddress,
        toEmailAddresses: [labelEmail],
        subject: EmailParam.subject,
        body:
          list && list?.collectionDate !== ''
            ? EmailParam.body.replace('(date)', formatDateToUKFormat(list.collectionDate))
            : EmailParam.body,
        attachments: [
          {
            content: labelResponse,
            type: EmailParam.type,
            fileName: EmailParam.fileName,
          },
        ],
        displayRecipients: EmailParam.displayRecipients,
      };
      POST(`${ServiceEndPoints.email}`, reqEmailObjArray)
        .then((response) => {
          dispatch(actions.setAppSnackBarData({ type: 'success', msg: 'Email with label sent successfully' }));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [labelResponse, labelEmail]);

  const isFormValid = (errors) => {
    const { deliveryServicePoint, ...restControls } = errors;
    return Object.keys(restControls).length === 0;
  };

  return (
    <React.Fragment>
      <Box boxShadow={3} bgcolor="background.paper" m={1} p={1} className="shipment_box">
        <CustomHeader heading="Setup">
          <CollectionSetUpSummary data={list || {}} />
        </CustomHeader>
        <CustomHeader heading="Parcel details">
          <CollectionParcelSummary shipmentSummary={list} accountDetails={accountDetails} isCollections={true} />
        </CustomHeader>
        <Grid container direction="row" alignItems="flex-start">
          <Grid
            item
            direction="row"
            lg={6}
            className={classes.boxContainer}
            style={{ padding: '10px', paddingLeft: '0px' }}
          >
            <Grid className={classes.customTitle}>
              <b>Collection details</b>
            </Grid>
            <AddressFormate
              shipmentManagement={true}
              addressType={((list || {}).collectionDetails || {}).addressType}
              contactName={((list || {}).collectionDetails || {}).recipientName}
              businessName={((list || {}).collectionDetails || {}).businessName}
              addressLine1={((list || {}).collectionDetails || {}).address1}
              addressLine2={((list || {}).collectionDetails || {}).address2}
              addressLine3={((list || {}).collectionDetails || {}).address3}
              town={((list || {}).collectionDetails || {}).city}
              country={((list || {}).collectionDetails || {}).country}
              postCode={((list || {}).collectionDetails || {}).postalCode}
              emailAddress={((list || {}).collectionDetails || {}).email}
              telephoneNumber={((list || {}).collectionDetails || {}).contactNumber}
            />
            <Grid className={classes.contentHead}>
              <CustomCaption
                title="Customer reference"
                // value={((list || {}).collectionDetails || {}).collectionInstructions}
                value={
                  ((list || {}).additionalInformation || {}).customerReference !== null
                    ? ((list || {}).additionalInformation || {}).customerReference
                    : '-'
                }
              />
            </Grid>

            <Grid className={classes.contentHead}>
              <CustomCaption
                title="Collection Instructions"
                value={
                  ((list || {}).collectionDetails || {}).collectionInstructions !== null
                    ? ((list || {}).collectionDetails || {}).collectionInstructions
                    : '-'
                }
              />
            </Grid>
            <Grid className={classes.contentHead}>
              <CustomCaption
                title="Description of goods to be collected"
                value={
                  ((list || {}).collectionDetails || {}).desOfGoodsCollected !== null
                    ? ((list || {}).collectionDetails || {}).desOfGoodsCollected
                    : '-'
                }
              />
            </Grid>
          </Grid>
          <Divider orientation="vertical" flexItem className="deliveryAddressDivider" style={{ marginTop: '18px' }} />
          <Grid item spacing={3} direction="row" lg={5} style={{ paddingTop: '4px', paddingLeft: '20px' }}>
            <Grid className={classes.customTitle}>
              <b>Delivery details</b>
            </Grid>
            <AddressFormate
              shipmentManagement={true}
              addressType={((list || {}).deliveryAddress || {}).addressType}
              contactName={((list || {}).deliveryAddress || {}).recipientName}
              businessName={((list || {}).deliveryAddress || {}).businessName}
              addressLine1={((list || {}).deliveryAddress || {}).address1}
              addressLine2={((list || {}).deliveryAddress || {}).address2}
              addressLine3={((list || {}).deliveryAddress || {}).address3}
              town={((list || {}).deliveryAddress || {}).city}
              country={((list || {}).deliveryAddress || {}).country}
              postCode={((list || {}).deliveryAddress || {}).postalCode}
              emailAddress={((list || {}).deliveryAddress || {}).email}
              telephoneNumber={((list || {}).deliveryAddress || {}).contactNumber}
            />
            <Grid item lg={6} className={classes.contentHead}>
              <CustomCaption title="Delivery type" value={((list || {}).deliveryOptions || {}).addressType} />
            </Grid>
            {(list || {}).thirdPartyCollection === false && (
              <Grid item lg={6} className={classes.contentHead}>
                <CustomCaption title="Doorstep instructions" value={doorStepInstructionsValue} />
              </Grid>
            )}
            {/* {((list || {}).deliveryOptions || {}).secureLocationDelivery ? (
              <Grid className="previewDeliveryOption" item xs={12} lg={6}>
                <CustomCaption
                  title="Leave safe location"
                  value={
                    ((list || {}).deliveryOptions || {}).leaveSafeLocation !== null
                      ? ((list || {}).deliveryOptions || {}).leaveSafeLocation
                      : '-'
                  }
                />
              </Grid>
            ) : null} */}
            <Grid item lg={6} className={classes.contentHead}>
              <CustomCaption
                title="Special instructions"
                value={
                  ((list || {}).deliveryOptions || {}).specialInstructions !== null
                    ? ((list || {}).deliveryOptions || {}).specialInstructions
                    : '-'
                }
              />
            </Grid>

            <Grid item lg={6} className={classes.contentHead}>
              <Grid className={classes.customTitle}>
                <b>Service</b>
              </Grid>
              <CustomCaption title="Service required" value={((list || {}).productDetail || {}).service} />
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={3} direction="row" justify="flex-end" alignItems="flex-end" className="Top-10px">
        <Grid item>
          <InputButton
            variant="text"
            className="text-btn"
            style={{ textTransform: 'unset' }}
            label="Back"
            onClick={() => backToBookedCollection()}
          />
        </Grid>
        <Grid item>
          <InputButton
            className="outlined-btn"
            variant="outlined"
            label="Cancel collection"
            onClick={cancelCollection}
          />
        </Grid>
        <Grid item style={{ marginRight: '1%' }}>
          <InputButton
            className="outlined-btn"
            variant="outlined"
            label="Email label"
            onClick={() => setShowEmailConfirmation(true)}
          />
        </Grid>
      </Grid>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={newEmailValidationSchema}
        innerRef={formikRef}
      >
        {({ values, setFieldValue, errors, setFieldError, setErrors, setFieldTouched }) => (
          <Form id="Email Form">
            <CustomDialog
              maxWidth="sm"
              open={showEmailConfirmation}
              onClose={() => setShowEmailConfirmation(false)}
              title="Email Address"
            >
              <>
                <Grid container direction="row" justify="flex-start" spacing={2}>
                  <Grid item>
                    <FormRenderer {...formField.sendLabelEmailAddress.props} fullWidth />
                  </Grid>
                </Grid>
                <Grid container direction="row" justify="flex-end" spacing={2}>
                  <Grid item>
                    <InputButton
                      label="Cancel"
                      onClick={() => setShowEmailConfirmation(false)}
                      variant="outlined"
                      className="outlined-btn"
                    />
                  </Grid>
                  <Grid item>
                    <InputButton
                      label="Confirm"
                      onClick={() => handleSubmit(values, errors, true)}
                      variant="outlined"
                      className="outlined-btn"
                      buttonType="submit"
                    />
                  </Grid>
                </Grid>
              </>
            </CustomDialog>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetails: state.auth.userDetails,
  };
};

export default connect(mapStateToProps, null)(BookedCollectionSummary);

const emailLabelForm = {
  sendLabelEmailAddress: {
    props: {
      name: 'sendLabelEmailAddress',
      label: 'Email address',
      type: 'text',
      isRequired: true,
      inputProps: {
        maxLength: 70,
      },
    },
    validation: {
      name: 'sendLabelEmailAddress',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Email address is required'],
        },
        {
          type: 'max',
          params: [70, 'Maximum 70 characters are allowed'],
        },
        {
          type: 'email',
          params: ['Invalid email address'],
        },
        {
          type: 'matches',
          params: [
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            'Invalid email address',
          ],
        },
      ],
    },
  },
};
