import React, { useEffect, useRef, useState } from 'react';
import { Grid, Paper } from '@material-ui/core';
import CustomAccordion from '../../../../framework/accordion/customAccordion';
import { Formik } from 'formik';
import PrinterQueueForm from '../../../../utils/formConfig/configuration/printerQueue/printerQueueForm';
import FormRenderer from '../../../../framework/Inputs/formRenderer/formRenderer';
import PrinterQueueList from './printerQueueList/printerQueueList';
import { axiosConfig } from '../../../../api/axios';
import { ServiceEndPoints } from '../../../../utils/constants/ApiConstant';
import { getCustomerAccountID, setDropdownValues } from '../../../../utils/helperFunctions';
import InputButton from '../../../../framework/Inputs/inputButton/inputButton';
import { getAvaiablePrinters } from '../../../../utils/PrintHelper';
import { getYupSchemaFromMetaData } from '../../../../utils/yupSchema/yupSchemaGenerator';
import { useDispatch } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import './printerQueue.scss';

const { formField } = PrinterQueueForm;

const PrinterQueue = (props) => {
  const [printers, setPrinters] = useState([]);
  const [printerQueueDefaults, setPrinterQueueDefaults] = useState({});
  const dispatch = useDispatch();

  const formikRef = useRef(null);
  const initialValues = {
    defaultPrinter: '',
    printerQueueName: '',
    printerToPrint: '',
    printerQueueList: [],
  };

  const createKeyValuePair = (arrayList) => {
    return arrayList?.map((item) => {
      return {
        name: item,
        value: item,
      };
    });
  };

  const validationSchema = getYupSchemaFromMetaData(formField, [], []);

  const handleSubmit = () => {
    const formikValues = formikRef.current.values;
    const printerQueues = formikValues.printerQueueList?.map((res) => {
      return {
        queueName: res?.printerQueueName,
        printerName: res?.printerToPrint?.value,
      };
    });
    const reqObj = {
      printerQueueDefault: formikValues.defaultPrinter.value,
      printerQueue: printerQueues,
    };
    axiosConfig
      .patch(`${ServiceEndPoints.printerQueue}?organization=${getCustomerAccountID()}`, reqObj)
      .then((res) => {
        dispatch(actions.setAppSnackBarData({ msg: "Data saved successfully" }))
       // console.log(res);
      })
      .catch((err) => {
        console.log(err);
        dispatch(actions.setAppSnackBarData({ type: "error", msg: "Something went wrong, please try again" }))
      });
  };

  useEffect(() => {
    getAvaiablePrinters()
      .then((res) => {
        setPrinters(createKeyValuePair(res));
      })
      .catch((err) => console.log(err));
    axiosConfig
      .get(`${ServiceEndPoints.getOrderDefaults}?organization=${getCustomerAccountID()}`)
      .then((res) => {
       // console.log(res);
        setPrinterQueueDefaults(res.data.printerQueueDefaults);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (Object.keys(printerQueueDefaults).length > 1 && printers.length > 1) {
      formikRef.current.setFieldValue(
        'defaultPrinter',
        setDropdownValues(printers, 'value', printerQueueDefaults?.printerQueueDefault ?? ''),
      );
      const printerQueueList = printerQueueDefaults?.printerQueue?.map((res) => {
        return {
          printerQueueName: res?.queueName ?? '',
          printerToPrint: setDropdownValues(printers, 'value', res?.printerName ?? ''),
        };
      });
      formikRef.current.setFieldValue('printerQueueList', printerQueueList);
    }
  }, [printerQueueDefaults, printers]);

  return (
    <Paper>
      <Grid container>
        <Formik
          initialValues={initialValues}
          innerRef={formikRef}
          validationSchema={validationSchema}
          // onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ values, setFieldValue, setFieldTouched, errors, resetForm }) => (
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <CustomAccordion heading="Default printer queue">
                      <Grid container direction="row">
                        <Grid item xs={5}>
                          <FormRenderer {...formField.defaultPrinter.props} data={printers} fullWidth />
                        </Grid>
                        <Grid item></Grid>
                      </Grid>
                    </CustomAccordion>
                  </Grid>
                  <Grid item>
                    <PrinterQueueList
                      formikValues={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      availablePrinters={printers}
                      formField={formField}
                      errors={errors}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container className="btn-container" direction="row" justify="flex-end">
                  <Grid item>
                    <InputButton
                      label="Save changes"
                      variant="contained"
                      className="contained-btn"
                      buttonType="submit"
                      onClick={handleSubmit}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Formik>
      </Grid>
    </Paper>
  );
};

PrinterQueue.propTypes = {};

export default PrinterQueue;
