import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import FormRenderer from '../../../../../framework/Inputs/formRenderer/formRenderer';
import newDomesticConsignmentForm from '../../../../../utils/formConfig/newDomesticConsignmentForm/newDomesticConsignmentForm';
import PropTypes from 'prop-types';
import './InvoiceTypeDeclaration.scss';
const InvoiceTypeDeclaration = (props) => {
  const { setFieldValue, formikValues } = props;

  useEffect(() => {
    if (!formikValues.InvoiceDate) {
      setFieldValue('InvoiceDate', Date());
    }
  }, [formikValues.InvoiceDate, setFieldValue]);

  return (
    <Grid className="invoiceType-container">
      <Grid container spacing={3} direction="row" alignItems="flex-start">
        <Grid item xs={12} sm={8} md={6} lg={6} alignItems="flex-start" fullWidth>
          <FormRenderer
            {...newDomesticConsignmentForm.formField.invoiceTypeLabel.props}
            data={invoiceTypeLabelOptions}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        direction="row"
        alignItems="flex-start"
        justify="space-around"
        style={{ paddingTop: '0px' }}
      >
        <Grid item xs={12} sm={8} md={6} lg={6} fullWidth style={{ paddingRight: '34px' }}>
          <FormRenderer {...newDomesticConsignmentForm.formField.InvoiceDate.props} fullWidth />
        </Grid>
        <Grid item xs={12} sm={8} md={6} lg={6} fullWidth>
          <FormRenderer {...newDomesticConsignmentForm.formField.InvoiceNumber.props} fullWidth />
        </Grid>
      </Grid>
    </Grid>
  );
};

InvoiceTypeDeclaration.propTypes = {
  formField: PropTypes.object,
};

export default InvoiceTypeDeclaration;

const invoiceTypeLabelOptions = [
  {
    value: 'commercial',
    label: 'Commercial',
  },
  {
    value: 'proforma',
    label: 'Proforma',
  },
];
