import React from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import SearchSchemas from './SearchSchemas';
import { getYupSchemaFromMetaData } from '../../../../../utils/yupSchema/yupSchemaGenerator';

const SearchMySchemas = (props) => {
  const formField = searchForm;
  const newSearchValidationSchema = getYupSchemaFromMetaData(formField, [], []);

  const initialValues = {
    schemaSearchFor: '',
  };

  return (
    <div>
      <Formik initialValues={initialValues} enableReinitialize={true} validationSchema={newSearchValidationSchema}>
        {({ values, setFieldValue, errors, setFieldTouched }) => (
          <Form >
            <SearchSchemas
              formField={formField}
              formikValues={values}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              onAddressChange={props.onAddressChange}
              errors={errors}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SearchMySchemas;

const searchForm = {
  schemaSearchFor: {
    props: {
      name: 'schemaSearchFor',
      label: 'Search by',
      type: 'text',
      placeholder: 'Schema name',
    },
  },
};

SearchMySchemas.propTypes = {
  onAddressChange: PropTypes.func,
};
