import React from 'react';
import { connect } from 'react-redux';
import Badge from '@material-ui/core/Badge';
import { Grid, AppBar, Tabs, Tab, Backdrop, SvgIcon, IconButton } from '@material-ui/core/';
import TabPanel from '../../../components/shared/tabPanel';
import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';
import { GET, POST } from '../../../api/axios';
import Orders from '../../../components/addShipment/processOrders/orders';
import ReviewOrders from '../../../components/addShipment/processOrders/reviewOrders';
import Collections from '../../../components/addShipment/processOrders/collections';
import ReviewCollections from '../../../components/addShipment/processOrders/reviewCollections';
import SearchProcessOrders from '../../../components/addShipment/processOrders/searchProcessOrders';
import './processOrders.scss';
import { printZplLabels, printPDF } from '../../../utils/PrintHelper';
import { customTableSort, getCustomerAccountID } from '../../../utils/helperFunctions';
import { ReactComponent as RefreshIcon } from '../../../assets/images/refresh.svg';
import { ReactComponent as uploadIcon } from '../../../assets/images/upload.svg';
import CustomDialog from '../../../framework/dialog/customDialog';
import UploadFile from '../../../components/addShipment/uploadFile/uploadFile';
import * as actions from '../../../store/actions/index';
import CircularProgressWithLabel from '../../../framework/spinner/circularProgressBar';
import CustomModal from '../../../framework/dialog/customDialog';
import ConfirmationDialog from '../../../components/addressBook/confirmation/confirmation';

class ProcessOrders extends React.Component {
  constructor(props) {
    super(props);
    this.counter = 0;
    this.success = 0;
    this.failed = 0;
    this.collectionRequest = false;
    this.allOrders = [];
    this.state = {
      formikRef: React.createRef(),
      tabIndex: 0,
      tabIndex_className: 'Orders-Tab-panel',
      completionPercentage: 0,
      isSearched: false,
      isOnlyOneItem: false,
      isScanFlag: false,
      isScanPrintFlag: false,
      inProgress: false,
      reviewOrderCount: '0',
      showConfirmationDialog: false,
      isRefersFlag: false,
      // Collectionscolumns: [
      //   { title: 'id', field: 'id', hidden: true, width: 0 },
      //   {
      //     title: 'Customer reference',
      //     field: 'customerReference',
      //     // customSort: (a, b) => customTableSort(a.customerReference, b.customerReference),
      //     width: 150,
      //   },
      //   {
      //     title: 'Business/Customer name',
      //     field: 'customerName',
      //     sorting: true,
      //     width: 150,
      //   },
      //   {
      //     title: 'Source',
      //     field: 'source',
      //     sorting: true,
      //     width: 200,
      //   },
      //   {
      //     title: 'Postal code',
      //     field: 'postalCode',
      //     sorting: true,
      //     width: 80,
      //   },
      //   {
      //     title: 'Alternative reference',
      //     field: 'alternateReference',
      //     sorting: true,
      //     width: 80,
      //   },
      //   {
      //     title: 'Contact',
      //     field: 'contact',
      //     sorting: true,
      //     width: 100,
      //   },
      //   {
      //     title: 'Collection date',
      //     field: 'collectionDate',
      //     sorting: true,
      //     width: 90,
      //   },
      // ],
      ReviewCollectionsTotalCounts: '0',
      openModal: false,
    };
    this.onProcessOrdersChange = this.onProcessOrdersChange.bind(this);
    this.onProcessOrdersSelectedItem = this.onProcessOrdersSelectedItem.bind(this);
    this.onProcessOrdersScanFalgChange = this.onProcessOrdersScanFalgChange.bind(this);
    this.deleteSelectedOrder = this.deleteSelectedOrder.bind(this);
    this.deleteAllOrder = this.deleteAllOrder.bind(this);
    this.createAllShipments = this.createAllShipments.bind(this);
    this.createSelectedShipments = this.createSelectedShipments.bind(this);
    this.setReviewOrderCount = this.setReviewOrderCount.bind(this);
    this.reviewCollectionsTotalCounts = this.reviewCollectionsTotalCounts.bind(this);
    this.createAllCollections = this.createAllCollections.bind(this);
    this.createSelectedCollections = this.createSelectedCollections.bind(this);
    this.refreshProcessOrder = this.refreshProcessOrder.bind(this);
    this.refreshProcessOrder = this.UploadrefreshOrder.bind(this);
    this.pauseCount = this.props?.printerConfiguration?.pauseCount ?? 50;
    this.getUpdatedCounts();
  }
  reviewCollectionsTotalCounts = (count) => {
    this.setState({ ReviewCollectionsTotalCounts: count });
  };

  setReviewOrderCount = (orderCount) => {
    this.setState({ reviewOrderCount: orderCount });
  };
  deleteAllOrder = async (isCollectionRequest, isValid) => {
    await POST(`${ServiceEndPoints.deleteOrders}/delete?organization=${getCustomerAccountID()}&isDeleteAll=true&isValid=${isValid}&iscollectionrequest=${isCollectionRequest}`, [])
      .then((response) => {
        this.props.setSnackBarMsg({ msg: 'Shipment(s) deleted successfully' });
        this.refreshProcessOrder(true);
      })
      .catch((error) => {
        this.props.setSnackBarMsg({ type: 'error', msg: 'Orders were not deleted, please try again.' });
        this.refreshProcessOrder();
      });
  }
  deleteSelectedOrder = (orders) => {
    const itemsToBeDeleted = orders.map((order) => {
      return order.id;
    });
    const payload = itemsToBeDeleted;
    POST(`${ServiceEndPoints.deleteOrders}/delete`, payload)
      .then((response) => {
        this.props.setSnackBarMsg({ msg: 'Shipment(s) deleted successfully' });
        this.refreshProcessOrder(true);
      })
      .catch((error) => {
        this.props.setSnackBarMsg({ type: 'error', msg: 'Orders were not deleted, please try again.' });
        this.refreshProcessOrder();
      });
  };

  getUpdatedCounts = () => {
    const orderUrl = `${ServiceEndPoints.getOrders
      }?organization=${getCustomerAccountID()}&isValid=false&isCollectionRequest=false&offset=0&limit=1`;
    GET(orderUrl)
      .then((res) => {
        this.setReviewOrderCount(res?.data?.totalRecords ?? '0');
      })
      .catch((err) => {
        console.log(err);
        this.setReviewOrderCount('0');
      });

    const collectionUrl = `${ServiceEndPoints.getOrders
      }?organization=${getCustomerAccountID()}&isValid=false&isCollectionRequest=true&offset=0&limit=1`;
    GET(collectionUrl)
      .then((res) => {
        this.reviewCollectionsTotalCounts(res?.data?.totalRecords ?? '0');
      })
      .catch((err) => {
        console.log(err);
        this.reviewCollectionsTotalCounts('0');
      });
  };

  printLabels = (res, isCollectionRequest,order) => {
    const labels = [];
    const invoices = [];
    res.forEach((shipment) => {
      shipment?.shipments[0]?.labels?.forEach((label) => {
        labels.push(label);
      });
      shipment?.shipments[0]?.invoices?.forEach((invoice) => {
        invoices.push(invoice);
      });
    });
    if (labels.length > 0) {
      this.success += 1;
    } else this.failed += 1;
    !isCollectionRequest && labels.map((label) => {
      if (this.props.printerConfiguration.labelType == 1) {
        printZplLabels(label, order?.printerQueueName || this.props.printerConfiguration?.labelPrinter);
      } else {
        printPDF(label, order?.printerQueueName || this.props.printerConfiguration?.labelPrinter, "Label");
      }
    });
    !isCollectionRequest && invoices.map((invoice) => {
      printPDF(invoice, this.props.printerConfiguration?.otherReportsPrinter, "Invoice");
    });
    // return true;
  }

  createShipmentsFromOrders = (order, isCollectionRequest) => {
    const url = `${ServiceEndPoints.createOrders
      }?organization=${getCustomerAccountID()}&isCreateAll=false&isCollectionRequest=${isCollectionRequest}&labelFormat=${this.props?.printerConfiguration?.labelType == 2 ? "PDF" : "ZPL"}`;
    const payload = [order.id];
    return POST(url, payload)
      .then((response) => {
        this.printLabels(response.data,isCollectionRequest,order);
        return true;
      })
      .catch((err) => {
        console.log(err);
        this.failed += 1;
        return false;
      });
  };
  getIdsWithStatus = (type, status = 'Order processing') => {
    let url = `${ServiceEndPoints.orderswithstatus
      }?organization=${getCustomerAccountID()}&status=${status}&type=${type}`;
      url += this.props.sortOrderState;
    return GET(url)
      .then((res) => {
        return res.data.orders.map(order => { return { ...order, id: order.orderId } }).filter((val) => val.id);
      })
      .catch((err) => {
        console.log(err);
        return [];
      });
  };

  createOrders = async (orders, isCollectionRequest = false, counter = 0, success = 0, failed = 0) => {
    this.counter = counter;
    this.collectionRequest = isCollectionRequest;
    this.setState({ inProgress: true, showConfirmationDialog: false });
    let flag = true;
    this.success = success;
    this.failed = failed;
    let len = orders.length;
    while (this.counter < len) {
      await this.createShipmentsFromOrders(orders[this.counter], this.collectionRequest);
      this.counter++;
      this.setState({
        completionPercentage: (this.counter / len) * 100,
      });
      if (this.counter > 0 && this.counter !== len && this.counter % this.pauseCount === 0) {
        this.setState({
          inProgress: false,
          showConfirmationDialog: true,
        });
        flag = false;
        break;
      }
    }
    if (flag) {
      setTimeout(() => {
        this.setState({
          inProgress: false,
          completionPercentage: 0,
        });
      }, 1000);
      this.props.setSnackBarMsg({ msg: `${this.success} shipment(s) created successfully` });
      this.refreshProcessOrder(true);
    }
  };
  createAllShipments = async () => {
    this.allOrders = await this.getIdsWithStatus('order');
    if (this.allOrders.length > 0) {
      this.setState({
        inProgress: true,
      });
      this.allOrders.length > 0 && this.createOrders(this.allOrders);
    }
  };

  createSelectedShipments = (orders) => {
    this.allOrders = orders;
    this.setState({
      inProgress: true,
    });
    this.createOrders(this.allOrders);
  };

  createAllCollections = async () => {
    this.allOrders = await this.getIdsWithStatus('collection');
    if (this.allOrders.length > 0) {
      this.setState({
        inProgress: true,
      });
      this.allOrders.length > 0 && this.createOrders(this.allOrders, true);
    }
  };

  createSelectedCollections = (orders) => {
    this.allOrders = orders;
    this.setState({
      inProgress: true,
    });
    this.createOrders(this.allOrders, true);
  };

  setRefreshList = (value) => {
    this.setState({
      refreshList: value
    })
  }

  UploadrefreshOrder = (isRefersFlag = false) => {
    this.setState({ isRefersFlag: isRefersFlag });
  }
  refreshProcessOrder = (refreshAllCounts = false) => {
    refreshAllCounts && this.getUpdatedCounts();
    this.setState({ isSearched: true, refreshList: true });
  };

  onProcessOrdersSelectedItem(value) {
    this.setState({ isOnlyOneItem: value })
  }
  onProcessOrdersChange(value) {
    // this.setState({ isSearched: value });
    this.setState({ isSearched: !this.state.isSearched })
    // this.refreshProcessOrder();
  }

  onProcessOrdersScanFalgChange(value, flag) {
    if (flag) {
      this.setState({ isScanPrintFlag: (value) ? value : false })
    } else {
      this.setState({ isScanFlag: (value) ? value : false })
    }
  }

  handleTabChange = (event, index) => {
    this.setState({ isSearched: false, isRefersFlag: true});
    this.setState({ tabIndex: index });
    this.props._setAddressBookSearchParams('');
    if (index === 0) {
      this.setState({ tabIndex_className: 'Orders-Tab-panel' });
    } else if (index === 1) {
      this.setState({ tabIndex_className: 'Review-Orders-Tab-panel' });
    } else if (index === 2) {
      this.setState({ tabIndex_className: 'Collections-Tab-panel' });
    } else if (index === 3) {
      this.setState({ tabIndex_className: 'Review-Collections-Tab-panel' });
    }
  };

  componentDidMount = () => {
    if (
      this.props.history.location &&
      this.props.history.location.state !== undefined &&
      this.props.history.location.state !== null
    ) {
      if (this.props.history.location.state.isScanFlag !== undefined) {
        this.state.formikRef.current.setFieldValue('isScan', this.props.history.location.state.isScanFlag);
      }
      if (
        this.props.history.location.state.isCollectionRequest &&
        this.props.history.location.state.isReviewCollection
      ) {
        this.setState({ tabIndex: 3 });
      } else if (
        this.props.history.location.state.isCollectionRequest &&
        !this.props.history.location.state.isReviewCollection
      ) {
        this.setState({ tabIndex: 2 });
      } else if (
        !this.props.history.location.state.isCollectionRequest &&
        this.props.history.location.state.isProcessOrder
      ) {
        this.setState({ tabIndex: 0 });
      } else if (
        !this.props.history.location.state.isCollectionRequest &&
        !this.props.history.location.state.isProcessOrder
      ) {
        this.setState({ tabIndex: 1 });
      }
    }
  };

  handleModal = () => {
    this.setState({ openModal: this.state.openModal ? false : true });
  };

  handleDialogClose = () => {
    this.setState({ showConfirmationDialog: false, completionPercentage: 0 });
    this.props.setSnackBarMsg({ msg: `${this.success} shipment(s) created successfully` });
    this.refreshProcessOrder();
  };

  render() {
    return (
      <Grid container className="ProcessOrders-Container">
        <Grid item xs={6} sm={12} md={12} lg={12} justify="flex-end" style={{ display: 'flex', marginTop: '-40px' }}>
          <IconButton
            className="refr-btn"
            onClick={() => {
              this.refreshProcessOrder(true);
            }}
          >
            Refresh <SvgIcon component={RefreshIcon} viewBox="0 0 48 48" style={{ fontSize: '2.5rem' }} />
          </IconButton>
        </Grid>
        <AppBar position="static" className="ProcessOrders-AppBar">
          <Grid container spacing={2} direction="row">
            <Grid item xs={12} sm={12} lg={12} style={{ position: 'relative' }}>
              <Tabs
                value={this.state.tabIndex}
                onChange={this.handleTabChange}
                className={this.state.tabIndex_className}
              >
                <Tab label="Orders" className="Tab-panel Orders-child-Tab-panel" />
                <Tab
                  label={
                    <Grid container direction="row">
                      <Grid item>
                        <label>Review orders</label>
                      </Grid>
                      <Grid item>
                        <Badge
                          style={{
                            marginLeft:
                              this.state.reviewOrderCount < 1000
                                ? this.state.reviewOrderCount < 100
                                  ? '20px'
                                  : '25px'
                                : '30px',
                          }}
                          badgeContent={this.state.reviewOrderCount}
                          max={this.state.reviewOrderCount}
                          color="error"
                        ></Badge>
                      </Grid>
                    </Grid>
                  }
                  className="Tab-panel"
                ></Tab>
                <Tab label="Collections" className="Tab-panel collection-child-Tab-panel" />
                <Tab
                  label={
                    <Grid container direction="row">
                      <Grid item>
                        <label>Review collections</label>
                      </Grid>
                      <Grid item>
                        <Badge
                          style={{
                            marginLeft:
                              this.state.ReviewCollectionsTotalCounts < 1000
                                ? this.state.ReviewCollectionsTotalCounts < 100
                                  ? '20px'
                                  : '25px'
                                : '30px',
                          }}
                          badgeContent={this.state.ReviewCollectionsTotalCounts}
                          max={this.state.ReviewCollectionsTotalCounts}
                          color="error"
                        ></Badge>
                        {/* <label style={{ marginLeft: '15px' }}>{this.state.ReviewCollectionsTotalCounts}</label> */}
                      </Grid>
                    </Grid>
                  }
                  className="Tab-panel"
                />
              </Tabs>
              <div className="Upload-File" onClick={() => this.handleModal()}>
                <SvgIcon component={uploadIcon} viewBox="0 0 48 48" style={{ fontSize: '2.5rem', fill: '#000' }} />
                <span>Upload file</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} lg={12} className={''}>


              <SearchProcessOrders
                tabIndex={this.state.tabIndex}
                onProcessOrdersChange={this.onProcessOrdersChange}
                onProcessOrdersScanFalgChange={this.onProcessOrdersScanFalgChange}
                onProcessOrdersSelectedItem={this.onProcessOrdersSelectedItem}
                totalRecords={this.state.totalRecords}
                isScanFlag={this.state.isScanFlag}
                isScanPrintFlag={this.state.isScanPrintFlag}
                isOnlyOneItem={this.state.isOnlyOneItem}
                formikRef={this.state.formikRef}
                isRefersFlag={this.state.isRefersFlag}
                UploadrefreshOrder={this.UploadrefreshOrder}
              />
            </Grid>
          </Grid>
        </AppBar>
        <>
          <TabPanel className="Orders-Tab" value={this.state.tabIndex} index={0} style={{ width: '100%' }}>
            <Orders
              isSearched={this.state.isSearched}
              isScanFlag={this.state.isScanFlag}
              isOnlyOneItem={this.state.isOnlyOneItem}
              isScanPrintFlag={this.state.isScanPrintFlag}
              deleteSelectedOrder={this.deleteSelectedOrder}
              deleteAllOrder={this.deleteAllOrder}
              createAllShipments={this.createAllShipments}
              createSelectedShipments={this.createSelectedShipments}
              onProcessOrdersSelectedItem={this.onProcessOrdersSelectedItem}
              printLabels={this.printLabels}
              tabIndex={this.state.tabIndex}
              isRefersFlag={this.state.isRefersFlag}
              UploadrefreshOrder={this.UploadrefreshOrder}
              refreshList={this.state.refreshList}
              setRefreshList={this.setRefreshList}
            />
          </TabPanel>
          <TabPanel className="Review-Orders-Tab" value={this.state.tabIndex} index={1} style={{ width: '100%' }}>
            <ReviewOrders
              isSearched={this.state.isSearched}
              isScanFlag={this.state.isScanFlag}
              deleteSelectedOrder={this.deleteSelectedOrder}
              deleteAllOrder={this.deleteAllOrder}
              tabIndex={this.state.tabIndex}
              setReviewOrderCount={this.setReviewOrderCount}
              refreshList={this.state.refreshList}
              setRefreshList={this.setRefreshList}
            />
          </TabPanel>
          <TabPanel className="Collections-Tab" value={this.state.tabIndex} index={2} style={{ width: '100%' }}>
            <Collections
              isSearched={this.state.isSearched}
              createAllCollections={this.createAllCollections}
              createSelectedCollections={this.createSelectedCollections}
              tabIndexes={this.state.tabIndex}
              deleteSelectedOrder={this.deleteSelectedOrder}
              deleteAllOrder={this.deleteAllOrder}
              tabIndex={this.state.tabIndex}
              refreshList={this.state.refreshList}
              setRefreshList={this.setRefreshList}
              isScanFlag={this.state.isScanFlag}
            />
          </TabPanel>
          <TabPanel className="Review-Collections-Tab" value={this.state.tabIndex} index={3} style={{ width: '100%' }}>
            <ReviewCollections
              isSearched={this.state.isSearched}
              reviewCollectionsTotalCounts={this.reviewCollectionsTotalCounts}
              deleteSelectedOrder={this.deleteSelectedOrder}
              deleteAllOrder={this.deleteAllOrder}
              refreshList={this.state.refreshList}
              tabIndex={this.state.tabIndex}
              setRefreshList={this.setRefreshList}
              isScanFlag={this.state.isScanFlag}
            />
          </TabPanel>
        </>
        <CustomDialog
          maxWidth="sm"
          className="process-upload-dialog"
          open={this.state.openModal}
          onClose={() => this.handleModal()}
          title="Upload file"
        >
          <UploadFile
            handleModal={this.handleModal}
            refreshGrid={() => {
              this.refreshProcessOrder(true);
            }}
            UploadRefreshGrid={() => {
              this.UploadrefreshOrder(true);
            }}
          />
        </CustomDialog>

        <Backdrop style={{ zIndex: 2000 }} open={this.state.inProgress}>
          <CircularProgressWithLabel
            value={this.state.completionPercentage}
            text={`${this.counter}/${this.allOrders.length}`}
          />
        </Backdrop>
        <CustomModal
          open={this.state.showConfirmationDialog}
          title=""
          onClose={() => {
            this.handleDialogClose();
          }}
          maxWidth="sm"
        >
          <ConfirmationDialog
            confirmationMessage={'Please change the label roll for the printer'}
            onConfirm={() => {
              this.createOrders(this.allOrders, this.collectionRequest, this.counter, this.success, this.failed);
            }}
            OnCancel={() => {
              this.handleDialogClose();
            }}
          />
        </CustomModal>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    JSPMStatus: state.common.isJSPMInstalled,
    printerConfiguration: state.common.printerConfiguration,
    availablePrinter: state.common.availablePrinter,
    genralConfiguration: state.common.commonConfiguration,
    sortOrderState: state.common.sortOrderState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSnackBarMsg: (data) => {
      dispatch(actions.setAppSnackBarData(data));
    },
    _setAddressBookSearchParams: (data) => {
      dispatch(actions.setAddressBookSearchParams(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProcessOrders);
