import React, { useState, useEffect } from 'react';
import CustomAccordion from '../../../../framework/accordion/customAccordion';
import SchemaDetailsField from './SchemaDetailsField';
import SchemaTabCreations from './SchemaTabCreations';

const MainSchemaDetails = (props) => {
  const {
    formField,
    setFieldValue,
    errors,
    setFieldTouched,
    formikValues,
    setIsValid,
    requiredField,
    setTotalMappingDetails,
    oldSchemaName,
    uploadedFile,
    setUploadedFile,
    resetForm,
    isClear,
    setSchemaNameExist,
    setValues,
    setIsClear,
    setFolderPathExist,
    existingFolderPaths,
    folderPathExist
  } = props;

  const [resultedSchema, setResultedSchema] = useState();
  const [value, setTabValue] = useState(0);
  const [dropdownValues, setDropdownValues] = useState([]);
  const [currentSelectedValue, setCurrentValue] = useState("");
  const [currentValueStartLength, setCurrentValueStartLength] = useState([0, 0]);
  const [currentRowIndicatorValue, setCurrentRowIndicatorValue] = useState("");
  const [currentRowIndicatorPlacement, setCurrentRowIndicatorPlacement] = useState();
  const [isDefaultSchema, setIsDefaultSchema] = useState(false);


  useEffect(() => {
    setSchemaDetails(formikValues);
  }, [formikValues]);

  const setSchemaDetails = (data) => {
    props.setTotalSchemaDetails(data);
  };

  const getResultedSchemaDetails = (data) => {
    setResultedSchema(data);
  };
  const getFieldSchemaDetails = (fields) => {
    setTotalMappingDetails(fields);
  };

  const setStartAndLengthValues = (arr) => {
    let [start, length] = arr;
    start = parseInt(start);
    length = parseInt(length);
    start = isNaN(start) ? 0 : start;
    length = isNaN(length) ? 0 : length;
    setCurrentValueStartLength([start, length])
  }

  return (
    <div>
      <CustomAccordion heading="Schema details" className="schemaDetails">
        <SchemaDetailsField
          formField={formField}
          formikValues={formikValues}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          getResultedSchemaDetails={getResultedSchemaDetails}
          oldSchemaName={oldSchemaName}
          setDropdownValues={setDropdownValues}
          dropdownValues={dropdownValues}
          currentSelectedValue={currentSelectedValue}
          currentValueStartLength={currentValueStartLength}
          currentRowIndicatorValue={currentRowIndicatorValue}
          setCurrentValue={setCurrentValue}
          setCurrentValueStartLength={setStartAndLengthValues}
          currentRowIndicatorPlacement={currentRowIndicatorPlacement}
          uploadedFile={uploadedFile}
          setUploadedFile={setUploadedFile}
          resetForm={resetForm}
          setSchemaNameExist={setSchemaNameExist}
          mappingDetails={resultedSchema}
          currentTab={value}
          setValues={setValues}
          isDefaultSchema={isDefaultSchema}
          setIsDefaultSchema={setIsDefaultSchema}
          setFolderPathExist={setFolderPathExist}
          existingFolderPaths={existingFolderPaths}
          folderPathExist={folderPathExist}
          {...props}
        />
      </CustomAccordion>
      {/* {uploadedFile ? */}
      <SchemaTabCreations
        formField={formField}
        formikValues={formikValues}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        mappingDetails={resultedSchema}
        errors={errors}
        getFieldSchemaDetails={getFieldSchemaDetails}
        requiredField={requiredField}
        setIsValid={setIsValid}
        dropdownValues={dropdownValues}
        setCurrentValue={setCurrentValue}
        setDropdownValues={setDropdownValues}
        setCurrentValueStartLength={setStartAndLengthValues}
        setCurrentRowIndicatorValue={setCurrentRowIndicatorValue}
        setCurrentRowIndicatorPlacement={setCurrentRowIndicatorPlacement}
        setTabValue={setTabValue}
        currentTab={value}
        isClear={isClear}
        isDefaultSchema={isDefaultSchema}
        setIsClear={setIsClear}
      />
      {/* : null} */}

    </div>
  );
};

export default MainSchemaDetails;
