import React from 'react';
import { useField } from 'formik';
import { at } from 'lodash';
import { PropTypes } from 'prop-types';
import './inputCheckbox.scss';

import { FormControl, FormControlLabel, FormHelperText, Checkbox, FormGroup } from '@material-ui/core';

const InputCheckbox = (props) => {
  const { label, isDisabled, labelPlacement, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const { setValue } = helper;

  const renderHelperText = () => {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  };

  const onChange = (e) => {
    setValue(e.target.checked);
  };

  return (
    <FormControl {...rest}>
      <FormGroup aria-label="position" row>
        <FormControlLabel
          className="main-checkbox"
          value={field.checked}
          checked={field.checked}
          control={<Checkbox {...field} disabled={isDisabled} onChange={onChange} />}
          label={label}
          labelPlacement={labelPlacement}
        />
        {renderHelperText()}
      </FormGroup>
    </FormControl>
  );
};

InputCheckbox.propTypes = {
  label: PropTypes.string,
  isDisabled: PropTypes.bool,
};
InputCheckbox.defaultProps = {
  isDisabled: false,
  labelPlacement: 'end',
};
export default InputCheckbox;
