/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Menu, MenuItem, IconButton, Box, Divider, SvgIcon } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import FormRenderer from '../../../framework/Inputs/formRenderer/formRenderer';
import { SearchIcon } from '@material-ui/data-grid';
import ClearIcon from '@material-ui/icons/Clear';
import '../../../container/shipment/addShipment/addShipment.scss';
import { resetFormAndKeepInitialValues, setValuesToForm, sortBasedOnNestedOrNot } from '../../../utils/helperFunctions';
import { connect, useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import * as actions from '../../../store/actions/index';
import { ReactComponent as TemplateIcon } from '../../../assets/images/TemplateIcon.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/closeIcon.svg';
import DialogBox from './dialogForTemplate/dialogForTemplate';
import { useDebouncedCallback } from 'use-debounce';
import './templates.scss';
import { UserRoles } from '../../../utils/constants/constants';

const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
});
const Templates = (props) => {
  const {
    formField: { templateSearch },
    setFieldValue,
    setValues,
    templateData,
    formikValues,
    errors,
    resetForm,
    isDomestic,
    backUpResponse,
    currencies,
    countries,
    getTemplates,
    templateLimit,
    isAmendShipment
  } = props;
  const classes = useStyles();
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  let [isClearBtn, setIsClearBtn] = useState(false);
  const [open, setOpen] = React.useState(false);

  const debounced = useDebouncedCallback((value) => {
    getTemplates(value);
  }, 500);

  const handleClick = () => {
    setOpen(true);
  };

  const dispatch = useDispatch();

  const searchTemplate = (event = null) => {
    if (event) {
      event.preventDefault();
    }
    if (formikValues.templateSearch.trim() !== '') {
      setIsClearBtn(true);
      debounced(formikValues.templateSearch);
    } else {
      setIsClearBtn(false);
      debounced(formikValues.templateSearch);
    }
  };

  // const getClear = (event = null, from = '') => {
  //   setIsClearBtn(false);
  //   setFieldValue('templateSearch', '');
  //   getTemplates();
  //   if (from !== 'clearField') {
  //     dispatch(actions.setBackUpResponse({ loadedFrom: '' }));
  //     resetFormAndKeepInitialValues(
  //       resetForm,
  //       setFieldValue,
  //       formikValues.accountNumber,
  //       formikValues.destinationCountry,
  //     );
  //     setFieldValue('selectedTemplate', '');
  //     handleClose();
  //   }
  //   if (event) {
  //     event.stopPropagation();
  //   }
  // };

  const getClear = (event = null, from = '') => {
    if (from !== 'clearField') {
      if ((backUpResponse || {}).loadedFrom === 'favourites' && (backUpResponse || {}).loadedResponse) {
        setValuesToFormAndDispatchAction((backUpResponse || {}).loadedResponse);
      } else {
        dispatch(actions.setBackUpResponse({ loadedFrom: '' }));
        resetFormAndKeepInitialValues(resetForm, setFieldValue, countries, formikValues.accountNumber);
      }
      setFieldValue('selectedTemplate', '');
      setFieldValue('templateSearch', '');
    }
    setFieldValue('templateSearch', '');
    setIsClearBtn(false);

    getTemplates();
    // handleClose();
    if (event) {
      event.stopPropagation();
    }
  };

  const handleMenuClick = (templateDetails) => {
    dispatch(actions.setLoaderLayOver(true));
    // dispatch(actions.setBackUpResponse({ loadedFrom: 'template', loadedResponse: { ...templateDetails } }));
    setValuesToFormAndDispatchAction(templateDetails);
    setFieldValue('selectedTemplate', templateDetails.templateName);
    setIsClearBtn(false);
    handleClose();
  };

  const setValuesToFormAndDispatchAction = (responseObj) => {
    responseObj['destinationCountry'] = 'GBR';
    setValues(setValuesToForm(responseObj, formikValues, countries, currencies));
    dispatch(actions.setLoadedFrom({ loadedFrom: 'dropdownResponse', loadedResponse: { ...responseObj } }));
    setTimeout(() => {
      dispatch(actions.setLoaderLayOver(false));
      dispatch(actions.setLoadedFrom({ loadedFrom: '', loadedResponse: {} }));
    }, 5000);
  };

  useEffect(() => {
    sortBasedOnNestedOrNot(templateData.templates, 'templateName', false);
    setFilteredTemplates(templateData.templates);
  }, [templateData]);

  useEffect(() => {
    if (!isDomestic && formikValues.selectedTemplate) {
      setFieldValue("selectedTemplate", "")
    }
  }, [formikValues.selectedTemplate, isDomestic]);

  useEffect(() => {
    searchTemplate();
  }, [formikValues.templateSearch]);

  const handleClose = (value) => {
    setOpen(false);
    // setFieldValue('selectedTemplate', '');
    setFieldValue('templateSearch', '');
  };
  return (
    <Grid
      container
      spacing={3}
      direction="row"
      justify="space-between"
      alignItems="flex-start"
      className="shipmentContainer"
      style={{ marginLeft: '20px' }}
    >
      <Grid item></Grid>
      <Grid item className="star Favourites-mani-grid" style={{ opacity: isDomestic && !isAmendShipment && props.userDetails.role !== UserRoles.BasicShipmentEntry ? 1 : 0.5 }}>
        <SvgIcon
          component={TemplateIcon}
          style={{ height: '20px', width: '20px', marginTop: '4px', marginRight: '6px' }}
        />
        <TextField
          label="Templates"
          value={formikValues.selectedTemplate}
          InputProps={{ classes, readOnly: true }}
          style={{ marginTop: '-20px' }}
          className="template-select"
        // disabled
        />
        <IconButton
          className="arrowDown1"
          disabled={!isDomestic || isAmendShipment || props.userDetails.role === UserRoles.BasicShipmentEntry}
          onClick={handleClick}
          style={{ marginTop: '-7px', marginLeft: '-18px' }}
        >
          <KeyboardArrowDown />
        </IconButton>
        <DialogBox
          className="template-dialog"
          onClose={handleClose}
          open={open}
          data={filteredTemplates}
          isClearBtn={isClearBtn}
          onClickClear={(event) => getClear(event, 'clearField')}
        >
          <Grid style={{ width: '100%', padding: '20px' }} item>
            <FormRenderer
              style={{ boxShadow: '0px 3px 6px #00000019' }}
              {...templateSearch.props}
              endArguments={{
                endAdornment: isClearBtn
                  ? (
                    <IconButton aria-label="clear" onClick={(event) => getClear(event, 'clearField')}>
                      <ClearIcon />
                    </IconButton>
                  )
                  : (
                    <IconButton aria-label="search" type="submit" onClick={(event) => searchTemplate(event)}>
                      <SearchIcon />
                    </IconButton>
                  ),
              }}
              fullWidth
            />
          </Grid>
          <List style={{ paddingTop: '4px' }}>
            <span className="temp-label">Templates</span>
            <Divider style={{ marginTop: '5px' }} />
            {(filteredTemplates || []).length > 0
              ? (filteredTemplates || []).map((template) => (
                <Grid>
                  <ListItem
                    button
                    key={template.id.toString()}
                    onClick={() => {
                      handleMenuClick(template);
                    }}
                    style={{ pointer: 'cursor', paddingTop: '4px', paddingBottom: '4px' }}
                  >
                    <Grid container direction="row" justify="space-between">
                      <Grid item xs={10} style={{ wordWrap: 'break-word' }}>
                        <ListItemText
                          style={{ marginLeft: '6px' }}
                          primary={template.templateName ? template.templateName : ''}
                        />
                      </Grid>
                      <Grid className="template-ddl" item xs={2}>
                        {formikValues.selectedTemplate === template.templateName
                          ? (
                            <IconButton onClick={(event) => getClear(event, 'menu')} style={{ height: '0px' }}>
                              <SvgIcon fontSize="small" component={CloseIcon} viewBox="5 5 30 30" />
                            </IconButton>
                          )
                          : null}
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider />
                </Grid>
              ))
              : <Grid container direction="column">
                <ListItem
                  button
                  style={{ pointer: 'cursor', paddingTop: '4px', paddingBottom: '4px' }}
                >
                  <span style={{ Width: '100%', textAlign: 'center', paddingLeft: '70px' }}>
                    No template found
                  </span>
                </ListItem>
                <Divider style={{ marginLeft: '-35px' }} />
              </Grid>
            }
          </List>
        </DialogBox>
      </Grid>
    </Grid>
  );
};

Templates.propTypes = {
  formField: PropTypes.object,
  setFieldValue: PropTypes.func,
  formikValues: PropTypes.object,
  getTemplates: PropTypes.func,
  templateLimit: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    backUpResponse: ((state || {}).backUpResponse || {}).loadedFrom,
    userDetails: state.auth.userDetails,
  };
};

export default connect(mapStateToProps, null)(Templates);
