import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { FieldArray } from 'formik';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/DeleteIcon.svg';
import FormRenderer from '../../../../framework/Inputs/formRenderer/formRenderer';
import { mappingCategoryByOptions, mappingCategoryForMarketplaces } from '../../../../utils/constants/constants';
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    SvgIcon,
} from '@material-ui/core';
import InputButton from '../../../../framework/Inputs/inputButton/inputButton';
import { makeStyles } from '@material-ui/core/styles';
import CustomModal from '../../../../framework/modal/modal';
import ConfirmationDialog from '../../../../components/addressBook/confirmation/confirmation';

const useStyles = makeStyles({
    table: {
        minWidth: 850,
        border: '1px solid #B5B5B5',
    },
    container: {
        maxHeight: "240px",

    },
    tableHeading: {
        backgroundColor: "#fafafa",
    }
});

const AddMapping = (props) => {
    const {
        formField: { mapFrom, mapTo, mapCategory, mapToForAccount, marketPlacesAvailable},
        saveNewMappings,
        formikValues,
        setFieldValue,
        setFieldTouched,
        mappedServices,
        mappedAccounts,
        defaultAccounts,
        defaultServices,
        _isUpdatingFlag,
        setErrors,
        isMarketplaceMapping,
        marketPlacesList,
    } = props;


    const [mappingDetails, setMappingDetails] = useState([]);
    const [mappingTo, setMappingTo] = useState([]);
    const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);
    const [showDeleteConfirmationDialog, setshowDeleteConfirmationDialog] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [oldAccountDetails, setOldAccountDetails] = useState(mappedAccounts)
    const [oldServiceDetails, setOldServiceDetails] = useState(mappedServices)
    const [Delete_array_Helpers, setDelete_array_Helpers] = useState({})
    const [Deleteindex, setDeleteindex] = useState('')
    const classes = useStyles();

    useEffect(() => {
        if (formikValues.mapFrom !== undefined &&
            (formikValues.mapCategory && formikValues.mapCategory.name !== 'Select')
        ) {
            if (formikValues.mapCategory.value === "accounts" && formikValues.mapToForAccount.displayName != 'Select') {
                setIsAddButtonDisabled(false)
            }
            else if (formikValues.mapCategory.value === "services" && formikValues.mapTo && formikValues.mapTo.name !== 'Select') {
                setIsAddButtonDisabled(false)
            } else {
                setIsAddButtonDisabled(true)
            }
        }
    }, [formikValues.mapFrom, formikValues.mapTo, formikValues.mapCategory, formikValues.mapToForAccount]);

    const deleteMappedDetails = (arrayHelpers, index) => {
        _isUpdatingFlag(true)
        setDelete_array_Helpers(arrayHelpers);
        setDeleteindex(index)
        setshowDeleteConfirmationDialog(true)
    }

    const onDialogConfirm = () => {
        let arrayHelpers = Delete_array_Helpers
        let index = Deleteindex

        arrayHelpers.remove(index)
        if (selectedCategory === 'accounts') {
            setOldAccountDetails((oldAccountDetails) => {
                const newMappingDetails = [...oldAccountDetails];
                newMappingDetails.splice(index, 1);

                return newMappingDetails;
            });
        }
        if (selectedCategory === 'services') {
            setOldServiceDetails((oldServiceDetails) => {
                const newMappingDetails = [...oldServiceDetails];
                newMappingDetails.splice(index, 1);

                return newMappingDetails;
            });
        }
        setshowDeleteConfirmationDialog(false);
    }
    const pushToParcelDetails = (arrayHelpers) => {
        if (formikValues.mapFrom === "" || formikValues.mapFrom === undefined) {
            let errors_object = {};
            errors_object.mapFrom = "Map from field is required";
            setFieldTouched('mapFrom', true);
            setErrors(errors_object);
            return;
        }
        _isUpdatingFlag(true)
        const rowDetails = {
            mapFrom: formikValues.mapFrom,
            mappedTo: selectedCategory === 'accounts' ? formikValues.mapToForAccount.accountId : formikValues.mapTo.name,
        };

        setMappingDetails((mappingDetails) => {
            const newMappingDetails = [...mappingDetails];
            newMappingDetails.push(rowDetails);

            return newMappingDetails;
        });
        arrayHelpers.push(rowDetails);

        if (selectedCategory === 'accounts') {
            setOldAccountDetails((oldAccountDetails) => {
                const newMappingDetails = [...oldAccountDetails];
                newMappingDetails.push(rowDetails);

                return newMappingDetails;
            });
        }
        if (selectedCategory === 'services') {
            setOldServiceDetails((oldServiceDetails) => {
                const newMappingDetails = [...oldServiceDetails];
                newMappingDetails?.push(rowDetails);

                return newMappingDetails;
            });
        }
        resetMapFromTo();
        setIsAddButtonDisabled(true);
    };

    const resetMapFromTo = () => {
        if (selectedCategory === 'accounts') {
            setFieldValue('mapFrom', '');
            setFieldValue('mapToForAccount', defaultAccounts[0]);
            setFieldTouched('mapFrom', false);
        }
        if (selectedCategory === 'services') {
            setFieldValue('mapFrom', '');
            setFieldValue('mapTo', defaultServices[0]);
            setFieldTouched('mapFrom', false);
        }
    }

    useEffect(() => {
        _isUpdatingFlag(true)
        isMarketplaceMapping ? setFieldValue('mapCategory', mappingCategoryForMarketplaces[0]) : setFieldValue('mapCategory', mappingCategoryByOptions[0]);
    }, []);

    useEffect(() => {
        if (formikValues.mapCategory && formikValues.mapCategory.value === '') {
            setFieldValue('mappingDetails', []);
            setMappingTo([])
            setFieldValue('mapFrom', '');
            setFieldValue('mapTo', { name: 'Select', value: '0' });
            setFieldValue('mapToForAccount', { name: 'Select', value: '0' });
            setSelectedCategory('select')
            setFieldTouched('mapFrom', false);
        }
        if (formikValues.mapCategory && formikValues.mapCategory.value === 'accounts') {
            setFieldValue('mappingDetails', oldAccountDetails);
            setMappingTo(defaultAccounts)
            setSelectedCategory('accounts')
        }
        if (formikValues.mapCategory && formikValues.mapCategory.value === 'services') {
            setFieldValue('mappingDetails', oldServiceDetails);
            setMappingTo(defaultServices)
            setSelectedCategory('services')
        }
    }, [formikValues.mapCategory]);

    useEffect(()=>{
        if (isMarketplaceMapping && formikValues?.marketPlacesAvailable?.marketplaceName && formikValues.mapCategory && formikValues.mapCategory.value === 'services') {
            setFieldValue('mappingDetails', mappedServices);
            setMappingTo(defaultServices)
            setSelectedCategory('services')
        }
    },[mappedServices, formikValues?.marketPlacesAvailable?.marketplaceName]);

    useEffect(() => {
        if (formikValues.mapCategory && formikValues.mapCategory.value === '') {
            setFieldValue('mapTo', { name: 'Select', value: '0' });
        }
        if (formikValues.mapCategory && formikValues.mapCategory.value === 'accounts') {
            setFieldValue('mapToForAccount', defaultAccounts[0]);
        }
        if (formikValues.mapCategory && formikValues.mapCategory.value === 'services') {
            setFieldValue('mapTo', defaultServices[0]);
        }
    }, [mappingTo]);

    const handleDialogClose = () => {
        setshowDeleteConfirmationDialog(false)
    }

    return (
        <Grid className="addedit-dialog">
            <FieldArray
                name="mappingDetails"
                render={(arrayHelpers) => {
                    const mappingDetails = formikValues.mappingDetails;
                    return (
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={9} lg={9} style={{ marginTop: '-25px' }}>
                                <div className="parcelDimensionsHeading">
                                    <strong>Add/edit mapping</strong>
                                </div>
                            </Grid>
                            {isMarketplaceMapping ? <Grid container xs={12} sm={12} md={12} lg={12} className="common-padding" style={{ paddingTop: "20px",paddingBottom: "20px" }}>
                             <Grid item xs={4} sm={4} md={4} lg={4} >
                              <FormRenderer {...marketPlacesAvailable.props} data={ marketPlacesList } isDisabled={true} fullWidth />
                              </Grid>
                            </Grid> : ''}
                            <Grid container className="common-padding">
                                <Grid item xs={8} sm={6} md={4} lg={4}>
                                    <FormRenderer {...mapCategory.props} data={isMarketplaceMapping ? mappingCategoryForMarketplaces : mappingCategoryByOptions} fullWidth />
                                </Grid>
                            </Grid>
                            <Grid container className="common-padding" xs={12} sm={12} md={12} lg={12} style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                                <Grid item xs={8} sm={6} md={4} lg={4}>
                                    <FormRenderer {...mapFrom.props} fullWidth />
                                </Grid>
                                <Grid item xs={8} sm={6} md={4} lg={4} style={{ paddingLeft: "50px" }} >
                                   {selectedCategory === 'accounts'
? <FormRenderer {...mapToForAccount.props} data={mappingTo} fullWidth />
                                    : <FormRenderer {...mapTo.props} data={mappingTo} fullWidth />}
                                    
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} justify="flex-end" style={{ display: 'flex' }} >
                                    <InputButton
                                        variant="outlined"
                                        className="outlined-btn add-btn"
                                        onClick={() => pushToParcelDetails(arrayHelpers)}
                                        buttonType="submit"
                                        label="Add"
                                        isDisabled={isAddButtonDisabled}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className="common-padding">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <TableContainer component={Paper} className={classes.container} >
                                        <Table className={`${classes.table} popupMappingTable`} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={classes.tableHeading}>
                                                        <b>Map from</b>
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeading}>
                                                        <b>Map to</b>
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeading}></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {(mappingDetails || [])?.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell align="left">{row.mapFrom}</TableCell>
                                                        <TableCell align="left">{row.mappedTo} </TableCell>
                                                        <TableCell align="right">
                                                            <IconButton className="popupMappingDltBtn" onClick={() => deleteMappedDetails(arrayHelpers, index)}>
                                                                <SvgIcon component={DeleteIcon} viewBox="5 5 30 30" />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                            <Grid container justify="flex-end" style={{ paddingTop: '10px' }} spacing={2} className="addParcel-popup-buttons common-padding">
                                <Grid item>
                                    <InputButton
                                        variant="contained"
                                        className="contained-btn save"
                                        onClick={() => saveNewMappings(oldAccountDetails, oldServiceDetails, formikValues)}
                                        buttonType="submit"
                                        label="OK"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item className="customModal-book">
                                <CustomModal
                                    open={showDeleteConfirmationDialog}
                                    title=""
                                    onClose={handleDialogClose}
                                    maxWidth="sm"
                                >
                                    <ConfirmationDialog
                                        confirmationMessage={"Are you sure, you want to delete this mapping entries?"}
                                        onConfirm={onDialogConfirm}
                                        OnCancel={handleDialogClose}
                                    />
                                </CustomModal>
                            </Grid>
                        </Grid>
                    );
                }}
            />
        </Grid>
    );
};

AddMapping.propTypes = {
    saveNewMappings: PropTypes.func.isRequired,
    formikValues: PropTypes.object,
    setFieldValue: PropTypes.func,
};

export default AddMapping;
