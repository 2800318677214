import React, { useState } from 'react';
import { Grid, Checkbox, FormControlLabel } from '@material-ui/core';
import { Form, Formik } from 'formik';
import TableExport from '../TableExport/TableExport';
import CustomModal from '../../framework/dialog/customDialog';

export default function TableExportPopup(props) {
    const {
        headers,
        myshipment,
        data,
        filename,
        onDataUpdate,
        onClick,
        disableExportButton,
        setSortByOrder,
        showExportDialog,
        ExportTitle,
        dashboard,
        tableData,
        handleDialogClose
    } = props;

    const initialValues = {
        exportTo: ''
    };


    return (
        <Grid container className="customModal-book">
            <CustomModal className="export-pop print-pop" open={showExportDialog} title={ExportTitle} onClose={handleDialogClose}>
                <Grid container direction="column">
                    <Formik initialValues={initialValues}>
                        {({ values, setFieldValue }) => {
                            return (
                                <Form>
                                    <Grid item justify="space-between" xs={12}>
                                        <TableExport
                                            myshipment={(myshipment) ? true : false}
                                            dashboard={(dashboard) ? true : false}
                                            isBookedCollections={ props.isBookedCollections ? true : false}
                                            headers={headers}
                                            data={data}
                                            filename={filename}
                                            onDataUpdate={onDataUpdate}
                                            setFieldValue={setFieldValue}
                                            formikValues={values}
                                            onClick={onClick}
                                            disableExportButton={disableExportButton}
                                            setSortByOrder={setSortByOrder}
                                            tableData={tableData}
                                        />
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>
                </Grid>
            </CustomModal>
        </Grid>
    )

}