import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import FormRenderer from '../../../framework/Inputs/formRenderer/formRenderer';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { SearchIcon } from '@material-ui/data-grid';
import { IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles(() => ({
  SearchTemplatesField: {
    marginTop: '0',
    paddingRight: '10px',
  },
}));

const SearchTemplates = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let [isClearBtn, setIsClearBtn] = useState(false);

  const {
    formField: { templateSearchFor },
    setFieldValue,
    onAddressChange,
    formikValues,
  } = props;

  const getClear = (event = null) => {
    if (event) {
      event.preventDefault();
    }
    setIsClearBtn(false);
    setFieldValue('templateSearchFor', '');
    dispatch(actions.setAddressBookSearchParams('&templateName='));
    onAddressChange(true);
  };

  const getAddresses = (event = null) => {
    if (event) {
      event.preventDefault();
    }
    setIsClearBtn(true);
    onAddressChange(true);
  };

  useEffect(() => {
    dispatch(actions.setAddressBookSearchParams(`&templateName=${formikValues.templateSearchFor}`));
  }, [dispatch, formikValues]);

  useEffect(() => {
    setFieldValue('templateSearchFor', '');
  }, [setFieldValue]);

  return (
    <div className={classes.SearchTemplatesField}>
      <Grid container direction="row" justify="flex-end" alignItems="flex-start" spacing={3}>
        <Grid item xs={12} sm={10} lg={9} className="Template-search">
          <FormRenderer
            style={{ boxShadow: '0px 3px 6px #00000019', height: '43px', top: '24px' }}
            {...templateSearchFor.props}
            endArguments={{
              endAdornment: isClearBtn
              ? (
                <IconButton aria-label="search" onClick={(event) => getClear(event)}>
                  <ClearIcon />
                </IconButton>
              ) 
              : (
                <IconButton className="search-icon-temp" aria-label="search" type="submit" onClick={(event) => getAddresses(event, '')}>
                  <SearchIcon />
                </IconButton>
              ),
            }}
            fullWidth
          />

          {/* 
          <FormRenderer
            {...templateSearchFor.props}
            endArguments={{
              endAdornment: (
                <IconButton onClick={(event) => getAddresses(event, '')}>
                  <SearchIcon />
                </IconButton>
              ),
            }}
            fullWidth
          /> */}
        </Grid>
      </Grid>
    </div>
  );
};

SearchTemplates.propTypes = {
  formField: PropTypes.object,
  templateSearchFor: PropTypes.string,
  setFieldValue: PropTypes.func,
  onAddressChange: PropTypes.func,
  formikValues: PropTypes.object,
};

export default SearchTemplates;
