import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { getYupSchemaFromMetaData } from '../../../utils/yupSchema/yupSchemaGenerator';
import carriageForwardForm from '../../../utils/formConfig/carriageForwardForm/carriageForwardForm';
import Basicdetails from '../../../components/carriageForward/basicDetails/basicdetails';
import ParcelHandover from '../../../components/carriageForward/parcelHandover/parcelHandover';
import CustomAccordion from '../../../framework/accordion/customAccordion';
import CollectFrom from '../../../components/carriageForward/collectFrom/collectFrom';
import DeliverTo from '../../../components/carriageForward/deliverTo/deliverTo';
import InputButton from '../../../framework/Inputs/inputButton/inputButton';
import './carriageForward.scss';
import {
  carriageForwardFormToFavReqObj,
  createCollectionReqObj,
  setValuesToCarriageForwardForm,
  saveCollectionReqObj,
  getCustomerAccountID,
  formatDateToUKFormat,
  formatDate,
} from '../../../utils/helperFunctions';
import { connect, useDispatch } from 'react-redux';
import { POST, GET, PUT, axiosConfig } from '../../../api/axios';
import { useHistory } from 'react-router';
import CustomDialog from '../../../framework/dialog/customDialog';
import PromptJSPMClientInstallation from '../../../components/addShipment/Printing/PromptJSPMClientInstallation';
import { printZplLabels } from '../../../utils/PrintHelper';
import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';
import ConfirmationDialog from '../../../components/addressBook/confirmation/confirmation';
import CustomModal from '../../../framework/dialog/customDialog';
import FormRenderer from '../../../framework/Inputs/formRenderer/formRenderer';
import { EmailParam } from '../../../utils/constants/constants';
import * as actions from '../../../store/actions/index';
import { SearchByOptions } from '../../../utils/constants/carriageForwardConstants';
import { downloadPDF } from '../../../utils/PrintHelper';

const formikRef = React.createRef();
let orderSetupResponse = null;

const CarriageForward = ({ servicePointSelected, JSPMStatus, userDetails, printerConfiguration, onLoad }) => {
  const { formId, formField } = carriageForwardForm;
  const [fromAddressSearchObj, setFromAddressSearchObj] = useState({});
  const [toAddressSearchObj, setToAddressSearchObj] = useState(false);

  const [openJSPMPrompt, setJSPMPrompt] = useState(false);
  const [updatePageFlag, setupdatePageFlag] = useState(false);
  const [collection_id, setcollection_id] = useState(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [collectionResponse, setCollectionResponse] = useState({});
  const [showEmailConfirmation, setShowEmailConfirmation] = useState(false);
  const [labelResponse, setLabelResponse] = useState('');
  const [labelEmail, setLabelEmail] = useState('');
  const [labelEmailDate, setLabelEmailDate] = useState('');
  const [servicePointAddress, setServicePointAddress] = React.useState({});
  const [carriageForwardValidationSchema, setValidationSchema] = React.useState(getYupSchemaFromMetaData(formField, [], []));
  let { id, cf, isvalid } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const initialValues = {};

  const updateValidationSchema = () => {
    setValidationSchema(getYupSchemaFromMetaData(formField, [], []));
  };

  for (let property in formField) {
    if (Object.prototype.hasOwnProperty.call(formField, property)) {
      const field = formField[property];
      initialValues[field.props.name] = field.value ?? '';
    }
  }

  const isFormValid = (errors, values) => {
    if (values.handOverMethod === 'servicePoint' && servicePointSelected?.servicePointID) {
      delete errors.servicePoint;
    }
    if (values.handOverMethod === 'driverCollection') {
      delete errors.servicePoint;
    }
    delete errors.sendLabelEmailAddress;
    const { deliveryServicePoint, ...restControls } = errors;
    // if (values?.handOverMethod === "driverCollection") {
    //   return true;
    // }
    if (
      values?.deliveryType === 'deliverToServicePoint' &&
      values.returnType === 'sendToThirdParty' &&
      !servicePointSelected?.servicePointID
    ) {
      return false;
    }

    return Object.keys(restControls).length === 0;
  };


  const save_handleSubmit = (values, errors) => {
    dispatch(actions.setLoaderLayOver(true));
    // history.push(`/shipment/processOrders/true/true`);
    PUT(ServiceEndPoints.saveOrder, saveCollectionReqObj(collectionResponse, values, servicePointSelected))
      .then((response) => {
        let isScanFlag = false;
        if (history.location && history.location.state !== undefined && history.location.state !== null) {
          isScanFlag = history.location.state.isScanFlag;
        }
        if (response.data.isValid === true) {
          history.push({
            pathname: '/shipment/processOrders',
            state: {
              isCollectionRequest: true,
              isReviewCollection: false,
              isScanFlag: isScanFlag
            },
          });
        } else {
          history.push({
            pathname: '/shipment/processOrders',
            state: {
              isCollectionRequest: true,
              isReviewCollection: true,
              isScanFlag: isScanFlag
            },
          });
        }
        dispatch(
          actions.setAppSnackBarData({
            msg: `Collection saved successfully`,
          }),
        );
      })
      .catch((err) => {
        dispatch(
          actions.setBottomAlert({
            msgs: [
              {
                type: 'error',
                msg: `Error in creating collection : ${JSON.parse(err?.response?.data?.errorMessage)?.[0]?.title}\n ${JSON.parse(err?.response?.data?.errorMessage)?.[0]?.detail
                  }`,
              },
            ],
          }),
        );
      })
      .finally(() => {
        dispatch(actions.setLoaderLayOver(false));
      });
  };

  const handleSubmit = (values, errors, setFieldTouched, setErrors, isEmail = false) => {
    const currentSelectedAccount = values.accountNumber;
    // if(errors.sendLabelEmailAddress===undefined )
    // {
    //   setIsConfirmButtonDisabled(false)
    // }
    if (!(values.handOverMethod === 'servicePoint' && servicePointSelected?.servicePointID)) {
      errors.servicePoint = "ServicePoint  is required";
      setFieldTouched('servicePoint', true);
    }
    setTimeout(() => {
      setErrors(errors)
    }, 0)
    if (!isFormValid(errors, values)) {
      dispatch(
        actions.setBottomAlert({ msgs: [{ type: 'error', msg: 'The form has errors, please correct and try again' }] }),
      );
      return;
    }
    if (isEmail && !showEmailConfirmation) {
      setShowEmailConfirmation(true);
      return;
    }
    showEmailConfirmation && setShowEmailConfirmation(false);
    dispatch(actions.setLoaderLayOver(true));
    const alertMsgs = [];
    let addressPromise = Promise.resolve();
    const reqObjArray = [];
    if (values.fromAddToaddressBook || values.updateFromAddToaddressBook) {
      reqObjArray.push(carriageForwardFormToFavReqObj(values, 'from', userDetails, fromAddressSearchObj));
    }
    if (values.toAddToaddressBook || values.updateToAddToaddressBook) {
      reqObjArray.push(carriageForwardFormToFavReqObj(values, 'to', userDetails, toAddressSearchObj));
    }
    if (reqObjArray.length > 0) {
      addressPromise = POST(ServiceEndPoints.postAddressBook, reqObjArray)
        .then((res) => {
          dispatch(actions.setAppSnackBarData({ msg: `Customer details saved successfully in addressbook` }));
        })
        .catch((err) => {
          alertMsgs.push({
            type: 'error',
            msg: 'Error occurred while saving customer details',
          });
        });
    } else if (values.fromAddToFavourites || values.toAddToFavourites) {
      const { userId } = userDetails;
      let payload = [];
      if (values.fromAddToFavourites) {
        payload.push(fromAddressSearchObj.id);
      }
      if (values.toAddToFavourites) {
        payload.push(toAddressSearchObj.id);
      }
      addressPromise = axiosConfig
        .post(`${ServiceEndPoints.postAddressBook}/favourite?userid=${userId}`, payload)
        .then((response) => {
          dispatch(actions.setAppSnackBarData({ msg: `Customer details saved in favourites` }));
        })
        .catch((err) => {
          alertMsgs.push({
            type: 'error',
            msg: 'Could not add items to favourites.',
          });
        });

    }

    let pageSize = printerConfiguration?.pageSize === 0 ? 0 : (printerConfiguration?.pageSize === null || printerConfiguration?.pageSize === undefined || printerConfiguration?.pageSize === "") ? 1 : printerConfiguration?.pageSize
    let manualShipment = false;
    if (!id && orderSetupResponse?.outputFileSetUp?.manualShipment) {
      manualShipment = true;
    }
    let url = `${ServiceEndPoints.shipmentLabelUrl}?includeLabel=INCLUDE&isOrderRequest=${updatePageFlag}&manualShipment=${manualShipment}`;
    if (isEmail) url += `&format=PDF`;
    if (printerConfiguration?.isDowloadLabelAsPDF) url += `&format=PDF`;
    if (updatePageFlag) {
      url += `&orderId=${collection_id}`;
    }

    if (isEmail) url += `&pageSize=${pageSize}`
    const collectionPromise = axiosConfig
      .post(url, createCollectionReqObj(collectionResponse, values, servicePointSelected))
      .then((response) => {
        dispatch(actions.setServiceLocationDetails(null));
        if (isEmail) {
          alertMsgs.push({
            type: 'success',
            msg: `Create & email label successful. Shipment number :${response.data?.shipments?.[0]?.shipmentId}`,
          });
          setLabelEmailDate(values.collectionDate);
          setLabelEmail(values.sendLabelEmailAddress);
          formikRef.current.resetForm();
          setInitialFormValues(currentSelectedAccount, formikRef.current.setFieldValue);
          setLabelResponse(response?.data?.shipments[0]?.labels[0]);
        } else {
          alertMsgs.push({
            type: 'success',
            msg: `Collection created successfully. Shipment number: ${response.data?.shipments?.[0]?.shipmentId}`,
          });
          formikRef.current.resetForm();
          setInitialFormValues(currentSelectedAccount, formikRef.current.setFieldValue);
        }
      })
      .catch((err) => {
        console.log(err);
        alertMsgs.push({
          type: 'error',
          msg: `${err?.response?.data?.[0]?.detail ?? 'Error occurred while creating collection, please try again'}`,
        });
      });
    Promise.all([addressPromise, collectionPromise])
      .then(() => {
        if (alertMsgs.length > 0) {
          dispatch(
            actions.setBottomAlert({
              msgs: alertMsgs,
            }),
          );
        }
      })
      .finally(() => {
        dispatch(actions.setLoaderLayOver(false));
      });
  };

  const setInitialFormValues = (account, setFieldValue) => {
    //setFieldValue('accountNumber', account);
    setFieldValue('fromSearchBy', SearchByOptions[0]);
    setFieldValue('handOverMethod', 'driverCollection');
  };

  useEffect(() => {
    if (labelResponse !== '' && labelEmail !== '') {
      let reqEmailObjArray = {
        fromEmailAddress: EmailParam.fromEmailAddress,
        toEmailAddresses: [labelEmail],
        subject: EmailParam.subject,
        body:
          labelEmailDate !== ''
            ? EmailParam.body.replace('(date)', formatDateToUKFormat(formatDate(labelEmailDate)))
            : EmailParam.body,
        attachments: [
          {
            content: labelResponse,
            type: EmailParam.type,
            fileName: EmailParam.fileName,
          },
        ],
        displayRecipients: EmailParam.displayRecipients,
      };
      POST(`${ServiceEndPoints.email}`, reqEmailObjArray)
        .then((response) => {
          setLabelEmail('');
          setLabelResponse('');
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [labelResponse, labelEmail]);

  const getOrderSetupResponse = () => {
    GET(`${ServiceEndPoints.orderSetup}?organization=${getCustomerAccountID()}`)
      .then((res) => {
        orderSetupResponse = res.data
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (id !== undefined && cf !== undefined && isvalid !== undefined) {
      populateCarriageForward();
    }
    getOrderSetupResponse();
  }, []);

  const handleCancel = () => {
    if (updatePageFlag) {
      setShowConfirmationDialog(true);
    } else {
      history.push('/shipment/addShipment');
    }
  };

  useEffect(() => {
    if (Object.keys(collectionResponse).length > 0 && id !== undefined && cf !== undefined && isvalid !== undefined) {
      formikRef.current.setValues(
        setValuesToCarriageForwardForm(collectionResponse.shipmentModel, formikRef.current.values, userDetails),
      );
      setupdatePageFlag(true);
      setcollection_id(collectionResponse.id);
      const formatedResponse = {
        ...collectionResponse.shipmentModel,
      };

      onLoad({
        loadedFrom: 'dropdownResponse',
        loadedResponse: { ...formatedResponse },
      });
      setTimeout(() => {
        onLoad({ loadedFrom: '' });
      }, 15000);
      if (collectionResponse.shipmentModel.shipments[0]?.consigneeAddress?.addressType?.toLowerCase() === 'servicepoint') {
        let consigneeAddress = collectionResponse.shipmentModel.shipments[0]?.consigneeAddress;
        let values = {
          servicePointID: consigneeAddress.locationId,
          address: {
            businessName: consigneeAddress.companyName,
            address1: consigneeAddress.address1,
            address2: consigneeAddress.address2,
            address3: consigneeAddress.address3,
            postalTown: consigneeAddress.city,
            zipcode: consigneeAddress.postalCode,
            addressType: consigneeAddress.locationType,
            county: consigneeAddress.state
          },
        };
        setServicePointAddress(values)
        dispatch(actions.setServiceLocationDetails(values));
      }
    }
  }, [collectionResponse]);

  const populateCarriageForward = () => {
    GET(
      `${ServiceEndPoints.getOrders
      }?organization=${getCustomerAccountID()}&id=${id}&isValid=${isvalid}&isCollectionRequest=${cf}`,
    )
      .then((res) => {
        setCollectionResponse(res.data?.orders?.[0]);
        if (res.data?.orders?.[0]?.errors?.length > 0) {
          const errorMessages = res.data.orders[0].errors.map((val) => {
            return {
              type: 'error',
              msg: val,
            };
          });
          dispatch(
            actions.setBottomAlert({
              msgs: errorMessages,
            }),
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClose = () => {
    setJSPMPrompt(false);
    let isScanFlag = false;
    if (history.location && history.location.state !== undefined && history.location.state !== null) {
      isScanFlag = history.location.state.isScanFlag;
    }
    if (updatePageFlag) {
      history.push({
        pathname: '/shipment/processOrders', state: {
          isCollectionRequest: true,
          isReviewCollection: !(isvalid === "true"),
          isScanFlag: isScanFlag
        }
      });
    }
  };

  const handleDialogClose = () => {
    setShowConfirmationDialog(false);
  };

  const onDialogConfirm = () => {
    let isScanFlag = false;
    if (history.location && history.location.state !== undefined && history.location.state !== null) {
      isScanFlag = history.location.state.isScanFlag;
    }
    history.push({
      pathname: '/shipment/processOrders', state: {
        isCollectionRequest: true,
        isReviewCollection: !(isvalid === "true"),
        isScanFlag: isScanFlag
      }
    });
  };

  return (
    <Grid className="carriageForward">
      <Grid>
        <React.Fragment>
          <Formik
            initialValues={initialValues}
            validationSchema={carriageForwardValidationSchema}
            enableReinitialize={true}
            innerRef={formikRef}
            validateOnMount={true}
          >
            {({ values, setValues, setFieldValue, setErrors, setFieldTouched, errors }) => (
              <Form id={formId} noValidate>
                <Grid>
                  <CustomAccordion heading="Basic details">
                    <Basicdetails
                      formField={formField}
                      formikValues={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                    />
                  </CustomAccordion>
                  <Grid className="grid-collect-form" container direction="row" justify="space-evenly" spacing={1}>
                    <Grid className="collect-form" item xs={12} sm={6} md={6} lg={6}>
                      <CustomAccordion heading="Collect from">
                        <Grid item >
                          <CollectFrom
                            formField={formField}
                            formikValues={values}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            setValues={setValues}
                            setFromAddressSearchObj={setFromAddressSearchObj}
                            updateValidationSchema={updateValidationSchema}
                          />
                        </Grid>
                      </CustomAccordion>
                    </Grid>
                    <Grid className="deliver-form" item xs={12} sm={6} md={6} lg={6}>
                      <CustomAccordion heading="Deliver to">
                        <DeliverTo
                          formField={formField}
                          formikValues={values}
                          setFieldValue={setFieldValue}
                          errors={errors}
                          setValues={setValues}
                          setToAddressSearchObj={setToAddressSearchObj}
                          servicePointAddress={servicePointAddress}
                          updateValidationSchema={updateValidationSchema}
                        />
                      </CustomAccordion>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" justify="flex-end" spacing={3}>
                    <Grid item>
                      <InputButton label="Cancel" variant="text" onClick={handleCancel} className="text-btn" />
                    </Grid>
                    {updatePageFlag && (
                      <Grid item>
                        <InputButton
                          label="Save"
                          onClick={() => save_handleSubmit(values, errors)}
                          variant="outlined"
                          className="outlined-btn"
                        />
                      </Grid>
                    )}
                    <Grid item>
                      <InputButton
                        label="Create collection"
                        onClick={() => handleSubmit(values, errors, setFieldTouched, setErrors)}
                        variant="outlined"
                        className="outlined-btn"
                        buttonType="submit"
                      />
                    </Grid>
                    <Grid item>
                      <InputButton
                        buttonType="submit"
                        label="Create & email label"
                        onClick={() => handleSubmit(values, errors, setFieldTouched, setErrors, true)}
                        variant="contained"
                        className="contained-btn"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <CustomDialog
                  maxWidth="sm"
                  open={showEmailConfirmation}
                  // onClose={() => { this.handleCloseModal(setErrors, resetForm) }}
                  onClose={() => setShowEmailConfirmation(false)}
                  // className="commodityDetails-popup"
                  title="Email Address"
                >
                  <>
                    <Grid container direction="row" justify="flex-start" spacing={2}>
                      <Grid item>
                        <FormRenderer {...formField.sendLabelEmailAddress.props} fullWidth />
                      </Grid>
                    </Grid>
                    <Grid container direction="row" justify="flex-end" spacing={2}>
                      {/* <Grid item>
                        <InputButton label="cancel" variant="text" onClick={handleCancel} className="text-btn" />
                      </Grid> */}
                      {/* {updatePageFlag && ( */}
                      <Grid item>
                        <InputButton
                          label="Cancel"
                          onClick={() => setShowEmailConfirmation(false)}
                          variant="outlined"
                          className="outlined-btn"
                        />
                      </Grid>
                      {/* )} */}
                      <Grid item>
                        <InputButton
                          label="Confirm"
                          isDisabled={values.sendLabelEmailAddress === '' || errors.sendLabelEmailAddress !== undefined}
                          onClick={() => handleSubmit(values, errors, setFieldTouched, setErrors, true)}
                          variant="outlined"
                          className="outlined-btn"
                          buttonType="submit"
                        />
                      </Grid>
                    </Grid>
                  </>
                </CustomDialog>
              </Form>
            )}
          </Formik>
        </React.Fragment>
      </Grid>
      <CustomDialog open={openJSPMPrompt} onClose={handleClose} title="JSPM">
        <PromptJSPMClientInstallation />
      </CustomDialog>
      <Grid container item className="customModal-book">
        <CustomModal
          open={showConfirmationDialog}
          title="Cancel collection request"
          onClose={handleDialogClose}
          maxWidth="sm"
        >
          <ConfirmationDialog
            confirmationMessage="Are you sure you want to cancel? Any unsaved changes will be lost."
            onConfirm={onDialogConfirm}
            OnCancel={handleDialogClose}
            confirmLabel="Yes"
            cancelLabel="No"
          />
        </CustomModal>
      </Grid>
    </Grid>
  );
};

CarriageForward.propTypes = {};

const mapStateToProps = (state) => {
  return {
    servicePointSelected: state.addShipmentData.servicePointDelivery,
    JSPMStatus: state.common.isJSPMInstalled,
    userDetails: state.auth.userDetails,
    printerConfiguration: state.common.printerConfiguration,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoad: (loadedFrom) => {
      dispatch(actions.setLoadedFrom(loadedFrom));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CarriageForward);
