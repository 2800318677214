import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import FormRenderer from '../../../../framework/Inputs/formRenderer/formRenderer';
import PropTypes from 'prop-types';
import { setDropdownValues } from '../../../../utils/helperFunctions';
import { connect } from 'react-redux';

const BasicDeclaration = (props) => {
  const {
    formField: { descriptionOfGoods, customDeclarationCurrency, customDeclarationValue },
    setFieldValue,
    currencies,
    fromPreviewSummary,
  } = props;

  const setDefaultValues = (key, value) => {
    setFieldValue(key, value);
  };

  useEffect(() => {
    const dropDownValue = setDropdownValues(currencies, 'code', 'GBP');
    setDefaultValues('customDeclarationCurrency', dropDownValue ? dropDownValue : '');
    if ((fromPreviewSummary || {}).loadedFrom === 'fromPreviewSummary') {
      setFieldValue(
        'customDeclarationCurrency',
        setDropdownValues(
          currencies,
          'code',
          ((fromPreviewSummary.loadedResponse || {}).customDeclarationCurrency || {}).code,
        ),
      );
    }
  }, [currencies, fromPreviewSummary]);

  return (
    <Grid container>
      <Grid className="bd-custom-decl" container spacing={3} direction="row" alignItems="flex-start">
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <FormRenderer {...descriptionOfGoods.props} fullWidth />
        </Grid>
        <Grid item xs={6} sm={3} md={3} lg={3}>
          <FormRenderer {...customDeclarationValue.props} fullWidth />
        </Grid>
        <Grid item xs={6} sm={3} md={3} lg={3}>
          <FormRenderer {...customDeclarationCurrency.props} data={currencies} fullWidth />
        </Grid>
      </Grid>
    </Grid>
  );
};

BasicDeclaration.propTypes = {
  setFieldValue: PropTypes.func,
  currencies: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    fromPreviewSummary: state.loadedFrom.loadedFrom,
  };
};

export default connect(mapStateToProps, null)(BasicDeclaration);
