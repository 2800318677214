import { Divider, FormHelperText, MenuItem, InputAdornment, Paper, Grid, InputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import { useField } from 'formik';
import { at } from 'lodash';
import ClearIcon from '@material-ui/icons/Clear';
import { Scanner } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import * as actions from '../../store/actions/index';
import StarIcon from '@material-ui/icons/Star';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: '0px 3px 6px #00000019',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ACACAC',
    borderRadius: '2.5px',
    height: '45px',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    '& .MuiFormLabel-root': {
      // top: '-5px !important',
      opacity: 0.6,
      fontSize: '16px !important',
    },
  },
  searchBy: {
    flex: 1,
  },
  searchFor: {
    marginLeft: theme.spacing(1),
    height: '43px',
    flex: 1,
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&$focused': {
      backgroundColor: '#fff',
      marginTop: 2,
    },
  },
  iconButton: {
    padding: 10,
    marginTop: '-13px',
    flex: 0,
    color: '#000000',
  },
  divider: {
    height: 35,
    margin: 1,
    // backgroundColor: '#ACACAC',
    width: 1,
  },
  textField: {
    backgroundColor: '#fff ',
  },
  labelRoot: {
    //marginTop: '-7px',
    '&$labelFocused': {
      marginTop: '2px',
    },
  },
  labelRootDate: {
    marginTop: '11px',
    '&$labelFocused': {
      marginTop: '2px',
    },
  },
  labelFocused: {},
  helperText: {
    // float: 'right',
    color: '#f44336',
    fontSize: '0.75rem',
    marginTop: '5px',
  },
}));

const SearchControl = (props) => {
  const { data, selectProps, textProps, isIconDisabled, isDisabled, isClearBtn, inputRef, favouriteFlag } = props;
  const [selectField, selectMeta] = useField(selectProps);
  const { value: selectedValue } = selectField;
  const [selectTouched, selectError] = at(selectMeta, 'touched', 'error');
  const selectIsError = selectTouched && selectError && true;
  const [favorites, setFavorites] = useState([]);
 
  const [textField, textMeta] = useField(textProps);
 
  useEffect(() => {
    if (favouriteFlag) {
      const storedFavorites = sessionStorage.getItem('favorite');
      if (storedFavorites) {
        setFavorites(JSON.parse(storedFavorites));
      }
    }
  }, []);
 
  const toggleFavorite = (item) => {
    const isFavorited = favorites.includes(item);
    setFavorites(isFavorited ? [] : [item]);
    sessionStorage.setItem('favorite', isFavorited ? '' : JSON.stringify([item]));
  }
 
  const dispatch = useDispatch();
  const renderSelectHelperText = () => {
    if (selectIsError) {
      return <FormHelperText>{selectError}</FormHelperText>;
    }
  };
  const Scanner = (e) => {
    if (e.key === "Enter") {
      dispatch(actions.setKeyPressEvent(e.key));
    }
  };

  const renderHelperText = () => {
    const [textTouched, textError] = at(textMeta, 'touched', 'error');
    if (textTouched && textError) {
      return textError;
    }
  };

  let extraLabelProps = {};
  if (selectedValue?.name?.toUpperCase()?.includes('DATE')) {
    extraLabelProps.shrink = true;
  }


  const classes = useStyles();
  return (
    <Grid container className="searchControl-container">
      <Grid item xs={12}>
        <Paper component="form" className={classes.root}>
          <TextField
            className={`${classes.searchBy} main-searchBy`}
            InputProps={{
              className: classes.textField, disableUnderline: true,
            }}
            label="Search by"
            disabled={(isDisabled) ? true : false}
            select
            {...selectField}
            value={selectedValue ? selectedValue : ''}
            variant="filled"
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },

                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
              },
              renderValue: (value) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {value.name}
                  {/* <StarIcon
                style={{ color:favorites.includes(value)? 'gold':'grey',marginLeft:'60px',fontSize:'16px'}}
                />            */}
                </div>
              ),
            }}
          >
            {data.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item.name}
                {favouriteFlag && <StarIcon
                  style={{ color: favorites.some(obj => obj.value === data[index].value) ? 'gold' : 'grey', cursor: 'pointer', marginLeft: 'auto', fontSize: '12px' }}
                  onClick={() => toggleFavorite(data[index])}
                />}
              </MenuItem>
            ))}
          </TextField>
          {renderSelectHelperText()}
          <Divider className={classes.divider} orientation="vertical" />
          <TextField
            label="Search for"
            inputRef={inputRef}
            disabled={(isDisabled) ? true : false}
            type={
              selectedValue &&
                selectedValue.name &&
                (selectedValue.name.includes('date') || selectedValue.name.includes('Date'))
                ? 'date'
                : 'text'
            }
            placeholder={selectedValue ? `Enter ${selectedValue.name}` : ''}
            className={`${classes.searchFor} searchFor-Main` + `${textField.value ? ' searchFor-Main-Padding' : ''}`}
            // format={selectedValue && selectedValue.name && selectedValue.name.includes('date') && 'dd/mm/yyyy'}
            // format={'dd/mm/yyyy'}
            // placeholder={props.textProps.placeholder}
            id="shiva"
            onKeyPress={(e) => {
              if (props.isScanned) {
                Scanner(e);
              }
            }}
            // className = {classes.searchFor + ' searchFor-Main' + `${(textField.value) ? ' searchFor-Main-Padding' : ''}`}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment>
                  {!isClearBtn
                    ? (
                      <IconButton
                        className={classes.iconButton}
                        aria-label="search"
                        type="submit"
                        onClick={props.onSearchClick}
                        disabled={isIconDisabled}
                      >
                        <SearchIcon />
                      </IconButton>
                    )
                    : (
                      <IconButton
                        className={classes.iconButton}
                        aria-label="search"
                        onClick={props.onClearSearch}
                        disabled={isIconDisabled}
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              ...extraLabelProps,
              classes: {
                root: textField?.value ? classes.labelRoot : (selectedValue && selectedValue?.name &&
                  (selectedValue.name.includes('date') || selectedValue.name.includes('Date')))
                  ? classes.labelRootDate : classes.labelRoot,
                focused: classes.labelFocused,
              },
            }}
            error={textMeta.touched && textMeta.error && true}
            // helperText={renderHelperText()}
            {...textField}
          ></TextField>
        </Paper>
      </Grid>
      {/* <p className={classes.helperText}>{renderHelperText()}</p> */}
      <Grid container>
        <Grid item xs={6} sm={6}></Grid>
        <Grid item xs={6} sm={6}>
          <span className={classes.helperText}>{renderHelperText()}</span>
        </Grid>
      </Grid>
    </Grid>
  );
};

SearchControl.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.array,
};

export default SearchControl;
