/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import FormRenderer from '../../../../framework/Inputs/formRenderer/formRenderer';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import './additionalInfo.scss';

const AdditionalInfo = (props) => {
  const dispatch = useDispatch();
  const {
    formField: {
      customerReference,
      alternateReference,
      extendedCoverDomestic,
      extendedCoverInternational,
      mobileRecipient,
      emailRecipient,
    },
    formikValues: { destinationCountry, addToFavCutomerList, customerNumber, inboxReturnLabel },
    setFieldValue,
    isDomestic,
    loadedFrom
  } = props;


  const selectedAccount = useSelector((state) => state.accounts.selectedAccount);
  const customerReferenceCounter = useSelector((state) => state.common.returnReferenceCount);

  const isInsuranceEnabled = () => {
    return selectedAccount?.configuration?.isInsuranceEnabled;
  };

  const onCustomerReferenceChange = (event) => {
    if (inboxReturnLabel === 'true' && event.target.value !== '') {
      dispatch(actions.setReturnReferenceCount(customerReferenceCounter + 1));
    }
  };

  useEffect(() => {
    if (selectedAccount?.configuration?.restrictExtendedLiability === true && (loadedFrom?.loadedFrom !== 'fromPreviewSummary')) {
      setTimeout(() => {
        setFieldValue('extendedCoverDomestic', 0);
        setFieldValue('extendedCoverInternational', false);
      }, 0);
    }

  }, [selectedAccount])
  return (
    <Grid container className="additioninfo-main-grid">
      <Grid container direction="row" alignItems="flex-start" spacing={6}>
        <Grid item xs={12} sm={6} md={6} lg={6} style={{ paddingRight: '34px' }}>
          <FormRenderer {...customerReference.props} fullWidth onBlurCapture={onCustomerReferenceChange} />
        </Grid>
        {isDomestic ? (
          <>
            {isInsuranceEnabled() ? (
              <Grid item xs={12} sm={6} md={6} lg={6} spacing={6} style={{ paddingLeft: '15px' }}>
                {isDomestic && selectedAccount.configuration.restrictExtendedLiability !== true ? (
                  <FormRenderer {...extendedCoverDomestic.props} restrictDecimal={true} fullWidth />
                ) : (
                  selectedAccount.configuration.restrictExtendedLiability !== true ? <FormRenderer {...extendedCoverInternational.props} fullWidth /> : " "
                )}
              </Grid>
            ) : null}
          </>
        ) : (
          <Grid item xs={12} sm={6} md={6} lg={6} spacing={2}>
            <FormRenderer {...alternateReference.props} fullWidth />
          </Grid>
        )}
      </Grid>

      <Grid container spacing={6} direction="row" style={{ paddingTop: '14px' }} alignItems="flex-start">
        {isDomestic ?(
          <Grid item xs={12} sm={6} md={6} lg={6} spacing={6} style={{ paddingRight: '34px' }}>
            <FormRenderer {...alternateReference.props} fullWidth />
          </Grid>
        ) : (
          <>
            {isInsuranceEnabled() ? (
              <Grid item xs={12} sm={6} md={6} lg={6} style={{ paddingLeft: '15px' }}>
                {isDomestic && selectedAccount.configuration.restrictExtendedLiability === false ? (
                  <FormRenderer  {...extendedCoverDomestic.props} restrictDecimal={true} fullWidth />
                ) : (
                  selectedAccount.configuration.restrictExtendedLiability === false ? <FormRenderer {...extendedCoverInternational.props} fullWidth /> : " "
                )}
              </Grid>
            ) : null}
          </>
        )}
      </Grid>
    </Grid>
  );
};

AdditionalInfo.propTypes = {
  formField: PropTypes.object,
  isDomestic: PropTypes.bool,
};

export default AdditionalInfo;
