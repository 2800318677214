import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid, Tab, Tabs } from '@material-ui/core';
import TabPanel from '../../components/shared/tabPanel';
import { useState } from 'react';
import ByShipmentNumber from '../../components/auditEnquiry/byShipmentNumber/byShipmentNumber';
import ByUserId from '../../components/auditEnquiry/byUserId/byUserId';
import { Form, Formik } from 'formik';
import AuditEnquiryForm from '../../utils/formConfig/auditEnquiryForm/auditEnquiryForm';
import { getYupSchemaFromMetaData } from '../../utils/yupSchema/yupSchemaGenerator';
import './auditEnquiry.scss';

const { formId, formField } = AuditEnquiryForm;

const AuditEnquiry = (props) => {
  const formikRef = useRef(null);
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, value) => {
    setTabIndex(value);
    formikRef.current.resetForm();
  };
  const auditEnquiryValidationSchema = getYupSchemaFromMetaData(formField, [], []);

  const initialValues = {};

  for (let property in formField) {
    if (Object.prototype.hasOwnProperty.call(formField, property)) {
      const field = formField[property];
      initialValues[field.props.name] = field.value || '';
    }
  }

  return (
    <Grid className="auditEnquiryContainer">
      <Formik
        initialValues={initialValues}
        validationSchema={auditEnquiryValidationSchema}
        enableReinitialize={true}
        innerRef={formikRef}
      >
        {({ values, errors, setValues, setFieldValue, setFieldError, setFieldTouched }) => {
          return (
            <Form id={formId}>
              <Grid className="auditEnquirySubContainer">
                <Tabs
                  value={tabIndex}
                  onChange={handleTabChange}
                  className={`${tabIndex === 0 ? 'By-shipment-Tab-panel' : 'By-UserId-Tab-panel'}`}
                >
                  <Tab c label="By shipment no." className="auditNav By-shipment-child-Tab-panel" />
                  <Tab c label="By user id." className="auditNav By-UserId-child-Tab-panel" />
                </Tabs>
                <Grid style={{ marginTop: '12px' }}>
                  <TabPanel className="shipment-tab" value={tabIndex} index={0} style={{ width: '100%' }}>
                    <Grid>
                      <ByShipmentNumber
                        formField={formField}
                        formikValues={values}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        tabIndex={tabIndex}
                      />
                    </Grid>
                  </TabPanel>
                  <TabPanel value={tabIndex} index={1} style={{ width: '100%' }}>
                    <Grid>
                      <ByUserId
                        formField={formField}
                        formikValues={values}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        tabIndex={tabIndex}
                        setValues={setValues}
                        setFieldError={setFieldError}
                        setFieldTouched={setFieldTouched}
                      />
                    </Grid>
                  </TabPanel>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
};

AuditEnquiry.propTypes = {};

export default AuditEnquiry;
