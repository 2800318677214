import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import CustomCaption from './CustomCaption';

const ProductAndServiceSummary = ({data}) => {
  // const [productName, setProductName] = useState();

  // const ProductForInterNational = () => {
  //   if (data.shipmentDetails && data.shipmentDetails.orderedProduct == '101') {
  //     setProductName('Worldwide Air');
  //   } else if (data.shipmentDetails && data.shipmentDetails.orderedProduct == '204') {
  //     setProductName('International Road Economy');
  //   }
  // };

  // useEffect(() => {
  //   ProductForInterNational();
  // }, []);

  return (
    <Grid container item xs={12} sm={12} lg={12} spacing={3}>
      <Grid item xs={12} lg={3}>
        <CustomCaption title="Product" value={(data || {} ).product !== null ? (data || {}).product : '-'} />
      </Grid>
      <Grid item xs={12} lg={3}>
        <CustomCaption title="Service" value={(data || {} ).service !== null ? (data || {}).service : '-'}/>
      </Grid>
    </Grid>
  );
};

export default ProductAndServiceSummary;
