import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import CustomCaption from '../../../framework/summaryFormate/CustomCaption';
import ReadOnlyCheckbox from '../../../framework/readOnlyCheckbox/ReadOnlyCheckbox';
import { getParsedFloatValues } from '../../../utils/helperFunctions';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const CollectionParcelSummary = ({ shipmentSummary, accountDetails, isCollections }) => {
  const [parcelDetails, setParcelDetails] = useState({});
  const [volumetricWeight, setVolumetricWeight] = useState(0.0);
  const [parcelDimensionsChecked, setParcelDimensionsChecked] = useState(false);
  const [volumetricWeightDivisor, setVolumetricWeightDivisor] = useState(4000);

  const userDetails = useSelector((state) => state.auth.userDetails);

  useEffect(() => {
    setParcelDetails(shipmentSummary?.parcelDetail);
  }, [shipmentSummary]);

  useEffect(() => {
    setParcelDimensionsChecked(parcelDetails?.parcelDimensionsAdded);
    let volumetricWeight = 0;
    parcelDetails?.pieces?.forEach((row) => {
      volumetricWeight += getCaluclatedVolumetricWeight(row);
    });
    setVolumetricWeight(volumetricWeight);
  }, [parcelDetails, accountDetails]);

  useEffect(() => {
    const shipmentAcc = userDetails?.accounts?.find((user) => user.accountId === shipmentSummary.accountNumber);
    setVolumetricWeightDivisor(shipmentAcc?.configuration?.volumetricWeightDivisor || 4000);
  }, [userDetails, shipmentSummary.accountNumber]);

  const getCaluclatedVolumetricWeight = (row) => {
    return getParsedFloatValues((row.length * row.height * row.width) / (volumetricWeightDivisor || 4000));
  };

  return (
    <>
      <Grid className="Testing" container alignItems="flex-start" xs={12} md={12} sm={12} lg={12}>
        <Grid container xs={6} md={6} sm={6} lg={6}>
          <Grid item xs={6} md={6} sm={6} lg={6}>
            <CustomCaption title="No. of items" value={parcelDetails?.numberOfItems} />
          </Grid>
          <Grid item xs={6} md={6} sm={6} lg={6}>
            <CustomCaption title="Weight(kg)" value={parcelDetails?.totalWeight} />
          </Grid>
        </Grid>
        <Grid container xs={6} md={6} sm={6} lg={6}>
          {!isCollections && (
            <>
              <Grid item xs={6} md={6} sm={6} lg={7}>
                <ReadOnlyCheckbox label="Parcel dimensions added" checked={parcelDimensionsChecked} />
              </Grid>
              <Grid item xs={6} md={6} sm={6} lg={5}>
                <CustomCaption title="Total volumetric wt.(kg)" value={volumetricWeight ?? 0.0} />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

CollectionParcelSummary.propTypes = {
  isCollections: PropTypes.bool,
};

CollectionParcelSummary.defaultProps = {
  isCollections: false,
};

export default CollectionParcelSummary;
