import React from 'react';
import { Grid } from '@material-ui/core';
import PhoneIcon from './../../assets/images/PhoneIcon.svg';
import EmailIcon from './../../assets/images/EmailIcon.svg';
import Word3WordsIcon from './../../assets/images/what3words.png';
import Divider from '@material-ui/core/Divider';
import './addressFormate.scss';
const AddressFormate = ({
  addressType,
  contactName,
  businessName,
  addressLine1,
  addressLine2,
  addressLine3,
  town,
  county,
  postCode,
  emailAddress,
  telephoneNumber,
  shipmentManagement,
  dashboardSummary,
  addShipmentSummary,
  what3Words,
  isDomestic
}) => {
  return (
    <>
      <Grid container item xs={12} sm={12} lg={12} spacing={3}>
        {shipmentManagement || dashboardSummary || addShipmentSummary
        ? null
        : <Grid item xs={12}>
          {addressType !== '' ? `Delivery type:  ${addressType}` : null}
        </Grid>
       
}
        <Grid className="unset-padding-top-bottom" style={{ fontSize: '16px' }} item xs={12}>
          {contactName !== '' && contactName}
        </Grid>
        {/* <Grid className="unset-padding-top-bottom" item xs={12}>
          {businessName !== '' && businessName}
        </Grid> */}
        <Grid className="unset-padding-top-bottom" item xs={12}>
          {businessName !== '' && businessName !== null ? `${businessName}, ` : ''}
          {addressLine1 !== '' && addressLine1 !== null ? `${addressLine1}` : ''}
        </Grid>
        {/* <Grid className="unset-padding-top-bottom" item xs={12}>
          {addressLine2 !== '' && addressLine2}
        </Grid> */}
        <Grid className="unset-padding-top-bottom" item xs={12}>
          {addressLine2 !== '' && addressLine2 !== null ? `${addressLine2}, ` : ''}
          {addressLine3 !== '' && addressLine3 !== null ? `${addressLine3}` : ''}
        </Grid>
        {/* <Grid className="unset-padding-top-bottom" item xs={12}>
          {town !== '' && town}
          </Grid>
          <Grid className="unset-padding-top-bottom" item xs={12}>
          {county !== '' && county}
        </Grid> */}
        <Grid className="unset-padding-top-bottom" item xs={12}>
          {county ? `${county}, ` : ''}
          {town ? `${town}, ` : ''}
          {postCode !== '' && postCode}
        </Grid>
      </Grid>
      <Grid className="unset-padding-top-bottom contact-main-grid" container item xs={12} sm={12} lg={12} spacing={3}>
        <Grid item xs={12} style={{ display: 'flex' }}>
         <p>
           <img src={EmailIcon} alt="Welcome to Universal Shipping Platform"></img>
          {emailAddress !== '' && <span >{emailAddress}</span>}
          </p> 
          <p>
            <img src={PhoneIcon} alt="Welcome to Universal Shipping Platform"></img>
          {telephoneNumber !== '' && <span style={{position: 'absolute', top: '8px'}}>{telephoneNumber}</span>}
          </p>
          {isDomestic?<p>
            <img src={Word3WordsIcon} alt="Welcome to Universal Shipping Platform" style={{marginLeft: '8px', height: '27px', width: '27px'}}></img>
          {what3Words !== '' && <span style={{position: 'absolute', top: '2px', marginLeft: '8px'}}>{what3Words}</span>}
          </p>:""}
        </Grid>
        {/* <Grid item xs={1}>
          <Divider style={{ width: '1px', height: '35px' }} orientation="vertical" variant="middle" />
        </Grid> */}
        {/* <Grid className="unset-padding-top-bottom contact-main-grid" container item xs={12} style={{ display: 'flex' }}>
          <img src={PhoneIcon} alt="Welcome to Universal Shipping Platform"></img>
          {telephoneNumber !== '' && <span >{telephoneNumber}</span>}
        </Grid> */}
      </Grid>
    </>
  );
};

export default AddressFormate;
