import React from 'react'
import { CSVLink } from "react-csv";


const SampleCSVFile = () => {
  const headers = [
    { label: "Customer number", key: "customernumber" },
    { label: "Is Business", key: "isbusiness" },
    { label: "Recipient name", key: "receivername" },
    { label: "Business Name", key: "businessname" },
    { label: "Address1", key: "address1" },

    { label: "Address2", key: "address2" },

    { label: "Address3", key: "address3" },

    { label: "Town", key: "town" },

    { label: "County", key: "county" },

    { label: "Postal code", key: "postalcode" },

    { label: "Country", key: "country" },
    { label: "Telephone number", key: "telephonenumber" },
    { label: "Email address", key: "emailaddress" },
    { label: "Items", key: "items" },
    { label: "Weight (Kg)", key: "weight(Kg)" },
    { label: "Customer Reference", key: "customerreference" },
    { label: "Alternative Reference", key: "alternativeReference" },
    { label: "Extended Liability", key: "extendedliability" },
    { label: "Leave safe location", key: "leavesafelocation" },
    { label: "Special instructions 1", key: "specialinstructions1" },
    { label: "Special instructions 2", key: "specialinstructions2" },
    { label: "Length (cm)", key: "length(cm)" },
    { label: "Width (cm)", key: "width(cm)" },
    { label: "Height (cm)", key: "height(cm)" },
    { label: "Value", key: "value" },
    { label: "Currency", key: "currency" },
    { label: "Collection instructions", key: "collectionInstructions" },
    { label: "Description of goods to be collected", key: "descriptionOfGoodsToBeCollected" },
      // changes made by vibhaas on 14-Dec-2023
      { label: "What3Words", key: "what3Words"},
      // changes over
  ];

  const csvData = [];
  return (
    <div>
      <CSVLink data={csvData} headers={headers} filename="SampleFile.csv" className="btn btn-danger " style={{ color: '#d40511' }}>Download Sample</CSVLink>
    </div>
  )
}

export default SampleCSVFile
