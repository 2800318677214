import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect, withRouter, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PrivateRoute from '../privateRoute/privateRoute';
import { makeStyles, Toolbar, Grid, Backdrop, CircularProgress } from '@material-ui/core';
import CustomModal from '../../framework/dialog/customDialog';
import ConfirmationDialog from '../../components/addressBook/confirmation/confirmation';
import { ShowNotification } from '../ShowNotification/ShowNotification';

import Header from '../header/header';
import SideNav from '../sideNav/sideNav';
import Breadcrumb from '../breadcrumb/breadcrumb';
import Login from '../login/login';
import AddShipment from '../../container/shipment/addShipment/addShipment';
import CarriageForward from '../../container/shipment/carriageForward/carriageForward';
import TemplateManagement from '../../container/shipment/templateManagement/templateManagement';
import TemplateManagements from '../../container/shipment/templateManagement/CreateNewTemplate/TemplateManagement';

import AddressBook from '../../container/addressBook/AddressBook';
import AddNewAddress from '../../container/addressBook/addNewAddress/addNewAddress';
import ShipmentManagement from '../../container/shipment/shipmentManagement/shipmentManagement';
import ShipmentSummary from '../../container/shipment/shipmentSummary/ShipmentSummary';
import AddShipmentSummary from '../../container/shipment/addShipment/addShipmentSummary/AddShipmentSummary';
import Dashboard from '../../container/dashboard/dashboard/dashboard';
import BookedCollectionSummary from '../../container/shipment/bookedCollectionSummary/BookedCollectionSummary';
import Summary from '../../components/dashboard/summary/summary';
import ConfigurationHome from '../../container/configuration/ConfigurationHome';
import GenralConfiguration from '../../container/configuration/GenralConfiguration/GenralConfiguration';
import PrinterConfiguration from '../../container/configuration/PrinterConfiguration/PrinterConfiguration';
import OrderFileConfiguration from '../../container/configuration/OrderFileConfiguration/OrderFileConfiguration';
import MarketplaceConfig from '../../container/configuration/MarketplaceConfiguration/MarketplaceConfig';
import UserManagement from '../../container/userManagement/UserManagement';
import { useDispatch } from 'react-redux';
import './layout.scss';
import DashBoardSummary from '../../container/dashboard/dashboard/dashBoardSummary/DashBoardSummary';
import { useSelector } from 'react-redux';
import BookDriverPickUp from '../../container/shipment/bookDriverPickUp/BookDriverPickUp';
import AuditEnquiry from '../../container/auditEnquiry/auditEnquiry';
import Tracking from '../../container/dashboard/tracking/tracking';
import Accounts from '../../container/accounts/Accounts';
import ProcessOrders from '../../container/shipment/processOrders/processOrders';
import * as actions from '../../store/actions/index';
import CreateNewSchema from '../../components/configuration/orderFileConfigurations/createNewSchema/createNewSchema';
import { ServiceEndPoints } from '../../utils/constants/ApiConstant';
import { axiosConfig } from '../../api/axios';
import { checkToken, getNonReturnAccounts } from '../../utils/helperFunctions';
import BarCodeConfiguration from '../../container/configuration/BarCodeConfiguration/BarCodeConfiguration';
import Faq from '../faq/faq';
import ApplicationUpdates from '../../container/applicationUpdates/ApplicationUpdates';
import { UserRoles } from '../../utils/constants/constants';
import Spinner from '../spinner/spinner';
import AppSnackBar from '../snackBar/appSnackBar';
import BottomAlert from '../bottomAlert/bottomAlert';
import { getDomesticAccounts, isPickUpAvailableInAccount } from '../../utils/helperFunctions';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: '13.6px 0px 13.6px 18.6px',
  },
  root: {
    display: 'flex',
  },
}));

let loginTries = 0;
const Layout = (props) => {
  let userDetails = useSelector((state) => state.auth.userDetails);
  let userAccounts = useSelector((state) => state.accounts.userAccounts);
  const domesticAccounts = useSelector((state) => state.accounts.userAccounts);
  const selectedAccount = domesticAccounts?.find((account) => isPickUpAvailableInAccount(account)) || {};
  const loader = useSelector((state) => state.loaderLayOver.showLoader);
  let notificationInfo = useSelector((state) => state.common);
  const [isUserDetailsLoaded, setUserDetailsLoaded] = useState(false);
  const [isLoginPage, setisLoginPage] = useState(false);
  const [isfaqPage, setIsFaqPage] = useState(false);

  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [isLoginInProgress, setLoginInProgress] = useState(false);
  const [isApplication, setIsApplocation] = useState(false);
  const [alertOpened, setAlertOpened] = useState(false);
  let location = useLocation();
  const dispatch = useDispatch();
  props.history.listen((location) => {
    if (location.pathname.includes('login')) {
      setisLoginPage(true);
    } else if (location.pathname.includes('faq')) {
      setisLoginPage(true);
      setIsFaqPage(true);
      setIsApplocation(false);
    } else if (location.pathname.includes('applicationUpdates')) {
      setIsApplocation(true);
      setisLoginPage(false);
    } else {
      setisLoginPage(false);
      setIsApplocation(false);
    }
  });

  const DrawerEventHandler = (data) => {
    setDrawerOpen(data);
  };

  const validateAddress = (address) => {
    const add = {
      ...address,
      name: address?.name?.trim()?.slice(0, 35),
      companyName: address?.companyName?.trim()?.slice(0, 35),
      address1: address?.address1?.trim()?.slice(0, 35),
      address2: address?.address2?.trim()?.slice(0, 35),
      address3: address?.address3?.trim()?.slice(0, 35),
      city: address?.city?.trim()?.slice(0, 35),
      country: address?.country?.trim()?.slice(0, 35),
      email: address?.email?.trim()?.slice(0, 70),
      phone: address?.phone?.trim()?.slice(0, 35),
    }
    return add;
  }
  const getUserInfo = () => {
    dispatch(actions.setLoaderLayOver(true));
    loginTries++;
    setLoginInProgress(true);
    axiosConfig
      .get(ServiceEndPoints.getUser)
      .then((res) => {
        let userDetails = res.data;
        sessionStorage.setItem('customerAccountID', userDetails?.customerAccountID);
        userDetails.accounts =
          userDetails?.accounts.map((account) => {
            return {
              ...account,
              displayName: `${account.accountId}-${account.accountName}${(account.configuration.firstMileDropOffAllowed) ? '-FMD' : ''}`,
              deliveryCheck:account?.configuration?.deliveryChecks,
              sender: validateAddress(account.sender)
            };
          }) ?? [];

        props.onAuth(userDetails);
        props.setUserAccounts((userDetails || {}).accounts);
        const temp = getNonReturnAccounts((userDetails || {}).accounts);
        props.setNonReturnUserAccounts(temp || [])
        if ((temp || []).length > 0) {
          props.setSelectedAccount(temp[0]);
        }
        setLoginInProgress(false);

        setTimeout(() => {
          dispatch(actions.setLoaderLayOver(false));
        }, 2000);
      })
      .catch((err) => {
        dispatch(actions.setLoaderLayOver(false));
        console.error(err);
        if (loginTries < 3) {
          getUserInfo();
          return;
        }
        setShowConfirmationDialog(true);
        alert('Incorrect User name');
        setLoginInProgress(false);
      });
  };

  const handleDialogClose = () => {
    setShowConfirmationDialog(true);
  };

  useEffect(() => {

    if (location.pathname === '/faq') {

      setisLoginPage(true);
      setIsFaqPage(true);
      setIsApplocation(false);
    }

    if (checkToken()) {
      getUserInfo();
    }
  }, []);

  useEffect(() => {
    if (userDetails) setUserDetailsLoaded(true);
  }, [userDetails]);

  const onDialogConfirm = () => {
    setShowConfirmationDialog(false);
  };
  const onDialogCancel = () => {
    setShowConfirmationDialog(false);
  };

  const alertClosed = (isAlert) => {
    setAlertOpened(isAlert);
    dispatch(actions.setAlertInfo(isAlert));
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid className="container">
        <div className={classes.root}>
          <Header
            isLoginPage={isLoginPage}
            isApplication={isApplication}
            userDetails={userDetails}
            isfaqPage={isfaqPage}
            alertClosed={alertClosed}
          ></Header>
          {isLoginPage ? [null] : <SideNav DrawerEventHandler={DrawerEventHandler} alertOpened={alertOpened} />}

          <main className={`${classes.content}${isDrawerOpen ? ' is_Drawer_Open' : ''}`}>
            <div className={alertOpened ? 'alertPadding' : ''}>
              <Toolbar />

              {isLoginPage && !isfaqPage ? [null] : <Breadcrumb className="Breadcrumb" />}
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() =>
                    userDetails?.role === UserRoles.Reporting ? (
                      <Redirect to="/dashboard" />
                    ) : (
                      <Redirect to="/shipment/addShipment" />
                    )
                  }
                />
                <Route
                  exact
                  path="/shipment"
                  render={() =>
                    userDetails?.role === UserRoles.Reporting ? (
                      <Redirect to="/dashboard" />
                    ) : (
                      <Redirect to="/shipment/addShipment" />
                    )
                  }
                />
                <Route exact path="/login" render={() => <Redirect to="/" />} />
                <PrivateRoute
                  exact
                  path="/shipment/addShipment"
                  component={AddShipment}
                  isAuth={userDetails?.userId && true}
                  isAccess={userDetails?.role === UserRoles.Reporting ? false : true}
                  role={userDetails?.role}
                />
                <PrivateRoute
                  exact
                  path="/shipment/addShipment/preview"
                  component={AddShipmentSummary}
                  isAuth={userDetails?.userId && true}
                  isAccess={userDetails?.role === UserRoles.Reporting ? false : true}
                  role={userDetails?.role}
                />
                <PrivateRoute
                  exact
                  path="/shipment/addShipment/:shipmentId"
                  component={AddShipment}
                  isAuth={userDetails?.userId && true}
                  isAccess={userDetails?.role === UserRoles.Reporting ? false : true}
                  role={userDetails?.role}
                />
                <PrivateRoute
                  exact
                  path="/shipment/addShipment/:id/:isCollectionRequest/:isvalid"
                  component={AddShipment}
                  isAuth={userDetails?.userId && true}
                  isAccess={userDetails?.role === UserRoles.Reporting ||
                    userDetails?.role === UserRoles.BasicShipmentEntry ? false : true}
                  role={userDetails?.role}
                />
                <PrivateRoute
                  exact
                  path="/shipment/collectionRequest"
                  component={CarriageForward}
                  isAuth={userDetails?.userId && true}
                  isAccess={userDetails?.role === UserRoles.Reporting ? false : true}
                  role={userDetails?.role}
                />
                <PrivateRoute
                  exact
                  path="/shipment/collectionRequest/:id/:cf/:isvalid"
                  component={CarriageForward}
                  isAuth={userDetails?.userId && true}
                  isAccess={userDetails?.role === UserRoles.Reporting ||
                    userDetails?.role === UserRoles.BasicShipmentEntry ? false : true}
                  role={userDetails?.role}
                />
                <PrivateRoute
                  exact
                  path="/shipment/templateManagement/editTemplate"
                  component={TemplateManagements}
                  isAuth={userDetails?.userId && true}
                  isAccess={userDetails?.role === UserRoles.Reporting ||
                    userDetails?.role === UserRoles.BasicShipmentEntry || userDetails?.role === UserRoles.BasicShipmentEntryAndReporting ? false : true}
                  role={userDetails?.role}
                />
                <PrivateRoute
                  exact
                  path="/shipment/templateManagement"
                  component={TemplateManagement}
                  isAuth={userDetails?.userId && true}
                  isAccess={userDetails?.role === UserRoles.Reporting ||
                    userDetails?.role === UserRoles.BasicShipmentEntry || userDetails?.role === UserRoles.BasicShipmentEntryAndReporting ? false : true}
                  role={userDetails?.role}
                />

                <PrivateRoute
                  exact
                  path="/shipment/shipmentManagement"
                  component={ShipmentManagement}
                  isAuth={userDetails?.userId && true}
                  isAccess={userDetails?.role === UserRoles.Reporting ? false : true}
                  role={userDetails?.role}
                />
                <PrivateRoute
                  excat
                  path="/addressBook/addNew"
                  component={AddNewAddress}
                  isAuth={userDetails?.userId && true}
                  isAccess={userDetails?.role === UserRoles.Reporting ||
                    userDetails?.role === UserRoles.BasicShipmentEntry || userDetails?.role === UserRoles.BasicShipmentEntryAndReporting ? false : true}
                  role={userDetails?.role}
                />
                <PrivateRoute
                  excat
                  path="/addressBook/editAddress"
                  component={AddNewAddress}
                  isAuth={userDetails?.userId && true}
                  isAccess={userDetails?.role === UserRoles.Reporting ||
                    userDetails?.role === UserRoles.BasicShipmentEntry || userDetails?.role === UserRoles.BasicShipmentEntryAndReporting ? false : true}
                  role={userDetails?.role}
                />
                <PrivateRoute
                  excat
                  path="/addressBook"
                  component={AddressBook}
                  isAuth={userDetails?.userId && true}
                  isAccess={userDetails?.role === UserRoles.Reporting ||
                    userDetails?.role === UserRoles.BasicShipmentEntry || userDetails?.role === UserRoles.BasicShipmentEntryAndReporting ? false : true}
                  role={userDetails?.role}
                />

                <PrivateRoute
                  exact
                  path="/shipment/shipmentmanagement/summary/:id"
                  component={ShipmentSummary}
                  isAuth={userDetails?.userId && true}
                  isAccess={userDetails?.role === UserRoles.Reporting ? false : true}
                  role={userDetails?.role}
                />
                <PrivateRoute
                  exact
                  path="/shipment/processOrders"
                  component={ProcessOrders}
                  isAuth={userDetails?.userId && true}
                  isAccess={userDetails?.role === UserRoles.Reporting ||
                    userDetails?.role === UserRoles.BasicShipmentEntry || userDetails?.role === UserRoles.BasicShipmentEntryAndReporting ? false : true}
                  role={userDetails?.role}
                />
                <PrivateRoute
                  excat
                  path="/shipment/bookDriverPickUp"
                  component={BookDriverPickUp}
                  isAuth={userDetails?.userId && true}
                  isAccess={userDetails?.role === UserRoles.Reporting ? false : Object.entries(selectedAccount).length === 0 ? false : true}
                  role={userDetails?.role}
                />
                <PrivateRoute
                  exact
                  path="/shipment/shipmentmanagement/bookedcollection/:id"
                  component={BookedCollectionSummary}
                  isAuth={userDetails?.userId && true}
                  isAccess={userDetails?.role === UserRoles.Reporting ? false : true}
                  role={userDetails?.role}
                />
                <PrivateRoute
                  exact
                  path="/dashboard"
                  component={Dashboard}
                  isAuth={userDetails?.userId && true}
                  isAccess={userDetails?.role === UserRoles.ShipmentEntry ||
                    userDetails?.role === UserRoles.BasicShipmentEntry ? false : true}
                  role={userDetails?.role}
                />
                <PrivateRoute
                  exact
                  path="/dashboard/dashboardDetailsSummary"
                  component={Summary}
                  isAuth={userDetails?.userId && true}
                  isAccess={userDetails?.role === UserRoles.ShipmentEntry ||
                    userDetails?.role === UserRoles.BasicShipmentEntry ? false : true}
                  role={userDetails?.role}
                />
                <PrivateRoute
                  exact
                  path="/dashboard/dashboardDetailsBreakdown"
                  component={Summary}
                  isAuth={userDetails?.userId && true}
                  isAccess={userDetails?.role === UserRoles.ShipmentEntry ||
                    userDetails?.role === UserRoles.BasicShipmentEntry ? false : true}
                  role={userDetails?.role}
                />
                <PrivateRoute
                  exact
                  path="/dashboard/summary/:id"
                  component={DashBoardSummary}
                  isAuth={userDetails?.userId && true}
                  isAccess={
                    userDetails?.role === UserRoles.Reporting ||
                      userDetails?.role === UserRoles.ShipmentEntry ||
                      userDetails?.role === UserRoles.BasicShipmentEntry
                      ? false
                      : true
                  }
                  role={userDetails?.role}
                />
                <PrivateRoute
                  excat
                  path="/dashboard/Tracking"
                  component={Tracking}
                  isAuth={userDetails?.userId && true}
                  isAccess={userDetails?.role === UserRoles.ShipmentEntry ||
                    userDetails?.role === UserRoles.BasicShipmentEntry ? false : true}
                  role={userDetails?.role}
                />

                <PrivateRoute
                  exact
                  path="/configuration/fileConfiguration/SchemaPreview/:oldSchemaName"
                  component={CreateNewSchema}
                  isAuth={userDetails?.userId && true}
                  isAccess={
                    userDetails?.role === '' ||
                      userDetails?.role === UserRoles.ShipmentEntry ||
                      userDetails?.role === UserRoles.Reporting ||
                      userDetails?.role === UserRoles.ReportingShipmentEntry ||
                      userDetails?.role === UserRoles.ShipmentEntryReporting ||
                      userDetails?.role === UserRoles.BasicShipmentEntry ||
                      userDetails?.role === UserRoles.BasicShipmentEntryAndReporting
                      ? false
                      : true
                  }
                />
                <PrivateRoute
                  excat
                  path="/configuration/genralConfiguration"
                  component={GenralConfiguration}
                  isAuth={userDetails?.userId && true}
                  isAccess={
                    userDetails?.role === UserRoles.ShipmentEntry ||
                      userDetails?.role === UserRoles.Reporting ||
                      userDetails?.role === UserRoles.ReportingShipmentEntry ||
                      userDetails?.role === UserRoles.ShipmentEntryReporting ||
                      userDetails?.role === UserRoles.BasicShipmentEntry ||
                      userDetails?.role === UserRoles.BasicShipmentEntryAndReporting
                      ? false
                      : true
                  }
                />
                <PrivateRoute
                  excat
                  path="/configuration/printerConfiguration"
                  component={PrinterConfiguration}
                  isAuth={userDetails?.userId && true}
                  isAccess={true}
                />
                <PrivateRoute
                  excat
                  path="/configuration/fileConfiguration"
                  component={OrderFileConfiguration}
                  isAuth={userDetails?.userId && true}
                  isAccess={
                    userDetails?.role === UserRoles.ShipmentEntry ||
                      userDetails?.role === UserRoles.Reporting ||
                      userDetails?.role === UserRoles.ReportingShipmentEntry ||
                      userDetails?.role === UserRoles.ShipmentEntryReporting ||
                      userDetails?.role === UserRoles.BasicShipmentEntry ||
                      userDetails?.role === UserRoles.BasicShipmentEntryAndReporting
                      ? false
                      : true
                  }
                />
                <PrivateRoute
                  excat
                  path="/configuration/barCodeConfiguration"
                  component={BarCodeConfiguration}
                  isAuth={userDetails?.userId && true}
                  isAccess={
                    userDetails?.role === UserRoles.ShipmentEntry ||
                      userDetails?.role === UserRoles.Reporting ||
                      userDetails?.role === UserRoles.ReportingShipmentEntry ||
                      userDetails?.role === UserRoles.ShipmentEntryReporting ||
                      userDetails?.role === UserRoles.BasicShipmentEntry ||
                      userDetails?.role === UserRoles.BasicShipmentEntryAndReporting
                      ? false
                      : true
                  }
                />

                <PrivateRoute
                  excat
                  path="/configuration/marketplaceConfig"
                  component={MarketplaceConfig}
                  isAuth={userDetails?.userId && true}
                  isAccess={true}
                />
                <PrivateRoute
                  excat
                  path="/configuration"
                  component={ConfigurationHome}
                  isAuth={userDetails?.userId && true}
                  isAccess={userDetails?.role === '' ? false : true}
                />

                <PrivateRoute
                  excat
                  path="/auditEnquiry"
                  component={AuditEnquiry}
                  isAuth={userDetails?.userId && true}
                  isAccess={
                    userDetails?.role === UserRoles.ShipmentEntry ||
                      userDetails?.role === UserRoles.Reporting ||
                      userDetails?.role === UserRoles.ReportingShipmentEntry ||
                      userDetails?.role === UserRoles.ShipmentEntryReporting ||
                      userDetails?.role === UserRoles.BasicShipmentEntry ||
                      userDetails?.role === UserRoles.BasicShipmentEntryAndReporting
                      ? false
                      : true
                  }
                />
                <PrivateRoute
                  excat
                  path="/userManagement"
                  component={UserManagement}
                  isAuth={userDetails?.userId && true}
                  isAccess={
                    userDetails?.role === UserRoles.Supervisor ||
                      userDetails?.role === UserRoles.ShipmentEntry ||
                      userDetails?.role === UserRoles.Reporting ||
                      userDetails?.role === UserRoles.ReportingShipmentEntry ||
                      userDetails?.role === UserRoles.ShipmentEntryReporting ||
                      userDetails?.role === UserRoles.BasicShipmentEntry ||
                      userDetails?.role === UserRoles.BasicShipmentEntryAndReporting
                      ? false
                      : true
                  }
                />
                <PrivateRoute
                  excat
                  path="/accounts"
                  component={Accounts}
                  isAuth={userDetails?.userId && true}
                  isAccess={
                    userDetails?.role === UserRoles.ShipmentEntry ||
                      userDetails?.role === UserRoles.Reporting ||
                      userDetails?.role === UserRoles.ReportingShipmentEntry ||
                      userDetails?.role === UserRoles.ShipmentEntryReporting
                      ? false
                      : true
                  }
                />
                <PrivateRoute
                  exact
                  path="/faq"
                  component={Faq}
                  isAuth={userDetails?.userId && true}
                  isAccess={
                    userDetails?.role === UserRoles.ShipmentEntry ||
                      userDetails?.role === UserRoles.Reporting ||
                      userDetails?.role === UserRoles.ReportingShipmentEntry ||
                      userDetails?.role === UserRoles.ShipmentEntryReporting ||
                      userDetails?.role === UserRoles.BasicShipmentEntryAndReporting
                      ? false
                      : true
                  }
                />
                <PrivateRoute
                  excat
                  path="/applicationUpdates"
                  component={ApplicationUpdates}
                  isAuth={userDetails?.userId && true}
                  isAccess={
                    userDetails?.role === UserRoles.ShipmentEntry ||
                      userDetails?.role === UserRoles.Reporting ||
                      userDetails?.role === UserRoles.ReportingShipmentEntry ||
                      userDetails?.role === UserRoles.ShipmentEntryReporting ||
                      userDetails?.role === UserRoles.BasicShipmentEntryAndReporting
                      ? false
                      : true
                  }
                />
                <Route path="*" render={() => <Redirect to="/" />} />
              </Switch>
            </div>
          </main>
        </div>
        <div>
          <ShowNotification
            displayMsg={notificationInfo.alertNotification.displayMsg}
            alertSeverity={notificationInfo.alertNotification.severity}
            openNotification={notificationInfo.alertNotification.showNotification}
          />
        </div>
        {/* <CustomModal open={showConfirmationDialog} title="" onClose={handleDialogClose} maxWidth="sm">
                <ConfirmationDialog
                  confirmationMessage={"Something went wrong"}
                  onConfirm={onDialogConfirm}
                  confirmLabel={"Try again"}
                // OnCancel={onDialogCancel}
                />
              </CustomModal> */}
      </Grid>
      <Backdrop style={{ zIndex: 2000 }} open={loader}>
        <Spinner />
      </Backdrop>
      <BottomAlert />
      <AppSnackBar />
    </React.Fragment>
  );
};

Layout.propTypes = {
  history: PropTypes.any,
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (userDetails) => {
      dispatch(actions.setAuthDetails(userDetails));
    },
    setUserAccounts: (userAccounts) => {
      dispatch(actions.setUserAccounts(userAccounts));
    },
    setNonReturnUserAccounts: (nonReturnUserAccounts) => {
      dispatch(actions.setNonReturnUserAccounts(nonReturnUserAccounts));
    },
    setSelectedAccount: (selectedAccount) => {
      dispatch(actions.setSelectedAccount(selectedAccount));
    },
    setFavouriteCustomers: (favouriteCustomers) => {
      dispatch(actions.setFavouriteCustomers(favouriteCustomers));
    },
  };
};

export default connect(null, mapDispatchToProps)(withRouter(Layout));
