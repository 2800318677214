import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { getYupSchemaFromMetaData } from '../../../utils/yupSchema/yupSchemaGenerator';
import { Formik } from 'formik';
import FormRenderer from '../../../framework/Inputs/formRenderer/formRenderer';
import { axiosConfig } from '../../../api/axios';
import InputButton from '../../../framework/Inputs/inputButton/inputButton';
import { useDispatch } from 'react-redux';
import * as actions from './../../../store/actions/index';
import FindImage from '../../../assets/images/polling_path.png';
import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';
import { getCustomerAccountID } from '../../../utils/helperFunctions';

const UploadFile = ({ handleModal, refreshGrid, UploadRefreshGrid }) => {
  const formField = getFormField();
  const [schemas, setSchemas] = useState([]);
  const hiddenFileInput = useRef(null);
  const formikRef = useRef(null);
  const dispatch = useDispatch();
  const [fileExtension, setFileExtension] = useState('');
  // const [isfileExtension, setIsFileExtension] = useState(true);
  const [uploadedFile, setUploadedFile] = useState(null);

  const validationSchema = getYupSchemaFromMetaData(formField, [], []);
  const initialValues = {
    schemaName: '',
    fileBase64: '',
    sourceReference: '',
  };

  useEffect(() => {
    dispatch(actions.setLoaderLayOver(true));
    axiosConfig
      .get(`${ServiceEndPoints.getSchemas}?organization=${getCustomerAccountID()}`)
      .then((res) => {
        let finalData = res.data?.orderFileSchemas.filter(val => val?.isEnabled);
        if (!(res.data?.orderFileSchemas.find(schema => schema.schemaName.toLowerCase() === "select"))) {
          finalData.unshift({ value: "", schemaName: "Select" })
        }
        setSchemas(finalData);
      })
      .catch((err) => {
        console.log(err);
        setSchemas([]);
      })
      .finally(() => {
        dispatch(actions.setLoaderLayOver(false));
      });
  }, []);

  const onSelectFile = (event) => {
    if (
      event?.target?.files?.[0]
    ) {
      setUploadedFile(event.target.files[0])
      formikRef.current.setFieldValue('sourceReference', event.target.files[0].name);
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (loadedEvent) => {
        formikRef.current.setFieldValue('fileBase64', loadedEvent.target.result?.split?.(',')?.[1]);
      };
    }
  };

  const openFileDialog = () => {
    hiddenFileInput.current.click();
  };

  const fileClicked = (event) => {
    event.target.value = '';
  };

  const handleSubmit = () => {
    const extension = formikRef.current?.values?.schemaName?.fileExtension?.slice((formikRef.current?.values?.schemaName?.fileExtension || "").lastIndexOf(".") + 1)

    if (extension?.toLowerCase()?.trim() === `${uploadedFile?.name?.slice((uploadedFile?.name || "").lastIndexOf(".") + 1)}`?.toLowerCase()) {
      dispatch(actions.setLoaderLayOver(true));
      const formikValues = formikRef.current.values;
      const encodeURIName = encodeURIComponent(formikValues?.schemaName?.schemaName)
      axiosConfig
        .post(
          `${ServiceEndPoints.orderFileUpload}?organization=${getCustomerAccountID()}&schemaName=${encodeURIName
          }&sourceReference=${formikValues.sourceReference}`,
          {
            fileBase64: formikValues.fileBase64,
          },
        )
        .then((res) => {
          handleModal(false);
          dispatch(actions.setAppSnackBarData({ msg: 'File uploaded successfully' }));
        })
        .catch((err) => {
          dispatch(
            actions.setBottomAlert({
              msgs: [
                {
                  type: 'error',
                  msg: 'File upload failed',
                },
              ],
            }),
          );
          console.log(err);
        })
        .finally(() => {
          dispatch(actions.setLoaderLayOver(false));
          refreshGrid?.();
          UploadRefreshGrid?.();
        });
    }
    else {
      dispatch(
        actions.setBottomAlert({
          msgs: [
            {
              type: 'error',
              msg: 'The file extension for the selected schema is incorrect',
            },
          ],
        }),
      );
    }
  };

  useEffect(() => {
    if (!formikRef.current?.values?.schemaName) {
      formikRef.current.setFieldValue('schemaName', schemas[0]);
    }
  }, [formikRef.current?.values?.schemaName, schemas]);

  return (
    <Grid container direction="column" alignItems="center">
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
      >
        {({ isValid }) => (
          <>
            <Grid container direction="column" spacing={3}>
              <Grid item xs={12}>
                <FormRenderer {...formField.schemaName.props} data={schemas} fullWidth />
              </Grid>
              <Grid item xs={12}>
                <FormRenderer
                  {...formField.sourceReference.props}
                  isDisabled={true}
                  endArguments={{
                    endAdornment: (
                      <IconButton onClick={openFileDialog}>
                        <img src={FindImage} alt="search icon" />
                      </IconButton>
                    ),
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Note: Files having orders up to 1000 are allowed, if the number of orders are more please upload file via Order processor
                </Typography>

              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" justify="flex-end">
                  <Grid item>
                    <InputButton
                      variant="contained"
                      className="contained-btn"
                      label="Upload"
                      onClick={handleSubmit}
                      isDisabled={!isValid}
                    />
                  </Grid>
                </Grid>
                <input
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(event) => onSelectFile(event)}
                  ref={hiddenFileInput}
                  name="imageField"
                  onClick={fileClicked}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Formik>
    </Grid>
  );
};

UploadFile.propTypes = {
  handleModal: PropTypes.func.isRequired,
  refreshGrid: PropTypes.func,
  UploadRefreshGrid: PropTypes.func
};

export default UploadFile;

const getFormField = () => {
  return {
    schemaName: {
      props: {
        name: 'schemaName',
        label: 'Select schema',
        type: 'select',
        isRequired: true,
        displayField: 'schemaName',
      },
      validation: {
        validationType: 'object',
        validations: [
          {
            type: 'required',
            params: ['Schema is required'],
          },
          {
            type: 'test',
            params: [
              '',
              'Schema is required',
              (value) => {
                if (!value?.id) {
                  return false;
                }
                return true;
              },
            ],
          },
        ],
      },
    },
    fileBase64: {
      props: {
        name: 'fileBase64',
        label: 'Select file',
        type: 'text',
      },
      validation: {
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Order file is required'],
          },
        ],
      },
    },
    sourceReference: {
      props: {
        name: 'sourceReference',
        label: 'Select file',
        type: 'text',
        isRequired: true,
      },
      validation: {
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['File is required'],
          },
        ],
      },
    },
  };
};
