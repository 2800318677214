import { CompareArrowsOutlined } from '@material-ui/icons';
import { UK_POSTAL_CODE_REGEX } from '../../constants/constants';
const templateManagementForm = {
  formId: 'templateManagementForm',
  formField: {
    templateName: {
      props: {
        name: 'templateName',
        label: 'Template name',
        type: 'text',
        isRequired: true,
      },
      validation: {
        name: 'templateName',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Template name is required'],
          },
        ],
      },
    },
    destinationCountry: {
      props: {
        name: 'destinationCountry',
        label: 'Destination country',
        type: 'select',
        displayField: 'name',
      },
    },
    pallets: {
      props: {
        name: 'pallets',
        label: 'Pallets',
        type: 'number',
        //isDisabled: true,
        inputProps: {
          min: 0,
          max: 1,
        },
      },
      value: 1,

      validation: {
        name: 'pallets',
        validationType: 'number',
        validations: [
          {
            type: 'min',
            params: [0, 'Pallets can  not be less than 0'],
          },
          {
            type: 'max',
            params: [1, 'Pallets can  not be greater than 1'],
          },
        ],
      },
    },
    addParcelDimensions: {
      props: {
        name: 'addParcelDimensions',
        label: 'Add parcel dimensions',
        type: 'checkbox',
      },
      value: false,
    },
    noOfItems: {
      props: {
        name: 'noOfItems',
        label: 'No. of items',
        type: 'number',
        inputProps: {
          min: 1,
          max: 20,
        },
        restrictDecimal: true
      },
      value: 1,
      validation: {
        name: 'noOfItems',
        validationType: 'number',
        validations: [
          {
            params: [1, 'Must be a value between 1 to 20'],
            type: 'min',
          },
          {
            type: 'max',
            params: [20, 'Must be a value between 1 to 20'],
          },
        ],
      },
    },
    weight: {
      props: {
        name: 'weight',
        label: 'Weight (Kg)',
        type: 'number',
        placeholder: 'Select',
        inputProps: {
          min: 0,
          max: 1000,
          step: 'any',
        },
      },
      validation: {
        name: 'weight',
        validationType: 'number',
        validations: [
          {
            type: 'when',
            params: [
              ['totalpieceWeight', 'addParcelDimensions'],
              (totalpieceWeight, addParcelDimensions, schema) => {
                if (totalpieceWeight > 0 && addParcelDimensions) {
                  return schema.test('', 'Weight must be greater than or equal to piece weight', (value) => {
                    return value >= totalpieceWeight;
                  });
                } else {
                  return schema;
                }
              },
            ],
          },
          {
            type: 'min',
            params: [1, 'Weight is outside permitted range'],
          },
          {
            type: 'max',
            params: [1000, 'Weight is outside permitted range'],
          },
        ],
      },
    },
    parcelDetails: {
      props: {
        name: 'parcelDetails',
        type: 'array',
        fields: {
          length: 'number',
          width: 'number',
          height: 'number',
          pieceWeight: 'number',
          volumetricWeight: 'number',
          id: 'number',
        },
      },
      values: [],
    },
    totalVolumetricWeight: {
      props: {
        name: 'totalVolumetricWeight',
      },
      value: 0.0,
    },
    length: {
      props: {
        name: 'length',
        label: 'Length (cm)',
        type: 'number',
        isRequired: true,
        inputProps: {
          min: 1,
          max: 200,
        },
      },
      validation: {
        name: 'length',
        validationType: 'number',
        validations: [
          {
            type: 'typeError',
            params: ['Length must be number type'],
          },
          {
            type: 'required',
            params: ['Declared length must not be empty.'],
          },
          {
            type: 'min',
            params: [1, 'Declared length must be greater than 0'],
          },
          {
            type: 'max',
            params: [200, 'Declared length must be less than or equal to 200'],
          },
          {
            type: 'when',
            params: [
              ['width', 'height'],
              (width, height, schema) => {
                if (width || height) {
                  let highest = width || 0;
                  if ((height || 0) > highest) highest = height;
                  return schema.test('', 'Length must be longest side', (value) => {
                    return value >= highest;
                  });
                } else return schema;
              },
            ],
          },
        ],
      },
    },
    width: {
      props: {
        name: 'width',
        label: 'Width (cm)',
        type: 'number',
        isRequired: true,

        inputProps: {
          min: 1,
          max: 120,
        },
      },
      validation: {
        name: 'width',
        validationType: 'number',
        validations: [
          {
            type: 'typeError',
            params: ['Width must be number type'],
          },
          {
            type: 'required',
            params: ['Declared width must not be empty.'],
          },
          {
            type: 'min',
            params: [1, 'Declared width must be greater than 0'],
          },
          {
            type: 'max',
            params: [120, 'Declared width must be less than or equal to 120'],
          },
        ],
      },
    },
    height: {
      props: {
        name: 'height',
        label: 'Height (cm)',
        type: 'number',
        isRequired: true,
        inputProps: {
          min: 1,
          max: 120,
        },
      },
      validation: {
        name: 'height',
        validationType: 'number',
        validations: [
          {
            type: 'typeError',
            params: ['Height must be number type'],
          },
          {
            type: 'required',
            params: ['Declared height must not be empty.'],
          },
          {
            type: 'min',
            params: [1, 'Declared height must be greater than 0'],
          },
          {
            type: 'max',
            params: [120, 'Declared height must be less than or equal to 120'],
          },
        ],
      },
    },
    deliveryType: {
      props: {
        name: 'deliveryType',
        label: 'Delivery type:',
        type: 'radio',
      },
      value: 'deliverToDoorStep',
      validation: {
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Delivery Type is required'],
          },
        ],
      },
    },
    doorStepInstructions: {
      props: {
        name: 'doorStepInstructions',
        label: 'Doorstep instructions',
        type: 'select',
        displayField: 'name',
        hasDefaultValue: true,
      },
      validation: {
        validationType: 'object',
        validations: [
          {
            type: 'required',
            params: ['Doorstep instructions is required'],
          },
        ],
      },
    },
    leaveSafeLocation: {
      props: {
        name: 'leaveSafeLocation',
        label: 'Leave Safe location',
        type: 'text',
        inputProps: {
          maxLength: 60,
        },
      },
      validation: {
        name: 'leaveSafeLocation',
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [60, 'Max 60 characters are allowed'],
          },
        ],
      },
    },
    exchange: {
      props: {
        name: 'exchange',
        label: 'Exchange on delivery:',
        type: 'radio',
      },
      value: 'no',
    },
    specialInstructions: {
      props: {
        name: 'specialInstructions',
        label: 'Special instructions',
        type: 'text',
        inputProps: {
          maxLength: 30,
        },
      },
      validation: {
        name: 'specialInstructions',
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [30, 'Max 30 characters are allowed'],
          },
        ],
      },
    },
    additionalInstruction: {
      props: {
        name: 'additionalInstruction',
        type: 'text',
        inputProps: {
          maxLength: 30,
        },
      },
    },
    product: {
      props: {
        name: 'product',
        label: 'Product',
        type: 'select',
        displayField: 'productNameForUI',
      },
    },
    service: {
      props: {
        name: 'service',
        label: 'Service',
        type: 'select',
        displayField: 'name',
      },
      validation: {
        name: 'service',
        validationType: 'object',
        validations: [
          {
            type: 'when',
            params: [
              'destinationCountry',
              (destinationCountry, schema) => {
                return (destinationCountry || {}).code === 'GBR'
                  ? schema.required('Service name required')
                  : schema.notRequired();
              },
            ],
          },
        ],
      },
    },
    customerReference: {
      props: {
        name: 'customerReference',
        label: 'Customer reference',
        type: 'text',
        inputProps: {
          maxLength: 20,
        },
      },
      validation: {
        name: 'customerReference',
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [20, 'Max 20 characters are allowed'],
          },
        ],
      },
    },
    alternateReference: {
      props: {
        name: 'alternateReference',
        label: 'Alternative reference',
        type: 'text',
        inputProps: {
          maxLength: 20,
        },
      },
      validation: {
        name: 'alternateReference',
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [20, 'Max 20 characters are allowed'],
          },
        ],
      },
    },
    extendedCoverDomestic: {
      props: {
        name: 'extendedCoverDomestic',
        label: 'Extended liability',
        type: 'number',
        placeholder: 'Please select a level of extended liability, if required',
        inputProps: {
          min: 0,
          max: 5,
        },
      },
      validation: {
        name: 'extendedCoverDomestic',
        validationType: 'number',
        validations: [
          {
            params: [0, 'Extended liability value should be between 0 - 5.'],
            type: 'min',
          },
          {
            params: [5, 'Extended liability value should be between 0 - 5.'],
            type: 'max',
          },
        ],
      },
    },
    mobileRecipient: {
      props: {
        name: 'mobileRecipient',
        label: 'Mobile',
        type: 'checkbox',
      },
    },
    emailRecipient: {
      props: {
        name: 'emailRecipient',
        label: 'Email',
        type: 'checkbox',
      },
      value: 'checked',
    },
    pieceWeight: {
      props: {
        name: 'pieceWeight',
        label: 'Weight (kg)',
        type: 'number',
        placeholder: 'Select',
        isRequired: true,
      },
      validation: {
        name: 'pieceWeight',
        validationType: 'number',
        validations: [
          {
            type: 'required',
            params: ['Weight is required'],
          },
        ],
      },
    },
    totalpieceWeight: {
      props: {
        name: 'totalpieceWeight',
      },
      value: 0.0,
    },
    //Addition of pallets.
    createPalletShipment: {
      props: {
        name: 'createPalletShipment',
        label: 'Create pallet shipment',
        type: 'radio',
      },
      value: 'no',
    },
  },
};

export default templateManagementForm;
