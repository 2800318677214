import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import {
  Grid,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  SvgIcon,
} from '@material-ui/core';

import { ReactComponent as DeleteIcon } from '../../../../../../assets/images/DeleteIcon.svg'
import { ReactComponent as EditIcon } from '../../../../../../assets/images/EditIcon.svg';
import { ReactComponent as AddIcon } from '../../../../../../assets/images/AddIcon.svg';
import CustomDialog from '../../../../../../framework/dialog/customDialog';
import AddCommodityDetails from '../../../../../../container/shipment/addShipment/customsDeclaration/addCommodityDetails/addCommodityDetails';
import './marketplaceCommodityDetails.scss';

const MarketplaceCommodityDetails = (props) => {
  const {
    formField: { commodityDetails },
    formikValues,
    //errors,
    setFieldValue,
    // isOrderDefaults,
    marketPlacesList
  } = props;

  const [open, setOpen] = React.useState(false);
  const [modalData, setModalData] = React.useState({});
  const [footerValues, setFooterValues] = React.useState({
    subTotal: 0,
    totalValue: 0,
  });
  // const [spanIsActive, setspanIsActive] = React.useState(false);
  // const [commodityWeight, setcommodityWeight] = React.useState(0);
  //var commodityDetails_weight = 0;
  const openAddCommodityDetails = () => {
    setModalData({
      operationType: 'addCommodityDetails',
      totalWeight: formikValues.weight,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addNewCommodityDetails = (arrayHelpers, values) => {
    arrayHelpers.push(values);
    setOpen(false);
  };

  const updateCommodityDetails = (arrayHelpers, index, values) => {
    arrayHelpers.replace(index, values);
    setOpen(false);
  };

  const openUpdateCommodityDetails = (index, rowDetails) => {
    setModalData({
      index,
      rowDetails,
      operationType: 'updateCommodityDetails',
      totalWeight: formikValues.weight,
    });
    setOpen(true);
  };

  React.useEffect(() => {
    let product = 0;
    formikValues.commodityDetails.forEach((commodity) => {
      product = commodity.quantity * commodity.unitValue + product;
    });
    const shippingCharges =
      isNaN(parseFloat(formikValues.customDeclarationShippingCharges)) ||
      parseFloat(formikValues.customDeclarationShippingCharges) < 0
        ? 0
        : parseFloat(formikValues.customDeclarationShippingCharges);
    const totalValue = parseFloat(product + shippingCharges).toFixed(2); //+ parseFloat(shippingCharges).toFixed(2);
    setFooterValues({ subTotal: parseFloat(product).toFixed(2), totalValue: parseFloat(totalValue).toFixed(2) });
    setFieldValue('subTotal', parseFloat(product).toFixed(2));
    setFieldValue('totalValue', parseFloat(totalValue).toFixed(2));

    // formikValues.commodityDetails.forEach((commodity) => {
    //   commodityDetails_weight += commodity.quantity * commodity.weight;
    // });
    // setcommodityWeight(commodityDetails_weight);
    // if (parseFloat(commodityDetails_weight) > parseFloat(formikValues.weight || 0) && !isOrderDefaults) {
    //   setspanIsActive(true);
    // }
    // else if(isOrderDefaults && parseFloat(commodityDetails_weight) > (parseFloat(formikValues.weight || 0)*(formikValues.noOfItems || 1))){
    //   setspanIsActive(true);
    // } else {
    //   setspanIsActive(false);
    // }
    // let totalPiecesWeight = 0;
    // formikValues?.intlParcelDetails?.map((piece)=>{
    //   totalPiecesWeight += parseFloat(piece.weight);
    // })
    // if(commodityDetails_weight > (formikValues.weight || 0) && !isOrderDefaults){
    //   setFieldValue("totalWeightExceed",commodityDetails_weight);
    // }
    // else if(isOrderDefaults && (commodityDetails_weight > ((formikValues.weight || 0)*formikValues.noOfItems))){
    //   setFieldValue("totalWeightExceed",commodityDetails_weight);
    // }
    // // else if(totalPiecesWeight > formikValues.weight){
    // //   setFieldValue("totalWeightExceed",totalPiecesWeight);
    // // }
    // else{
    //   setFieldValue("totalWeightExceed",formikValues.weight || 0);
    // }
    // if(formikValues.reasonForExport?.type === 'documents' && formikValues?.subTotal > 5 ) 
    // {
    //   setspanIsActive(true);
    //   setFieldValue("totalWeightExceed",formikValues.weight+1);
    // }
  }, [formikValues.commodityDetails, formikValues.customDeclarationShippingCharges, formikValues.weight ,formikValues.noOfItems, formikValues?.subTotal]);
  return (
    <div className="wd-100">
      <FieldArray
        name={commodityDetails.props.name}
        render={(arrayHelpers) => {
          const commodityItems = formikValues.commodityDetails;
          return (
            <Grid>
              <Grid container direction="row" justify="space-between">
                <Grid item style={{ marginTop: '1%' }}>
                  <strong>Item details</strong>
                </Grid>
                <Grid item>
                  <strong>
                    <IconButton disabled={(commodityItems || []).length > 19} onClick={openAddCommodityDetails}>
                      <SvgIcon component={AddIcon} viewBox="5 5 30 30" />
                    </IconButton>
                    Add new
                  </strong>
                  <CustomDialog
                    maxWidth="sm"
                    open={open}
                    onClose={handleClose}
                    className="commodityDetails-popup"
                    title={
                      modalData.operationType === 'updateCommodityDetails'
                        ? 'Update item description'
                        : 'Add item description'
                    }
                  >
                    <AddCommodityDetails
                      data={modalData}
                      addNewCommodityDetails={(values) => {
                        addNewCommodityDetails(arrayHelpers, values);
                      }}
                      updateCommodityDetails={(index, values) => updateCommodityDetails(arrayHelpers, index, values)}
                      marketPlacesList = {marketPlacesList}
                      isMarketplacesCommodity = {true}
                    />
                  </CustomDialog>
                </Grid>
              </Grid>
              <TableContainer component={Paper} className="tableContainer Add_shipment_tableContainer">
                <Table aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left"><strong>SKU number</strong></TableCell>
                      <TableCell align="left"><strong>Marketplace channel</strong></TableCell>
                      <TableCell align="left">
                        <strong>Commodity code</strong>
                      </TableCell>
                      <TableCell align="left">
                        <strong>Full description of goods</strong>
                      </TableCell>
                      <TableCell align="left">
                        <strong>Weight</strong>
                      </TableCell>
                      <TableCell align="left">
                        <strong>Quantity</strong>
                      </TableCell>
                      <TableCell align="left">
                        <strong>Unit value</strong>
                      </TableCell>
                      <TableCell align="left">
                        <strong>Country of origin</strong>
                      </TableCell>
                      <TableCell align="left"></TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {commodityItems.length>0 ? commodityItems.map((row, index) => (
                      <TableRow key={index} className="footerRow">
                        <TableCell align="left">{row.skuNumber}</TableCell>
                        <TableCell align="left">{row?.marketplaceChannel?.marketplaceName}</TableCell>
                        <TableCell component="th" scope="row">
                          {row.commodityCode}
                        </TableCell>
                        <TableCell align="left">{row.fullDescriptionOfGoods}</TableCell>
                        <TableCell align="left">{row.weight} kg</TableCell>
                        <TableCell align="left">{row.quantity}</TableCell>
                        <TableCell align="left">{row.unitValue}</TableCell>
                        <TableCell align="left">{row.countryOfOrigin?.name}</TableCell>
                        <TableCell align="left">
                          <IconButton onClick={() => openUpdateCommodityDetails(index, row)}>
                            <SvgIcon component={EditIcon} viewBox="5 5 30 30" />
                          </IconButton>
                        </TableCell>
                        <TableCell align="left">
                          <IconButton onClick={() => arrayHelpers.remove(index)}>
                            <SvgIcon component={DeleteIcon} viewBox="5 5 30 30" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )) : 
                        <TableRow  className="footerRow">
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                        </TableRow>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              {/* { formikValues.reasonForExport?.type === 'documents' && formikValues?.subTotal > 5 ?
                <span className="span-required" style={{ display: spanIsActive ? '' : 'none' }}>
                Max sub total allowed for this reason for export is 5.
                </span> 
              : <span className="span-required" style={{ display: spanIsActive ? '' : 'none' }}>
                Total weight: <b>{parseFloat(commodityWeight).toFixed(2)}</b> can not be greater than the declared
                weight <b>{!isOrderDefaults ? parseFloat(formikValues.weight || 0).toFixed(2) : (parseFloat(formikValues.weight || 0)*(formikValues.noOfItems || 1)).toFixed(2) } </b>
                </span>
              }
              {errors?.commodityDetails ? <span className="span-required">{errors.commodityDetails}</span> : null} */}
            </Grid>
          );
        }}
      />
    </div>
  );
};

MarketplaceCommodityDetails.propTypes = {
  formField: PropTypes.object,
  formikValues: PropTypes.object,
  errors: PropTypes.object,
};

export default MarketplaceCommodityDetails;
