import React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Form, Formik } from 'formik';
import Grid from '@material-ui/core/Grid';

import CustomAccordion from '../../../framework/accordion/customAccordion';
import InputButton from '../../../framework/Inputs/inputButton/inputButton';
import Spinner from '../../../framework/spinner/spinner';

import { printerConfiguration } from '../../../utils/formConfig/printerConfiguration/printerConfiguration';
import FormRenderer from '../../../framework/Inputs/formRenderer/formRenderer';
import { POST, GET } from '../../../api/axios';
import { getAvaiablePrinters } from '../../../utils/PrintHelper';
import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';
import { labelType, labelSize, labelSizeWhenZpl } from '../../../utils/constants/constants';
import { getYupSchemaFromMetaData } from '../../../utils/yupSchema/yupSchemaGenerator';

import * as actions from '../../../store/actions/index';
import './PrinterConfiguration.scss';

const initialVals = {
  labelPrinter: { name: 'Select', value: '' },
  labelType: { name: 'Select', value: '' },
  otherReportsPrinter: { name: 'Select', value: '' },
  pauseCount: '',
  labelSize: labelSize[0],
  // labelRadioTypes:false,
};

class PrinterConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.printerConfigResp = null;
    this.state = {
      downloadPDF: false,
      initialFormValues: {
        labelRadioTypes: labelTypeRadioOptions[0].value,
        pauseCount: '',
        jspmVersion: _jspmVersion[0],
      },
      availablePrinters: [],
      jspmVersionMismatch: true,
    };
    this.onJspmVersionSelectionChange = this.onJspmVersionSelectionChange.bind(this);
  }

  componentDidMount() {
    this.props.setLoader(true);
      GET(`${ServiceEndPoints.printConfiguration}?userId=${this.props.userDetails.emailId}`)
      .then((response) => {
        this.printerConfigResp = response?.data;
        this.setState({ downloadPDF: response?.data?.isDowloadLabelAsPDF })
        setTimeout(() => {
           this.setAvailablePrinters(response);
        }, 2500);
      })
      .catch((err) => {
          // this.setEmptyValues();
          // this.props.setLoader(false);
          setTimeout(() => {
            this.setAvailablePrinters();
         }, 2500);
        });
      // this.setAvailablePrinters();
  }

  getInitialValues = (response) => {
        const { availablePrinters } = this.state;
    // GET(`${ServiceEndPoints.printConfiguration}?userId=${this.props.userDetails.emailId}`)
    //   .then((response) => {
        let labelPrinter = availablePrinters?.find((x) => x.value === response?.data?.labelPrinter);
        let otherPrinter = availablePrinters?.find((x) => x.value === response?.data?.otherReportsPrinter);
        let printFormat = (labelType || []).find((x) => x.value === response?.data?.labelType);
        let labelSizeFormat = !printFormat && !(response?.data?.isDowloadLabelAsPDF) ? labelSize[0] : labelSize[response?.data?.pageSize + 1 || 0]
        const storedValues = {
          labelPrinter: labelPrinter ?? availablePrinters[0],
          labelType: printFormat ?? labelType[0],
          otherReportsPrinter: otherPrinter ?? availablePrinters[0],
          pauseCount: response?.data?.pauseCount === 0 ? '' : response?.data.pauseCount,
          labelRadioTypes: response?.data?.isDowloadLabelAsPDF === true ? labelTypeRadioOptions[1].value : labelTypeRadioOptions[0].value,
          labelSize: labelSizeFormat,
          jspmVersion: (response?.data?.jspmVersion === '3') ? _jspmVersion[1] : response?.data?.jspmVersion === '4' ? _jspmVersion[2] : this.state.availablePrinters?.length ? _jspmVersion[2] : _jspmVersion[0] ,
        };
        this.setState({
          initialFormValues: storedValues,
        },()=> {this.props.setLoader(false)});
        
      // })
      // .catch((err) => {
      //   this.setEmptyValues();
      //   this.props.setLoader(false);
      // });
  };

  setEmptyValues = () => {
    const emptyVal = {
      jspmVersion: this.state.availablePrinters?.length ? _jspmVersion[2] : _jspmVersion[0],
      labelPrinter: this.state.availablePrinters[0],
      labelType: labelType[0],
      otherReportsPrinter: this.state.availablePrinters[0],
      pauseCount: '',
      labelSize: labelSize[0],
      labelRadioTypes: this.state.downloadPDF === true ? labelTypeRadioOptions[1].value : labelTypeRadioOptions[0].value
    };
    this.setState({ initialFormValues: emptyVal });
  };

  setAvailablePrinters = (response) => {
    getAvaiablePrinters()
      ?.then((availablePrinterList) => {
        let printers = this.createKeyValuePair(availablePrinterList);
        printers.unshift({ name: 'Select', value: '' });
        this.setState({ availablePrinters: printers ,jspmVersionMismatch: false}, () => {
          this.getInitialValues(response);
        });
      })
      .catch((err) => {
        console.log(err)
        this.setState({ availablePrinters: [], jspmVersionMismatch: true, ...this.getInitialValues(response) });
      });
  };

  handleFormSubmit = (formValues, isValid) => {
    if (formValues.labelRadioTypes === "Downloadlabel") {
      if(formValues.labelSize?.value || formValues.labelSize?.value === 0)
      {
        isValid = true;
      }
      else
      {
        isValid = false;
      } 
    }
    if (isValid || (this.state.jspmVersionMismatch && formValues.labelRadioTypes !== "Downloadlabel" && formValues.jspmVersion.value !== '')) {
      let payload = {
        userId: this.props.userDetails.emailId,
        organization: 'dhl',
        labelPrinter: formValues.labelPrinter?.value ? formValues.labelPrinter?.value : undefined,
        labelType: formValues.labelType?.value ? formValues.labelType?.value : undefined,
        otherReportsPrinter: formValues.otherReportsPrinter?.value ? formValues.otherReportsPrinter?.value : undefined,
        pauseCount: formValues.pauseCount ? parseInt(formValues.pauseCount) : undefined,
        isDowloadLabelAsPDF: formValues.labelRadioTypes === "PrintLabel" ? false : true,
        pageSize: formValues.labelRadioTypes === "Downloadlabel" ? (formValues?.labelSize?.value === "" || formValues?.labelSize?.value === null || formValues?.labelSize?.value === undefined ? 1 : formValues.labelSize?.value) : formValues.labelSize?.value || undefined,
        jspmVersion: formValues.jspmVersion.value || undefined, 
      };
      if(this.state.jspmVersionMismatch && this.printerConfigResp) {
        payload = { ...this.printerConfigResp, jspmVersion: formValues.jspmVersion.value || undefined, isDowloadLabelAsPDF: formValues.labelRadioTypes === "PrintLabel" ? false : true, pageSize: formValues.labelRadioTypes === "Downloadlabel" ? (formValues?.labelSize?.value === "" || formValues?.labelSize?.value === null || formValues?.labelSize?.value === undefined ? 1 : formValues.labelSize?.value) : formValues.labelSize?.value || undefined,}
      }
      POST(ServiceEndPoints.printConfiguration, payload)

        .then((response) => {
          this.props.savePrintConfiguration(payload);
          this.props.setSnackBar({ msg: 'Printer configuration saved successfully' });
          // if(this.printerConfigResp?.jspmVersion !== formValues.jspmVersion.value){
          // setTimeout(() => {
          //   window.location.reload();
          // }, 100);
        // }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.props.setBottomAlert([
        {
          type: 'error',
          msg: 'The form has errors, please correct and try again',
        },
      ]);
    }
  };

  createKeyValuePair = (arrayList) => {
    return arrayList?.map((item) => {
      return {
        name: item,
        value: item,
      };
    });
  };

  onJspmVersionSelectionChange(jspmVersion) {
    if(this.printerConfigResp?.jspmVersion !== jspmVersion)
    {
      let payload = {
        jspmVersion: jspmVersion || undefined,
        userId: this.props.userDetails.emailId,
        organization: 'dhl', 
      };
      if(this.printerConfigResp) {
        payload = { ...this.printerConfigResp,  jspmVersion: jspmVersion || undefined, isDowloadLabelAsPDF: false,}
      }
      POST(ServiceEndPoints.printConfiguration, payload)
        .then((response) => {
          this.props.savePrintConfiguration(payload);
            setTimeout(() => {
              window.location.reload();
            }, 100);
        })
        .catch((err) => {
          console.log(err);
        });
      }
  }

  render() {
    const { formId, formField } = printerConfiguration;
    const { initialFormValues, availablePrinters } = this.state;
    const printerConfigValidationSchema = getYupSchemaFromMetaData(formField, [], []);
    return (
      <React.Fragment>
        {/* {initialFormValues  ? ( */}
        <div>
          <Formik
            initialValues={initialFormValues}
            validationSchema={printerConfigValidationSchema}
            validateOnMount={true}
            enableReinitialize={true}
          >
            {({ values, isValid, setFieldValue }) => {
              if (values?.labelRadioTypes === "PrintLabel" && values?.labelType?.value === 1 && values?.labelSize?.value === 1) {
                setFieldValue('labelSize', labelSizeWhenZpl[1])
              }
              return (
                <Form id={formId}>

                  <Grid container direction="row">
                    <FormRenderer {...formField?.labelRadioTypes.props} data={labelTypeRadioOptions} fullWidth />
                  </Grid>
                  {(values || {}).labelRadioTypes === 'Downloadlabel' ?
                    <Grid container direction="row" xs={12} sm={12} md={12} lg={12} style={{ background: '#f0f0f0', height: '150px' }}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <p style={{ marginLeft: '30px' }}>The labels will be downloaded to the downloads folder.</p>
                        <strong style={{ marginLeft: '30px', }}>Please select label size</strong>
                      </Grid>
                      <Grid xs={12} sm={12} md={3} lg={3}>
                        <FormRenderer {...formField.labelSize.props} data={labelSize} fullWidth style={{ marginLeft: '30px' }} />
                      </Grid>
                    </Grid>

                    :
                    <>
                      <CustomAccordion heading="JSPM version">
                        <Grid container direction="row">
                          <Grid container item spacing={3}>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormRenderer {...formField.jspmVersion.props} data={_jspmVersion}  onJspmVersionSelectionChange={this.onJspmVersionSelectionChange} fullWidth />
                              {this.state.jspmVersionMismatch ? 
                              <span className="span-required" >
                                Please install JSPM to allow direct label printing (Download JSPM client app - 
                                  <a
                                 href="https://www.neodynamic.com/downloads/jspm/?v=4"
                                 target="_blank"
                                 rel="noreferrer"
                                 className="btn btn-lg btn-primary center"
                                >
                                <i className="fa fa-download" /> link to JSPM version 4 
                                </a>
                                ),
                                if already installed please ensure you have selected the correct JSPM version to save printer configurations.
                              </span> : ''}
                            </Grid>
                          </Grid>
                        </Grid>
                      </CustomAccordion>
                      {this.state.jspmVersionMismatch === false ?( <>
                      <CustomAccordion heading="Label printer">
                        <Grid container direction="row">
                          <Grid container item spacing={3}>
                            <Grid item xs={12} sm={12} md={5} lg={5}>
                              <FormRenderer {...formField.labelPrinter.props} data={availablePrinters} fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2}>
                              <FormRenderer {...formField.labelType.props} data={labelType} fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2}>
                              <FormRenderer {...formField.labelSize.props} data={values?.labelRadioTypes.value === 'Downloadlabel' ? labelSize : values?.labelPrinter && values?.labelType?.value === 1 ? labelSizeWhenZpl : labelSize} fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3} className="pause-input-section">
                              <FormRenderer {...formField.pauseCount.props} fullWidth />
                            </Grid>
                          </Grid>
                        </Grid>
                      </CustomAccordion>
                      <CustomAccordion heading="A4 printer configuration (Invoice,audit & other printing)">
                        <Grid container direction="row">
                          <Grid container item spacing={3}>
                            <Grid item xs={12} sm={6} md={6} lg={5}>
                              <FormRenderer {...formField.otherReportsPrinter.props} data={availablePrinters} fullWidth />
                            </Grid>
                          </Grid>
                        </Grid>
                      </CustomAccordion> </>): ''}
                    </>
                  }
                  <Grid container className="btn-container" spacing={3}>
                    <Grid container item spacing={3} direction="row" justify="flex-end" alignItems="flex-end">
                      <Grid item>
                        <InputButton
                          variant="text"
                          className="text-btn"
                          label="Cancel"
                          component={RouterLink}
                          to="/configuration"
                        />
                      </Grid>
                      <Grid item>
                        <InputButton
                          variant="contained"
                          className="contained-btn"
                          onClick={() => this.handleFormSubmit(values, isValid)}
                          buttonType="submit"
                          isDisabled={ this.state.jspmVersionMismatch && values?.labelRadioTypes !== "Downloadlabel" ? true : false}
                          label="Save"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </div>
        {/* ) :null} */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    printerConfiguration: state.common.printerConfiguration,
    userDetails: state.auth.userDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    savePrintConfiguration: (printerConfiguration) => {
      dispatch(actions.setPrinterConfiguration(printerConfiguration));
    },
    setUserNotification: (alert) => {
      dispatch(actions.setUserNotification(alert));
    },
    setLoader: (isActive) => {
      dispatch(actions.setLoaderLayOver(isActive));
    },
    setSnackBar: (data) => {
      dispatch(actions.setAppSnackBarData(data));
    },
    setBottomAlert: (alertMsgs) => {
      dispatch(actions.setBottomAlert({ msgs: alertMsgs }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrinterConfiguration);

const labelTypeRadioOptions = [
  {
    value: 'PrintLabel',
    label: 'Print label',
  },
  {
    value: 'Downloadlabel',
    label: 'Download label as PDF',
  },
];

const _jspmVersion = [
  {
    name: 'Select',
    value: ''
  },
  { 
    name: '3',
    value: '3' 
  },
  { 
    name: '4',
    value: '4' 
  }
];