import React from 'react';
import { connect } from 'react-redux';
import { AppBar, Tabs, Tab } from '@material-ui/core/';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import './shipmentManagement.scss';
import TabPanel from '../../../components/shared/tabPanel';
import MyShipmentManagement from '../../../components/MyShipmentManagement/MyShipmentManagement';
import SearchShipmentManagement from '../../../components/shipmentManagement/searchShipment/SearchShipmentManagement';
import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';
import BookedCollections from '../../../components/shipmentManagement/bookedCollections/bookedCollections';
import { abortOnGoingCallsFor, axiosConfig } from '../../../api/axios';
import {
  basicSort,
  arrayToString,
  remove_duplicates,
  customTableSort,
  customTableShipmentNoSort,
  formatDate,
} from '../../../utils/helperFunctions';
import { printZplLabels, printPDF, downloadPDF } from '../../../utils/PrintHelper';
import PromptJSPMClientInstallation from '../../../components/addShipment/Printing/PromptJSPMClientInstallation';
import CustomDialog from '../../../framework/dialog/customDialog';
import * as actions from '../../../store/actions/index';
import { formatDateToUKFormat } from '../../../utils/helperFunctions';

class ShipmentManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      columns: [
        { title: 'id', field: 'id', hidden: true },
        {
          title: 'Customer ref.',
          field: 'customerRef',
          // customSort: (a, b) => customTableSort(a?.customerRef, b?.customerRef),
          render: (row) => (
            <Link className="Redirect-Link" to={`/shipment/shipmentmanagement/summary/${row.id}`}>
              {row.customerRef}
            </Link>
          ),
          width: 150,
          sorting: true,
        },
        {
          title: 'Alternative ref.',
          field: 'alternativeRef',
          // customSort: (a, b) => customTableSort(a?.alternativeRef, b?.alternativeRef),
          render: (row) => (
            <Link className="Redirect-Link" to={`/shipment/shipmentmanagement/summary/${row.id}`}>
              {row.alternativeRef}
            </Link>
          ),
          width: 150,
          sorting: true,
        },
        {
          title: 'Shipment no.',
          field: 'shipmentNumber',
          // customSort: (a, b) => customTableShipmentNoSort(a?.shipmentNumber, b?.shipmentNumber),
          render: (row) => (
            <Link className="Redirect-Link" to={`/shipment/shipmentmanagement/summary/${row.id}`}>
              {row.shipmentNumber}
            </Link>
          ),
          width: 150,
          sorting: true,
        },
        {
          title: 'Return shipment no.',
          field: 'returnShipmentNumber',
          // customSort: (a, b) => customTableShipmentNoSort(a?.returnShipmentNumber, b?.returnShipmentNumber),
          render: (row) => (
            <Link className="Redirect-Link" to={`/shipment/shipmentmanagement/summary/${row.id}`}>
              {row.returnShipmentNumber}
            </Link>
          ),
          width: 150,
          sorting: true,
        },
        {
          title: 'Collection date',
          field: 'collectionDate',
          customSort: (a, b) =>
            basicSort(new Date(a?.collectionDate)?.getTime(), new Date(b?.collectionDate)?.getTime()),
          render: (row) => {
            let dateFormate = formatDateToUKFormat(row.collectionDate);
            return (
              <Link className="Redirect-Link" to={`/shipment/shipmentmanagement/summary/${row.id}`}>
                {dateFormate}
              </Link>
            );
          },
          width: 80,
        },
        {
          title: 'Business/Recipient name',
          field: 'businessName',
          // customSort: (a, b) => customTableSort(a?.businessName, b?.businessName),
          render: (row) => (
            <Link className="Redirect-Link" to={`/shipment/shipmentmanagement/summary/${row.id}`}>
              {row.businessName}
            </Link>
          ),
          width: 150,
          sorting: true,
        },
        {
          title: 'Postal code',
          field: 'collectionPostalCode',
          // customSort: (a, b) => customTableSort(a?.collectionPostalCode, b?.collectionPostalCode),
          render: (row) => (
            <Link className="Redirect-Link" to={`/shipment/shipmentmanagement/summary/${row.id}`}>
              {row.collectionPostalCode}
            </Link>
          ),
          width: 100,
          sorting: true,
        },
        {
          title: 'No. of items',
          field: 'noOfItems',
          // customSort: (a, b) => customTableSort(a?.noOfItems, b?.noOfItems),
          render: (row) => (
            <Link className="Redirect-Link" to={`/shipment/shipmentmanagement/bookedcollection/${row.id}`}>
              {row.noOfItems}
            </Link>
          ),
          width: 150,
          sorting: true,
        },
      ],

      bookedCollectionColumn: [
        { title: 'id', field: 'id', hidden: true },
        {
          title: 'Customer ref.',
          field: 'customerRef',
          // customSort: (a, b) => customTableSort(a?.customerRef, b?.customerRef),
          render: (row) => (
            <Link className="Redirect-Link" to={`/shipment/shipmentmanagement/bookedcollection/${row.id}`}>
              {row.customerRef}
            </Link>
          ),
          width: 150,
          sorting: true,
        },
        {
          title: 'Shipment no.',
          field: 'shipmentNumber',
          // customSort: (a, b) => customTableSort(a?.shipmentNumber, b?.shipmentNumber),
          render: (row) => (
            <Link className="Redirect-Link" to={`/shipment/shipmentmanagement/bookedcollection/${row.id}`}>
              {row.shipmentNumber}
            </Link>
          ),
          width: 200,
          sorting: true,
        },
        {
          title: 'Collection date',
          field: 'collectionDate',

          customSort: (a, b) =>
            basicSort(new Date(a?.collectionDate)?.getTime(), new Date(b?.collectionDate)?.getTime()),
          render: (row) => {
            let dateFormate = formatDateToUKFormat(row.collectionDate);
            return (
              <Link className="Redirect-Link" to={`/shipment/shipmentmanagement/bookedcollection/${row.id}`}>
                {dateFormate}
              </Link>
            );
          },
          width: 80,
        },
        {
          title: 'Business/Recipient name',
          field: 'businessName',
          // customSort: (a, b) => customTableSort(a?.businessName, b?.businessName),
          render: (row) => (
            <Link className="Redirect-Link" to={`/shipment/shipmentmanagement/bookedcollection/${row.id}`}>
              {row.businessName}
            </Link>
          ),
          width: 190,
          sorting: true,
        },
        {
          title: 'Collection postal code',
          field: 'collectionPostalCode',
          // customSort: (a, b) => customTableSort(a?.collectionPostalCode, b?.collectionPostalCode),
          render: (row) => (
            <Link className="Redirect-Link" to={`/shipment/shipmentmanagement/bookedcollection/${row.id}`}>
              {row.collectionPostalCode}
            </Link>
          ),
          width: 80,
          sorting: true,
        },
        { title: 'consigneePostCode', field: 'consigneePostCode', hidden: true },
      ],
      shipmentSearchParams: '',
      bookedCollection_pageDetails: {
        offset: 0,
        limit: 0,
        totalRecordsLength: 0,
      },
      bookedCollection_final_data: [],
      bookedCollection_totalCount: 0,
      shipment_pageDetails: {
        offset: 0,
        limit: 0,
        totalRecordsLength: 0,
      },
      shipment_final_data: [],
      shipment_totalCount: 0,
      shipment_offset: 0,
      openJSPMPrompt: false,
      shipmentCancelItems: [],
      bookedCollectionCancelItems: [],
      shipmentIsMessage: false,
      bookedCollectionIsMessage: false,
      userAccounts: props.userAccounts,
      shipmentManagementData: [],
      bookedCollectionsData: [],
      isLoading: false,
      pageNumber: 0,
      pageSize: 10,
    };
    this.onShipmentChange = this.onShipmentChange.bind(this);
  }

  onShipmentChange(value) {
    this.setState({ search: true, pageSize: this.state.pageSize || 10, pageNumber: 0 }, () => {
      this.getShipmentData();
    });
  }

  handlePrintSubmit = (values, selectedShipments, errors) => {
    const itemsToBeReprinted = selectedShipments.map((shipment) => {
      return shipment.id;
    });
    let printFormat
    const payload = itemsToBeReprinted;
    if (this.props.printerConfiguration?.isDowloadLabelAsPDF) {
      printFormat = 'PDF';
    } else {
      printFormat = this.props.printerConfiguration?.labelType === 1 ? 'ZPL' : 'PDF';
    }
    let pageSize = this.props.printerConfiguration?.pageSize ? this.props.printerConfiguration?.pageSize : 0
    axiosConfig
      .post(
        `${ServiceEndPoints.reprintShipments}?isLabel=${values.reprintLabel}&pageSize=${pageSize}&isCustomsInvoice=${values.reprintCustomsInvoice}&format=${printFormat}`,
        payload,
      )
      .then((response) => {
        if (!this.props.JSPMStatus && !this.props.printerConfiguration?.isDowloadLabelAsPDF) {
          this.setState({ openJSPMPrompt: true });
        } else {
          let labelResponse = response?.data[0]?.labelResponse;
          let customsInvoiceResponse = response?.data[0]?.customsInvoiceResponse;

          labelResponse?.map((lblResponse, labelIndex) => {
            lblResponse?.labels?.map((lblValue, index) => {
              if (this.props.printerConfiguration?.isDowloadLabelAsPDF === true) {
                let lableName = '';
                if (index === 0) {
                  lableName = '_Label.pdf';
                } else if (index === 1) {
                  lableName = '_ReturnLabel.pdf';
                } else {
                  lableName = '_ReturnInstructions.pdf';
                }
                downloadPDF(lblValue.label, lableName, response.data[0], labelIndex);
              }
              else {
                if (this.props.printerConfiguration?.labelType == 1) {
                  printZplLabels(lblValue.label, this.props.printerConfiguration?.labelPrinter);
                } else {
                  printPDF(lblValue.label, this.props.printerConfiguration?.labelPrinter, "Label");
                }
              }
              this.props.setAppSnackBarData({ msg: 'Shipment successfully reprinted' });
            });
          });
          if (this.props.genralConfiguration && this.props.genralConfiguration.printCustomInvoice) {
            customsInvoiceResponse?.map((customResponse, customIndex) => {
              customResponse?.customsInvoice?.map((customValue, index) => {
                if (this.props.printerConfiguration?.isDowloadLabelAsPDF === true) {
                  let lableName = '';
                  if (index === 0) {
                    lableName = '_CustomsInvoice.pdf';
                  } else if (index === 1) {
                    lableName = '_CustomsInvoice.pdf';
                  } else {
                    lableName = '_CustomsInvoice.pdf';
                  }
                  downloadPDF(customValue, lableName, response?.data[0], customIndex);
                }
                else {
                  printPDF(customValue, this.props.printerConfiguration?.otherReportsPrinter, "Invoice");
                }
                this.props.setAppSnackBarData({ msg: 'Shipment successfully reprinted' });

              });
            });
          }
          this.setState({ search: true }, () => {
            this.getShipmentData();
          });
        }
      })
      .catch((error) => {
        console.log(error);
        var failedReprintShipment = '';
        payload.map((invdata) => (failedReprintShipment = failedReprintShipment + invdata + ','));
        this.props.setBottomAlert({
          msgs: [
            {
              type: 'error',
              msg: 'Error in reprinting the shipment:' + failedReprintShipment.slice(0, -1),
            },
          ],
        });
      });
  };

  cancelSelectedShipment = (shipmentIds) => {
    const itemsToBeSelected = shipmentIds.map((shipment) => {
      const item = {
        pickupAccount: shipment?.pickupAccount,
        shipmentId: shipment?.id,
        postalCode: shipment?.collectionPostalCode ? shipment?.collectionPostalCode : null,
      };
      return item;
    });

    const payload = itemsToBeSelected;
    this.setState({ shipmentCancelItems: itemsToBeSelected });
    axiosConfig
      .post(ServiceEndPoints.cancelShipment, payload)
      .then((response) => {
        this.setState({ search: true }, () => {
          this.getShipmentData();
        });
        this.props.setAppSnackBarData({ msg: 'Shipment successfully cancelled' });
      })
      .catch((error) => {
        var failedShipment = '';
        payload.map((invdata) => (failedShipment = failedShipment + invdata.shipmentId + ','));
        this.props.setBottomAlert({
          msgs: [
            {
              type: 'error',
              msg: 'Error in cancelling the shipment:' + failedShipment.slice(0, -1),
            },
          ],
        });
      });
  };

  handleTabChange = (event, index) => {
    this.setState({ tabIndex: index, search: true, pageNumber: 0, pageSize: 10 }, () => {
      setTimeout(() => {
        this.getShipmentData();
      }, 100);
    });
  };

  setShipmentSearchParams = (values) => {
    this.setState({ shipmentSearchParams: values });
  };

  // _MyShipmentManagement = (query) => {
  //   return this.getShipmentManagementResults(query);
  // };

  getShipmentData = () => {
    this.setState({ isLoading: true });
    const isBookedCollections = this.state.tabIndex === 1;
    const offset = this.state.pageNumber * this.state.pageSize;
    abortOnGoingCallsFor(ServiceEndPoints.getShipmentManagement);
    const url = `${ServiceEndPoints.getShipmentManagement}isCollectionRequest=${isBookedCollections}`;
    setTimeout(() => {
      this.state.userAccounts?.length > 0 ? axiosConfig
        .get(this.getUrl(url, offset, this.state.pageSize))
        .then((res) => {
          isBookedCollections ? this.formatBookingDetails(res) : this.formatShipmentManagementData(res);

          this.setState({
            [isBookedCollections ? 'bookedCollection_pageDetails' : 'shipment_pageDetails']: {
              offset: offset,
              limit: this.state.pageSize,
              totalRecordsLength: res.data?.totalRecords,
            },
            search: false,
          });
        })
        .catch((err) => {
          isBookedCollections ? this.formatBookingDetails([]) : this.formatShipmentManagementData([]);
          this.setState({ shipmentManagementData: [] });
          this.setState({
            shipment_pageDetails: {
              offset: 0,
              limit: 10,
              totalRecordsLength: 0,
            },
            bookedCollection_pageDetails: {
              offset: 0,
              limit: 10,
              totalRecordsLength: 0,
            },
            search: false,
          });
        })
        .finally(() => {
          this.setState({ isLoading: false });
        }) : this.setState({ isLoading: false });
    }, 0);
  };

  getUrl(url, offset, limit) {
    url += `&${arrayToString(this.state.userAccounts, 'accounts', 'accountId')}&status=Order received`;
    // if(this.state.tabIndex !== 1 || (this.state.tabIndex === 1 && this.state.shipmentSearchParams.split('&')[1].split('=')[0] !== 'fromCollectionDate')){
      url += this.state.shipmentSearchParams;
    // }
    url += `&offset=${offset}`;
    return (url += `&limit=${limit}`);
  }

  formatShipmentManagementData = (result) => {
    let final_data = [];
    (((result || {}).data || {}).shipmentModels || []).map((element, index) => {
      const shipments = element.shipments;
      const pickup = element.pickup;
      const pickupAccount = element.pickupAccount;
      final_data.push({
        id: shipments[0].shipmentDetails.shipmentId,
        noOfItems: element.numberOfParcels,
        customerRef: shipments[0].shipmentDetails?.customerRef1 ?? '',
        customerNumber: shipments[0].consigneeAddress?.idNumber ?? '',
        alternativeRef: shipments[0].shipmentDetails?.customerRef2 ?? '',
        shipmentNumber: shipments[0].shipmentDetails?.shipmentId ?? '',
        returnShipmentNumber: shipments[0]?.return?.returnShipmentId ?? '',
        collectionDate: formatDate(pickup && pickup.date, '-', 'yy/mm/dd'),
        exchangeOnDelivery: shipments[0].shipmentDetails?.exchangeOnDelivery,
        businessName:
          // shipments[0].consigneeAddress?.recipientType === 1
          shipments[0].consigneeAddress?.companyName !== ''
            ? shipments[0].consigneeAddress?.companyName ?? ''
            : shipments[0].consigneeAddress?.name ?? '',
        collectionPostalCode: shipments[0].consigneeAddress?.postalCode ?? '',
        isDomestic: shipments[0].consigneeAddress?.country === 'GB' ? true : false,
        pickupAccount,
      });
    });
    if (this.state.shipmentCancelItems.length > 0) {
      final_data = remove_duplicates(final_data, this.state.shipmentCancelItems, 'id', 'shipmentId');
      let _search_params = this.state.shipmentSearchParams;
      _search_params = _search_params.substring(_search_params.indexOf('=') + 1);
      if (final_data?.length === 0 && _search_params === '') {
        this.setState({ shipmentIsMessage: true });
      } else {
        this.setState({ shipmentIsMessage: false });
      }
    }
    this.setState({ shipmentManagementData: final_data });
  };

  formatBookingDetails(response) {
    let final_data = response?.data?.shipmentModels?.map((shippingModel, index) => {
      const consigneeAddress = shippingModel?.shipments?.[0]?.consigneeAddress;
      const shipmentDetails = shippingModel?.shipments?.[0]?.shipmentDetails;
      const pickupAddress = shippingModel?.pickupAddress ?? {};
      return {
        id: shippingModel?.shipmentId,
        customerRef: shipmentDetails?.customerRef1,
        customerNumber: consigneeAddress?.idNumber,
        alternativeRef: shipmentDetails?.customerRef2,
        shipmentNumber: shippingModel.shipmentId,
        collectionDate: formatDate(shippingModel?.pickup?.date, '-', 'yy/mm/dd'),
        businessName: pickupAddress?.companyName || pickupAddress.name,
        collectionPostalCode: pickupAddress?.postalCode,
        consigneePostCode: consigneeAddress?.postalCode,
        pickupAccount: shippingModel?.pickupAccount,
      };
    });
    if (this.state.bookedCollectionCancelItems.length > 0) {
      final_data = remove_duplicates(final_data, this.state.bookedCollectionCancelItems, 'id', 'shipmentId');
      let _search_params = this.state.shipmentSearchParams;
      _search_params = _search_params.substring(_search_params.indexOf('=') + 1);
      if (final_data?.length === 0 && _search_params === '') {
        this.setState({ bookedCollectionIsMessage: true });
      } else {
        this.setState({ bookedCollectionIsMessage: false });
      }
    }
    this.setState({ bookedCollectionsData: final_data });
  }

  cancelCollection = (shipmentIds) => {
    const itemsToBeSelected = shipmentIds.map((shipment) => {
      const item = {
        pickupAccount: shipment?.pickupAccount,
        shipmentId: shipment?.id,
        postalCode: shipment?.consigneePostCode ? shipment?.consigneePostCode : null,
      };
      return item;
    });
    this.setState({ bookedCollectionCancelItems: itemsToBeSelected });
    const payload = itemsToBeSelected;
    axiosConfig
      .post(ServiceEndPoints.cancelShipment, payload)
      .then((response) => {
        this.setState({ search: true }, () => {
          this.getShipmentData();
        });
        this.props.setAppSnackBarData({ msg: 'Collection successfully cancelled' });
      })
      .catch((error) => {
        var failedCollection = '';
        payload.map((invdata) => (failedCollection = failedCollection + invdata.shipmentId + ','));
        this.props.setBottomAlert({
          msgs: [
            {
              type: 'error',
              msg: 'Error in cancelling the collection:' + failedCollection.slice(0, -1),
            },
          ],
        });
      });
  };

  componentDidMount() {
    // this.getShipmentData();
    if (this.props.activeTab === 'bookedCollection') {
      this.setState({ tabIndex: 1 }, () => {
        this.getShipmentData();
      });
    }
    this.props.setActiveTab('');
    if (
      this.props.history.location &&
      this.props.history.location.state !== undefined &&
      this.props.history.location.state !== null
    ) {
      if (this.props.history.location.state.shipmentSummary) {
        this.setState({ shipmentCancelItems: this.props.history.location.state.itemsToBeSelected });
      } else {
        this.setState({ bookedCollectionCancelItems: this.props.history.location.state.itemsToBeSelected });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.userAccounts.length !== nextProps.userAccounts.length) {
      this.setState({ userAccounts: nextProps.userAccounts }, () => {
        this.getShipmentData();
      });
    }
  }

  handleClose = () => {
    this.setState({ openJSPMPrompt: false });
  };

  onPageNumChange(pageNum) {
    this.setState({ pageNumber: pageNum }, () => {
      this.getShipmentData();
    });
  }

  onPageSizeChange(pageSize) {
    this.setState({ pageSize: pageSize }, () => {
      this.getShipmentData();
    });
  }

  render() {
    return (
      <React.Fragment>
        <Grid container className="Shipment-Container">
          <AppBar position="static" className="Shipment-AppBar">
            <Grid container spacing={3} direction="row">
              <Grid className="shipment-management-tab" item xs={12} sm={4} lg={4}>
                <Tabs
                  value={this.state.tabIndex}
                  onChange={this.handleTabChange}
                  className={`${this.state.tabIndex === 0 ? 'shipment-Tab-panel' : 'bookedCollection-Tab-panel'}`}
                >
                  <Tab label="Booked shipments" className="Tab-panel" />
                  <Tab label="Booked collections" className="Tab-panel bookedCollection-child-Tab-panel" />
                </Tabs>
              </Grid>
              <Grid item xs={12} sm={8} lg={8}>
                <SearchShipmentManagement
                  className="AddressBook-SearchBar"
                  tabIndex={this.state.tabIndex}
                  onShipmentChange={this.onShipmentChange}
                  setShipmentSearchParams={this.setShipmentSearchParams}
                />
              </Grid>
            </Grid>
          </AppBar>
          <>
            <TabPanel className="My-Address-Book-Tab" value={this.state.tabIndex} index={0} style={{ width: '100%' }}>
              <MyShipmentManagement
                rows={this.state.shipmentManagementData}
                columns={this.state.columns}
                shipmentSearchParams={this.state.shipmentSearchParams}
                cancelSelectedShipment={this.cancelSelectedShipment}
                handlePrintSubmit={this.handlePrintSubmit}
                Dialogtitle="Cancel shipment"
                confirmationMessage="Are you sure you want to cancel the selected shipments?"
                reprintTitle="Select type of reprint"
                empty_DataSource_Message={
                  this.state.shipmentIsMessage ? 'All shipments on this page are cancelled' : 'Shipment not found'
                }
                filtering={false}
                pageSize={this.state.pageSize}
                totalCount={this.state.shipment_pageDetails.totalRecordsLength}
                count={this.state.shipment_pageDetails.totalRecordsLength}
                isLoading={this.state.isLoading}
                inBuiltEvents={{
                  onChangeRowsPerPage: (e) => this.onPageSizeChange(e),
                  onChangePage: (a) => this.onPageNumChange(a),
                }}
                page={this.state.pageNumber}
              />
            </TabPanel>
            <TabPanel className="My-Address-Book-Tab" value={this.state.tabIndex} index={1} style={{ width: '100%' }}>
              <BookedCollections
                rows={this.state.bookedCollectionsData}
                columns={this.state.bookedCollectionColumn}
                pageSizeOptions={[10, 25, 50]}
                cancelCollection={this.cancelCollection}
                confirmationMessage="Are you sure you want to cancel the selected collection?"
                empty_DataSource_Message={
                  this.state.bookedCollectionIsMessage
                    ? 'All Collection on this page are cancelled'
                    : 'Collection not found'
                }
                pageSize={this.state.pageSize}
                totalCount={this.state.bookedCollection_pageDetails.totalRecordsLength}
                count={this.state.bookedCollection_pageDetails.totalRecordsLength}
                isLoading={this.state.isLoading}
                inBuiltEvents={{
                  onChangeRowsPerPage: (e) => this.onPageSizeChange(e),
                  onChangePage: (a) => this.onPageNumChange(a),
                }}
                page={this.state.pageNumber}
                shipmentSearchParams={this.state.shipmentSearchParams}
                tabIndex={this.state.tabIndex}
              />
            </TabPanel>
          </>
        </Grid>
        <CustomDialog open={this.state.openJSPMPrompt} onClose={this.handleClose} title="JSPM">
          <PromptJSPMClientInstallation />
        </CustomDialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeTab: state.common.activeTab,
    userAccounts: state.accounts.userAccounts,
    JSPMStatus: state.common.isJSPMInstalled,
    printerConfiguration: state.common.printerConfiguration,
    availablePrinter: state.common.availablePrinter,
    genralConfiguration: state.common.commonConfiguration,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAppSnackBarData: (data) => {
      dispatch(actions.setAppSnackBarData(data));
    },
    setBottomAlert: (data) => {
      dispatch(actions.setBottomAlert(data));
    },
    setActiveTab: (value) => {
      dispatch(actions.setActiveTab(value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentManagement);
