import React, { useState } from 'react';

import {  Grid, Paper } from '@material-ui/core';

import DynamicMaterialTable from '../../framework/DynamicMaterialTable/DynamicMaterialTable';
import InputButton from '../../framework/Inputs/inputButton/inputButton';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { basicSort, formatDate } from '../../utils/helperFunctions';

const ApplicationUpdates = (props) => {
  const history = useHistory();

  let tableData = useSelector((state) => state.applicationUpdates.applicationUpdates);

let applicationArray = []
let updatedDate = tableData.map((data, i) => {
  const appObj = {}
 
  appObj["id"] = data.id
  appObj["createdOn"] = formatDate(data.createdOn, '/', 'dd/mm/yy')
  appObj["updateDescription"] = data.updateDescription;

  applicationArray.push(appObj)
  
})


  const tableColumns = [
    { title: 'id', field: 'id', hidden: true },
    {
      title: 'Update description',
      field: 'updateDescription',
      sorting: false,
    },
    {
      title: 'Date',
      field: 'createdOn',
      customSort: (a, b) =>
      basicSort(new Date(a.createdOn).getTime(), new Date(b.createdOn).getTime()),
    },
  ];

  const [columns] = useState(tableColumns);

  const handleBackButton = () => {
    history.push('/shipment/addShipment');

  };

  return (
    <Paper className="paper-container application-updates" style={{padding:'20px 15px'}} >

 <Grid container direction="column"  className="Table-column-color application-Table">
      <DynamicMaterialTable
        rows={applicationArray}
        columns={columns}
        isSelectable={false}
        empty_DataSource_Message="Application Updates not found"
        sorting={true}
        paging={true}
        pageSize={10}
        pageSizeOptions={[10, 25, 50]}
        fullWidth
      />
      <Grid container spacing={3} direction="row" justify="flex-end" alignItems="flex-end" className="Top-10px">
        <Grid item>
          <InputButton
            variant="outlined"
            className="text-btn"
            style={{ textTransform: 'unset' }}
            label="Back"
            onClick={() => handleBackButton()}
          />
        </Grid>
      </Grid>
    </Grid>
 
    </Paper>
    );
};

ApplicationUpdates.propTypes = {};

export default ApplicationUpdates;
