/* eslint-disable no-useless-concat */
import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl, FormLabel, FormHelperText } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { at } from 'lodash';
import { useField } from 'formik';
import { connect } from 'react-redux';
import './multipleSelect.scss';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: '#f50057',
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};

function MultipleSelect(props) {
  const classes = useStyles();
  const { label, isRequired, data, displayField, isDisabled, isFromCreateNewUser, hasDefaultValue, _className, ...rest } = props;
  const [field, meta] = useField(props);
  let { value: selectedValue } = field;
  if (!selectedValue) {
    if (hasDefaultValue) {
      selectedValue = [{ accountId: "select" }];
    } else {
      selectedValue = [];
    }
  } else if (hasDefaultValue) {
      if (selectedValue.length > 0 && selectedValue[0].accountId === 'select') {
        selectedValue.shift()
      }
    }
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;
  const renderHelperText = () => {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  };
  return (
    <Grid container className="multiple-select-container">
      <FormControl className="multiple-select">
        <FormLabel className="labelSelect">
          {isRequired
            ? (
              <label className="labelSelect">
                {label} <span className="span-required">*</span>
              </label>
            )
            : (
              <label className="labelSelect">{label}</label>
            )}
        </FormLabel>
        <Select
          disabled={isDisabled === true ? isDisabled : false}
          {...field}
          labelId="mutiple-select-label"
          multiple
          value={selectedValue ? selectedValue : ''}
          renderValue={
            (isDisabled === true && isFromCreateNewUser === false)
              ? (selected) => 'please select 1 tab'
              : (selected) => {
                if (selected[0].accountId === 'select') {
                  return 'Select'
                }
                if (selected.length > 1) {
                  return `${selected.length} ` + `accounts selected`;
                }
                return `${selected.length} ` + `account selected`;
              }
          }
          MenuProps={MenuProps}
          disableUnderline
        >
          {data.map((option) => (
            <MenuItem className={_className} key={option.accountId} value={option.accountId}>
              <ListItemIcon>
                <Checkbox checked={selectedValue.indexOf(option.accountId) > -1} />
              </ListItemIcon>
              <ListItemText primary={option[displayField]+'-'+option['accountName']} />
            </MenuItem>
          ))}
        </Select>
        <div style={{ marginTop: '-10px' }}>{renderHelperText()}</div>
      </FormControl>
    </Grid>
  );
}

export default MultipleSelect;
