import React from 'react';
import { Card, Grid, Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CustomCaption from '../../../../framework/summaryFormate/CustomCaption';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Slider from "react-slick";
import './TrackingDetails.scss';
import { ReactComponent as ArrowLeft } from "../../../../assets/images/slider-arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../../../assets/images/slider-arrow-right.svg";
import { GOOGLE_API_KEY } from '../../../../utils/constants/ApiConstant';
import { convertTZwithFormat } from '../../../../utils/helperFunctions';

const useStyles = makeStyles((theme) => ({
  contentTitle: {
    color: '#000',
    fontSize: '16px',
    display: 'block',
    paddingBottom: '5px'
  }
}));

var settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  prevArrow: <ArrowLeft />,
  nextArrow: <ArrowRight />
};
const TrackingStepperDetails = ({ ContentDetails }) => {
  const classes = useStyles();
  let listOfImages = [];
  let eventMapURL = '';
  // let decodedImage = atob(ContentDetails.imageBase64);
  // console.log("ContentDetails",ContentDetails);
  {
    ContentDetails.shipmentImages.map((photo, key) => {

      if (photo.imageType != "signature") {
        var decodedStringAtoB = atob(photo.imageBase64);
        listOfImages.push({ decodedStringAtoB })
      }
      // Decode the String
    }

    )

    if (ContentDetails.latitude && ContentDetails.longitude) {
      eventMapURL = `https://www.google.com/maps/embed/v1/place?key=${GOOGLE_API_KEY}&q=${ContentDetails.latitude},${ContentDetails.longitude}&zoom=11`
    }
  }
  return (
    <div className="TrackingStepperDetails">
      {eventMapURL && `Event Map`}
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12} lg={4} md={4} sm={12}>
          <Typography variant="p" className={classes.contentTitle}>
            {eventMapURL &&
              <iframe
                width="100%"
                height="300px"
                loading="lazy"
                allowFullScreen
                frameBorder="0"
                src={eventMapURL}
              >
              </iframe>
            }
          </Typography>
        </Grid>

        <Grid item xs={12} lg={4} md={4} sm={12}>
          <Slider {...settings}>
            {listOfImages.map((img, key) => <div>
              <img width="100%" height="300px" src={img.decodedStringAtoB} alt="imagelist" />
            </div>)

            }
          </Slider>
        </Grid>
        <Grid item xs={12} lg={4} md={4} sm={12}>
          <Typography variant="div" display="block" style={{ paddingBottom: '15px' }}>
            <Typography className="sub-title" variant="p">
              {ContentDetails.labels}
            </Typography>
            <Typography variant="p" className={`${classes.contentTitle} Testing`} >
              {ContentDetails.location}
            </Typography>
            <Typography variant="p" className={classes.contentTitle}>
              {convertTZwithFormat(ContentDetails.dateAndTime, 'YYYY-MM-DDTHH:mm:ssZ')}
            </Typography>
          </Typography>
          <Typography variant="div" display="block" style={{ paddingBottom: '15px' }}>
            {ContentDetails.deliveryType != "" ? <CustomCaption title="Delivery Type" value={ContentDetails.deliveryType} /> : ""
            }
          </Typography>
          {ContentDetails.comments === ''
            ? " "
            : <Typography variant="div" display="block" style={{ paddingBottom: '15px' }}>
              <CustomCaption title="Comments" value={ContentDetails.comments} />
            </Typography>}

          {ContentDetails.shipmentImages.map((sign, key) => {
            var decodedStringAtoB = atob(sign.imageBase64);

            return (
              sign.imageType === 'signature'
                ? <Typography variant="div" display="block" style={{ paddingBottom: '15px' }}>
                  <Typography variant="caption" className={classes.contentTitle}>
                    Signature of the receiver
                  </Typography>
                  <img width="100" height="100" src={decodedStringAtoB} />
                </Typography>
                : ""
            )
          })}


        </Grid>
      </Grid>
    </div>
  );
};

export default TrackingStepperDetails;
