import React from 'react';
import { Form, Formik } from 'formik';
import SearchShipment from './SearchShipment';
import { getYupSchemaFromMetaData } from '../../../utils/yupSchema/yupSchemaGenerator';
import { shipmentManagementDateFilter } from '../../../utils/constants/constants';

const SearchShipmentManagement = (props) => {
  const formField = searchForm;
  const newSearchValidationSchema = getYupSchemaFromMetaData(formField, [], []);

  const initialValues = {
    shipmentManagementSearchFor: '',
    shipmentManagementSearchBy: searchByOptions[0],
    deliveryStatusForAccounts: shipmentManagementDateFilter[0],
  };

  return (
    <div>
      <Formik initialValues={initialValues} enableReinitialize={true} validationSchema={newSearchValidationSchema}>
        {({ values, setFieldValue, errors, setFieldTouched, resetForm, setValues }) => (
          <Form className="Addessbook-Searchbar">
            <SearchShipment
              formField={formField}
              searchByOptions={searchByOptions}
              formikValues={values}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              tabChanged={props.tabIndex}
              onShipmentChange={props.onShipmentChange}
              errors={errors}
              setShipmentSearchParams={props.setShipmentSearchParams}
              resetForm={resetForm}
              setValues={setValues}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SearchShipmentManagement;

const searchForm = {
  shipmentManagementSearchFor: {
    props: {
      name: 'shipmentManagementSearchFor',
      type: 'searchControl',
    },
    selectProps: {
      props: {
        name: 'shipmentManagementSearchBy',
        label: 'Search For',
        placeholder: "Enter Customer's Number",
      },
    },
    textProps: {
      props: {
        name: 'shipmentManagementSearchFor',
        label: 'Search For',
        placeholder: "Enter Customer's Number",
      },
    },
    validation: {
      name: 'shipmentManagementSearchFor',
      validationType: 'string',
      // validations: [
      //   {
      //     type: 'required',
      //     params: ['SearchFor Number is required'],
      //   },
      // ],
    },
  },
  deliveryStatusForAccounts: {
    props: {
      name: 'deliveryStatusForAccounts',
      // label: 'Select despatch date',
      type: 'select',
      isRequired: true,
      displayField: 'name',
      hasDefaultValue: true,
    },
    value: shipmentManagementDateFilter[0],
  },
};

const searchByOptions = [
  {
    value: 'customerrefnumber',
    name: 'Customer reference',
  },
  {
    value: 'shipmentnumber',
    name: 'Shipment number',
  },
  // {
  //   value: 'customernumber',
  //   name: 'Customer number',
  // },
  {
    value: 'recipientname',
    name: 'Recipient name',
  },
  {
    value: 'businessname',
    name: 'Business name',
  },
  {
    value: 'postcode',
    name: 'Postal code',
  },
];
