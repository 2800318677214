import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { Grid, Box, Snackbar } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import bookDriverPickUpForm from '../../../utils/formConfig/bookDriverPickUpForm/bookDriverPickUpForm';

import DriverPickUpContainer from './DriverPickUpContainer';
import InputButton from '../../../framework/Inputs/inputButton/inputButton';
import './bookDriverPickUp.scss';
import {
  formatBookDriverPickUpObj,
  getDomesticAccounts,
  isPickUpAvailableInAccount,
} from '../../../utils/helperFunctions';
import { axiosConfig } from '../../../api/axios';
import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { getYupSchemaFromMetaData } from '../../../utils/yupSchema/yupSchemaGenerator';

// let latestPickup = null;
const BookDriverPickUp = () => {
  const { formId, formField } = bookDriverPickUpForm;
  const [latestPickup, setLatestPickup] = useState(null);
  const [operationalAccount, setOperationalAccount] = useState(null)
  let history = useHistory();
  const dispatch = useDispatch();
  const initialValues = {};

  for (let property in formField) {
    if (Object.prototype.hasOwnProperty.call(formField, property)) {
      const field = formField[property];
      if (field.props?.type === 'checkbox' || field.props?.type === 'radio') {
        initialValues[field.props.name] = field.value;
      } else if (field.props?.type === 'array') {
        initialValues[field.props.name] = field.values;
      } else {
        initialValues[field.props.name] = field.value || '';
      }
    }
  }

  const [errorMessage, setErrorMessage] = useState('Latest time cannot be less than earliest time');
  const domesticAccounts = useSelector((state) => state.accounts.userAccounts);
  const selectedAccount = domesticAccounts.find((account) => isPickUpAvailableInAccount(account)) || {};
  const userDetails = useSelector((state) => state.auth.userDetails);
  const [isCurrentPickupBooked, setIsCurrentPickUpBooked] = useState(false);
  const [driverPickupValidationSchema, setDriverPickupValidationSchema] = useState(
    getYupSchemaFromMetaData(formField, [], []),
  );
  const updateValidationSchema = () => {
    setDriverPickupValidationSchema(getYupSchemaFromMetaData(formField, [], []));
  };

  const handleSubmit = (values, errors, setFieldValue, resetForm) => {
    const validationObject = isFormInValid(errors, values);
    if (validationObject.formValid) {
      dispatch(actions.setLoaderLayOver(true));
      // const minTimeing = moment(values.earliestTime, 'HHmmss');
      // const maxTimeing = moment(values.latestTime, 'HHmmss');
      // if (minTimeing.isBefore(maxTimeing)) {
      if (values) {
        const reqObj = formatBookDriverPickUpObj(values, userDetails, operationalAccount);
        axiosConfig.post(ServiceEndPoints.pickUpsUrl, reqObj)
          .then((res) => {
            if (validationObject.afterLatestTime) {

              dispatch(
                actions.setBottomAlert({
                  msgs: [
                    {
                      type: 'success',
                      msg: `Your request to book a driver pick-up has been received but you have missed the cut off time of ${latestPickup} to book a driver from your postcode area.`,
                    },
                    {
                      type: 'success',
                      msg: `It may not be possible to collect your parcels today. Please contact your local depot to confirm.`,
                    }
                  ],
                  hasMultipleMsgs: false
                }),
              );
            }
            else {
              dispatch(actions.setAppSnackBarData({ msg: 'Your request to book a driver pick-up has been received. You will receive an email confirmation when a driver has been allocated.' }));
            }
            resetForm();
            setIsCurrentPickUpBooked(true);
          })
          .catch((err) => {
            dispatch(
              actions.setBottomAlert({
                msgs: [
                  {
                    type: 'error',
                    msg: `Error in booking driver pick up : ${err.response?.data[0]?.detail ?? 'Error occurred while booking driver pick up, please try again'
                      }`,
                  },
                ],
              }),
            );
            // dispatch(actions.setAppSnackBarData({ type: "error", msg: "Driver pick up booking failed: error" }));
          })
          .finally(() => {
            dispatch(actions.setLoaderLayOver(false));
          });
      }
      // } else {
      //   dispatch(actions.setLoaderLayOver(false));
      //   dispatch(actions.setAppSnackBarData({ type: 'error', msg: 'Latest time cannot be less than earliest time' }));
      // }
    } else {
      dispatch(actions.setLoaderLayOver(false));
      dispatch(
        actions.setBottomAlert({
          msgs: [
            {
              type: 'error',
              msg: 'The form has errors, please correct and try again',
            },
          ],
        }),
      );
      // dispatch(actions.setAppSnackBarData({ type: 'error', msg: 'The form has errors, please correct and try again' }));

    }
  };

  const isFormInValid = (errors, values) => {
    // var earliestTime = values.earliestTime.split(':');
    // var latestTime = values.latestTime.split(':');

    // if (!(earliestTime[0] >= 9 && earliestTime[0] <= 17)) {
    //   return true;
    // }
    // if (!(latestTime[0] >= 9 && latestTime[0] <= 17)) {
    //   return true;
    // }
    var today = new Date();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    let collectionDate = new Date(values.bookCollectionDate).getDate();
    let todayDate = today.getDate();

    const currentSystemTime = moment(time, 'HHmmss')
    const validationObject = { formValid: true, afterLatestTime: false };
    const minTimeing = moment(values.earliestTime, 'HHmmss');
    const maxTimeing = moment(values.latestTime, 'HHmmss');
    const latestTimeAccount = latestPickup ? moment(latestPickup, 'HHmmss') : maxTimeing;

    if (collectionDate === todayDate) {
      if (latestTimeAccount.isBefore(currentSystemTime)) {
        validationObject.afterLatestTime = true;
      }
    }
    // if (latestTimeAccount.isBefore(maxTimeing)) {
    //   validationObject.afterLatestTime = true;
    // }
    if (maxTimeing.isBefore(minTimeing)) {
      validationObject.formValid = false;
    }
    return validationObject;
  };

  const setCurrentBookStatus = (bookVal) => {
    setIsCurrentPickUpBooked(bookVal);
  };

  // useEffect(() => {
  //   selectedAccount?.sender?.postalCode &&
  //     axiosConfig
  //       .get(
  //         `${ServiceEndPoints.getPostcodeDistrict}?postcode=${selectedAccount.sender.postalCode}`,
  //       )
  //       .then((res) => {
  //         latestPickup = res?.data?.latestCollection;
  //       })
  //       .catch((err) => console.log(err));
  // }, [selectedAccount])
  return (
    <Formik initialValues={initialValues} enableReinitialize={true} validationSchema={driverPickupValidationSchema}>
      {({ values, setFieldValue, setErrors, errors, setFieldTouched, resetForm }) => (
        <Form id={formId} className="BookDriverPickUpContainer">
          <DriverPickUpContainer
            setLatestPickup={setLatestPickup}
            formField={formField}
            formikValues={values}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            setErrors={setErrors}
            errors={errors}
            errorMessage={errorMessage}
            isCurrentPickupBooked={isCurrentPickupBooked}
            setCurrentBookStatus={setCurrentBookStatus}
            updateValidationSchema={updateValidationSchema}
            setOperationalAccount={setOperationalAccount}
          />
          <Grid container className="btn-container" spacing={3} direction="row" justify="flex-end" alignItems="flex-end">
            <Grid item>
              <InputButton
                variant="text"
                className="text-btn"
                label="Cancel"
                onClick={() => history.push('/shipment/addShipment')}
              />
            </Grid>
            <Grid item>
              <InputButton
                buttonType="submit"
                disabled={isCurrentPickupBooked}
                variant="outlined"
                className="contained-btn pickup-btn"
                label="Request pick-up"
                onClick={() => {
                  handleSubmit(values, errors, setFieldValue, resetForm);
                }}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default BookDriverPickUp;
