import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
const useStyles = makeStyles({
  table: {
    minWidth: 700,
    cursor: 'pointer',
    height:200
  },
  tableRow: {
    '&$hover:hover': {
      backgroundColor: '#FFEEA9',
    },
  },
  rowText: {
    verticalAlign: 'text-top',
  },
  heading: {
    fontWeight: 700,
  },
  hover: {},
});
export default function ShowLocationDetails(props) {
  const { rows, rowClicked, isServicePoint } = props;
  const classes = useStyles();
  return isServicePoint
? (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="spanning table">
        <TableBody>
          {rows.map((row) => (
            <TableRow
              // hover
              // classes={{ hover: classes.hover }}
              className={classes.tableRow}
              key={row.servicePointID}
              onClick={() => rowClicked(row)}
              selected={props.selectedID === row.servicePointID}
            >
              <TableCell align="left" className={classes.rowText}>
                <TableHead className={classes.heading}>Address:</TableHead>
                <TableRow>{row.title}</TableRow>
                <TableRow>{row.address.address1}</TableRow>
                <TableRow>{row.address.address2}</TableRow>
                <TableRow>{row.address.address3}</TableRow>
                <TableRow>{row.address.postalTown}</TableRow>
              </TableCell>
              <TableCell align="left" className={classes.rowText}>
                <TableHead className={classes.heading}>Distance:</TableHead>
                <TableRow>Distance {parseFloat((row.distance || 0) * (0.00062137)).toFixed(2)} miles</TableRow>
              </TableCell>
              <TableCell align="left" className={classes.rowText}>
                <TableHead className={classes.heading}>Opening Hours:</TableHead>
                {row.openingHours.map((openingTime) => (
                  <TableRow>
                    {openingTime.dayDescription}-{openingTime.timeDescription}{' '}
                  </TableRow>
                ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
: (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="spanning table">
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              // classes={{ hover: classes.hover }}
              className={classes.tableRow}
              key={index}
              onClick={() => rowClicked(row)}
              selected={props.selectedID === row.depotId}
            >
              <TableCell align="left" className={classes.rowText}>
                <TableHead className={classes.heading}>Address:</TableHead>
                <TableRow>{row.depotAddress.address1}</TableRow>
                <TableRow>{row.depotAddress.address2}</TableRow>
                <TableRow>{row.depotAddress.address3}</TableRow>
                <TableRow>{row.depotAddress.postalTown}</TableRow>
              </TableCell>
              <TableCell align="left" className={classes.rowText}>
                <TableHead className={classes.heading}>Parcel collection opening times: </TableHead>
                {row.openingTimes.map((time) => (
                  <TableRow>
                  {time.openingTime.day}-{time.openingTime.openTime}-{time.openingTime.closeTime}
                  </TableRow>
                ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
