import React from 'react';
import { Grid } from '@material-ui/core';
import FormRenderer from '../../../framework/Inputs/formRenderer/formRenderer';

const CollectionDetails = (props) => {

    const {
        formField: {
            collectionInstructions,
            descriptionOfGoodsToBeCollected,
        },
    } = props;

    return (
        <Grid container>
            <Grid container direction="row" alignItems="flex-start" spacing={6}>
                <Grid item xs={12} sm={6} md={6} lg={6} style={{ paddingRight: '34px' }}>
                    <FormRenderer {...collectionInstructions.props} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} style={{ paddingRight: '34px' }}>
                    <FormRenderer {...descriptionOfGoodsToBeCollected.props} fullWidth />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CollectionDetails;