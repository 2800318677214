import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Drawer, IconButton, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Clear } from '@material-ui/icons';
import * as actions from '../../store/actions/index';

const useStyles = makeStyles((theme) => ({
  err: {
    color: '#D40511',
    fontSize: '16px',
    paddingLeft: '29px',
  },
  success: {
    color: '#1A7C02',
    fontSize: '16px',
    paddingLeft: '29px',
  },
  closeIcon: {
    paddingRight: '20px',
  },
  drawerPaper: {
    minHeight: 70,
  },
}));

const BottomAlert = (props) => {
  const [backDrop, setBackDrop] = useState(false);
  const [alertDetails, setAlertDetails] = useState({});
  const bottomAlertData = useSelector((state) => state.common.bottomAlert);
  const classes = useStyles();
  const dispatch = useDispatch();

  if (backDrop) {
    document.addEventListener('keyup', (event) => {
      if (event?.key === 'Enter') {
        alertClosed();
      }
    });
  }

  useEffect(() => {
    if (bottomAlertData.open) {
      setBackDrop(true);
      setAlertDetails(bottomAlertData);
    } else {
      setBackDrop(false);
      setAlertDetails(bottomAlertData);
    }
  }, [bottomAlertData]);

  const alertClosed = () => {
    setBackDrop(false);
    dispatch(actions.resetBottomAlert());
  };

  return (
    <Grid>
      <Drawer
        disableBackdropClick
        anchor={'bottom'}
        open={backDrop}
        onClose={alertClosed}
        classes={{ paper: classes.drawerPaper }}
      >
        <Grid container direction="row" justify="space-between" alignItems="center" >
          <Grid item>
            <Grid conatiner>
              {alertDetails?.msgs?.map((message, index, arr) => (
                <Grid item>
                  <p className={message?.type === 'success' ? classes.success : classes.err}>{`${alertDetails.hasMultipleMsgs ? (index + 1 + ".") : ""}${message.msg}`}</p>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item>
            <IconButton onClick={alertClosed} className={classes.closeIcon}>
              <Clear style={{ fill: alertDetails?.msgs?.[0]?.type === 'success' ? 'black' : '#D40511' }} />
            </IconButton>
          </Grid>
        </Grid>
      </Drawer>
    </Grid>
  );
};

BottomAlert.propTypes = {};

export default BottomAlert;
