import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import './ReadOnlyCheckbox.scss';
const ReadOnlyCheckbox = ({ label, checked }) => {
  return (
    <div>
      <FormControlLabel
        disabled
        className="readOnlyCheckBox"
        control={<Checkbox checked={checked} name="checkedC" />}
        label={label}
      />
    </div>
  );
};

export default ReadOnlyCheckbox;
