import React from 'react';
import { connect } from 'react-redux';
import { AppBar, Tabs, Tab } from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';

import './AddressBook.scss';
import TabPanel from '../../components/shared/tabPanel';
import MyAddressBook from '../../components/MyAddressBook/MyAddressBook';
import SearchAddressBook from '../../components/addressBook/searchAddress/SearchAddressBook';
import { ServiceEndPoints } from '../../utils/constants/ApiConstant';
import { customTableSort, getCustomerAccountID } from '../../utils/helperFunctions';
import { withRouter } from 'react-router-dom';
import * as actions from '../../store/actions/index';
import { axiosConfig } from '../../api/axios';

// const tableRef = React.createRef();
class AddressBook extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      totalRecords: 0,
      isSearched: false,
      columns: [
        { title: 'id', field: 'id', hidden: true, width: 0 },
        {
          title: 'Customer no.',
          field: 'customerNumber',
          customSort: (a, b) => customTableSort(a.customerNumber, b.customerNumber),
          width: 200,
        },
        {
          title: 'Business/Recipient name',
          field: 'contactName',
          sorting: false,
          width: 210,
        },
        {
          title: 'Address',
          field: 'line1',
          sorting: false,
          width: 200,
        },
        {
          title: 'Town',
          field: 'town',
          sorting: false,
          width: 150,
        },
        {
          title: 'County',
          field: 'country',
          sorting: false,
          width: 150,
        },
        {
          title: 'Postal code',
          field: 'postCode',
          sorting: false,
          width: 100,
        },
        {
          title: 'Telephone no',
          field: 'telephone',
          sorting: false,
          width: 150,
        },
        {
          title: 'Email address',
          field: 'email',
          sorting: false,
          width: 200,
        },
        {
          title: 'What3Words',
          field: 'what3Words',
          sorting: false,
          width: 150,
        },
      ],
      rows: [],
      addressBook_pageDetails: {
        offset: 0,
        limit: 0,
        totalRecordsLength: 0,
      },
      addressBook_final_data: [],
      addressBook_totalCount: 0,
      favurites_pageDetails: {
        offset: 0,
        limit: 0,
        totalRecordsLength: 0,
      },
      favurites_final_data: [],
      favurites_totalCount: 0,
      pageNumber: 0,
      pageSize: 10,
      isLoading: false,
    };
    this.onAddressChange = this.onAddressChange.bind(this);
  }

  customerNumberClicked = (rowData) => {
    if (this.state.tabIndex === 1) {
      this.props.setActiveTab('addressbookFavourites');
    }
    this.props.history.push('/addressBook/editAddress?id=' + rowData.id);
  };

  onAddressChange(value) {
    this.setState({ isSearched: true, pageNumber: 0, pageSize: this.state.pageSize ? this.state.pageSize : 10 }, () => {
      this.getAddressBookDetails();
    });
  }

  deleteSelectedAddress = (addressIds) => {
    const itemsToBeDeleted = addressIds.map((address) => {
      return address.id;
    });
    const payload = itemsToBeDeleted;
    axiosConfig
      .post(`${ServiceEndPoints.postAddressBook}/delete`, payload)
      .then((response) => {
        this.props.setSnackBar({ msg: 'Address deleted successfully' });
        this.onAddressChange();
      })
      .catch((error) => {
        alert('Items were not deleted, please try again.');
      });
  };

  addFavouriteAddress = (addressIds) => {
    const { userId } = this.props.userDetails;
    const payload = addressIds.map((address) => {
      return address.id;
    });

    axiosConfig
      .post(`${ServiceEndPoints.postAddressBook}/favourite?userid=${userId}`, payload)
      .then((response) => {
        this.props.setSnackBar({ msg: 'Address added as favourite' });
        this.onAddressChange();
      })
      .catch((err) => {
        alert('Could not add items to favourites.');
      });
  };

  removeAddressFromFavourite = (addressIds) => {
    const { userId } = this.props.userDetails;
    const itemsToBeRemoved = addressIds.map((address) => {
      return address.id;
    });
    const payload = itemsToBeRemoved;
    axiosConfig
      .post(`${ServiceEndPoints.postAddressBook}/favourite/delete?userid=${userId}`, payload)
      .then((response) => {
        this.props.setSnackBar({ msg: 'Address removed from favourites' });
        this.onAddressChange();
      })
      .catch((error) => {
        alert('Items were not removed, please try again.');
      });
  };

  handleTabChange = (event, index) => {
    this.setState({ tabIndex: index, isSearched: true, pageNumber: 0, pageSize: 10 }, () => {
      this.getAddressBookDetails();
    });
  };

  refreshTableData = () => {
    this.setState({ pageNumber: 0, pageSize: this.state.pageSize ? this.state.pageSize : 10 }, () => {
      this.getAddressBookDetails();
    });
  }

  getAddressBookDetails() {
    this.setState({ isLoading: true });
    const isFavourites = this.state.tabIndex === 1;
    const offset = this.state.pageNumber * this.state.pageSize;
    const limit = this.state.pageSize;
    axiosConfig
      .get(this.getUrl(offset, limit))
      .then((res) => {
        this.setState({
          [isFavourites ? 'favurites_pageDetails' : 'addressBook_pageDetails']: {
            offset,
            limit,
            totalRecordsLength: (res.data || {}).totalRecords,
          },
          isSearched: false,
        });
        this.setState({ totalRecords: res.data?.totalRecords ? res.data?.totalRecords : 0 });
        this.formatAddressBookData(res.data, isFavourites);
      })
      .catch((err) => {
        console.log(err);
        this.formatAddressBookData([], isFavourites);
        this.setState({ totalRecords: 0 });
        this.setState({
          [isFavourites ? 'favurites_pageDetails' : 'addressBook_pageDetails']: {
            totalRecordsLength: 0,
          },
        });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  getUrl = (offset, limit) => {
    const { userId } = this.props.userDetails;
    let url = `${ServiceEndPoints.getAddressBook}?organization=${getCustomerAccountID()}`;
    url += '&offset=' + offset;
    url += '&limit=' + limit;
    url += this.props.addressBookSearchParams;
    if (this.state.tabIndex === 1) {
      url += '&userid=' + userId;
    }
    return url;
  };

  formatAddressBookData = (result, isFav = false) => {
    let final_data = [];
    ((result || {}).addressBooks || []).forEach((element, index) => {
      const customer = element?.deliveryAddress;
      final_data.push({
        id: element.id,
        customerNumber: customer?.customerNumber,
        contactName: customer?.recipientType == 1 ? customer?.businessName : customer?.recipientName,
        line1: customer?.line1,
        town: customer?.town,
        country: customer?.county,
        postCode: customer?.postCode,
        telephone: customer?.telephone,
        email: customer?.email,
        favouriteUsers: element?.favouriteUsers,
        what3Words:customer?.what3Words?.words,
      });
    });
    if (isFav) {
      this.setState({
        favurites_final_data: final_data,
        favurites_totalCount: result.totalRecords ? result.totalRecords : 0,
      });
    } else {
      this.setState({
        addressBook_final_data: final_data,
        addressBook_totalCount: result.totalRecords ? result.totalRecords : 0,
      });
    }
  };

  componentDidMount() {
    this.getAddressBookDetails();
    if (this.props.activeTab === 'addressbookFavourites') {
      this.setState({ tabIndex: 1 ,pageNumber: 0, pageSize: this.state.pageSize ? this.state.pageSize : 10 }, () => {
        this.getAddressBookDetails();
      });
    }
    this.props.setActiveTab('');
  }

  onPageNumChange(pageNum) {
    this.setState({ pageNumber: pageNum }, () => {
      this.getAddressBookDetails();
    });
  }

  onPageSizeChange(pageSize) {
    this.setState({ pageSize: pageSize }, () => {
      this.getAddressBookDetails();
    });
  }

  render() {
    return (
      <Grid container className="AddressBook-Container">
        <AppBar position="static" className="AddressBook-AppBar">
          <Grid container spacing={3} direction="row">
            <Grid item xs={12} sm={5} lg={5}>
              <Tabs
                value={this.state.tabIndex}
                onChange={this.handleTabChange}
                className={`${this.state.tabIndex === 0 ? 'My-address-book-Tab-panel' : 'Favourites-Tab-panel'}`}
              >
                <Tab label="My address book" className="Tab-panel" />
                <Tab label="Favourites" className="Tab-panel" />
              </Tabs>
            </Grid>
            <Grid
              item
              xs={12}
              sm={7}
              lg={7}
              className={`${this.state.tabIndex === 0 ? 'AddressBook-Search-control' : 'Favourites-Search-control'}`}
            >
              <SearchAddressBook
                className="AddressBook-SearchBar"
                tabIndex={this.state.tabIndex}
                onAddressChange={this.onAddressChange}
                totalRecords={this.state.totalRecords}
                refreshData={this.refreshTableData}
              />
            </Grid>
          </Grid>
        </AppBar>
        <>
          <TabPanel className="My-Address-Book-Tab" value={this.state.tabIndex} index={0} style={{ width: '100%' }}>
            <MyAddressBook
              rows={this.state.addressBook_final_data}
              columns={this.state.columns}
              deleteSelectedAddress={this.deleteSelectedAddress}
              addFavouriteAddress={this.addFavouriteAddress}
              removeAddressFromFavourite={this.removeAddressFromFavourite}
              empty_DataSource_Message="Customer not found"
              isFavourites={false}
              title="Delete address"
              confirmationMessage="Are you sure you want to delete the selected address book entries?"
              userDetails={this.props.userDetails}
              onRowClicked={this.customerNumberClicked}
              tableLayout="fixed"
              pageSize={this.state.pageSize}
              totalCount={this.state.addressBook_pageDetails.totalRecordsLength}
              count={this.state.addressBook_pageDetails.totalRecordsLength}
              isLoading={this.state.isLoading}
              inBuiltEvents={{
                onChangeRowsPerPage: (e) => this.onPageSizeChange(e),
                onChangePage: (a) => this.onPageNumChange(a),
              }}
              page={this.state.pageNumber}
              isSearched={this.state.isSearched}
            />
          </TabPanel>
          <TabPanel className="Favourites-Tab" value={this.state.tabIndex} index={1} style={{ width: '100%' }}>
            <MyAddressBook
              rows={this.state.favurites_final_data}
              columns={this.state.columns}
              deleteSelectedAddress={this.deleteSelectedAddress}
              addFavouriteAddress={this.addFavouriteAddress}
              removeAddressFromFavourite={this.removeAddressFromFavourite}
              empty_DataSource_Message="Favourite not found"
              isFavourites={true}
              title="Remove address"
              confirmationMessage="Are you sure you want to remove the selected favourites?"
              userDetails={this.props.userDetails}
              onRowClicked={this.customerNumberClicked}
              pageSize={this.state.pageSize}
              totalCount={this.state.favurites_pageDetails.totalRecordsLength}
              count={this.state.favurites_pageDetails.totalRecordsLength}
              isLoading={this.state.isLoading}
              inBuiltEvents={{
                onChangeRowsPerPage: (e) => this.onPageSizeChange(e),
                onChangePage: (a) => this.onPageNumChange(a),
              }}
              page={this.state.pageNumber}
            />
          </TabPanel>
        </>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.auth.userDetails,
    addressBookSearchParams: state.searchParams,
    activeTab: state.common.activeTab,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoad: (loadedFrom) => {
      dispatch(actions.setLoadedFrom(loadedFrom));
    },
    setSnackBar: (data) => {
      dispatch(actions.setAppSnackBarData(data));
    },
    setActiveTab: (value) => {
      dispatch(actions.setActiveTab(value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddressBook));
