/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import FormRenderer from '../../../../../framework/Inputs/formRenderer/formRenderer';
import { useSelector, useDispatch } from 'react-redux';

const OrderFileDefaultsAdditionalInfo = (props) => {
//   const dispatch = useDispatch();
  const {
    formField: {
      extendedCoverDomestic,
      extendedCoverInternational,
    },
    setFieldValue,
    isDomestic,
    // loadedFrom
  } = props;


  const selectedAccount = useSelector((state) => state.accounts.selectedAccount);

  // useEffect(() => {
  //   setTimeout(() => {
  //       setFieldValue('extendedCoverDomestic', 0);
  //       setFieldValue('extendedCoverInternational', false);
  //     }, 0);
  // }, [selectedAccount])

  return (
    <Grid container className="additioninfo-main-grid">
      <Grid container direction="row" alignItems="flex-start" spacing={6}>
          <>
              <Grid item xs={12} sm={6} md={6} lg={6} spacing={6} style={{ paddingLeft: '25px' }}>
                {isDomestic && selectedAccount.configuration.restrictExtendedLiability !== true ? (
                  <FormRenderer {...extendedCoverDomestic.props} restrictDecimal={true} fullWidth />
                ) : (
                  selectedAccount.configuration.restrictExtendedLiability !== true ? <FormRenderer {...extendedCoverInternational.props} fullWidth /> : " "
                )}
              </Grid>
          </> 
      </Grid>
    </Grid>
  );
};

OrderFileDefaultsAdditionalInfo.propTypes = {
  formField: PropTypes.object,
  isDomestic: PropTypes.bool,
};

export default OrderFileDefaultsAdditionalInfo;
