import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Container from '@material-ui/core/Container';
import { uniq, sortBy, sort } from 'lodash';
import FaqSideNav from './faqSideNav';
import FaqContainer from './faqContainer';
import './faq.scss';
import InputButton from '../Inputs/inputButton/inputButton';
import { Grid } from '@material-ui/core';
import CustomModal from '../modal/modal';
import CustomDialog from '../dialog/customDialog';
import CreateNewFaq from './CreateNewFaq';
import { GET, POST } from '../../api/axios';
import ConfirmationDialog from '../../components/addressBook/confirmation/confirmation';
import { UserRoles } from '../../utils/constants/constants';
import { useSelector } from 'react-redux';
import { ServiceEndPoints } from '../../utils/constants/ApiConstant';
import { removeDuplicates, setValuesToForm } from '../../utils/helperFunctions';
const Faq = () => {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const userRole = useSelector((state) => state?.auth?.userDetails?.role);
  // const userRole = UserRoles.Admin;
  const [uniqueLeftPanelData, setUniqueLeftPanelData] = useState([]);
  const [faqDetails, setFaqDetails] = useState([]);
  const [showCheckboxes, setShowCheckBoxes] = useState(false);
  const [isChecked, setIsChecked] = useState([]);
  const [defaultExpanded, setDefaultExpanded] = useState(false);
  const [categoryType, setCategoryType] = useState('');
  const [editDetails, setEditDetails] = useState({});
  const [editFaqID, setEditFaqID] = useState('');

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [confirmationMessage, setConfirmationMsg] = useState('Are you sure you want to delete these questions?');
  const [catagoryValues, setCatagoryValues] = useState([]);

  const [uniqSideNav, setUniqSideNav] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState('');
  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setIsUpdate(!isUpdate);
  };

  const handleModalClose = () => {
    setShowConfirmationDialog(false);
  };
  const onDialogConfirm = () => {
    setShowConfirmationDialog(false);
    onDelete();
  };
  const onDialogCancel = () => {
    setShowConfirmationDialog(false);
  };

  const addFAQ = (e, name) => {
    setCategoryType(name);

    setIsDialogOpen(true);
  };
  const getAllFaqDetails = () => {
    const categoryArray = []
    GET(ServiceEndPoints.faqsUrl)
      .then((res) => {

        (res.data || []).forEach((item, key) => {
          let categoryObj = {}
          if (item.category !== null && item.category != '') {
            categoryObj["id"] = key;
            categoryObj["name"] = item.category;
            categoryObj["value"] = item.category.replace(/\s+/g, '');
            // console.log("category",categoryObj)
            // categoryArray((prevArray) => [...prevArray, categoryObj]);
            categoryArray.push(categoryObj)


          }
        });
        setUniqueLeftPanelData(categoryArray)

      })
      .catch((err) => {
        console.log(err);
      });
    // setUniqSideNav(arrayList);
  };
  const getCheckBox = () => {
    return isChecked.length === 0
  };

  const getFaqDetails = (name) => {
    GET(`${ServiceEndPoints.faqsUrl}?category=${name}`)
      .then((res) => {
        setFaqDetails(res.data);
      })
      .catch((err) => {
        console.log(err);
        // let afterDeleteData = catagoryValues.filter((item) => {
        //   // return item !== name;
        // });
        getAllFaqDetails();
        // getFaqDetails(catagoryValues[0].name);
        setSelectedMenu(catagoryValues[0].name)

        // setUniqueLeftPanelData(afterDeleteData);
        setFaqDetails([]);
      });
  };

  useEffect(() => {
    getAllFaqDetails();
  }, []);
  useEffect(() => {
    let data = removeDuplicates(uniqueLeftPanelData, item => item.name)

    const sortedDate = data.sort(function (a, b) {
      /* Storing case insensitive comparison */
      var comparison = a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      /* If strings are equal in case insensitive comparison */
      if (comparison === 0) {
        /* Return case sensitive comparison instead */
        return a.name.localeCompare(b.name);
      }
      /* Otherwise return result */
      return comparison;
    });
    setCatagoryValues(sortedDate);
    if (uniqueLeftPanelData.length > 0 && selectedMenu === '') {
      setSelectedMenu(sortedDate[0].name)
    }

  }, [uniqueLeftPanelData, isUpdate]);

  useEffect(() => {
    setIsChecked([]);
    setShowCheckBoxes(false);
    setDefaultExpanded(false);
    if (selectedMenu != '') {
      getFaqDetails(selectedMenu);
    }
  }, [selectedMenu]);

  //delete faq items
  const onDelete = () => {
    const itemsToBeDeleted = isChecked.map((faqItem) => {
      return faqItem;
    });

    const payload = itemsToBeDeleted;
    POST(`${ServiceEndPoints.faqsUrl}/delete`, payload)
      .then((response) => {
        getAllFaqDetails()
        // console.log("selectedMenu",selectedMenu)
        getFaqDetails(selectedMenu);
        setIsChecked([])
      })
      .catch((error) => {
        alert('Items were not deleted, please try again.');
      });
  };

  const handleSingleCheck = (event) => {
    let checkBoxlist = [...isChecked];

    checkBoxlist.push(event);

    checkBoxlist = checkBoxlist.reduce((a, b) => {
      if (a.indexOf(b) < 0) {
        a.push(b);
      }
      return a;
    }, []);

    setIsChecked(checkBoxlist);
  };
  const handleRemove = (event) => {
    let checkBoxlist = [...isChecked];

    checkBoxlist.push(event);

    checkBoxlist = checkBoxlist.filter((name) => name != event);

    setIsChecked(checkBoxlist);
  };

  const addAndEditFaq = (e) => {
    addFAQ(e, 'dropDown');
    const itemsToBeUpdated = isChecked.map((faqItem) => {
      setEditFaqID(faqItem);
      return faqItem;
    });
    GET(`${ServiceEndPoints.faqsUrl}?id=${itemsToBeUpdated}`)
      .then((res) => {
        setEditDetails(res.data);
        //  getAllFaqDetails()
        // getFaqDetails(selectedMenu);

      })
      .catch((err) => {
        console.log('err');
      });
  };


  // function removeDuplicates(data, key) {

  //   return [
  //     ...new Map(data.map(item => [key(item), item])).values()
  //   ]

  // };


  return (
    <div className="wd-100">
      <Grid container direction="row" justify="flex-end" alignItems="flex-start" spacing={3}>
        <Grid item xs={12} sm={4} lg={3} md={3} className="faq-left-panel">
          {/* <CssBaseline /> */}
          <Drawer
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <FaqSideNav
              addFAQ={addFAQ}
              setSelectedMenu={setSelectedMenu}
              selectedMenu={selectedMenu === '' ? catagoryValues.length != 0 ? catagoryValues[0].name : ""
                : selectedMenu}
              showCheckboxes={showCheckboxes}
              catagoryValues={catagoryValues}
              userRole={userRole === UserRoles.Admin}
              isUpdate={isUpdate}
              setIsUpdate={setIsUpdate}
            />
          </Drawer>
        </Grid>

        <Grid item xs={12} sm={8} lg={9} md={9} className="faq-right-panel">
          <Container maxWidth="lg" className={classes.container}>
            {(faqDetails || []).map((content, key) => {
              return (
                <FaqContainer
                  key={key}
                  question={content.question}
                  answer={content.answer}
                  index={content.id}
                  isChecked={isChecked}
                  setIsChecked={handleSingleCheck}
                  setIsUnChecked={handleRemove}
                  setShowCheckBoxes={setShowCheckBoxes}
                  showCheckboxes={showCheckboxes}
                  defaultExpanded={defaultExpanded}
                  userRole={userRole === UserRoles.Admin}

                />
              );
            })}
          </Container>
          <Grid container direction="row" justify="flex-end" spacing={3}>
            <Grid item>
              {userRole === UserRoles.Admin ? (
                <InputButton
                  className="outlined-btn Delete-AddressBook"
                  label="Add/edit FAQ"
                  disabled={isChecked.length > 1}
                  onClick={(e) => addAndEditFaq(e)}
                />
              ) : null}
            </Grid>
            <Grid item>
              {showCheckboxes && userRole === UserRoles.Admin ? (
                <InputButton
                  className="contained-btn Delete-AddressBook"
                  label="Delete"
                  onClick={() => setShowConfirmationDialog(true)}
                  disabled={isChecked.length === 0}
                />
              ) : null}
              <CustomModal
                open={showConfirmationDialog}
                title="delete a faq"
                onClose={handleModalClose}
                maxWidth="sm"
              >
                <ConfirmationDialog
                  confirmationMessage={confirmationMessage}
                  onConfirm={onDialogConfirm}
                  OnCancel={onDialogCancel}
                />
              </CustomModal>
            </Grid>
          </Grid>

        </Grid>
        <CustomDialog
          open={isDialogOpen}
          title={categoryType === 'textBox' ? 'Add category & FAQ' : 'Add/edit FAQ'}
          onClose={handleDialogClose}
          maxWidth="sm"
        >
          <CreateNewFaq
            handleDialogClose={handleDialogClose}
            getAllFaqDetails={getAllFaqDetails}
            categoryType={categoryType}
            editDetails={editDetails}
            setEditDetails={setEditDetails}
            editFaqID={editFaqID}
            catagoryValues={catagoryValues}
            selectedMenu={selectedMenu}


            getCheckBox={getCheckBox}
            getFaqDetails={getFaqDetails}

            setIsDialogOpen={setIsDialogOpen}
            setIsChecked={setIsChecked}
            setShowCheckBoxes={setShowCheckBoxes}
          />
        </CustomDialog>
      </Grid>
    </div>
  );
};

const drawerWidth = 268;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
    // paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    background: '#fff',
    color: '#000',
    border: '1px solid #E3E3E3',
    textAlign: 'left',
  },
  content: {
    flexGrow: 1,
    height: '100%',
    overflow: 'auto',
  },
  container: {
    // paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

export default Faq;
