import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, SvgIcon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { ReactComponent as AirplainIcon } from '../../../assets/images/AirplainIcon.svg';
import { ReactComponent as VenIcon } from '../../../assets/images/VenIcon.svg';
import './InternationalProducts.scss';
import { connect } from 'react-redux';
import { setDropdownValues } from '../../../utils/helperFunctions';

const InternationalProduct = (props) => {
  const { loadedFrom, productList, setFieldValue, formikValues } = props;
  const StyledToggleButtonGroup = withStyles((theme) => ({
    root: {
      width: '100%',
      height: '52px',
      padding: '2px 7px',
      margin: '4px 0px',
    },
    grouped: {
      width: '50%',
      '&:first-child': {
        marginRight: '4%',
      },
      // '&:last-child': {
      //   border: '1px solid rgba(0, 0, 0, 0.12)',
      //   color:'white'
      // },
    },
  }))(ToggleButtonGroup);

  const StyledToggleButton = withStyles((theme) => ({
    selected: {
      backgroundColor: '#76BD22 !important',
    },
  }))(ToggleButton);

  const [selectedProduct, setSelectedProduct] = useState('');
  const [availableProductList, setAvailableProductList] = useState([]);

  useEffect(() => {
    let updatedProductList = [];
    let finalList =[];
    for(let i=0;i<productList.length;i++)
    {
        if(formikValues.applyIossNumber === 'false' && productList[i]?.dutiesPaidList?.includes(formikValues?.TermsOfDelivery?.name))
        {
          updatedProductList.push(productList[i]);
        }
        else if(formikValues.applyIossNumber === 'true' && productList[i]?.deliveryCapabilities?.iossDutiesPaid=== formikValues?.TermsOfDelivery?.name){
          updatedProductList.push(productList[i]);
        }
    }
    
    if(updatedProductList.length>1){
      let finalAirProduct = updatedProductList.find((ele) => ele.deliveryCapabilities.accountType === 'internationalAir');
      finalList.push(updatedProductList[0]);

      if(finalAirProduct){
        finalList.push(finalAirProduct);
      }
      setAvailableProductList(finalList);
    }
    else{
      setAvailableProductList(updatedProductList);
    }
  }, [productList, formikValues?.TermsOfDelivery, formikValues?.applyIossNumber]);

  useEffect(() => {
    if (formikValues?.accountNumber?.allowedProducts?.[0] === "internationalAir" && availableProductList?.[1]) {
      setSelectedProduct(availableProductList[1]);
      setFieldValue('product', availableProductList[1]);
    }
    else if (availableProductList?.[0]) {
      setSelectedProduct(availableProductList[0]);
      setFieldValue('product', availableProductList[0]);
    }
  }, [availableProductList, formikValues?.applyIossNumber, formikValues?.accountNumber]);


  useEffect(() => {
    const { displayAccounts } = props;
    const { accountNumber } = formikValues;
    if (
      selectedProduct !== '' &&
      selectedProduct?.deliveryCapabilities &&
      !accountNumber?.allowedProducts?.includes(selectedProduct?.deliveryCapabilities?.accountType)
    ) {
      let selectedAccount = displayAccounts?.find((acct) =>
        acct?.allowedProducts?.includes(selectedProduct?.deliveryCapabilities?.accountType) &&
        (formikValues.applyIossNumber === Boolean(acct.configuration?.iossDHLAccountAllowed || acct.customsRegistration?.iossNumber).toString()),
      );
      if (!selectedAccount) {
        selectedAccount = displayAccounts?.find((acct) =>
          acct?.allowedProducts?.includes(selectedProduct?.deliveryCapabilities?.accountType)
        );
      }
      setFieldValue('accountNumber', selectedAccount);
    }
  }, [selectedProduct]);

  useEffect(() => {
    const loadedFromScreen = loadedFrom.loadedFrom;
    const isFromDropDown = loadedFromScreen === 'dropdownResponse';
    const loadedResponse = loadedFrom.loadedResponse;
    if (
      (availableProductList || []).length > 0 &&
      (loadedFromScreen === 'dropdownResponse' || loadedFromScreen === 'fromPreviewSummary') &&
      ((loadedFrom || {}).loadedResponse || {}).product
    ) {
      const selectedProduct = setDropdownValues(
        availableProductList,
        'productCode',
        isFromDropDown ? loadedResponse.product : loadedResponse.product?.productCode,
      );
      setFieldValue('product', selectedProduct);
      setSelectedProduct(selectedProduct);
    }
    else if ((availableProductList || []).length === 0) {
      const selectedProduct = setDropdownValues(
        availableProductList,
        'productCode',
        '',
      );

      setFieldValue('product', selectedProduct);
      setSelectedProduct(selectedProduct);
    }
  }, [availableProductList, loadedFrom]);

  const handleChange = (event, value) => {
    if (value !== null) {
      setSelectedProduct(value);
      props.setFieldValue('product', value);
    }
  };
  const setInternationalProds = () => {
    // const { productList } = props;
    if (availableProductList.length === 0) {

      return (<><div>Products not available for current selection</div>
        <span style={{
          color: '#D40511',
          fontSize: '16px',
          paddingLeft: '29px;'
        }}>Product is required</span>
      </>
      );
    } else {
      return (
        <Grid className="internationalProduct-togglebutton" container spacing={2}>
          <StyledToggleButtonGroup value={selectedProduct} exclusive onChange={handleChange}>
            {availableProductList.map((prod) => {
              if (prod.deliveryCapabilities.accountType === 'internationalAir') {
                return (
                  <StyledToggleButton value={prod} aria-label={prod}>
                    <Grid style={{ marginTop: '15px' }}>
                      <SvgIcon component={AirplainIcon} viewBox="0 0 48 48" style={{ fontSize: '3rem' }} />
                    </Grid>
                    {prod?.deliveryCapabilities?.transitTimeDescription === "null" || prod?.deliveryCapabilities?.transitTimeDescription === null || prod?.deliveryCapabilities?.transitTimeDescription === undefined ?
                      <Grid style={{ textTransform: 'none' }}>
                        {`${prod.productName}`}
                      </Grid>
                      :
                      <Grid style={{ textTransform: 'none' }}>
                        {`${prod.productName} (${prod?.deliveryCapabilities?.transitTimeDescription})`}
                      </Grid>
                    }
                  </StyledToggleButton>
                );
              } else {
                return (
                  <StyledToggleButton value={prod} aria-label={prod}>
                    <Grid style={{ marginTop: '15px' }}>
                      <SvgIcon component={VenIcon} viewBox="0 0 48 48" style={{ fontSize: '3rem' }} />
                    </Grid>
                    {prod?.deliveryCapabilities?.transitTimeDescription === "null" || prod?.deliveryCapabilities?.transitTimeDescription === null || prod?.deliveryCapabilities?.transitTimeDescription === undefined ?
                      <Grid style={{ textTransform: 'none' }}>
                        {`${prod.productName}`}
                      </Grid>
                      :
                      <Grid style={{ textTransform: 'none' }}>
                        {`${prod.productName} (${prod?.deliveryCapabilities?.transitTimeDescription})`}
                      </Grid>
                    }
                  </StyledToggleButton>
                );
              }
            })}
          </StyledToggleButtonGroup>
        </Grid>
      );
    }
  };
  return setInternationalProds();
};

InternationalProduct.propTypes = {
  productList: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  return {
    loadedFrom: state.loadedFrom.loadedFrom,
    displayAccounts: state.accounts.displayAccounts,
  };
};

export default connect(mapStateToProps, null)(InternationalProduct);
