export const ShipmentRequestModel = () => {
  return {
    pickupAccount: '',
    dropOffType: '',
    shipmentId: '',
    documentCreatedOn: '',
    pickup: null,
    senderAddress: {},
    shipments: [],
    correlationId: '',
  };
};

export const ShipmentModel = () => {
  return {
    consigneeAddress: consigneeAddress(),
    shipmentDetails: shipmentDetails(),
    pieces: [],
  };
};

export const consigneeAddress = () => {
  return {
    recipientType: '',
    addressType: '',
    companyName: '',
    address1: '',
    address2: '',
    address3: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    name: '',
    email: '',
    phone: '',
    locationId: '',
    locationType: '',
    idNumber: '',
    idType: '',
    what3Words: {},
  };
};

export const shipmentDetails = () => {
  return {
    shipmentId: '',
    customerRef1: '',
    customerRef2: '',
    orderedProduct: '',
    totalPieces: '',
    totalWeight: '',
    inBoxReturn: false,
    shipmentDesc: '',
    dutiesPaid: '',
    freightCharges: 0,
    currency: '',
    deliveryChoice: '',
    deliveryInstructions: '',
    secureLocation: '',
    partnerCode: '',
    totalPallets: '',
    exchangeOnDelivery: false,
    carriageForward: false,
    directInjectionCustomerId: '',
  };
};

export const recipientAddress = () => {
  return {
    companyName: '',
    address1: '',
    address2: '',
    address3: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    name: '',
    email: '',
    phone: '',
  };
};
