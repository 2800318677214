import { Grid, Snackbar } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { useRef, useEffect, useState } from 'react';
import { axiosConfig, POST } from '../../api/axios';
import { ServiceEndPoints } from '../../utils/constants/ApiConstant';
import { connect } from 'react-redux';

import { FaqValidatorForm } from '../../utils/formConfig/FaqValidatorForm/FaqValidatorForm';
import { addFAQReqObj, setValuesToForm } from '../../utils/helperFunctions';
import { getYupSchemaFromMetaData } from '../../utils/yupSchema/yupSchemaGenerator';
// import { getYupSchemaFromMetaData } from '../../utils/yupSchema/yupSchemaGenerator';
import * as actions from '../../store/actions/index';

import InputButton from '../Inputs/inputButton/inputButton';
import FaqForm from './FaqForm';
const { formId, formField } = FaqValidatorForm;
const CreateNewFaq = (props) => {
  const {catagoryValues, selectedMenu} = props;
  const { onLoad } = props;
 
  const formikRef = useRef(null);
  const [snackBarMsg, setSnackBarMsg] = useState('');
  const [editQuestion, setEditQuestion] = useState('');
  const [editAnswer, setEditAnswer] = useState('');
  const [newCategory, setNewCategory] = useState(false);
  const [categoryID, setCategoryID] = useState('');

  const newFaqValidationSchema = getYupSchemaFromMetaData(formField, [], []);
  const initialValues = {
    catagory: '',
    addQuestion: '',
    addAnswer: '',
    //  faqCatagory:'',
  };
  const handleSubmit = async (values, errors, categoryType, id) => {

    if (await isFormValid(errors, values, categoryType) && !newCategory) {
      const reqObj = addFAQReqObj(values, categoryType, id);
      axiosConfig
        .post(ServiceEndPoints.faqsUrl, reqObj)
        .then((res) => {
          // setSnackBarMsg('Customer details stored sucessfully');
          formikRef.current.resetForm();
          props.handleDialogClose();
          props.getAllFaqDetails();
          props.setEditDetails('')
          props.setIsChecked([])
          props.setShowCheckBoxes(false)
          props.getFaqDetails(selectedMenu)
        })
        .catch((err) => {
          console.log(err);
          // setSnackBarMsg('Error!');
        });
    } else {
     // console.log('all fields are required');
      props.setIsDialogOpen(true)
    }
  };

  
  const isFormValid = async (errors, values, categoryType) => {

    // let clonedReqFields = [...reqFields];

    const { ...restControls } = errors;
  
    if (categoryType === 'textBox') {
      
        return Object.keys(restControls).length === 0;
      
    } else if (categoryType === 'dropDown') {

 
        return (errors.addQuestion === undefined && errors.addAnswer === undefined)
      

    }
   

    return true;
  };
useEffect(() => {
 
  if (selectedMenu != '' && props.getCheckBox()) {
 catagoryValues.map((category, index) => {

    if (category.value === selectedMenu.replace(/\s+/g, '')) {

 
            // setFieldValue('defaultDomesticAccounts', _tempArray[0])
             formikRef.current.setFieldValue('faqCatagory', catagoryValues[index]);
    
             setCategoryID('')
  
    }
        }) 
}
}, [selectedMenu])


  useEffect(() => {
    if (Object.keys(props.editDetails).length > 0 && !props.getCheckBox() ) {
      
    
      catagoryValues.map((category, index) => {

    if (category.value === props.editDetails[0].category.replace(/\s+/g, '')) {
     
 
            // setFieldValue('defaultDomesticAccounts', _tempArray[0])
             formikRef.current.setFieldValue('faqCatagory', catagoryValues[index]);
    
             setCategoryID(props.editFaqID)

  
    }
        })

     formikRef.current.setFieldValue('addQuestion', props.editDetails[0].question);
     formikRef.current.setFieldValue('addAnswer', props.editDetails[0].answer);
    setEditAnswer(props.editDetails[0].answer)
    setEditQuestion(props.editDetails[0].question)
   

      // formikRef.current.setValues(setValuesToForm(props.editDetails, formikRef.current.values));
      onLoad({
        loadedFrom: 'dropdownResponse',
        loadedResponse: { ...props.editDetails },
      });
      setTimeout(() => {
        onLoad({ loadedFrom: '' });
      }, 5000);
    }
  }, [props.editDetails]);

 
  // const htmlRef=useRef(null)
  // console.log("catagoryValues",catagoryValues)

  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={newFaqValidationSchema}
        innerRef={formikRef}
        validate={(values) => {
          const errors = {};
          if (!values.addQuestion) {
            errors.addQuestion = 'Question is Required';
          }

          if (!values.addAnswer) {
            errors.addAnswer = 'Answer is Required';
          }

          return errors;
        }}
      >
        {({ values, setFieldValue, errors, setFieldTouched, resetForm, setValues}) => (
          <Form className="faqForm" id={formId}>
             <FaqForm
              formField={formField}
              formikValues={values}
              // setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              errors={errors}
              catagoryValues={catagoryValues}
              categoryType={props.categoryType}
              setValues={setValues}
              editAnswer={editAnswer}
              editQuestion={editQuestion}

              setNewCategory={setNewCategory}
            /> 
            <Grid container justify="flex-end" alignItems="flex-end" >
              <Grid item>
                <InputButton style={{ marginTop: '10px' }}
                  // disabled={props.uniqueLeftPanelData.includes(values.catagory)}
                  variant="contained"
                  className="contained-btn"
                  onClick={() => {
                    handleSubmit(values, errors, props.categoryType, categoryID);
                  }}
                  buttonType="submit"
                  label="save"
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <Snackbar
        id="MuiAlert-snackbar-message"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={snackBarMsg !== ''}
        onClose={() => setSnackBarMsg('')}
        autoHideDuration={2000}
        message={snackBarMsg}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoad: (loadedFrom) => {
      dispatch(actions.setLoadedFrom(loadedFrom));
    },
  };
};

export default connect(null, mapDispatchToProps)(CreateNewFaq);
