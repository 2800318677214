import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import './customAccordion.scss';

const CustomAccordion = ({ heading, defaultExpanded, children }) => {
  return (
    <Grid className="accordion-Container">
      <Accordion className="custom-accordion" defaultExpanded={defaultExpanded}>
        <AccordionSummary
          className="expandedPanel"
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {/* <Typography className="heading">{heading}</Typography> */}
          {heading}
        </AccordionSummary>
        <AccordionDetails className="content">{children}</AccordionDetails>
      </Accordion>
    </Grid>
  );
};

CustomAccordion.propTypes = {
  heading: PropTypes.string.isRequired,
  content: PropTypes.any,
  defaultExpanded: PropTypes.bool,
  children: PropTypes.object,
};
CustomAccordion.defaultProps = {
  defaultExpanded: true,
};

export default CustomAccordion;
