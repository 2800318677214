import * as actionTypes from './actionsTypes';

export const setSelectedAccount = (selectedAccount) => {
  return {
    type: actionTypes.SETSELECTEDACCOUNT,
    data: selectedAccount,
  };
};

export const setUserAccounts = (userAccounts) => {
  return {
    type: actionTypes.SETUSERACCOUNTS,
    data: userAccounts,
  };
};

export const setNonReturnUserAccounts = (userReturnAccounts) => {
  return {
    type: actionTypes.SETNONRETURNUSERACCOUNTS,
    data: userReturnAccounts,
  };
};

export const setDisplayAccount = (accounts) => {
  return {
    type: actionTypes.SETDISPLAYACCOUNTS,
    data: accounts
  }
}