import * as JSPM_V3 from "jsprintmanager";
import * as JSPM_V4 from 'jsprintmanager-4';
import { GET } from '../api/axios';
import { ServiceEndPoints } from './constants/ApiConstant';
import { Base64 } from 'js-base64';
import { getCustomerAccountID } from './helperFunctions';

let JSPM, license_url;

export const updateJSPMReference = (jspmVersion) => {
  console.log("jspmVersion::: ", jspmVersion)
  if (jspmVersion === "3") {
    console.log("inside 3")
    JSPM = JSPM_V3;
    license_url = ServiceEndPoints.getJSPMLicense_v3;
  } else {
    console.log("inside 4")
    JSPM = JSPM_V4;
    license_url = ServiceEndPoints.getJSPMLicense_v4;
  }
};

export const getJSPMStatus = (dispatch, actions) => {
  if (!JSPM) {
    JSPM = JSPM_V3;
    license_url = ServiceEndPoints.getJSPMLicense_v3;
  }
  console.log("JSPM", JSPM)
  console.log("license_url", license_url)
  JSPM.JSPrintManager.license_url = license_url;
  JSPM.JSPrintManager.auto_reconnect = true;
  JSPM.JSPrintManager.start();

  JSPM.JSPrintManager.WS.onStatusChanged = function () {
    const status = JSPM.JSPrintManager.WS.status === 0;
    dispatch(actions.setJSPMStatus(status));
    getAvaiablePrinters()
      .then((printerList) => {
        dispatch(actions.setAvailablePrinter(printerList));
      })
      .catch((err) => {
        dispatch(actions.setAvailablePrinter([]));
      });
  };

  JSPM.JSPrintManager.WS.onClose = function () {
    const status = JSPM.JSPrintManager.WS.status === 0;
    dispatch(actions.setJSPMStatus(status));
  };
};

export const printZplLabels = (printData, selectedPrinter) => {
  var cpj = new JSPM.ClientPrintJob();
  cpj.clientPrinter = selectedPrinter ? new JSPM.InstalledPrinter(selectedPrinter) : 0;
  cpj.printerCommands = Base64.decode(printData);
  cpj.printerCommandsCodePage = -1;
  cpj.sendToClient();
};

export const printPDF = (printData, selectedPrinter, type) => {
  let cpj = new JSPM.ClientPrintJob();
  cpj.clientPrinter = selectedPrinter ? new JSPM.InstalledPrinter(selectedPrinter) : 0;
  if (printData) {
    let myPdfFile;
    myPdfFile = new JSPM.PrintFilePDF(printData, JSPM.FileSourceType.Base64, `${type}.pdf`, 1);
    myPdfFile.pageSizing = JSPM.Sizing.None;
    myPdfFile.printRotation = JSPM.PrintRotation.Rot180
    cpj.files.push(myPdfFile);
  }
  cpj.sendToClient();
};

export const downloadPDF = (base64, lableName, shipmentData, index) => {
  if (base64) {
    let shipmentid
    let blob = convertBase64toBlob(base64, 'application/pdf');
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    if (shipmentData?.labelResponse && shipmentData?.labelResponse.length > 0) {
      if (index !== undefined) {
        shipmentid = shipmentData.labelResponse[index]?.shipmentId ?? index + 1;
      }
      else {
        shipmentData?.labelResponse.map((id) => {
          shipmentid = id.shipmentId
        })
      }
    }
    else if (shipmentData?.customsInvoiceResponse && shipmentData?.customsInvoiceResponse.length > 0) {
      if (index !== undefined) {
        shipmentid = shipmentData.customsInvoiceResponse[index]?.shipmentId ?? index + 1;
      }
      else {
        shipmentid = shipmentData?.customsInvoiceResponse[0]?.shipmentId
      }
    }
    else if (shipmentData.length > 0) {
      shipmentid = shipmentData[0]?.labelResponse[0]?.shipmentId ?? shipmentData[0]?.customsInvoiceResponse[0]?.shipmentId;
    }
    else if (shipmentData?.shipmentId) {
      shipmentid = shipmentData.shipmentId
    }
    else if (shipmentData?.shipments[0].shipmentId) {
      shipmentid = shipmentData?.shipments[0].shipmentId
    }
    else {
      shipmentid = ""
    }
    link.href = blobUrl;
    link.download = shipmentid + lableName;
    document.body.appendChild(link);
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: false,
        cancelable: true,
        view: window
      })
    );
  }
}

const convertBase64toBlob = (content, contentType) => {
  contentType = contentType || '';
  var sliceSize = 512;
  var byteCharacters = window.atob(content);
  var byteArrays = [
  ];
  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);
    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  var blob = new Blob(byteArrays, {
    type: contentType
  });
  return blob;
}


export const getAvaiablePrinters = () => {
  return JSPM?.JSPrintManager.getPrinters()
  // || new Promise((resolve,reject)=> {
  //  reject([]);
  // });
};

export const getPrintConfiguration = (dispacth, actions, userDetails) => {
  if (userDetails) {
    GET(`${ServiceEndPoints.printConfiguration}?userId=${userDetails.emailId}`)
      .then((response) => {
        dispacth(actions.setPrinterConfiguration(response.data));
      }).catch((err) => {
        dispacth(actions.setPrinterConfiguration({ userId: userDetails.emailId }));
      });
    GET(`${ServiceEndPoints.getOrderDefaults}?organization=${getCustomerAccountID()}`).then(res => {
      dispacth(actions.setPrinterConfiguration({
        "printerQueueDefault": res.data.printerQueueDefaults?.printerQueueDefault,
        "printerQueue": res.data.printerQueueDefaults?.printerQueue
      }))
    })
  }
};
