import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import InputSelect from '../../../../framework/Inputs/inputSelect/inputSelect';
import { useSelector } from 'react-redux';
import { getYupSchemaFromMetaData } from '../../../../utils/yupSchema/yupSchemaGenerator';
import FormRenderer from '../../../../framework/Inputs/formRenderer/formRenderer';
import {
  formatParcelDetails,
  formatDeliveryOptions,
  setValuesToParcelDetails,
  setValuesToDeliveryOptions,
  setValuesToDeliveryOptions_domaesticDefaults,
  removeUndefinedProperties,
  getCustomerAccountID,
  getDomesticAccounts,
  getDriverCollectionAccounts,
  setDropdownValues,
} from '../../../../utils/helperFunctions';
import domesticDefaultsForm from '../../../../utils/formConfig/configuration/orderFileDefaultsForm/domesticDefaultsForm';
const DomesticDefaultAccounts = (props) => {
  const {
    formField: { defaultDomesticAccounts },
    setFieldValue,
    formikValues,
    tabIndex,
    domesticAccounts,
    domesticDefaultValues,
  } = props;

  useEffect(() => {
    if (domesticAccounts?.length > 0 &&
      (!(formikValues.defaultDomesticAccounts) ||
        // !(formikValues.defaultDomesticAccounts?.value) ||
        // !domesticDefaultValues?.account)) {
          (formikValues.defaultDomesticAccounts?.value === "") ||
          domesticDefaultValues?.account === null)) {
      setFieldValue('defaultDomesticAccounts', domesticAccounts[0]);
    }
  }, [domesticAccounts, tabIndex, domesticDefaultValues]);

  return (
    <Grid container spacing={2} direction="row">
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <FormRenderer {...defaultDomesticAccounts.props} data={domesticAccounts} fullWidth />

      </Grid>
    </Grid>
  );
};

export default DomesticDefaultAccounts;
