import { UK_POSTAL_CODE_REGEX } from '../../../constants/constants';

const collectionsDefaultsForm = {
  formId: 'collectionsDefaultsForm',
  formField: {
    destinationCountry: {
      props: {
        name: 'destinationCountry',
        label: 'Destination country',
        type: 'select',
        displayField: 'name',
      },
    },
    totalVolumetricWeight: {
      props: {
        name: 'totalVolumetricWeight',
      },
      value: 0.0,
    },
    weight: {
      props: {
        name: 'weight',
        label: 'Weight (kg)',
        type: 'number',
        placeholder: 'Select',
        inputProps: {
          min: 0,
          max: 999,
        },
      },
      validation: {
        name: 'weight',
        validationType: 'number',
        validations: [
          {
            type: 'min',
            params: [0.1, 'Weight is outside permitted range'],
          },
          {
            type: 'max',
            params: [999, 'Weight is outside permitted range'],
          },
        ],
      },
    },

    deliveryType: {
      props: {
        name: 'deliveryType',
        label: 'Delivery type:',
        type: 'radio',
      },
    },
    doorStepInstructions: {
      props: {
        name: 'doorStepInstructions',
        label: 'Doorstep instructions',
        type: 'select',
        displayField: 'name',
      },
    },
    deliveryServicePoint: {
      props: {
        name: 'deliveryServicePoint',
        label: 'Find service point',
        type: 'text',
        placeholder: 'Find ServicePoint ',
      },
      validation: {
        name: 'postalCode',
        validationType: 'string',
        validations: [
          {
            type: 'when',
            params: [
              'deliveryType',
              (deliveryType, schema) => {
                return deliveryType === 'deliverToServicePoint'
                  ? schema.required('ServicePoint  is required')
                  // .matches(UK_POSTAL_CODE_REGEX, 'Invalid postal code')
                  : schema.notRequired();
              },
            ],
          },
        ],
      },
    },
    leaveSafeLocation: {
      props: {
        name: 'leaveSafeLocation',
        label: 'Leave safe location',
        type: 'text',
        placeholder: 'Leave Safe location',
      },
    },
    exchange: {
      props: {
        name: 'exchange',
        label: 'Exchange on delivery:',
        type: 'radio',
      },
    },
    specialInstructions: {
      props: {
        name: 'specialInstructions',
        label: 'Special instructions',
        type: 'text',
        inputProps: {
          maxLength: 30,
        },
      },
      validation: {
        name: 'specialInstructions',
        validationType: 'string',
        validations: [{
          type: 'max',
          params: [30, 'Max 30 characters are allowed'],
        }],
      },
    },
    additionalInstruction: {
      props: {
        name: 'additionalInstruction',
        type: 'text',
        inputProps: {
          maxLength: 30,
        },
      },
    },

    service: {
      props: {
        name: 'service',
        label: 'Service',
        type: 'select',
        displayField: 'name',
      },
    },

    noOfItems: {
      props: {
        name: 'noOfItems',
        label: 'Number of parcels',
        type: 'number',
        inputProps: {
          min: 1,
          max: 20,
        },
        restrictDecimal: true
      },
      validation: {
        name: 'noOfItems',
        validationType: 'number',
        validations: [
          {
            params: [1, 'Number of parcels must be between 1 and 200.'],
            type: 'min',
          },
          {
            type: 'max',
            params: [200, 'Number of parcels must be between 1 and 200.'],
          },
        ],
      },
    },

    collectionInstructions: {
      props: {
        name: 'collectionInstructions',
        label: 'Collection instructions',
        type: 'text',
        inputProps: {
          maxLength: 60,
        },
      },
    },
    descriptionOfGoods: {
      props: {
        name: 'descriptionOfGoods',
        label: 'Description of goods to be collected',
        type: 'text',
        inputProps: {
          maxLength: 50,
        },
      },
    },
    defaultCollectionAccounts: {
      props: {
        name: 'defaultCollectionAccounts',
        label: 'Default collection account',
        type: 'select',
        displayId: "accountId",
        displayField: 'accountName',
        hasDefaultValue: false,
      },

    },
  },
};

export default collectionsDefaultsForm;
