import React, { useState, useEffect } from 'react';
import ShowLocationDetails from './showLocationDetails';
import { ServiceEndPoints } from '../../utils/constants/ApiConstant';
import { GET } from '../../api/axios';

const DeliverToServicePoint = (props) => {
  const [servicePoints, setServicePoints] = useState(null);

  useEffect(() => {
    const servicePoint = props.shipmentDetails.consigneeAddress.postalCode;
    let url = '';
    const encodePostcodeURI = encodeURIComponent(servicePoint);
    url = `${ServiceEndPoints.getServicePoints}?countryCode=GB&PostCode=${encodePostcodeURI}&ServicePointType=parcelshop`;
    GET(url)
      .then((res) => {
        setServicePoints(res.data.slice(0, 5));
      })
      .catch((err) => {
        alert('Error');
        setServicePoints(null);
      });
  }, []);

  return servicePoints
? (
    <ShowLocationDetails rows={servicePoints} rowClicked={props.rowClicked} isServicePoint={true} selectedID={props.selectedID} />
  )
: (
   null
  );
};

export default DeliverToServicePoint;
