import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import FormRenderer from '../../../../../framework/Inputs/formRenderer/formRenderer';
import { abortOnGoingCallsFor, axiosConfig, GET } from '../../../../../api/axios';
import { connect } from 'react-redux';
import { basicSort } from '../../../../../utils/helperFunctions';
import { ServiceEndPoints } from '../../../../../utils/constants/ApiConstant';

const OrderedProduct = (props) => {
  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);

  const {
    formField: { product, service },
    setFieldValue,
    formikValues,
    selectedAccount,
    loadedFrom,
  } = props;

  const getDorStepInstructionsForParams = () => {
    const DSI = formikValues.doorStepInstructions;
    let instruction = 'doorstepOnly';
    if (DSI.value === 'deliverToNeighbour') {
      instruction = 'neighbourDelivery';
    } else if (DSI.value === 'leaveSafe') {
      instruction = 'secureLocationDelivery';
    }
    return instruction;
  };

  const getProducts = () => {
    let url = ServiceEndPoints.getProducts;
    let queryParams = '';
    const destinationPostalCode = 'CV37 6UH';
    const destinationRecipientType = 'business';
    const parcels = formikValues.noOfItems || 1;
    const weightDefined = formikValues.weight || 1;
    const doorstepDeliveryTypes =
      formikValues.deliveryType === 'deliverToDoorStep' ? getDorStepInstructionsForParams() : 'doorstepOnly';
    const destinationAddressType = formikValues.deliveryType === 'deliverToDoorStep' ? 'doorstep' : 'servicePoint';
    queryParams = `originCountryCode=GB&originPostalCode=${selectedAccount?.sender?.postalCode}&destinationCountryCode=GB&destinationPostalCode=${destinationPostalCode}&destinationRecipientType=${destinationRecipientType}&parcels=${parcels}&weight=${weightDefined}&doorstepDeliveryTypes=${doorstepDeliveryTypes}&destinationAddressType=${destinationAddressType}`;
    abortOnGoingCallsFor(url);
    axiosConfig.get(url + queryParams)
      .then((res) => {
        const allProducts = res.data;
        allProducts?.sort?.((a, b) => basicSort(a.sortOrder, b.sortOrder));
        setProducts(allProducts);
      })
      .catch((err) => {
        console.log(err);
        setProducts([]);
      });
  };

  useEffect(() => {
    getProducts();
  }, [
    props.formikValues.noOfItems,
    props.formikValues.weight,
    props.formikValues.deliveryType,
    props.formikValues.doorStepInstructions,
  ]);

  useEffect(() => {
    if (loadedFrom.loadedFrom !== 'dropdownResponse') {
      setFieldValue('product', (products || [])[0]);
    }
  }, [products]);

  useEffect(() => {
    setServices(formikValues.product?.deliveryCapabilities?.services || []);
  }, [formikValues.product]);

  useEffect(() => {
    if (loadedFrom.loadedFrom !== 'dropdownResponse') {
      setFieldValue('service', (services || [])[0]);
    }
  }, [services]);



  useEffect(() => {
    let serviceObj;
    const loadedResponse = loadedFrom.loadedResponse;
    if (loadedFrom.loadedFrom === 'dropdownResponse') {
      (products || []).forEach((product) => {
        serviceObj = (product?.deliveryCapabilities?.services || []).find((service) => {
          return service.orderedProduct === loadedResponse.orderedProduct;
        });

        if (serviceObj) {
          setFieldValue('product', product);
          setFieldValue('service', serviceObj);
          return;
        }
      });
    }
  }, [products, services, loadedFrom]);

  return (
    <Grid container direction="row">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container spacing={3} direction="row" alignItems="flex-start">
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FormRenderer {...product.props} data={products} fullWidth></FormRenderer>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FormRenderer {...service.props} data={services} fullWidth />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

OrderedProduct.propTypes = {};

const mapStateToProps = (state) => {
  return {
    loadedFrom: state.loadedFrom.loadedFrom,
  };
};
export default connect(mapStateToProps, null)(OrderedProduct);
