import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import DynamicMaterialTable from '../../../framework/DynamicMaterialTable/DynamicMaterialTable';
import InputButton from '../../../framework/Inputs/inputButton/inputButton';
import ConfirmationDialog from '../../addressBook/confirmation/confirmation';
import CustomModal from '../../../framework/dialog/customDialog';
export default function ReviewCollectionData(props) {
  const { rows, columns, isSearchable, isSelectable, sorting, paging, pageSize, pageSizeOptions, title } = props;
  const [selectedRows, setSelectedRows] = useState([]);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState();
  const [_deleteAllOrders, setdeleteAllOrders] = useState(false);
  const addSelectedRows = (selectedRows) => {
    setSelectedRows(selectedRows);
  };

  const handleDialogClose = () => {
    setShowConfirmationDialog(false);
  };
  const onDialogConfirm = () => {
    if (_deleteAllOrders) {
      props.deleteAllOrder(props.isCollectionRequest, props.isValid);
    } else {
      props.deleteSelectedOrder(selectedRows);
      setSelectedRows([]);
    }
    setShowConfirmationDialog(false);
  };
  const onDialogCancel = () => {
    setShowConfirmationDialog(false);
  };
  const deleteOrderEntry = () => {
    // setConfirmationMsg(`This action will delete ${selectedRows.length} order and can't be undone.Select Delete all to confirm.`)
    setConfirmationMsg(`Are you sure you want to delete these collections?`);
    setShowConfirmationDialog(true);
    setdeleteAllOrders(false);
  };

  const deleteAllOrderEntry = () => {
    // setConfirmationMsg(`This action will delete ${selectedRows.length} order and can't be undone.Select Delete all to confirm.`)
    setConfirmationMsg(`All collections will be removed, are you sure you want to delete them?`);
    setShowConfirmationDialog(true);
    setdeleteAllOrders(true);
  };

  return (
    <Grid container justify="flex-end" alignItems="flex-start">
      <Grid item lg={12} md={12} xs={12} className="Table-column-color">
        <DynamicMaterialTable
          rows={rows}
          columns={columns}
          isSelectable={isSelectable}
          isSearchable={isSearchable}
          sorting={sorting}
          paging={paging}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
          onSelectionChange={addSelectedRows}
          isProcesOrders={true}
          onClickSetSortOrder={props.onClickSetSortOrder}
          {...props}
        />
      </Grid>
      <Grid container item className="customModal-book">
        <CustomModal open={showConfirmationDialog} title={title} onClose={handleDialogClose} maxWidth="sm">
          <ConfirmationDialog
            confirmationMessage={confirmationMsg}
            onConfirm={onDialogConfirm}
            OnCancel={onDialogCancel}
            confirmLabel={(_deleteAllOrders) ? "Ok" : "Yes"}
            cancelLabel={(_deleteAllOrders) ? "Cancel" : "No"}
          />
        </CustomModal>
      </Grid>
      <Grid
        className="order-btn-container"
        container
        spacing={2}
        justify="flex-end"
        alignItems="flex-end"
        style={{ paddingTop: '10px' }}
      >
        <Grid item>
          <InputButton
            className="outlined-btn Delete-Orders"
            isDisabled={props.totalCount === 0}
            label={(props.isCollectionRequest) ? "Delete all collections" : "Delete all shipments"}
            variant="outlined"
            onClick={() => deleteAllOrderEntry()}
          />
        </Grid>
        <Grid item>
          <InputButton
            className="outlined-btn Delete-Orders"
            isDisabled={selectedRows.length === 0}
            label={(props.isCollectionRequest) ? "Delete selected collections" : "Delete selected shipments"}
            variant="outlined"
            onClick={() => deleteOrderEntry()}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

ReviewCollectionData.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  isSearchable: PropTypes.bool,
  isSelectable: PropTypes.bool,
  sorting: PropTypes.bool,
  paging: PropTypes.bool,
  pageSize: PropTypes.object,
  pageSizeOptions: PropTypes.object,
  title: PropTypes.string,
  confirmationMessage: PropTypes.string,
  deleteSelectedOrder: PropTypes.func,
  createAllShipments: PropTypes.func,
  createShipment: PropTypes.func,
};
