import React, { useState, useEffect } from 'react';

import { Grid, TableContainer, Paper, Table, TableRow, TableCell, TableBody } from '@material-ui/core';

import { isValidNumber } from '../../../../utils/common';

import IntlParcelDimension from './IntlParcelDimension';
import IntlParcelHeaders from './IntlParcelHeaders';

import { useDebouncedCallback } from 'use-debounce'
import '../../../../container/shipment/addShipment/parcelDetails/parcelDetails.scss';

const InternationalParcelDetails = (props) => {
  const {maxParcels, parcelCount, dimsValidation, volumetricDivisor, setFieldValue, formValues , totalValues, setTotalValues, loadedFrom, setAfterSubmitFlag, afterSubmitFlag} = props;

  const dimsIntialValue = [...formValues.intlParcelDetails];

  const [dimensionRows, setDimensionrows] = useState(dimsIntialValue);
  const [spanIsActive, setspanIsActive] = React.useState(false);
  const [duplicatedFlag, setDuplicatedFlag] = React.useState(false);

  // const [totalValues, setTotalValues] = useState({ totalWeight: 0.0, totalVolumetricWeight: 0.0 });
  //ManageNumber of rows and initialValues
  const debouncedParcelCount = useDebouncedCallback(()=>{
    const currentDimensions = [...dimensionRows];
    if (dimensionRows.length !== parcelCount || formValues?.duplicateParcelDimensions) {
      if (dimensionRows.length < parcelCount && parcelCount<=props.maxParcels) {
        const rowsToAdd = Math.abs(parseInt(parseInt(parcelCount) - dimensionRows.length));
        for (let i = 0; i < rowsToAdd; i++) {
          const newRow = formValues?.duplicateParcelDimensions ?{ length: currentDimensions[0]?.length, weight: currentDimensions[0]?.weight, width: currentDimensions[0]?.width, height: currentDimensions[0]?.height, pieceRefId: '', isValid: false } : { length: '', weight: '', width: '', height: '', pieceRefId: '', isValid: false };
          currentDimensions.push(newRow);
        }
      } else if (dimensionRows.length > parcelCount) {
        const rowsToDelete = Math.abs(dimensionRows.length - parseInt(parcelCount));
        currentDimensions.splice(currentDimensions.length - rowsToDelete, rowsToDelete);
      } else if (dimensionRows.length === parcelCount){
        for (let i = 0; i < parcelCount; i++) {
          const newRow = { length: currentDimensions[0]?.length, weight: currentDimensions[0]?.weight, width: currentDimensions[0]?.width, height: currentDimensions[0]?.height, pieceRefId: currentDimensions[i]?.pieceRefId, isValid: false };
          currentDimensions[i] = newRow;
        }
      }
    }
    setDimensionrows(currentDimensions);
    setFieldValue('intlParcelDetails', currentDimensions);
    if(formValues?.duplicateParcelDimensions){
      setDuplicatedFlag(true);
    }
  },500);
  useEffect(() => {
    if(parcelCount <= maxParcels){
      debouncedParcelCount();
    }
  }, [parcelCount, formValues?.duplicateParcelDimensions]);

  const firstRowWeightChange = useDebouncedCallback(() => {
    const currentDimensions = [...dimensionRows]
    if(formValues?.duplicateParcelDimensions){
      for (let i = 0; i < parcelCount; i++) {
        const newRow = { length: formValues?.intlParcelDetails?.[i]?.length, weight: formValues?.intlParcelDetails?.[0]?.weight, width: formValues?.intlParcelDetails?.[i]?.width, height: formValues?.intlParcelDetails?.[i]?.height, pieceRefId: formValues?.intlParcelDetails?.[i]?.pieceRefId, isValid: false };
        currentDimensions[i] = newRow;
      }
      setDimensionrows(currentDimensions);
      setFieldValue('intlParcelDetails', currentDimensions);
    }
    if(formValues?.duplicateParcelDimensions){
      setDuplicatedFlag(true);
    }
  },200)
  useEffect(() => {
    if(parcelCount<=maxParcels)
    {
    firstRowWeightChange();
    }
  },[formValues?.intlParcelDetails?.[0]?.weight]);

  const firstRowLengthChange = useDebouncedCallback(() => {
    const currentDimensions = [...dimensionRows]
    if(formValues?.duplicateParcelDimensions){
      for (let i = 0; i < parcelCount; i++) {
        const newRow = { length: formValues?.intlParcelDetails?.[0]?.length, weight: formValues?.intlParcelDetails?.[i]?.weight, width: formValues?.intlParcelDetails?.[i]?.width, height: formValues?.intlParcelDetails?.[i]?.height, pieceRefId: formValues?.intlParcelDetails?.[i]?.pieceRefId, isValid: false };
        currentDimensions[i] = newRow;
      }
      setDimensionrows(currentDimensions);
      setFieldValue('intlParcelDetails', currentDimensions);
    }
    if(formValues?.duplicateParcelDimensions){
      setDuplicatedFlag(true);
    }
  },200)
  useEffect(() => {
    if(parcelCount<=maxParcels){
    firstRowLengthChange();}
  },[formValues?.intlParcelDetails?.[0]?.length]);

  const firstRowWidthChange = useDebouncedCallback(() => {
    const currentDimensions = [...dimensionRows]
    if(formValues?.duplicateParcelDimensions){
      for (let i = 0; i < parcelCount; i++) {
        const newRow = { length: formValues?.intlParcelDetails?.[i]?.length, weight: formValues?.intlParcelDetails?.[i]?.weight, width: formValues?.intlParcelDetails?.[0]?.width, height: formValues?.intlParcelDetails?.[i]?.height, pieceRefId: formValues?.intlParcelDetails?.[i]?.pieceRefId, isValid: false };
        currentDimensions[i] = newRow;
      }
      setDimensionrows(currentDimensions);
      setFieldValue('intlParcelDetails', currentDimensions);
    }
    if(formValues?.duplicateParcelDimensions){
      setDuplicatedFlag(true);
    }
  },200)
  useEffect(() => {
    if(parcelCount<=maxParcels){
    firstRowWidthChange();}
  },[formValues?.intlParcelDetails?.[0]?.width]);

  const firstRowHeightChange = useDebouncedCallback(() => {
    const currentDimensions = [...dimensionRows]
    if(formValues?.duplicateParcelDimensions){
      for (let i = 0; i < parcelCount; i++) {
        const newRow = { length: formValues?.intlParcelDetails?.[i]?.length, weight: formValues?.intlParcelDetails?.[i]?.weight, width: formValues?.intlParcelDetails?.[i]?.width, height: formValues?.intlParcelDetails?.[0]?.height, pieceRefId: formValues?.intlParcelDetails?.[i]?.pieceRefId, isValid: false };
        currentDimensions[i] = newRow;
      }
      setDimensionrows(currentDimensions);
      setFieldValue('intlParcelDetails', currentDimensions);
    }
    if(formValues?.duplicateParcelDimensions){
      setDuplicatedFlag(true);
    }
  },200)
  useEffect(() => {
    if(parcelCount<=maxParcels){
    firstRowHeightChange();}
  },[formValues?.intlParcelDetails?.[0]?.height]);

  //Manage row values
  useEffect(() => {
    setDimensionrows([...formValues.intlParcelDetails]);
  }, [formValues.intlParcelDetails]);

  //Manage parcel rows
  const manageParcelDimensions = (parcelDetails, parcelNo) => {
    const updateDimensions = [...dimensionRows];
    switch (parcelDetails.name) {
      case 'weight':
        updateDimensions[parcelNo].weight = parcelDetails.value;
        updateDimensions[parcelNo].isValid = parcelDetails.isValid;
        break;
      case 'length':
        updateDimensions[parcelNo].length = parcelDetails.value;
        updateDimensions[parcelNo].isValid = parcelDetails.isValid;
        break;
      case 'width':
        updateDimensions[parcelNo].width = parcelDetails.value;
        updateDimensions[parcelNo].isValid = parcelDetails.isValid;
        break;
      case 'height':
        updateDimensions[parcelNo].height = parcelDetails.value;
        updateDimensions[parcelNo].isValid = parcelDetails.isValid;
        break;
      case 'pieceRefId':
        updateDimensions[parcelNo].pieceRefId = parcelDetails.value;
        updateDimensions[parcelNo].isValid = parcelDetails.isValid;
        break;
      default:
        break;
    }
    setDimensionrows(updateDimensions);
    setFieldValue('intlParcelDetails', updateDimensions);
  };

  useEffect(() => {
    const totalWeight = dimensionRows.map((rowData) => {
      return isValidNumber(rowData.weight) ? parseFloat(rowData.weight) : parseFloat(0.0);
    });
    const totalVolumetricWeight = dimensionRows.map((rowData) => {
      if (isValidNumber(rowData.length) && isValidNumber(rowData.width) && isValidNumber(rowData.height)) {
        return (rowData.length * rowData.width * rowData.height) / volumetricDivisor;
      } else {
        return 0.0;
      }
    });
    setTotalValues({
      totalWeight: totalWeight.reduce((a, b) => a + b, 0),
      totalVolumetricWeight: totalVolumetricWeight.reduce((a, b) => a + b, 0),
    });
    setFieldValue('totalVolumetricWeight', parseFloat( totalVolumetricWeight.reduce((a, b) => a + b, 0)).toFixed(2));
  }, [dimensionRows]);

  useEffect(()=>{
    if(parseFloat(totalValues.totalWeight) > parseFloat(formValues.weight)){
      setspanIsActive(true);
    } else {
      setspanIsActive(false);
    }
  },[formValues.weight,totalValues?.totalWeight]);

  //Render rows
  const renderRepeaterControl = () => {
    let items = [];
    items = dimensionRows.map((rowData, index) => {
      return (
        <IntlParcelDimension
          parcelNo={index}
          rowData={rowData}
          manageParcelDimensions={manageParcelDimensions}
          volumetricDivisor={volumetricDivisor}
          dimsValidation={dimsValidation}
          isAddressBook={props.isAddressBook}
          loadedFrom={loadedFrom}
          setAfterSubmitFlag={setAfterSubmitFlag}
          afterSubmitFlag={afterSubmitFlag}
          duplicatedFlag={duplicatedFlag}
          setDuplicatedFlag={setDuplicatedFlag}
        />
      );
    });
    return items;
  };

  return (
    <Grid container className='dimTableBorder'>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <TableContainer component={Paper}>
          <Table className='AddShipment-DimTable' aria-label="simple table">
            <IntlParcelHeaders />
            <TableBody>
              {renderRepeaterControl()}
              <TableRow className="footerRow">
                <TableCell align="left" style={{maxWidth:'60px'}}>Total</TableCell>
                <TableCell align="left" style={{maxWidth:'138px'}}>{parseFloat(totalValues.totalWeight).toFixed(2)}</TableCell>
                <TableCell align="left" style={{maxWidth:'138px'}}></TableCell>
                <TableCell align="left" style={{maxWidth:'138px'}}></TableCell>
                <TableCell align="left" style={{maxWidth:'138px'}}></TableCell>
                <TableCell align="left" style={{maxWidth:'138px'}}></TableCell>
                <TableCell align="left" style={{maxWidth:'90px'}}>{parseFloat(totalValues.totalVolumetricWeight).toFixed(2)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <span className="span-required" style={{ display: spanIsActive ? '' : 'none' }}>
                Total weight: <b>{parseFloat(totalValues.totalWeight).toFixed(2)}</b> can not be greater than the declared
                weight <b>{parseFloat(formValues.weight).toFixed(2)} </b>
        </span>
      </Grid>
    </Grid>
  );
};

export default InternationalParcelDetails;
