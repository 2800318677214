import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from "@material-ui/core/Chip";
import { makeStyles } from '@material-ui/core';
import { useField } from 'formik';
import { useDebouncedCallback } from 'use-debounce';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 765,
        height: '100% !important',
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
    },
}));

const MultipleValues = (props) => {
    const { data, label, displayField, name, fieldValue, formikValues, valuePropertyName, style, ...rest } = props;
    const [inputValue, setInputValue] = React.useState('');
    const [accountsFieldValue, setAccountsFieldValue] = React.useState([]);

    const [field, meta, helper] = useField(props);
    const { touched, error } = meta;
    const { setValue } = helper;
    const isError = touched && error && true;
    const { value } = field;

    const classes = useStyles();

    const onAutoCompleteSelection = (event, value) => {
        setValue(value ? value : '');
    };

    const debounced = useDebouncedCallback((value) => {
        onChangeHandle(value);
    }, 500);

    useEffect(() => {
        if (formikValues)  //&& !props.createUser
        {
            setAccountsFieldValue(formikValues);
        }
        // props.setState({createUser:false});
    }, [formikValues, props]);

    useEffect(() => {
        if (props.createUser) {
            setAccountsFieldValue([]);
        }

    }, [props?.createUser]);

    useEffect(() => {
        if (inputValue === '' || inputValue === null) {
            props.getAccounts([]);
        }
    }, [inputValue]);

    const onChangeHandle = (value) => {
        props.getAccounts([], value);
    }

    return (
        <div id={'tags-outlined2'} className={classes.root}>
            <Autocomplete
                //  {...field}
                value={accountsFieldValue}
                multiple={props.multiple}
                getOptionDisabled={formikValues?.length >= 350 ? (option) => !formikValues.includes(option) : (option) => formikValues.includes(option?.split('-')?.[0])}
                ListboxProps={{
                    onScroll: (event) => {
                        const listboxNode = event.currentTarget;
                        if (listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - 50) {
                            props.setOffset(props.offset + 50);
                            props.getAccounts(data, inputValue?.length >= 3 ? inputValue : "", props.offset + 50);
                        }
                    }
                }}
                onChange={(event, value) => onAutoCompleteSelection(event, value)}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                inputValue={inputValue}
                limitTags={6}
                disableCloseOnSelect
                id="tags-outlined"
                options={data.map(option => option.accountId + "-" + option.accountName)}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            variant="outlined"
                            label={option?.split('-')?.[0]}
                            size="small"
                            {...getTagProps({ index })}
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField {...params} label={
                        (props.isRequired)
                            ? (<label className="labelTextfield">
                                {label} <span className="span-required">*</span>
                            </label>)
                            : (<label className="labelTextfield">{label} </label>)
                    }
                        variant="outlined" size='small' onChange={ev => {
                            if ((ev.target.value !== "" || ev.target.value !== null) && ev.target.value.length >= 3) {
                                debounced(ev.target.value);
                            }
                        }}
                        placeholder="Accounts"
                    />
                )}
            />
        </div>
    );
};

MultipleValues.propTypes = {
    //data: PropTypes.array.isRequired, // options for autocomplete dropdown
    label: PropTypes.string, // label for text field
    displayField: PropTypes.string, // nested object property name that need to be shown to user
    name: PropTypes.string.isRequired, // formcontrol name in formik
    fieldValue: PropTypes.object, // formikValue of field control for prefilling
    valuePropertyName: PropTypes.string, // nested object unique property name()
    style: PropTypes.object,
};

MultipleValues.defaultProps = {
    data: [], // options for autocomplete dropdown
    label: '', // label for text field
};

export default MultipleValues;
