import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, SvgIcon, Divider, Snackbar } from '@material-ui/core';
// import InputTextfield from '../../../framework/Inputs/inputTextField/inputTextField'
import userManagementForm from '../../../utils/formConfig/userManagement/userManagementForm';
import FormRenderer from '../../../framework/Inputs/formRenderer/formRenderer';
import ConfirmationDialog from '../../addressBook/confirmation/confirmation';
import { useSelector, useDispatch, connect } from 'react-redux';
import InputButton from '../../../framework/Inputs/inputButton/inputButton';
import { ReactComponent as InfoIcon } from '../../../assets/images/InfoIcon.svg';
import { axiosConfig, GET, PATCH, POST } from '../../../api/axios';
import DialogBox from '../../../components/addShipment/templates/dialogForTemplate/dialogForTemplate';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { userDetailsObj, setDropdownValues, updateUserObj } from '../../../utils/helperFunctions';
import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';
import { roleOptions } from '../../../utils/constants/constants';
import * as actions from '../../../store/actions/index';
import CustomModal from '../../../framework/dialog/customDialog';

const UserDetails = (props) => {
  const {
    formField: { userName, userId, emailId, role, accounts, isActive },
    getuserListData,
  } = props;
  const isFormValid = (errors) => {
    const { deliveryServicePoint, ...restControls } = errors;
    return Object.keys(restControls).length === 0;
  };
  const { setFieldValue, formikValues, errors, setErrors, setFieldTouched } = props;
  const userAccounts = useSelector((state) => state.accounts.userAccounts);
  const [roles, setroles] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [roleAccess, setRollAccess] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = React.useState(false);
  let roleArray = [];
  const showRoleDetails = () => { };
  const dispatch = useDispatch();
  const getRoles = () => {
    GET(ServiceEndPoints.getRolesAccess).then((res) => {
      roleArray.push(res.data.find(r => r.roleName?.toLowerCase() === formikValues?.role?.value?.toLowerCase())?.accessSet || []);

      // if (formikValues.role && formikValues.role.value === 'Admin') {
      //   roleArray.push(res.data[0].accessSet);
      // }
      // if (formikValues.role && formikValues.role.value === 'SuperUser') {
      //   roleArray.push(res.data[1].accessSet);
      // }
      // if (formikValues.role && formikValues.role.value === 'Supervisor') {
      //   roleArray.push(res.data[3].accessSet);
      // }
      // if (formikValues.role && formikValues.role.value === 'Shipment Entry') {
      //   roleArray.push(res.data[4].accessSet);
      // }
      // if (formikValues.role && formikValues.role.value === 'Shipment Entry & Reporting') {
      //   roleArray.push(res.data[5].accessSet);
      // }
      // if (formikValues.role && formikValues.role.value === 'Reporting & Shipment Entry') {
      //   roleArray.push(res.data[6].accessSet);
      // }
      // if (formikValues.role && formikValues.role.value === 'Reporting') {
      //   roleArray.push(res.data[2].accessSet);
      // }

      setRollAccess(roleArray);
    });
  };

  React.useEffect(() => {
    getRoles();
  }, [formikValues.role]);

  React.useEffect(() => {
    if (!props.updateUser)
      setFieldValue('userName', '');
  }, []);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (value) => {
    setAnchorEl(null);
  };

  const handleDialogClose = () => {
    setShowConfirmationDialog(false);
    setFieldValue('role', roleOptions?.[0]);
  };

  const onDialogConfirm = () => {
    setShowConfirmationDialog(false);
  };

  const onDialogCancel = () => {
    setShowConfirmationDialog(false);
    setFieldValue('role', setDropdownValues(roleOptions, 'value', props.updateRowData.role));
  };

  function validateEmail(input) {
    var validRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (input.match(validRegex)) {
      return false;
    }
    else {
      return true;
    }
  }

  const postData = () => {
    let errors_object = {};

    if (formikValues.userName?.trim() === '') {
      errors_object.userName = 'User name is required';
    }
    if (formikValues.emailId === '' || formikValues.emailId === ' ') {
      errors_object.emailId = 'Email is required';
    }
    if (validateEmail(formikValues.emailId)) {
      errors_object.emailId = 'Invalid email';
    }
    if (formikValues.role.value === '') {
      errors_object.role = 'Role is required';
    }
    if (formikValues.accounts.length === 0) {
      errors_object.accounts = 'Accounts selection is required';
    }
    setErrors(errors_object);

    if (isFormValid(errors_object)) {
      dispatch(actions.setLoaderLayOver(true));
      const reqObj = userDetailsObj(formikValues);
      POST(`${ServiceEndPoints.postUser}`, reqObj)
        .then((res) => {
          dispatch(actions.setAppSnackBarData({ msg: 'User Created Successfully' }));
          setTimeout(() => {
            dispatch(actions.setLoaderLayOver(false));
          }, 2000);
          props.handleButtonClick();
        })
        .catch((err) => {
          console.log(err);
          dispatch(
            actions.setBottomAlert({
              msgs: [
                {
                  type: 'error',
                  msg: err?.response?.data?.[0]?.detail ?? 'Error occurred, please try again',
                },
              ],
            }),
          );
          // dispatch(actions.setAppSnackBarData({type:"error", msg:'Error!'}))
          setTimeout(() => {
            dispatch(actions.setLoaderLayOver(false));
          }, 2000);
        });
    } else {
      dispatch(
        actions.setBottomAlert({
          msgs: [{ type: 'error', msg: 'The form has errors, please correct and try again' }],
        }),
      );
      setFieldTouched('userName', true);
      setFieldTouched('emailId', true);
      setFieldTouched('role', true);
      setFieldTouched('accounts', true);
    }
  };

  const updateData = () => {
    let errors_object = {};

    if (formikValues.userName?.trim() === '') {
      errors_object.userName = 'User name is required';
    }
    if (formikValues.emailId === '' || formikValues.emailId === ' ') {
      errors_object.emailId = 'Email is required';
    }
    if (validateEmail(formikValues.emailId)) {
      errors_object.emailId = 'Invalid email';
    }
    if (formikValues.role.value === '') {
      errors_object.role = 'Role is required';
    }
    if (formikValues.accounts.length === 0) {
      errors_object.accounts = 'Accounts selection is required';
    }
    setErrors(errors_object);
    if (isFormValid(errors_object)) {
      dispatch(actions.setLoaderLayOver(true));
      const reqObj = updateUserObj(formikValues);
      PATCH(ServiceEndPoints.postUser, reqObj)
        .then((res) => {
          dispatch(actions.setAppSnackBarData({ msg: 'User updated successfully' }));

          setTimeout(() => {
            dispatch(actions.setLoaderLayOver(false));
          }, 2000);

          props.handleButtonClick();
          props.getuserListData();
        })
        .catch((err) => {
          console.log(err);
          dispatch(
            actions.setBottomAlert({
              msgs: [
                {
                  type: 'error',
                  msg: err?.response?.data?.[0]?.detail ?? 'Error occurred, please try again',
                },
              ],
            }),
          );
          setTimeout(() => {
            dispatch(actions.setLoaderLayOver(false));
          }, 2000);
        });
    } else {
      dispatch(
        actions.setBottomAlert({
          msgs: [{ type: 'error', msg: 'The form has errors, please correct and try again' }],
        }),
      );
      setFieldTouched('userName', true);
      setFieldTouched('emailId', true);
      setFieldTouched('role', true);
      setFieldTouched('accounts', true);
      // return;
    }
  };
  React.useEffect(() => {
    if (props.updateUser) {
      setFieldValue('userName', props.updateRowData.userName);
      setFieldValue('userId', props.updateRowData.id);
      setFieldValue('emailId', props.updateRowData.emailId);
      setFieldValue('role', setDropdownValues(roleOptions, 'value', props.updateRowData.role));
      setTimeout(() => {
        setFieldValue('accounts', props.updateRowData.accounts);
      }, 0);
      setFieldValue('isActive', props.updateRowData.status);
    } else if (props.row) {
      setFieldValue('userName', '');
      setFieldValue('emailId', '');
      setFieldValue('role', setDropdownValues(roleOptions, 'value', props.row[0].role));
      setFieldValue('accounts', props.row[0].accounts);
    } else {
      setFieldValue('userName', '');
      setFieldValue('userId', '        ');
      setFieldValue('emailId', '');
      setFieldValue('role', setDropdownValues(roleOptions, 'value', roleOptions[0].value));
      setFieldValue('accounts', '');
      setFieldValue('isActive', props.updateRowData.status);
    }
  }, [props.updateUser, props.row]);

  React.useEffect(() => {
    if ((formikValues.role?.value?.toLowerCase() === 'retail superuser' || formikValues.role?.value?.toLowerCase() === 'national superuser') && props.updateSuperUserFlag) {
      setShowConfirmationDialog(true);
    }
  }, [formikValues?.role]);

  React.useEffect(() => {
    let selectedAccount = [];
    if (formikValues.role.value === 'SuperUser') {
      userAccounts.map((ele) => {
        selectedAccount.push(ele.accountId);
      });
      setFieldValue('accounts', selectedAccount);
    }
    else {
      if (props.createUser && props.createUser === true)
        setFieldValue('accounts', selectedAccount);
    }
  }, [formikValues.role]);
  return (
    <Grid container direction="column" className="createUser-container" spacing={2}>
      <Grid className="form-group" item xs={12} sm={12} md={12} lg={12}>
        <FormRenderer {...userName.props} fullWidth />
      </Grid>
      {props.updateUser === true ? (
        <Grid className="form-group" item xs={12} sm={12} md={12} lg={12}>
          <FormRenderer {...emailId.props} fullWidth isReadOnly={true} />
        </Grid>
      ) : (
        <Grid className="form-group" item xs={12} sm={12} md={12} lg={12}>
          <FormRenderer {...emailId.props} fullWidth />
        </Grid>
      )}
      <Grid className="form-group" item xs={12} sm={12} md={12} lg={12}>
        <FormRenderer {...role.props} data={roleOptions} fullWidth />
      </Grid>
      <Grid className="form-group text-low-visible" item xs={12} sm={12} md={12} lg={12}>
        <IconButton onClick={handleClick} style={{ width: '20px', height: '20px' }}>
          <SvgIcon component={InfoIcon} />
        </IconButton>
        <span className="role-text">With this role user get access to</span>
        <Menu
          style={{ marginLeft: '25px', marginTop: '10px' }}
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <span style={{ fontWeight: 'bold', fontSize: '16px', marginLeft: '15px' }}>
            With this role user get access to
          </span>
          <Divider style={{ marginTop: '5px' }} />
          {roleAccess[0] &&
            roleAccess[0].map((item, index) => (
              <Grid>
                <MenuItem onClick={handleClose} key={index} value={item}>
                  {item}
                </MenuItem>
                <Divider style={{ marginTop: '5px' }} />
              </Grid>
            ))}
        </Menu>
      </Grid>
      <Grid className="form-group" item xs={12} sm={12} md={12} lg={12}>
        <FormRenderer
          {...accounts.props}
          data={userAccounts}
          isFromCreateNewUser={true}
          isDisabled={formikValues.role.value === 'SuperUser'}
          fullWidth
          style={{ width: '100% !important' }}
        />
      </Grid>
      <Grid className="form-group" item xs={12} sm={12} md={12} lg={12}>
        <Grid container direction="column" spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {formikValues.accounts !== undefined ? (
              formikValues.accounts.length > 1 ? (
                <span>Selected accounts</span>
              ) : formikValues.accounts.length === 1 ? (
                <span>Selected account</span>
              ) : null
            ) : null}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={9} lg={9} className="create-user-popup">
          <strong style={{ fontSize: '16px' }}>{((formikValues || {}).accounts || []).join(',')}</strong>
        </Grid>
        {props.updateUser === true ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormRenderer {...isActive.props} checked={true} fullWidth />
          </Grid>
        ) : null}
      </Grid>
      {props.updateUser === true ? <Grid container className="customModal-book">
        <CustomModal open={showConfirmationDialog} title={'Confirmation'} maxWidth="sm" onClose={handleDialogClose}>
          <ConfirmationDialog
            confirmationMessage={(formikValues.role?.value === 'Retail SuperUser') ? "You are changing the user role for this user to “Retail Super User” you will not be able to revert this user back to a standard “Super User” role, are you happy to continue?" :
              "You are changing the user role for this user to “National Super User” you will not be able to revert this user back to a standard “Super User” role, are you happy to continue?"}
            onConfirm={onDialogConfirm}
            OnCancel={onDialogCancel}
          />
        </CustomModal>
      </Grid> : ''}
      <Grid
        className={`${props.updateUser ? 'update-btn-container form-group' : 'create-btn-container form-group'}`}
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
      >
        <Grid container direction="row" spacing={3}>
          <Grid item xs={9} sm={9} md={9} lg={9}></Grid>
          <Grid item xs={3} sm={3} md={3} lg={3}>
            <InputButton
              label={props.updateUser ? 'Update' : 'Create'}
              isDisabled={!((formikValues || {}).accounts || []).length > 0 && formikValues.isActive}
              variant="contained"
              buttonType="submit"
              onClick={() => {
                props.updateUser !== true ? postData() : updateData();
              }}
              className={`${props.updateUser ? 'contained-btn update-btn' : 'contained-btn create-btn'}`}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
UserDetails.propTypes = {
  formField: PropTypes.object,
  formikValues: PropTypes.object,
  setFieldValue: PropTypes.func,
};

// const roleOptions = [
//   {
//     value: '',
//     label: 'Select',
//   },
//   {
//     value: 'Admin',
//     label: 'Admin',
//   },

//   {
//     value: 'SuperUser',
//     label: 'SuperUser',
//   },
//   {
//     value: 'Supervisor',
//     label: 'Supervisor',
//   },
//   {
//     value: 'Shipment Entry',
//     label: 'Shipment Entry',
//   },
//   {
//     value: 'Shipment Entry & Reporting',
//     label: 'Shipment Entry & Reporting',
//   },
//   {
//     value: 'Reporting',
//     label: 'Reporting',
//   },
// ];
export default UserDetails;