import React, { useState, useEffect } from 'react';

import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';
import { abortOnGoingCallsFor, axiosConfig } from '../../../api/axios';
import FormRenderer from '../../../framework/Inputs/formRenderer/formRenderer';
import { makeStyles } from '@material-ui/core/styles';
import {
  formatDate,
  getCustomerAccountID,
  setDropdownValues,
  tradingLocationArrayForm,
} from '../../../utils/helperFunctions';
import { useSelector } from 'react-redux';
import moment from 'moment';
const useStyles = makeStyles({
  mainConatiner: {
    marginTop: '10px',
    marginBottom: '10px',
  },
});
let publicHolidays = [];
const DriverPickUpContainer = (props) => {
  const {
    formField: { bookCollectionDate, earliestTime, latestTime, specialInstructions, pickupLocation },
    setFieldValue,
    setFieldTouched,
    errorMessage,
    formikValues,
    setLatestPickup,
    setCurrentBookStatus,
    setOperationalAccount
  } = props;
  const classes = useStyles();

  const [workingDays, setWorkingDays] = useState([]);
  const [isPickUpBooked, setIsPickUpBooked] = useState(false);
  const [isError, setIsError] = useState(false);
  const [tradingLocations, setTradingLocations] = useState([]);
  const [pickUpDropdownData, setpickUpDropdownData] = useState([]);
  const [selectedTradingLocation, setselectedTradingLocation] = useState([]);
  const [isHidden, setIsHidden] = useState(false);
  const [isDisabled, setisDisabled] = useState(false);
  const [isRequestPickUp, setIsRequestPickUp] = useState(false);
  const [isOnStop, setIsOnStop] = useState(false);
  const userAccounts = useSelector((state) => state.accounts.userAccounts);

  useEffect(() => {
    getPickupLocation();
    abortOnGoingCallsFor(ServiceEndPoints.getPublicHolidays);
    axiosConfig
      .get(`${ServiceEndPoints.getPublicHolidays}?countryCode=gb`)
      .then((res) => {
        // const weekendHolidayPickup = userAccounts.filter((userAcc) => userAcc.configuration.weekendHolidayPickup === true).length > 0
        //   ? true : false;
        // getWorkingDays(res.data.publicHolidays);
        publicHolidays = res.data.publicHolidays;
        getWorkingDays(publicHolidays);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getWorkingDays = (holidayList, weekendHolidayPickup = false) => {
    let nextDate = new Date();
    const fiveWorkingDays = [];
    while (fiveWorkingDays.length < 5) {
      let tempVar = new Date(nextDate).setHours(0, 0, 0, 0);
      nextDate.setDate(nextDate.getDate() + 1);
      tempVar = new Date(tempVar);
      if (!weekendHolidayPickup) {
        if (
          tempVar.getDay() !== 0 &&
          tempVar.getDay() !== 6 &&
          !(holidayList || []).some((holiday) => new Date(holiday.date).getTime() === tempVar.getTime())
        ) {
          fiveWorkingDays.push(tempVar);
        }
      } else {
        fiveWorkingDays.push(tempVar);
      }
    }
    setWorkingDays(fiveWorkingDays);
  };

  useEffect(() => {
    setFieldValue('bookCollectionDate', workingDays.length > 0 ? workingDays[0] : '');
  }, [workingDays]);

  useEffect(() => {
    !formikValues.bookCollectionDate &&
      setFieldValue('bookCollectionDate', workingDays.length > 0 ? workingDays[0] : '');
  }, [formikValues.bookCollectionDate]);

  useEffect(() => {
    const minTimeing = moment(formikValues.earliestTime, 'HHmmss');
    const maxTimeing = moment(formikValues.latestTime, 'HHmmss');
    if (maxTimeing.isBefore(minTimeing)) setIsError(true);
    else setIsError(false);
  }, [formikValues.latestTime]);

  const sortAlphaNum = (a, b) => a?.companyName?.toLowerCase().localeCompare(b?.companyName?.toLowerCase(), 'en', { numeric: true })

  const getPickupLocation = () => {
    abortOnGoingCallsFor(ServiceEndPoints.getCustomerTradingLocation);
    axiosConfig
      .get(`${ServiceEndPoints.getCustomerTradingLocation}?customerAccountId=${getCustomerAccountID()}`)
      .then((res) => {
        let tradingLocations = res.data.tradingLocations.sort(sortAlphaNum);
        setTradingLocations(tradingLocations);
      })
      .catch((err) => {
        console.log(err);
        setTradingLocations([]);
      });
  };

  const compareAccountStatuswithAtleastone = (userAccounts, status) => {
    return (
      userAccounts.filter(function (account) {
        return status.toString().toLowerCase() === 'active'.toLowerCase()
          ? account?.accountStatus.toString().toLowerCase() !== 'closed'.toLowerCase() &&
          account?.accountStatus.toString().toLowerCase() !== 'onstop'.toLowerCase()
          : account?.accountStatus.toString().toLowerCase() === status.toString().toLowerCase();
      }).length > 0
    );
  };
  const compareAllAccountStatus = (userAccounts, status) => {
    return (
      userAccounts.filter(function (account) {
        return account?.accountStatus.toString().toLowerCase() === status.toString().toLowerCase();
      }).length == userAccounts.length
    );
  };

  const moreThenOnetradingLocationArrayForm = (tradingLocations, userAccounts) => {
    let accountsArray = [];
    let LocationArray = [];
    tradingLocations.map((element, i) => {
      let tt = element?.accounts ?? [];
      let tempArray = userAccounts.filter(({ accountId: _accountId }) =>
        tt.some(({ accountId: accountId }) => accountId === _accountId),
      );
      if (tempArray.length !== 0) {
        if (compareAllAccountStatus(tempArray, 'Active') || compareAccountStatuswithAtleastone(tempArray, 'Active')) {
          element.location_status = 'AllActive';
        } else if (
          !compareAccountStatuswithAtleastone(tempArray, 'Active') &&
          compareAccountStatuswithAtleastone(tempArray, 'OnStop')
        ) {
          element.location_status = 'OnStop';
        } else if (compareAllAccountStatus(tempArray, 'Closed')) {
          element.location_status = 'AllClosed';
        }
        LocationArray.push(element);
        tempArray.map((ele) => {
          accountsArray.push(ele);
        });
      }
    });
    return [accountsArray, LocationArray];
  };

  useEffect(() => {
    let result = tradingLocationArrayForm(tradingLocations, userAccounts);
    let _tradingLocationArray = result[1];
    let matchedUserAccounts = _tradingLocationArray;
    if (matchedUserAccounts.length === 1) {
      let resultofnone = moreThenOnetradingLocationArrayForm(tradingLocations, userAccounts);
      let _accountsArray = resultofnone[0];
      let _locationArray = resultofnone[1];
      let tradingLocationResponseData = (_locationArray || []).map(
        ({ tradingLocationId, location_status, companyName, city, postalCode }) => {
          return {
            name: `${companyName}, ${city}, ${postalCode}`,
            value: tradingLocationId,
            status: location_status,
          };
        },
      );
      setpickUpDropdownData(tradingLocationResponseData);
      if (compareAllAccountStatus(_accountsArray, 'Closed')) {
        setIsHidden(true);
        setIsRequestPickUp(true);
      } else if (compareAccountStatuswithAtleastone(_accountsArray, 'Active')) {
        setIsHidden(true);
        setIsRequestPickUp(false);
        setFieldValue(
          'pickupLocation',
          setDropdownValues(tradingLocationResponseData, 'name', tradingLocationResponseData.tradingLocationId),
        );
      } else if (
        !compareAccountStatuswithAtleastone(_accountsArray, 'Active') &&
        compareAccountStatuswithAtleastone(_accountsArray, 'OnStop')
      ) {
        setIsHidden(false);
        setIsOnStop(true);
        setIsRequestPickUp(true);
        setFieldValue(
          'pickupLocation',
          setDropdownValues(tradingLocationResponseData, 'name', tradingLocationResponseData.tradingLocationId),
        );
        setFieldTouched('pickupLocation', true);
      }
    } else if (matchedUserAccounts.length > 1) {
      let resultofMorethenone = moreThenOnetradingLocationArrayForm(tradingLocations, userAccounts);
      let _accountsArray = resultofMorethenone[0];
      let _locationArray = resultofMorethenone[1];
      let rmClosedLocation = _locationArray.filter((e) => e.location_status !== 'AllClosed');
      let tradingLocationResponseData = (rmClosedLocation || []).map(
        ({ tradingLocationId, location_status, companyName, city, postalCode }) => {
          return {
            name: `${companyName}, ${city}, ${postalCode}`,
            value: tradingLocationId,
            status: location_status,
          };
        },
      );
      setpickUpDropdownData(tradingLocationResponseData);
      setFieldValue(
        'pickupLocation',
        setDropdownValues(tradingLocationResponseData, 'name', tradingLocationResponseData.tradingLocationId),
      );
      setIsHidden(false);
    } else {
      setIsHidden(true);
      setIsRequestPickUp(true);
    }
  }, [tradingLocations]);

  useEffect(() => {
    let _selectedTradingLocation = [];
    if (formikValues.pickupLocation) {
      if (tradingLocations.length > 0) {
        _selectedTradingLocation = tradingLocations.filter(
          (e) => e.tradingLocationId == formikValues.pickupLocation.value,
        );
        setselectedTradingLocation(_selectedTradingLocation);
        let matchedAccountAndLocations = tradingLocationArrayForm(_selectedTradingLocation, userAccounts);
        setOperationalAccount(matchedAccountAndLocations[0]?.[0])
      }
      if (tradingLocations.length > 1) {
        if (formikValues?.pickupLocation?.status == 'OnStop') {
          setIsOnStop(true);
          setIsRequestPickUp(true);
          setFieldTouched('pickupLocation', true);
        } else {
          setIsOnStop(false);
          setIsRequestPickUp(false);
        }
      }
    } else {
      setIsRequestPickUp(true);
      if (pickUpDropdownData.length > 0) {
        setFieldValue(
          'pickupLocation',
          setDropdownValues(pickUpDropdownData, 'name', pickUpDropdownData[0].name),
        );
      }
      else {
        getPickupLocation();
      }
    }
  }, [formikValues.pickupLocation]);

  useEffect(() => {
    if (workingDays.length > 0 && formikValues.bookCollectionDate && tradingLocations.length > 0) {
      abortOnGoingCallsFor(ServiceEndPoints.getPickups);
      axiosConfig
        .get(
          `${ServiceEndPoints.getPickups}?customerAccountId=${getCustomerAccountID()}&startDate=${formatDate(
            formikValues.bookCollectionDate,
          )}&endDate=${formatDate(formikValues.bookCollectionDate)}`,
        )
        .then((res) => {
          if (res.data?.length > 0) {
            let availablePickups = res.data
              .filter(
                (e) => e.pickupType?.toLowerCase() !== 'carriageforward' && e.pickupType?.toLowerCase() !== 'return',
              )
              .filter(
                (val) =>
                  val.status !== null &&
                  val.status?.toLowerCase() !== 'failed' &&
                  val.status?.toLowerCase() !== 'autogeneratedclosed',
              );
            let availableTradingLocations = tradingLocations.filter(
              (location) =>
                location.tradingLocationId === selectedTradingLocation?.[0]?.tradingLocationId &&
                availablePickups.some((pickup) => pickup.tradingLocationId === location.tradingLocationId),
            );
            availableTradingLocations = tradingLocationArrayForm(availableTradingLocations, userAccounts, true)[1];
            if (availableTradingLocations.length > 0) {
              setIsPickUpBooked(true);
              setCurrentBookStatus(true);
            } else {
              setIsPickUpBooked(false);
              setCurrentBookStatus(false);
            }
          } else if (res.data?.length === 0) {
            setIsPickUpBooked(false);
            setCurrentBookStatus(false);
          } else {
            setIsPickUpBooked(false);
            setCurrentBookStatus(false);
          }
        })
        .catch((err) => {
          if (isRequestPickUp) {
            setIsPickUpBooked(false);
            setCurrentBookStatus(true);
          } else {
            setIsPickUpBooked(false);
            setCurrentBookStatus(false);
          }
        })
        .finally(() => {
          if (isRequestPickUp) {
            setCurrentBookStatus(true);
          }
        });
    } else {
      if (tradingLocations.length === 0) {
        setCurrentBookStatus(true);
      }
    }
  }, [formikValues.bookCollectionDate, workingDays, isRequestPickUp, selectedTradingLocation]);

  useEffect(() => {
    if (selectedTradingLocation?.[0]?.postalCode) {
      abortOnGoingCallsFor(ServiceEndPoints.getPostcodeDistrict);
      axiosConfig
        .get(`${ServiceEndPoints.getPostcodeDistrict}?postcode=${selectedTradingLocation[0].postalCode}`)
        .then((res) => {
          setLatestPickup(res?.data?.latestCollection);
          getWorkingDays(
            publicHolidays.filter(
              (holiday) => holiday.region?.toLowerCase() === res?.data?.ukHolidayRegion?.toLowerCase(),
            ),
          );
        })
        .catch((err) => {
          console.log(err);
          getWorkingDays([]);
        });
    }
  }, [selectedTradingLocation]);

  const formatDisplayAddress = (selectedTradingLocation) => {
    let addArray = [];
    selectedTradingLocation[0].address1 && addArray.push(selectedTradingLocation[0].address1);
    selectedTradingLocation[0].address2 && addArray.push(selectedTradingLocation[0].address2);
    selectedTradingLocation[0].address3 && addArray.push(selectedTradingLocation[0].address3);
    selectedTradingLocation[0].city && addArray.push(selectedTradingLocation[0].city);
    selectedTradingLocation[0].postalCode && addArray.push(selectedTradingLocation[0].postalCode);
    selectedTradingLocation[0].state && addArray.push(selectedTradingLocation[0].state);

    return addArray.toString();
  };

  return (
    <React.Fragment>
      <Box boxShadow={3} bgcolor="background.paper" m={1} p={1}>
        <Grid container lg={12} md={12} sm={12}>
          <Grid item lg={6} md={6} sm={6} style={{ paddingRight: '20px' }}>
            <Grid item spacing={3} lg={12} md={12} sm={12}>
              <Grid lg={12} md={12} sm={12} style={{ float: 'left', width: '100%' }} className={classes.mainConatiner}>
                <span className={isPickUpBooked ? 'booked-msg' : 'not-booked-msg'}>
                  {isPickUpBooked ? 'Driver pick-up requested' : 'Driver pick-up not requested'}{' '}
                </span>
                <FormRenderer {...bookCollectionDate.props} workingDays={workingDays} isWorkingDays={true} />
              </Grid>
            </Grid>
            {!isHidden ? (
              <Grid item spacing={3} lg={12} md={12} sm={12}>
                <Grid
                  lg={12}
                  md={12}
                  sm={12}
                  style={{ float: 'left', width: '100%' }}
                  className={`AutoComplete_inputRoot_parent_class ${classes.mainConatiner} ${isOnStop ? ' OnStopDropdown' : ''}`}
                >
                  <FormRenderer {...pickupLocation.props} data={pickUpDropdownData} fieldValue={formikValues.pickupLocation} valuePropertyName="name" isDisabled={isDisabled} fullWidth />
                </Grid>
              </Grid>
            ) : null}
            <Grid item spacing={3} lg={12} md={12} sm={12}>
              <Grid lg={12} md={12} sm={12} className={classes.mainConatiner}>
                <FormRenderer
                  {...earliestTime.props}
                  defaultTime={new Date('2014-08-18T09:00:00')}
                  label="Earliest time"
                  minTime="8:59:59"
                  maxTime="17:05:05"
                  timeInternval={15}
                  errorMessage={errorMessage}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid item spacing={3} lg={12} md={12} sm={12}>
              <Grid lg={12} md={12} sm={12} className={classes.mainConatiner}>
                <FormRenderer
                  {...latestTime.props}
                  defaultTime={new Date('2014-08-18T17:00:00')}
                  label="Latest time"
                  minTime="8:59:59"
                  maxTime="17:05:05"
                  timeInternval={15}
                  errorMessage={errorMessage}
                  isValidationError={isError}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid item spacing={3} lg={12} md={12} sm={12}>
              <Grid lg={12} md={12} sm={12} className={classes.mainConatiner}>
                <FormRenderer {...specialInstructions.props} isMultilined="true" noOfRows="2" fullWidth />
              </Grid>
            </Grid>
          </Grid>
          {!isHidden ? (
            <Grid item lg={5} md={5} sm={5}>
              <Grid item>
                <Grid className={`textAreaGrid`} style={{ marginTop: '94px' }}>
                  {selectedTradingLocation.length > 0 ? (
                    <div>
                      <p style={{ margin: '0px', color: '#c1c1c1' }}>Pick-up address</p>
                      <p style={{ margin: '0px', wordBreak: 'break-all' }}>
                        {formatDisplayAddress(selectedTradingLocation)}
                      </p>
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </React.Fragment>
  );
};

DriverPickUpContainer.propTypes = {
  formField: PropTypes.object,
  formikValues: PropTypes.object,
  setFieldValue: PropTypes.func,
  errors: PropTypes.object,
};

export default DriverPickUpContainer;
