import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, SvgIcon } from '@material-ui/core';
import FormRenderer from '../../../framework/Inputs/formRenderer/formRenderer';
import { useSelector } from 'react-redux';
import { ServicePointError, NoOfRows } from '../../../utils/constants/constants';
import { SearchIcon } from '@material-ui/data-grid';
import { axiosConfig } from '../../../api/axios';
import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';
import CustomModal from '../../../framework/modal/modal';
import CustomDataTable from '../../../framework/CustomDataTable/CustomDataTable';
import CustomDialog from '../../../framework/dialog/customDialog';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
const ParcelHandover = (props) => {
    const {
        formField: { handOverMethod, servicePoint },
        setFieldValue,
        formikValues, errors,
    } = props;

    let userAccounts = useSelector((state) => state.accounts.nonReturnuserAccounts);

    const [servicePoints, setservicePoints] = React.useState([]);
    const [isAddressSelected, setIsAddressSelected] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    //Set the handover method to drivercollection on intial load
    useEffect(() => {
        setFieldValue('handOverMethod', 'driverCollection');
    }, []);

    const isDriverCollection = () => {
        return (props.formikValues || {}).handOverMethod === 'driverCollection';
    };

    useEffect(() => {
        setFieldValue('handOverMethod', 'driverCollection');
    }, [])

    const rowClicked = (val) => {
        props.setServicePointData(val);
        setIsAddressSelected(true);
        const data = `${val.address.address1 || ''} ${val.address.address2 || ''} ${val.address.postalTown || ''}`;
        setFieldValue('servicePoint', data);
        handleModal(false);
    };

    const handleModal = (value) => {
        setOpen(value);
    };

    const handleClose = (value) => {
        setOpen(false);
    };


    const getServicePoints = () => {
        setservicePoints([]);
        props?.loader(true);
        const { servicePoint } = props.formikValues;
        const encodePostcodeURI = encodeURIComponent(servicePoint);
        const url = `${ServiceEndPoints.getServicePoints}?countryCode=GB&PostCode=${encodePostcodeURI}&ServicePointType=parcelshop`;
        axiosConfig
            .get(url)
            .then((res) => {
                setservicePoints(res.data.slice(0, NoOfRows));
                handleModal(true);
            })
            .catch((err) => {
                setservicePoints([]);
                props.setSnackBar({ type: 'error', msg: 'No service point found' });
            })
            .finally(() => {
                props?.loader(false);
            });
    };

    useEffect(() => {
        if (formikValues.handOverMethod === 'servicePoint') {
            setIsAddressSelected(false);
        }
    }, [formikValues.handOverMethod]);

    useEffect(() => {
        if (formikValues.servicePoint === '') {
            setIsAddressSelected(false);
        }
    }, [formikValues.servicePoint]);

    useEffect(() => {
        // setFieldValue('servicePoint', formikValues?.accountNumber?.sender?.postalCode ?? '')
        if (formikValues.handOverMethod === 'driverCollection') {
            setFieldValue('servicePoint', '')
            setIsAddressSelected(false);
        }
    }, [formikValues.handOverMethod, formikValues?.accountNumber]);


    return (
        <Grid className="wd-100">
            <Grid container spacing={2} direction="row">
                <Grid item xs={6} sm={9} md={9} lg={9}>
                    <FormRenderer {...handOverMethod.props} data={handOverOptions} />
                </Grid>
                {isDriverCollection() ? null :
                    (
                        <Grid item xs={6} sm={9} md={12} lg={12}>
                            <FormRenderer
                                isAddressSelected={isAddressSelected}
                                {...servicePoint.props}
                                endArguments={{
                                    endAdornment: (
                                        <IconButton
                                            className="Parcel-Search-Icon-btn"
                                            disabled={errors.servicePoint || isAddressSelected}
                                            onClick={getServicePoints}
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    ),
                                }}
                                fullWidth
                            />
                            <CustomDialog
                                className="Select-service-dialog"
                                open={open}
                                onClose={handleClose}
                                title="Select Service Point"
                            >
                                <CustomDataTable rows={servicePoints} rowClicked={rowClicked} />
                            </CustomDialog>
                        </Grid>
                    )}
            </Grid>
        </Grid>
    );
};

ParcelHandover.propTypes = {
    setFieldValue: PropTypes.func,
    formField: PropTypes.object,
    formikValues: PropTypes.object,
};

const mapStateToProps = (state) => {
    return {
        loadedFrom: state.loadedFrom.loadedFrom,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSnackBar: (data) => {
            dispatch(actions.setAppSnackBarData(data));
        },
        loader: (data) => {
            dispatch(actions.setLoaderLayOver(data));
        },
        setIossLimits: (data) => {
            dispatch(actions.setIossValueLimits(data));
        },
        setServicePointData: (data) => {
            dispatch(actions.setServiceLocationDetails(data));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ParcelHandover);

const handOverOptions = [
    {
        value: 'driverCollection',
        label: 'Driver collection',
    },
    {
        value: 'servicePoint',
        label: 'Drop off at ServicePoint',
    },
];