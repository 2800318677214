import { updateObject } from '../../utils/common';
import * as actionTypes from '../actions/actionsTypes';

const initialState = {
  alertNotification: {
    displayMsg: '',
    severity: '',
    showNotification: false,
  },
  isJSPMInstalled: null,
  printerConfiguration: undefined, //default value until api doesn't give response
  availablePrinter: [],
  commonConfiguration: null,
  snackBarData: {
    isOpen: false,
    type: 'success',
    msg: '',
    inputProps: {
      horizontalPostion: 'right',
      verticalPosition: 'top',
      autoHideDuration: 5000,
    },
  },
  bottomAlert: {
    open: false,
    msgs: [],
    hasMultipleMsgs: null
  },
  activeTab: '',
  returnReferenceCount: 0,
  isReturnReferenceUpdated: false,
  allProducts: [],
  DhlIossNumber: '',
  sortOrderState: '',
  autoReloadProcessOrders: false,
};

const setAppSnackbarData = (state, action) => {
  action.data = { ...action.data, isOpen: true };
  return updateObject(state, { snackBarData: updateObject(initialState.snackBarData, action.data) });
};

const resetAppSnackBar = (state, action) => {
  return updateObject(state, { snackBarData: initialState.snackBarData });
};

const setBottomAlert = (state, action) => {
  action.data = { ...action.data, open: true };
  return updateObject(state, {
    bottomAlert: updateObject(initialState.bottomAlert, {
      ...action.data,
      hasMultipleMsgs: action.data?.hasMultipleMsgs ?? action.data.msgs.length > 1
    }),
  });
};

const resetBottomAlert = (state, action) => {
  return updateObject(state, { bottomAlert: initialState.bottomAlert });
};

const setUserNotification = (state, action) => {
  return updateObject(state, {
    alertNotification: {
      displayMsg: action.data.displayMsg,
      severity: action.data.severity,
      showNotification: action.data.showNotification,
    },
  });
};

const setJSPMStatus = (state, action) => {
  return updateObject(state, {
    isJSPMInstalled: action.data,
  });
};

const setPrinterConfiguration = (state, action) => {
  return updateObject(state, {
    printerConfiguration: { ...state.printerConfiguration, ...action.data },
  });
};

const setAvailablePrinter = (state, action) => {
  return updateObject(state, {
    availablePrinter: action.data,
  });
};

const setGenralConfiguration = (state, action) => {
  return updateObject(state, {
    commonConfiguration: action.data,
  });
};

const setActiveTab = (state, action) => {
  return updateObject(state, {
    activeTab: action.data,
  });
};

const setReturnReferenceCount = (state, action) => {
  return updateObject(state, {
    returnReferenceCount: action.data,
  });
};

const setIsReturnReferenceUpdated = (state, action) => {
  return updateObject(state, {
    isReturnReferenceUpdated: action.data,
  });
};

const resetReturnReferenceParams = (state, action) => {
  return updateObject(state, {
    returnReferenceCount: 0,
    isReturnReferenceUpdated: false,
  });
};

const setAllAvailableProducts = (state, action) => {
  return updateObject(state, {
    allProducts: action.data,
  });
};

const setDhlIossNumber = (state, action) => {
  return updateObject(state, {
    DhlIossNumber: action.data,
  });
}

const setSortOrderState = (state, action) => {
  return updateObject(state, {
    sortOrderState: action.data,
  });
};

const setAutoReloadProcessOrders = (state, action) => {
  return updateObject(state, {
    autoReloadProcessOrders: action.data,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_NOTIFICATION:
      return setUserNotification(state, action);
    case actionTypes.SET_JSPMSTATUS:
      return setJSPMStatus(state, action);
    case actionTypes.SET_PRINTERCONFIGURATION:
      return setPrinterConfiguration(state, action);
    case actionTypes.SET_AVAILABLEPRINTERS:
      return setAvailablePrinter(state, action);
    case actionTypes.SET_COMMONCONFIGURATION:
      return setGenralConfiguration(state, action);
    case actionTypes.SET_APPSNACKBAR:
      return setAppSnackbarData(state, action);
    case actionTypes.RESET_APPSNACKBAR:
      return resetAppSnackBar(state, action);
    case actionTypes.SET_BOTTOM_ALERT:
      return setBottomAlert(state, action);
    case actionTypes.RESET_BOTTOM_ALERT:
      return resetBottomAlert(state, action);
    case actionTypes.SETACTIVETAB:
      return setActiveTab(state, action);
    case actionTypes.SET_RETURN_REFERENCE_COUNT:
      return setReturnReferenceCount(state, action);
    case actionTypes.SET_IS_RETURN_REFERENCE_UPDATED:
      return setIsReturnReferenceUpdated(state, action);
    case actionTypes.RESET_RETURN_REFERENCE_VALUES:
      return resetReturnReferenceParams(state, action);
    case actionTypes.SET_ALL_PRODUCTS:
      return setAllAvailableProducts(state, action);
    case actionTypes.SET_DHL_IOSS_NUMBER:
      return setDhlIossNumber(state, action);
    case actionTypes.SET_SORT_ORDER_STATE:
      return setSortOrderState(state, action);
    case actionTypes.SET_AUTORELOAD_PROCESSORDERS:
      return setAutoReloadProcessOrders(state,action);  
    default:
      return state;
  }
};

export default reducer;
