import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, SvgIcon, Tooltip } from '@material-ui/core';
import DynamicMaterialTable from '../../../framework/DynamicMaterialTable/DynamicMaterialTable';
import { GET } from '../../../api/axios';
import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';
import { convertTZ } from '../../../utils/helperFunctions';
import FormRenderer from '../../../framework/Inputs/formRenderer/formRenderer';
import { SearchIcon } from '@material-ui/data-grid';
import TableExport from '../../../components/TableExport/TableExport';
import ClearIcon from '@material-ui/icons/Clear';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useDispatch } from 'react-redux';
import * as actions from '../../../store/actions/index';
import AuditDetails from '../auditDetails/auditDetails';
import { ReactComponent as InfoIcon } from '../../../assets/images/InfoIconDark.svg';

const ByShipmentNumber = (props) => {
  const {
    formikValues,
    formField: { shipmentNumber },
    setFieldValue,
    errors,
  } = props;
  const headers = [
    { label: 'Shipment number', key: 'artifactNumber' },
    { label: 'User ID', key: 'user' },
    // { label: 'Event name', key: 'artifact' },
    { label: 'Audit date', key: 'logDate' },
    { label: 'Audit time', key: 'logTime' },
    { label: 'Description', key: 'operation' },
  ];

  const tableColumns = [
    { title: 'id', field: 'artifactId', hidden: true },
    {
      title: 'User ID',
      field: 'user',
      sorting: false,
    },
    {
      title: 'Audit date',
      field: 'logDate',
      sorting: true,
      customSort: (a, b) =>
        sortTableData(new Date(`${a?.logDate}`.split('/').reverse().join('-')).getTime(), new Date(`${b?.logDate}`.split('/').reverse().join('-')).getTime()),
    },
    {
      title: 'Audit time',
      field: 'logTime',
      sorting: true,
      customSort: (a, b) =>
        sortTableData(new Date(`01/01/2021 ${a.logTime}`).getTime(), new Date(`01/01/2021 ${b.logTime}`).getTime()),
    },
    {
      title: 'Description',
      field: 'operation',
      sorting: false,
    },
    {
      field: 'operation',
      title: 'Details',
      filtering: false,
      sorting: false,
      render: (row) => {
        return <div>
          {row?.activity === 'amend' && row?.artifactDisplayName === 'Shipment' ?
            <Tooltip
              title="Click to view details"
              arrow
              placement="right"
              onClick={(e) => handleInfoClick(row)}
            >
              <SvgIcon component={InfoIcon} viewBox="0 0 48 48" style={{ fontSize: '2.5rem', borderStyle: 'none' }} />
            </Tooltip> : ''}
        </div>
      },
    }
  ];

  const [tableData, setTableData] = useState([]);
  const [tableDataLength, setTableDataLength] = useState([]);
  const [columns] = useState(tableColumns);
  const [showClearBtn, setShowClearBtn] = useState(false);
  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const [amendInfoTableData, setAmendInfoTableData] = useState([]);
  const [artifactId, setArtifactId] = useState('');
  const dispatch = useDispatch();

  const getTableData = (event = {}) => {
    dispatch(actions.setLoaderLayOver(true));
    event?.stopPropagation?.();
    GET(`${ServiceEndPoints.auditLogs}?shipmentNo=${formikValues.shipmentNumber}`)
      .then((res) => {
        let _temp = _formatTableData(res.data.auditLogs)
        setTableData(_temp);
        setShowClearBtn(true);
        setTimeout(() => {
          dispatch(actions.setLoaderLayOver(false));
        }, 2000);
      })
      .catch((err) => {
        dispatch(actions.setLoaderLayOver(false));
        console.log(err);
      });
  };

  const handleInfoClick = (row) => {
    setTimeout(() => {
      let _temp = _formatInfoTableData(row)
      setAmendInfoTableData(_temp);
      setShowInfoDialog(true);
    }, 0);

  };

  const _formatInfoTableData = (row) => {
    setArtifactId(row.artifactId);
    let finalInfoData = [];
    row?.amendments?.forEach((element) => {
      finalInfoData.push({
        fieldName: element?.fieldName,
        oldValue: element?.oldValue ? element.oldValue : "-",
        newValue: element?.newValue ? element.newValue : "-",
      })
    });
    return finalInfoData;
  }

  const _formatTableData = (_result) => {
    let _finalData = [];
    (_result || []).forEach((_element) => {
      let _convertedDateTime = convertTZ(_element.responseTimeStamp)
      _finalData.push({
        artifactId: _element.artifactId,
        artifactNumber: _element.artifactNumber,
        user: _element.user,
        operation: _element.operation,
        logDate: _convertedDateTime.logDate,
        logTime: _convertedDateTime.logTime,
        artifactDisplayName: _element.artifactDisplayName,
        amendments: _element.amendments,
        activity: _element.activity,
      })
    });
    return _finalData;
  }
  const onDataUpdate = () => {
    try {
      getTableData();
      dispatch(actions.setAppSnackBarData({ msg: `Data exported successfully` }));
    }
    catch (err) {
      console.log(err)
      dispatch(
        actions.setBottomAlert({
          msgs: [
            {
              type: 'error',
              msg: 'Error in exporting data, please try again later',
            },
          ],
        }),
      );
    }
  };

  const sortTableData = (a, b) => {
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    } else {
      return 0;
    }
  };

  const printDocument = () => {
    // const input = document.getElementById('shipmentTable');
    // html2canvas(input)
    //   .then((canvas) => {
    //     let imgWidth = 208;
    //     let imgHeight = canvas.height * imgWidth / canvas.width;
    //     const imgData = canvas.toDataURL('img/png');
    //     const pdf = new jsPDF('p', 'mm', 'a4');
    //     pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
    //     // pdf.output('dataurlnewwindow');
    //     pdf.save("download.pdf");
    //   })
    // ;
    try {
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "landscape"; // portrait or landscape
      const doc = new jsPDF(orientation, unit, size);

      const title = "My Awesome Report";
      const headersName = [headers.map(header => header.label)];
      const data = tableData.map(elt => [elt.artifactNumber, elt.user, elt.logDate, elt.logTime, elt.operation]);

      let content = {
        head: headersName,
        body: data,
        columnStyles: {
          0: {
            columnWidth: 150
          },
          1: {
            columnWidth: 200
          },
          2: {
            columnWidth: 100
          },
          3: {
            columnWidth: 100
          },
          4: {
            columnWidth: 230
          }
        },
        styles: {
          cellWidth: 'wrap',
          fontSize: 12,
          overflowColumns: 'linebreak'
        }
      };

      doc.autoTable(content);
      doc.save("AuditByShipmentNumber.pdf")
      dispatch(actions.setAppSnackBarData({ msg: `Data exported successfully` }));
    }
    catch (err) {
      console.log(err);
      dispatch(
        actions.setBottomAlert({
          msgs: [
            {
              type: 'error',
              msg: 'Error in exporting data, please try again later',
            },
          ],
        }),
      );
    }
  }

  const clearTable = () => {
    setTableData([]);
    setShowClearBtn(false);
    setFieldValue('shipmentNumber', '');
  };
  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Grid container direction="row" justify="space-between">
          <Grid item xs={6} className="search-shipment-box">
            <FormRenderer
              style={{ boxShadow: '0px 3px 6px #00000019' }}
              {...shipmentNumber.props}
              endArguments={{
                endAdornment: (
                  <>
                    {showClearBtn ? (
                      <IconButton type="button" onClick={clearTable}>
                        <ClearIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        type="submit"
                        disabled={(errors || {}).shipmentNumber || !formikValues.shipmentNumber}
                        onClick={getTableData}
                      >
                        <SearchIcon />
                      </IconButton>
                    )}
                  </>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TableExport
              headers={headers}
              data={tableData}
              formikValues={formikValues}
              class_Name="ByShipmentNumber_Export"
              filename={'ByShipmentNumber.csv'}
              onDataUpdate={onDataUpdate}
              setFieldValue={setFieldValue}
              tabIndex={props.tabIndex}
              onClick={printDocument}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item className="Table-column-color" id="shipmentTable">
        <DynamicMaterialTable
          rows={tableData}
          columns={columns}
          isSelectable={false}
          paging={false}
          empty_DataSource_Message="Audit details not found"
          sorting={true}
        />
      </Grid>
      <Grid container xs={12} sm={12} md={12} lg={12}>
        <AuditDetails
          showInfoDialog={showInfoDialog}
          amendInfoTableData={amendInfoTableData}
          artifactId={artifactId}
          setShowInfoDialog={setShowInfoDialog}
        />
      </Grid>
    </Grid>
  );
};

ByShipmentNumber.propTypes = {};

export default ByShipmentNumber;
