import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Modal, Backdrop } from '@material-ui/core';
import './modal.scss';
import Popup from './popup';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    //height:'241px',
   
    // marginTop:142
    margin: 'auto',
  },
}));

const CustomModal = (props) => {
  const classes = useStyles();
  const { onClose, open, modalTitle } = props;

  const handleClose = () => {
    onClose('closed');
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      BackdropProps={{ style: { backgroundColor: 'rgba(255,203,0,1)', opacity: 0.95 } }}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      disableBackdropClick
    >
      <Popup handleClose={handleClose} open={open} title={modalTitle}>
        {props.children}
      </Popup>
    </Modal>
  );
};

CustomModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  children: PropTypes.element,
  modalTitle: PropTypes.string,
};

export default CustomModal;
