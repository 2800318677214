import React from 'react';
import { connect } from 'react-redux';
import { ServiceEndPoints } from '../../../../utils/constants/ApiConstant';
import { withRouter } from 'react-router-dom';
import MySchemaData from './mySchemaData';
import { IconButton, SvgIcon } from '@material-ui/core';
import { ReactComponent as DownloadIcon } from '../../../../assets/images/Download.svg';
import * as actions from '../../../../store/actions/index';
import { axiosConfig } from '../../../../api/axios';
import order from '../../../../assets/files/schema_sample_download_files/order.txt';
import ordersollection from '../../../../assets/files/schema_sample_download_files/order_collection.txt';
import orderscustomscollections from '../../../../assets/files/schema_sample_download_files/order_custom_collection.txt';
import orderscustoms from '../../../../assets/files/schema_sample_download_files/order_customs.txt';
import { customTableSort, getCustomerAccountID } from '../../../../utils/helperFunctions';
class MySchemas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: 'id', field: 'id', hidden: true, width: 0 },
        {
          title: 'Schema name',
          field: 'schemaName',
          width: 150,
          customSort: (a, b) => customTableSort(a.schemaName, b.schemaName),
        },
        {
          title: 'Schema type',
          field: 'schemaType',
          customSort: (a, b) => customTableSort(a.schemaType, b.schemaType),
          width: 150,
        },
        {
          title: 'File polling folder',
          field: 'filePollingFolder',
          sorting: false,
          width: 200,
          cellStyle: {
            whiteSpace: 'normal',
            WordBreak: 'break-all !important',
          },
        },
        {
          title: 'Download sample file',
          headerStyle: {
            textAlign: 'center',
          },
          cellStyle: {
            textAlign: 'center',
          },
          render: (row) => this._My_Schemas(row),
          sorting: false,
          disableClick: true,
          width: 150,
        },
      ],
      rows: [],
      schema_pageDetails: {
        offset: 0,
        limit: 0,
        totalRecordsLength: 0,
      },
      schema_final_data: [],
      schema_totalCount: 0,
      pageSize: 10,
      pageNumber: 0,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.getSchemaResults();
  }

  componentDidUpdate(prevProps) {
    if (this.props.isSearched !== prevProps.isSearched) {
      this.setState({ pageSize: 10, pageNumber: 0 }, () => {
        this.getSchemaResults();
      });
    }
  }

  _My_Schemas = (row) => {
    if (row.schemaType === 'orders+collections') {
      return row.isDefaultSchema ? (
        <IconButton>
          <a href={ordersollection} download={row.schemaType + '.txt'}>
            <SvgIcon style={{ borderStyle: 'none', width: '2em' }} component={DownloadIcon} viewBox="5 5 20 20" />
          </a>
        </IconButton>
      ) : null;
    } else if (row.schemaType === 'order') {
      return row.isDefaultSchema ? (
        <IconButton>
          <a href={order} download={row.schemaType + '.txt'}>
            <SvgIcon style={{ borderStyle: 'none', width: '2em' }} component={DownloadIcon} viewBox="5 5 20 20" />
          </a>
        </IconButton>
      ) : null;
    } else if (row.schemaType === 'orders+customs+collections') {
      return row.isDefaultSchema ? (
        <IconButton>
          <a href={orderscustomscollections} download={row.schemaType + '.txt'}>
            <SvgIcon style={{ borderStyle: 'none', width: '2em' }} component={DownloadIcon} viewBox="5 5 20 20" />
          </a>
        </IconButton>
      ) : null;
    } else if (row.schemaType === 'orders+customs') {
      return row.isDefaultSchema ? (
        <IconButton>
          <a href={orderscustoms} download={row.schemaType + '.txt'}>
            <SvgIcon style={{ borderStyle: 'none', width: '2em' }} component={DownloadIcon} viewBox="5 5 20 20" />
          </a>
        </IconButton>
      ) : null;
    } else {
      return null;
    }
  };
  // _MySchemas = (query) => {
  //   return this.getSchemaResults(query);
  // };

  getSchemaResults = () => {
    this.setState({ isLoading: true });
    // return new Promise((resolve, reject) => {
    //   if (
    //     this.state.schema_pageDetails.offset !== query.page * query.pageSize ||
    //     this.state.schema_pageDetails.limit !== query.pageSize
    //   ) {
    let url;
    setTimeout(() => {
      url = this.getUrl();
      axiosConfig
        .get(url)
        .then((result) => {
          this.setState({ totalRecords: result.data?.totalRecords ?? 0 });
          this.formatSchemaData(result.data);
        })
        .catch((error) => {
          alert('Schema not found');
          this.formatSchemaData({
            orderFileSchemas: [],
            totalRecords: 0,
          });
        })
        .finally(() => this.setState({ isLoading: false }));
    }, 0);
    // }
    // });
  };

  getUrl = (query) => {
    let url = `${ServiceEndPoints.getSchemas}?organization=${getCustomerAccountID()}`;
    url += '&offset=' + this.state.pageNumber * this.state.pageSize;
    url += '&limit=' + this.state.pageSize;
    // setTimeout(() => {
    url += this.props.addressBookSearchParams;
    // }, 0);
    return url;
  };

  formatSchemaData = (result) => {
    let final_data = [];
    ((result || {}).orderFileSchemas || []).map((element, index) => {
      final_data.push({
        id: element.id,
        schemaName: element.schemaName,
        schemaType: element.schemaType,
        filePollingFolder: element.filePollingFolder,
        isDefaultSchema: element.isDefault,
      });
    });
    // resolve({
    //   data: final_data,
    //   page: query.page,
    //   totalCount: result === null ? 0 : result.totalRecords,
    // });
    this.setState({
      schema_final_data: final_data,
      schema_totalCount: result === null ? 0 : result.totalRecords,
    });
  };

  deleteSelectedSchema = (schemaIds) => {
    const itemsToBeDeleted = schemaIds.map((schema) => {
      return schema.id;
    });
    const payload = itemsToBeDeleted;
    axiosConfig
      .post(`${ServiceEndPoints.postSchemas}/delete`, payload)
      .then((response) => {
        this.props.setSnackBar({ msg: 'Schema(s) deleted successfully' });
        this.getSchemaResults();
        // this.props.tableRef.current && this.props.tableRef.current.onQueryChange();
      })
      .catch((error) => {
        alert('Items were not deleted, please try again.');
      });
  };

  onRowClicked = (res) => {
    this.props.dispatch('');
    this.props.history.push(`/configuration/fileConfiguration/SchemaPreview/${res.schemaName}`);
    // this.props.history.push(`/configuration/fileConfiguration/editSchema`,+res.schemaName);
  };

  onPageNumChange(pageNum) {
    if (this.state.pageNumber !== pageNum) {
      this.setState({ pageNumber: pageNum }, () => {
        this.getSchemaResults();
      });
    }
  }

  onPageSizeChange(pageSize) {
    this.setState({ pageSize: pageSize }, () => {
      this.getSchemaResults();
    });
  }

  render() {
    return (
      <MySchemaData
        rows={this.state.schema_final_data}
        columns={this.state.columns}
        onRowClicked={this.onRowClicked}
        tableLayout="fixed"
        deleteSelectedSchema={this.deleteSelectedSchema}
        empty_DataSource_Message="Schema not found"
        // tableRef={this.props.tableRef}
        Dialogtitle="Delete schema"
        fullWidth
        confirmationMessage="The schema will be removed for all the users, are you sure you want to delete it?"
        totalCount={this.state.schema_totalCount}
        pageSize={this.state.pageSize}
        count={this.state.schema_totalCount}
        isLoading={this.state.isLoading}
        inBuiltEvents={{
          onChangeRowsPerPage: (e) => this.onPageSizeChange(e),
          onChangePage: (a) => this.onPageNumChange(a),
        }}
        page={this.state.pageNumber}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    addressBookSearchParams: state.searchParams,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (value) => {
      dispatch(actions.setActiveTab(value));
    },
    setSnackBar: (data) => {
      dispatch(actions.setAppSnackBarData(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MySchemas));
