import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import FormRenderer from '../../../framework/Inputs/formRenderer/formRenderer';

const CollectionDetails = (props) => {
  const {
    formField: { collectionInstructions, descriptionOfGoods },
  } = props;

  return (
    <Grid container direction="row" spacing={3} style={{ width: '100%' }}>
      <Grid item xs={12} sm={6}>
        <FormRenderer {...collectionInstructions.props} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormRenderer {...descriptionOfGoods.props} fullWidth />
      </Grid>
    </Grid>
  );
};

CollectionDetails.propTypes = {};

export default CollectionDetails;
