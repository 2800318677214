import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, SvgIcon } from '@material-ui/core';
import FormRenderer from '../../../../../framework/Inputs/formRenderer/formRenderer';
import InputButton from '../../../../../framework/Inputs/inputButton/inputButton';
import DynamicMaterialTable from '../../../../../framework/DynamicMaterialTable/DynamicMaterialTable';
import { ReactComponent as DeleteIcon } from '../../../../../assets/images/DeleteIcon.svg';

const AddPrinterQueue = (props) => {
  const {
    availablePrinters,
    setFieldTouched,
    setFieldValue,
    formikValues,
    formField,
    arrayHelpers,
    setOldprinterQueueList,
    savePrinterQueues,
    errors,
    setIsFormDirty,
  } = props;

  const getColumns = () => {
    return [
      { title: 'id', field: 'id', hidden: true, width: 0 },
      {
        title: 'Printer queue name',
        field: 'printerQueueName',
        sorting: false,
      },
      {
        title: 'Printer to print on',
        field: 'printerToPrint.name',
        sorting: false,
      },
      {
        title: '',
        render: (row) => (
          <IconButton
            onClick={() => {
              setIsFormDirty(true);
              arrayHelpers.remove(row.tableData?.id);
            }}
          >
            <SvgIcon component={DeleteIcon} viewBox="0 0 48 48" style={{ fontSize: '2.5rem' }} />
          </IconButton>
        ),
        sorting: false,
      },
    ];
  };
  const [columns] = useState(getColumns());

  const addDataToTable = (arrayHelpers) => {
    setIsFormDirty(true);
    const rowDetails = {
      printerQueueName: formikValues.printerQueueName,
      printerToPrint: formikValues.printerToPrint,
    };
    arrayHelpers.push(rowDetails);
    setFieldValue('printerQueueName', '');
    setFieldValue('printerToPrint', availablePrinters[0]);
    setFieldTouched('printerQueueName', false);
    setFieldTouched('printerToPrint', false);
  };

  useEffect(() => {
    setOldprinterQueueList([...formikValues.printerQueueList]);
  }, []);

  useEffect(() => {
    if (!formikValues?.printerToPrint?.value) {
      setFieldValue('printerToPrint', availablePrinters[0]);
    }
  }, [availablePrinters, formikValues?.printerToPrint, setFieldValue]);

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item xs={12}>
        <Grid container direction="row" justify="space-between">
          <Grid item xs={4}>
            <FormRenderer {...formField.printerQueueName.props} fullWidth />
          </Grid>
          <Grid item xs={4}>
            <FormRenderer {...formField.printerToPrint.props} data={availablePrinters} fullWidth />
          </Grid>
          <Grid item xs={2}>
            <InputButton
              variant="contained"
              className="contained-btn"
              buttonType="submit"
              label={'Add'}
              isDisabled={errors.printerQueueName || errors.printerToPrint}
              onClick={() => addDataToTable(arrayHelpers)}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container>
          <Grid item xs={12} className={'Table-column-color'}>
            <DynamicMaterialTable
              rows={formikValues.printerQueueList}
              columns={columns}
              paging={false}
              isSelectable={false}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="row" justify="flex-end">
          <Grid item>
            <InputButton
              label="Ok"
              variant="contained"
              className="contained-btn"
              buttonType="submit"
              onClick={savePrinterQueues}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

AddPrinterQueue.propTypes = {};

export default AddPrinterQueue;
