import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import CustomCaption from '../../../../framework/summaryFormate/CustomCaption';
import CommodityDetailsSummary from './CommodityDetailsSummary';
import { formatDate } from '../../../../utils/helperFunctions';

const useStyles = makeStyles({
  flexContainer: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
  },
  rowDiff: {
    marginTop: '5px',
    marginBottom: '10px',
  },
});

const CustomsDeclarationSummary = ({ shipmentDetails }) => {
  const classes = useStyles();
  const [iossFieldName, setiossFieldName] = useState('');
  const [iossFieldvalue, setiossFieldvalue] = useState('');
  useEffect(() => {
    if (!shipmentDetails.applyIossNumber) {
      setiossFieldName("Shipper's IOSS number");
      setiossFieldvalue('-');
    } else if (shipmentDetails.iossType === 'shippersNo') {
      setiossFieldName("Shipper's IOSS number");
      setiossFieldvalue(shipmentDetails.iossNumber);
    } else if (shipmentDetails.iossType === 'marketNo') {
      setiossFieldName('Marketplace IOSS number');
      setiossFieldvalue(shipmentDetails.marketplaceIossNo);
    } else if (shipmentDetails.iossType === 'dhlIossNo') {
      setiossFieldName('DHL IOSS number');
      setiossFieldvalue(shipmentDetails.iossNumber);
    } else {
      setiossFieldName("Shipper's IOSS number");
      setiossFieldvalue('-');
    }
  }, [shipmentDetails]);
  return (
    <React.Fragment>
      <Grid>
        <Grid item xs={12} lg={12}>
          <Typography m={2} className={classes.rowDiff}>
            {(shipmentDetails || {}).invoiceTypeLabel !== ''
              ? `Invoice type:  ${(shipmentDetails || {}).invoiceTypeLabel}`
              : null}
          </Typography>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={3}
          className={classes.rowDiff}
        >
          <Grid item className={classes.flexContainer}>
            <CustomCaption title="Invoice date" value={formatDate(shipmentDetails.InvoiceDate, '/', 'dd/mm/yy')} />
          </Grid>
          <Grid item className={classes.flexContainer}>
            <CustomCaption title="Invoice no." value={(shipmentDetails || {}).InvoiceNumber} />
          </Grid>
          <Grid item className={classes.flexContainer}>
            <CustomCaption title="Currency" value={(shipmentDetails.customDeclarationCurrency || {}).code} />
          </Grid>
          <Grid item className={classes.flexContainer}>
            <CustomCaption title="Shipping charges" value={shipmentDetails.customDeclarationShippingCharges} />
          </Grid>

          <Grid item className={classes.flexContainer}>
            <CustomCaption title="Shipper’s EORI no." value={shipmentDetails.customDeclarationShippersEoriNo} />
          </Grid>
        </Grid>

        <Grid item xs={12} lg={12} spacing={3} className={classes.rowDiff}>
          <CustomCaption
            m={2}
            className={classes.parcelHandover}
            title="Receiver’s VAT/PID/EORI No."
            value={shipmentDetails.customDeclarationReceiversEoriNo}
          />
        </Grid>
        <CommodityDetailsSummary shipmentDetails={shipmentDetails} className={classes.rowDiff} />
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={3}
          className={classes.rowDiff}
        >
          <Grid item className={classes.flexContainer}>
            <CustomCaption title="Reason for export" value={(shipmentDetails.reasonForExport || {}).description} />
          </Grid>
          <Grid item className={classes.flexContainer}>
            <CustomCaption title="FDA registration no." value={shipmentDetails.FDARegistrationNumber} />
          </Grid>
          <Grid item className={classes.flexContainer}>
            <CustomCaption title="Terms of delivery" value={shipmentDetails.TermsOfDelivery?.label} />
          </Grid>
          <Grid item className={classes.flexContainer}>
            <CustomCaption
              title="Apply IOSS number"
              value={shipmentDetails.applyIossNumber === 'true' ? 'Yes' : 'No'}
            />
          </Grid>
          {shipmentDetails.applyIossNumber === 'true' && (
            <Grid item className={classes.flexContainer}>
              <CustomCaption title={iossFieldName} value={iossFieldvalue} />
            </Grid>
          )}
          {/* <Grid item className={classes.flexContainer}></Grid> */}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CustomsDeclarationSummary;
