import * as actionTypes from '../actions/actionsTypes';

const initialState = '';

const setShipmentManagementSearchParams = (state, action) => {
  return action.data;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SETSHIPMENTMANAGEMENTSEARCHPARAMS:
      return setShipmentManagementSearchParams(state, action);
    default:
      return state;
  }
};

export default reducer;
