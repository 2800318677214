/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Divider, Grid, IconButton, SvgIcon } from '@material-ui/core';
import FormRenderer from '../../../../framework/Inputs/formRenderer/formRenderer';
import PropTypes from 'prop-types';
import './deliveryAddress.scss';
import { removeUndefinedProperties } from '../../../../utils/helperFunctions';
import { ReactComponent as search_address } from '../../../../assets/images/search_address.svg';
import { ReactComponent as enter_manually } from '../../../../assets/images/enter_manually.svg';
import AddressSearch from '../../../../components/helperComponents/addessSearch/addressSearch';
import { useDispatch, connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import AutoSuggestW3W from '../autoSuggestW3W/AutoSuggestW3W';

const DeliveryAddress = (props) => {
  const {
    formField: {
      addressType,
      postalCode,
      recipientName,
      businessName,
      addressLine1,
      addressLine2,
      addressLine3,
      telephone,
      emailAddress,
      town,
      county,
      customerNumber,
    },
    setFieldValue,
    setCustomerNumber,
    isEditAddress,
    setValues,
    formikValues,
    errors,
    setFieldTouched,
    loadedFrom,
    isAmendShipment
  } = props;

  const [isAddressSearch, setAddressSearch] = useState(false);
  const [showError, setShowError] = useState(false);


  const defaultDeliveryAddress = {
    addressType: 'residential',
    postalCode: '',
    recipientName: '',
    businessName: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    telephone: '',
    emailAddress: '',
    searchFor: '',
    town: '',
    county: '',
    customerNumber: isEditAddress ? formikValues?.customerNumber : '',
    deliveryType: 'deliverToDoorStep',
  };

  const internationalLabelChanges = {
    addressLine1: {
      label: 'Street name and house no.',
    },
    county: {
      label: 'County/State',
    },
  };

  const Address_Search = () => {
    isAddressSearch ? setAddressSearch(false) : setAddressSearch(true);
    isAddressSearch && props.setShowAddressFields();
    isAddressSearch && setFieldValue('what3Words','');
    !isAddressSearch && setShowError(false);
  };

  const setAddressValues = (addressObj) => {
    const formObj = {
      town: "",
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      postalCode: "",
      county: "",
      businessName: "",
    };

    if (formikValues?.createPalletShipment !== "yes" || (formikValues?.createPalletShipment === "yes" && addressObj.company)) {
      formObj.town = addressObj.city;
      formObj.addressLine1 = addressObj.line1;
      formObj.addressLine2 = addressObj.line2;
      formObj.addressLine3 = addressObj.line3;
      formObj.postalCode = addressObj.postalCode;
      if(!addressObj.postalCode)
      {
        setAddressSearch(true);
      }
      formObj.county = addressObj.provinceName;
      formObj.businessName = addressObj.company;
    }
    // removeUndefinedProperties(formObj);
    setValues({ ...formikValues, ...formObj });
  };

  const isBusiness = () => {
    return (formikValues || {}).addressType === 'business';
  };

  const isDomestic = () => {
    return ((formikValues || {}).destinationCountry || {}).code === 'GBR';
  };

  useEffect(()=>{
    if(loadedFrom?.loadedFrom === 'fromPreviewSummary'){
      const fromPreviewResponse = loadedFrom?.loadedResponse;
      if(!fromPreviewResponse?.postalCode)
      {
        setAddressSearch(true);
      }
    }
  },[loadedFrom])

  //Make County/State as mandatory field in case of USA.
  React.useEffect(() => {
    
    if(!isDomestic()){
      recipientName.props.label = 'Recipient full name';
    } else {
      recipientName.props.label = 'Recipient name';
    }
 
    const { destinationCountry } = props.formikValues;
    if (destinationCountry?.code === 'USA') {
      county.validation.validations.push({
        type: 'required',
        params: ['County/State is required'],
      });
      county.props.isRequired = true;
    } else {
      county.validation.validations = county.validation.validations.filter((x) => x.type !== 'required');
      county.props.isRequired = false;
    }
    props.updateValidationSchema();
    if (props.formikValues.postalCode) {
      setFieldTouched('postalCode', true);
    }
    if (destinationCountry?.postcodeOptional !== true) {
      postalCode.validation.validations.push({
        type: 'required',
        params: ['Postal code is required'],
      });
    }
    else {
      postalCode.validation.validations = postalCode.validation.validations.filter((x) => x.type !== 'required');
      postalCode.props.isRequired = false;
    }
  }, [props.formikValues.destinationCountry]);

  React.useEffect(() => {
    if (props.formikValues.destinationCountry && props.formikValues.destinationCountry.code !== 'GBR') {
      telephone.props.isRequired = true;
    } else {
      telephone.props.isRequired = false;
    }
  }, [props.formikValues.destinationCountry]);

  const customerNumberBlurred = (eve) => {
    if (setCustomerNumber) {
      setCustomerNumber(eve.target.value);
    }
  };

  // React.useEffect(() => {
  //   const { postalCode } = formikValues;
  //   if (postalCode) {
  //     setFieldValue('deliveryServicePoint', postalCode);
  //     dispatch(actions.setServiceLocationDetails(null));
  //   }
  // }, [formikValues.postalCode]);

  React.useEffect(() => {
    if (!isAddressSearch) {
      setValues({ ...formikValues, ...defaultDeliveryAddress, addressType: formikValues.addressType });
    }
  }, [isAddressSearch]);

  const onBlurEvent = () => {
    if (setCustomerNumber) {
      return { onBlur: (e) => customerNumberBlurred(e) };
    }
    return {};
  };

  React.useEffect(() => {
    if (formikValues.addressType === 'residential') {
      setFieldValue('businessName', '');
    }
  }, [formikValues.addressType]);

  React.useEffect(() => {
    formikValues?.createPalletShipment !== "yes" && setShowError(false);
  }, [formikValues.createPalletShipment]);

  const handleAddressChangeByPostCode = (addressType) => {
    if (addressType) {
      if (addressType === 'Commercial') {
        setFieldValue('addressType', cities[0].value);
        setShowError(false);
      } else if (addressType === 'Residential') {
        if (formikValues?.createPalletShipment === "yes") {
          setShowError(true)
        } else {
          setFieldValue('addressType', cities[1].value);
        }
      }
    }
  };

  //Show all address fields if Error
  useEffect(() => {
    if (props.showAllAddressFields) {
      setAddressSearch(true);
    }
  }, [props.showAllAddressFields]);

  useEffect(() => {
    formikValues?.createPalletShipment === "yes" && setFieldValue("addressType", cities[0].value);
  }, [formikValues?.createPalletShipment]);


  //Check the account status and change email mandatory 
  useEffect(() => {
    if (!isDomestic() || !formikValues?.accountNumber?.configuration?.consigneeEmailOptional) {
      !(emailAddress.validation.validations.find((x) => x.type === 'required')) && emailAddress.validation.validations.push({
        type: 'required',
        params: ['Email address is required'],
      });
      emailAddress.props.isRequired = true;
    }
    else if (isDomestic()) {
      emailAddress.validation.validations = emailAddress.validation.validations.filter((x) => x.type !== 'required');
      emailAddress.props.isRequired = false;
    }
    props.updateValidationSchema();
  }, [formikValues?.accountNumber, formikValues?.destinationCountry]);

  return (
    <div className="wd-100">
      <Grid container spacing={2} direction="row" alignItems="flex-start" justify="space-between">
        <Grid className="delivery-addr" item xs={10} sm={6} md={6}>
          <FormRenderer   {...addressType.props} isDisabled={formikValues?.createPalletShipment === "yes" || isAmendShipment} data={cities} fullWidth />
        </Grid>
        <Grid
          item
          xs={10}
          sm={6}
          md={6}
          direction="column"
          style={{ paddingTop: '16px', paddingRight: '14px', float: 'right', display: 'inline-flex' }}
          alignItems="flex-end"
        >
          {/* {isDomestic() ? ( */}
          <div>
            <IconButton disabled={isAmendShipment} style={{ height: '30px', width: '30px', paddingTop: '20px' }} onClick={Address_Search}>
              {isAddressSearch ? (
                <SvgIcon
                  component={search_address}
                  viewBox="0 0 36 36"
                  style={{ fontSize: '2.5rem', paddingTop: '6px' }}
                />
              ) : (
                <SvgIcon
                  component={enter_manually}
                  viewBox="0 0 36 36"
                  style={{ fontSize: '2.5rem', paddingTop: '2px' }}
                />
              )}
            </IconButton>
            {isAddressSearch ? <span> Search address</span> : <span> Enter address manually</span>}
          </div>
          {/* ) : null} */}
        </Grid>
      </Grid>
      {!isAddressSearch ? (
        // {isDomestic() && !isAddressSearch ? (
        <Grid item xs={12} sm={6} md={6}>
          <Grid container spacing={2} direction="column" style={{ paddingRight: '35px', paddingBottom: '8px' }}>
            <Grid item>
              <Grid container item direction="row" alignItems="flex-start">
                <Grid item xs={12} sm={12} lg={12} className="delivery-Address">
                  <AddressSearch
                    setAddressValues={setAddressValues}
                    addressType={formikValues.addressType}
                    destinationCountrycode2Digit={formikValues?.destinationCountry?.code2Digit}
                    onSelectAddress={handleAddressChangeByPostCode}
                    isDomestic={isDomestic()}
                    showError={showError}
                    isDisabled={isAmendShipment}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
        : null}
      <Grid container item spacing={2} direction="row" alignItems="flex-start" style={{ paddingRight: '10px' }}>
        {!isAddressSearch ? (
          // {isDomestic() && !isAddressSearch ? (
          <Grid item xs={12} sm={6} md={6}>
            <Grid container spacing={2} direction="column" style={{ paddingRight: '20px' }}>
              <Grid item>
                <FormRenderer {...recipientName.props} isRequired={!isBusiness() || !isDomestic()} isDisabled={isAmendShipment} fullWidth />
              </Grid>
              <Grid item>
                <Grid container spacing={2} direction="row" justify="space-around" alignItems="flex-start">
                  <Grid item className="customerDetails" xs={6}>
                    <FormRenderer
                      {...customerNumber.props}
                      isDisabled={isEditAddress || isAmendShipment}
                      {...onBlurEvent()}
                      // onBlur={(event) => customerNumberBlurred(event)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormRenderer {...telephone.props} isDisabled={isAmendShipment} fullWidth />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <FormRenderer {...emailAddress.props} isDisabled={isAmendShipment} fullWidth />
              </Grid>
              {isDomestic() && !isAmendShipment ?<Grid item>
                <AutoSuggestW3W formikValues={formikValues} setFieldValue={setFieldValue} formField={props.formField} isDisabled={isAmendShipment}/>
              </Grid>:""}
            </Grid>
          </Grid>
        ) : isDomestic()
          ? (
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={2} direction="column" style={{ paddingRight: '20px' }}>
                <Grid item>
                  <FormRenderer {...addressLine1.props} fullWidth />
                </Grid>
                <Grid item>
                  <FormRenderer {...addressLine2.props} fullWidth />
                </Grid>
                <Grid item>
                  <FormRenderer {...addressLine3.props} fullWidth />
                </Grid>
                <Grid item>
                  <FormRenderer {...postalCode.props} fullWidth isRequired={formikValues?.destinationCountry?.postcodeOptional === false ? true : false} />
                </Grid>
                <Grid item>
                  <Grid container spacing={2} direction="row" justify="space-around" alignItems="flex-start">
                    <Grid item xs={6}>
                      <FormRenderer {...town.props} data={cities} fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                      <FormRenderer {...county.props} fullWidth />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (!isDomestic() && isAddressSearch) && (
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={2} direction="column" style={{ paddingRight: '20px' }}>
                {isBusiness()
                  ? (
                    <Grid item>
                      <FormRenderer {...businessName.props} fullWidth />
                    </Grid>
                  )
                  : null}
                <Grid item>
                  <FormRenderer {...recipientName.props} isRequired={!isBusiness() || !isDomestic()} fullWidth />
                </Grid>
                <Grid item>
                  <FormRenderer {...addressLine1.props} {...internationalLabelChanges.addressLine1} fullWidth />
                </Grid>
                <Grid item>
                  <FormRenderer {...town.props} fullWidth />
                </Grid>
                <Grid item>
                  <FormRenderer {...county.props} {...internationalLabelChanges.county} fullWidth />
                </Grid>
                <Grid item>
                  <FormRenderer {...postalCode.props} fullWidth isRequired={formikValues?.destinationCountry?.postcodeOptional === false ? true : false} />
                </Grid>
              </Grid>
            </Grid>
          )}
        <Divider orientation="vertical" flexItem className="deliveryAddressDivider" />
        {!isAddressSearch ? (
          // {isDomestic() && !isAddressSearch ? (
          <Grid item md={5} sm={5} xs={12} className="deliveryAddressrightgrid">
            <Grid container spacing={2} direction="column" className="delivery_Address_right_child_grid">
              {isBusiness()
                ? (
                  <Grid item>
                    <FormRenderer {...businessName.props} isDisabled={isAmendShipment} fullWidth />
                  </Grid>
                )
                : null}
              <Grid item>
                <Grid className={`textAreaGrid ${isBusiness() ? '' : ' is_Business_textAreaGrid'}`}>
                  {formikValues && formikValues.addressLine1 && (
                    <p style={{ margin: '0px' }}>{formikValues.addressLine1}</p>
                  )}
                  {formikValues && formikValues.addressLine2 && (
                    <p style={{ margin: '0px' }}>{formikValues.addressLine2}</p>
                  )}
                  {formikValues && formikValues.addressLine3 && (
                    <p style={{ margin: '0px' }}>{formikValues.addressLine3}</p>
                  )}
                  {formikValues && (
                    <p style={{ margin: '0px' }}>
                      {`${formikValues.town ? `${formikValues.town},` : ''}`}
                      {`${formikValues.county ? `${formikValues.county},` : ''}`}
                      {`${formikValues.postalCode ? formikValues.postalCode : ''}`}
                    </p>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : isDomestic() ? (
          <Grid item md={5} sm={5} xs={12} className="deliveryAddressrightgrid">
            <Grid container spacing={2} className="delivery_Address_right_child_grid_two" direction="column">
              <Grid item>
                <FormRenderer {...recipientName.props} isRequired={!isBusiness() || !isDomestic()} fullWidth />
              </Grid>
              {isBusiness()
                ? (
                  <Grid item>
                    <FormRenderer {...businessName.props} isDisabled={isAmendShipment} fullWidth />
                  </Grid>
                )
                : null}
              <Grid item className="customerDetails">
                <FormRenderer
                  {...customerNumber.props}
                  isDisabled={isEditAddress}
                  {...onBlurEvent()}
                  // onBlur={(event) => customerNumberBlurred(event)}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <FormRenderer {...emailAddress.props} fullWidth />
              </Grid>
              <Grid item>
                <FormRenderer {...telephone.props} fullWidth />
              </Grid>
              {!isAmendShipment ?
              <Grid item>
                <AutoSuggestW3W formikValues={formikValues} setFieldValue={setFieldValue} formField={props.formField} isDisabled={isAmendShipment}/> 
              </Grid> : ''}
            </Grid>
          </Grid>
        ) : (
          <Grid item md={5} sm={5} xs={12} className="deliveryAddressrightgrid">
            <Grid container spacing={2} className="delivery_Address_right_child_grid_two" direction="column">
              <Grid item className="customerDetails">
                <FormRenderer
                  {...customerNumber.props}
                  isDisabled={isEditAddress}
                  {...onBlurEvent()}
                  // onBlur={(event) => customerNumberBlurred(event)}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <FormRenderer {...emailAddress.props} fullWidth />
              </Grid>
              <Grid item>
                <FormRenderer {...telephone.props} fullWidth />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

DeliveryAddress.propTypes = {
  formField: PropTypes.object,
  errors: PropTypes.object,
  formikValues: PropTypes.object,
  isEditAddress: PropTypes.bool,
};

DeliveryAddress.defaultProps = {
  isEditAddress: false,
};

const mapStateToProps = (state) => {
  return {
    loadedFrom: state.loadedFrom.loadedFrom,
  };
};

export default connect(mapStateToProps, null)(DeliveryAddress);

const cities = [
  {
    value: 'business',
    label: 'Business',
  },
  {
    value: 'residential',
    label: 'Residential',
  },
];
