import React, { useState } from "react";
import { Form, Formik } from 'formik';
import MarketplaceMapping from "../marketplaceMapping";
import orderFileMappingForm from "../../../../../utils/formConfig/configuration/orderFileMappingForm/orderFileMappingForm";
import { getYupSchemaFromMetaData } from "../../../../../utils/yupSchema/yupSchemaGenerator";

const formikRef = React.createRef();
const { formId, formField } = orderFileMappingForm;

const AdminLevelMarketplaceMapping = ((props) => {

  const [marketPlacesList, setMarketPlacesList] = useState([]);
  const orderFileMappingSchema = getYupSchemaFromMetaData(formField, [], []);
  const initialValues = {
    marketPlacesAvailable: marketPlacesList[0],
  };

  return (
    <React.Fragment>
      <Formik initialValues={initialValues} validationSchema={orderFileMappingSchema} enableReinitialize={true} innerRef={formikRef}>
        {({ values, setFieldValue, setFieldTouched, setErrors, errors }) => (
          <Form id={formId}>
            <MarketplaceMapping
              customerLevelMapping={true}
              values={values}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              setErrors={setErrors}
              errors={errors}
              formField={formField}
              marketPlacesList={marketPlacesList}
              setMarketPlacesList={setMarketPlacesList}
              adminLevelMapping={true}
            />
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
})

export default AdminLevelMarketplaceMapping;