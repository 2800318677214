import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import FormRenderer from '../../../../../framework/Inputs/formRenderer/formRenderer';
import { connect } from 'react-redux';
import { setDropdownValues } from '../../../../../utils/helperFunctions';
import IossDeclartion from '../../../../../components/addShipment/IossDeclaration/iossDeclaration';
import { axiosConfig } from '../../../../../api/axios';
import { ServiceEndPoints } from '../../../../../utils/constants/ApiConstant';
import { useSelector } from 'react-redux';

const AddDetailsForCustomDeclaration = (props) => {
  const {
    formField: { reasonForExport, fdaRegistrationNumber, termsOfDelivery, applyIossNumber},
    setFieldValue,
    isAddNewAddress,
    formikValues,
    fromPreviewSummary,
    isDomestic,
    iossProduct,
    internationalProductList,
    setInternationalProductList,
    reasonsForExport
  } = props;
  const [showIossNumber, setShowIossNumber] = useState(false);
  const [disableIossNumber, setDisableIossNumber] = useState(false);
  const selectedAccount = useSelector((state) => state.accounts.selectedAccount);
  const dutiesPaidList =[];
  internationalProductList?.filter((val)=> (val.deliveryCapabilities.iossApplies === false || (val.deliveryCapabilities.nonIossAllowed === true ))).forEach(ele =>{
    ele.dutiesPaidList.forEach((val)=>{
      dutiesPaidList.push({'name' : val ,'label': val});
    });});
  const iossDutiesPaidList = internationalProductList?.filter((val)=> val.deliveryCapabilities.iossApplies === true).map(ele =>{return {'name' : ele.deliveryCapabilities?.iossDutiesPaid ,'label': ele.deliveryCapabilities?.iossDutiesPaid}} );
  const uniqueDutiesPaidList = dutiesPaidList?.filter((val,index)=> dutiesPaidList.findIndex((ele)=> ele.label===val.label)===index);
  const uniqueIossDutiesPaidList = iossDutiesPaidList?.filter((val,index)=> iossDutiesPaidList.findIndex((ele)=> ele.label===val.label)===index);

  useEffect(() => {
    if(formikValues?.applyIossNumber === 'false' && uniqueDutiesPaidList && !formikValues?.TermsOfDelivery?.name){
       setFieldValue('TermsOfDelivery', setDropdownValues(uniqueDutiesPaidList, 'label', uniqueDutiesPaidList[0]?.label));
      }
    else if(formikValues?.applyIossNumber === 'true' && uniqueIossDutiesPaidList && !formikValues?.TermsOfDelivery?.name){
       setFieldValue('TermsOfDelivery', setDropdownValues(uniqueIossDutiesPaidList, 'label', uniqueIossDutiesPaidList[0]?.label));
      }
  },[uniqueDutiesPaidList, uniqueIossDutiesPaidList, formikValues?.applyIossNumber]);

  useEffect(() => {
    if (formikValues.accountNumber) {
      const accountFdaNumber =
        formikValues.accountNumber?.customsRegistration?.fdaNumber === null ||
        formikValues.accountNumber?.customsRegistration?.fdaNumber === undefined
          ? ''
          : formikValues.accountNumber?.customsRegistration?.fdaNumber;
      // const accountTermofDelivery =
      //   formikValues.accountNumber?.customsRegistration?.termsOfDelivery === null
      //     ? ''
      //     : formikValues.accountNumber?.customsRegistration?.termsOfDelivery;
      // setFieldValue('TermsOfDelivery', accountTermofDelivery);
      setFieldValue('FDARegistrationNumber', accountFdaNumber);
    }
  }, [formikValues.accountNumber, setFieldValue]);

  useEffect(() => {
    // let currentReasonForExport = '';
    // if (formikValues.reasonForExport) {
    //   currentReasonForExport = formikValues.reasonForExport.value;
    // }
    if(!isAddNewAddress){
    setFieldValue('reasonForExport', setDropdownValues(reasonsForExport, 'type',reasonsForExport[0]?.type));
    }
  }, [reasonsForExport]);

  //Method to display/Hide IOSS Fiels
  useEffect(() => {
    if (formikValues.destinationCountry.iossApplies && selectedAccount) {
      if (
        selectedAccount.configuration.iossDHLAccountAllowed === false &&
        !selectedAccount.customsRegistration.iossNumber
      ) {
        if (fromPreviewSummary && fromPreviewSummary.loadedFrom === "dropdownResponse") {
          if (fromPreviewSummary.loadedResponse?.iossNumberAssignedFrom !== undefined &&
            fromPreviewSummary.loadedResponse?.iossNumberAssignedFrom !== null &&
            fromPreviewSummary.loadedResponse?.iossNumberAssignedFrom === 'FILE') {
            setFieldValue('applyIossNumber', 'true');
            setDisableIossNumber(false);
          }
        }
        else if (fromPreviewSummary && fromPreviewSummary.loadedFrom === 'fromPreviewSummary') {
          setFieldValue('applyIossNumber', formikValues.applyIossNumber);
          setDisableIossNumber(false);
        } else {
          setFieldValue('applyIossNumber', formikValues.applyIossNumber ?? 'false');
          setDisableIossNumber(false);
        }
      } else {
        if (fromPreviewSummary && fromPreviewSummary.loadedFrom === 'fromPreviewSummary') {
          setFieldValue('applyIossNumber', formikValues.applyIossNumber);
          setDisableIossNumber(true);
        } else {
          setFieldValue('applyIossNumber', 'true');
          setDisableIossNumber(true);
        }
      }
    }
    if (formikValues.destinationCountry.iossApplies && iossProduct) {
      const commTotal = parseFloat(formikValues.subTotal);
      const maxValue = iossProduct.iossValueLimits.filter(
        (x) => x.currency === formikValues.customDeclarationCurrency?.code,
      );
      if (commTotal !== 0 && commTotal <= maxValue[0]?.maxTotalValue && formikValues.addressType === 'residential') {
        setShowIossNumber(true);
      } else {
        setShowIossNumber(false);
        if (fromPreviewSummary && fromPreviewSummary.loadedFrom === 'fromPreviewSummary') {
          setFieldValue('applyIossNumber', formikValues.applyIossNumber);
        } else {
          setFieldValue('applyIossNumber', 'false');
        }
      }
    } else {
      setFieldValue('applyIossNumber', 'false');
      setShowIossNumber(false);
    }
  }, [
    selectedAccount,
    formikValues.destinationCountry,
    iossProduct,
    formikValues.subTotal,
    formikValues.customDeclarationCurrency,
    formikValues.addressType,
  ]);

  useEffect(() => {
    if (fromPreviewSummary && fromPreviewSummary.loadedFrom === 'fromPreviewSummary') {
         setFieldValue(
            'reasonForExport',
            setDropdownValues(
              reasonsForExport,
              'type',
              (((fromPreviewSummary || {}).loadedResponse || {}).reasonForExport || {}).type,
            ),
          );

      setFieldValue('applyIossNumber', formikValues.applyIossNumber);
    } else if (
      fromPreviewSummary?.loadedFrom === 'dropdownResponse' &&
      fromPreviewSummary?.loadedResponse?.shipments?.[0].customsInvoice
    ) {
      setFieldValue(
        'reasonForExport',
        setDropdownValues(
          reasonsForExport,
          'type',
          fromPreviewSummary?.loadedResponse?.shipments[0].customsInvoice.reasonForExport,
        ),
      );
    }
  }, [fromPreviewSummary, reasonsForExport]);

  // useEffect(()=> {
  //   if(formikValues?.applyIossNumber==='false' && !formikValues?.product?.dutiesPaidList?.includes(formikValues?.TermsOfDelivery?.label)){
  //     for(let i=0;i<uniqueDutiesPaidList?.length;i++){
  //       setTimeout(() => {
  //       if(formikValues?.product?.dutiesPaidList?.includes(uniqueDutiesPaidList[i]?.label)){
  //         setFieldValue('TermsOfDelivery', setDropdownValues(uniqueDutiesPaidList, 'label', uniqueDutiesPaidList[i]?.label));
  //         return;
  //        }
  //       }, 50);
  //     }
  //   }
  //   else if(formikValues?.applyIossNumber==='true' && formikValues?.product?.deliveryCapabilities?.iossDutiesPaid !== formikValues?.TermsOfDelivery?.label){
  //     for(let i=0;i<iossDutiesPaidList.length;i++){
  //       if(iossDutiesPaidList[i]?.label === formikValues?.product?.deliveryCapabilities?.iossDutiesPaid){
  //         setFieldValue('TermsOfDelivery', setDropdownValues(iossDutiesPaidList, 'label', iossDutiesPaidList[i]?.label));
  //         return;
  //       }
  //     }
  //   }
  // },[formikValues?.product, formikValues?.applyIossNumber])

  return (
    <Grid>
      <Grid container spacing={2} direction="row" alignItems="flex-start">
        {!isAddNewAddress ? (
          <React.Fragment>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container spacing={5} direction="row" alignItems="flex-start">
                {showIossNumber && (
                  <Grid item xs={12} sm={6} md={6} lg={6} style={{ paddingRight: '34px' }}>
                    <FormRenderer
                      {...applyIossNumber.props}
                      data={applyIOSSOption}
                      fullWidth
                      isDisabled={disableIossNumber}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={6} lg={6} style={{ paddingRight: '34px' }}>
                  <FormRenderer {...termsOfDelivery.props} data={formikValues.applyIossNumber==='false' ? uniqueDutiesPaidList : uniqueIossDutiesPaidList} hasDefaultValue={true} fullWidth />
                </Grid>
              </Grid>
            </Grid>
            {formikValues.applyIossNumber === 'true' && showIossNumber ? (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <IossDeclartion
                  formField={props.formField}
                  formikValues={props.formikValues}
                  setFieldValue={props.setFieldValue}
                  errors={props.errors}
                  selectedAccount={selectedAccount}
                />
              </Grid>
            ) : null}
          </React.Fragment>
        ) : null}
      </Grid>
    </Grid>
  );
};

AddDetailsForCustomDeclaration.propTypes = {
  formField: PropTypes.object,
};

const applyIOSSOption = [
  {
    value: 'true',
    label: 'Yes',
  },
  {
    value: 'false',
    label: 'No',
  },
];

const mapStateToProps = (state) => {
  return {
    fromPreviewSummary: state.loadedFrom.loadedFrom,
    iossProduct: state.addShipmentData.iossValueLimits,
  };
};

export default connect(mapStateToProps, null)(AddDetailsForCustomDeclaration);
