import React, { useState, useEffect } from 'react'

import { Grid } from '@material-ui/core';


import CustomCaption from './CustomCaption';
import ReadOnlyCheckbox from '../readOnlyCheckbox/ReadOnlyCheckbox';

const ReturnOptionsSummary = ({ data }) => {
    const [state, setstate] = useState(false);
    useEffect(() => {
        setstate((data || {}).returnLabelIncluded)

    }, [data])

    return (
        <Grid container item xs={12} sm={12} lg={12}>
            <Grid item xs={12} lg={3}>
                <ReadOnlyCheckbox label="Inbox return label included" checked={state} />
            </Grid>
            <Grid item xs={12} lg={3}>
                <CustomCaption title="Return shipment no." value={(data || {}).returnShipmentNumber !== null ? (data || {}).returnShipmentNumber : '-'} />
            </Grid>
            <Grid item xs={12} lg={3}>
                <CustomCaption title="Return reference" value={(data || {}).returnReference !== null ? (data || {}).returnReference : '-'} />
            </Grid>
        </Grid>
    )
}

export default ReturnOptionsSummary
