import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../store/actions/index';

const AppSnackBar = () => {
  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };
const dispatch = useDispatch();
const snackBarData = useSelector(state => state.common.snackBarData);

  return (
    <Snackbar
      id="MuiAlert-snackbar-message"
      anchorOrigin={{
        vertical: snackBarData.inputProps.verticalPosition,
        horizontal: snackBarData.inputProps.horizontalPostion,
      }}
      open={snackBarData.isOpen}
      onClose={() => dispatch(actions.resetAppSnackBarData())}
      autoHideDuration={snackBarData.inputProps.autoHideDuration}
    >
      <Alert onClose={() => dispatch(actions.resetAppSnackBarData())} severity={snackBarData.type}>
        {snackBarData.msg}
      </Alert>
    </Snackbar>
  );
};

// AppSnackBar.propTypes = {
//   horizontalPostion: PropTypes.oneOf(['left', 'center', 'right']),
//   verticalPosition: PropTypes.oneOf(['top', 'bottom']),
//   autoHideDuration: PropTypes.number,
//   snackBarMsg: PropTypes.string.isRequired,
//   setSnackBarMsg: PropTypes.func.isRequired,
//   severity: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
// };

// AppSnackBar.defaultProps = {
//   horizontalPostion: 'right',
//   verticalPosition: 'top',
//   autoHideDuration: 3000,
//   severity: 'success',
// };

export default AppSnackBar;
