import React, { useEffect, useState } from "react";
import { Grid, Hidden, makeStyles, SvgIcon } from '@material-ui/core';
import DynamicMaterialTable from '../../../framework/DynamicMaterialTable/DynamicMaterialTable';
import Tooltip from "@material-ui/core/Tooltip";
import { ReactComponent as Ordernotes } from '../../../assets/images/order_notes.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/closeIcon.svg';
import { convertTZwithFormat } from "../../../utils/helperFunctions";

const useStyles = makeStyles((theme) => ({
    customTooltip: {
        backgroundColor: '#fff',
        color: '#000000E6',
        fontSize: '14px',
        width: '700px',
        maxWidth: '700px',
        maxHeight: '250px',
        boxShadow: '10px 8px 5px #00000029',
        padding: '8px 15px',
        borderRadius: '4',
        overflow: 'auto',
    },
    customArrow: {
        color: '#fff',
    },
}));

var currentId = null;

const OrderNotes = (props) => {
    const { shipmentDetails, setOpen } = props;
    var _open = false;
    const [openToolTip, setOpenToolTip] = React.useState(false);

    const handleTooltipClose = () => {
        setOpenToolTip(false);
        _open = false;
    };

    const handleTooltipOpen = () => {
        _open = true;
    }
    const tableColumns = [
        { title: 'id', field: 'artifactId', hidden: true },
        {
            title: 'Date & time',
            field: 'noteDateTime',
            sorting: true,
            render: (row) => {
                return <div><span>{convertTZwithFormat(row?.noteDateTime, 'DD/MM/YYYY')}  {convertTZwithFormat(row?.noteDateTime, 'HH:mm')}</span></div>
            },
            width: 180,
        },
        {
            title: 'Note by',
            field: 'userId',
            sorting: false,
            width: 180,
        },
        {
            title: 'Notes',
            field: 'note',
            sorting: false,

            render: (row) => {
                return <div>{row.note && <Tooltip className='orderNotesTooltipMainDiv' open={_open && (row.tableData.id == currentId)} onClose={handleTooltipClose} classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }} onClick={handleTooltipOpen} disableHoverListener arrow placement="bottom" title={row.note}><span>{`${row.note.slice(0, 100)}...`}</span>
                </Tooltip>}</div>
            },
            width: 600,
        },
    ];
    const [tableData, setTableData] = useState([]);
    const [columns] = useState(tableColumns);
    const classes = useStyles();

    useEffect(() => {
        getTableData();
    }, [])

    const onRowClicked = (res) => {
        if (res.tableData.id == currentId) {
            currentId = null;
            _open = false;
        }
        else {
            currentId = res.tableData.id;
        }
        setOpenToolTip(!openToolTip);
    };

    const getTableData = (event = {}) => {
        let _temp = _formatTableData(shipmentDetails)
        setTableData(_temp);
    };

    const _formatTableData = (_result) => {
        let _finalData = [];
        (_result)?.notes?.forEach((_element) => {
            _finalData.push(
                {
                    note: _element.note,
                    noteDateTime: _element.noteDateTime,
                    userId: _element.userId,
                })
        });
        return _finalData;
    }

    return (
        <Grid container direction="row" className="orderNotesDrawer" spacing={0} xs={12} sm={12} md={12} lg={12}>
            <Grid item xs={12} sm={12} md={12} lg={12} className="OrderNotesHeader" >
                <div>
                    <SvgIcon className={'OrderNotesIcon'} component={Ordernotes} />
                    Shipment notes
                    <CloseIcon className={'OrderNotesCloseBtn'} component={CloseIcon} onClick={() => setOpen(false)} />
                </div>
            </Grid>

            <Grid item className="Table-column-color orderNotesTable" id="shipmentTable" xs={12} sm={12} md={12} lg={12} >

                <DynamicMaterialTable
                    rows={tableData}
                    columns={columns}
                    onRowClicked={row => { onRowClicked(row) }}
                    isSelectable={false}
                    paging={false}
                    sorting={true}
                    customMaxHeight={'400px'}
                />
            </Grid>
        </Grid>
    );
}

OrderNotes.propTypes = {};

export default OrderNotes;