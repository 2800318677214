import React, { useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './deliveryPhotographs.scss';
import  DisplayPhotographs  from './displayPhotographs';

const DeliveryPhotographs = (props) => {
  const { shipmentDetails } = props
  const [trackingImages, setTrackingImages] = useState([])

  useEffect(() => {
    if (shipmentDetails && shipmentDetails.shipmentEventModels) {
      const eventImages = []
      shipmentDetails.shipmentEventModels.forEach((shipEvent, i) => {
        if (shipEvent.images && shipEvent.images.length > 0) {
          shipEvent.images.forEach((img, index) => {
            if (img.imageType === 'photo') {
              const item = {
                eventId: shipEvent.shipmentEvent.code,
                imageBase64: atob(img.imageBase64),
                imageAltText: img.imageType
              }
              eventImages.push(item)
            }
          })
        }
      })
      setTrackingImages(eventImages)
    }
  }, [shipmentDetails])

  return (
    <>
     <Grid  container item spacing={2} direction="row" alignItems="flex-start">
        <Grid item  xs={12} sm={12} md={12} lg={12} className="photos-heading" >
          <span > 
              {trackingImages.length > 0
? `Delivery photographs (${trackingImages.length})`
                : `Delivery photographs`}
          </span>
        </Grid>
        <Grid item  xs={12} sm={12} md={12} lg={12}>
          {trackingImages.length > 0
          ? <DisplayPhotographs
            shipmentDetails = {trackingImages}
          />
          :          <>
           <Grid className="customer-photos" style={{height:'200px', width:'500px'}}  xs={12} sm={12} md={12} lg={12}>
             <p style={{fontSize:'16px'}}>No image captured yet!</p>
            <p style={{fontSize:'14px', margin:'0'}} >When delivered,your parcel</p>
             <p style={{fontSize:'14px', margin:'0'}} >images will come here</p> 
           </Grid>  
          </>
      }
        </Grid>
      </Grid>
      
    </>
  );
};

DeliveryPhotographs.propTypes = {shipmentDetails:PropTypes.object};

export default DeliveryPhotographs;
