import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import FormRenderer from '../../../framework/Inputs/formRenderer/formRenderer';
import Grid from '@material-ui/core/Grid';
import InputButton from '../../../framework/Inputs/inputButton/inputButton';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import * as actions from '../../../store/actions/index';
import CustomizedDialogs from '../addressBookModal/addressBookModal';
import AddressBookModalContent from '../AddressBookModalContent/AddressBookModalContent';

const useStyles = makeStyles((theme) => ({
  SearchAddressField: {
    marginTop: '10px',
    paddingRight: '13px',
  },
}));

const SearchAddress = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();
  const inputRef = React.useRef();
  const {
    formField: { addressSearchFor, favoriteSearchFor },
    setFieldValue,
    formikValues,
    setValues,
    tabIndex,
    searchByOptions,
    errors,
    setFieldTouched,
    // addressImportTypeOptions,
  } = props;

  let [isClearBtn, setIsClearBtn] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [addressBookSearchForFlag, setAddressBookSearchForFlag] =React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    // setFieldValue('addressImportType', addressImportTypeOptions[0]);
    setOpen(false);
  };

  const getClear = (event = null, queryParams = '') => {
    if (event) {
      event.preventDefault();
    }
    setIsClearBtn(false);
    setFieldValue('favoriteSearchFor', '');
    setFieldValue('addressSearchFor', '');
    setFieldTouched('addressSearchFor', false);
    setFieldTouched('favoriteSearchFor', false);
    dispatch(actions.setAddressBookSearchParams(''));
    props.onAddressChange(false);
  };

  const getAddresses = (event = null) => {
    setAddressBookSearchForFlag(true);
    if (event) {
      event.preventDefault();
    }
    props.onAddressChange(true);
    setIsClearBtn(true);
  };
  const onAddNewButtonClick = () => {
    history.push('/addressBook/Addnew');
  };

  useEffect(() => {
    if (tabIndex === 0) {
      const encodedAddressSearchFor = encodeURIComponent(props.formikValues?.addressSearchFor);
      dispatch(
        actions.setAddressBookSearchParams(`&${props.formikValues.addressSearchBy.value}=${encodedAddressSearchFor}`),
      );
    } else {
      const encodedFavoriteSearchFor = encodeURIComponent(props.formikValues?.favoriteSearchFor);
      dispatch(
        actions.setAddressBookSearchParams(`&${props.formikValues.favoriteSearchBy.value}=${encodedFavoriteSearchFor}`),
      );
    }
  }, [dispatch, props.formikValues, tabIndex]);

  useEffect(() => {
  setValues({
    addressSearchFor: '',
    favoriteSearchFor:"",
    addressSearchBy: searchByOptions[0],
    favoriteSearchBy: searchByOptions[0],
  });
  setFieldTouched('shipmentManagementSearchFor', false);
  }, [tabIndex]);


  // useEffect(() => {
  //   setFieldValue('addressSearchFor', '');
  //   setIsClearBtn(false);
  // }, [tabIndex, setFieldValue]);

  useEffect(() => {
    setIsClearBtn(false);
  }, [props.formikValues.addressSearchFor]);

  useEffect(() => {
    setIsClearBtn(false);
  }, [props.formikValues.favoriteSearchFor]);

  useEffect(() => {
    setFieldValue('addressSearchFor', '');
    setFieldTouched('addressSearchFor', true);
    inputRef.current.focus();
    if(props.formikValues.addressSearchFor || addressBookSearchForFlag)
    {
    getClear();
    setAddressBookSearchForFlag(false);
    }
  }, [props.formikValues.addressSearchBy]);

  useEffect(() => {
    setFieldValue('favoriteSearchFor', '');
    setFieldTouched('favoriteSearchFor', true);
    inputRef.current.focus();
    if(props.formikValues.favoriteSearchFor || addressBookSearchForFlag){
    getClear();
    setAddressBookSearchForFlag(false);
    }
  }, [props.formikValues.favoriteSearchBy]);

  return (
    <div className={classes.SearchAddressField}>
      <Grid container direction="row" justify="flex-end" alignItems="flex-start" spacing={3}>
        <Grid item xs={12} sm={10} lg={9} md={9}>
          {tabIndex === 0 ? (
            <FormRenderer
              data={searchByOptions || []}
              onSearchClick={(event) => getAddresses(event, '')}
              onClearSearch={(event) => getClear(event, '')}
              isIconDisabled={errors.addressSearchFor || formikValues.addressSearchFor?.trim() === ''}
              selectProps={addressSearchFor.selectProps.props}
              textProps={addressSearchFor.textProps.props}
              isClearBtn={isClearBtn}
              inputRef={inputRef}
              {...addressSearchFor.props}
            />
          ) : (
            <FormRenderer
              data={searchByOptions || []}
              onSearchClick={(event) => getAddresses(event, '')}
              onClearSearch={(event) => getClear(event, '')}
              isIconDisabled={errors.favoriteSearchFor}
              selectProps={favoriteSearchFor.selectProps.props}
              textProps={favoriteSearchFor.textProps.props}
              isClearBtn={isClearBtn}
              inputRef={inputRef}
              {...favoriteSearchFor.props}
            />
          )}
        </Grid>
        {tabIndex ? null : (
          <Grid item xs={12} sm={2} lg={3} md={3}>
            <InputButton variant="outlined" className="outlined-btn" label="Add new" onClick={handleClickOpen} />
          </Grid>
        )}
        <Grid container>
          <CustomizedDialogs
            open={open}
            onClose={handleClose}
            onAddNewButtonClick={onAddNewButtonClick}
            title="Add new address book entry"
          >
            <AddressBookModalContent setFieldValue={setFieldValue} onClose={handleClose} totalRecords={props.totalRecords} {...props} />
          </CustomizedDialogs>
        </Grid>
      </Grid>
    </div>
  );
};

SearchAddress.propTypes = {
  formField: PropTypes.object,
  formikValues: PropTypes.object,
  onAddressChange: PropTypes.func,
  totalRecords: PropTypes.string,
  setFieldValue: PropTypes.func,
  tabIndex: PropTypes.object,
  setFieldTouched: PropTypes.func,
};

export default SearchAddress;
