const userManagementForm = {
  formId: 'userManagement',
  formField: {
    userName: {
      props: {
        name: 'userName',
        type: 'text',
        label: 'User name',
        isRequired: true,
        inputProps: {
          maxLength: 35
        }
      },
      validation: {
        name: 'userName',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['User name is required'],
          },
          {
            type: 'matches',
            params: [/^(?!\s+$).*/,'User name is required'],
          },
        ],
      },
    },
    userId: {
      props: {
        name: 'userId',
        type: 'text',
        label: 'Id',


      },

    },
    emailId: {
      props: {
        name: 'emailId',
        type: 'text',
        label: 'Email Id',
        isRequired: true,

      },
      validation: {
        name: 'emailId',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Email is required'],
          },

          {
            type: 'matches',
            params: [/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Invalid email'],
          },
        ],
      },
    },

    role: {
      props: {
        name: 'role',
        type: 'select',
        label: 'Role',
        displayField: 'label',
        isRequired: true,
        hasDefaultValue: true
      },
      value: [],
      validation: {
        validationType: 'object',
        validations: [
          {
            type: 'required',
            params: ['Role is required'],
          },
        ],
      },
    },


    accounts: {
      props: {
        name: 'accounts',
        type: 'multiSelect',
        label: 'Accounts',
        displayField: 'accountId',
        isRequired: true,
        hasDefaultValue: false,
      },
      value: [],
      validation: {
        name: 'accounts',
        validationType: 'array',
        validations: [
          {
            type: 'required',
            params: ['Accounts selection is required'],
          },
        ],
      },
    },
    isActive: {
      props: {
        name: 'isActive',
        label: 'Is active',
        type: 'checkbox',
      },
      value: false,
    },
    selectAccount: {
      props: {
        name: 'selectAccount',
        label: 'Select account',
        type: 'select',
        displayField: 'name',
        isRequired: true,
        value: '0',
      },
      validation: {
        name: 'selectAccount',
        validationType: 'object',
        validations: [
          // {
          //   type: 'required',
          //   params: ['Select account is required'],
          // },
        ],
      },
    },
    setupSearchBy: {
      props: {
        name: 'setupSearchFor',
        type: 'searchControl',
      },
      selectProps: {
        props: {
          name: 'setupSearchBy',
          label: 'Search For',
          placeholder: "Enter User's Name",
        },
      },
      textProps: {
        props: {
          name: 'setupSearchFor',
          label: 'Search For',
          placeholder: "Enter User's Name",
        },
      },
      validation: {
        name: 'setupSearchFor',
        validationType: 'string',
        validations: [
          // {
          //   type: 'required',
          //   params: ['SearchFor is required'],
          // },
          {
            type: 'min',
            params: [1, 'SearchFor length must be greater than 0'],
          },
        ],
      },
    },
  },
};

export default userManagementForm;
