import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import DynamicMaterialTable from '../../../../framework/DynamicMaterialTable/DynamicMaterialTable';
import InputButton from '../../../../framework/Inputs/inputButton/inputButton';
import ConfirmationDialog from '../../../../components/addressBook/confirmation/confirmation';
import CustomModal from '../../../../framework/dialog/customDialog';
export default function MySchemaData(props) {
    const {
        rows,
        columns,
        isSearchable,
        isSelectable,
        sorting,
        paging,
        pageSize,
        pageSizeOptions,
        Dialogtitle,
        confirmationMessage,
        tableLayout
    } = props;
    const [selectedRows, setSelectedRows] = useState([]);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [confirmationMsg, setConfirmationMsg] = useState(confirmationMessage);

    const addSelectedRows = (selectedRows) => {
        setSelectedRows(selectedRows);
    };
    const handleDialogClose = () => {
        setShowConfirmationDialog(false);
    };
    const onDialogConfirm = () => {
        props.deleteSelectedSchema(selectedRows);
        setShowConfirmationDialog(false);
        setSelectedRows([]);
    };
    const onDialogCancel = () => {
        setShowConfirmationDialog(false);
    };

    return (
        <Grid container justify="flex-end" alignItems="flex-start" className="address-Book-Table">
            <Grid item lg={12} md={8} xs={8} className="address-Book-Table">
                <DynamicMaterialTable
                    rows={rows}
                    columns={columns}
                    isSelectable={isSelectable}
                    isSearchable={isSearchable}
                    sorting={sorting}
                    paging={paging}
                    pageSize={pageSize}
                    tableLayout = {tableLayout}
                    pageSizeOptions={pageSizeOptions}
                    onSelectionChange={addSelectedRows}
                    {...props}
                />
            </Grid>

            <Grid container item className="customModal-book">
                <CustomModal open={showConfirmationDialog} title={Dialogtitle} onClose={handleDialogClose} maxWidth="sm">
                    <ConfirmationDialog
                        confirmationMessage={confirmationMsg}
                        onConfirm={onDialogConfirm}
                        OnCancel={onDialogCancel}
                    />
                </CustomModal>
            </Grid>
            <Grid
                item
                container
                lg={4}
                md={4}
                xs={4}
                justify="flex-end"
                alignItems="flex-end"
                style={{ paddingTop: '10px', display: 'grid' }}
            >
                <Grid item lg={6} md={6} xs={6}>
                    <InputButton
                        isDisabled={selectedRows.length === 0}
                        className="outlined-btn Delete-AddressBook"
                        label="Delete"
                        onClick={() => setShowConfirmationDialog(true)}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}

MySchemaData.propTypes = {
    rows: PropTypes.array,
    columns: PropTypes.array,
    isSearchable: PropTypes.bool,
    isSelectable: PropTypes.bool,
    sorting: PropTypes.bool,
    paging: PropTypes.bool,
    pageSize: PropTypes.object,
    pageSizeOptions: PropTypes.object,
    title: PropTypes.string,
    confirmationMessage: PropTypes.string,
};
