import React from 'react';
import InputTextField from '../inputTextField/inputTextField';
import InputSelect from '../inputSelect/inputSelect';
import InputCheckbox from '../inputCheckbox/inputCheckbox';
import InputRadio from '../inputRadio/inputRadio';
import InputDate from '../inputDate/inputDate';
import SearchControl from '../../searchControl/searchControl';
import MultipleSelect from '../../multipleSelect/multipleSelect';
import InputTime from '../inputTime/inputTime';
import InputAutoComplete from '../inputAutoComplete/inputAutoComplete';
import RichTextEditor from '../richTextEditor/richTextEditor';
import MultipleValues from '../multipleValues/multipleValues';

const FormRenderer = (props) => {
  const builder = (configType) => {
    switch (configType) {
      case 'text':
      case 'textarea':
      case 'input':
      case 'number':
        return <InputTextField {...props} />;
      case 'select':
        return <InputSelect {...props} />;
      case 'checkbox':
        return <InputCheckbox {...props} />;
      case 'radio':
        return <InputRadio {...props} />;
      case 'date':
        return <InputDate {...props} />;
      case 'time':
        return <InputTime {...props} />;
      case 'searchControl':
        return <SearchControl {...props} />;
      case 'multiSelect':
        return <MultipleSelect {...props} />;
      case 'autoComplete':
        return <InputAutoComplete {...props} />;
      case 'richText':
        return <RichTextEditor {...props} />;
      case 'multipleValues':
        return <MultipleValues {...props} />;
      default:
        return <div>Unsupported field type {configType}</div>;
    }
  };
  return builder(props.type);
};

FormRenderer.propTypes = {};

export default FormRenderer;
