import React, { useState, useRef, useEffect } from 'react';
import { Grid, Paper, Tab, Tabs, IconButton, Tooltip, SvgIcon, makeStyles } from '@material-ui/core';

import TabPanel from '../../components/shared/tabPanel';

import { Form, Formik } from 'formik';
import './accounts.scss';
import { getYupSchemaFromMetaData } from '../../utils/yupSchema/yupSchemaGenerator';
import { AccountFromDetails } from './AccountFromDetails';
import ConfigureReturnLabel from '../../components/accounts/configureReturnLabel/configureReturnLabel';
import AccountForm from '../../utils/formConfig/accounts/accountsForm';
import { connect } from 'react-redux';
import { sortBasedOnNestedOrNot } from '../../utils/helperFunctions';
import { ReactComponent as InfoIcon } from '../../assets/images/InfoIcon.svg';

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: '#fff',
    color: '#000000E6',
    fontSize: '14px',
    boxShadow: '0px 3px 6px #00000029',
    padding: '8px 15px',
    borderRadius: '0'
  },
  customArrow: {
    color: '#fff',
  }
}));

const Accounts = ({ userAccounts }) => {
  const classes = useStyles();
  const { formId, formField } = AccountForm;
  const formikRef = useRef(null);
  const newMaintainAccountSchema = getYupSchemaFromMetaData(formField, [], []);

  const initialValues = {};
  const [tabIndex, setTabIndex] = useState(0);
  const [accounts, setAccounts] = useState([]);
  const [returnAccounts, setReturnAccounts] = useState([]);

  for (let property in formField) {
    if (Object.prototype.hasOwnProperty.call(formField, property)) {
      const field = formField[property];
      initialValues[field.props.name] = field.value ?? '';
    }
  }

  const onTabChange = (event, index) => {
    setTabIndex(index);
  };

  const sortAlphaNum = (a, b) => a?.accountId?.toLowerCase().localeCompare(b?.accountId?.toLowerCase(), 'en', { numeric: true })

  useEffect(() => {
    const sortedArray = userAccounts.sort(sortAlphaNum);
    setAccounts(sortedArray);
    const returnAccountsArray = sortedArray.filter(retAcc => retAcc?.returnConfiguration?.inboxReturnAllowed === true)
    setReturnAccounts(returnAccountsArray)
  }, [userAccounts]);

  return (
    <div className="accounts-container">
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={newMaintainAccountSchema}
        innerRef={formikRef}
      >
        {({ values, setFieldValue, setValues, errors, setFieldTouched, isValid, resetForm }) => (
          <Form id={formId}>
            <Paper className="paper-container">
              <Grid container className="OrderFileConfiguration-container" direction="row" spacing={1}>
                <Grid item xs={9} sm={9} md={9} lg={9}>
                  <Tabs value={tabIndex} onChange={onTabChange} className="OrderFileConfiguration-tabs-container">
                    <Tab label="Accounts" />
                    <Tab
                      disabled={!values.selectAccount?.returnConfiguration?.inboxReturnAllowed}
                      label="Configure return label"
                    />
                  </Tabs>
                </Grid>
                {tabIndex === 1 ? (
                  <Grid item xs={3} md={3} sm={3} lg={3} justify="flex-end">
                    <Grid container justify="flex-end" alignItems="center" className="return-config">
                      <IconButton>
                        <Tooltip classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }}
                          title={
                            <React.Fragment>
                              <div>
                                Ship platform can print an additional label containing instructions for your customer on
                                what to do to return their shipment.
                              </div>
                              <br />
                              <div>
                                Additionally you can upload your company logo and also include specific customized
                                return instructions.
                              </div>
                            </React.Fragment>
                          }
                          arrow
                          placement="left"
                        >
                          <SvgIcon fontSize="small" component={InfoIcon} />
                        </Tooltip>
                      </IconButton>
                      <i>What is return configuration?</i>
                    </Grid>
                  </Grid>
                ) : null}

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TabPanel value={tabIndex} index={0}>
                    <AccountFromDetails
                      formField={formField}
                      formikValues={values}
                      setFieldValue={setFieldValue}
                      accounts={accounts}
                    />
                  </TabPanel>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TabPanel value={tabIndex} index={1}>
                    <ConfigureReturnLabel
                      formField={formField}
                      formikValues={values}
                      setFieldValue={setFieldValue}
                      accounts={returnAccounts}
                      setValues={setValues}
                      setFieldTouched={setFieldTouched}
                      isValid={isValid}
                    />
                  </TabPanel>
                </Grid>
              </Grid>
            </Paper>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userAccounts: (((state || {}).auth || {}).userDetails || {}).accounts,
  };
};

export default connect(mapStateToProps, null)(Accounts);
