import React from 'react';
import { Grid, IconButton, SvgIcon } from '@material-ui/core';

import { ReactComponent as CheckIcon } from '../../assets/images/add.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';

const DeliveryButtonSet = (props) => {
  return (
    <Grid container justify="flex-end">
      <Grid item>
        <IconButton onClick={() => props.onCancel()} disabled={props.isDisabled}>
          <SvgIcon component={CloseIcon} viewBox="0 0 30 30" />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton onClick={() => props.onSubmit()} disabled={props.isDisabled}>
          <SvgIcon component={CheckIcon} viewBox="0 0 30 30" />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default DeliveryButtonSet;
