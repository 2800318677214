import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
const useStyles = makeStyles({
  table: {
    minWidth: 700,
    cursor: 'pointer',
  },
  tableRow: {
    '&$hover:hover': {
      backgroundColor: '#FFEEA9',
    },
  },
  hover: {},
});

const renderText = (value) => {
  return value === null || value === undefined ? '' : value;
};

const CustomAddressTable = (props) => {
  const { rows, rowClicked } = props;
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table aria-label="spanning table" className={classes.table}>
        <TableBody>
          <List>
            {(rows || []).map((row, index) => (
              <ListItem button onClick={() => rowClicked(row)}>
                <TableRow
                  hover
                  classes={{ hover: classes.hover }}
                  className={classes.tableRow}
                  key={row.id}
                  onClick={() => rowClicked(row)}
                >
                  <TableCell>
                    <TableRow className="dialogPopData">
                      {props.isPostalSearch ? (
                        <span>
                          {' '}
                          {row.text} , {row.description}
                        </span>
                      ) : (
                        <span>
                          {row.deliveryAddress?.customerNumber ?? ''},{' '}
                          {row.deliveryAddress.recipientName || row.deliveryAddress.businessName},{' '}
                          {row.deliveryAddress.line1},{' '}
                          {row.deliveryAddress.line2 ? `${row.deliveryAddress.line2},` : ''}{' '}
                          {row.deliveryAddress.line3 ? `${row.deliveryAddress.line3},` : ''}{' '}
                          {renderText(row.deliveryAddress.town).toUpperCase()},{' '}
                          {renderText(row.deliveryAddress.county).toUpperCase()}, {row.deliveryAddress.postCode}
                        </span>
                      )}
                    </TableRow>
                  </TableCell>
                </TableRow>
              </ListItem>
            ))}
          </List>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomAddressTable;
