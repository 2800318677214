import React, { useState, useEffect } from 'react';

import Spinner from '../../framework/spinner/spinner';
import ShowLocationDetails from './showLocationDetails';

import { ServiceEndPoints } from '../../utils/constants/ApiConstant';
import { GET } from '../../api/axios';
import { useDispatch } from 'react-redux';
import  * as actions from '../../store/actions/index';

const DeliverToDepot = (props) => {
  const [servicePoints, setServicePoints] = useState(null);
const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.setLoaderLayOver(true))
    const servicePoint = props.shipmentDetails.consigneeAddress.postalCode;
    let url = '';
    const encodePostcodeURI = encodeURIComponent(servicePoint);
    // url = `${ServiceEndPoints.getServicePoints}?countryCode=GB&PostCode=${encodePostcodeURI}&ServicePointType=parcelshop`;
    url = `${ServiceEndPoints.getLocations}/depots?postcode=${encodePostcodeURI}`;
    GET(url).then((res) => {
      setServicePoints([res.data]);
      dispatch(actions.setLoaderLayOver(false))
    })
.catch(err => {
      console.log(err);
      dispatch(actions.setLoaderLayOver(false))
    });
  }, []);

  return servicePoints ? <ShowLocationDetails rows={servicePoints} rowClicked={props.rowClicked} isServicePoint={false} selectedID={props.selectedID}/> : null;
};

export default DeliverToDepot;
