import React from 'react';
import { Typography } from '@material-ui/core';

const CustomCaption = ({ title, value }) => {
  return (
    <>
      <Typography variant="caption" display="block" style={{ color: '#707070', fontSize: '12px' }}>
        {title}
      </Typography>
      <Typography variant="body2" style={{ color: '#000000E6', fontSize: '16px' }}>
        {value ? value : '-'}
      </Typography>
    </>
  );
};

export default CustomCaption;
