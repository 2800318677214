import React from "react";
import "./dividerWithText.scss";


 const DividerWithText = ({ children }) => {
  return (
      
    <div className="bordercontainer">
      <div className="border" />
      <span className="content">
        {children}
      </span>
      <div className="border" />
    </div>
  
  );
};
export default  DividerWithText