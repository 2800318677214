import { React, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';

import FormRenderer from '../../framework/Inputs/formRenderer/formRenderer';
import { safePlaceLocationData } from '../../utils/constants/constants';

const SafePlaceLocation = (props) => {
  const { formField, values } = props
  const { safePlaceLocation } = values;

  useEffect(() => {
    props.set_isDisableddeliveryType(false);
  }, [safePlaceLocation])

  const displayAdditionalText = () => {
    if (
      safePlaceLocation &&
      (safePlaceLocation.name === 'Other' ||
        safePlaceLocation.name ===
        'If you are self isolating, part of an at risk group or disabled, please tell us about any accessibility delivery needs you have')
    ) {
      return true;
    }
    return false;
  };
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <FormRenderer {...formField.safePlaceLocation.props} data={safePlaceLocationData} fullWidth />
      </Grid>
      {displayAdditionalText()
        ? (
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormRenderer {...formField.safePlaceLocationAdditionalText.props} fullWidth />
          </Grid>
        )
        : null}
    </Grid>
  );
};

export default SafePlaceLocation;
