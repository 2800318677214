import { axiosConfig } from '../../../api/axios';
import { ServiceEndPoints } from '../../constants/ApiConstant';
import { testFunctionForPostCodeRegex } from '../../helperFunctions';

const newDomesticConsignmentForm = {
  formId: 'addDomesticConsignment',
  formField: {
    templateSearch: {
      props: {
        name: 'templateSearch',
        type: 'text',
        label: 'Search by',
        placeholder: 'Template name',
      },
      value: '',
      // validation: {
      //   name: 'templateSearch',
      //   validationType: 'string',
      //   validations: [
      //     {
      //       type: 'required',
      //       params: ['Template name is required'],
      //     },
      //   ],
      // },
    },
    selectedTemplate: {
      props: {
        name: 'selectedTemplate',
        type: 'text',
      },
    },
    totalWeightExceed: {
      props: {
        name: 'totalWeightExceed',
        type: 'number',
      },
      value: 0,
      validation: {
        name: 'totalWeightExceed',
        validationType: 'number',
        validations: [
          {
            type: 'when',
            params: [
              ['weight','destinationCountry'],
              (weight, destinationCountry, schema) => {
                if (destinationCountry?.code !== 'GBR') {
                  return schema
                  .test('', 'Total Weight of Pieces exceeds the Declared Weight', (value) => {
                    return value <= (weight ? weight : 0);
                    });
                } else {
                  return schema;
                }
              },
            ],
          },
        ],
      },
    },
    handOverMethod: {
      props: {
        name: 'handOverMethod',
        label: 'Parcel handover method:',
        type: 'radio',
      },
      value: 'driverCollection',
      validation: {
        name: 'handOverMethod',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Name is required'],
          },
        ],
      },
    },
    setupSearchBy: {
      props: {
        name: 'setupSearchFor',
        type: 'searchControl',
      },
      selectProps: {
        props: {
          name: 'setupSearchBy',
          label: 'Search For',
          placeholder: "Enter Customer's Number",
        },
      },
      textProps: {
        props: {
          name: 'setupSearchFor',
          label: 'Search For',
          placeholder: "Enter Customer's Number",
        },
      },
      validation: {
        name: 'setupSearchFor',
        validationType: 'string',
        validations: [
          // {
          //   type: 'required',
          //   params: ['SearchFor is required'],
          // },
          {
            type: 'min',
            params: [1, 'SearchFor length must be greater than 0'],
          },
        ],
      },
    },
    accountNumber: {
      props: {
        name: 'accountNumber',
        label: 'Account number',
        type: 'autoComplete',
        displayField: 'displayName',
        hasDefaultValue: true,
      },
      validation: {
        name: 'accountNumber',
        validationType: 'object',
        validations: [
          {
            type: 'required',
            params: ['Account Number is required'],
          },
        ],
      },
    },
    destinationCountry: {
      props: {
        name: 'destinationCountry',
        label: 'Destination country',
        type: 'select',
        displayField: 'name',
        isRequired: true,
      },
      validation: {
        name: 'destinationCountry',
        validationType: 'object',
        validations: [
          {
            type: 'required',
            params: ['Destination country is required'],
          },
        ],
      },
    },
    servicePoint: {
      props: {
        name: 'servicePoint',
        label: 'Find ServicePoint',
        type: 'text',
        placeholder: 'Enter and Search',
      },
      validation: {
        name: 'servicePoint',
        validationType: 'string',

        validations: [
          {
            type: 'required',
            params: ['ServicePoint  is required'],
          },
          {
            type: 'when',
            params: [
              'handOverMethod',
              (handOverMethod, schema) => {
                if (handOverMethod === 'servicePoint') {
                  return testFunctionForPostCodeRegex(schema, 'Please enter a valid format', '', true);
                }
                return schema;
              },
            ],
          },
        ],
      },
    },
    pallets: {
      props: {
        name: 'pallets',
        label: 'Pallets',
        type: 'number',
        // isDisabled: true,
        inputProps: {
          min: 0,
          max: 1,
        },
      },
      value: 1,

      validation: {
        name: 'pallets',
        validationType: 'number',
        validations: [
          {
            type: 'min',
            params: [0, 'Pallets can  not be less than 0'],
          },
          {
            type: 'max',
            params: [1, 'Pallets can  not be greater than 1'],
          },
        ],
      },
    },
    addParcelDimensions: {
      props: {
        name: 'addParcelDimensions',
        label: 'Add parcel dimensions',
        type: 'checkbox',
      },
      value: false,
    },
    duplicateParcelDimensions: {
      props: {
        name: 'duplicateParcelDimensions',
        label: 'Duplicate parcel dimensions',
        type: 'checkbox',
      },
      value: false,
    },
    parcelDetails: {
      props: {
        name: 'parcelDetails',
        type: 'array',
        fields: {
          length: 'number',
          width: 'number',
          height: 'number',
          pieceWeight: 'number',
          volumetricWeight: 'number',
          id: 'number',
        },
      },
      values: [],
    },
    totalVolumetricWeight: {
      props: {
        name: 'totalVolumetricWeight',
      },
      value: 0.0,
    },
    weight: {
      props: {
        name: 'weight',
        label: 'Weight (kg)',
        type: 'number',
        placeholder: 'Select',
        isRequired: true,
        inputProps: {
          min: 0,
          max: 1000,
          step: 'any',
        },
      },
      validation: {
        name: 'weight',
        validationType: 'number',
        validations: [
          {
            type: 'when',
            params: [
              ['totalpieceWeight', 'destinationCountry', 'addParcelDimensions'],
              (totalpieceWeight, destinationCountry, addParcelDimensions, schema) => {
                if (destinationCountry?.code === 'GBR' && addParcelDimensions) {
                  return schema
                    .required('Weight is required')
                    .test('', 'Weight must be greater than or equal to piece weight', (value) => {
                      return value >= (totalpieceWeight ? totalpieceWeight : 0);
                    });
                } else {
                  return schema.required('Weight is required');
                }
              },
            ],
          },
        ],
      },
    },
    length: {
      props: {
        name: 'length',
        label: 'Length (cm)',
        type: 'text',
        id: 'length',
        isRequired: true,
        inputProps: {
          maxLength: 4,
        },
      },
      validation: {
        name: 'length',
        validationType: 'number',
        validations: [
          // {
          //   type: 'min',
          //   params: [1, 'Declared length must be greater than 0'],
          // },
          // {
          //   type: 'max',
          //   params: [200, 'Declared length must be less than or equal to 200'],
          // },
          {
            type: 'typeError',
            params: ['Length must be number type'],
          },
          {
            type: 'when',
            params: [
              ['destinationCountry', 'width', 'height'],
              (destinationCountry, width, height, schema) => {
                if (destinationCountry) {
                  let highest = width || 0;
                  if ((height || 0) > highest) highest = height;
                  return destinationCountry.code !== 'GBR'
                    ? schema.required('Length is required.').test('', 'Length must be longest side', (value) => {
                      return value >= highest;
                    })
                    : schema.required('Length is required.').test('', 'Length must be longest side', (value) => {
                      return value >= highest;
                    });
                }
              },
            ],
          },
        ],
      },
    },
    width: {
      props: {
        name: 'width',
        label: 'Width (cm)',
        type: 'text',
        id: 'width',
        isRequired: true,
        inputProps: {
          maxLength: 4,
        },
      },
      validation: {
        name: 'width',
        validationType: 'number',
        validations: [
          {
            type: 'typeError',
            params: ['Width must be number type'],
          },
          // {
          //   type: 'required',
          //   params: ['Width is required.'],
          // },
          // {
          //   type: 'min',
          //   params: [1, 'Declared width must be greater than 0'],
          // },
          // {
          //   type: 'max',
          //   params: [120, 'Declared width must be less than or equal to 120'],
          // },
          {
            type: 'when',
            params: [
              'destinationCountry',
              (destinationCountry, schema) => {
                if (destinationCountry) {
                  return destinationCountry.code !== 'GBR'
                    ? schema.required('Width is required.')
                    : // .required('Declared width must not be empty')
                    // .min(1, 'Declared width must be greater than 0')
                    // .max(120, 'Declared width must less than or equal to 120')
                    schema.required('Width is required.');
                }
              },
            ],
          },
        ],
      },
    },
    height: {
      props: {
        name: 'height',
        id: 'height',
        label: 'Height (cm)',
        type: 'text',
        isRequired: true,
        inputProps: {
          maxLength: 4,
        },
      },
      validation: {
        name: 'height',
        validationType: 'number',
        validations: [
          {
            type: 'typeError',
            params: ['Height must be number type'],
          },
          // {
          //   type: 'required',
          //   params: ['Height is required.'],
          // },
          // {
          //   type: 'min',
          //   params: [1, 'Declared height must be greater than 0'],
          // },
          // {
          //   type: 'max',
          //   params: [120, 'Declared height must be less than or equal to 120'],
          // },
          {
            type: 'when',
            params: [
              'destinationCountry',
              (destinationCountry, schema) => {
                if (destinationCountry) {
                  return destinationCountry.code !== 'GBR'
                    ? schema.required('Height is required.')
                    : // .required('Declared height must not be empty')
                    // .min(1, 'Declared height must be greater than 0')
                    // .max(120, 'Declared height must be less than or equal to 120')
                    schema.required('Height is required.');
                }
              },
            ],
          },
        ],
      },
    },
    addressType: {
      props: {
        name: 'addressType',
        label: 'Address type:',
        type: 'radio',
      },
      value: 'residential',
      validation: {
        name: 'addressType',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Choose any one of address'],
          },
          {
            type: 'when',
            params: [
              ['createPalletShipment', 'destinationCountry'],
              (createPalletShipment, destinationCountry, schema) => {
                if (createPalletShipment === 'yes' && destinationCountry?.code === 'GBR') {
                  return schema.test('', 'Residential address not allowed for pallets.', (value) => {
                    return value !== 'residential';
                  });
                }
              },
            ],
          },
        ],
      },
    },
    searchFor: {
      props: {
        type: 'searchControl',
        name: 'searchFor',
      },
      selectProps: {
        props: {
          name: 'searchBy',
          label: 'Search By',
        },
        value: '',
      },
      textProps: {
        props: {
          name: 'searchFor',
          placeholder: "Enter Customer's Number",
        },
      },
      validation: {
        name: 'searchFor',
        validationType: 'string',
        validations: [
          {
            type: 'when',
            params: [
              ['destinationCountry', 'searchBy'],
              (destinationCountry, searchBy, schema) => {
                return (destinationCountry || {}).code && (searchBy || {}).value === 'postCode'
                  ? schema
                    .required('Postal code is required')
                    .matches((destinationCountry || {}).postcodeRegex, 'Invalid postal code')
                  : schema.required('Address is required');
              },
            ],
          },
        ],
      },
    },
    customerNumber: {
      props: {
        name: 'customerNumber',
        label: 'Customer number',
        type: 'text',
        placeholder: 'Enter',
        inputProps: {
          maxLength: 30,
        },
      },
      validation: {
        name: 'customerNumber',
        validationType: 'string',
        validations: [
          {
            type: 'when',
            params: [
              ['addToAddressBook', 'addToFavCutomerList', 'updateAddToAddressBook'],
              (addToAddressBook, addToFavCutomerList, updateAddToAddressBook, schema) => {
                return addToAddressBook || addToFavCutomerList || updateAddToAddressBook
                  ? schema.required('Enter Customer Number')
                  : schema.notRequired();
              },
            ],
          },
        ],
      },
    },
    postalCode: {
      props: {
        name: 'postalCode',
        label: 'Postal code',
        type: 'text',
        // isRequired: true,
        inputProps: {
          maxLength: 15,
          minLength: 3,
        },
      },
      validation: {
        name: 'searchFor',
        validationType: 'string',
        validations: [
          // {
          //   type: 'required',
          //   params: ['Postal code is required'],
          // },
          // validations: [
          //   {
          //     type: 'when',
          //     params: [
          //       'searchFor',
          //       (destinationCountry, schema) => {
          //         return destinationCountry?.postcodeOptional === false
          //           ? schema.required('Postal code is required')
          //           : schema.notRequired();
          //       },
          //     ],
          //   },
          {
            type: 'when',
            params: [
              'destinationCountry',
              (destinationCountry, schema) => {
                if (!destinationCountry?.postcodeOptional) {
                  return schema.required('Postal code is required')
                }
              }
            ],
          },
          {
            type: 'when',
            params: [
              'destinationCountry',
              (destinationCountry, schema) => {
                if (destinationCountry?.postcodeRegex) {
                  return testFunctionForPostCodeRegex(
                    schema,
                    `Postal code should match ${destinationCountry?.postcodeMask} format`,
                    destinationCountry?.postcodeRegex,
                    false,
                  );
                }
              },
            ],
          },
          {
            type: 'max',
            params: [15, 'Maximum of 15 characters are allowed'],
          },
          {
            type: 'min',
            params: [3, 'Minimum of 3 characters are required'],
          },
        ],
      },
    },
    recipientName: {
      props: {
        name: 'recipientName',
        label: 'Recipient full name',
        type: 'text',
        inputProps: {
          maxLength: 35,
        },
      },
      validation: {
        name: 'recipientName',
        validationType: 'string',
        validations: [
          {
            type: 'when',
            params: [
              ['addressType', 'destinationCountry'],
              (addressType, destinationCountry, schema) => {
                return addressType === 'residential' || destinationCountry?.code !== 'GBR'
                  ? schema.required('Recipient name is required')
                  : schema.notRequired();
              },
            ],
          },
          {
            type: 'max',
            params: [35, 'Maximum of 35 characters allowed'],
          },
          {
            type: 'min',
            params: [1, 'Minimum of 1 character required'],
          },
          {
            type: 'when',
            params: [
              ['addressType', 'destinationCountry'],
              (addressType, destinationCountry, schema) => {
                return addressType === 'residential' || destinationCountry?.code !== 'GBR'
                  ? schema.test("", "Recipient name is required", (value) => { return value?.trim() }
                  )
                  : schema.notRequired();
              },
            ],
          }
        ],
      },
    },
    businessName: {
      props: {
        name: 'businessName',
        label: 'Business name',
        placeholder: 'Business 1',
        type: 'text',
        isRequired: true,
        inputProps: {
          maxLength: 35,
          minLength: 1,
        },
      },
      validation: {
        name: 'businessName',
        validationType: 'string',
        validations: [
          {
            type: 'when',
            params: [
              'addressType',
              (addressType, schema) => {
                return addressType === 'business'
                  ? schema.required('Business name is required').max(35, 'Maximum of 35 characters allowed')
                  : schema;
              },
            ],
          },
          {
            type: 'when',
            params: [
              'addressType',
              (addressType, schema) => {
                return addressType === 'business'
                  ? schema.required('Business name is required').min(1, 'Minimum of 1 characters required')
                  : schema;
              },
            ],
          },
          {
            type: 'when',
            params: [
              ['addressType'],
              (addressType, schema) => {
                return addressType === 'business'
                  ? schema.test("", "Business name is required", (value) => { return value?.trim() }
                  )
                  : schema.notRequired();
              },
            ],
          },
        ],
      },
    },
    addressLine1: {
      props: {
        name: 'addressLine1',
        label: 'Address line 1',
        type: 'text',
        isRequired: true,
        inputProps: {
          maxLength: 35,
          minLength: 1,
        },
      },
      validation: {
        name: 'addressLine1',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Address is required'],
          },
          {
            type: 'max',
            params: [35, 'Maximum of 35 characters allowed'],
          },
          {
            type: 'min',
            params: [1, 'Minimum of 1 character required'],
          },
        ],
      },
    },
    addressLine2: {
      props: {
        name: 'addressLine2',
        label: 'Address line 2',
        type: 'text',
        inputProps: {
          maxLength: 35,
          minLength: 1,
        },
      },
      validation: {
        name: 'addressLine2',
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [35, 'Maximum of 35 characters allowed'],
          },

          {
            type: 'min',
            params: [1, 'Minimum of 1 character required'],
          },
        ],
      },
    },
    addressLine3: {
      props: {
        name: 'addressLine3',
        label: 'Address line 3',
        type: 'text',
        inputProps: {
          maxLength: 35,
          minLength: 1,
        },
      },
      validation: {
        name: 'addressLine3',
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [35, 'Maximum of 35 characters allowed'],
          },

          {
            type: 'min',
            params: [1, 'Minimum of 1 character required'],
          },
        ],
      },
    },
    town: {
      props: {
        name: 'town',
        label: 'Town',
        type: 'text',
        isRequired: true,
        inputProps: {
          maxLength: 35,
          minLength: 1,
        },
      },
      validation: {
        name: 'town',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Town is required'],
          },
          {
            type: 'max',
            params: [35, 'Maximum of 35 characters allowed'],
          },
          {
            type: 'min',
            params: [1, 'Minimum of 1 character required'],
          },
        ],
      },
    },
    county: {
      props: {
        name: 'county',
        label: 'County',
        type: 'text',
        inputProps: {
          maxLength: 35,
          minLength: 1,
        },
      },
      validation: {
        name: 'county',
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [35, 'Maximum of 35 characters allowed'],
          },
          {
            type: 'min',
            params: [1, 'Minimum of 1 character required'],
          },
        ],
      },
    },
    telephone: {
      props: {
        name: 'telephone',
        label: 'Telephone number',
        type: 'text',
        inputProps: {
          maxLength: 35,
          minLength: 1,
        },
      },
      validation: {
        name: 'telephone',
        validationType: 'string',
        validations: [
          // {
          //   type: 'matches',
          //   params: [/^[0-9,(,),+,\-,.,[,\] ].{0,35}/, 'Invalid phone number'],
          // },
          {
            type: 'when',
            params: [
              'destinationCountry',
              (destinationCountry, schema) => {
                return destinationCountry?.code !== 'GBR' ? schema.required('Phone number is required') : schema;
              },
            ],
          },
          {
            type: 'when',
            params: [
              ['destinationCountry'],
              (destinationCountry, schema) => {
                return schema.test("", "Invalid phone number", (value) => {
                  if (value?.trim()?.length > 0) {
                    // return destinationCountry?.code === "GBR" ? /^[ \d+()-].{0,35}$/.test(value) : /^[ \d+()-].{9,35}$/.test(value)
                    return destinationCountry?.code === "GBR" ? /^[0-9()+\-.[\], ]{0,35}$/.test(value) : /^[0-9()+\- ]{9,35}$/.test(value)
                  }
                  else if (destinationCountry?.code !== 'GBR') {
                    return schema.required('Phone number is required')
                  }
                  else return schema
                }
                )
              },
            ],
          }
        ],
      },
    },
    emailAddress: {
      props: {
        name: 'emailAddress',
        label: 'Email address',
        type: 'text',
        inputProps: {
          maxLength: 70,
        },
      },
      validation: {
        name: 'emailAddress',
        validationType: 'string',
        validations: [
          {
            type: 'email',
            params: ['Invalid email address'],
          },
          {
            type: 'max',
            params: [70, 'Maximum of 70 characters allowed'],
          },
        ],
      },
    },
    deliveryType: {
      props: {
        name: 'deliveryType',
        label: 'Delivery type:',
        type: 'radio',
      },
      value: 'deliverToDoorStep',
      validation: {
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Delivery Type is required'],
          },
        ],
      },
    },
    doorStepInstructions: {
      props: {
        name: 'doorStepInstructions',
        label: 'Doorstep instructions',
        type: 'select',
        displayField: 'name',
        hasDefaultValue: true,
      },
      validation: {
        validationType: 'object',
        validations: [
          {
            type: 'required',
            params: ['Doorstep Instructions is required'],
          },
        ],
      },
    },
    deliveryServicePoint: {
      props: {
        name: 'deliveryServicePoint',
        label: 'Search for ServicePoint',
        type: 'text',
        placeholder: 'Search For ServicePoint',
      },
      validation: {
        name: 'postalCode',
        validationType: 'string',
        validations: [
          {
            type: 'when',
            params: [
              'deliveryType',
              (deliveryType, schema) => {
                return deliveryType === 'deliverToServicePoint'
                  ? schema.required('ServicePoint  is required')
                  : schema.notRequired();
              },
            ],
          },
          {
            type: 'when',
            params: [
              'destinationCountry',
              (destinationCountry, schema) => {
                if (destinationCountry?.postcodeRegex) {
                  return testFunctionForPostCodeRegex(
                    schema,
                    'Please  enter a correct postal code',
                    destinationCountry?.postcodeRegex,
                  );
                }
              },
            ],
          },
        ],
      },
    },
    servicePointDetails: {
      props: {
        name: 'servicePointDetails',
        label: 'Service point details',
        type: 'text',
      },
      validation: {
        validationType: 'object',
        validations: [
          {
            type: 'when',
            params: [
              'deliveryType',
              (deliveryType, schema) => {
                return deliveryType === 'deliverToServicePoint'
                  ? schema.required('Service point details are required')
                  : schema.notRequired();
              },
            ],
          },
        ],
      },
    },

    leaveSafeLocation: {
      props: {
        name: 'leaveSafeLocation',
        label: 'Leave safe location',
        type: 'text',
        placeholder: 'Leave Safe location',
        inputProps: {
          maxLength: 60,
        },
      },
      validation: {
        name: 'leaveSafeLocation',
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [60, 'Max 60 characters are allowed'],
          },
        ],
      },
    },
    exchange: {
      props: {
        name: 'exchange',
        label: 'Exchange on delivery:',
        type: 'radio',
      },
      value: 'no',
    },
    specialInstructions: {
      props: {
        name: 'specialInstructions',
        label: 'Special instructions',
        type: 'text',
        inputProps: {
          maxLength: 30,
        },
      },
      validation: {
        name: 'specialInstructions',
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [30, 'Max 30 characters are allowed'],
          },
        ],
      },
    },
    additionalInstruction: {
      props: {
        name: 'additionalInstruction',
        type: 'text',
        inputProps: {
          maxLength: 30,
        },
      },
    },
    product: {
      props: {
        name: 'product',
        label: 'Product',
        type: 'select',
        displayField: 'productNameForUI',
        isRequired: true,
        hasDefaultValue: true,
      },
      validation: {
        name: 'product',
        validationType: 'object',
        validations: [
          {
            type: 'when',
            params: [
              'destinationCountry',
              (destinationCountry, schema) => {
                return destinationCountry?.code === 'GBR'
                  ? schema.required('Product not available for current selection')
                  : schema.notRequired();
              },
            ],
          },
        ],
      },
    },
    service: {
      props: {
        name: 'service',
        label: 'Service',
        type: 'select',
        displayField: 'name',
        isRequired: true,
        hasDefaultValue: true,
      },
      validation: {
        name: 'service',
        validationType: 'object',
        validations: [
          {
            type: 'when',
            params: [
              'destinationCountry',
              (destinationCountry, schema) => {
                return destinationCountry?.code === 'GBR'
                  ? schema.required('Service not available for current selection')
                  : schema.notRequired();
              },
            ],
          },
        ],
      },
    },
    customerReference: {
      props: {
        name: 'customerReference',
        label: 'Customer reference',
        type: 'text',
        inputProps: {
          maxLength: 20,
        },
      },
      validation: {
        name: 'customerReference',
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [20, 'Max 20 characters are allowed'],
          },
        ],
      },
    },
    alternateReference: {
      props: {
        name: 'alternateReference',
        label: 'Alternative reference',
        type: 'text',
        inputProps: {
          maxLength: 20,
        },
      },
      validation: {
        name: 'alternateReference',
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [20, 'Max 20 characters are allowed'],
          },       
        ],
      },
    },
    extendedCoverDomestic: {
      props: {
        name: 'extendedCoverDomestic',
        label: 'Extended liability',
        type: 'number',
        placeholder: 'Please select a level of extended liability, if required',
        inputProps: {
          min: 0,
          max: 5,
        },
      },
      validation: {
        name: 'extendedCoverDomestic',
        validationType: 'number',
        validations: [
          {
            type: 'when',
            params: [
              'destinationCountry',
              (destinationCountry, schema) => {
                if (destinationCountry) {
                  return destinationCountry.code === 'GBR'
                    ? schema
                      .min(0, 'Extended liability value should be between 0 - 5.')
                      .max(5, 'Extended liability value should be between 0 - 5.')
                    : schema.notRequired();
                }
              },
            ],
          },
        ],
      },
    },
    extendedCoverInternational: {
      props: {
        name: 'extendedCoverInternational',
        label: 'Extended liability',
        type: 'checkbox',
      },
      value: false,
    },
    mobileRecipient: {
      props: {
        name: 'mobileRecipient',
        label: 'Mobile',
        type: 'checkbox',
      },
      value: false,
    },
    emailRecipient: {
      props: {
        name: 'emailRecipient',
        label: 'Email',
        type: 'checkbox',
      },
      value: true,
    },
    inboxReturnLabel: {
      props: {
        name: 'inboxReturnLabel',
        label: 'Include inbox return label:',
        type: 'radio',
      },
      value: 'false',
    },
    invoiceTypeLabel: {
      props: {
        name: 'invoiceTypeLabel',
        label: 'Invoice type',
        type: 'radio',
        isRequired: 'true',
      },
      value: 'commercial',
    },
    returnReference: {
      props: {
        name: 'returnReference',
        label: 'Return reference',
        type: 'text',
        inputProps: {
          maxLength: 20,
        },
      },
      validation: {
        name: 'returnReference',
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [20, 'Maximum of 20 characters are allowed'],
          },
        ],
      },
    },
    InvoiceDate: {
      props: {
        name: 'InvoiceDate',
        label: 'Invoice date',
        type: 'date',
        isRequired: true,
      },
    },
    InvoiceNumber: {
      props: {
        name: 'InvoiceNumber',
        label: 'Invoice number',
        type: 'text',
        isRequired: true,
        inputProps: {
          maxLength: 35,
        },
      },
      validation: {
        name: 'InvoiceNumber',
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [35, 'Maximum 35 characters allowed'],
          },
          {
            type: 'required',
            params: ['Invoice number is required'],
          },
        ],
      },
    },
    noOfItems: {
      props: {
        name: 'noOfItems',
        label: 'No. of items',
        type: 'number',
        isRequired: true,
        inputProps: {
          min: 1,
          max: 20,
        },
        restrictDecimal: true
      },
      value: 1,
      validation: {
        name: 'noOfItems',
        validationType: 'number',
        validations: [
          {
            type: 'required',
            params: ['Number of Items is required.'],
          },
          {
            params: [1, 'Must be a value between 1 to 20'],
            type: 'min',
          },
          {
            type: 'max',
            params: [20, 'Must be a value between 1 to 20'],
          },
        ],
      },
    },
    collectionDate: {
      props: {
        name: 'collectionDate',
        label: 'Collection Date',
        type: 'date',
      },
    },
    addToAddressBook: {
      props: {
        name: 'addToAddressBook',
        label: 'Add to address book',
        type: 'checkbox',
      },
      value: false,
    },
    updateAddToAddressBook: {
      props: {
        name: 'updateAddToAddressBook',
        label: 'Update address in address book',
        type: 'checkbox',
      },
      value: false,
    },
    addToFavCutomerList: {
      props: {
        name: 'addToFavCutomerList',
        label: 'Add to favourites',
        type: 'checkbox',
      },
      value: false,
    },
    descriptionOfGoods: {
      props: {
        name: 'descriptionOfGoods',
        label: 'Description of goods',
        type: 'text',
        isRequired: true,
      },
      validation: {
        name: 'descriptionOfGoods',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Description of goods is required'],
          },
          {
            type: 'min',
            params: [3, 'Minimum of 3 characters are required'],
          },
          // {
          //   type: 'max',
          //   params: [50, 'Minimum 50 characters are required'],
          // },
          {
            type: 'matches',
            params: [/(.*[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-]){3}/i, 'Minimum 3 non blank characters are required'],
          },
        ],
      },
    },
    customDeclarationCurrency: {
      props: {
        name: 'customDeclarationCurrency',
        label: 'Currency',
        type: 'select',
        displayField: 'name',
        isRequired: true,
        hasDefaultValue: true,
      },
      validation: {
        name: 'customDeclarationCurrency',
        validationType: 'object',
        validations: [
          {
            type: 'required',
            params: ['Currency is required'],
          },
        ],
      },
    },
    customDeclarationValue: {
      props: {
        name: 'customDeclarationValue',
        label: 'Value',
        type: 'number',
        isRequired: true,
        inputProps: {
          min: 1,
          max: 10000,
        },
      },
      validation: {
        name: 'customDeclarationValue',
        validationType: 'number',
        validations: [
          {
            type: 'required',
            params: ['Value is required'],
          },
          {
            type: 'min',
            params: [1, 'Value must be greater than 0'],
          },
          {
            type: 'max',
            params: [10000, 'Value should not exceed 10000'],
          },
        ],
      },
    },
    customDeclarationShippingCharges: {
      props: {
        name: 'customDeclarationShippingCharges',
        label: 'Shipping charges($currency)',
        type: 'text',
        isRequired: true,
        inputProps: {
          maxLength: 10,
        },
      },
      validation: {
        name: 'customDeclarationShippingCharges',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Shipping charges are required'],
          },
          {
            type: 'max',
            params: [10, 'Maximum 10 digits are allowed for shipping charges'],
          },
          {
            type: 'matches',
            params: [/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/, 'Shipping charges must be a positive number'],
          },
        ],
      },
    },
    customDeclarationShippersEoriNo: {
      props: {
        name: 'customDeclarationShippersEoriNo',
        label: "Shipper's EORI number",
        type: 'text',
        isRequired: true,
        // value:'AB123456789012'
        inputProps: {
          maxLength: 14,
        },
      },
      validation: {
        name: 'customDeclarationShippersEoriNo',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ["Shipper's EORI number is required"],
          },
          {
            type: 'max',
            params: [14, "Maximum 14 characters/digits are allowed for shipper's EORI no"],
          },
          {
            type: 'matches',
            // params: [/[GB]{2}\d{12}/, "Shipper's EORI no. must be in the correct format (e.g. GB123456789011)"],
            params: [/[A-Z]{2}\d{12}/, "Shipper's EORI no. must be in the correct format (e.g. AB123456789011)"],
          },
        ],
      },
    },
    customDeclarationReceiversEoriNo: {
      props: {
        name: 'customDeclarationReceiversEoriNo',
        label: "Receiver's VAT/PID/EORI number",
        type: 'text',
        isRequired: false,
        inputProps: {
          maxLength: 20,
        },
      },
      validation: {
        name: 'customDeclarationReceiversEoriNo',
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [20, 'Maximum 20 characters/digits are allowed for receivers number'],
          },
          {
            type: 'matches',
            params: [/^[a-zA-Z0-9]*$/, "Receiver's number must be a either characters/digits"],
          },
        ],
      },
    },
    commodityDetails: {
      props: {
        name: 'commodityDetails',
        type: 'array',
        fields: {
          commodityCode: 'text',
          fullDescriptionOfGoods: 'text',
          weight: 'number',
          quantity: 'number',
          unitValue: 'number',
          countryOfOrigin: 'text',
        },
      },
      values: [],
      validation: {
        name: 'commodityDetails',
        validationType: 'array',
        validations: [
          {
            type: 'when',
            params: [
              ['destinationCountry', 'isFullCustomDetails'],
              (destinationCountry, isFullCustomDetails, schema) => {
                return (destinationCountry || {}).code !== 'GBR' && isFullCustomDetails
                  ? schema
                    .required('Commodity details are required')
                    .min(1, 'Minimum of 1 item needs to be added')
                    .max(20, 'Maximum 20 items are allowed')
                  : schema.notRequired();
              },
            ],
          },
        ],
      },
    },
    subTotal: {
      props: {
        name: 'subTotal',
      },
      value: 0,
    },
    totalValue: {
      props: {
        name: 'totalValue',
      },
      value: 0,
    },
    reasonForExport: {
      props: {
        name: 'reasonForExport',
        label: 'Reason for export',
        type: 'select',
        displayField: 'description',
        isRequired: true,
        // value: '0',
        hasDefaultValue: true,
      },
      validation: {
        name: 'reasonForExport',
        validationType: 'object',
        validations: [
          {
            type: 'when',
            params: [
              ['destinationCountry', 'isFullCustomDetails'],
              (destinationCountry, isFullCustomDetails, schema) => {
                return (destinationCountry || {}).code !== 'GBR' && isFullCustomDetails
                  ? schema.required('Reason for export is required')
                  : schema.notRequired();
              },
            ],
          },
        ],
      },
    },

    fdaRegistrationNumber: {
      props: {
        name: 'FDARegistrationNumber',
        label: 'FDA registration number',
        type: 'input',
        isDisabled: true,
      },
    },
    termsOfDelivery: {
      props: {
        name: 'TermsOfDelivery',
        label: 'Terms of delivery',
        type: 'select',
        displayField: 'label',
        isRequired: 'true',
        // isDisabled: true,
      },
    },
    favouriteSearchBy: {
      props: {
        name: 'favouriteSearchBy',
        label: 'Search by',
        type: 'text',
        placeholder: 'Customer no./ business name',
      },
    },
    DispatchDate: {
      props: {
        name: 'DispatchDate',
        label: 'Dispatch date',
        type: 'date',
        isRequired: true,
      },
    },
    isFullCustomDetails: {
      props: {
        name: 'isFullCustomDetails',
        label: '',
        type: 'checkbox',
      },
      value: false,
    },
    pieceWeight: {
      props: {
        name: 'pieceWeight',
        label: 'Weight (kg)',
        type: 'number',
        placeholder: 'Select',
        isRequired: true,
        inputProps: {
          min: 0,
          max: 999,
          step: 'any',
        },
      },
      value: 0,
      validation: {
        name: 'pieceWeight',
        validationType: 'number',
        validations: [
          {
            type: 'required',
            params: ['Weight is required'],
          },
        ],
      },
    },
    totalpieceWeight: {
      props: {
        name: 'totalpieceWeight',
      },
      value: 0.0,
    },
    applyIossNumber: {
      props: {
        name: 'applyIossNumber',
        label: 'Apply IOSS number:',
        type: 'radio',
      },
      value: 'true',
    },
    iossType: {
      props: {
        name: 'iossType',
      },
      value: '',
    },
    iossNumber: {
      props: {
        name: 'iossNumber',
        // label:'IOSS number',
        type: 'text',
        isDisabled: true,
      }
    },
    marketplaceIossNo: {
      props: {
        name: 'marketplaceIossNo',
        label: "Marketplace IOSS number",
        type: 'text',
        isRequired: true,
        inputProps: {
          maxLength: 12,
        },
      },
      validation: {
        name: 'marketplaceIossNo',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ["IOSS number is required"],
          },
          // {
          //   type: 'max',
          //   params: [12, "Maximum 12 characters/digits are allowed for shipper's EORI no"],
          // },
          {
            type: 'matches',
            params: [/IM\d{10}/, "IOSS no. must be in the correct format (e.g. IM0123456789)"],
            // params: [/[A-Z]{2}\d{12}/, "Shipper's EORI no. must be in the correct format (e.g. AB123456789011)"],
          },
        ],
      },
    },
    //Addition of pallets.
    createPalletShipment: {
      props: {
        name: 'createPalletShipment',
        label: 'Create pallet shipment',
        type: 'radio',
      },
      value: 'no',
    },

    //Express Changes for international shipment.
    intlParcelDetails: {
      props: {
        name: 'intlParcelDetails',
        type: 'array',
        fields: {
          length: 'number',
          width: 'number',
          height: 'number',
          pieceWeight: 'number',
          pieceRefId: 'string',
          volumetricWeight: 'number',
          id: 'number',
        },
      },
      values: [],
    },

    what3Words: {
      props: {
        name: 'what3Words',
        label: 'What3Words',
        type: 'text',
      },
    },
    w3WCoordinates: {
      props: {
        name: 'w3WCoordinates',
        type: 'object',
      },
    },
  },
};

export default newDomesticConsignmentForm;
