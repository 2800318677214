import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AddressFormate from '../../../framework/addressFormate/AddressFormate';
import CustomHeader from '../../../framework/summaryFormate/CustomHeader';

import { Grid, Box, makeStyles, Checkbox, FormControlLabel, SvgIcon } from '@material-ui/core';

import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';
import { axiosConfig } from '../../../api/axios';

import InputButton from '../../../framework/Inputs/inputButton/inputButton';
import SetupSummary from '../../../framework/summaryFormate/SetupSummary';
import ParcelSummary from '../../../framework/summaryFormate/ParcelSummary';
import DeliveryOptionsSummary from '../../../framework/summaryFormate/DeliveryOptionsSummary';
import AdditionalInfoSummary from '../../../framework/summaryFormate/AdditionalInfoSummary';
import ReturnOptionsSummary from '../../../framework/summaryFormate/ReturnOptionsSummary';
import ProductAndServiceSummary from '../../../framework/summaryFormate/ProductAndServiceSummary';
import { connect, useDispatch } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { setDropdownValues } from '../../../utils/helperFunctions';
import CustomModal from '../../../framework/dialog/customDialog';
import { printZplLabels, printPDF, downloadPDF } from '../../../utils/PrintHelper';
import PromptJSPMClientInstallation from '../../../components/addShipment/Printing/PromptJSPMClientInstallation';
import CustomDialog from '../../../framework/dialog/customDialog';
import './ShipmentSummary.scss';
import CustomDetailsSummary from '../../../components/shipmentManagement/shipmentSummary/customDetailsSummary/customDetailsSummary';
import { ReactComponent as EyesIcon } from '../../../assets/images/eyes.svg';
import ConfirmationDialog from '../../../components/addressBook/confirmation/confirmation';
import CustomCaption from '../../../framework/summaryFormate/CustomCaption';

const useStyles = makeStyles({
  boxContainer: {
    '& :last-child': {
      borderBottom: 'unset',
    },
  },
});

const ShipmentSummary = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const classes = useStyles();
  const [list, setlist] = useState({});
  const [accountDetails, setAccountDetails] = useState({});
  const [isDomestic, setisDomestic] = useState(true);
  const [showPrintDialog, setshowPrintDialog] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState('');
  const [fullCustom, setFullCustom] = useState(false)
  const [showCancelConfirmationDialog, setShowCancelConfirmationDialog] = useState(false);
  const [state, setState] = useState({
    reprintLabel: false,
    reprintCustomsInvoice: false,
  });
  const [showCustomDetails, setShowCustomDetails] = useState(false);
  const [customsDetails, setCustomsDetails] = useState({});

  const [openJSPMPrompt, setOpenJSPMPrompt] = useState(false);

  const history = useHistory();

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handlePrintSubmit = (values, selectedShipment, errors) => {
    const payload = [selectedShipment];

    const printFormat = props.printerConfiguration?.isDowloadLabelAsPDF === true ? "PDF" : props.printerConfiguration?.labelType === 1 ? 'ZPL' : 'PDF';

    const selctedPrinter = props.availablePrinter?.findIndex((x) => x === props.printerConfiguration?.labelPrinter);
    axiosConfig
      .post(
        `${ServiceEndPoints.reprintShipments}?isLabel=${values.reprintLabel}&isCustomsInvoice=${values.reprintCustomsInvoice}&format=${printFormat}`,
        payload,
      )
      .then((response) => {
        if (!props.JSPMStatus && !props.printerConfiguration?.isDowloadLabelAsPDF) {
          setOpenJSPMPrompt(true);
        } else {
          let labelResponse = response?.data[0]?.labelResponse;
          let customsInvoiceResponse = response?.data[0]?.customsInvoiceResponse;
          let hasLabelValue = false;

          labelResponse?.map((lblResponse) => {
            lblResponse?.labels?.map((lblValue, index) => {
              if (props.printerConfiguration?.isDowloadLabelAsPDF === true) {
                let lableName = '';
                if (index === 0) {
                  lableName = '_Label.pdf';
                } else if (index === 1) {
                  lableName = '_ReturnLabel.pdf';
                } else {
                  lableName = '_ReturnInstructions.pdf';
                }
                downloadPDF(lblValue.label, lableName, response.data);
                hasLabelValue = true;
              }
              else {
                if (props.printerConfiguration?.labelType == 1) {
                  printZplLabels(lblValue.label, props.printerConfiguration?.labelPrinter);
                  hasLabelValue = true;
                } else {
                  printPDF(lblValue.label, props.printerConfiguration?.labelPrinter, "Label");
                  hasLabelValue = true;
                }
              }
            });
          });

          customsInvoiceResponse?.map((customResponse) => {
            customResponse?.customsInvoice?.map((customValue, index) => {
              if (props.printerConfiguration?.isDowloadLabelAsPDF === true) {
                let lableName = '';
                if (index === 0) {
                  lableName = '_CustomsInvoice.pdf';
                } else if (index === 1) {
                  lableName = '_CustomsInvoice.pdf';
                } else {
                  lableName = '_CustomsInvoice.pdf';
                }
                downloadPDF(customValue, lableName, response.data);
                hasLabelValue = true;
              }
              else {
                printPDF(customValue, props.printerConfiguration?.otherReportsPrinter, "Invoice");
                hasLabelValue = true;
              }
            });
          });
          hasLabelValue && dispatch(actions.setAppSnackBarData({ msg: 'Shipment successfully reprinted' }));
        }
      })
      .catch((error) => {
        console.log(error)
        var failedReprintShipment = '';
        payload.map((invdata) => (failedReprintShipment = failedReprintShipment + invdata + ','));
        dispatch(
          actions.setBottomAlert({
            msgs: [
              {
                type: 'error',
                msg: 'Error in reprinting the shipment:' + failedReprintShipment.slice(0, -1),
              },
            ],
          }),
        );
        // dispatch(
        //   actions.setAppSnackBarData({
        //     type: 'error',
        //     msg: 'Error in reprinting the shipment:' + failedReprintShipment.slice(0, -1),
        //   }),
        // );
      });
  };

  const resetReprintState = () => {
    setState({ ...state, reprintLabel: false, reprintCustomsInvoice: false });
  };

  const handleDialogClose = () => {
    setshowPrintDialog(false);
    resetReprintState();
  };

  const handleClose = () => {
    setOpenJSPMPrompt(false);
  };
  const onDialogCancel = () => {
    setshowPrintDialog(false);
    resetReprintState();
  };
  const savePrintDialog = () => {
    setshowPrintDialog(false);
    const values = { reprintLabel: state.reprintLabel, reprintCustomsInvoice: state.reprintCustomsInvoice };
    handlePrintSubmit(values, id);
    resetReprintState();
  };

  const getShipmentList = () => {
    axiosConfig
      .get(`${ServiceEndPoints.getShipment}/${id}`)
      .then((res) => {
        if (res.data.destinationCountry !== 'United Kingdom') {
          setisDomestic(false);
        }
        setlist(res.data);
        setSelectedAccountDetails(res.data?.accountNumber);
        dispatch(
          actions.setShipmentId({
            shipmentId: res.data?.shipmentNumber,
          }),
        );
        getProductDetails(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setSelectedAccountDetails = (accountId) => {
    setAccountDetails(setDropdownValues(props.userDetails.accounts, 'accountId', accountId));
  };

  useEffect(() => {
    getShipmentList();
  }, []);

  const backToBookedCollection = () => {
    dispatch(actions.setActiveTab(''));
    history.push('/shipment/shipmentmanagement');
  };

  const cancelShipment = () => {
    const itemsToBeSelected = [];
    const item = {
      pickupAccount: list?.accountNumber,
      shipmentId: list?.shipmentNumber,
      postalCode: list?.deliveryAddress?.postalCode,
    };
    itemsToBeSelected.push(item);
    const payload = itemsToBeSelected;
    axiosConfig
      .post(ServiceEndPoints.cancelShipment, payload)
      .then((response) => {
        dispatch(actions.setAppSnackBarData({ msg: 'Shipment successfully cancelled' }));
        // history.goBack()
        history.push({
          pathname: '/shipment/shipmentmanagement',
          state: {
            itemsToBeSelected: itemsToBeSelected,
            shipmentSummary: true,
          },
        });
      })
      .catch((error) => {
        dispatch(
          actions.setBottomAlert({
            msgs: [
              {
                type: 'error',
                msg: 'Error in cancelling the shipment:' + payload[0].shipmentId,
              },
            ],
          }),
        );
        // dispatch(
        //   actions.setAppSnackBarData({
        //     type: 'error',
        //     msg: 'Error in cancelling the shipment:' + payload[0].shipmentId,
        //   }),
        // );
      });
  };
  const getCustomsDetails = () => {
    axiosConfig
      .get(`${ServiceEndPoints.getCustomsDeclaration}?shipmentId=${id}`)
      .then((res) => {
        setCustomsDetails(res.data);
        setShowCustomDetails(true);
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          actions.setBottomAlert({
            msgs: [
              {
                type: 'error',
                msg: 'Error in fetching customs details',
              },
            ],
          }),
        );
        // dispatch(actions.setAppSnackBarData({ type: 'error', msg: 'Error in fetching customs details' }));
      });
  };

  const handleCancel = () => {
    setConfirmationMsg('Are you sure you want to cancel the shipment?');
    setShowCancelConfirmationDialog(true);
  };

  const handleCancelDialogClose = () => {
    setShowCancelConfirmationDialog(false);
  };

  const onCancelDialogConfirm = () => {
    setShowCancelConfirmationDialog(false);
    cancelShipment();
  };

  const getProductDetails = (details) => {
    axiosConfig
      .get(`${ServiceEndPoints.productCountryFeature}?countrycode=${details?.deliveryAddress?.countryCode}&orderedProduct=${details?.productDetail?.orderedProduct}`)
      .then((res) => {
        if (res.data[0]?.customsLevel === 'full') {
          setFullCustom(true)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const goToAmmendPage = () => {
    history.push(`/shipment/addShipment/${list?.shipmentNumber}`)
  }
  return (
    <React.Fragment>
      <Box boxShadow={3} bgcolor="background.paper" m={1} p={1} className={`${classes.boxContainer} shipment_box`}>
        <CustomHeader heading="Setup">
          <SetupSummary data={list} />
        </CustomHeader>
        <CustomHeader heading="Parcel details">
          <ParcelSummary shipmentSummary={list} accountDetails={accountDetails} shipmentmanagement={true} />
        </CustomHeader>
        <CustomHeader heading="Delivery address">
          <Grid container spacing={3} direction="row" lg={6}>
            <AddressFormate
              shipmentManagement={true}
              addressType={((list || {}).deliveryAddress || {}).addressType}
              contactName={((list || {}).deliveryAddress || {}).recipientName}
              businessName={((list || {}).deliveryAddress || {}).businessName}
              addressLine1={((list || {}).deliveryAddress || {}).address1}
              addressLine2={((list || {}).deliveryAddress || {}).address2}
              addressLine3={((list || {}).deliveryAddress || {}).address3}
              town={((list || {}).deliveryAddress || {}).city}
              country={((list || {}).deliveryAddress || {}).country}
              postCode={((list || {}).deliveryAddress || {}).postalCode}
              emailAddress={((list || {}).deliveryAddress || {}).email}
              telephoneNumber={((list || {}).deliveryAddress || {}).contactNumber}
            />
          </Grid>
        </CustomHeader>
        <CustomHeader heading="Delivery options">
          <DeliveryOptionsSummary
            deliveryOptions={(list || {}).deliveryOptions || {}}
            deliveryChoice={(list || {}).deliveryChoice || {}}
            servicePoint={((list || {}).deliveryAddress || {})?.businessName}
          />
        </CustomHeader>
        <CustomHeader heading="Product and service">
          <ProductAndServiceSummary data={(list || {}).productDetail} />
        </CustomHeader>
        <CustomHeader heading="Additional information about your shipment">
          <AdditionalInfoSummary
            additionalInfo={(list || {}).additionalInformation}
            destinationCountry={list.destinationCountry}
          />
        </CustomHeader>
        <CustomHeader heading="Return option">
          <ReturnOptionsSummary data={(list || {}).returnOption || {}} />
        </CustomHeader>
        {!isDomestic && (
          fullCustom ?
            (
              <Grid className="view-custom-icon" container direction="row" justify="flex-end" alignItems="center">
                <Grid item>
                  <InputButton
                    variant="text"
                    className="plain-btn"
                    label="View customs details"
                    startIcon={<SvgIcon fontSize="small" component={EyesIcon} viewBox="1 1 30 30" />}
                    onClick={getCustomsDetails}
                    isDisabled={showCustomDetails}
                    style={{ color: '#000000 !important' }}
                  />
                </Grid>
                <Grid item></Grid>
              </Grid>
            )
            :
            <>
              <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3}>
                <Grid item xs={3}>
                  <CustomCaption title="Description of goods" value={(list || {})?.additionalInformation?.shipmentDesc} />
                </Grid>
                <Grid item xs={3}>
                  <CustomCaption title="Value" value={(list || {})?.additionalInformation?.valueOfGoods} />
                </Grid>

                <Grid item xs={3}>
                  <CustomCaption title="Currency" value={((list || {})?.additionalInformation?.currency)} />
                </Grid>

                <Grid item xs={3}></Grid>
              </Grid>
            </>
        )}
        {showCustomDetails && (
          <>
            <CustomHeader heading="Customs declaration">
              <CustomDetailsSummary shipmentData={customsDetails} />
            </CustomHeader>
          </>
        )}
      </Box>

      <Grid
        container
        spacing={3}
        direction="row"
        justify="flex-end"
        alignItems="flex-end"
        className="Top-10px shipment-btn-continer"
      >
        <Grid item>
          <InputButton
            style={{ textTransform: 'unset' }}
            variant="text"
            className="text-btn"
            label="Back"
            onClick={() => backToBookedCollection()}
          />
        </Grid>
        {list?.destinationCountry?.toLowerCase() === "united kingdom" && !(list?.deliveryOptions?.exchangeOnDelivery) &&
          <Grid item>
            <InputButton className="outlined-btn" variant="outlined" label="Amend shipment" onClick={() => { goToAmmendPage() }} />
          </Grid>}
        <Grid item>
          <InputButton className="outlined-btn" variant="outlined" label="Cancel shipment" onClick={handleCancel} />
        </Grid>
        {/* {!isDomestic ? (
          <Grid item style={{ marginRight: '20px' }}>
            <InputButton variant="contained" className="contained-btn" label="View customs details" />
          </Grid>
        ) : null} */}

        <Grid item>
          <InputButton
            variant="contained"
            className="contained-btn"
            onClick={() => setshowPrintDialog(true)}
            buttonType="submit"
            label="Reprint"
          />
        </Grid>
      </Grid>
      <Grid container className="customModal-book">
        <CustomModal
          className="print-pop"
          open={showPrintDialog}
          title="Select type of reprint"
          onClose={handleDialogClose}
        >
          <Grid container direction="column">
            <Grid item>
              <FormControlLabel
                control={<Checkbox checked={state.reprintLabel} onChange={handleChange} name="reprintLabel" />}
                label="Label"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.reprintCustomsInvoice}
                    onChange={handleChange}
                    name="reprintCustomsInvoice"
                  />
                }
                label="Customs Invoice"
              />
            </Grid>
            <Grid>
              <span>Customs invoice will be printed only if applicable for a shipment</span>
            </Grid>
            <br />
            <Grid item xs={12} justify="flex-end" alignItems="flex-end">
              <InputButton
                variant="contained"
                disabled={state.reprintLabel || state.reprintCustomsInvoice ? false : true}
                className="contained-btn print-btn"
                buttonType="submit"
                label="Print"
                onClick={savePrintDialog}
              />
            </Grid>
          </Grid>
        </CustomModal>
      </Grid>
      <Grid container item className="customModal-book">
        <CustomModal
          open={showCancelConfirmationDialog}
          title="Cancel shipment"
          onClose={handleCancelDialogClose}
          maxWidth="sm"
        >
          <ConfirmationDialog
            confirmationMessage={confirmationMsg}
            onConfirm={onCancelDialogConfirm}
            OnCancel={handleCancelDialogClose}
          />
        </CustomModal>
      </Grid>
      <CustomDialog open={openJSPMPrompt} onClose={handleClose} title="JSPM">
        <PromptJSPMClientInstallation />
      </CustomDialog>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetails: state.auth.userDetails,
    JSPMStatus: state.common.isJSPMInstalled,
    printerConfiguration: state.common.printerConfiguration,
    availablePrinter: state.common.availablePrinter,
  };
};

export default connect(mapStateToProps, null)(ShipmentSummary);
