import * as actionTypes from '../actions/actionsTypes';

import { updateObject } from '../../utils/common';

const initialState = {
  userAccounts: [],
  nonReturnuserAccounts: [],
  selectedAccount: {},
  displayAccounts: [],
};

const setUSerAccounts = (state, action) => {
  return updateObject(state, {
    userAccounts: action.data,
  });
};
const setNonReturnUserAccounts = (state, action) => {
  return updateObject(state, {
    nonReturnuserAccounts: action.data,
  });
};

const setSelectedAccount = (state, action) => {
  return updateObject(state, {
    selectedAccount: action.data,
  });
};

const setDisplayAccounts = (state, action) => {
  return updateObject(state, {
    displayAccounts: action.data,
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SETUSERACCOUNTS:
      return setUSerAccounts(state, action);
    case actionTypes.SETNONRETURNUSERACCOUNTS:
      return setNonReturnUserAccounts(state, action);
    case actionTypes.SETSELECTEDACCOUNT:
      return setSelectedAccount(state, action);
    case actionTypes.SETDISPLAYACCOUNTS:
      return setDisplayAccounts(state, action);
    default:
      return state;
  }
};

export default reducer;
