import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider } from '@material-ui/core';
import InputButton from '../../../framework/Inputs/inputButton/inputButton';
import { POST } from '../../../api/axios';
import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';
import Spinner from '../../../framework/spinner/spinner'

const classes = {};

const ReturnInstructionsPreview = (props) => {
  const { formikValues, filePath, closePopup } = props;
  const [pdfFile, setPdfFile] = useState('');

  useEffect(() => {
    POST(ServiceEndPoints.returnInstructionLabelPreview, {
      labelFormat: 'pdf',
      returnLabel: true,
      returnInstructionLabel: true,
      returnInstructions: {
        customerLogo: filePath,
        customerLogoBase64: filePath,
        customerLogoType: 'png',
        printLabel: true,
        customerInstructions: [
          {
            CustomerInstruction: formikValues.returnInstructions,
          },
        ],
      },
    })
      .then((res) => {
        setPdfFile(res.data.label);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    pdfFile
      ? <Grid>
        <iframe
          src={`data:application/pdf;base64,${pdfFile}`}
          title="return label"
          style={{ width: '100%', height: '500px' }}
        ></iframe>

        <Grid container justify="flex-end">
          <Grid item>
            <InputButton variant="outlined" className="outlined-btn" label="Cancel" onClick={closePopup} />
          </Grid>
        </Grid>
      </Grid>
      :      <Spinner></Spinner>

  );
};

ReturnInstructionsPreview.propTypes = {};

export default ReturnInstructionsPreview;
