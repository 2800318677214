import React from 'react';
import { Form, Formik } from 'formik';
import './trackingFindShipmentStyle.scss';
import FindShipment from './TrackingFindShipment';
import { getYupSchemaFromMetaData } from '../../../../utils/yupSchema/yupSchemaGenerator';
import { fullSearchDashboardByOptions } from '../../../../utils/constants/constants';
const TrackingFindShipment = (props) => {
    const formField = searchForm;
    const newSearchValidationSchema = getYupSchemaFromMetaData(formField, [], []);

    const initialValues = {
        // dashboardSearchBy: fullSearchDashboardByOptions[0],
    };

    return (
        <div>
            <Formik initialValues={initialValues} enableReinitialize={true} validationSchema={newSearchValidationSchema}>
                {({ values, setFieldValue, errors, setFieldTouched, resetForm, setValues }) => (
                    <Form >
                        <FindShipment
                            formField={formField}
                            searchByOptions={fullSearchDashboardByOptions}
                            formikValues={values}
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            resetForm={resetForm}
                            setValues={setValues}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default TrackingFindShipment;

const searchForm = {
    dashboardSearchBy: {
        props: {
            name: 'dashboardSearchFor',
            type: 'searchControl',
        },
        selectProps: {
            props: {
                name: 'dashboardSearchBy',
                label: 'Search For',
                placeholder: "Enter Customer's ref.no",
            },
        },
        textProps: {
            props: {
                name: 'dashboardSearchFor',
                label: 'Search For',
                placeholder: "Enter Customer's ref.no",
            },
        },
        validation: {
            name: 'dashboardSearchFor',
            validationType: 'string',
            validations: [
                {
                    type: 'when',
                    params: [
                        'dashboardSearchBy',
                        (dashboardSearchBy, schema) => {
                            if (dashboardSearchBy) {
                                return dashboardSearchBy.value === 'recipientName'
                                    ? schema.matches(/[a-zA-Z]+([\s][a-zA-Z]+)*/, 'Recipient name must be alphabets')
                                    : dashboardSearchBy.value === 'telephoneNumber'
                                        ? schema.matches(/^[0-9,(,),+,\-,.,[,\] ].{9,35}/, 'Telephone number is invalid')
                                        : schema;
                            }
                        },
                    ],
                },
            ],
        },
    },
};