import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import FormRenderer from '../../../framework/Inputs/formRenderer/formRenderer';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { axiosConfig, GET, POST } from '../../../api/axios';
import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';
import { setDropdownValues, getCustomerAccountID } from '../../../utils/helperFunctions';

const useStyles = makeStyles((theme) => ({
  SearchProcessOrderField: {
    marginTop: '-40px',
  },
}));

const SearchOrders = (props) => {
  const inputRef = React.useRef();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    formField: { processorderSearchFor, isScan, isScanPrint },
    setFieldValue,
    isFavourites,
    searchByOptions,
    errors,
    setFieldError,
    setErrors,
    setFieldTouched,
  } = props;
  let formProcessOrdersearchFor = '';
  let [isClearBtn, setIsClearBtn] = useState(false);
  const [barCodeLength, setbarCodeLength] = React.useState(0);
  const [barCodeStartPosition, setbarCodeStartPosition] = React.useState(0);
  const [barCodeEndPosition, setbarCodeEndPosition] = React.useState(0);
  const [fieldToSearchBy, setfieldToSearchBy] = React.useState('');
  let [isConfigure, setIsConfigure] = useState(false);
  const keyPressed = useSelector((state) => state.keyPressEvent.key);
  const storedFavorites = sessionStorage.getItem('favorite');
 
  const getClear = (event = null, queryParams = '') => {
    if (event) {
      event.preventDefault();
    }
    setIsClearBtn(false);
    props.onProcessOrdersChange(false);
    setFieldValue('processorderSearchFor', '');
    setFieldTouched('processorderSearchFor', false);
    dispatch(actions.setAddressBookSearchParams(''));
  };

  const getProcessOrders = (event = null) => {
    if (event) {
      event.preventDefault();
    }
    props.onProcessOrdersChange(true);
    setIsClearBtn(true);
  };

  useEffect(() => {
    dispatch(
      actions.setAddressBookSearchParams(
        `&${props.formikValues.processorderSearchBy.value}=${encodeURIComponent(props.formikValues.processorderSearchFor)}`,
      ),
    );
  }, [props.formikValues.processorderSearchBy, props.formikValues.processorderSearchFor]);

  // useEffect(() => {
  //   setFieldValue('processorderSearchFor', '');
  //   setIsClearBtn(false);
  // }, [setFieldValue]);

  useEffect(() => {
    setIsClearBtn(false);
  }, [props.formikValues.processorderSearchFor]);

  useEffect(() => {
    if (props.isOnlyOneItem && props.formikValues.isScanPrint) {
      // dispatch(actions.setAddressBookSearchParams(`&${props.formikValues.processorderSearchBy.value}=`));
      setFieldValue('processorderSearchFor', '');
      setFieldTouched('processorderSearchFor', true);
      inputRef.current.focus();
      props.onProcessOrdersSelectedItem(false);
      getProcessOrders();
    }
  }, [props.isOnlyOneItem]);

  useEffect(() => {
    if (props.isRefersFlag) {
      setFieldValue('processorderSearchFor', '');
      if (storedFavorites && JSON.parse(storedFavorites).length > 0 && searchByOptions.some(obj => obj.value === JSON.parse(storedFavorites)[0].value)) {
        setFieldValue('processorderSearchBy', JSON.parse(storedFavorites)[0])
      }
      else{
        setFieldValue('processorderSearchBy', searchByOptions[0]);
      }
      getProcessOrders();
      if(!props.formikValues.isScanPrint){
        props.UploadrefreshOrder(false);
      }
    }
  }, [props.isRefersFlag]);

  useEffect(() => {
    setFieldValue('processorderSearchFor', '');
    setFieldTouched('processorderSearchFor', true);
    inputRef.current.focus();
  }, [props.formikValues.processorderSearchBy.value]);

  useEffect(() => {
    GET(`${ServiceEndPoints.getBarcode}?organization=${getCustomerAccountID()}`)
      .then((res) => {
        setbarCodeLength(res.data.barCodeLength);
        setbarCodeStartPosition(res.data.startPosition);
        setbarCodeEndPosition(res.data.finalPosition);
        setfieldToSearchBy(res.data.fieldToSearchBy);
        setIsConfigure(true);
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      dispatch(actions.setAddressBookSearchParams(''));
    }

  }, []);

  useEffect(() => {
    if (keyPressed === 'Enter' && (props.formikValues.isScan || props.formikValues.isScanPrint) && isConfigure && !isClearBtn) {
      dispatch(actions.setKeyPressEvent(''));
      formProcessOrdersearchFor = props.formikValues.processorderSearchFor;
      if (fieldToSearchBy === 'customerReference') {
        setFieldValue(
          'processorderSearchBy',
          setDropdownValues(searchByOptions || [], 'value', searchByOptions[0].value),
        );
      }
      if (fieldToSearchBy === 'alternateReference') {
        setFieldValue(
          'processorderSearchBy',
          setDropdownValues(searchByOptions || [], 'value', searchByOptions[4].value),
        );
      }
      if (barCodeLength !== 0 && barCodeLength !== formProcessOrdersearchFor.length) {
        setFieldError('processorderSearchFor', 'Barcode length different than what is configured', false);
      }
      formProcessOrdersearchFor = formProcessOrdersearchFor.slice(barCodeStartPosition - 1, barCodeEndPosition);
      setFieldValue('processorderSearchFor', formProcessOrdersearchFor);
      getProcessOrders()
      setFieldTouched('processorderSearchFor', true);
    }
    setIsClearBtn(true);
  }, [keyPressed]);

  const ScannerThing = () => {
  };

  useEffect(() => {
    setFieldValue('processorderSearchFor', '');
    if (storedFavorites && JSON.parse(storedFavorites).length > 0 && searchByOptions.some(obj => obj.value === JSON.parse(storedFavorites)[0].value)) {
      setFieldValue('processorderSearchBy', JSON.parse(storedFavorites)[0])
    }
    else{
      setFieldValue('processorderSearchBy', searchByOptions[0]);
    }
    dispatch(actions.setAddressBookSearchParams(''));
  }, [props.tabIndex]);

  useEffect(() => {
    if (props.formikValues.isScan) {
      setFieldTouched('isScan', true);
      setFieldValue('isScanPrint', false)
      inputRef.current.focus();
      // if (props.formikValues.processorderSearchBy !== searchByOptions[5]) {
      //   setFieldValue('processorderSearchFor', '');
      // }
    }
    props.onProcessOrdersScanFalgChange(props.formikValues.isScan, false)
  }, [props.formikValues.isScan, props.formikValues.processorderSearchBy]);

  useEffect(() => {
    if (props.formikValues.isScanPrint) {
      setFieldTouched('isScanPrint', true);
      setFieldValue('isScan', false)
      inputRef.current.focus();
      // if (props.formikValues.processorderSearchBy != searchByOptions[5]) {
      //   setFieldValue('processorderSearchFor', '');
      // }
    }
    props.onProcessOrdersScanFalgChange(props.formikValues.isScanPrint, true)
  }, [props.formikValues.isScanPrint, props.formikValues.processorderSearchBy]);

  // useEffect(() => {
  //   if (
  //     props.formikValues.processorderSearchBy.value === 'dispatchDate' &&
  //     props.formikValues.processorderSearchFor === ''
  //   ) {
  //     setFieldValue('processorderSearchFor', '');
  //   }
  // }, [props.formikValues.processorderSearchBy]);

  return (
    <div className={classes.SearchProcessOrderField}>
      <Grid container direction="row" justify="flex-end" alignItems="flex-start" spacing={3} style={{ marginTop: '15px', width: '750px', marginLeft: 'auto', marginRight: '0' }}>
        {props.tabIndex == 0 &&
          <Grid item lg={2.5} className="scanner-container">
            <FormRenderer
              style={{ opacity: '1 !important' }}
              {...isScanPrint.props}
            />
          </Grid>}
        <Grid item lg={2} className="scanner-container">
          <FormRenderer
            style={{ opacity: '1 !important' }}
            // isDisabled={
            //   formikValues[addToFavControlName] || (formikValues[customerNumberControl] || '').trim().length < 1
            // }
            // fullWidth
            {...isScan.props}
          />
        </Grid>
        <Grid lg={7}>
          <FormRenderer
            Scanner={ScannerThing()}
            isScanned={props.formikValues.isScan || props.formikValues.isScanPrint}
            data={searchByOptions || []}
            onSearchClick={(event) => getProcessOrders(event, '')}
            onClearSearch={(event) => getClear(event, '')}
            // isIconDisabled={errors.processorderSearchFor}
            selectProps={processorderSearchFor.selectProps.props}
            textProps={processorderSearchFor.textProps.props}
            isClearBtn={isClearBtn}
            inputRef={inputRef}
            favouriteFlag={true}
            {...processorderSearchFor.props}
          />
        </Grid>

        {/* {isFavourites
? null
: (
          <Grid item xs={12} sm={2} lg={3} md={3}>
            <InputButton variant="outlined" className="outlined-btn" label="Add New" onClick={handleClickOpen} />
          </Grid>
        )}
        <Grid container>
          <CustomizedDialogs
            open={open}
            onClose={handleClose}
            onAddNewButtonClick={onAddNewButtonClick}
            title="Add new address book entry"
          >
            <AddressBookModalContent onClose={handleClose} totalRecords={props.totalRecords} {...props} />
          </CustomizedDialogs>
        </Grid> */}
      </Grid>
    </div>
  );
};

SearchOrders.propTypes = {
  formField: PropTypes.object,
  formikValues: PropTypes.object,
  onProcessOrdersChange: PropTypes.func,
  totalRecords: PropTypes.string,
  setFieldValue: PropTypes.func,
  isFavourites: PropTypes.object,
  setFieldTouched: PropTypes.func,
};

export default SearchOrders;
