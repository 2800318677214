import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import * as actions from '../../../../../store/actions/index';
import { getYupSchemaFromMetaData } from '../../../../../utils/yupSchema/yupSchemaGenerator';
import CustomAccordion from '../../../../../framework/accordion/customAccordion';
import ParcelDetails from '../../../../../container/shipment/addShipment/parcelDetails/parcelDetails';
import MarketplaceCommodityDetails from './marketplaceCommodityDetails/marketplaceCommodityDetails';
import internationDefaultsForm from '../../../../../utils/formConfig/configuration/orderFileDefaultsForm/internationalDefaultsForm';
import {
    formatParcelDetails,
    getParsedFloatValues,
    getMarketplaceCommodityDetails,
    setValuesToParcelDetails,
    setMarketplaceCommodityDetails,
    removeUndefinedProperties,
    getCustomerAccountID,
    setDropdownValues,
} from '../../../../../utils/helperFunctions';
import InputButton from '../../../../../framework/Inputs/inputButton/inputButton';
import { PATCH } from '../../../../../api/axios';
import { ServiceEndPoints } from '../../../../../utils/constants/ApiConstant';
import CustomModal from '../../../../../framework/modal/modal';
import ConfirmationDialog from '../../../../addressBook/confirmation/confirmation';
import FormRenderer from '../../../../../framework/Inputs/formRenderer/formRenderer';
import MarketplaceInvoiceDecAndShippingDtls from './marketplaceInvoiceDeclarationAndShippingDetails/marketplaceInvoiceDecAndShippingDtls';
import * as CONSTANTS from '../../../../../utils/constants/constants';
import { axiosConfig } from '../../../../../api/axios';


const { formField } = internationDefaultsForm;

const MarketplaceIntlDefaults = (props) => {
    const { internationalDefaultValues, countries, getOrderDefaults, } = props;
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [marketPlacesList, setMarketPlacesList] = useState([]);
    const [orderFileDetailsValidationSchema, setorderFileDetailsValidationSchema] = useState(
        getYupSchemaFromMetaData(formField, [], []),
    );
    const formikRef = useRef(null);
    const initialValues = {};
    const dispatch = useDispatch();

    for (let property in formField) {
        if (Object.prototype.hasOwnProperty.call(formField, property)) {
            const field = formField[property];
            if (field.props.type === 'checkbox' || field.props.type === 'radio') {
                initialValues[field.props.name] = field.value;
            } else if (field.props.type === 'array') {
                initialValues[field.props.name] = field.values;
            } else {
                initialValues[field.props.name] = field.value || '';
            }
        }
    }

    useEffect(() =>{
            axiosConfig
            .get(`${ServiceEndPoints.getMarketplacesList}`)
            .then((response) => {
                setMarketPlacesList(response.data);
            })
            .catch((err) => {
                setMarketPlacesList([]);
            })
    },[]);

    useEffect(() => {
        if (internationalDefaultValues) {
            const customDeclaration = internationalDefaultValues?.customDeclaration;
            const parcelDimensions = (internationalDefaultValues?.parcelDetails?.parcelDimensions || [])[0];
            internationalDefaultValues['destinationCountry'] = 'DER';
            const defaultValues = {
                ...setValuesToParcelDetails(internationalDefaultValues),
                invoiceTypeLabel: customDeclaration?.invoiceType === 1 ? CONSTANTS.reverseInvoiceType[1] : CONSTANTS.reverseInvoiceType[2],
                InvoiceNumber: customDeclaration?.invoiceNumber,
                customDeclarationShippingCharges: customDeclaration?.shippingCharges,
                customDeclarationCurrency: setDropdownValues(CONSTANTS.currenciesMarketplaces, 'code', customDeclaration?.currency),
                termsOfDelivery: setDropdownValues(CONSTANTS.TermsOfDelivery, 'label', customDeclaration?.termsOfDelivery),
                reasonForExport: setDropdownValues(CONSTANTS.reasonsForExportList, 'type', customDeclaration?.reasonForExport),
                marketplaceIossNo: internationalDefaultValues?.senderCustomsRegistrations?.[0]?.id,
                commodityDetails: setMarketplaceCommodityDetails(
                    customDeclaration?.customItemDetails,
                    countries,
                    marketPlacesList
                ),
                length: parcelDimensions?.length || '',
                width: parcelDimensions?.width || '',
                height: parcelDimensions?.height || '',
            };
            removeUndefinedProperties(defaultValues);
            formikRef.current.setValues({ ...formikRef.current.values, ...defaultValues });
        }
    }, [internationalDefaultValues, marketPlacesList]);

    const handleSubmit = async (formikValues, errors) => {
        if (Object.keys(await formikRef.current.validateForm(formikValues)).length === 0) {
            const reqObj = {
                parcelDetails: formatParcelDetails(formikValues, false, true),
                customDeclaration: {
                    currency: formikValues?.customDeclarationCurrency.code,
                    invoiceNumber: formikValues?.InvoiceNumber,
                    invoiceType: formikValues?.invoiceTypeLabel === 'proforma' ? 2 : 1,
                    termsOfDelivery: formikValues?.termsOfDelivery.label?.toString(),
                    reasonForExport: formikValues?.reasonForExport.type,
                    customItemDetails: getMarketplaceCommodityDetails(formikValues.commodityDetails),
                    subTotal: Number(formikValues.subTotal),
                    shippingCharges: getParsedFloatValues(formikValues.customDeclarationShippingCharges),
                    totalValue: Number(formikValues.totalValue),
                },
                senderCustomsRegistrations: [{
                    type: 'IOSS', id: formikValues.marketplaceIossNo
                }],
            };
            removeUndefinedProperties(reqObj);
            PATCH(`${ServiceEndPoints.getMarketplaceDefaults}/internationaldefaults?organization=${getCustomerAccountID()}`, reqObj)
                .then((res) => {
                    dispatch(actions.setAppSnackBarData({ msg: "International marketplace defaults saved successfully" }))
                    getOrderDefaults();
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        else {
            dispatch(actions.setBottomAlert({
                msgs: [
                    {
                        type: 'error',
                        msg: 'The form has errors, please correct and try again',
                    },
                ]
            }));
        }

    };

    const handleDialogClose = () => {
        setShowConfirmationDialog(false);
    };

    const resetForm = () => {
        formikRef?.current?.resetForm();
        handleDialogClose();
    };

    const updateValidationSchema = () => {
        setorderFileDetailsValidationSchema(getYupSchemaFromMetaData(formField, [], []));
    };

    return (
        <Grid container spacing={2} direction="column">
            <Formik
                initialValues={initialValues}
                validationSchema={orderFileDetailsValidationSchema}
                enableReinitialize={true}
                innerRef={formikRef}
                validateOnMount={true}
            >
                {({ values, setFieldValue, setFieldTouched, errors }) => {
                    return (
                        <Grid>
                            <CustomAccordion heading="Parcel details">
                                <ParcelDetails
                                    formField={formField}
                                    formikValues={values}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    isDomestic={false}
                                    setFieldTouched={setFieldTouched}
                                    isOrderDefaults={true}
                                    updateValidationSchema={updateValidationSchema}
                                />
                            </CustomAccordion>
                            <CustomAccordion heading="Customs declaration" className="returnOptions-accordion">
                                <Grid container direction="column" spacing={3} style={{ flexFlow: 'wrap' }}>
                                    <MarketplaceInvoiceDecAndShippingDtls formField={formField} setFieldValue={setFieldValue} currencies={CONSTANTS.currenciesMarketplaces} reasonsForExportList={CONSTANTS.reasonsForExportList} />
                                    <Grid item xs={12}>
                                        <MarketplaceCommodityDetails
                                            formField={formField}
                                            formikValues={values}
                                            setFieldValue={setFieldValue}
                                            errors={errors}
                                            isOrderDefaults={true}
                                            marketPlacesList={marketPlacesList}
                                        />
                                    </Grid>
                                    <Grid container item direction="col" style={{ paddingBottom: '25px' }}>
                                        <Grid item xs={6} sm={6} md={6} lg={6} style={{ paddingRight: '20px' }}>
                                            <FormRenderer
                                                {...formField.marketplaceIossNo.props}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} lg={6} style={{ paddingLeft: '20px' }}>
                                            <FormRenderer {...formField.termsOfDelivery.props} data={CONSTANTS.TermsOfDelivery} hasDefaultValue={true} fullWidth />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CustomAccordion>
                            <Grid container className="international-btn-container" direction="row" justify="flex-end" spacing={3}>
                                <Grid item>
                                    <InputButton
                                        variant="outlined"
                                        className="outlined-btn"
                                        onClick={() => setShowConfirmationDialog(true)}
                                        label="Reset"
                                    />
                                </Grid>
                                <Grid item>
                                    <InputButton
                                        variant="contained"
                                        className="contained-btn"
                                        onClick={() => handleSubmit(values, errors)}
                                        label="Save changes"
                                        buttonType="submit"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                }}
            </Formik>

            <Grid item></Grid>
            <CustomModal open={showConfirmationDialog} title="Reset Form" onClose={handleDialogClose} maxWidth="sm">
                <ConfirmationDialog
                    confirmationMessage="Are you sure you want to reset?"
                    onConfirm={resetForm}
                    OnCancel={handleDialogClose}
                />
            </CustomModal>
        </Grid>
    );
};

MarketplaceIntlDefaults.propTypes = {};

export default MarketplaceIntlDefaults;

