const AuditEnquiryForm = {
  formId: 'auditEnquiryForm',
  formField: {
    shipmentNumber: {
      props: {
        name: 'shipmentNumber',
        label: 'Shipment number',
        type: 'text',
      },
      validation: {
        name: 'shipmentNumber',
        validationType: 'string',
        validations: [
          {
            type: 'matches',
            params: [/^[A-Za-z0-9]*$/, 'Invalid shipment number'],
          },
        ],
      },
    },
    exportTo: {
      props: {
        name: 'exportTo',
        label: 'Export to',
        type: 'select',
        displayField: 'name',
      },
    },
    userId: {
      props: {
        name: 'userId',
        label: 'User id',
        type: 'autoComplete',
        isRequired: true,
        displayField: 'name',
        hasDefaultValue: true,
      },
    },
    startDate: {
      props: {
        name: 'startDate',
        label: 'Date range',
        type: 'date',
      },
    },
    endDate: {
      props: {
        name: 'endDate',
        label: 'Date range',
        type: 'date',
      },
    },
    dateRange: {
      props: {
        name: 'dateRange',
        label: 'Date range',
        type: 'input',
        placeholder: 'dd/mm/yyyy - dd/mm/yyyy',
      },
      Value:'Select'
    },
  },
};

export default AuditEnquiryForm;
