import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider, Checkbox, Box} from '@material-ui/core';
import CustomAccordion from '../../../../framework/accordion/customAccordion';
import OrderSetupForm from '../../../../utils/formConfig/configuration/orderSetupForm/orderSetupForm';
import { Form, Formik } from 'formik';
import { GET, POST } from '../../../../api/axios';
import FormRenderer from '../../../../framework/Inputs/formRenderer/formRenderer';
import DynamicMaterialTable from '../../../../framework/DynamicMaterialTable/DynamicMaterialTable';
import InputButton from '../../../../framework/Inputs/inputButton/inputButton';
import { ReactComponent as ChooseIcon } from '../../../../../src/assets/images/ChooseIcon.svg';
import { SearchIcon } from '@material-ui/data-grid';
import { reverseTransferActionTypes, constantTransferActionTypes } from '../../../../utils/constants/constants';
import { getCustomerAccountID, sortBasedOnNestedOrNot, customTableSort, setDropdownValues } from '../../../../utils/helperFunctions';
import './orderSetup.scss';
import '../../../../styles/styles.scss';
import { getYupSchemaFromMetaData } from '../../../../utils/yupSchema/yupSchemaGenerator';
import { ServiceEndPoints } from '../../../../utils/constants/ApiConstant';
import * as actions from "../../../../store/actions/index"
import { useDispatch, connect} from 'react-redux';
import ConfirmationDialog from '../../../addressBook/confirmation/confirmation';
import CustomModal from '../../../../framework/dialog/customDialog';
import OutputFile from './outputFile';

const { formField } = OrderSetupForm;
let reqFields = [];
const OrderSetup = (props) => {
  const initialValues = {};
  const formikRef = useRef(null);
  const [orderSetupResponse, setOrderSetupResponse] = useState({});
  const [activeSchemas, setActiveSchemas] = useState([]);
  const orderSetupValidationSchema = getYupSchemaFromMetaData(formField, [], []);
  const [dragToValues, setDragToValues] = useState(selectedOutputFileValues);
  const [dragFromValues, setDragFromValues] = useState(sortBasedOnNestedOrNot(outputFileValues, "name"));

  for (let property in formField) {
    if (Object.prototype.hasOwnProperty.call(formField, property)) {
      const field = formField[property];
      if (field.props.type === 'checkbox' || field.props.type === 'radio') {
        initialValues[field.props.name] = field.value;
      } else if (field.props.type === 'array') {
        initialValues[field.props.name] = field.values;
      } else {
        initialValues[field.props.name] = field.value || '';
      }
    }
  }

  const dispatch = useDispatch();

  const columns = [
    {
      title: 'Schema name',
      field: 'schemaName',
      customSort: (a, b) => customTableSort(a.schemaName, b.schemaName),
      width: 150,
    },
    {
      title: 'Schema type',
      field: 'schemaType',
      customSort: (a, b) => customTableSort(a.schemaType, b.schemaType),
      width: 100,
    },
    {
      title: 'File polling folder',
      field: 'filePollingFolder',
      customSort: (a, b) => customTableSort(a.filePollingFolder, b.filePollingFolder),
      width: 350,
    },
    {
      title: 'Is enabled?',
      field: 'isEnabled',
      sorting: false,
      width: 100,
      cellStyle: {
        textAlign: 'center'
      },
      render: (row) => (
        <div>
          <Checkbox checked={row.isEnabled} onChange={(event) => handleChange(event, row)} />
        </div>
      ),
    },
  ];


  const handleChange = (event, row) => {
    const tableData = [...activeSchemas];
    (tableData[row?.tableData?.id] || {}).isEnabled = event.target.checked;
    setActiveSchemas(tableData);
  };

  useEffect(() => {
    getOrderSetupResponse();
  }, []);

  const getOrderSetupResponse = () => {
    GET(`${ServiceEndPoints.orderSetup}?organization=${getCustomerAccountID()}`)
      .then((res) => {
        setOrderSetupResponse(res.data);
        sortBasedOnNestedOrNot(res.data?.schemas || [], 'schemaName', false);
        setActiveSchemas(res.data.schemas || []);
      })
      .catch((err) => {
        console.log(err);
        setOrderSetupResponse({});
        setActiveSchemas([]);
      });
  };

  useEffect(() => {
    var outputFileSetUpValues = {
      outputExportDirectory: orderSetupResponse.outputFileSetUp?.outputExportDirectory,
      shipmentConfirmation: orderSetupResponse.outputFileSetUp?.shipmentConfirmation,
    }
    formikRef.current.setValues({
      ...formikRef.current.values,
      ...orderSetupResponse.importsSetup,
      // ...orderSetupResponse.outputFileSetUp,
      ...outputFileSetUpValues,
      transferAction:
        constantTransferActionTypes[orderSetupResponse?.importsSetup?.transferAction] || 'transferToHoldingArea',
    });
    props.setAutoReloadProcessOrders(orderSetupResponse.importsSetup?.refreshOrderGridOnScanAndPrint);
    if (orderSetupResponse.outputFileSetUp?.selectedOutputFileValues?.length > 0) {
      let selectedOutputValues = orderSetupResponse.outputFileSetUp.selectedOutputFileValues;
      let availableOutputValues = [];
      let dragFromValuesResp = [];
      // selectedOutputValues = dragToValues.filter((ele) => (selectedOutputValues.map((val) => val.valueName).includes(ele.valueName))).concat(dragFromValues.filter((ele) => (selectedOutputValues.map((val) => val.valueName).includes(ele.valueName))));
      availableOutputValues = dragToValues.filter((ele) => !(selectedOutputValues.map((val) => val.valueName).includes(ele.valueName)));
      dragFromValuesResp = dragFromValues.filter((ele) => !(selectedOutputValues.map((val) => val.valueName).includes(ele.valueName)));
      setTimeout(() => {
        setDragToValues(selectedOutputValues);
        setDragFromValues(sortBasedOnNestedOrNot(availableOutputValues.concat(dragFromValuesResp), "name"));
      }, 0);
    }
    else {
      setDragToValues(selectedOutputFileValues);
      setDragFromValues(sortBasedOnNestedOrNot(outputFileValues, "name"));
    }
    if (orderSetupResponse.outputFileSetUp?.manualShipment) {
      setTimeout(() => {
        formikRef.current?.setFieldValue('manualShipmentConfirmation', orderSetupResponse.outputFileSetUp?.manualShipment);
        formikRef.current?.setFieldValue('fileExtension', orderSetupResponse.outputFileSetUp?.fileExtension);
        if ((delimiterOptions.map((ele) => ele.value).includes(orderSetupResponse.outputFileSetUp?.delimiter))) {
          formikRef.current?.setFieldValue('delimiter', setDropdownValues(delimiterOptions, 'value', delimiterOptions.filter((val) => val.value === orderSetupResponse.outputFileSetUp?.delimiter)[0].value));
        }
        else {
          formikRef.current?.setFieldValue('delimiter', setDropdownValues(delimiterOptions, 'value', 'otherDelimiter'));
          formikRef.current?.setFieldValue('newDelimeter', orderSetupResponse.outputFileSetUp?.delimiter);
        }
      }, 0);
    }
  }, [orderSetupResponse]);

  const isFormInValid = (errors, values, setFieldTouched) => {
    let clonedReqFields = [...reqFields];
    if (values.shipmentConfirmation) {
      clonedReqFields.push('outputExportDirectory')
      setFieldTouched('outputExportDirectory',true);
      if (values.manualShipmentConfirmation) {
        clonedReqFields.push('fileExtension');
        setFieldTouched('fileExtension',true);
        if (values.delimiter.value === 'otherDelimiter') {
          clonedReqFields.push('newDelimeter');
          setFieldTouched('newDelimeter',true);
        }
      }
    }
    for (let err in errors) {
      if (clonedReqFields.some((fieldName) => fieldName === err)) {
        return true;
      }
    }
    return false;
  }

  const handleSubmit = (values, errors ,setFieldTouched) => {
    if (!isFormInValid(errors, values, setFieldTouched)) {
      const formikValues = formikRef.current.values;
      const reqObj = {
        organization: getCustomerAccountID(),
        importsSetup: {
          overWriteSameCustomerRef: formikValues.overWriteSameCustomerRef,
          overWriteSameAltRef: formikValues.overWriteSameAltRef,
          autoPostCollection: formikValues.autoPostCollection,
          includeOrderDefaults: formikValues.includeOrderDefaults,
          includeOrderMapping: formikValues.includeOrderMapping,
          autoUpdateDomesticService: formikValues.autoUpdateDomesticService,
          refreshOrderGridOnScanAndPrint: formikValues.refreshOrderGridOnScanAndPrint,
          includeMarketplaceDefaults: formikValues.includeMarketplaceDefaults,
          includeMarketplaceMapping: formikValues.includeMarketplaceMapping,
          transferAction: reverseTransferActionTypes[formikValues.transferAction],
          pollingInterval: parseInt(formikValues.pollingInterval),
        },
        outputFileSetUp: {
          shipmentConfirmation: formikValues.shipmentConfirmation,
          manualShipment: formikValues.shipmentConfirmation ? formikValues.manualShipmentConfirmation : false,
          fileExtension: formikValues.shipmentConfirmation ? formikValues.manualShipmentConfirmation ? formikValues.fileExtension : null : null,
          delimiter: formikValues.shipmentConfirmation ? formikValues.manualShipmentConfirmation ? formikValues.delimiter.value === 'otherDelimiter' ? formikValues?.newDelimeter.toString() : formikValues.delimiter.value : null : null,
          outputExportDirectory: formikValues.outputExportDirectory,
          selectedOutputFileValues: formikValues.shipmentConfirmation === true ? dragToValues : [],
        },
        schemas: [...activeSchemas],
      };
      POST(`${ServiceEndPoints.orderSetup}`, reqObj)
        .then((res) => {
          dispatch(actions.setAppSnackBarData({ msg: "Order setup saved successfully" }))
          getOrderSetupResponse();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      dispatch(
        actions.setBottomAlert({
          msgs: [{ type: 'error', msg: 'The form has errors, please correct and try again' }],
        }),
      );
    }
  };
  const [overWriteCustomerRef, setOverWriteCustomerRef] = useState();
  const [overWriteAltRef, setOverWriteAltRef] = useState();
  const [currentSelected, setCurrentSelected] = useState("");
  const [canShowPopupCustomerRef, setCanShowPopupCustomerRef] = useState(false);
  const [canShowPopupAltRef, setCanShowPopupAltRef] = useState(false);

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  useEffect(() => {
    if (!orderSetupResponse?.importsSetup?.overWriteSameCustomerRef && overWriteCustomerRef) {
      setCanShowPopupCustomerRef(true)
    }
    else {
      if (!overWriteCustomerRef && (canShowPopupCustomerRef || orderSetupResponse?.importsSetup?.overWriteSameCustomerRef)) {
        setCurrentSelected("overWriteSameCustomerRef");
        setShowConfirmationDialog(true)
      }
      setCanShowPopupCustomerRef(false);
    }


  }, [overWriteCustomerRef])

  useEffect(() => {
    if (!orderSetupResponse?.importsSetup?.overWriteSameAltRef && overWriteAltRef) {
      setCanShowPopupAltRef(true)
    }
    else {
      if (!overWriteAltRef && (canShowPopupAltRef || orderSetupResponse?.importsSetup?.overWriteSameAltRef)) {
        setCurrentSelected("overWriteSameAltRef");
        setShowConfirmationDialog(true)
      }
      setCanShowPopupAltRef(false)
    }

  }, [overWriteAltRef])

  const handleDialogClose = () => {
    setShowConfirmationDialog(false);
    formikRef.current.setFieldValue(currentSelected, true)

  }

  const onDialogConfirm = () => {
    setShowConfirmationDialog(false);
  }

  return (
    <Grid container direction="column" className="order-setup">
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        innerRef={formikRef}
        validationSchema={orderSetupValidationSchema}
      >
        {({ values, errors, setFieldValue, setFieldTouched }) => {
          setOverWriteCustomerRef(values.overWriteSameCustomerRef);
          setOverWriteAltRef(values.overWriteSameAltRef);
          return (
            <Grid container spacing={2} direction="column">
              <Grid className="accordion-main" item>
                <CustomAccordion heading="Imports setup">
                  <Grid container direction="row" spacing={4}>
                    <Grid item xs={5}>
                      <Grid container direction="column">
                        <Grid item>
                          <Grid container direction="row" justify="space-between" alignItems="center">
                            <Grid item xs={10} sm={10} md={10} lg={10}>Overwrite in queue with same customer. ref</Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2}>
                              <FormRenderer {...formField.overWriteSameCustomerRef.props} />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid container direction="row" justify="space-between" alignItems="center">
                            <Grid item xs={10} sm={10} md={10} lg={10}>Overwrite in queue with same alt ref</Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2}>
                              <FormRenderer {...formField.overWriteSameAltRef.props} />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid container direction="row" justify="space-between" alignItems="center">
                            <Grid item xs={10} sm={10} md={10} lg={10}>Automatically post collections</Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2}>
                              <FormRenderer {...formField.autoPostCollection.props} />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid container direction="row" justify="space-between" alignItems="center">
                            <Grid item xs={10} sm={10} md={10} lg={10}>Include order defaults</Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2}>
                              <FormRenderer {...formField.includeOrderDefaults.props} />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid container direction="row" justify="space-between" alignItems="center">
                            <Grid item xs={10} sm={10} md={10} lg={10}>Include order mapping</Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2}>
                              <FormRenderer {...formField.includeOrderMapping.props} />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid container direction="row" justify="space-between" alignItems="center">
                            <Grid item xs={10} sm={10} md={10} lg={10}>Auto update to the best service if chosen service is not available</Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2}>
                              <FormRenderer {...formField.autoUpdateDomesticService.props} />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid container direction="row" justify="space-between" alignItems="center">
                            <Grid item xs={10} sm={10} md={10} lg={10}>Auto refresh orders grid for scan and print</Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2}>
                              <FormRenderer {...formField.refreshOrderGridOnScanAndPrint.props} />
                            </Grid>
                          </Grid>
                        </Grid>
                        {props.isMarketplaceAccount && <> <Grid item>
                          <Grid container direction="row" justify="space-between" alignItems="center">
                            <Grid item xs={10} sm={10} md={10} lg={10}>Include marketplace defaults</Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2}>
                              <FormRenderer {...formField.includeMarketplaceDefaults.props} />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid container direction="row" justify="space-between" alignItems="center">
                            <Grid item xs={10} sm={10} md={10} lg={10}>Include marketplace mapping</Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2}>
                              <FormRenderer {...formField.includeMarketplaceMapping.props} />
                            </Grid>
                          </Grid>
                        </Grid></>}
                      </Grid>
                    </Grid>
                    <Divider className="Importtype-hr" orientation="vertical" flexItem />
                    <Grid item xs={6} style={{ paddingTop: '20px', paddingRight: '0' }}>
                      <Grid container direction="column" spacing={1} style={{ padding: '0px 0 0 25px' }}>
                        <Grid item>
                          <Grid container direction="row" justify="flex-start" alignItems="center">
                            <Grid item style={{ width: '185px' }}>
                              Action to carry out on transfer:
                            </Grid>
                            <Grid item style={{ width: '55%' }} className="action-carry-radio">
                              <FormRenderer {...formField.transferAction.props} data={transferActionTypes} />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item style={{ paddingTop: '20px' }}>
                          <Grid container direction="row" justify="flex-start" alignItems="center">
                            <Grid item xs={4}>
                              Polling Interval:
                            </Grid>
                            <Grid item xs={6}>
                              <FormRenderer {...formField.pollingInterval.props} data={transferActionTypes} />
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* <Grid container spacing={2} direction="row" alignItems="flex-start">
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box component="div" style={{ paddingTop: '15px', paddingBottom: '5px' }}>
                            <b>Process order setup</b>
                            </Box>
                          </Grid>
                        </Grid> */}
                        {/* <Grid item style={{paddingTop: "10px"}}>
                          <Grid container direction="row" justify="space-between" alignItems="center">
                            <Grid item xs={8} sm={8} md={8} lg={8}>Auto refresh orders grid for scan and print</Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4}>
                              <FormRenderer {...formField.autoReloadOrdersOnScanPrint.props} />
                            </Grid>
                          </Grid>
                        </Grid> */}
                      </Grid>
                    </Grid>
                  </Grid>
                </CustomAccordion>
                <CustomAccordion heading="Output file setup">
                  <Grid container direction="column" spacing={1}>
                    <Grid container direction="row" spacing={4} className="output-file-height">
                      <Grid item xs={12} >
                        <Grid container direction="row" spacing={2}>
                          <Grid item xs={5}>
                            <Grid container direction="row" justify="space-between" alignItems="center">
                              <Grid item xs={10} sm={10} md={10} lg={10}>Produce shipment confirmation</Grid>
                              <Grid item xs={2} sm={2} md={2} lg={2}>
                                <FormRenderer {...formField.shipmentConfirmation.props} />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6} className="directory-output">
                            <FormRenderer
                              {...formField.outputExportDirectory.props}
                              isDisabled={!values.shipmentConfirmation}
                              isRequired={values.shipmentConfirmation}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {values.shipmentConfirmation ? <Grid item>
                      <OutputFile
                        dragToValues={dragToValues}
                        dragFromValues={dragFromValues}
                        setDragToValues={setDragToValues}
                        setDragFromValues={setDragFromValues}
                        outputFileValues={outputFileValues}
                        selectedOutputFileValues={selectedOutputFileValues}
                        formField={formField}
                        formikValues={values}
                        setFieldValue={setFieldValue}
                      />
                    </Grid> : ''}
                  </Grid>
                </CustomAccordion>
                <CustomAccordion heading="Active schemas" style={{ width: '100%' }}>
                  <Grid className="table-container active-Schema-table wd-100">
                    <DynamicMaterialTable
                      rows={activeSchemas}
                      columns={columns}
                      isSelectable={false}
                      tableLayout="fixed"
                      sorting={true}
                      paging={false}
                      style={{ width: '100%' }}
                    />
                  </Grid>
                </CustomAccordion>
              </Grid>
              <Grid item>
                <Grid container direction="row" justify="flex-end" spacing={3}>
                  <Grid item className="orderfile-btn-container">
                    <InputButton label="Save" variant="contained" buttonType="submit" className="contained-btn"
                      onClick={() => {
                        handleSubmit(values, errors, setFieldTouched);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>)
        }}
      </Formik>
      <CustomModal open={showConfirmationDialog} title={"Confirmation"} onClose={handleDialogClose} maxWidth="sm">
        <ConfirmationDialog
          confirmationMessage={"There are active schemas using this configuration. Unchecking this configuration might cause error in uploading orders"}
          onConfirm={onDialogConfirm}
          OnCancel={handleDialogClose}
          confirmLabel="Ok"
          cancelLabel="Cancel"
        />
      </CustomModal>
    </Grid>
  );
};

OrderSetup.propTypes = {};

const mapDispatchToProps = (dispatch) => {
  return {
    setAutoReloadProcessOrders: (autoReloadOrdersFlag) => {
      dispatch(actions.setAutoReloadProcessOrders(autoReloadOrdersFlag));
    },
  };
};
export default connect(null, mapDispatchToProps)(OrderSetup);

const transferActionTypes = [
  {
    label: 'Transfer to process orders',
    value: 'transferToHoldingArea',
  },
  {
    label: 'Post with labels',
    value: 'postWithLabels',
  },
];

const outputFileValues = [
  {
    valueName: 'accountNumber',
    name: 'Account number',
  },
  {
    valueName: 'deliveryType',
    name: 'Delivery type',
  },
  {
    valueName: 'specialInstructions',
    name: 'Special instructions',
  },
  {
    valueName: 'inBoxReturn',
    name: 'Include inbox return label',
  },
  {
    valueName: 'returnCustomerReference',
    name: 'Return reference',
  },
  {
    valueName: 'extendedLiability',
    name: 'Extended liability',
  },
  {
    valueName: 'product',
    name: 'Product',
  },
  {
    valueName: 'service',
    name: 'Service',
  },
  {
    valueName: 'what3Words',
    name: 'What3Words',
  },
  {
    valueName: 'w3WLat',
    name: 'What3Words latitude',
  },
  {
    valueName: 'w3WLong',
    name: 'What3Words longitude',
  },
];

const selectedOutputFileValues = [
  {
    valueName: 'customerReferenceNumber',
    name: 'Customer reference',
  },
  {
    valueName: 'shipmentNumber',
    name: 'Shipment number',
  },
  {
    valueName: 'alternateReference',
    name: 'Alternative reference',
  },
  {
    valueName: 'postCode',
    name: 'Postcode',
  },
  {
    valueName: 'businessName',
    name: 'Business name',
  },
  {
    valueName: 'contactName',
    name: 'Recipient name',
  },
  {
    valueName: 'address1',
    name: 'Address line 1',
  },
  {
    valueName: 'items',
    name: 'No. of items',
  },
  {
    valueName: 'weight',
    name: 'Weight',
  },
  {
    valueName: 'orderedProduct',
    name: 'Service code',
  },
]

export const delimiterOptions = [
  {
    value: ',',
    name: 'Comma',
  },
  {
    value: '~',
    name: 'Tilde',
  },
  {
    value: '|',
    name: 'Pipe',
  },
  {
    value: '\\t',
    name: 'Tab',
  },
  {
    value: ';',
    name: 'Semicolon',
  },
  {
    value: 'otherDelimiter',
    name: 'Other',
  },
];