/* eslint-disable no-restricted-globals */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-useless-concat */
import * as React from 'react';
import { Doughnut } from 'react-chartjs-2';
import './doughnut.scss';
import { startOfDay } from 'date-fns';
import { useHistory, withRouter } from 'react-router';

import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import { getDefaultDashboardValues } from '../../utils/helperFunctions';
import { arrayValuesToQueryStringParam } from '../../utils/common';
import ReactHtmlParser from 'react-html-parser';

Chart.pluginService.register({
  beforeDraw: function (chart) {
    if (chart.config.options.elements.center) {
      // Get ctx from string
      var ctx = chart.chart.ctx;

      // Get options from the center object in options
      var centerConfig = chart.config.options.elements.center;
      var fontStyle = centerConfig.fontStyle || 'Arial';
      var txt = centerConfig.text;
      var color = '#000';
      var maxFontSize = centerConfig.maxFontSize || 75;
      var sidePadding = centerConfig.sidePadding || 20;
      var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
      // Start with a base font of 30px
      ctx.font = '17px ' + fontStyle;
      // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
      var stringWidth = ctx.measureText(txt).width;
      var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

      // Find out how much the font can grow in width.
      var widthRatio = elementWidth / stringWidth;
      var newFontSize = Math.floor(30 * widthRatio);
      var elementHeight = chart.innerRadius * 2;

      // Pick a new font size so it will not be larger than the height of label.
      var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
      var minFontSize = centerConfig.minFontSize;
      var lineHeight = centerConfig.lineHeight || 25;
      var wrapText = false;

      if (minFontSize === undefined) {
        minFontSize = 20;
      }

      if (minFontSize && fontSizeToUse < minFontSize) {
        fontSizeToUse = minFontSize;
        wrapText = true;
      }

      // Set font settings to draw it correctly.
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
      var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
      ctx.font = fontSizeToUse + 'px ' + fontStyle;
      ctx.fillStyle = color;

      if (!wrapText) {
        ctx.fillText(txt, centerX, centerY);
        return;
      }

      var words = txt.split(' ');
      var line = '';
      var lines = [];
      // Break words up into multiple lines if necessary
      for (var n = 0; n < words.length; n++) {
        var testLine = line + words[n] + ' ';
        var metrics = ctx.measureText(testLine);
        var testWidth = metrics.width;
        if (testWidth > elementWidth && n > 0) {
          lines.push(line);
          line = words[n] + ' ';
        } else {
          line = testLine;
        }
      }

      // Move the center up depending on line height and number of lines
      centerY -= (lines.length / 2) * lineHeight;

      for (var n = 0; n < lines.length; n++) {
        ctx.fillText(lines[n], centerX, centerY);
        centerY += lineHeight;
      }
      //Draw text in center
      ctx.fillText(line, centerX, centerY);
    }
  },
});

// var statusForRouting=data.datasets.map((datas=>datas.labelss));

export const valueAsPercentage = (value, total) => `${(value / total) * 100}%`;

class DoughnutChart extends React.Component {
  myRef;

  constructor(props) {
    super(props);

    this.myRef = null;
    this.state = { legend: <>no legend</> };

    this.createMarkup = this.createMarkup.bind(this);
    this.onClickFunc = this.onClickFunc.bind(this);
  }

  componentDidMount() {
    const leg = this.generateLegend();
    this.setState({ legend: leg });
  }

  setTextInputRef(element) {
    this.myRef = element;
  }

  generateLegend() {
    if (!this.myRef) return null;
    return this.myRef.chartInstance.generateLegend();
  }

  createMarkup() {
    return this.state.legend;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      const leg = this.generateLegend();
      let listItemsCollection = document.getElementsByClassName('list-items');
      this.setState({ legend: leg } , ()=> {
        for(let i=0;i<listItemsCollection.length;i++)
        {
          listItemsCollection[i].addEventListener('click' , () => {this.onClickFunc(i)});
        }
    });
  }
}

  onClickFunc = (index)=> {
              // let index = elems[0]._index;
              let label = this.props.data.datasets[0].label[index];
              this.props.dispatch({
                loadedFrom: 'onChartClick',
                loadedResponse: this.props.formikValues,
                dashboardQueryParams: getDefaultDashboardValues(this.props.formikValues) + '&status=' + label,
                accountsParams: arrayValuesToQueryStringParam(this.props.formikValues.accounts),
                tabIndex: this.props.tabIndex,
                products: this.props.productData,
                countries: this.props.countries,
                services: this.props.serviceData,
                status:label,
              });
              this.props.history.push('/dashboard/dashboardDetailsSummary');
  }

  render() {
    let segment;
    return (
      <div className="doughnut-container">
        <div className={this.props.text ? '' : 'doughnut-chart-number'}>
          {this.props.text ? '' : 'No data available for current selection'}
        </div>
        <Doughnut
          ref={(element) => this.setTextInputRef(element)}
          data={this.props.data}
          onElementsClick={(elems, chart) => {
            if (elems && (elems || []).length > 0) {
              let index = elems[0]._index;
              let label = this.props.data.datasets[0].label[index];
              this.props.dispatch({
                loadedFrom: 'onChartClick',
                loadedResponse: this.props.formikValues,
                dashboardQueryParams: getDefaultDashboardValues(this.props.formikValues) + '&status=' + label,
                accountsParams: arrayValuesToQueryStringParam(this.props.formikValues.accounts),
                tabIndex: this.props.tabIndex,
                products: this.props.productData,
                countries: this.props.countries,
                services: this.props.serviceData,
                status:label,
              });
              this.props.history.push('/dashboard/dashboardDetailsSummary');
            }
          }}
          options={{
            showAllTooltips: true,
            tooltips: {
              mode: 'label',
              callbacks: {
                label: function (tooltipItem, data) {
                  var dataset = data.datasets[tooltipItem.datasetIndex];
                  return dataset.label[tooltipItem.index] + ':' + dataset.data[tooltipItem.index];
                },
              },
            },
            responsive: true,
            aspectRatio: 1,

            legend: {
              display: false,
              position: 'right',
              align: 'start',
            },
            //onHover: function (evt, elements) {
            // if (elements && elements.length) {
            //   segment = elements[0];
            //   this.chart.update();
            //   let selectedIndex = segment['_index'];
            //   segment._model.outerRadius += 10;
            // } else {
            //   if (segment) {
            //     segment._model.outerRadius -= 10;
            //   }
            //   segment = null;
            // }
            // },
            elements: {
              center: {
                text: `${this.props.text ? 'Total ' + this.props.text : ''}`,
                color: '#FF6384',
                minimumuFontSize: 30,
                maxFontSize: 24,
                sidePadding: 20,
              },
            },
            legendCallback: function (chart) {
              if (chart.config.data.datasets.length > 0) {
                var legendHtml = [];
                legendHtml.push('<div class="legend-container">');
                legendHtml.push('<ul class="list-chart">');
                var item = chart.config.data.datasets[0];
                for (var i = 0; i < item.data.length; i++) {
                  legendHtml.push('<li class="list-items">');
                  legendHtml.push(
                    '<span class="chart-legend" style="background-color:' + item.backgroundColor[i] + '"></span>',
                  );
                  // legendHtml.push('<span class="chart-legend-label-text">' + item.data[i] + ' person - '+chart.data.labels[i]+' times</span>');
                  // legendHtml.push(`'<span class="chart-legend-label-text">'  ${chart.data.labels[i].percentage}'</span>'  '<span class="legend-Bar" style={{background-color:${item.backgroundColor[i]}}}>'${item.backgroundColor[i]} '</span>''<span>'${chart.data.labels[i].lable} '</span>'`);
                  legendHtml.push(`
                      <div class="chart-items"> <div class="chart-item-list"> <span class="chart-legend-label-text pie-charrt-span">${(chart.data.labels[i].percentage === 'NaN%')?'0.00%':chart.data.labels[i].percentage}</span>
                        <span class="legend-Bar pie-charrt-span" style="color: ${item.backgroundColor[i]}">
                        </span></div>
                       <div> <span class="labels-legend">${chart.data.labels[i].label} </span>
                        <span class="pie-StatusCount-span">${chart.data.labels[i].StatusCount} </span></div></div>
                        `);
                  legendHtml.push('</li>');
                }

                legendHtml.push('</ul>');
                legendHtml.push('</div>');
                return legendHtml.join('');
              }
            },
          }}
        />

        {/* <div dangerouslySetInnerHTML={this.createMarkup()} /> */}
        <div>{ReactHtmlParser(this.createMarkup())}</div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (data) => {
      dispatch(actions.setDashboardTracking(data));
    },
  };
};

export default connect(null, mapDispatchToProps)(withRouter(DoughnutChart));
