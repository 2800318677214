import React, { useState } from "react";
import { Grid, IconButton } from '@material-ui/core';
import DynamicMaterialTable from '../../../framework/DynamicMaterialTable/DynamicMaterialTable';
import CustomModal from '../../../framework/dialog/customDialog';
import InputButton from '../../../framework/Inputs/inputButton/inputButton';
import '../../../styles/styles.scss';

const AuditDetails = (props) => {
    const { artifactId, amendInfoTableData, showInfoDialog, setShowInfoDialog } = props;

    const amendInfoTableColumns = [
        { title: 'id', field: 'artifactId', hidden: true },
        {
            title: 'Items',
            field: 'fieldName',
            sorting: true,
            width: 80,
        },
        {
            title: 'Old value',
            field: 'oldValue',
            sorting: false,
            width: 180,
        },
        {
            title: 'New value',
            field: 'newValue',
            sorting: false,
            width: 180,
        },
    ];

    const [ammendInfoColumns] = useState(amendInfoTableColumns);

    const handleDialogClose = () => {
        setShowInfoDialog(false);
    };

    return (
        <Grid container xs={12} sm={12} md={12} lg={12}>
            <CustomModal className='Table-column-color amendInfoTable' open={showInfoDialog} title={'Details'} maxWidth="sm" onClose={handleDialogClose}>
                <div className='shipmentInfoTable'>Shipment-{artifactId}</div>
                <DynamicMaterialTable
                    rows={amendInfoTableData}
                    columns={ammendInfoColumns}
                    isSelectable={false}
                    paging={false}
                    sorting={true}
                    customMaxHeight={'400px'}
                />
                <Grid className='infoOkButton' item xs={3} sm={3} md={3} lg={2} >
                    <InputButton
                        className="outlined-btn"
                        label={'Ok'}
                        variant="outlined"
                        type='submit'
                        onClick={() => {
                            handleDialogClose()
                        }}

                    />
                </Grid>
            </CustomModal>
        </Grid>
    );

};

AuditDetails.propTypes = {};

export default AuditDetails;