import React from 'react';
import FormRenderer from '../../../../../../framework/Inputs/formRenderer/formRenderer';
import { Grid } from '@material-ui/core';

const MarketplaceInvoiceDecAndShippingDtls = ({ formField, setFieldValue, currencies, reasonsForExportList, ...rest }) => {

    const { customDeclarationShippingCharges, customDeclarationCurrency, invoiceTypeLabel, invoiceNumber, reasonForExport } = formField;

    return (
        <>
            <Grid container item xs={12} sm={12} md={12} lg={12} direction='col' className='Marketplace-Intl-Dflts'>
                <Grid item xs={6} sm={6} md={6} lg={6} style={{ paddingRight: '20px' }}>
                    <FormRenderer
                        {...invoiceTypeLabel.props}
                        data={invoiceTypeLabelOptions}
                        fullWidth
                    />
                </Grid>
            </Grid>

            <Grid container item xs={12} sm={12} md={12} lg={12} direction='col'>
                <Grid item xs={6} sm={6} md={6} lg={6} style={{ paddingRight: '20px' }}>
                    <FormRenderer {...invoiceNumber.props} fullWidth />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} style={{ paddingLeft: '20px' }}>
                    <FormRenderer {...reasonForExport.props} data={reasonsForExportList} fullWidth />
                </Grid>

            </Grid>

            <Grid container item xs={12} sm={12} md={12} lg={12} direction='col'>
                <Grid item xs={6} sm={6} md={6} lg={6} style={{ paddingRight: '20px' }}>
                    <FormRenderer
                        {...customDeclarationShippingCharges.props}
                        onChange={({ target: { value } }) => {
                            if (/^\d+(\.\d{0,2})?$/.test(value)) {
                                setFieldValue('customDeclarationShippingCharges', value);
                            } else if (value === '') {
                                setFieldValue('customDeclarationShippingCharges', '');
                            }
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} style={{ paddingLeft: '20px' }}>
                    <FormRenderer {...customDeclarationCurrency.props} data={currencies} fullWidth />
                </Grid>
            </Grid>
        </>
    );

}

export default MarketplaceInvoiceDecAndShippingDtls;

const invoiceTypeLabelOptions = [
    {
        value: 'commercial',
        label: 'Commercial',
    },
    {
        value: 'proforma',
        label: 'Proforma',
    },
];

