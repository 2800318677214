import React, { useRef, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import FormRenderer from '../../framework/Inputs/formRenderer/formRenderer';
import InputButton from '../../framework/Inputs/inputButton/inputButton';
import { Grid } from '@material-ui/core';

const TableExport = (props) => {
  const csvLink = useRef();
  const Export_Data = async () => {
    await props.onDataUpdate();
    csvLink.current.link.click();
  };
  let is_Disabled = true;
  let Enabled = false;
  let sortOptions;
  if (props.tabIndex === undefined && props.dashboard === false) {
    if (
      (props.formikValues.startDate !== '' && props.formikValues.endDate !== '') ||
      props.formikValues.exportTo.value !== ''
    ) {
      is_Disabled = false;
    }
  } else if (props.tabIndex === 0 || props.tabIndex === 1) {
    if (!(props.formikValues.exportTo.value === '')) {
      {
        is_Disabled = false;
      }
    }
  }
  if (props.dashboard === true && props.formikValues.exportTo.value !== '') {
    is_Disabled = false;
  }
  if (props.myshipment === true && props.formikValues.exportTo.value !== '') {
    Enabled = true;
  }
  if(props.dashboard === true)
  { 
    sortOptions = props.headers.filter((ele) => ele.key !== 'weight' && ele.key !== 'ETA');
  }
  else if(props.myshipment === true)
  {
    sortOptions = props.headers.filter((ele) => ele.key !== 'weight' && ele.key !== 'product' && ele.key !== 'service');
    if (props.isBookedCollections)
    {
      sortOptions = sortOptions.filter( (ele) => ele.key !== 'alternativeReference' && ele.key !== 'returnShipmentnumber' && ele.key !== 'numberOfParcels')
    }
  }
  useEffect(() => {
    props.setFieldValue('exportTo', ExportOptions[0]);
    props.setFieldValue('orderOptions', OrderOptions[0]);
    props.setFieldValue('sortByOptions', props.headers[0]);
  }, []);

  useEffect(()=>{
     if((props.myshipment || props.dashboard) && props.formikValues?.sortByOptions?.key !== '' && props.formikValues?.orderOptions?.value !== '')
     {
      props.setSortByOrder(`&sortBy=${props.formikValues?.sortByOptions?.key}&sortIn=${props.formikValues?.orderOptions?.value}`);
     }
  }, [props.formikValues.sortByOptions, props.formikValues.orderOptions]);
  
  return (
    <Grid container spacing={2} direction="row" justify={props.myshipment || props.dashboard ? 'flex-start' : 'flex-end'}>
      {props.myshipment === true || props.dashboard === true ? (<><Grid className="export-container" item style={{ width: '35%' }}>
        <FormRenderer {...formField.sortByOptions.props} data={sortOptions} fullWidth />
      </Grid>
      <Grid className="export-container" item style={{ width: '23%' }}>
        <FormRenderer {...formField.orderOptions.props} data={OrderOptions} fullWidth />
      </Grid></>) : ''}
      <Grid className="export-container" item xs={3} style={{ maxWidth: '18%' }}>
        <FormRenderer {...formField.exportTo.props} data={ExportOptions} fullWidth />
      </Grid>
      <Grid item xs={3} className="btn-container" style={{ maxWidth: '100px' }}>
        <InputButton
          variant="outlined"
          className={`outlined-btn ${props.class_Name ? props.class_Name : ''}`}
          label="Export"
          onClick={props.formikValues.exportTo.value === 'pdf' ? props.onClick : Export_Data}
          isDisabled={((props.myshipment && Enabled) === true ? false : (props.dashboard) === true ? (!((props.tableData.length > 0 && !is_Disabled))) : !(props.data.length > 0 && !is_Disabled)) || props.disableExportButton}
        />
        <CSVLink data={props.data} headers={props.headers} filename={props.filename} ref={csvLink} />
      </Grid>
    </Grid>
  );
};

export default TableExport;

const formField = {
  exportTo: {
    props: {
      name: 'exportTo',
      label: 'Export to',
      type: 'select',
      displayField: 'name',
      hasDefaultValue: true,
    },
  },
  orderOptions: {
    props: {
      name: 'orderOptions',
      label: 'Sort in',
      type: 'select',
      displayField: 'name',
      hasDefaultValue: true,
    },
  },
  sortByOptions: {
    props: {
      name: 'sortByOptions',
      label: 'Sort by',
      type: 'select',
      displayField: 'label',
      hasDefaultValue: true,
    },
  },
};

const ExportOptions = [
  {
    name: 'Select',
    value: '',
  },
  {
    name: 'PDF',
    value: 'pdf',
  },
  {
    name: 'CSV',
    value: 'csv',
  },
];

const OrderOptions = [
  {
    name: 'Select',
    value: '',
  },
  {
    name: 'Ascending',
    value: 'ascending',
  },
  {
    name: 'Descending',
    value: 'descending',
  },
];