/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Grid } from '@material-ui/core';
import Stepper from '../../../../framework/stepper/stepper';
import './trackingBanner.scss';
import { useHistory } from 'react-router';
import { GET } from '../../../../api/axios';
import { formatDateToSecondFormat } from '../../../../utils/helperFunctions';
import { getDayOfTheWeek, convertTZwithFormat } from '../../../../utils/helperFunctions';
import { ServiceEndPoints } from '../../../../utils/constants/ApiConstant';
import moment from 'moment';
const TrackingBanner = (props) => {
  let getSteps = ['Order received', 'We have your parcel', 'Out for delivery', 'Delivered'];
  const [activeStep, setActiveStep] = React.useState(0);
  const [estimatedDuration, setEstimatedDuration] = React.useState('Estimated delivery on');
  const [estimatedDeliveryDate, setestimatedDeliveryDate] = React.useState([]);
  const [estimatedDeliveryStartTime, setestimatedDeliveryStartTime] = React.useState([]);
  const [estimatedDeliveryEndTime, setestimatedDeliveryEndTime] = React.useState([]);
  const [deliveredTime, setdeliveredTime] = React.useState([]);
  const [dayOftheWeek, setdayOftheWeek] = React.useState('');
  let ShipmentEventName = [];
  let estimatedDeliveryDates = [];
  let deliveryStartTime = [];
  let shipmentEventCode = [];
  let deliveryEndTime = [];
  let deliveryDueDate = '';
  let deliveryDueStartTime = '';
  let deliveryDueEndTime = '';
  let deliveredDateTime = [];
  let deliveredDateTimeEvent;
  let indexOfEvent;
  const history = useHistory();

  const convertTime12to24 = time12h => {
    const [time, modifier] = time12h.split(" ");

    let [hours, minutes] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }

    if (`${hours}:${minutes}` > "20:00") {
      return null

    }

    return `${hours}:${minutes}`;


  };

  var convertedTime = convertTime12to24(new Date().toLocaleTimeString());



  const getTrackingProcessingData = () => {
    const queryParams = history.location.search.split('=');
    GET(`${ServiceEndPoints.getShipmentTracking}${queryParams[1]}`)
      .then((res) => {
        for (const dataObj of res.data.shipmentEventModels) {
          ShipmentEventName.push(dataObj.shipmentEvent.shipHarmonisedEvents.trackingPageStatus);
          estimatedDeliveryDates.push(dataObj.shipmentEvent.details.deliveryDate);
          deliveryStartTime.push(dataObj.shipmentEvent.details.estimatedDeliveryStartTime);
          deliveryEndTime.push(dataObj.shipmentEvent.details.estimatedDeliveryEndTime);
          deliveredDateTime.push(dataObj.shipmentEvent.dateTime)
          shipmentEventCode.push(dataObj.shipmentEvent.code)
        }
        indexOfEvent = shipmentEventCode.findIndex(e => e === 'T415')
        {
          res.data.dueDate === null
            ? (deliveryDueDate = '')
            : (deliveryDueDate = formatDateToSecondFormat(res.data.dueDate));
        }
        {
          res.data.dueStartTime === null
            ? (deliveryDueStartTime = '')
            : (deliveryDueStartTime = res.data.dueStartTime);
        }
        res.data.dueEndtime ? (deliveryDueEndTime = '') : (deliveryDueEndTime = (res.data.dueEndTime ? res.data.dueEndTime : null));
        const ShipmentValidEventName = ShipmentEventName.filter(eventName => eventName !== "NA")
        deliveredDateTimeEvent = convertTZwithFormat(deliveredDateTime[0], 'H:mm');
        if (ShipmentValidEventName[0] === 'We have your Parcel' || ShipmentValidEventName[0] === 'We have your parcel') {
          setEstimatedDuration('Delivery due on');
          setActiveStep(2);
          setestimatedDeliveryDate(deliveryDueDate);
          setestimatedDeliveryStartTime(res.data.dueStartTime);
          setestimatedDeliveryEndTime(res.data.dueEndTime);
          setdayOftheWeek(getDayOfTheWeek(deliveryDueDate));

        } else if (ShipmentValidEventName[0] === 'Out for delivery') {
          // if (convertedTime === null) {
          //   setActiveStep(2)
          // }
          // else {
          setActiveStep(3)
          // }

          setEstimatedDuration('Delivery due on');
          ShipmentEventName[0]?.toLowerCase()?.trim() === 'na' ? setestimatedDeliveryDate(deliveryDueDate) : setestimatedDeliveryDate((estimatedDeliveryDates[0]) ? formatDateToSecondFormat(estimatedDeliveryDates[0]) : deliveryDueDate);

          // if (indexOfEvent !== -1) {
          //   setestimatedDeliveryStartTime(deliveryStartTime[indexOfEvent]);
          //   setestimatedDeliveryEndTime(deliveryEndTime[indexOfEvent]);
          // }

          if (res.data?.eventEstimatedDeliveryStartTime) {
            setestimatedDeliveryStartTime(res.data.eventEstimatedDeliveryStartTime);
            setestimatedDeliveryEndTime(res.data.eventEstimatedDeliveryEndTime);
          }
          else {
            setestimatedDeliveryStartTime(res.data.dueStartTime);
            setestimatedDeliveryEndTime(res.data.dueEndTime);
          }

          ShipmentEventName[0]?.toLowerCase()?.trim() === 'na' ? setdayOftheWeek(getDayOfTheWeek((deliveryDueDate))) : setdayOftheWeek(getDayOfTheWeek((estimatedDeliveryDates[0]) ? estimatedDeliveryDates[0] : deliveryDueDate));
        } else if (ShipmentValidEventName[0] === 'Delivered') {
          setEstimatedDuration('Delivered on');
          setActiveStep(4);
          ShipmentEventName[0]?.toLowerCase()?.trim() === 'na' ? setestimatedDeliveryDate(deliveryDueDate) : setestimatedDeliveryDate((estimatedDeliveryDates[0]) ? formatDateToSecondFormat(estimatedDeliveryDates[0]) : deliveryDueDate);
          setdeliveredTime(deliveredDateTimeEvent)
          ShipmentEventName[0]?.toLowerCase()?.trim() === 'na' ? setdayOftheWeek(getDayOfTheWeek((deliveryDueDate))) : setdayOftheWeek(getDayOfTheWeek((estimatedDeliveryDates[0]) ? estimatedDeliveryDates[0] : deliveryDueDate));
        } else {
          setEstimatedDuration('Estimated delivery on');
          setActiveStep(1);
          if (deliveryDueDate === '') {
            setestimatedDeliveryDate('Will be updated once shipment has been despatched');
            setdayOftheWeek('');
          }
          else {
            setestimatedDeliveryDate(deliveryDueDate);
            setestimatedDeliveryStartTime(res.data.dueStartTime);
            setestimatedDeliveryEndTime(res.data.dueEndTime);
            setdayOftheWeek(getDayOfTheWeek(deliveryDueDate));
          }
        }
      })
      .catch((err) => { });
  };

  React.useEffect(() => {
    getTrackingProcessingData();
  }, []);

  return (
    <Grid className="trackingHandler-container">
      <Grid container alignItems="flex-start" className="trackingHandler" direction="row">
        <Grid item xs={7} sm={7} md={7} lg={7} className="estimatedOn-grid-item">
          <Grid container alignItems="flex-start" className="trackingHandler" direction="row">
            <Grid item xs={4} sm={4} md={4} lg={4} className="estimatedOn-grid-item">
              <span className="estimatedOn-text">{estimatedDuration}</span>
            </Grid>
            {dayOftheWeek ?
              <Grid item xs={12} sm={12} md={12} lg={12} className="estimation-duration-item">
                <span className="estimation-day">
                  {dayOftheWeek}, {estimatedDeliveryDate}
                </span>
                &nbsp;&nbsp;
                {
                  estimatedDuration === 'Delivered on'
                    ? (
                      <span>
                        <span className="between">at</span>&nbsp;&nbsp;
                        <span className="estimation-time-from">{deliveredTime}</span> &nbsp;

                      </span>
                    )
                    : (
                      <span>

                        <span className="between">between</span>&nbsp;
                        <span className="estimation-time-from">{estimatedDeliveryStartTime}</span> &nbsp;&nbsp;
                        <span className="estimation-time-and">and </span>
                        <span className="estimation-time-from">{estimatedDeliveryEndTime}</span>
                      </span>
                    )
                }

              </Grid>
              :
              <Grid item xs={12} sm={12} md={12} lg={12} className="estimation-duration-item">
                <span className="estimation-day-1">
                  {estimatedDeliveryDate}
                </span>
              </Grid>
            }
          </Grid>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5} className="stepper-process-itemcontainer">
          <Grid container direction="row" alignContent="flex-end" className="stepper-process-container">
            <Grid item xs={12} sm={12} md={12} lg={12} className="stepper-process-grid">
              <Stepper steps={getSteps} activeStep={activeStep} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TrackingBanner;
