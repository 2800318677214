import { deliveryStatusForDashboard } from '../../constants/constants';

const dashboardForm = {
  formId: 'dashboardForm',
  formField: {
    accounts: {
      props: {
        name: 'accounts',
        label: 'Select account',
        type: 'multiSelect',
        displayField: 'displayName',
        _className: "dashBoard-account-selectBox",
        isRequired: true,
        hasDefaultValue: false
      },
      value: [],
      validation: {
        name: 'accounts',
        validationType: 'array',
        validations: [
          {
            type: 'min',
            params: [1, 'Select an account to view the data'],
          },
        ],
      },
    },
    internationalAccounts: {
      props: {
        name: 'internationalAccounts',
        label: 'Select account',
        type: 'multiSelect',
        displayField: 'accountId',
        isRequired: true,
        hasDefaultValue: false
      },
      value: [],
      validation: {
        name: 'internationalAccounts',
        validationType: 'array',
        validations: [
          {
            type: 'min',
            params: [1, 'Select an account to view the data'],
          },
        ],
      },
    },
    deliveryStatusForAccounts: {
      props: {
        name: 'deliveryStatusForAccounts',
        label: 'Select delivery status',
        type: 'select',
        isRequired: true,
        displayField: 'name',
        hasDefaultValue: true,
      },
      value: deliveryStatusForDashboard[0],
    },
    dashboardSearchBy: {
      props: {
        name: 'dashboardSearchFor',
        type: 'searchControl',
      },
      selectProps: {
        props: {
          name: 'dashboardSearchBy',
          label: 'Search For',
          placeholder: "Enter Customer's ref.no",
        },
      },
      textProps: {
        props: {
          name: 'dashboardSearchFor',
          label: 'Search For',
          placeholder: "Enter Customer's ref.no",
        },
      },
      validation: {
        name: 'dashboardSearchFor',
        validationType: 'string',
        validations: [
          {
            type: 'when',
            params: [
              'dashboardSearchBy',
              (dashboardSearchBy, schema) => {
                if (dashboardSearchBy) {
                  return dashboardSearchBy.value === 'recipientName'
                    ? schema.matches(/[a-zA-Z]+([\s][a-zA-Z]+)*/, 'Recipient name must be alphabets')
                    : dashboardSearchBy.value === 'telephoneNumber'
                      ? schema.matches(/^[0-9,(,),+,\-,.,[,\] ].{9,35}/, 'Telephone number is invalid')
                      : schema;
                }
              },
            ],
          },
        ],
      },
    },
    selectdateRange: {
      props: {
        name: 'selectdateRange',
        label: 'Date range',
        type: 'date',
      },
    },
    collectionDate: {
      props: {
        name: 'collectionDate',
        label: 'Collection Date',
        type: 'date',
      },
    },
    domesticProduct: {
      props: {
        name: 'domesticProduct',
        label: 'Product',
        type: 'select',
        displayField: 'name',
      },
    },
    domesticService: {
      props: {
        name: 'domesticService',
        label: 'Service',
        type: 'select',
        displayField: 'serviceName',
      },
    },
    internationalCountry: {
      props: {
        name: 'internationalCountry',
        label: 'Country',
        type: 'select',
        displayField: 'name',
      },
    },
    internationalProduct: {
      props: {
        name: 'internationalProduct',
        label: 'Product',
        type: 'select',
        displayField: 'name',
      },
    },

    product: {
      props: {
        name: 'product',
        label: 'Product',
        type: 'select',
        displayField: 'productName',
      },
    },
    service: {
      props: {
        name: 'service',
        label: 'Service',
        type: 'select',
        displayField: 'name',
        isRequired: true,
      },
      validation: {
        name: 'service',
        validationType: 'object',
        validations: [
          {
            type: 'when',
            params: [
              'destinationCountry',
              (destinationCountry, schema) => {
                return (destinationCountry || {}).code === 'GBR'
                  ? schema.required('Service name required')
                  : schema.notRequired();
              },
            ],
          },
        ],
      },
    },
    fromCollectionDate: {
      props: {
        name: 'fromCollectionDate',
        type: 'date',
      },
    },
    toCollectionDate: {
      props: {
        name: 'toCollectionDate',
        type: 'date',
      },
    },
    isDomestic: {
      props: {
        name: 'isDomestic',
        type: 'checkbox',
      },
      value: true,
    },
  },
};

export default dashboardForm;
