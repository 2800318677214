/* eslint-disable no-undef */
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import './barChart.scss';
import * as actions from '../../../store/actions/index';
import { getDefaultDashboardValues } from '../../../utils/helperFunctions';
import DeliveryFont from '../../../assets/fonts/Delivery_W_Rg.woff';
import { arrayValuesToQueryStringParam } from '../../../utils/common';
//./assets/fonts/Delivery_W_Rg.woff

const DeliveryTheme = {
  fontFamily: 'Delivery Regular',
  fontStyle: 'normal',
  src: `local('Delivery'),
  local('Delivery-Regular')
  url(${DeliveryFont}) format('woff')
  `,
  fontFamily: 'Delivery Light',
  fontStyle: 'normal',
  src: `local('Delivery'),
  local('Delivery_W_Lt')
  url(${DeliveryFont}) format('woff')
  `,
};

Chart.pluginService.register({
  beforeDraw(chart) {
    if (chart.config.options.elements.center) {
      // Get ctx from string
      var ctx = chart.chart.ctx;

      // Get options from the center object in options
      var centerConfig = chart.config.options.elements.center;
      var fontStyle = centerConfig.fontStyle || 'Arial';
      var txt = centerConfig.text;
      var color = '#717171';
      var maxFontSize = centerConfig.maxFontSize || 75;
      var sidePadding = centerConfig.sidePadding || 20;
      var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
      // Start with a base font of 30px
      ctx.font = `17px ${fontStyle}`;

      // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
      var stringWidth = ctx.measureText(txt).width;
      var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

      // Find out how much the font can grow in width.
      var widthRatio = elementWidth / stringWidth;
      var newFontSize = Math.floor(30 * widthRatio);
      var elementHeight = chart.innerRadius * 2;

      // Pick a new font size so it will not be larger than the height of label.
      var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
      var minFontSize = centerConfig.minFontSize;
      var lineHeight = centerConfig.lineHeight || 25;
      var wrapText = false;

      if (minFontSize === undefined) {
        minFontSize = 20;
      }

      if (minFontSize && fontSizeToUse < minFontSize) {
        fontSizeToUse = minFontSize;
        wrapText = true;
      }

      // Set font settings to draw it correctly.
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
      var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
      ctx.font = `${fontSizeToUse}px ${fontStyle}`;
      ctx.fillStyle = color;

      if (!wrapText) {
        ctx.fillText(txt, centerX, centerY);
        return;
      }

      var words = txt.split(' ');
      var line = '';
      var lines = [];

      // Break words up into multiple lines if necessary
      for (var n = 0; n < words.length; n++) {
        var testLine = `${line + words[n]} `;
        var metrics = ctx.measureText(testLine);
        var testWidth = metrics.width;
        if (testWidth > elementWidth && n > 0) {
          lines.push(line);
          line = `${words[n]} `;
        } else {
          line = testLine;
        }
      }

      // Move the center up depending on line height and number of lines
      centerY -= (lines.length / 2) * lineHeight;

      for (var m = 0; m < lines.length; m++) {
        ctx.fillText(lines[m], centerX, centerY);
        centerY += lineHeight;
      }
      //Draw text in center
      ctx.fillText(line, centerX, centerY);
    }
  },
});

const BarChartComponent = (props) => {
  const { labels, values, totalCount, formikValues, colors } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  let segment;
  const data = {
    labels,
    datasets: [
      {
        showScale: false,
        data: values,
        barPercentage: 0.99,
        borderColor: colors,
        backgroundColor: colors,
      },
    ],
  };

  const options = {
    events: ['mousemove', 'mouseout', 'touchstart', 'touchmove'],
    tooltips: {
      displayColors: false,
      // backgroundColor: '#fff',
      titleFontColor: '#fff',
      // titleFontStyle:'inherit',
      // titleFontSize:'14',
      callbacks: {
        label: function (tooltipItem) {
          if (tooltipItem.yLabel === 1) {
            return Number(tooltipItem.yLabel) + ' Shipment';
          } else {
            return Number(tooltipItem.yLabel) + ' Shipments';
          }
        },
        labelTextColor: function (context) {
          return '#fff';
        },
      },
    },
    onHover(evt, elements) {
      if (elements && elements.length) {
        segment = elements[0];
        this.chart.update();
        let selectedIndex = segment['_index'];
        segment._model.outerRadius += 10;
      } else {
        if (segment) {
          segment._model.outerRadius -= 10;
        }
        segment = null;
      }
    },
    onClick: (e) => e.stopPropagation(),
    responsive: true,
    barRoundness: 0.3,
    legend: {
      display: false,
    },
    scaleShowLabels: false,
    title: {
      display: false,
    },
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 1,
        right: 2,
        top: 2,
        bottom: 0,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
            max: totalCount,
            stepSize: 1,
            padding: 20,
            fontFamily: 'Delivery Light',
            fontColor: '#717171',
            fontSize: 10,
            // minRotation: 0,
          },
          gridLines: {
            drawBorder: true,
            tickMarkLength: false,
          },
        },
      ],
      xAxes: [
        {
          maxBarThickness: 20,
          // barPercentage: 0.5
          ticks: {
            // autoSkip: false,
            // maxRotation: 0,
            // minRotation: 0,
            // maxTicksLimit: 20,
            // autoSkip: true,
            display: true,
            // autoSkipPadding: 15,
            fontFamily: 'Delivery Regular',
            fontColor: '#717171',
            fontSize: 12,
            maxRotation: 0,
            // precision: 2,
            // suggestedMin: 0,
            wordWrap: false,
            wordBreak: false,
            flexWrap: false,
            padding: 10,
            minRotation: 60,
          },
          time: {
            displayFormats: {
              millisecond: 'HH:mm:ss',
              second: 'HH:mm:ss',
              minute: 'HH:mm:ss',
              hour: 'HH:mm:ss',
              day: 'HH:mm:ss',
              week: 'HH:mm:ss',
              month: 'HH:mm:ss',
              quarter: 'HH:mm:ss',
              year: 'HH:mm:ss',
            },
          },
          gridLines: {
            // display: false,
            // drawBorder: true,
            // borderSkipped:'yes',
            tickMarkLength: 40,
            offsetGridLines: true,
            display: true,
            drawTicks: false,
            drawOnChartArea: false,
          },

          'dataset.maxBarThickness': 5,
        },
      ],
    },
  };

  return (
    <div className="barMain_Head">
      <Bar
        data={data}
        onElementsClick={(elems) => {
          if (elems && (elems || []).length > 0) {
            let filterUrl = '';
            let index = elems[0]._index;
            let label = labels[index];
            let labelProperty = 'status';
            if (formikValues.isDomestic && !formikValues.domesticService?.value) {
              labelProperty = 'service';
            } else if (!formikValues.isDomestic && !formikValues.product?.value) {
              labelProperty = 'product';
            }
            if (labelProperty === 'status' && formikValues?.domesticService?.value) {
              filterUrl = `${labelProperty}=${label}&product=${formikValues?.product?.productName}&service=${formikValues?.domesticService?.serviceName}`;
            } else if ((labelProperty === 'status' || labelProperty === 'service') && formikValues?.product?.value) {
              filterUrl = `${labelProperty}=${label}&product=${formikValues?.product?.productName}`;
            } else {
              filterUrl = `${labelProperty}=${label}`;
            }
            dispatch(
              actions.setDashboardTracking({
                loadedFrom: `onChartClick`,
                loadedResponse: formikValues,
                dashboardQueryParams: `${getDefaultDashboardValues(formikValues)}&${filterUrl}`,
                accountsParams: arrayValuesToQueryStringParam(formikValues.accounts),
                status:label
              }),
            );
            history.push('/dashboard/dashboardDetailsBreakdown');
          }
        }}
        options={options}
      />
    </div>
  );
};

export default BarChartComponent;
