import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import DynamicMaterialTable from '../../framework/DynamicMaterialTable/DynamicMaterialTable';
import InputButton from '../../framework/Inputs/inputButton/inputButton';
import ConfirmationDialog from '../addressBook/confirmation/confirmation';
import CustomModal from '../../framework/dialog/customDialog';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as actions from '../../store/actions/index';

export default function MyTemplate(props) {
  const {
    rows,
    columns,
    isSearchable,
    isSelectable,
    sorting,
    paging,
    pageSize,
    pageSizeOptions,
    Dialogtitle,
    confirmationMessage,
    emptyDataSourceMessage,
  } = props;
  const [selectedRows, setSelectedRows] = useState([]);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  let history = useHistory();
  const dispatch = useDispatch();

  const addSelectedRows = (selRows) => {
    setSelectedRows(selRows);
  };
  const handleDialogClose = () => {
    setShowConfirmationDialog(false);
  };
  const onDialogConfirm = () => {
    props.deleteSelectedTemplates(selectedRows);
    setShowConfirmationDialog(false);
    setSelectedRows([]);
  };
  const onDialogCancel = () => {
    setShowConfirmationDialog(false);
  };

  const onRowClicked = (res) => {
    dispatch(
      actions.setActiveTab(""),
    );
    history.push(`/shipment/templateManagement/editTemplate?templateName=${res.templateName}`);
  };

  return (
    <Grid container justify="flex-end" alignItems="flex-end">
      <Grid item lg={12} md={12} xs={12} className="Table-column-color">
        <DynamicMaterialTable
          rows={rows}
          columns={columns}
          isSelectable={isSelectable}
          isSearchable={isSearchable}
          sorting={sorting}
          paging={paging}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
          empty_DataSource_Message={emptyDataSourceMessage}
          onSelectionChange={addSelectedRows}
          onRowClicked={onRowClicked}
          {...props}
        />
      </Grid>

      <Grid container item className="customModal-book">
        <CustomModal open={showConfirmationDialog} title={Dialogtitle} onClose={handleDialogClose} maxWidth="sm">
          <ConfirmationDialog
            confirmationMessage={confirmationMessage}
            onConfirm={onDialogConfirm}
            OnCancel={onDialogCancel}
          />
        </CustomModal>
      </Grid>
      <Grid
        item
        container
        lg={4}
        md={4}
        xs={4}
        justify="flex-end"
        alignItems="flex-end"
        style={{ paddingTop: '0', display: 'grid', marginTop: '-12px' }}
      >
        <Grid item lg={6} md={6} xs={6}>
          <InputButton
            isDisabled={selectedRows.length === 0}
            className="Template-Delete-btn Delete-btn Favourites-Remove"
            label="Delete"
            onClick={() => setShowConfirmationDialog(true)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

MyTemplate.propTypes = {
  rows: PropTypes.any,
  columns: PropTypes.any,
  isSearchable: PropTypes.bool,
  isSelectable: PropTypes.bool,
  sorting: PropTypes.any,
  paging: PropTypes.any,
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.any,
  title: PropTypes.string,
  confirmationMessage: PropTypes.string,
  emptyDataSourceMessage: PropTypes.string,
  deleteSelectedTemplates: PropTypes.bool,
};
