import React from 'react';
import { connect } from 'react-redux';
import { AppBar, Tabs, Tab } from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import './templateManagement.scss';
import TabPanel from '../../../components/shared/tabPanel';
import TemplateManagement from './CreateNewTemplate/TemplateManagement';
import MyTemplate from '../../../components/MyTemplate/MyTemplate';
import SearchMyTemplates from '../../../components/MyTemplate/searchTemplates/SearchMyTemplates';
import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';
import { axiosConfig } from '../../../api/axios';
import { getCustomerAccountID } from '../../../utils/helperFunctions';
import * as actions from "../../../store/actions/index"
const tableRef = React.createRef();
class templateManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      // isSearched: false,
      columns: [
        { title: 'id', field: 'id', hidden: true, width: '0%' },
        { title: 'Template name', field: 'templateName', sorting: false, width: '97%' },
      ],
      rows: [],
    };
    this.onAddressChange = this.onAddressChange.bind(this);
  }

  onAddressChange(value) {
    // this.setState({ isSearched: value });
    tableRef.current && tableRef.current.onQueryChange();
  }

  deleteSelectedTemplates = (templateIds) => {
    const itemsToBeDeleted = templateIds.map((template) => {
      return template.id;
    });
    const payload = itemsToBeDeleted;
    axiosConfig
      .post(`${ServiceEndPoints.deleteTemplate}`, payload)
      .then((response) => {
        this.props.setSnackBar({ msg: "Template(s) deleted successfully" })
        tableRef.current && tableRef.current.onQueryChange();
      })
      .catch((error) => {
        alert('Items were not deleted, please try again.');
      });
  };

  handleTabChange = (event, index) => {
    this.setState({ tabIndex: index }, () => {
      tableRef.current && tableRef.current.onQueryChange();
    });
  };

  _MyTemplate = (query) => {
    return this.getMyTemplateResults(query);
  };

  getMyTemplateResults = (query) => {
    return new Promise((resolve, reject) => {
      const url = this.getUrl(query);
      axiosConfig.get(url)
        .then((res) => {
          this.formatMyTemplateData(query, resolve, res.data);
        })
        .catch((err) => {
          console.log(err);
          this.formatMyTemplateData(query, resolve, {});
        });
    });
  };

  getUrl = (query) => {
    let url = `${ServiceEndPoints.getTemplateBy}?organization=${getCustomerAccountID()}`;
    url += '&offset=' + query.page * query.pageSize;
    url += '&limit=' + query.pageSize;
    url += this.props.addressBookSearchParams;
    return url;
  };

  formatMyTemplateData = (query, resolve, result) => {
    let final_data = [];
    ((result || {}).templates || []).map((element, index) => {
      final_data.push({
        id: element.id,
        templateName: element.templateName,
      });
    });
    resolve({
      data: final_data,
      page: query.page,
      totalCount: result?.totalRecords ? result.totalRecords : 0,
    });
  };

  componentDidMount() {
    if (this.props.activeTab === "myTemplates") {
      this.setState({ tabIndex: 1 });
    }
    this.props.setActiveTab("");
  }

  render() {
    return (
      <React.Fragment>
        <Grid container className="Template-Container">
          <AppBar position="static" className="Template-AppBar">
            <Grid container spacing={3} direction="row">
              <Grid className="template-Tab" item xs={12} sm={6} lg={6} style={{ paddingTop: '2px', paddingBottom: '0' }}>
                <Tabs
                  value={this.state.tabIndex}
                  className={`${this.state.tabIndex === 0 ? 'Create-template-tab-panel' : 'my-template-tab-panel'}`}
                  onChange={this.handleTabChange}
                >
                  <Tab label="Create new template" className="Tab-panel" />
                  <Tab label="My templates" className="My_templates Tab-panel" />
                </Tabs>
              </Grid>
              {this.state.tabIndex == 1 && (
                <Grid item xs={12} sm={6} lg={6} style={{ paddingTop: '22px' }}>
                  <SearchMyTemplates
                    className="Template-SearchBar"
                    tabIndex={this.state.tabIndex}
                    onAddressChange={this.onAddressChange}
                  />
                </Grid>
              )}
            </Grid>
          </AppBar>
          <>
            <TabPanel
              className="Create-New-Template-Tab"
              value={this.state.tabIndex}
              index={0}
              style={{ width: '100%' }}
            >
              <TemplateManagement />
            </TabPanel>
            <TabPanel className="MyTemplate-Tab" value={this.state.tabIndex} index={1} style={{ width: '100%' }}>
              <MyTemplate
                rows={this._MyTemplate}
                columns={this.state.columns}
                isSelectable={true}
                isSearchable={false}
                sorting={true}
                paging={true}
                pageSize={10}
                pageSizeOptions={[10, 25, 50]}
                deleteSelectedTemplates={this.deleteSelectedTemplates}
                tableRef={tableRef}
                empty_DataSource_Message="Templates not found"
                Dialogtitle="Remove templates"
                confirmationMessage="Are you sure you want to delete the selected templates?"
              />
            </TabPanel>
          </>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.auth.userDetails,
    addressBookSearchParams: state.searchParams,
    activeTab: state.common.activeTab,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSnackBar: (data) => {
      dispatch(actions.setAppSnackBarData(data))
    },
    setActiveTab: (value) => {
      dispatch(actions.setActiveTab(value))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(templateManagement);
