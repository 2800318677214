import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, SvgIcon } from '@material-ui/core';
import { ReactComponent as AddIcon } from '../../../../src/assets/images/AddIcon.svg';
import CustomDialog from './../../../framework/dialog/customDialog';
import './createNewUser.scss';
import UserDetails from '../userDetails/userDetails';
import userManagementForm from '../../../utils/formConfig/userManagement/userManagementForm';
const CreateNewUser = (props) => {
  const { formField, formId } = userManagementForm;
  const { setFieldValue, formikValues, updateUser, updateRowData, row, setFieldTouched } = props;
  const [open, setOpen] = React.useState(false);

  const openCreateUserModal = () => {
    setOpen(true);
  };

//   React.useEffect(() => {
// setFieldValue('user')
//   },[])

  const handleClose = (props) => {
    setOpen(false);
  };
  return (
    <Grid container direction="row" className="createUser-container">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <UserDetails {...props} />
      </Grid>
    </Grid>
  );
};

export default CreateNewUser;
