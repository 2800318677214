import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Grid>{children}</Grid>}
    </div>
  );
};

TabPanel.propTypes = {
  value: PropTypes.number,
  index: PropTypes.number,
};
export default TabPanel;
