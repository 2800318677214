import { Grid } from '@material-ui/core';
import React from 'react';
import CustomAccordion from '../../../framework/accordion/customAccordion';
import FormRenderer from '../../../framework/Inputs/formRenderer/formRenderer';
import { filedToSearchByOptions } from '../../../utils/constants/constants';


const BarcodeConfigurationDetails = (props) => {
  const {
    formField: { filedToSearchBy, startPosition, finalPosition, barCodeLength },
    formikValues,
    setFieldValue,
  } = props;
  return (
    <div>
      <Grid container className="additioninfo-main-grid">
        <Grid item xs={12} sm={12} md={12} lg={12} spacing={2}>
          <CustomAccordion heading="Look-up settings">
            <Grid container direction="row" alignItems="flex-start" spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6} >
                <FormRenderer {...filedToSearchBy.props} data={filedToSearchByOptions} fullWidth />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} ></Grid>

              <Grid item xs={12} sm={6} md={6} lg={6} >
                <FormRenderer {...startPosition.props} fullWidth />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} >
                <FormRenderer {...finalPosition.props} fullWidth />
              </Grid>
            </Grid>
          </CustomAccordion>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} spacing={2}>
          <CustomAccordion heading="Expected barcode length">
            <Grid container direction="row" alignItems="flex-start" spacing={6}>
              <Grid item xs={12} sm={6} md={6} lg={6} >
                <FormRenderer {...barCodeLength.props} fullWidth />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} ></Grid>
            </Grid>
          </CustomAccordion>
        </Grid>
      </Grid>
    </div>
  );
};


export default BarcodeConfigurationDetails;
