import { Grid, Paper, Tabs, Tab } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import TabPanel from '../../../components/shared/tabPanel';
import CreateNewSchema from '../../../components/configuration/orderFileConfigurations/createNewSchema/createNewSchema';

import MySchemas from '../../../components/configuration/orderFileConfigurations/mySchemas/mySchemas';
import OrderFileDefaults from '../../../components/configuration/orderFileConfigurations/orderFileDefaults/orderFileDefaults';
import OrderFileMapping from '../../../components/configuration/orderFileConfigurations/orderFileMapping/orderFileMapping';
import OrderSetup from '../../../components/configuration/orderFileConfigurations/orderSetup/orderSetup';
import './OrderFileConfiguration.scss';
import MarketplacesDefaults from '../../../components/configuration/orderFileConfigurations/marketplacesDefaults/marketplacesDefaults';
import SearchMySchemas from '../../../components/configuration/orderFileConfigurations/mySchemas/searchSchema/SearchMySchemas';
import PrinterQueue from '../../../components/configuration/orderFileConfigurations/printerQueue/printerQueue';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../store/actions/index';
import CustomerLevelMarketplaceMapping from '../../../components/configuration/orderFileConfigurations/MarketplaceMapping/customerLevelMarketplaceMapping/customerLevelMarketplaceMapping';
import AdminLevelMarketplaceMapping from '../../../components/configuration/orderFileConfigurations/MarketplaceMapping/adminLevelMarketplaceMapping/adminLevelMarketplaceMapping';

// const tableRef = React.createRef();
const OrderFileConfiguration = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [isSearched, setIsSearched] = useState(0);

  const dispatch = useDispatch();

  let activeTab = useSelector((state) => state.common.activeTab);
  const userDetails = useSelector((state) => state?.auth?.userDetails);
  const userRole = userDetails?.role;
  const isMarketplaceAccount = userDetails?.accounts?.some((val) => {
    return val?.configuration?.marketplaceUserOptional === true;
  })

  const onTabChange = (event, index) => {
    setTabIndex(index);
  };

  const onAddressChange = () => {
    setIsSearched(!isSearched);
    // tableRef.current && tableRef.current.onQueryChange();
  };

  useEffect(() => {
    if (activeTab === 'mySchema') {
      setTabIndex(1);
    }
    dispatch(actions.setActiveTab(''));
  }, []);

  return (
    <Paper className="paper-container orderfile-papercontainer">
      <Grid container className="OrderFileConfiguration-container" direction="row" spacing={1}>
        <Grid item xs={9} sm={9} md={9} lg={12}>
          <Tabs value={tabIndex} onChange={onTabChange} variant="scrollable" scrollButtons="auto" className="OrderFileConfiguration-tabs-container" selectionFollowsFocus>
            <Tab label="Create new schema" />
            <Tab label="My schemas" />
            <Tab label="Order setup" />
            <Tab label="Order defaults" />
            <Tab label="Order mapping" />
            {(userRole !== 'Supervisor' && isMarketplaceAccount) || userRole === 'Admin' ?
              <Tab label="Marketplace defaults" /> : ''}
            {(userRole !== 'Supervisor' && isMarketplaceAccount) || userRole === 'Admin' ?
              <Tab label="Marketplace mappings" /> : ''}
            {userRole === 'Admin' ?
              <Tab label="DHL marketplace mappings" /> : ''}
            <Tab label="Printer queue" />
          </Tabs>
        </Grid>
        {tabIndex === 1
          ? (
            <Grid item xs={4} sm={4} lg={4} justify="flex-end" style={{ marginLeft: 'auto', marginTop: '10px' }}>
              <SearchMySchemas className="Addessbook-Searchbar" onAddressChange={onAddressChange} />
            </Grid>
          )
          : null}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TabPanel value={tabIndex} index={0}>
            <CreateNewSchema />
          </TabPanel>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TabPanel className="MySchema-Tab" value={tabIndex} index={1}>
            <MySchemas isSearched={isSearched} />
          </TabPanel>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TabPanel className="OrderSetup-Tab" value={tabIndex} index={2}>
            <OrderSetup isMarketplaceAccount={(userRole !== 'Supervisor' && isMarketplaceAccount) || userRole === 'Admin'}/>
          </TabPanel>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TabPanel className="OrderFile-default-Tab" value={tabIndex} index={3}>
            <OrderFileDefaults />
          </TabPanel>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TabPanel className="order-filemap-Tab" value={tabIndex} index={4}>
            <OrderFileMapping />
          </TabPanel>
        </Grid>
        {(userRole !== 'Supervisor' && isMarketplaceAccount) || userRole === 'Admin' ? <Grid item xs={12} sm={12} md={12} lg={12}>
          <TabPanel className="OrderFile-default-Tab" value={tabIndex} index={5}>
            <MarketplacesDefaults />
          </TabPanel>
        </Grid> : ''}
        {(userRole !== 'Supervisor' && isMarketplaceAccount) || userRole === 'Admin' ? <Grid item xs={12} sm={12} md={12} lg={12}>
          <TabPanel className="order-filemap-Tab" value={tabIndex} index={6}>
            <CustomerLevelMarketplaceMapping />
          </TabPanel>
        </Grid> : ''}
        {userRole === 'Admin' ? <Grid item xs={12} sm={12} md={12} lg={12}>
          <TabPanel className="order-filemap-Tab" value={tabIndex} index={7}>
            <AdminLevelMarketplaceMapping />
          </TabPanel>
        </Grid> : ''}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TabPanel className="printer-queue-Tab" value={tabIndex} index={userRole === 'Admin' ? 8 : (userRole !== 'Supervisor' && isMarketplaceAccount) ? 7 : 5}>
            <PrinterQueue />
          </TabPanel>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default OrderFileConfiguration;
