import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import FormRender from '../../../framework/Inputs/formRenderer/formRenderer';
import newDomesticConsignmentForm from '../../../utils/formConfig/newDomesticConsignmentForm/newDomesticConsignmentForm';

const AddDimension = (props) => {
  newDomesticConsignmentForm.formField.length.props.isRequired = props.isRequired;
  newDomesticConsignmentForm.formField.width.props.isRequired = props.isRequired;
  newDomesticConsignmentForm.formField.height.props.isRequired = props.isRequired;
  const colSize = props.isDomestic ? 3 : 4;

  React.useEffect(() => {
    props.setFieldValue('pieceWeight', 0);
  }, []);

  return (
    <Grid container spacing={2} xs={12} sm={12} md={12} lg={12} style={{ maxWidth: '105%', width: '105%' }}>
      <Grid item xs={12} sm={12} md={colSize} lg={colSize}>
        <FormRender {...newDomesticConsignmentForm.formField.length.props} fullWidth />
      </Grid>
      <Grid item xs={12} sm={12} md={colSize} lg={colSize}>
        <FormRender {...newDomesticConsignmentForm.formField.width.props} fullWidth />
      </Grid>
      <Grid item xs={12} sm={12} md={colSize} lg={colSize}>
        <FormRender {...newDomesticConsignmentForm.formField.height.props} fullWidth />
      </Grid>
      {props.isDomestic && (
        <Grid item xs={12} sm={12} md={colSize} lg={colSize}>
          <FormRender {...newDomesticConsignmentForm.formField.pieceWeight.props} fullWidth />
        </Grid>
      )}
    </Grid>
  );
};

AddDimension.propTypes = {
  isRequired: PropTypes.bool,
  isDomestic: PropTypes.bool,
};

export default AddDimension;
