import PropTypes from 'prop-types';
import React from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import { makeStyles, TextField } from '@material-ui/core';

const useStylesTextField = makeStyles(() => ({
  root: {
    border: '1px solid #ACACAC',
    overflow: 'hidden',
    color: 'black',
    opacity: 1,
    borderRadius: 4,
    backgroundColor: '#FFFFFF  ',
    '&:hover': {
      backgroundColor: '#ffffff',
    },
    '&$focused': {
      backgroundColor: '#fff',
    },
  },
}));

const InputTextField = (props) => {
  const {
    label,
    isRequired,
    endArguments,
    inputProps,
    isReadOnly,
    isAddressSelected,
    placeholder,
    type,
    isDisabled,
    onkeydown,
    isMultilined,
    noOfRows,
    class_Name,
    restrictToOneDecimal,
    restrictDecimal,
    ...rest
  } = props;
  const [field, meta] = useField(props);
  const classes = useStylesTextField();
  if (props.type === 'number') {
    if (Number.isInteger(field.value)) {
    } else if (((field || {}).value || '').toString().length > 3) {
      var t = ((field || {}).value || '').toString();
      field.value =
        t.indexOf('.') >= 0 ? t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), restrictToOneDecimal ? 2 : 3) : t;
    }
  }

  const renderHelperText = () => {
    const [touched, error] = at(meta, 'touched', 'error');
    if (isAddressSelected !== true) {
      if (touched && error) {
        return error;
      }
    }
  };

  return (
    <TextField
      type={type}
      multiline={isMultilined}
      className={class_Name}
      rows={noOfRows}
      onKeyPress={(e) => {
        if ((e.key === 'e' || e.key === '+' || e.key === '-' || (e.key === '.' && restrictDecimal)) && props.type === 'number') {
          e.preventDefault();
        }
      }}
      label={
        isRequired
          ? (
            <label className="labelTextfield">
              {label} <span className="span-required">*</span>
            </label>
          )
          : (
            <label className="labelTextfield">{label} </label>
          )
      }
      error={meta.touched && meta.error && true}
      helperText={renderHelperText()}
      InputProps={{ classes, disabled: isDisabled, disableUnderline: true, readOnly: isReadOnly, ...endArguments }}
      placeholder={placeholder}
      inputProps={{
        ...inputProps,
      }}
      {...field}
      {...rest}
    />
  );
};

InputTextField.propTypes = {
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  isDisabled: PropTypes.bool,
};

InputTextField.defaultProps = {
  type: 'text',
  variant: 'filled',
  isDisabled: false,
  placeholder: '',
  restrictToOneDecimal: false,
};

export default InputTextField;
