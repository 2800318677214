import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Typography from '@material-ui/core/Typography';
import { StepContent } from '@material-ui/core';
import './varticalLinearStepper.scss';
import CustomDialog from '../dialog/customDialog';
import { Grid } from '@material-ui/core';
import TrackingStepperDetails from '../../components/dashboard/Tracking/TrackingDetails/TrackingStepperDetails';
import { Details } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { convertTZwithFormat, convertTZ } from "../../utils/helperFunctions"

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 20,
    alignItems: 'center',
  },
  active: {
    color: '#D40511',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    backgroundColor: '#fff',
    border: '1px solid #8C8C8C',
    zIndex: 1,
    fontSize: 18,
    width: 8,
    height: 8,
    borderRadius: '50%',
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <div className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {

  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,

  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  stepper: {
    flexDirection: 'column-reverse',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  childStepper: {
    width: '100%',
    fontSize: '16px',
    '&:hover': {
      backgroundColor: '#FFEEA9',
      cursor: 'pointer',
    },
  },
  selectedStepper: {
    width: '100%',
    fontSize: '16px',
    backgroundColor: '#FFEEA9',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  minStepper: {
    color: '#7F7F7F !important',
  },
}));

export default function CustomizedSteppers({ trackingDetails, trackingStatus, showPopup, isDelveryHistory }) {
  const classes = useStyles();
  const [stepContent, setStepContents] = useState([]);
  const [ContentDetails, setContentDetails] = useState({});

  const [open, setOpen] = React.useState(false);

  const [activeStep, setActiveStep] = React.useState(0);
  const selectedEventDetails = useSelector((state) => state.selectedEvent.selectedEvent);

  const handleClick = (content) => {
    console.log(trackingStatus, content)
    if (showPopup && (content.shipmentImages.length > 0 ||
      (content.latitude && content.longitude))
    ) {
      setContentDetails(content);
      handleModal(true);
    }
  };
  const handleModal = (value) => {
    setOpen(value);
  };

  const handleClose = (value) => {
    setOpen(false);
  };
  useEffect(() => {
    const rawlabels = [];

    if (trackingDetails != 0) {
      (trackingDetails || []).forEach((element) => {
        rawlabels.unshift({
          labels: isDelveryHistory && element.shipmentEvent.shipHarmonisedEvents.texttodisplayinShipTrackingPage.split('(')[1] ? element.shipmentEvent.shipHarmonisedEvents.texttodisplayinShipTrackingPage.split('(')[0] +" "+ convertTZwithFormat(element.shipmentEvent.dateTime, 'HH:mm') + " " + element.shipmentEvent.shipHarmonisedEvents.texttodisplayinShipTrackingPage.split(')')[1] : element.shipmentEvent.shipHarmonisedEvents.texttodisplayinShipTrackingPage,
          dateAndTime: element.shipmentEvent.dateTime,
          location: element.shipmentEvent.location.location,
          latitude: element.shipmentEvent.location.latitude,
          longitude: element.shipmentEvent.location.longitude,
          deliveryType: element.shipmentEvent.shipHarmonisedEvents.deliveryAddressType,
          comments: element.shipmentEvent.details.comments,
          shipmentId: element.shipmentEvent.shipmentId,
          shipmentImages: element.images,
          code: element.shipmentEvent.code,
          deliveryReceivedBy: element.shipmentEvent.details.deliveryReceivedBy,
          recipientName: element.shipmentEvent.details.recipientName,
          _Code: element.shipmentEvent.code,
        });
      });
      setActiveStep(rawlabels.length - 1);
    }
    
    setStepContents(rawlabels);
  }, [trackingDetails, selectedEventDetails]);


  return (
    <div className="VerticalLinearStepper">
      <div className={classes.root}>
        <Stepper
          className={classes.stepper}
          alternativeLabel
          activeStep={activeStep}
          connector={<QontoConnector />}
          orientation="vertical"
        >
          {stepContent.map((content, index) => {
            return (
              <Step
                key={index}
                onClick={() => handleClick(content)}
                className={content.code === selectedEventDetails ? classes.selectedStepper : classes.childStepper}
              >
                <StepLabel StepIconComponent={QontoStepIcon}>
                  <b>{content.labels}</b>
                </StepLabel>
                <StepContent TransitionComponent="None">
                  <Grid container direction="row" spacing={2}>
                    <Grid item lg={9} sm={9} md={9}>
                      <Typography className={classes.minStepper}>{content.location}</Typography>
                      {(isDelveryHistory && trackingStatus == "delivered") &&
                        (content.deliveryReceivedBy) ?
                        <Typography className={classes.minStepper}>{content.deliveryReceivedBy}</Typography>
                        : null
                      }
                      {(!isDelveryHistory && content._Code == "DESF") &&
                        (content.recipientName) ?
                        <Typography className={classes.minStepper}>{content.recipientName}</Typography>
                        : null
                      }
                      <Typography className={classes.minStepper}>{(content.dateAndTime) ? convertTZwithFormat(content.dateAndTime, 'YYYY-MM-DDTHH:mm:ssZ') : content.dateAndTime}</Typography>
                    </Grid>
                    <Grid item lg={3} sm={3} md={3}>
                      {content.shipmentImages.map((sign, key) => {
                        // Decode the String
                        var decodedStringAtoB = atob(sign.imageBase64);
                        return sign.imageType === 'signature'
                          ? (
                            <img width="50" height="50" src={decodedStringAtoB} />
                          )
                          : (
                            ''
                          );
                      })}
                    </Grid>
                  </Grid>
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
        <CustomDialog className="delivery-history-dialog" open={open} onClose={handleClose} title={`Details for ${ContentDetails.shipmentId}`}>
          <TrackingStepperDetails ContentDetails={ContentDetails} />
        </CustomDialog>
      </div>
    </div>
  );
}
