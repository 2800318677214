import axios from 'axios';
import { applicationID } from '../utils/authProvider/authProvider';

const apiTimeout = 240000; //4 minutes
export let controller = {};

const axiosDefaults = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const abortOnGoingCallsFor = (url = "") => {
  controller?.[url.split("?")[0]]?.abort?.();
}

export const axiosConfig = axios.create({
  ...axiosDefaults,
  timeout: apiTimeout,
});

axiosConfig.interceptors.response.use((response) => {
  return response;
});
axiosConfig.interceptors.request.use((config) => {
  const baseUrl = config.url.split("?")[0];
  // abortOnGoingCallsFor(baseUrl);
  controller[baseUrl] = new AbortController();

  config.headers.Authorization = sessionStorage.getItem(`msal.${applicationID}.idtoken`);
  config.signal = controller[baseUrl].signal;
  return config;
});

export const GET = axiosConfig.get;
export const POST = axiosConfig.post;
export const PUT = axiosConfig.put;
export const PATCH = axiosConfig.patch;
