import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
// import InputTextfield from '../../../framework/Inputs/inputTextField/inputTextField'
import FormRenderer from '../../../../framework/Inputs/formRenderer/formRenderer';
import { useDispatch } from 'react-redux';
import * as actions from '../../../../store/actions';
// import { useSelector } from 'react-redux';
// import { genralConfigurationForm } from '../../../../utils/formConfig/genralconfiguration/genralConfiguratiomForm';
const GeneralCoonfig = (props) => {
  const {
    formField: { addNewAccountToUsers },
  } = props;

  const { formikValues } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.setDefaultAddNewAccount(formikValues.addToAllUsers));
  }, [formikValues.addToAllUsers]);

  return (
    <Grid container direction="column" className="createUser-container" spacing={2}>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <FormRenderer {...addNewAccountToUsers.props} fullWidth />
      </Grid>
    </Grid>
  );
};
GeneralCoonfig.propTypes = {
  formField: PropTypes.object,
  formikValues: PropTypes.object,
  setFieldValue: PropTypes.func,
};

export default GeneralCoonfig;
