import React, { useState, useEffect } from "react";
import { What3wordsAutosuggest } from "@what3words/react-components";
import './AutoSuggestW3W.scss';
import { applicationID } from "../../../../utils/authProvider/authProvider";
import { ServiceEndPoints } from "../../../../utils/constants/ApiConstant";

function AutoSuggestW3W(props) {
  const {
    setFieldValue,
    formikValues,
    isDisabled,
    formField: {
      what3Words,
      w3WCoordinates
    }
  } = props;
  const [value, setValue] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [selectedValidW3W,setSelectedValidW3W] = useState('');
  const headersW3W = '{"Authorization": "@authValue"}'.replace("@authValue",sessionStorage.getItem(`msal.${applicationID}.idtoken`));

  useEffect(() => {
    setValue(formikValues?.what3Words || "");
    setSelectedValidW3W(formikValues?.what3Words);
  }, [formikValues?.what3Words]);

  const onChange = (e) => {
    e.target.value = e.target.value.replace(/[^A-Za-z./]/g, '');
    setValue(e.target.value);
    setIsValid(false);
    if(e.target.value ==='')
    {
      setValue("");
      setFieldValue('what3Words', "");
      setFieldValue('w3WCoordinates', {});
    }
  };

  const onBlur = (e) => {
    if(!isValid && !selectedValidW3W){
        setValue("");
        setFieldValue('what3Words', "");
        setFieldValue('w3WCoordinates', {});
      }
      setSelectedValidW3W('');
  };

  const onValidW3WSelection = (detail) =>{
    setFieldValue('w3WCoordinates', detail.coordinates);
  }

  return (
    <>
      <What3wordsAutosuggest 
        base_url={ServiceEndPoints.getWhat3Words}
        clip_to_country="GB,JE,GG" 
        return_coordinates={true}
        headers={headersW3W}
        onSelected_suggestion={({ detail }) => {
          setFieldValue('what3Words', detail.suggestion.words);
          setValue(detail.suggestion.words);
          setIsValid(true);
        }}
        invalid_address_error_message="No valid what3words address found"
        onCoordinates_changed={(ele)=>onValidW3WSelection(ele.detail)}
      >
        <input
          id="w3w"
          autoComplete={"off"}
          placeholder="Start typing what3words e.g. ///pretty.needed.chill"
          type="text"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          optional
          disabled={isDisabled}
        />
      </What3wordsAutosuggest>
    </>
  );
}

export default AutoSuggestW3W;
